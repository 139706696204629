import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { apiGetDataAddressAutocomplete, apiGetDataCallToActions, apiGetDataCities, apiGetDataCityTargets, apiGetDataCountryTargets, apiGetDataDmas, apiGetDataDmaTargets, apiGetDataPostalCodeTargets, apiGetDataRegionTargets, apiGetDataStates, apiGetDataTimezones, apiGetDataTvAudiences, apiGetDataZips, apiSearchBusinessTypes } from 'api/adcritterApi'
import { apiGetDataAudiences } from 'api/adcritterApi'
import { AddressAutocomplete, State, Dma, Timezone, ThirdPartyAudience, City, Zip, BusinessType, RegionTarget, DmaTarget, CityTarget, PostalCodeTarget, CountryTarget } from 'api/interfaces'

import { AppThunk } from './store'

export interface PreBuiltAd {
  headline: string
  body: string
  callToAction: string
  highQualityUrl: string
  squareUrl: string
  thumbnailUrl: string
}

export type AppState = {
  isOpenDictionaryLoaded: boolean
  isDictionaryLoaded: boolean
  isLoadingCountryTargets: boolean
  countryTargets: CountryTarget[]
  isLoadingRegionTargets: boolean
  regionTargets: RegionTarget[]
  regionTargetsFiltered: RegionTarget[]
  isLoadingStates: boolean
  states: State[]
  statesFiltered: State[]
  isLoadingDmaTargets: boolean
  dmaTargets: DmaTarget[]
  dmaTargetsFiltered: DmaTarget[]
  isLoadingDmas: boolean
  dmas: Dma[]
  dmasFiltered: Dma[]
  isLoadingTimezones: boolean
  timezones: Timezone[]
  isLoadingCallToActions: boolean
  callToActions: string[]
  isLoadingCityTargets: boolean
  cityTargets: CityTarget[]
  isLoadingCities: boolean
  cities: City[]
  isLoadingPostalCodeTargets: boolean
  postalCodeTargets: PostalCodeTarget[]
  isLoadingZips: boolean
  zips: Zip[]
  isLoadingAddressAutocompletes: boolean
  addressAutocompletes: AddressAutocomplete[]
  isLoadingAudiences: boolean
  audiences: ThirdPartyAudience[]
  isLoadingTvAudiences: boolean
  tvAudiences: ThirdPartyAudience[]
  isLoadingBusinessTypes: boolean
  businessTypes: BusinessType[]
  prebuiltStylistAds: PreBuiltAd[]
  prebuiltStudentAds: PreBuiltAd[]
}

const initialState: AppState = {
  isOpenDictionaryLoaded: false,
  isDictionaryLoaded: false,
  isLoadingCountryTargets: false,
  countryTargets: [],
  isLoadingRegionTargets: false,
  regionTargets: [],
  regionTargetsFiltered: [],
  isLoadingStates: false,
  states: [],
  statesFiltered: [],
  isLoadingDmaTargets: false,
  dmaTargets: [],
  dmaTargetsFiltered: [],
  isLoadingDmas: false,
  dmas: [],
  dmasFiltered: [],
  isLoadingTimezones: false,
  timezones: [],
  isLoadingCallToActions: false,
  callToActions: [],
  isLoadingCityTargets: false,
  cityTargets: [],
  isLoadingCities: false,
  cities: [],
  isLoadingPostalCodeTargets: false,
  postalCodeTargets: [],
  isLoadingZips: false,
  zips: [],
  isLoadingAddressAutocompletes: false,
  addressAutocompletes: [],
  isLoadingAudiences: false,
  audiences: [],
  isLoadingTvAudiences: false,
  tvAudiences: [],
  isLoadingBusinessTypes: false,
  businessTypes: [],
  prebuiltStylistAds: [
    {
      headline: 'Great Clips Salons. Great Things Await.',
      body: 'No matter your expertise, we want to meet you. From new graduates to established stylists, all have a special place behind the chair here.',
      callToAction: 'Join Now',
      highQualityUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/ae88eb9e-8674-463c-b75c-8ba76ab1a39d_highquality.jpg',
      squareUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/ae88eb9e-8674-463c-b75c-8ba76ab1a39d_square.jpg',
      thumbnailUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/ae88eb9e-8674-463c-b75c-8ba76ab1a39d_thumbnail.jpg'
    },
    {
      headline: 'Great Clips Salons. Live Your Dream.',
      body: "Don't just dream big. Live it. It all starts with your first steps through our doors. Join a Great Clips salon team today!",
      callToAction: 'Join Now',
      highQualityUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/7f9ed509-0975-4a3a-bcfe-c0b5e557b5d9_highquality.jpg',
      squareUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/7f9ed509-0975-4a3a-bcfe-c0b5e557b5d9_square.jpg',
      thumbnailUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/7f9ed509-0975-4a3a-bcfe-c0b5e557b5d9_thumbnail.jpg'
    },
    {
      headline: 'Great Clips Salons. Make Great Happen.',
      body: 'Find everything - and everyone - you need right here. Join a Great Clips salon team today!',
      callToAction: 'Join Now',
      highQualityUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/357f2f4d-8b21-4bd2-8ccf-a071be6019ba_highquality.jpg',
      squareUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/357f2f4d-8b21-4bd2-8ccf-a071be6019ba_square.jpg',
      thumbnailUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/357f2f4d-8b21-4bd2-8ccf-a071be6019ba_thumbnail.jpg'
    },
    {
      headline: 'Great Clips Salons. Join A Cutting-Edge Team.',
      body: "We believe great doesn't happen alone. Join a team of stylists that cares about you and your future.",
      callToAction: 'Join Now',
      highQualityUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/051d07a7-ce05-4dd3-b5b3-a7839f6b069e_highquality.jpg',
      squareUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/051d07a7-ce05-4dd3-b5b3-a7839f6b069e_square.jpg',
      thumbnailUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/051d07a7-ce05-4dd3-b5b3-a7839f6b069e_thumbnail.jpg'
    },
    {
      headline: 'Great Clips Salons. Be One of the Greats.',
      body: "Show off your talents with the best of them. With over 4,500 salons and 35,000 stylists, we almost have it all. All we're missing is you.",
      callToAction: 'Join Now',
      highQualityUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/85a7a18a-91c7-483b-bdca-745219476c9c_highquality.jpg',
      squareUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/85a7a18a-91c7-483b-bdca-745219476c9c_square.jpg',
      thumbnailUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/85a7a18a-91c7-483b-bdca-745219476c9c_thumbnail.jpg'
    }
  ],
  prebuiltStudentAds: [
    {
      headline: 'Great Clips Salons. Salon Careers Start Here.',
      body: 'See why over 35,000 stylists chose the Great Clips brand to strengthen their careers.',
      callToAction: 'Learn More',
      highQualityUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/9046ce3c-3c37-4014-a479-874979fb3269_highquality.jpg',
      squareUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/9046ce3c-3c37-4014-a479-874979fb3269_square.jpg',
      thumbnailUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/9046ce3c-3c37-4014-a479-874979fb3269_thumbnail.jpg'
    },
    {
      headline: 'Great Clips Salons. Live Your Dream.',
      body: "Don't just dream big. Live it. It all starts with your first steps through our doors. Join a Great Clips salon team today!",
      callToAction: 'Join Now',
      highQualityUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/69ddc31e-c7c6-47a5-8424-c9e7915e9897_highquality.jpg',
      squareUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/69ddc31e-c7c6-47a5-8424-c9e7915e9897_square.jpg',
      thumbnailUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/69ddc31e-c7c6-47a5-8424-c9e7915e9897_thumbnail.jpg'
    },
    {
      headline: 'Great Clips Salons. Great Things Await.',
      body: 'Want to be the best stylist in town? As part of a Great Clips salon team, that can happen.',
      callToAction: 'Join Now',
      highQualityUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/11acc026-fade-4971-acec-ed421f9a693a_highquality.jpg',
      squareUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/11acc026-fade-4971-acec-ed421f9a693a_square.jpg',
      thumbnailUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/11acc026-fade-4971-acec-ed421f9a693a_thumbnail.jpg'
    },
    {
      headline: 'Great Clips Salons. Build Your Career.',
      body: 'The Great Clips brand offers stylists the tools, resources, ongoing education, and connections to make their career a beautiful success.',
      callToAction: 'Join Now',
      highQualityUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/6c5c88ad-ce69-4b3d-8628-a8ffbf02c73c_highquality.jpg',
      squareUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/6c5c88ad-ce69-4b3d-8628-a8ffbf02c73c_square.jpg',
      thumbnailUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/6c5c88ad-ce69-4b3d-8628-a8ffbf02c73c_thumbnail.jpg'
    },
    {
      headline: 'Great Clips Salons. Express Your Creativity.',
      body: "Show off your talents with the best of them. With over 4,500 salons and 35,000 stylists, we almost have it all. All we're missing is you.",
      callToAction: 'Learn More',
      highQualityUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/25ddfc8f-b821-4d0f-87d0-3c25990b3c5e_highquality.jpg',
      squareUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/25ddfc8f-b821-4d0f-87d0-3c25990b3c5e_square.jpg',
      thumbnailUrl: 'https://acprodeastusstorage.blob.core.windows.net/c8d519fe-43f2-431c-8bd5-62ad14c3e67e/25ddfc8f-b821-4d0f-87d0-3c25990b3c5e_thumbnail.jpg'
    }
  ]
}

const dictionarySlice = createSlice({
  name: 'dictionary',
  initialState,
  reducers: {
    startLoadingRegionTargets(state: AppState) {
      state.isLoadingRegionTargets = true
    },
    finishedLoadingRegionTargets(state: AppState, { payload }: PayloadAction<RegionTarget[]>) {
      state.regionTargets = [...payload]
      state.regionTargetsFiltered = [...payload]
      state.isLoadingRegionTargets = false
    },
    startLoadingCountryTargets(state: AppState) {
      state.isLoadingCountryTargets = true
    },
    finishedLoadingCountryTargets(state: AppState, { payload }: PayloadAction<CountryTarget[]>) {
      state.countryTargets = payload
      state.isLoadingCountryTargets = false
    },
    filterRegionTargets(state: AppState, { payload }: PayloadAction<string>) {
      state.regionTargetsFiltered = [...state.regionTargets.filter(d => d.name.toLowerCase().includes(payload.toLowerCase()) || d.code.toLowerCase().includes(payload.toLowerCase()))]
    },
    clearRegionTargets(state: AppState) {
      state.regionTargetsFiltered = []
    },
    startLoadingStates(state: AppState) {
      state.isLoadingStates = true
    },
    finishedLoadingStates(state: AppState, { payload }: PayloadAction<State[]>) {
      state.states = [...payload]
      state.statesFiltered = []
      state.isLoadingStates = false
    },
    filterStates(state: AppState, { payload }: PayloadAction<string>) {
      state.statesFiltered = [...state.states.filter(d => d.name.toLowerCase().includes(payload.toLowerCase()) || d.code.toLowerCase().includes(payload.toLowerCase()))]
    },
    clearStates(state: AppState) {
      state.statesFiltered = []
    },
    startLoadingDmaTargets(state: AppState) {
      state.isLoadingDmaTargets = true
    },
    finishedLoadingDmaTargets(state: AppState, { payload }: PayloadAction<DmaTarget[]>) {
      state.dmaTargets = [...payload]
      state.dmaTargetsFiltered = []
      state.isLoadingDmaTargets = false
    },
    filterDmaTargets(state: AppState, { payload }: PayloadAction<string>) {
      state.dmaTargetsFiltered = [...state.dmaTargets.filter(d => d.name.toLowerCase().includes(payload.toLowerCase()))]
    },
    clearDmaTargets(state: AppState) {
      state.dmaTargetsFiltered = []
    },
    startLoadingDmas(state: AppState) {
      state.isLoadingDmas = true
    },
    finishedLoadingDmas(state: AppState, { payload }: PayloadAction<Dma[]>) {
      state.dmas = [...payload]
      state.dmasFiltered = []
      state.isLoadingDmas = false
    },
    filterDmas(state: AppState, { payload }: PayloadAction<string>) {
      state.dmasFiltered = [...state.dmas.filter(d => d.name.toLowerCase().includes(payload.toLowerCase()))]
    },
    clearDmas(state: AppState) {
      state.dmasFiltered = []
    },
    startLoadingTimezones(state: AppState) {
      state.isLoadingTimezones = true
    },
    startOpenDictionaryLoading(state: AppState) {
      state.isOpenDictionaryLoaded = true
    },
    startDictionaryLoading(state: AppState) {
      state.isDictionaryLoaded = true
    },
    finishedLoadingTimezones(state: AppState, { payload }: PayloadAction<Timezone[]>) {
      state.timezones = payload
      state.isLoadingTimezones = false
    },
    startLoadingCallToActions(state: AppState) {
      state.isLoadingCallToActions = true
    },
    finishedLoadingCallToActions(state: AppState, { payload }: PayloadAction<string[]>) {
      state.callToActions = payload
      state.isLoadingCallToActions = false
    },
    startLoadingCityTargets(state: AppState) {
      state.isLoadingCityTargets = true
    },
    finishedLoadingCityTargets(state: AppState, { payload }: PayloadAction<CityTarget[]>) {
      state.cityTargets = payload
      state.isLoadingCityTargets = false
    },
    startLoadingCities(state: AppState) {
      state.isLoadingCities = true
    },
    finishedLoadingCities(state: AppState, { payload }: PayloadAction<City[]>) {
      state.cities = payload
      state.isLoadingCities = false
    },
    startLoadingPostalCodeTargets(state: AppState) {
      state.isLoadingPostalCodeTargets = true
    },
    finishedLoadingPostalCodeTargets(state: AppState, { payload }: PayloadAction<PostalCodeTarget[]>) {
      state.postalCodeTargets = payload
      state.isLoadingPostalCodeTargets = false
    },
    startLoadingZips(state: AppState) {
      state.isLoadingZips = true
    },
    finishedLoadingZips(state: AppState, { payload }: PayloadAction<Zip[]>) {
      state.zips = payload
      state.isLoadingZips = false
    },
    startLoadingAddressAutocompletes(state: AppState) {
      state.isLoadingAddressAutocompletes = true
    },
    finishedLoadingAddressAutocompletes(state: AppState, { payload }: PayloadAction<AddressAutocomplete[]>) {
      state.addressAutocompletes = payload
      state.isLoadingAddressAutocompletes = false
    },
    startLoadingAudiences(state: AppState) {
      state.isLoadingAudiences = true
    },
    finishedLoadingAudiences(state: AppState, { payload }: PayloadAction<ThirdPartyAudience[]>) {
      state.audiences = payload
      state.isLoadingAudiences = false
    },
    startLoadingTvAudiences(state: AppState) {
      state.isLoadingTvAudiences = true
    },
    finishedLoadingTvAudiences(state: AppState, { payload }: PayloadAction<ThirdPartyAudience[]>) {
      state.tvAudiences = payload
      state.isLoadingTvAudiences = false
    },
    startLoadingBusinessTypes(state: AppState) {
      state.isLoadingBusinessTypes = true
    },
    finishedLoadingBusinessTypes(state: AppState, { payload }: PayloadAction<BusinessType[]>) {
      state.businessTypes = payload
      state.isLoadingBusinessTypes = false
    }
  }
})

export const {
  startDictionaryLoading,
  startOpenDictionaryLoading,
  startLoadingCountryTargets,
  finishedLoadingCountryTargets,
  startLoadingRegionTargets,
  filterRegionTargets,
  clearRegionTargets,
  finishedLoadingRegionTargets,
  startLoadingStates,
  filterStates,
  clearStates,
  finishedLoadingStates,
  startLoadingDmas,
  startLoadingDmaTargets,
  filterDmaTargets,
  clearDmaTargets,
  finishedLoadingDmaTargets,
  finishedLoadingDmas,
  filterDmas,
  clearDmas,
  startLoadingTimezones,
  finishedLoadingTimezones,
  startLoadingCallToActions,
  finishedLoadingCallToActions,
  startLoadingCityTargets,
  finishedLoadingCityTargets,
  startLoadingCities,
  finishedLoadingCities,
  startLoadingPostalCodeTargets,
  finishedLoadingPostalCodeTargets,
  startLoadingZips,
  finishedLoadingZips,
  startLoadingAddressAutocompletes,
  finishedLoadingAddressAutocompletes,
  startLoadingAudiences,
  finishedLoadingAudiences,
  startLoadingTvAudiences,
  finishedLoadingTvAudiences,
  startLoadingBusinessTypes,
  finishedLoadingBusinessTypes
} = dictionarySlice.actions

export default dictionarySlice.reducer

export const fetchOpenDictionary = (): AppThunk => async dispatch => {
  dispatch(startOpenDictionaryLoading())
  dispatch(startLoadingCountryTargets())
  dispatch(startLoadingRegionTargets())
  dispatch(startLoadingStates())
  dispatch(startLoadingDmaTargets())
  dispatch(startLoadingDmas())

  apiGetDataCountryTargets().then(response => dispatch(finishedLoadingCountryTargets(response.data)))
  apiGetDataRegionTargets(233).then(response => dispatch(finishedLoadingRegionTargets(response.data)))
  apiGetDataStates('US').then(response => dispatch(finishedLoadingStates(response.data)))
  apiGetDataDmaTargets(233).then(response => dispatch(finishedLoadingDmaTargets(response.data)))
  apiGetDataDmas('US').then(response => dispatch(finishedLoadingDmas(response.data)))
}

export const fetchDictionary = (): AppThunk => async dispatch => {
  dispatch(startDictionaryLoading())
  dispatch(startLoadingTimezones())
  dispatch(startLoadingCallToActions())

  apiGetDataCallToActions().then(response => dispatch(finishedLoadingCallToActions(response.data)))
  apiGetDataTimezones().then(response => dispatch(finishedLoadingTimezones(response.data)))
}

export const fetchRegionTargets = (): AppThunk => async dispatch => {
  dispatch(startLoadingRegionTargets())
  const { data } = await apiGetDataRegionTargets(233)
  dispatch(finishedLoadingRegionTargets(data))
}

export const fetchDmaTargets = (): AppThunk => async dispatch => {
  dispatch(startLoadingDmaTargets())
  const { data } = await apiGetDataDmaTargets(233)
  dispatch(finishedLoadingDmaTargets(data))
}

export const fetchCityTargets =
  (query: string): AppThunk =>
  async dispatch => {
    dispatch(startLoadingCityTargets())
    const { data } = await apiGetDataCityTargets(233, query)
    dispatch(finishedLoadingCityTargets(data))
  }

export const clearCityTargets = (): AppThunk => async dispatch => {
  dispatch(finishedLoadingCityTargets([]))
}

export const fetchCities =
  (query: string): AppThunk =>
  async dispatch => {
    dispatch(startLoadingCities())
    const { data } = await apiGetDataCities('US', query)
    dispatch(finishedLoadingCities(data))
  }

export const clearCities = (): AppThunk => async dispatch => {
  dispatch(finishedLoadingCities([]))
}

export const fetchPostalCodeTargets =
  (query: string): AppThunk =>
  async dispatch => {
    dispatch(startLoadingPostalCodeTargets())
    const { data } = await apiGetDataPostalCodeTargets(233, query)
    dispatch(finishedLoadingPostalCodeTargets(data))
  }

export const clearPostalCodeTargets = (): AppThunk => async dispatch => {
  dispatch(finishedLoadingPostalCodeTargets([]))
}

export const fetchZips =
  (query: string): AppThunk =>
  async dispatch => {
    dispatch(startLoadingZips())
    const { data } = await apiGetDataZips('US', query)
    dispatch(finishedLoadingZips(data))
  }

export const clearZips = (): AppThunk => async dispatch => {
  dispatch(finishedLoadingZips([]))
}

export const fetchAddressAutocompletes =
  (query: string): AppThunk =>
  async dispatch => {
    dispatch(startLoadingAddressAutocompletes())
    const { data } = await apiGetDataAddressAutocomplete(query)
    dispatch(finishedLoadingAddressAutocompletes(data))
  }

export const clearAddressAutocompletes = (): AppThunk => async dispatch => {
  dispatch(finishedLoadingAddressAutocompletes([]))
}

export const fetchAudiences =
  (query: string): AppThunk =>
  async dispatch => {
    dispatch(startLoadingAudiences())
    const { data } = await apiGetDataAudiences(query)
    data.sort((a: ThirdPartyAudience, b: ThirdPartyAudience) => (a.reachCount < b.reachCount ? 1 : -1))
    dispatch(finishedLoadingAudiences(data))
  }

export const clearAudiences = (): AppThunk => async dispatch => {
  dispatch(finishedLoadingAudiences([]))
}

export const fetchTvAudiences =
  (query: string): AppThunk =>
  async dispatch => {
    dispatch(startLoadingTvAudiences())
    const { data } = await apiGetDataTvAudiences(query)
    dispatch(finishedLoadingTvAudiences(data))
  }

export const clearTvAudiences = (): AppThunk => async dispatch => {
  dispatch(finishedLoadingTvAudiences([]))
}

export const fetchBusinessTypes =
  (query: string): AppThunk =>
  async dispatch => {
    dispatch(startLoadingBusinessTypes())
    const { data } = await apiSearchBusinessTypes(query)
    dispatch(finishedLoadingBusinessTypes(data))
  }

export const clearBusinessTypes = (): AppThunk => async dispatch => {
  dispatch(finishedLoadingBusinessTypes([]))
}
