import React, { FC } from 'react'

import { InsightsConfiguration } from 'api/interfaces'
import { Insights } from 'api/interfaces/Insights'
import { useGetCampaignInsightsConfigurationQuery, useGetCampaignInsightsRepetitionQuery } from 'api/rtkQueryApi/platform/campaignInsightsApi'
import InsightsRepetitionChart from 'components/insights/Charts/InsightsRepetitionChart'
import InsightsPanel from 'components/insights/Components/InsightsPanel'
import { repetitionButtonColor, repetitionButtonTextColor, repetitionDescription, repetitionRightPanelColor, repetitionRightPanelDescription, repetitionRightPanelLink, repetitionRightPanelLinkText, repetitionRightPanelTitleDescription } from 'components/insights/InsightsConstants'

export interface InsightsRepetitionProps {
  isLoading: boolean
  repetition?: Insights
  config?: InsightsConfiguration
}

const InsightsRepetition: FC<InsightsRepetitionProps> = ({ isLoading, repetition, config }) => {
  let currentMonth = 0
  for (const item in repetition?.byMonth) {
    let curr = repetition?.byMonth[item]?.value ?? 0

    if (curr !== -1) {
      currentMonth = repetition?.byMonth[item]?.value ?? 0
    }
  }

  return (
    <InsightsPanel
      isLoading={isLoading}
      title='Repetition'
      description={repetitionDescription}
      buttonColor={repetitionButtonColor}
      buttonTextColor={repetitionButtonTextColor}
      rightPanelTitle={currentMonth.toLocaleString()}
      rightPanelTitleDescription={repetitionRightPanelTitleDescription}
      rightPanelDescription={repetitionRightPanelDescription}
      rightPanelLink={repetitionRightPanelLink}
      rightPanelLinkText={repetitionRightPanelLinkText}
      rightPanelColor={repetitionRightPanelColor}>
      <InsightsRepetitionChart isLoading={isLoading} repetitionTarget={config?.repetitionTarget ?? 45} repetition={repetition} />
    </InsightsPanel>
  )
}

export default InsightsRepetition
