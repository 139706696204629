import React, { FC, useState } from 'react'

import { EuiText } from '@elastic/eui'

export interface IDayPartPickerCell {
  isSelected: boolean
  onSelected?: () => void
  onMouseEnter?: () => void
  text?: string
  disableHover?: boolean
}

const dayPartCellWidth = 36
const dayPartCellMargin = 2
const dayPartCellBorder = '1px solid #d7d7d7'

const dayPartSelectedHoveredColor = '#6092C0'
const dayPartSelectedColor = '#006BB4'
const dayPartHoveredColor = '#98A2B3'
const dayPartBaseColor = 'white'

const getBackgroundColorForDayPart = (cell: IDayPartPickerCell, hovered: boolean) => {
  if (cell) {
    if (cell.isSelected) {
      if (!cell.disableHover && hovered) {
        return dayPartSelectedHoveredColor
      } else {
        return dayPartSelectedColor
      }
    } else if (!cell.disableHover && hovered) {
      return dayPartHoveredColor
    }
  }
  return dayPartBaseColor
}
export const DayPartPickerCell: FC<IDayPartPickerCell> = props => {
  const [isHovered, setIsHovered] = useState(false)

  const onClick = () => {
    if (props.onSelected) {
      props.onSelected()
    }
  }

  const onMouseEnter = () => {
    setIsHovered(true)
    if (props.onMouseEnter) {
      props.onMouseEnter()
    }
  }

  const onMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <div
      style={{
        float: 'left',
        width: dayPartCellWidth + 'px',
        height: dayPartCellWidth + 'px',
        margin: dayPartCellMargin + 'px',
        border: dayPartCellBorder,
        borderRadius: '2px',
        textAlign: 'center',
        lineHeight: dayPartCellWidth - 4 + 'px',
        backgroundColor: getBackgroundColorForDayPart(props, isHovered),
        userSelect: 'none',
        msUserSelect: 'none'
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}>
      <EuiText size='xs' draggable={false}>
        {props.text}
      </EuiText>
    </div>
  )
}
