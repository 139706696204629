import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiCard, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiLoadingContent, EuiShowFor, EuiSpacer, EuiText, EuiTitle, htmlIdGenerator } from '@elastic/eui'

import { TvCommercial } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

import { setPreviewUrl, setTvCommercial, setCreate, fetchTvCommercials } from './builderTvRetailSlice'

const SuggestionsStep: React.FC = () => {
  const dispatch = useDispatch()
  const builder = useSelector((state: RootState) => state.builderTvRetail)
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [chosenTvCommercial, setChosenTvCommercial] = useState<TvCommercial | null>(null)
  const campaign = useSelector((state: RootState) => state.campaign)

  useEffect(() => {
    if (!builder.tvCommercials && !builder.isLoadingTvCommercials && builder.businessType && currentAccount) {
      dispatch(fetchTvCommercials(currentAccount.id, builder.businessType.id))
    }
  }, [dispatch, currentAccount, builder.businessType, builder.tvCommercials, builder.isLoadingTvCommercials])

  useEffect(() => {
    if (chosenTvCommercial && currentAccount && builder.suggest) {
      dispatch(setTvCommercial(chosenTvCommercial))
      let website = ''
      if (currentAccount.website) {
        try {
          let domain = new URL(currentAccount.website)
          website = domain.host
        } catch (TypeError) {
          website = currentAccount.website
        }
      }
      dispatch(
        setCreate({
          sections: chosenTvCommercial.sections,
          destinationUrl: website,
          name: null,
          phone: null,
          notes: null,
          uploadedUrl: null,
          logoUrl: null,
          outroUrl: null,
          script: chosenTvCommercial.script
        })
      )
      dispatch(setPreviewUrl(chosenTvCommercial.previewUrl))
      history.push('/build/tv/public/create')
    }
  }, [dispatch, currentAccount, chosenTvCommercial, campaign.campaign, builder.suggest])

  const gotoSuggest = () => {
    history.push('/build/tv/public/suggest')
  }

  interface VideoCardParams {
    tvCommercial: TvCommercial
  }

  const VideoCard: React.FC<VideoCardParams> = ({ tvCommercial }) => {
    const [url, setUrl] = useState<string>()

    useEffect(() => {
      if (tvCommercial) {
        setUrl(tvCommercial.thumbnailUrl)
      }
    }, [tvCommercial])

    return (
      <EuiFlexItem id='tvAdSuggestion' style={{ width: 640 }}>
        <EuiCard
          title={tvCommercial.name}
          textAlign={'left'}
          description={''}
          image={<img src={url || ''} alt='' />}
          footer={
            <EuiFlexGroup justifyContent='flexEnd'>
              <EuiFlexItem>
                <EuiText size='s'>{tvCommercial.kind === 'Seconds15' ? '15 seconds' : '30 seconds'}</EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton id='useEditBtn' color='primary' onClick={() => setChosenTvCommercial(tvCommercial)} style={{ width: 200 }} fill iconType='arrowRight' iconSide='right'>
                  Use/Edit
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        />
      </EuiFlexItem>
    )
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>TV Commercials</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>TV Commercials</h3>
      </EuiTitle>
      <EuiText size='s'>
        <p>Browse and edit these suggested commercials.</p>
      </EuiText>
      <EuiSpacer />

      {builder.isLoadingTvCommercials && <EuiLoadingContent lines={3} />}
      {builder.tvCommercials && (
        <React.Fragment>
          {builder.tvCommercials && builder.tvCommercials.length > 0 && (
            <React.Fragment>
              <EuiShowFor sizes={['s', 'xs']}>
                <EuiFlexGrid columns={1} responsive={true}>
                  {builder.tvCommercials.map(t => (
                    <VideoCard key={htmlIdGenerator()()} tvCommercial={t} />
                  ))}
                </EuiFlexGrid>
              </EuiShowFor>
              <EuiShowFor sizes={['l', 'm']}>
                <EuiFlexGrid columns={2} responsive={true}>
                  {builder.tvCommercials.map(t => (
                    <VideoCard key={htmlIdGenerator()()} tvCommercial={t} />
                  ))}
                </EuiFlexGrid>
              </EuiShowFor>
              <EuiShowFor sizes={['xl']}>
                <EuiFlexGrid columns={3} responsive={true}>
                  {builder.tvCommercials.map(t => (
                    <VideoCard key={htmlIdGenerator()()} tvCommercial={t} />
                  ))}
                </EuiFlexGrid>
              </EuiShowFor>
            </React.Fragment>
          )}
          <EuiSpacer />
        </React.Fragment>
      )}

      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton id='back' fill type='button' color='text' onClick={gotoSuggest} iconType='arrowLeft' iconSide='left'>
            Back
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default SuggestionsStep
