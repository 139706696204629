import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { EuiCallOut, EuiFormRow, EuiHideFor, EuiRadio, EuiShowFor, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { CityTarget, DmaTarget, GeoFence, LocationTarget, PostalCodeTarget, RegionTarget, Zip } from 'api/interfaces'
import TargetingMap from 'components/TargetingMap'
import { GeoFenceFinder } from 'components/finders'
import { NewCityFinder } from 'components/finders/NewCityFinder'
import { NewDmaFinder } from 'components/finders/NewDmaFinder'
import { NewPostalCodeFinder } from 'components/finders/NewPostalCodeFinder'
import { NewRegionFinder } from 'components/finders/NewRegionFinder'
import { GeoFenceTargeter } from 'components/targeters/GeoFenceTargeter'
import { NewCityTargeter } from 'components/targeters/NewCityTargeter'
import { NewDmaTargeter } from 'components/targeters/NewDmaTargeter'
import { NewRegionTargeter } from 'components/targeters/NewRegionTargeter'
import { ZipTargeter } from 'components/targeters/ZipTargeter'
import { configureDisplays, modifyTargetedPlaces, resetTargetingMapState } from 'components/targetingMapSlice'
import { useDebounceEffect } from 'utils/useDebounceEffect'

export interface IGeoSelectionTargeter {
  title?: string
  locations?: LocationTarget
  addresses?: GeoFence[]
  selectedLocationType: LocationType
  onSelectedTargetingTypeChange?: (locationType: LocationType) => void
  onTargetingUpdated?: (locations: LocationTarget, addresses?: GeoFence[]) => void
  hideLocationTypes?: LocationType[]
  alwaysShowMap?: boolean
  includeBillboards?: boolean
}

export enum LocationType {
  USA = 'USA',
  State = 'State',
  DMA = 'DMA',
  City = 'City',
  Zip = 'Zip',
  Address = 'Address',
  Map = 'Map'
}

export const GeoSelectionTargeter: React.FC<IGeoSelectionTargeter> = props => {
  const dispatch = useDispatch()
  const [selectedStates, setSelectedStates] = useState<RegionTarget[]>([])
  const [selectedCities, setSelectedCities] = useState<CityTarget[]>([])
  const [selectedDmas, setSelectedDmas] = useState<DmaTarget[]>([])
  const [selectedZips, setSelectedZips] = useState<PostalCodeTarget[]>([])
  const [selectedAddresses, setSelectedAddresses] = useState<GeoFence[]>([])
  const [suggestedPlaces, setSuggestedPlace] = useState<string[]>([])

  useEffect(() => {
    switch (props.selectedLocationType) {
      case LocationType.State:
        setSuggestedPlace(selectedStates.map(s => s.name))
        break
      case LocationType.City:
        setSuggestedPlace(selectedCities.map(c => c.name))
        break
      case LocationType.DMA:
        setSuggestedPlace(selectedDmas.map(d => d.name))
        break
      case LocationType.Zip:
        setSuggestedPlace(selectedZips.map(z => z.name))
        break
      default:
        setSuggestedPlace([])
        break
    }
  }, [props.selectedLocationType, selectedStates, selectedCities, selectedDmas, selectedZips, selectedAddresses])

  useEffect(() => {
    return () => {
      dispatch(resetTargetingMapState())
    }
  }, [])

  useEffect(() => {
    if (props.selectedLocationType === LocationType.Map || props.alwaysShowMap) {
      dispatch(
        configureDisplays({
          hideDrawingTools: props.selectedLocationType !== LocationType.Map,
          hideLocationSearch: props.selectedLocationType !== LocationType.Map,
          includeBillboards: !!props.includeBillboards
        })
      )
    }
  }, [props.includeBillboards, props.selectedLocationType, props.alwaysShowMap])

  useEffect(() => {
    if (suggestedPlaces) {
      dispatch(modifyTargetedPlaces(suggestedPlaces))
    }
  }, [suggestedPlaces])

  const onLocationRadioSelected = (locationType: LocationType) => {
    if (props.onSelectedTargetingTypeChange) {
      props.onSelectedTargetingTypeChange(locationType)
    }
  }

  useEffect(() => {
    if (props.locations) {
      setSelectedStates(props.locations.regions ?? [])
      setSelectedCities(props.locations.cities ?? [])
      setSelectedDmas(props.locations.dmas ?? [])
      setSelectedZips(props.locations.postalCodes ?? [])
    }
  }, [props.locations])

  useEffect(() => {
    if (props.addresses) {
      setSelectedAddresses(props.addresses)
    }
  }, [props.addresses])

  useDebounceEffect(
    500,
    () => {
      if (props.onTargetingUpdated && !props.locations) {
        props.onTargetingUpdated(
          {
            countries: [],
            regions: [...selectedStates],
            cities: [...selectedCities],
            dmas: [...selectedDmas],
            postalCodes: [...selectedZips]
          },
          selectedAddresses
        )
      }
    },
    [props.onTargetingUpdated, props.locations, selectedStates, selectedCities, selectedDmas, selectedZips, selectedAddresses]
  )

  const onStateAdd = (state: RegionTarget) => {
    if (props.locations && props.onTargetingUpdated) {
      props.onTargetingUpdated(
        {
          countries: [],
          regions: [...selectedStates, state],
          cities: [...selectedCities],
          dmas: [...selectedDmas],
          postalCodes: [...selectedZips]
        },
        [...selectedAddresses]
      )
      return
    }
    setSelectedStates([...selectedStates, state])
  }
  const onStateRemove = (state: RegionTarget) => {
    if (props.locations && props.onTargetingUpdated) {
      props.onTargetingUpdated(
        {
          countries: [],
          regions: selectedStates.filter(s => s.id !== state.id),
          cities: [...selectedCities],
          dmas: [...selectedDmas],
          postalCodes: [...selectedZips]
        },
        [...selectedAddresses]
      )
      return
    }
    setSelectedStates([...selectedStates.filter(s => s.id !== state.id)])
  }

  const onCityAdd = (city: CityTarget) => {
    if (props.locations && props.onTargetingUpdated) {
      props.onTargetingUpdated(
        {
          countries: [],
          regions: [...selectedStates],
          cities: [...selectedCities, city],
          dmas: [...selectedDmas],
          postalCodes: [...selectedZips]
        },
        [...selectedAddresses]
      )
      return
    }
    setSelectedCities([...selectedCities, city])
  }
  const onCityRemove = (city: CityTarget) => {
    if (props.locations && props.onTargetingUpdated) {
      props.onTargetingUpdated(
        {
          countries: [],
          regions: [...selectedStates],
          cities: selectedCities.filter(c => city.id !== c.id),
          dmas: [...selectedDmas],
          postalCodes: [...selectedZips]
        },
        [...selectedAddresses]
      )
      return
    }
    setSelectedCities(selectedCities.filter(c => city.id !== c.id))
  }

  const onDmaAdd = (dma: DmaTarget) => {
    if (props.locations && props.onTargetingUpdated) {
      props.onTargetingUpdated(
        {
          countries: [],
          regions: [...selectedStates],
          cities: [...selectedCities],
          dmas: [...selectedDmas, dma],
          postalCodes: [...selectedZips]
        },
        [...selectedAddresses]
      )
      return
    }
    setSelectedDmas([...selectedDmas, dma])
  }
  const onDmaRemove = (dma: DmaTarget) => {
    if (props.locations && props.onTargetingUpdated) {
      props.onTargetingUpdated(
        {
          countries: [],
          regions: [...selectedStates],
          cities: [...selectedCities],
          dmas: selectedDmas.filter(d => d.id !== dma.id),
          postalCodes: [...selectedZips]
        },
        [...selectedAddresses]
      )
      return
    }
    setSelectedDmas(selectedDmas.filter(d => d.id !== dma.id))
  }

  const onZipAdd = (zip: PostalCodeTarget) => {
    if (props.locations && props.onTargetingUpdated) {
      props.onTargetingUpdated(
        {
          countries: [],
          regions: [...selectedStates],
          cities: [...selectedCities],
          dmas: [...selectedDmas],
          postalCodes: [...selectedZips, zip]
        },
        [...selectedAddresses]
      )
      return
    }
    setSelectedZips([...selectedZips, zip])
  }
  const onZipRemove = (zip: Zip) => {
    if (props.locations && props.onTargetingUpdated) {
      props.onTargetingUpdated(
        {
          countries: [],
          regions: [...selectedStates],
          cities: [...selectedCities],
          dmas: [...selectedDmas],
          postalCodes: selectedZips.filter(z => z.id.toString() !== zip.id)
        },
        [...selectedAddresses]
      )
      return
    }
    setSelectedZips(selectedZips.filter(z => z.id.toString() !== zip.id))
  }

  const onAddressAdd = (address: GeoFence) => {
    if (props.locations && props.onTargetingUpdated) {
      props.onTargetingUpdated(
        {
          countries: [],
          regions: [...selectedStates],
          cities: [...selectedCities],
          dmas: [...selectedDmas],
          postalCodes: [...selectedZips]
        },
        [...selectedAddresses, address]
      )
      return
    }
    setSelectedAddresses([...selectedAddresses, address])
  }
  const onAddressRemove = (address: GeoFence) => {
    if (props.locations && props.onTargetingUpdated) {
      props.onTargetingUpdated(
        {
          countries: [],
          regions: [...selectedStates],
          cities: [...selectedCities],
          dmas: [...selectedDmas],
          postalCodes: [...selectedZips]
        },
        selectedAddresses.filter(a => a.placeId !== address.placeId)
      )
      return
    }
    setSelectedAddresses(selectedAddresses.filter(a => a.placeId !== address.placeId))
  }

  return (
    <React.Fragment>
      {props.title && (
        <EuiTitle size='s'>
          <span>{props.title}</span>
        </EuiTitle>
      )}
      {!props.hideLocationTypes?.some(l => l === LocationType.USA) && (
        <React.Fragment>
          <EuiRadio
            id='usa'
            name='locationType'
            value='Usa'
            label={
              <EuiText size='s'>
                <strong>Entire USA</strong>
              </EuiText>
            }
            checked={props.selectedLocationType === LocationType.USA}
            onChange={() => {
              onLocationRadioSelected(LocationType.USA)
            }}
          />
          <EuiSpacer size='s' />
        </React.Fragment>
      )}
      {!props.hideLocationTypes?.some(l => l === LocationType.State) && (
        <React.Fragment>
          <EuiRadio
            id='state'
            name='locationType'
            value='State'
            label={
              <EuiText size='s'>
                <strong>Target by State</strong> (one or more U.S. States)
              </EuiText>
            }
            checked={props.selectedLocationType === LocationType.State}
            onChange={() => {
              onLocationRadioSelected(LocationType.State)
            }}
          />
          <EuiSpacer size='s' />
        </React.Fragment>
      )}
      {!props.hideLocationTypes?.some(l => l === LocationType.DMA) && (
        <React.Fragment>
          <EuiRadio
            id='dma'
            name='locationType'
            value='DMA'
            label={
              <EuiText size='s'>
                <strong>Target by Metro Area / DMA</strong> (one or more)
              </EuiText>
            }
            checked={props.selectedLocationType === LocationType.DMA}
            onChange={() => {
              onLocationRadioSelected(LocationType.DMA)
            }}
          />
          <EuiSpacer size='s' />
        </React.Fragment>
      )}
      {!props.hideLocationTypes?.some(l => l === LocationType.City) && (
        <React.Fragment>
          <EuiRadio
            id='city'
            name='locationType'
            value='City'
            label={
              <EuiText size='s'>
                <strong>Target by City</strong> (one or more)
              </EuiText>
            }
            checked={props.selectedLocationType === LocationType.City}
            onChange={() => {
              onLocationRadioSelected(LocationType.City)
            }}
          />
          <EuiSpacer size='s' />
        </React.Fragment>
      )}
      {!props.hideLocationTypes?.some(l => l === LocationType.Zip) && (
        <React.Fragment>
          <EuiRadio
            id='zip'
            name='locationType'
            value='Zip'
            label={
              <EuiText size='s'>
                <strong>Target by Zip Code</strong> (one or more)
              </EuiText>
            }
            checked={props.selectedLocationType === LocationType.Zip}
            onChange={() => {
              onLocationRadioSelected(LocationType.Zip)
            }}
          />
          <EuiSpacer size='s' />
        </React.Fragment>
      )}
      {!props.hideLocationTypes?.some(l => l === LocationType.Address) && (
        <React.Fragment>
          {' '}
          <EuiRadio
            id='address'
            name='locationType'
            value='Address'
            label={
              <EuiText size='s'>
                <strong>Target by Street Addresses</strong> (one or more)
              </EuiText>
            }
            checked={props.selectedLocationType === LocationType.Address}
            onChange={() => {
              onLocationRadioSelected(LocationType.Address)
            }}
          />
          <EuiSpacer size='s' />
        </React.Fragment>
      )}
      {!props.hideLocationTypes?.some(l => l === LocationType.Map) && (
        <React.Fragment>
          <EuiRadio
            id='map'
            name='locationType'
            value='Map'
            label={
              <EuiText size='s'>
                <strong>Target on Map</strong> (draw the areas you want to target on a map)
              </EuiText>
            }
            checked={props.selectedLocationType === LocationType.Map}
            onChange={() => {
              onLocationRadioSelected(LocationType.Map)
            }}
          />
          <EuiSpacer size='s' />
        </React.Fragment>
      )}
      <EuiSpacer size='s' />
      {props.selectedLocationType === LocationType.State && (
        <React.Fragment>
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. states below.' />
          <EuiSpacer size='s' />

          <EuiFormRow id='stateFinder' label='Search for a state' fullWidth isInvalid={false} error={''}>
            <NewRegionFinder addRegion={onStateAdd} isInvalid={false} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these states' fullWidth>
            <NewRegionTargeter
              regions={selectedStates}
              onRegionRemoved={regionId => {
                if (selectedStates.filter(s => s.id !== regionId)) {
                  onStateRemove(selectedStates.filter(s => s.id === regionId)[0])
                }
              }}
            />
          </EuiFormRow>
        </React.Fragment>
      )}
      {props.selectedLocationType === LocationType.DMA && (
        <React.Fragment>
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more metro areas / DMAs below.' />
          <EuiSpacer size='s' />

          <EuiFormRow id='dmaFinder' label='Search for a metro area' fullWidth isInvalid={false} error={''}>
            <NewDmaFinder addDma={onDmaAdd} isInvalid={false} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these metro areas' fullWidth>
            <NewDmaTargeter
              dmas={selectedDmas}
              onDmaRemoved={dmaId => {
                if (selectedDmas.filter(d => d.id !== dmaId)) {
                  onDmaRemove(selectedDmas.filter(s => s.id === dmaId)[0])
                }
              }}
            />
          </EuiFormRow>
        </React.Fragment>
      )}
      {props.selectedLocationType === LocationType.City && (
        <React.Fragment>
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. cities by name.' />
          <EuiSpacer size='s' />

          <EuiFormRow id='cityFinder' label='Search for a city' fullWidth isInvalid={false} error={''}>
            <NewCityFinder onCityClicked={onCityAdd} isInvalid={false} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these cities' fullWidth>
            <NewCityTargeter
              cities={selectedCities}
              onCityRemoved={cityId => {
                if (selectedCities.filter(d => d.id !== cityId)) {
                  onCityRemove(selectedCities.filter(s => s.id === cityId)[0])
                }
              }}
            />
          </EuiFormRow>
        </React.Fragment>
      )}
      {props.selectedLocationType === LocationType.Zip && (
        <React.Fragment>
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. zip codes.' />
          <EuiSpacer size='s' />

          <EuiFormRow id='zipFinder' label='Search for a zip code' fullWidth isInvalid={false} error={''}>
            <NewPostalCodeFinder onPostalCodeClicked={onZipAdd} isInvalid={false} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these zip codes' fullWidth>
            <ZipTargeter zips={selectedZips.map(z => ({ id: z.id.toString(), name: z.name }))} onZipRemoved={onZipRemove} />
          </EuiFormRow>
        </React.Fragment>
      )}
      {props.selectedLocationType === LocationType.Address && (
        <React.Fragment>
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. street addresses.' />
          <EuiSpacer size='s' />

          <EuiFormRow id='addressFinder' label='Search for an address' fullWidth isInvalid={false} error={''}>
            <GeoFenceFinder onGeoFenceClicked={onAddressAdd} isInvalid={false} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these addresses' fullWidth>
            <GeoFenceTargeter geoFence={selectedAddresses} onGeoFenceRemoved={onAddressRemove} />
          </EuiFormRow>
        </React.Fragment>
      )}
      {props.selectedLocationType === LocationType.Map && (
        <React.Fragment>
          <EuiShowFor sizes={['xs', 's']}>
            <EuiCallOut color='warning' title='Drawing on the map is best experienced on a computer.' iconType='alert' size='s' />
          </EuiShowFor>

          <EuiHideFor sizes={['xs', 's']}>
            <EuiCallOut size='s' iconType='mapMarker' color='success' title='Use the box, circle and/or polygon tool to draw areas on the map you want to target.' />
          </EuiHideFor>

          <EuiSpacer size='s' />
        </React.Fragment>
      )}
      {(props.selectedLocationType === LocationType.Map || props.alwaysShowMap) && <TargetingMap />}
    </React.Fragment>
  )
}
