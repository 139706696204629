import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiDescriptionList, EuiDescriptionListDescription, EuiDescriptionListTitle, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPanel, EuiTabbedContent, EuiToolTip } from '@elastic/eui'
import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { Ad, AcImage } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import { DesktopWeb, MobileInApp, MobileWeb } from 'components/previews'

const View: React.FC = () => {
  const { ad } = useSelector((state: RootState) => state)
  const [image, setImage] = useState<AcImage | null>(null)
  const [adDetails, setAdDetails] = useState<Ad | null>({
    headline: '',
    body: '',
    destinationUrl: '',
    companyName: '',
    callToAction: '',
    id: ''
  })

  useEffect(() => {
    if (ad.ad) {
      setImage({
        highQualityUrl: ad.ad.highQualityUrl,
        squareUrl: ad.ad.squareUrl,
        largeSquareUrl: ad.ad.largeSquareUrl,
        thumbnailUrl: ad.ad.thumbnailUrl,
        thumbnailHeight: null,
        thumbnailWidth: null,
        mobileUrl: ad.ad.squareUrl,
        isUploaded: false,
        isPurchased: false,
        id: ''
      })
      setAdDetails({
        headline: ad.ad.nativeDetails.headline,
        body: ad.ad.nativeDetails.body,
        companyName: ad.ad.nativeDetails.companyName,
        callToAction: ad.ad.nativeDetails.callToAction,
        destinationUrl: ad.ad.destinationUrl,
        id: ''
      })
    }
  }, [ad.ad])

  const previewTabs: Array<EuiTabbedContentTab> = [
    {
      id: 'ex1',
      name: 'Example 1',
      content: <DesktopWeb ad={adDetails} image={image} />
    },
    {
      id: 'ex2',
      name: 'Example 2',
      content: <MobileInApp ad={adDetails} image={image} />
    },
    {
      id: 'ex3',
      name: 'Example 3',
      content: <MobileWeb ad={adDetails} image={image} />
    }
  ]

  return (
    <EuiPanel grow={false}>
      <EuiFlexGroup style={{ marginTop: 20 }}>
        <EuiFlexItem grow={true}>
          <EuiDescriptionList>
            <EuiDescriptionListTitle>Headline</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>{adDetails?.headline}</EuiDescriptionListDescription>
            <EuiDescriptionListTitle>Body</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>{adDetails?.body}</EuiDescriptionListDescription>
            <EuiDescriptionListTitle>Call-to-action</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>{adDetails?.callToAction}</EuiDescriptionListDescription>
            <EuiDescriptionListTitle>Company Name</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>{adDetails?.companyName}</EuiDescriptionListDescription>
            <EuiDescriptionListTitle>Website URL</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>{adDetails?.destinationUrl}</EuiDescriptionListDescription>
            <EuiDescriptionListTitle>Image</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              <img src={ad.ad!.highQualityUrl} alt={ad.ad!.squareUrl} style={{ width: 400 }} />
            </EuiDescriptionListDescription>
          </EuiDescriptionList>
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: 420 }}>
          <EuiTabbedContent tabs={previewTabs} />
          {image?.isPurchased && (
            <EuiToolTip position='bottom' content='Upon activating this ad, the watermark will be removed from your image'>
              <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
            </EuiToolTip>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )
}

export default View
