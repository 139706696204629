import React, { FC } from 'react'

import { EuiButton, EuiCallOut, EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer } from '@elastic/eui'

import history from 'services/HistoryService'

const InitialBuildersPageForMobile: FC = () => {
  return (
    <React.Fragment>
      <EuiCallOut color='warning' title='Creating a campaign is best experienced on a computer.' iconType='alert' size='s' />

      <EuiSpacer size='l' />

      <EuiPanel grow={false}>
        <EuiFlexGroup justifyContent='spaceAround' alignItems='flexStart'>
          <EuiFlexItem grow={false}>
            <EuiEmptyPrompt
              title={<h3>Let's start a new campaign.</h3>}
              titleSize='xs'
              body='Create as many campaigns as you desire with specific targeting to grow your business!'
              actions={
                <EuiButton id='createCampaignBtn' size='s' key='createCampaign' onClick={() => history.push('/build')} fill>
                  Create Campaign
                </EuiButton>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    </React.Fragment>
  )
}

export default InitialBuildersPageForMobile
