import moment from 'moment/moment'
import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import { EuiButton, EuiInMemoryTable, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { Account } from 'api/interfaces'
import { useGetAccountsQuery } from 'api/rtkQueryApi/platform/accountsApi'
import { hideAccountsFlyout, showSuccessToast, updateAccountState } from 'app/appSlice'
import history from 'services/HistoryService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

const AccountsGrid: FC = () => {
  const dispatch = useDispatch()
  const whiteLabel = useWhiteLabel()
  const [currentPage, setCurrentPage] = useState<number>(0)
  const { isLoading, data: accounts } = useGetAccountsQuery(
    { isAgency: whiteLabel?.isAgencies() },
    {
      skip: !whiteLabel
    }
  )

  const onSelectClick = (account: Account) => {
    dispatch(updateAccountState(account))
    dispatch(showSuccessToast(`Switch account to ${account.name}`))
    dispatch(hideAccountsFlyout())
    history.push('/dashboard')
  }

  const columns = [
    {
      field: 'name',
      name: 'Client Name',
      sortable: true
    },
    ...(!whiteLabel?.isAgencies() ?? false
      ? [
          {
            field: 'selfServiceMatchingRatio',
            name: 'Matching %',
            sortable: true,
            render: (value: number) => (value ? `${value * 100}%` : 'No Matching')
          }
        ]
      : []),
    {
      name: 'Created',
      sortable: (account: Account) => moment(account.created).valueOf(),
      render: (account: Account) => moment(account.created).format('DD MMM yyyy')
    },
    {
      field: '',
      name: '',
      render: (account: Account) => <EuiButton onClick={() => onSelectClick(account)}>Select</EuiButton>,
      width: '15em'
    }
  ] as EuiBasicTableColumn<Account>[]

  const sorting = {
    sort: {
      field: 'name',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 10,
    pageSizeOptions: [10, 50, 100],
    pageIndex: currentPage
  }

  return <EuiInMemoryTable loading={isLoading} items={accounts ?? []} columns={columns} sorting={sorting} pagination={pagination} />
}

export default AccountsGrid
