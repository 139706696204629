import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCallOut, EuiCheckbox, EuiFieldNumber, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiLoadingContent, EuiRadio, EuiSelect, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { ApiError, apiPutCampaignAdvancedTargeting } from 'api/adcritterApi'
import { City, DayPartitioning, DeviceSettings, FrequencyUnit, GeoFence, InventorySettings, ThirdPartyAudience, Zip } from 'api/interfaces'
import { showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import TargetingMap from 'components/TargetingMap'
import { AudienceFinder, CityFinder, GeoFenceFinder, ZipFinder, DmaFinder, StateFinder } from 'components/finders'
import { CityTargeter, DmaTargeter, GeoFenceTargeter, StateTargeter, ZipTargeter } from 'components/targeters'
import { AudienceTargeterDetailed } from 'components/targeters/AudienceTargeterDetailed'
import { DayPartPicker, DefaultDayParts } from 'components/targeters/DayPartPicker'
import { resetTargetingMapState, setInitialTargetingMapState } from 'components/targetingMapSlice'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import { finishedLoadingCampaign } from '../campaignSlice'

interface FormValues {
  locationType: string
  states: Array<string>
  dmas: Array<string>
  cities: Array<City>
  zips: Array<Zip>
  geoFences: Array<GeoFence>
  audiences: Array<ThirdPartyAudience>
  inventorySettings: InventorySettings
  deviceSettings: DeviceSettings
  frequency: number
  frequencyUnit: FrequencyUnit
  dayParts: DayPartitioning
}

const AdvancedLocationDrawer: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount, isGoogleLoaded, isShopifyUser } = useSelector((state: RootState) => state.app)
  const { updatedGeoCircles, updatedGeoRectangles, updatedGeoPolygons } = useSelector((state: RootState) => state.targetingMap)
  const campaign = useSelector((state: RootState) => state.campaign)
  const [formValues, setFormValues] = useState<FormValues>({
    locationType: 'City',
    states: [],
    dmas: [],
    cities: [],
    zips: [],
    geoFences: [],
    audiences: [],
    inventorySettings: { targetWeb: true, targetApp: false },
    deviceSettings: { targetPc: true, targetPhone: true, targetTablet: true },
    frequency: 0,
    frequencyUnit: FrequencyUnit.hour,
    dayParts: DefaultDayParts
  })
  const whiteLabel = useWhiteLabel()

  useEffect(() => {
    dispatch(resetTargetingMapState())
  }, [])

  useEffect(() => {
    if (campaign.campaign && campaign.campaign.advancedTargeting && campaign.campaign.advancedTargeting!.advancedTargetingType === 'Location') {
      setFormValues({
        locationType: campaign.campaign.advancedTargeting!.locationType ?? 'City',
        states: campaign.campaign.advancedTargeting!.states,
        dmas: campaign.campaign.advancedTargeting!.dmas,
        cities: campaign.campaign.advancedTargeting!.cities,
        zips: campaign.campaign.advancedTargeting!.zips,
        geoFences: campaign.campaign.advancedTargeting!.geoFences,
        audiences: campaign.campaign.advancedTargeting!.audiences,
        inventorySettings: {
          targetWeb: campaign.campaign.advancedTargeting!.inventorySettings?.targetWeb ?? true,
          targetApp: campaign.campaign.advancedTargeting!.inventorySettings?.targetApp ?? false
        },
        deviceSettings: {
          targetPc: campaign.campaign.advancedTargeting!.deviceSettings?.targetPc ?? true,
          targetPhone: campaign.campaign.advancedTargeting!.deviceSettings?.targetPhone ?? true,
          targetTablet: campaign.campaign.advancedTargeting!.deviceSettings?.targetTablet ?? true
        },
        frequency: campaign.campaign.advancedTargeting!.frequency ?? 0,
        frequencyUnit: campaign.campaign.advancedTargeting!.frequencyUnit ?? FrequencyUnit.hour,
        dayParts: campaign.campaign.advancedTargeting!.dayParts ?? DefaultDayParts
      })

      dispatch(
        setInitialTargetingMapState({
          initialGeoCircles: campaign.campaign.advancedTargeting!.geoCircles,
          initialGeoRectangles: campaign.campaign.advancedTargeting!.geoRectangles,
          initialGeoPolygons: campaign.campaign.advancedTargeting!.geoPolygons,
          includeBillboards: false
        })
      )
    }
  }, [campaign.campaign])

  const advancedLocationSchema = Yup.object().shape({
    locationType: Yup.string().required(),
    states: Yup.array().when('locationType', {
      is: 'State',
      then: Yup.array().min(1, 'Please Select at Least 1 State').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    dmas: Yup.array().when('locationType', {
      is: 'DMA',
      then: Yup.array().min(1, 'Please Select at Least 1 DMA/Metro Area').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    cities: Yup.array().when('locationType', {
      is: 'City',
      then: Yup.array().min(1, 'Please Add at Least 1 City').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    zips: Yup.array().when('locationType', {
      is: 'Zip',
      then: Yup.array().min(1, 'Please Add at Least 1 Zip').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    geoFences: Yup.array().when('locationType', {
      is: 'Address',
      then: Yup.array().min(1, 'Please Add at Least 1 Address').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    audiences: Yup.array().of(Yup.object()),
    inventorySettings: Yup.object().shape({ targetWeb: Yup.boolean(), targetApp: Yup.boolean() }),
    deviceSettings: Yup.object().shape({
      targetPc: Yup.boolean(),
      targetPhone: Yup.boolean(),
      targetTablet: Yup.boolean()
    }),
    frequency: Yup.number(),
    frequencyUnit: Yup.number()
  })

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: advancedLocationSchema,
    onSubmit: (values: FormValues) => {
      apiPutCampaignAdvancedTargeting(currentAccount!.id, campaign.campaign!.id, {
        advancedTargetingType: 'Location',
        locationType: values.locationType,
        states: values.states,
        dmas: values.dmas,
        cities: values.cities.map(c => c.id),
        zips: values.zips.map(z => z.id),
        geoFences: values.geoFences,
        geoCircles: updatedGeoCircles,
        geoRectangles: updatedGeoRectangles,
        geoPolygons: updatedGeoPolygons,
        audiences: values.audiences,
        audienceType: null,
        ageRanges: [],
        genders: [],
        householdIncomes: [],
        uploadType: null,
        uploadedData: [],
        retargetingType: null,
        urlParts: [],
        keywords: [],
        negativeKeywords: [],
        inventorySettings: whiteLabel?.isAgencies() ? values.inventorySettings : null,
        deviceSettings: whiteLabel?.isAgencies() ? values.deviceSettings : null,
        frequency: whiteLabel?.isAgencies() ? values.frequency : null,
        frequencyUnit: whiteLabel?.isAgencies() ? values.frequencyUnit : null,
        dayParts: values.dayParts
      })
        .then(response => {
          dispatch(showSuccessToast('Saved Location Based targeting'))
          dispatch(finishedLoadingCampaign(response.data))
          formik.setSubmitting(false)
          formik.setStatus(null)
        })
        .catch(response => {
          formik.setSubmitting(false)
          response.errors.forEach(function (error: ApiError) {
            formik.setFieldError(error.name, error.message)
          })
        })
    }
  })

  const frequencyOptions = [
    { value: FrequencyUnit.hour, text: 'Hour' },
    { value: FrequencyUnit.day, text: 'Day' },
    { value: FrequencyUnit.week, text: 'Week' }
  ]

  let content

  if (campaign.isLoadingCampaign) {
    content = <EuiLoadingContent lines={3} />
  } else {
    content = (
      <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
        {isShopifyUser && (
          <React.Fragment>
            <EuiRadio
              id='usa'
              name='locationType'
              value='USA'
              label={
                <EuiText size='s'>
                  <strong>Targeting the USA</strong>
                </EuiText>
              }
              checked={formik.values.locationType === 'USA'}
              onChange={() => {
                formik.setFieldValue('locationType', 'USA', true)
              }}
            />
            <EuiSpacer size='s' />
          </React.Fragment>
        )}
        <EuiRadio
          id='state'
          name='locationType'
          value='State'
          label={
            <EuiText size='s'>
              <strong>Targeting Using State</strong> (one or more U.S. States)
            </EuiText>
          }
          checked={formik.values.locationType === 'State'}
          onChange={() => {
            formik.setFieldValue('locationType', 'State', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='dma'
          name='locationType'
          value='DMA'
          label={
            <EuiText size='s'>
              <strong>Targeting Using DMA/Metro Area</strong> (one or more DMAs)
            </EuiText>
          }
          checked={formik.values.locationType === 'DMA'}
          onChange={() => {
            formik.setFieldValue('locationType', 'DMA', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='city'
          name='locationType'
          value='City'
          label={
            <EuiText size='s'>
              <strong>Targeting Using City</strong> (one or more City names)
            </EuiText>
          }
          checked={formik.values.locationType === 'City'}
          onChange={() => {
            formik.setFieldValue('locationType', 'City', true)
          }}
        />
        {!isShopifyUser && (
          <React.Fragment>
            <EuiSpacer size='s' />
            <EuiRadio
              id='zip'
              name='locationType'
              value='Zip'
              label={
                <EuiText size='s'>
                  <strong>Targeting Using Zip Code</strong> (one or more Zip Codes)
                </EuiText>
              }
              checked={formik.values.locationType === 'Zip'}
              onChange={() => {
                formik.setFieldValue('locationType', 'Zip', true)
              }}
            />
          </React.Fragment>
        )}
        {!isShopifyUser && (
          <React.Fragment>
            <EuiSpacer size='s' />
            <EuiRadio
              id='address'
              name='locationType'
              value='Address'
              label={
                <EuiText size='s'>
                  <strong>Targeting Addresses</strong> (one or more Addresses)
                </EuiText>
              }
              checked={formik.values.locationType === 'Address'}
              onChange={() => {
                formik.setFieldValue('locationType', 'Address', true)
              }}
            />
          </React.Fragment>
        )}
        {!isShopifyUser && (
          <React.Fragment>
            <EuiSpacer size='s' />
            <EuiRadio
              id='map'
              name='locationType'
              value='Map'
              label={
                <EuiText size='s'>
                  <strong>Targeting Map</strong> (draw the areas you want to target on a Map)
                </EuiText>
              }
              checked={formik.values.locationType === 'Map'}
              onChange={() => {
                formik.setFieldValue('locationType', 'Map', true)
              }}
            />
          </React.Fragment>
        )}

        <EuiSpacer />

        <div hidden={formik.values.locationType !== 'State'}>
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can select U.S. States below, multiple States can be targeted at once.' />
          <EuiSpacer size='s' />

          <EuiFormRow label='Search for a State' fullWidth isInvalid={!!formik.errors.states} error={formik.errors.states}>
            <StateFinder addState={stateToAdd => formik.setFieldValue('states', [...formik.values.states, stateToAdd])} isInvalid={!!formik.errors.states} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these States:' fullWidth>
            <StateTargeter
              states={formik.values.states}
              onStateRemoved={stateToRemove =>
                formik.setFieldValue(
                  'states',
                  formik.values.states.filter(x => x !== stateToRemove),
                  true
                )
              }
            />
          </EuiFormRow>
        </div>

        <div hidden={formik.values.locationType !== 'DMA'}>
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can select a DMA/Metro Area(s) below, multiple DMAs can be targeted at once.' />
          <EuiSpacer size='s' />

          <EuiFormRow label='Search for a DMA' fullWidth isInvalid={!!formik.errors.dmas} error={formik.errors.dmas}>
            <DmaFinder addDma={dmaToAdd => formik.setFieldValue('dmas', [...formik.values.dmas, dmaToAdd])} isInvalid={!!formik.errors.dmas} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these DMAs:' fullWidth>
            <DmaTargeter
              dmas={formik.values.dmas}
              onDmaRemoved={dmaToRemove =>
                formik.setFieldValue(
                  'dmas',
                  formik.values.dmas.filter(x => x !== dmaToRemove),
                  true
                )
              }
            />
          </EuiFormRow>
        </div>

        <div hidden={formik.values.locationType !== 'City'}>
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can search for U.S. Cities below, multiple Cities can be targeted at once.' />
          <EuiSpacer size='s' />

          <EuiFormRow label='Search for a city' fullWidth isInvalid={!!formik.errors.cities} error={formik.errors.cities}>
            <CityFinder onCityClicked={city => formik.setFieldValue('cities', [...formik.values.cities, city])} isInvalid={!!formik.errors.cities} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these cities:' fullWidth>
            <CityTargeter
              cities={formik.values.cities}
              onCityRemoved={city => {
                formik.setFieldValue(
                  'cities',
                  formik.values.cities.filter(c => c.id !== city.id)
                )
              }}
            />
          </EuiFormRow>
        </div>

        <div hidden={formik.values.locationType !== 'Zip'}>
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can search for U.S. Zip Codes below, multiple Zip Codes can be targeted at once.' />
          <EuiSpacer size='s' />

          <EuiFormRow label='Search for a zip code' fullWidth isInvalid={!!formik.errors.zips} error={formik.errors.zips}>
            <ZipFinder onZipClicked={zip => formik.setFieldValue('zips', [...formik.values.zips, zip])} isInvalid={!!formik.errors.zips} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these zip codes:' fullWidth>
            <ZipTargeter
              zips={formik.values.zips}
              onZipRemoved={zip => {
                formik.setFieldValue(
                  'zips',
                  formik.values.zips.filter(z => z.id !== zip.id)
                )
              }}
            />
          </EuiFormRow>
        </div>

        <div hidden={formik.values.locationType !== 'Address'}>
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can search for Addresses below, multiple Addresses can be targeted at once.' />
          <EuiSpacer size='s' />

          <EuiFormRow label='Search for an address.' fullWidth isInvalid={!!formik.errors.geoFences} error={formik.errors.geoFences}>
            <GeoFenceFinder onGeoFenceClicked={geoFence => formik.setFieldValue('geoFences', [...formik.values.geoFences, geoFence])} isInvalid={!!formik.errors.geoFences} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these addresses:' fullWidth>
            <GeoFenceTargeter
              geoFence={formik.values.geoFences}
              onGeoFenceRemoved={geoFence => {
                formik.setFieldValue(
                  'geoFences',
                  formik.values.geoFences.filter(g => g.placeId !== geoFence.placeId)
                )
              }}
            />
          </EuiFormRow>
        </div>

        <div hidden={formik.values.locationType !== 'Map'}>
          <EuiCallOut size='s' iconType='mapMarker' color='success' title='If you do not draw an area to target, the entire USA will be targeted by default.' />
          <EuiSpacer size='s' />

          {formik.values.locationType === 'Map' && isGoogleLoaded && <TargetingMap />}
        </div>

        <EuiSpacer size='xl' />
        <EuiTitle size='xs'>
          <h2>Traffic Prioritization</h2>
        </EuiTitle>
        <EuiSpacer size='s' />

        <EuiFormRow label='Search for individual characteristics to refine your ideal audience.' fullWidth>
          <AudienceFinder onAudienceClicked={audience => formik.setFieldValue('audiences', [...formik.values.audiences, audience])} isInvalid={false} />
        </EuiFormRow>
        <EuiFormRow label='Your campaign will prioritize individuals in any of these categories:' fullWidth>
          <AudienceTargeterDetailed
            audiences={formik.values.audiences}
            onAudienceRemoved={audience => {
              formik.setFieldValue(
                'audiences',
                formik.values.audiences.filter(a => a.id !== audience.id)
              )
            }}
          />
        </EuiFormRow>

        {whiteLabel?.isAgencies() && (
          <React.Fragment>
            <EuiSpacer size='xl' />
            <EuiTitle size='xs'>
              <span>Advanced Targeting Options</span>
            </EuiTitle>
            <EuiSpacer size='s' />
            <EuiFormRow fullWidth label={'Select which hours of the day your ad will run. (Uses the audience time zone)'}>
              <DayPartPicker
                dayParts={formik.values.dayParts}
                onSelectedDayPartsChange={days => {
                  formik.setFieldValue('dayParts', days)
                }}
              />
            </EuiFormRow>
            <EuiSpacer size='s' />
            <EuiFormRow label='Inventory'>
              <EuiFlexGrid direction='column'>
                <EuiFlexItem>
                  <EuiCheckbox
                    id='targetWebCheck'
                    checked={formik.values.inventorySettings.targetWeb}
                    label='Target Web'
                    onChange={e => {
                      formik.setFieldValue('inventorySettings.targetWeb', e.target.checked)
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiCheckbox
                    id='targetAppCheck'
                    checked={formik.values.inventorySettings.targetApp}
                    label='Target Apps'
                    onChange={e => {
                      formik.setFieldValue('inventorySettings.targetApp', e.target.checked)
                    }}
                  />
                </EuiFlexItem>
              </EuiFlexGrid>
            </EuiFormRow>
            <EuiFormRow label='Device'>
              <EuiFlexGrid direction='column'>
                <EuiFlexItem>
                  <EuiCheckbox
                    id='targetPcCheck'
                    checked={formik.values.deviceSettings.targetPc}
                    label='Target PC'
                    onChange={e => {
                      formik.setFieldValue('deviceSettings.targetPc', e.target.checked)
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiCheckbox
                    id='targetPhoneCheck'
                    checked={formik.values.deviceSettings.targetPhone}
                    label='Target Phone'
                    onChange={e => {
                      formik.setFieldValue('deviceSettings.targetPhone', e.target.checked)
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiCheckbox
                    id='targetTabletCheck'
                    checked={formik.values.deviceSettings.targetTablet}
                    label='Target Tablet'
                    onChange={e => {
                      formik.setFieldValue('deviceSettings.targetTablet', e.target.checked)
                    }}
                  />
                </EuiFlexItem>
              </EuiFlexGrid>
            </EuiFormRow>
            <EuiFormRow label='Frequency Capping (0 is unlimited)'>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFieldNumber
                    name='frequency'
                    value={formik.values.frequency!}
                    onChange={e => {
                      formik.setFieldValue('frequency', Number(e.target.value))
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiText size='xs'>per</EuiText>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiSelect
                    options={frequencyOptions}
                    value={formik.values.frequencyUnit}
                    onChange={e => {
                      formik.setFieldValue('frequencyUnit', e.target.value)
                    }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFormRow>
          </React.Fragment>
        )}
        <EuiSpacer />

        <EuiButton id='save' fill type='submit' isLoading={formik.isSubmitting}>
          Save
        </EuiButton>
      </EuiForm>
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>{content}</EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: 260 }}>
          <EuiText size='xs'>
            <h3>Best Practices</h3>
            <h5>Bulk Targeting by Zip/Address</h5>
            <p>If you are wanting to target a long list of Zip Codes or Street Addresses, you may want to consider using the Upload an Audience option instead of entering them all by hand. Either will work just fine, but the bulk upload feature may save you time.</p>
            <h5>About Traffic Prioritization</h5>
            <p>{whiteLabel?.getName()} uses the characteristics you identify in Traffic Prioritization to favor people in the targeted location(s) who match your desired audience. For example, you could enter ‘golf’ to prioritize people in the specified geographic area interested in golfing.</p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default AdvancedLocationDrawer
