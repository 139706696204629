import { CampaignType } from 'utils/CampaignType'

export const getMinPrice = (campaignType: CampaignType) => {
  switch (campaignType) {
    case CampaignType.Internet:
      return 300
    case CampaignType.TV:
      return 700
    case CampaignType.Billboard:
      return 600
  }
  return 300
}
