import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { CampaignCreateStep, CreativeBuilderType, CreativeType } from 'workflows/CampaignCreateWorkflow'

import { EuiButton, EuiCallOut, EuiCard, EuiCheckbox, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHideFor, EuiHorizontalRule, EuiLink, EuiSelect, EuiShowFor, EuiSpacer, EuiSwitch, EuiText, EuiTitle, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'

import { apiGetBusinessType, apiPutAccountAddress, apiPutAccountBusinessType } from 'api/adcritterApi'
import { BusinessType } from 'api/interfaces'
import { SmartTargeterAreaType, SmartTargeting } from 'api/interfaces/CampaignTargeting'
import { updateAccountState } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { BusinessTypeFinder } from 'components/finders/BusinessTypeFinder'
import { CompetitorTargeter } from 'components/targeters/CompetitorTargeter'
import { WalkInAddressesTargeter } from 'components/targeters/WalkInAddressesTargeter'
import { setCampaignCreateStep, setSmartTargeting } from 'features/builders/CampaignCreateSlice'
import { ICampaignBuilderWorkflowStep } from 'features/builders/createCampaign/creativeBuilder/CreativeBuilderWorkflowManager'

export const TargetSmartPage: React.FC<ICampaignBuilderWorkflowStep> = props => {
  const dispatch = useDispatch()
  const {
    campaignTargeting: { smartTargeting },
    businessInfo,
    creativeInfo,
    createWorkflow
  } = useSelector((state: RootState) => state.campaignCreateWorkflow)
  const [targetingInfo, setTargetingInfo] = useState<SmartTargeting>({
    competitors: [],
    competitorTargeting: false,
    targetArea: SmartTargeterAreaType.Mile5,
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    walkInAddresses: [],
    walkInTargeting: false,
    walkInUsePrimary: true
  })

  const { currentAccount } = useSelector((state: RootState) => state.app)
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const [getAddress, setGetAddress] = useState(true)
  const [getStateOnly, setGetStateOnly] = useState(false)
  const [showBusinessType, setShowBusinessType] = useState(false)
  const [businessTypeError, setBusinessTypeError] = useState(false)
  const [chosenBusinessType, setChosenBusinessType] = useState<BusinessType>()

  useEffect(() => {
    if (smartTargeting) {
      setTargetingInfo({ ...smartTargeting, targetArea: !smartTargeting.targetArea ? SmartTargeterAreaType.Mile20 : smartTargeting.targetArea })
    }
  }, [smartTargeting])

  useEffect(() => {
    if (createWorkflow.creativeBuilderWorkflow.creativeBuilderType === CreativeBuilderType.Upload || createWorkflow.creativeBuilderWorkflow.creativeBuilderType === CreativeBuilderType.AdBuilder) {
      setShowBusinessType(true)
    } else {
      setShowBusinessType(false)
    }
  }, [createWorkflow])

  useEffect(() => {
    if (businessInfo.businessTypeId) {
      apiGetBusinessType(businessInfo.businessTypeId).then(result => setChosenBusinessType(result.data))
    } else {
      if (currentAccount?.businessTypeId) {
        apiGetBusinessType(currentAccount.businessTypeId).then(result => setChosenBusinessType(result.data))
      }
    }
  }, [businessInfo, currentAccount])

  const returnTargetingChooseStep = () => {
    props.onBack?.()
  }

  const targetAreaOptions: Array<EuiSelectOption> = [
    {
      value: SmartTargeterAreaType.Mile5,
      text: 'Up to 5 miles'
    },
    {
      value: SmartTargeterAreaType.Mile20,
      text: 'Up to 20 miles'
    },
    {
      value: SmartTargeterAreaType.Mile50,
      text: 'Up to 50 miles'
    },
    {
      value: SmartTargeterAreaType.State,
      text: 'Statewide'
    },
    {
      value: SmartTargeterAreaType.Country,
      text: 'Entire USA'
    }
  ]

  const onTargetAreaChanged = (v: SmartTargeterAreaType) => {
    setTargetingInfo({ ...targetingInfo, targetArea: v })
    setGetAddress(v === SmartTargeterAreaType.Mile5 || v === SmartTargeterAreaType.Mile20 || v === SmartTargeterAreaType.Mile50)
    setGetStateOnly(v === SmartTargeterAreaType.State)
  }

  const chooseBusinessType = (businessType: BusinessType | null) => {
    if (businessType) {
      setChosenBusinessType(businessType)
      setBusinessTypeError(false)
    }
  }

  const onNextClick = () => {
    if ((!currentAccount!.businessTypeId || currentAccount!.businessTypeId === '') && chosenBusinessType) {
      apiPutAccountBusinessType(currentAccount!.id, chosenBusinessType!.id).then(result => dispatch(updateAccountState(result.data)))
    }
    if (!currentAccount!.streetAddress || currentAccount!.streetAddress === '') {
      apiPutAccountAddress(currentAccount!.id, targetingInfo.streetAddress, targetingInfo.city, targetingInfo.state, targetingInfo.zip, 'usa').then(result => dispatch(updateAccountState(result.data)))
    }
    dispatch(setSmartTargeting(targetingInfo))
    dispatch(setCampaignCreateStep(CampaignCreateStep.Deliver))
  }

  const isValid = () => {
    if (targetingInfo.walkInTargeting && targetingInfo.walkInAddresses.length === 0 && !targetingInfo.walkInUsePrimary) {
      return false
    }
    if (targetingInfo.competitorTargeting && targetingInfo.competitors.length === 0) {
      return false
    }
    if (targetingInfo.targetArea !== SmartTargeterAreaType.State && targetingInfo.targetArea !== SmartTargeterAreaType.Country && targetingInfo.targetArea !== SmartTargeterAreaType.Mile5 && targetingInfo.targetArea !== SmartTargeterAreaType.Mile20 && targetingInfo.targetArea !== SmartTargeterAreaType.Mile50) {
      return false
    }
    if (targetingInfo.targetArea === SmartTargeterAreaType.State && targetingInfo.state === '') {
      return false
    }
    if ((targetingInfo.targetArea === SmartTargeterAreaType.Mile5 || targetingInfo.targetArea === SmartTargeterAreaType.Mile20 || targetingInfo.targetArea === SmartTargeterAreaType.Mile50) && (targetingInfo.streetAddress === '' || targetingInfo.city === '' || targetingInfo.state === '' || targetingInfo.zip === '')) {
      return false
    }
    return true
  }

  const displayDetails = creativeInfo.displayDetails?.find(d => d.creativeId === creativeInfo.creativeId)

  return (
    <React.Fragment>
      <Helmet>
        <title>Smart Targeter</title>
      </Helmet>
      <EuiFlexGroup>
        <EuiFlexItem>
          {showBusinessType && (
            <React.Fragment>
              <EuiTitle size='s'>
                <h3>Smart Targeter</h3>
              </EuiTitle>
              <EuiSpacer />
              <EuiCallOut title="Let's get started with Smart Targeter" iconType='user' color='success'>
                <p>Select your business type to use professionally designed targeting for your campaign.</p>
              </EuiCallOut>
              <EuiSpacer />
              <EuiFormRow label='Business Type' fullWidth isInvalid={businessTypeError}>
                <React.Fragment>
                  {chosenBusinessType && (
                    <React.Fragment>
                      <EuiCard
                        display='primary'
                        layout='horizontal'
                        paddingSize='s'
                        titleSize='xs'
                        icon={
                          <div>
                            <img alt='' src={chosenBusinessType.imageUrl} />
                          </div>
                        }
                        title={chosenBusinessType.name}
                        description={chosenBusinessType.businessTypeCategories.join(', ')}
                      />
                      <EuiSpacer size='s' />
                      <EuiLink color='success' onClick={() => setChosenBusinessType(undefined)}>
                        Clear selected business type
                      </EuiLink>
                    </React.Fragment>
                  )}
                  {!chosenBusinessType && <BusinessTypeFinder onBusinessTypeClicked={chooseBusinessType} isInvalid={businessTypeError} />}
                </React.Fragment>
              </EuiFormRow>
            </React.Fragment>
          )}

          {chosenBusinessType && (
            <React.Fragment>
              <EuiSpacer />
              <EuiFlexGroup>
                {creativeInfo.creativeType === CreativeType.Display && displayDetails && (
                  <EuiFlexItem grow={false}>
                    <React.Fragment>
                      <EuiSpacer size='s' />
                      <div
                        style={{
                          width: 82,
                          height: (82 / (displayDetails.displayWidth || 1)) * (displayDetails.displayHeight || 0),
                          borderRadius: 8,
                          overflow: 'hidden'
                        }}>
                        <img src={displayDetails.displayImageUrl} alt={'display ad'} width={82} />
                      </div>
                      <EuiSpacer size={'s'} />
                    </React.Fragment>
                  </EuiFlexItem>
                )}
                <EuiFlexItem>
                  <EuiText size='s'>
                    <h3>Smart Targeter</h3>
                  </EuiText>
                  <EuiText size='s'>
                    <p>AdCritter will target your ads to your most likely customers based on your selected business type. We use machine learning to continually adjust and target individuals using their interest and demographic data, browsing history, search history, and past purchase behavior.</p>
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            </React.Fragment>
          )}

          <EuiHorizontalRule />

          <React.Fragment>
            <EuiText size='m'>
              <h4>Target Area</h4>
            </EuiText>
            <EuiText size='s'>
              <p>How large of an area do you want to reach?</p>
            </EuiText>
            <EuiSpacer size='s' />
            <EuiSelect name='targetArea' options={targetAreaOptions} value={targetingInfo.targetArea} onChange={e => onTargetAreaChanged(e.target.value as SmartTargeterAreaType)} fullWidth hasNoInitialSelection={true} />
            <EuiSpacer size='s' />
            <div hidden={!getAddress} style={{ marginLeft: 53 }}>
              <EuiSpacer size='s' />
              <EuiText size='m'>
                <h4>From this Location</h4>
              </EuiText>
              <EuiSpacer size='s' />
              <EuiFormRow label='Street Address' fullWidth>
                <EuiFieldText
                  name='streetAddress'
                  value={targetingInfo.streetAddress}
                  onChange={e => {
                    setTargetingInfo({ ...targetingInfo, streetAddress: e.target.value })
                  }}
                  placeholder=''
                  fullWidth
                />
              </EuiFormRow>
              <EuiSpacer size='s' />

              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow label='City' fullWidth>
                    <EuiFieldText
                      name='city'
                      value={targetingInfo.city}
                      onChange={e => {
                        setTargetingInfo({ ...targetingInfo, city: e.target.value })
                      }}
                      placeholder=''
                      fullWidth
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow id='targetAreaState' label='State' fullWidth>
                    <EuiSelect
                      name='state'
                      isLoading={dictionary.isLoadingStates}
                      options={dictionary.states.map(
                        s =>
                          ({
                            value: s.code,
                            label: s.name,
                            text: s.name
                          } as EuiSelectOption)
                      )}
                      value={targetingInfo.state}
                      onChange={value => {
                        setTargetingInfo({ ...targetingInfo, state: value.target.value })
                      }}
                      fullWidth
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label='Zip' fullWidth>
                    <EuiFieldText
                      name='zip'
                      value={targetingInfo.zip}
                      onChange={e => {
                        setTargetingInfo({ ...targetingInfo, zip: e.target.value })
                      }}
                      placeholder=''
                      fullWidth
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
            <div hidden={!getStateOnly} id='hiddenSatewideDiv'>
              <EuiSpacer size='m' />
              <EuiFormRow id='statewide' label='State' fullWidth>
                <EuiSelect
                  name='state'
                  isLoading={dictionary.isLoadingStates}
                  options={dictionary.states.map(
                    s =>
                      ({
                        value: s.code,
                        label: s.name,
                        text: s.name
                      } as EuiSelectOption)
                  )}
                  value={targetingInfo.state}
                  onChange={e => {
                    setTargetingInfo({ ...targetingInfo, state: e.target.value })
                  }}
                  fullWidth
                />
              </EuiFormRow>
            </div>
            <EuiHorizontalRule />
            <EuiTitle size='xs'>
              <h6 id='walkInTarget'>
                <EuiSwitch
                  label=''
                  checked={targetingInfo.walkInTargeting}
                  onChange={e => {
                    setTargetingInfo({ ...targetingInfo, walkInTargeting: e.target.checked, walkInAddresses: !e.target.checked ? [] : targetingInfo.walkInAddresses })
                  }}
                />
                Walk-in Customer Targeting
              </h6>
            </EuiTitle>
            <div style={{ paddingLeft: 53 }}>
              <EuiText size='s'>
                <p>Targets individuals who’ve visited your location within the past sixty days.</p>
              </EuiText>
            </div>
            <EuiSpacer size='s' />
            <div hidden={!targetingInfo.walkInTargeting} style={{ paddingLeft: 53 }}>
              <div hidden={!getAddress}>
                <EuiSpacer size='s' />
                <EuiCheckbox id={htmlIdGenerator()()} label={<React.Fragment>Target people who visit the above address</React.Fragment>} checked={targetingInfo.walkInUsePrimary} onChange={e => setTargetingInfo({ ...targetingInfo, walkInUsePrimary: e.target.checked })} />
              </div>
              <EuiSpacer size='s' />
              <EuiCallOut color='danger' hidden={targetingInfo.walkInAddresses.length !== 0 || targetingInfo.walkInUsePrimary} size='s' title={'Please Enter at Least 1 Walk-in Address, or Disable Walk-in Customer Targeting'} iconType='listAdd' style={{ marginBottom: 12 }} />
              <WalkInAddressesTargeter smartTargeting={targetingInfo} setSmartTargeting={setTargetingInfo} />
            </div>
            <EuiSpacer size='xl' />
            <EuiTitle size='xs'>
              <h6 id='competitorTarget'>
                <EuiSwitch
                  label=''
                  checked={targetingInfo.competitorTargeting ?? false}
                  onChange={e => {
                    setTargetingInfo({ ...targetingInfo, competitorTargeting: e.target.checked, competitors: !e.target.checked ? [] : targetingInfo.competitors })
                  }}
                />
                Competitor Location Targeting
              </h6>
            </EuiTitle>
            <div style={{ paddingLeft: 53 }}>
              <EuiText size='s'>
                <p>Targets individuals who’ve been to your competitor’s location(s) within the past sixty days.</p>
              </EuiText>
            </div>
            <EuiSpacer size='s' />
            <div hidden={!targetingInfo.competitorTargeting} style={{ paddingLeft: 55 }}>
              <EuiCallOut color='danger' hidden={targetingInfo.competitors.length !== 0} size='s' title={'Please Enter at Least 1 Competitor Address, or Disable Competitor Location Targeting'} iconType='listAdd' style={{ marginBottom: 12 }} />
              <CompetitorTargeter setSmartTargeting={setTargetingInfo} smartTargeting={targetingInfo} />
            </div>
          </React.Fragment>
          <EuiSpacer />
          {!isValid() && (
            <React.Fragment>
              <EuiText color='warning' size={'xs'}>
                Pleas finish defining your target area.
              </EuiText>
              <EuiSpacer size={'xs'} />
            </React.Fragment>
          )}
          <EuiShowFor sizes={['xs', 's']}>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButton id='finalize' fill onClick={onNextClick} disabled={!isValid()}>
                  Continue
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton id='back' fill type='button' color='text' onClick={returnTargetingChooseStep}>
                  Back
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiShowFor>

          <EuiHideFor sizes={['xs', 's']}>
            <EuiFlexGroup responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiButton id='back' fill type='button' color='text' onClick={returnTargetingChooseStep} iconType='arrowLeft' iconSide='left'>
                  Back
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton id='finalize' fill onClick={onNextClick} iconType='arrowRight' iconSide='right' disabled={!isValid()}>
                  Continue
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiHideFor>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}
