import { useEffect, useState } from 'react'

import config from './config'

export const useMicrosoftAdvertisingScript = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)
  const [isScriptLoadSucceed, setIsScriptLoadSucceed] = useState(false)

  useEffect(() => {
    if (isScriptLoaded || !config.microsoftAdvertising.TAG_ID) {
      return
    }

    const script = document.createElement('script')

    script.id = 'msadvertising-snippet'
    script.innerHTML = `(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"${config.microsoftAdvertising.TAG_ID}"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`
    script.onload = () => {
      setIsScriptLoaded(true)
      setIsScriptLoadSucceed(true)
    }

    document.body.appendChild(script)
  }, [isScriptLoaded])

  return isScriptLoadSucceed
}
