import React from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { BuilderCampaignType, CreativeBuilderStep } from 'workflows/CampaignCreateWorkflow'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from '@elastic/eui'

import { AcImage, TvCommercial } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import InternetSuggestedAds from 'components/suggestedAds/InternetSuggestedAds'
import { TvSuggestedAds } from 'components/suggestedAds/TvSuggestedAds'
import { setCreativeBuilderStep } from 'features/builders/CampaignCreateSlice'
import { ICampaignBuilderWorkflowStep } from 'features/builders/createCampaign/creativeBuilder/CreativeBuilderWorkflowManager'
import { useCampaignBuilderActions } from 'features/builders/createCampaign/useCampaignBuilderActions'

export const AdSuggestionsPage: React.FC<ICampaignBuilderWorkflowStep> = props => {
  const { suggestedNativeSelected, suggestedTvSelected } = useCampaignBuilderActions()
  const dispatch = useDispatch()
  const { campaignType, businessInfo } = useSelector((state: RootState) => state.campaignCreateWorkflow)

  const onInternetSelected = (image: AcImage, headline: string, body: string) => {
    suggestedNativeSelected(businessInfo, image, headline, body)
  }

  const onTvAdSelected = (tvCommercial: TvCommercial) => {
    suggestedTvSelected(tvCommercial)
  }

  const gotoCreate = () => {
    dispatch(setCreativeBuilderStep(CreativeBuilderStep.AdBuilder))
  }

  const onBackClick = () => {
    props.onBack?.()
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Suggested Ads</title>
      </Helmet>
      <EuiFlexGroup responsive={false}>
        <EuiFlexItem>
          <EuiTitle size='s'>
            <h3>Suggested Ads</h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton onClick={gotoCreate} fullWidth={false}>
            Switch to Ad Builder
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      {businessInfo.businessTypeId && (campaignType === BuilderCampaignType.Native || campaignType === BuilderCampaignType.Display) && <InternetSuggestedAds businessTypeId={businessInfo.businessTypeId} proceed={onInternetSelected} companyName={businessInfo.companyName} cityName={businessInfo.cityName} />}
      {businessInfo.businessTypeId && campaignType === BuilderCampaignType.TV && <TvSuggestedAds businessTypeId={businessInfo.businessTypeId} onSelected={onTvAdSelected} />}
      <EuiSpacer />
      <EuiButton id='back' fill type='button' color='text' onClick={onBackClick} iconType='arrowLeft' iconSide='left'>
        Back
      </EuiButton>
    </React.Fragment>
  )
}
