import React, { FC } from 'react'

import { EuiButton, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiText } from '@elastic/eui'

export interface LastCreativePausedErrorModalProps {
  isModalVisible: boolean
  closeModal: () => void
}

const LastCreativePausedErrorModal: FC<LastCreativePausedErrorModalProps> = ({ isModalVisible, closeModal }) => {
  let modal

  if (isModalVisible) {
    modal = (
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Unable to pause ad</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiText size='m'>Campaigns must have at least one active ad. If you would like to pause the campaign, this can be done on the campaigns page.</EuiText>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButton onClick={closeModal} fill>
            Close
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    )
  }

  return <React.Fragment>{modal}</React.Fragment>
}

export default LastCreativePausedErrorModal
