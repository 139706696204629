import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiLoadingChart, EuiSpacer } from '@elastic/eui'

import { useGetMobileDashboardAnalyticsQuery } from 'api/rtkQueryApi/platform/mobileDashboardApi'
import { RootState } from 'app/rootReducer'
import MobileDashboardNoBundles from 'features/mobileDashboard/MobileDashboardNoBundles'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

const MobileDashboardPage: FC = () => {
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const whiteLabel = useWhiteLabel()

  const { isLoading: isLoadingMobileDashboardAnalytics, data: mobileDashboardAnalytics } = useGetMobileDashboardAnalyticsQuery(
    {
      accountId: currentAccount?.id ?? '',
      isAgency: whiteLabel?.isAgencies() ?? false
    },
    { skip: !currentAccount || !whiteLabel }
  )

  if (isLoadingMobileDashboardAnalytics || !mobileDashboardAnalytics) {
    return (
      <React.Fragment>
        <EuiCallOut color='warning' title='This dashboard is best viewed on a computer.' iconType='alert' size='s' />

        <EuiSpacer size='l' />

        <EuiFlexGroup justifyContent='center' responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiLoadingChart size='xl' />
          </EuiFlexItem>
        </EuiFlexGroup>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <EuiCallOut color='warning' title='This dashboard is best viewed on a computer.' iconType='alert' size='s' />

      <EuiSpacer size='l' />

      <MobileDashboardNoBundles mobileDashboardAnalytics={mobileDashboardAnalytics} />
    </React.Fragment>
  )
}

export default MobileDashboardPage
