import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiFieldNumber, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiSpacer, EuiTitle } from '@elastic/eui'

import { CampaignActiveStatus } from 'api/interfaces'
import { showSuccessToast, showWarningToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { PaymentMethodPicker, usePaymentMethodPickerState } from 'components/payments/PaymentMethodPicker'
import history from 'services/HistoryService'
import { CampaignType } from 'utils/CampaignType'

import { createCampaign, resetBuilder, setNameAndBudget } from './builderInternetVistaSlice'

interface FormValues {
  campaignName: string
  budget: number
}

const ActivateStep: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount, currentOrganization } = useSelector((state: RootState) => state.app)
  const builder = useSelector((state: RootState) => state.builderInternetVista)
  const { campaigns } = useSelector((state: RootState) => state.campaigns)
  const [{ selectedPaymentMethodId }, setPaymentMethodPickerState] = usePaymentMethodPickerState()
  const [initialValues, setInitialValues] = useState<FormValues>({
    campaignName: '',
    budget: 100
  })
  const [isSavedForLater, setIsSaveForLater] = useState(false)
  const [isActivating, setIsActivating] = useState(false)
  const [isSavingForLater, setIsSavingForLater] = useState(false)

  useEffect(() => {
    if (currentAccount && currentOrganization && campaigns) {
      const campaignsCount = campaigns.filter(c => c.targetingTemplate === 'Vista').length
      setInitialValues({
        campaignName: builder?.name ?? `Internet Campaign ${campaignsCount + 1}`,
        budget: builder?.budget ?? 100
      })
    }
  }, [currentAccount, currentOrganization, builder, builder.vistaTargeting, campaigns, dispatch])

  useEffect(() => {
    if (builder.result) {
      if (builder.result.campaignActiveStatus === CampaignActiveStatus.Inactive) {
        if (!isSavedForLater) {
          dispatch(showWarningToast('Successfully saved your campaign, but there was a problem with your payment'))
        } else {
          dispatch(showSuccessToast('Successfully saved your campaign'))
        }
        history.push(`/campaigns/edit/${builder.result.campaignId}/details`)
      }
      if (builder.result.campaignActiveStatus === CampaignActiveStatus.Active) {
        dispatch(showSuccessToast('Successfully activated your campaign'))
        history.push(`/build/finished/${builder.result.campaignId}/${builder.result.campaignType}`)
      }
      setIsActivating(false)
      setIsSavingForLater(false)
      dispatch(resetBuilder())
    }
  }, [dispatch, builder.result, isSavedForLater])

  const saveForLaterCampaign = (values: FormValues) => {
    setIsSaveForLater(true)
    setIsSavingForLater(true)

    create(values)
  }

  const activateCampaign = (values: FormValues) => {
    setIsActivating(true)
    create(values)
  }

  const create = (values: FormValues) => {
    let budget = values.budget
    dispatch(createCampaign(currentAccount!.id, values.campaignName, budget, selectedPaymentMethodId, isSavedForLater))
  }

  const activateSchema = Yup.object().shape({
    campaignName: Yup.string().required('Please enter a campaign name'),
    budget: Yup.number().required('Please enter the budget').min(1, 'Please enter at least $1').typeError('Please enter a valid budget amount')
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: activateSchema,
    onSubmit: (values: FormValues) => {
      if (isSavedForLater) saveForLaterCampaign(values)
      else activateCampaign(values)
    }
  })

  const gotoTarget = () => {
    history.push('/build/internet/vista/target')
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Activate Your Campaign</title>
      </Helmet>
      <EuiTitle size='s'>
        <h2>Activate Your Campaign</h2>
      </EuiTitle>
      <EuiSpacer size='m' />

      <EuiForm component='form' onSubmit={formik.handleSubmit}>
        <EuiFormRow label='Campaign Name' fullWidth isInvalid={formik.touched.campaignName && !!formik.errors.campaignName} error={formik.errors.campaignName}>
          <EuiFieldText fullWidth value={formik.values.campaignName || ''} onBlur={formik.handleBlur} onChange={e => formik.setFieldValue('campaignName', e.target.value, true)} isInvalid={formik.touched.campaignName && !!formik.errors.campaignName} />
        </EuiFormRow>
        <EuiSpacer />
        <EuiFormRow label={`Monthly Budget`} fullWidth isInvalid={formik.touched.budget && !!formik.errors.budget} error={formik.errors.budget}>
          <EuiFieldNumber fullWidth prepend={'$'} value={formik.values.budget || ''} onBlur={formik.handleBlur} onChange={e => formik.setFieldValue('budget', Number(e.target.value), true)} isInvalid={formik.touched.budget && !!formik.errors.budget} min={70} />
        </EuiFormRow>
        <EuiSpacer />
        {currentAccount?.id && (
          <PaymentMethodPicker
            campaignType={CampaignType.Internet}
            paymentMethodPickerState={{ selectedPaymentMethodId, selectedCampaignSlotId: '' }}
            updatePaymentMethodPickerState={setPaymentMethodPickerState}
            onAddPaymentMethodClicked={() => {
              dispatch(setNameAndBudget({ Name: formik.values.campaignName, Budget: formik.values.budget }))
            }}
          />
        )}
        <EuiSpacer />

        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill color='text' iconType='arrowLeft' iconSide='left' isDisabled={builder.isCreating} onClick={gotoTarget}>
              Back
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton
              id='save'
              iconType='save'
              iconSide='left'
              onClick={() => {
                setIsSaveForLater(true)
                formik.handleSubmit()
              }}
              isLoading={isSavingForLater}
              isDisabled={builder.isCreating}>
              Save for Later
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='activate' fill iconType='check' type='submit' isLoading={isActivating} isDisabled={!selectedPaymentMethodId || builder.isCreating}>
              Activate
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </React.Fragment>
  )
}

export default ActivateStep
