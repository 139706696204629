import React from 'react'
import { useSelector } from 'react-redux'

import { EuiFlexGroup, EuiFlexItem, EuiLoadingContent, EuiPanel, EuiSpacer, EuiText } from '@elastic/eui'

import { RootState } from 'app/rootReducer'

const View: React.FC = () => {
  const { ad } = useSelector((state: RootState) => state)

  return (
    <EuiPanel grow={false}>
      <EuiText>
        <h4>TV Commercial Details</h4>
      </EuiText>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem grow={true} />
        <EuiFlexItem> {!ad || !ad.ad ? <EuiLoadingContent /> : <video controls src={ad.ad.tvCommercialDetails?.finalUrl ?? ad.ad.tvCommercialDetails?.previewUrl ?? ad.ad.tvCommercialUploadedDetails?.uploadedUrl} style={{ width: 640 }} />}</EuiFlexItem>
        <EuiFlexItem grow={true} />
      </EuiFlexGroup>
    </EuiPanel>
  )
}

export default View
