import numeral from 'numeral'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiBadge, EuiBreadcrumbs, EuiFieldSearch, EuiFlexGroup, EuiFlexItem, EuiHighlight, EuiInputPopover, EuiSelectable, EuiText, EuiToolTip, htmlIdGenerator } from '@elastic/eui'
import { EuiBreadcrumb } from '@elastic/eui/src/components/breadcrumbs/breadcrumbs'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { ThirdPartyAudience } from 'api/interfaces'
import { clearTvAudiences, fetchTvAudiences } from 'app/dictionarySlice'
import { RootState } from 'app/rootReducer'
import useDebounce from 'utils/useDebounce'

interface TvAudienceTargeterProps {
  isInvalid: boolean

  onAudienceClicked(audience: ThirdPartyAudience): void
}

export const TvAudienceFinder = ({ onAudienceClicked, isInvalid }: TvAudienceTargeterProps) => {
  const dispatch = useDispatch()
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const [audienceSearchTerm, setAudienceSearchTerm] = useState<string>('')
  const debouncedAudienceSearchTerm = useDebounce(audienceSearchTerm, 750)
  const [searchAudiencePopoverOpen, setSearchAudiencePopoverOpen] = useState<boolean>(false)
  const [audiences, setAudiences] = useState<Array<EuiSelectableOption>>([])

  const onSearchAudience = () => {
    setSearchAudiencePopoverOpen(false)
  }

  const onSearchAudienceChange = (hint: string) => {
    setAudienceSearchTerm(hint)
  }

  const onSearchAudienceClick = useCallback(
    (audience: ThirdPartyAudience) => {
      onAudienceClicked(audience)
    },
    [onAudienceClicked]
  )

  useEffect(() => {
    if (debouncedAudienceSearchTerm) {
      dispatch(fetchTvAudiences(debouncedAudienceSearchTerm))
    } else {
      dispatch(clearTvAudiences())
    }
  }, [debouncedAudienceSearchTerm, dispatch])

  useEffect(() => {
    if (dictionary.tvAudiences) {
      setAudiences(
        dictionary.tvAudiences.map(
          a =>
            ({
              label: a.name,
              key: a.id.toString(),
              checked: undefined,
              onClick: () => onSearchAudienceClick(a)
            } as EuiSelectableOption)
        )
      )
    }
  }, [dictionary.tvAudiences, onSearchAudienceClick])

  const renderAudienceOption = (option: EuiSelectableOption) => {
    const audience = dictionary.tvAudiences.find(a => a.id.toString() === option.key)
    if (!audience) return <></>

    return (
      <React.Fragment>
        <EuiFlexGroup>
          <EuiFlexItem grow={true}>
            <EuiHighlight search={audienceSearchTerm}>{option.label}</EuiHighlight>
            <EuiBreadcrumbs breadcrumbs={audience.hierarchy.map(h => ({ text: h } as EuiBreadcrumb))} max={5} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiToolTip title='Audience reach'>
              <EuiBadge>{audience.reachCount > 0 ? numeral(audience.reachCount).format('0a') : '~'} people</EuiBadge>
            </EuiToolTip>
          </EuiFlexItem>
        </EuiFlexGroup>
      </React.Fragment>
    )
  }

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          value={audienceSearchTerm}
          isLoading={dictionary.isLoadingTvAudiences}
          onChange={v => onSearchAudienceChange(v.target.value)}
          onFocus={() => setSearchAudiencePopoverOpen(true)}
          incremental={false}
          onSearch={() => onSearchAudience()}
          placeholder='Type to begin search...'
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
          isInvalid={isInvalid}
        />
      }
      isOpen={searchAudiencePopoverOpen}
      closePopover={() => setSearchAudiencePopoverOpen(false)}>
      {dictionary.tvAudiences && dictionary.tvAudiences.length ? (
        <EuiSelectable singleSelection options={audiences} renderOption={renderAudienceOption} listProps={{ rowHeight: 60 }}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search, results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
