import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiCallOut, EuiCheckbox, EuiFieldText, EuiFormRow, EuiLink, EuiListGroup, EuiListGroupItem, EuiLoadingSpinner, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { useGetRetargetingCampaignsQuery, useUpdateCampaignAdvancedTargetingMutation } from 'api/rtkQueryApi/platform/campaignsApi'
import { showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { DefaultDayParts } from 'components/targeters/DayPartPicker'
import { AdvancedTargetingType } from 'features/builders/internet/retail/builderInternetRetailSlice'
import { finishedLoadingCampaign } from 'features/campaigns/campaign/campaignSlice'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

export const RetargetingDrawer: React.FC = () => {
  const [updateAdvancedTargeting, updateAdvancedTargetingQuery] = useUpdateCampaignAdvancedTargetingMutation()
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const { campaign } = useSelector((state: RootState) => state.campaign)
  const whiteLabel = useWhiteLabel()
  const getCampaignsQuery = useGetRetargetingCampaignsQuery({ accountId: currentAccount?.id ?? '', isAgency: whiteLabel?.isAgencies() })
  const [selectedCampaignIds, setSelectedCampaignIds] = useState<string[]>(campaign?.advancedTargeting?.retargetingCampaignIds ?? [])
  const [targetEntireSite, setTargetEntireSite] = useState<boolean>(campaign?.advancedTargeting?.retargetingType === 'All')
  const [targetSpecificPages, setTargetSpecificPages] = useState<boolean>(campaign?.advancedTargeting?.retargetingType === 'Specific')
  const [websiteUrls, setWebsiteUrls] = useState<string[]>(campaign?.advancedTargeting?.urlParts ?? [])
  const [websiteUrl, setWebsiteUrl] = useState<string>()

  const canContinue = selectedCampaignIds.length > 0 || targetSpecificPages || targetEntireSite

  const onCampaignSelected = (optionId: string) => {
    if (selectedCampaignIds.some(id => id === optionId)) {
      setSelectedCampaignIds(selectedCampaignIds.filter(id => id !== optionId))
    } else {
      setSelectedCampaignIds([...selectedCampaignIds, optionId])
    }
  }

  const onUrlAdded = () => {
    if (websiteUrlIsValid) {
      setWebsiteUrls([...websiteUrls.filter(url => url !== websiteUrl), websiteUrl])
      setWebsiteUrl('')
    }
  }

  const onUrlRemoved = (url: string) => {
    setWebsiteUrls(websiteUrls.filter(u => u !== url))
  }

  const onUpdateCampaign = () => {
    if (campaign && canContinue) {
      updateAdvancedTargeting({
        accountId: currentAccount!.id,
        campaignId: campaign.id,
        advancedTargeting: {
          advancedTargetingType: AdvancedTargetingType.Retarget,
          locationType: 'USA',
          states: [],
          dmas: [],
          cities: [],
          zips: [],
          geoFences: [],
          geoCircles: [],
          geoRectangles: [],
          geoPolygons: [],
          audiences: [],
          keywords: [],
          inventorySettings: null,
          deviceSettings: null,
          frequency: null,
          frequencyUnit: null,
          uploadType: null,
          uploadedData: [],
          audienceType: 'Retargeting',
          retargetingType: targetEntireSite ? 'All' : targetSpecificPages ? 'Specific' : null,
          urlParts: targetSpecificPages ? websiteUrls : [],
          retargetingCampaignIds: selectedCampaignIds,
          dayParts: DefaultDayParts,
          ageRanges: [],
          genders: [],
          householdIncomes: [],
          negativeKeywords: []
        }
      }).then(response => {
        if (!(response as any).error) {
          dispatch(showSuccessToast('Saved targeting information'))
          dispatch(finishedLoadingCampaign((response as any).data))
        }
      })
    }
  }

  const websiteUrlIsValid = websiteUrl && websiteUrl.length > 0 && websiteUrl.startsWith('https://')

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiTitle size='s'>
        <h3>Retarget my campaigns</h3>
      </EuiTitle>
      <EuiText size='s'>Select the campaigns you want to retarget.</EuiText>
      {getCampaignsQuery.data && getCampaignsQuery.data.length === 0 && (
        <EuiCallOut title='You have no campaigns to retarget' color={'primary'}>
          <p>Retargeting requires a previously active TV or Internet campaign to have generated an audience.</p>
        </EuiCallOut>
      )}
      <EuiSpacer />
      <EuiFormRow>
        <div style={{ maxHeight: 600, overflow: 'auto' }}>{getCampaignsQuery.data ? getCampaignsQuery.data.map(campaign => <EuiCheckbox key={campaign.id} id={campaign.id} label={`${campaign.name} ($${campaign.budget} ${campaign.type})`} checked={selectedCampaignIds.some(id => id === campaign.id)} onChange={() => onCampaignSelected(campaign.id)} />) : getCampaignsQuery.isLoading ? <EuiLoadingSpinner /> : <React.Fragment />}</div>
      </EuiFormRow>
      <EuiSpacer />
      <EuiTitle size='s'>
        <h3>Retarget my website</h3>
      </EuiTitle>
      <EuiText size='s'>Select how you want to retarget your website</EuiText>
      {!currentAccount?.hasVisits && (
        <EuiCallOut title='The Audience Pixel must be installed onto the site you wish to retarget' color={'primary'}>
          <p>
            To install the audience pixel and enable website retargeting, check out the{' '}
            <EuiLink target='_blank' href={'/audiencePixel'} color={'accent'}>
              Audience Pixel Tab
            </EuiLink>
          </p>
        </EuiCallOut>
      )}
      <EuiSpacer />
      <EuiFormRow label={'Target an audience generated from your website'}>
        <React.Fragment>
          <EuiCheckbox
            id='website'
            label='Entire Site'
            checked={targetEntireSite}
            onChange={() => {
              setTargetEntireSite(!targetEntireSite)
            }}
            disabled={targetSpecificPages || !currentAccount?.hasVisits}
          />
          <EuiCheckbox
            id='specificPages'
            label='Specific Pages'
            checked={targetSpecificPages}
            onChange={() => {
              setTargetSpecificPages(!targetSpecificPages)
            }}
            disabled={targetEntireSite || !currentAccount?.hasVisits}
          />
        </React.Fragment>
      </EuiFormRow>
      {targetSpecificPages && (
        <React.Fragment>
          <EuiFormRow label={"Add the url's of the pages you would like to target"} helpText={!!websiteUrl && websiteUrl.length > 0 && !websiteUrlIsValid ? 'Url must begin with https://' : undefined}>
            <EuiFieldText
              placeholder='https://'
              value={websiteUrl}
              onChange={e => {
                setWebsiteUrl(e.target.value)
              }}
              isInvalid={!!websiteUrl && websiteUrl.length > 0 && !websiteUrlIsValid}
              append={
                <EuiButton size='s' disabled={!websiteUrlIsValid} onClick={onUrlAdded}>
                  Add
                </EuiButton>
              }
            />
          </EuiFormRow>
          <EuiListGroup>
            {websiteUrls.map(url => (
              <EuiListGroupItem
                key={url}
                label={url}
                extraAction={{
                  color: 'text',
                  onClick: () => onUrlRemoved(url),
                  iconType: 'cross',
                  iconSize: 's',
                  'aria-label': 'Favorite link2',
                  alwaysShow: true
                }}
              />
            ))}
          </EuiListGroup>
        </React.Fragment>
      )}
      <EuiButton onClick={onUpdateCampaign} fill disabled={!canContinue} isLoading={updateAdvancedTargetingQuery.isLoading}>
        Save
      </EuiButton>
    </React.Fragment>
  )
}
