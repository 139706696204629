import React from 'react'

import { EuiIcon, EuiListGroup } from '@elastic/eui'

import { ThirdPartyAudience } from 'api/interfaces'

interface AudienceTargeterProps {
  audiences: Array<ThirdPartyAudience>
  itemIcon?: string
  onAudienceRemoved(audience: ThirdPartyAudience): void
}

export const AudienceTargeterDetailed = ({ audiences, onAudienceRemoved, itemIcon }: AudienceTargeterProps) => {
  return (
    <React.Fragment>
      {audiences && audiences.length > 0 && (
        <EuiListGroup bordered={true} maxWidth={false} size='s'>
          {audiences.map(w => (
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 5px' }} key={w.id.toString()}>
              <div>
                <b>{w.name}</b>
                <div style={{ paddingLeft: '10px' }}>{w.description}</div>
                <div style={{ paddingLeft: '10px', color: '#7d7d7d' }}>{w.sourceDetails}</div>
              </div>
              <div style={{ minWidth: '150px', display: 'flex', justifyContent: 'end' }}>
                <EuiIcon type={itemIcon ? itemIcon : 'cross'} onClick={() => onAudienceRemoved(w)} />
              </div>
            </div>
          ))}
        </EuiListGroup>
      )}
    </React.Fragment>
  )
}
