import { DayPartitioning, DeviceSettings, FrequencyUnit, InternetAdPlacementCategory, InventorySettings, TvAdPlacementCategory } from 'api/interfaces/Campaign'

export enum AdPlacementType {
  Everywhere = 'Everywhere',
  Categories = 'Categories',
  Specific = 'Specific'
}

export interface CampaignDelivery {
  inventorySettings: InventorySettings
  deviceSettings: DeviceSettings
  frequency: number
  frequencyUnit: FrequencyUnit
  dayParts: DayPartitioning
  categories: (InternetAdPlacementCategory | TvAdPlacementCategory)[]
  placement: AdPlacementType
  specific: string[]
  targetCPM?: number
}
