import React from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiHideFor, EuiIcon, EuiImage, EuiPanel, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { IAcDictionary } from 'utils/AcDictionary'

import EntertainmentPNG from './Entertainment.png'
import FamilyFriendlyPNG from './Family Friendly.png'
import LifestylePNG from './Lifestyle.png'
import MajorPNG from './Major.png'
import NewsPNG from './News.png'
import SportsPNG from './Sports.png'

export interface TvLegendProps {
  colorMap: IAcDictionary
}

export const TvLegend: React.FC<TvLegendProps> = ({ colorMap }) => {
  return (
    <EuiHideFor sizes={['xs', 's']}>
      <EuiFlexGroup style={{ flexGrow: 0 }}>
        <EuiFlexItem>
          <EuiPanel>
            <EuiTitle size='xs'>
              <h4>Targeted TV Legend</h4>
            </EuiTitle>
            <EuiSpacer size='s' />
            <EuiFlexGroup gutterSize='none'>
              <EuiFlexItem>
                <EuiFlexGroup direction='column' gutterSize='none'>
                  <EuiFlexItem>
                    <EuiFlexGroup gutterSize='none'>
                      <EuiFlexItem grow={false} style={{ width: 22 }}>
                        <EuiIcon type='dot' size='l' color={colorMap['Major'] as string} />
                      </EuiFlexItem>
                      <EuiFlexItem grow={true}>
                        <EuiText size='s' style={{ marginTop: 2 }}>
                          <strong>Major Networks</strong>
                        </EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiImage alt={'Major Networks'} src={MajorPNG} size='m' style={{ marginLeft: 20, width: 200, height: 39 }} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFlexGroup direction='column' gutterSize='none'>
                  <EuiFlexItem>
                    <EuiFlexGroup gutterSize='none'>
                      <EuiFlexItem grow={false} style={{ width: 22 }}>
                        <EuiIcon type='dot' size='l' color={colorMap['Sports'] as string} />
                      </EuiFlexItem>
                      <EuiFlexItem grow={true}>
                        <EuiText size='s' style={{ marginTop: 2 }}>
                          <strong>Sports Networks</strong>
                        </EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiImage alt={'Sports Networks'} src={SportsPNG} size='m' style={{ marginLeft: 16, width: 200, height: 39 }} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFlexGroup direction='column' gutterSize='none'>
                  <EuiFlexItem>
                    <EuiFlexGroup gutterSize='none'>
                      <EuiFlexItem grow={false} style={{ width: 22 }}>
                        <EuiIcon type='dot' size='l' color={colorMap['News'] as string} />
                      </EuiFlexItem>
                      <EuiFlexItem grow={true}>
                        <EuiText size='s' style={{ marginTop: 2 }}>
                          <strong>News Networks</strong>
                        </EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiImage alt={'News Networks'} src={NewsPNG} size='m' style={{ marginLeft: 12, width: 200, height: 39 }} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFlexGroup direction='column' gutterSize='none'>
                  <EuiFlexItem>
                    <EuiFlexGroup gutterSize='none'>
                      <EuiFlexItem grow={false} style={{ width: 22 }}>
                        <EuiIcon type='dot' size='l' color={colorMap['Entertainment'] as string} />
                      </EuiFlexItem>
                      <EuiFlexItem grow={true}>
                        <EuiText size='s' style={{ marginTop: 2 }}>
                          <strong>Entertainment Networks</strong>
                        </EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiImage alt={'Entertainment Networks'} src={EntertainmentPNG} size='m' style={{ marginLeft: 20, width: 200, height: 39 }} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size='s' />
            <EuiFlexGroup gutterSize='none'>
              <EuiFlexItem>
                <EuiFlexGroup direction='column' gutterSize='none'>
                  <EuiFlexItem>
                    <EuiFlexGroup gutterSize='none'>
                      <EuiFlexItem grow={false} style={{ width: 22 }}>
                        <EuiIcon type='dot' size='l' color={colorMap['FamilyFriendly'] as string} />
                      </EuiFlexItem>
                      <EuiFlexItem grow={true}>
                        <EuiText size='s' style={{ marginTop: 2 }}>
                          <strong>Family Friendly Networks</strong>
                        </EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiImage alt={'Family Friendly Networks'} src={FamilyFriendlyPNG} size='m' style={{ marginLeft: 18, width: 200, height: 39 }} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFlexGroup direction='column' gutterSize='none'>
                  <EuiFlexItem>
                    <EuiFlexGroup gutterSize='none'>
                      <EuiFlexItem grow={false} style={{ width: 22 }}>
                        <EuiIcon type='dot' size='l' color={colorMap['Lifestyle'] as string} />
                      </EuiFlexItem>
                      <EuiFlexItem grow={true}>
                        <EuiText size='s' style={{ marginTop: 2 }}>
                          <strong>Lifestyle Networks</strong>
                        </EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiImage alt={'Lifestyle Networks'} src={LifestylePNG} size='m' style={{ marginLeft: 12, width: 200, height: 39 }} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFlexGroup direction='column' gutterSize='none'>
                  <EuiFlexItem>
                    <EuiFlexGroup gutterSize='none'>
                      <EuiFlexItem grow={false} style={{ width: 22 }}>
                        <EuiIcon type='dot' size='l' color={colorMap['Other'] as string} />
                      </EuiFlexItem>
                      <EuiFlexItem grow={true}>
                        <EuiText size='s' style={{ marginTop: 2 }}>
                          <strong>Other</strong>
                        </EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  <EuiFlexItem />
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem />
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
    </EuiHideFor>
  )
}
