import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { EuiLoadingContent, EuiPanel, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { RootState } from 'app/rootReducer'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

export const LoggingUserInPage: React.FC = () => {
  const whiteLabel = useWhiteLabel()
  const { returnUrl, isVendastaUser, vendastaAccessToken } = useSelector((state: RootState) => state.app)
  const { pathname } = useLocation()

  let titleText = 'Logging you in...'

  if (returnUrl) {
    titleText = 'Returning...'
  }

  if (isVendastaUser && (!vendastaAccessToken || vendastaAccessToken.length <= 0)) {
    titleText = 'Logging you in...'
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{returnUrl ? 'Return' : 'Login'}</title>
      </Helmet>
      <div
        style={{
          width: '100%',
          height: '100%',
          minHeight: 500,
          background: 'linear-gradient(0deg, #D3DAE6 50%, #232e40 50%)',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: 'repeat(3, 1fr)',
          justifyItems: 'center',
          alignItems: 'center'
        }}>
        <div style={{ height: 500, width: 350, gridRow: '2/3' }}>
          <div style={{ textAlign: 'center' }}>
            <img src={whiteLabel?.getLogo()} alt={whiteLabel?.getName()} style={{ marginBottom: 25, width: 220 }} />
          </div>
          <EuiPanel hasShadow={true}>
            <EuiTitle>
              <h2>{titleText}</h2>
            </EuiTitle>
            <EuiSpacer />
            <EuiLoadingContent />
            <EuiSpacer />
            <EuiText size='s'>
              <p>{returnUrl ? 'Please wait while we bring you to your previous page.' : 'Please wait while we access your dashboard.'}</p>
            </EuiText>
          </EuiPanel>
        </div>
      </div>
    </React.Fragment>
  )
}
