import { createSlice } from '@reduxjs/toolkit'

type OverviewStats = 'Views' | 'Clicks' | 'Spend' | 'Ctr' | 'Cpc' | 'Cpm'
type AxisPosition = 'left' | 'right'

interface OverviewState {
  showViews: boolean
  showClicks: boolean
  showSpend: boolean
  showCtr: boolean
  showCpc: boolean
  showCpm: boolean
  positionViews: AxisPosition
  positionClicks: AxisPosition
  positionSpend: AxisPosition
  positionCtr: AxisPosition
  positionCpc: AxisPosition
  positionCpm: AxisPosition
  lastClick: OverviewStats
}

const initialState: OverviewState = {
  showViews: true,
  showClicks: true,
  showSpend: false,
  showCtr: false,
  showCpc: false,
  showCpm: false,
  positionViews: 'left',
  positionClicks: 'right',
  positionSpend: 'left',
  positionCtr: 'left',
  positionCpc: 'left',
  positionCpm: 'left',
  lastClick: 'Clicks'
}

function getCount(state: OverviewState, currentClick: OverviewStats) {
  let count = 0
  if (currentClick !== 'Views' && state.showViews) count++
  if (currentClick !== 'Clicks' && state.showClicks) count++
  if (currentClick !== 'Spend' && state.showSpend) count++
  if (currentClick !== 'Ctr' && state.showCtr) count++
  if (currentClick !== 'Cpc' && state.showCpc) count++
  if (currentClick !== 'Cpm' && state.showCpm) count++

  return count
}

function setPosition(state: OverviewState, position: AxisPosition, currentClick: OverviewStats) {
  switch (currentClick) {
    case 'Views':
      state.positionViews = position
      break
    case 'Clicks':
      state.positionClicks = position
      break
      break
    case 'Spend':
      state.positionSpend = position
      break
    case 'Ctr':
      state.positionCtr = position
      break
    case 'Cpc':
      state.positionCpc = position
      break
    case 'Cpm':
      state.positionCpm = position
      break
  }
}

function getPosition(state: OverviewState, currentClick: OverviewStats) {
  switch (currentClick) {
    case 'Views':
      return state.positionViews
    case 'Clicks':
      return state.positionClicks
    case 'Spend':
      return state.positionSpend
    case 'Ctr':
      return state.positionCtr
    case 'Cpc':
      return state.positionCpc
    case 'Cpm':
      return state.positionCpm
  }
}

function turnOnSimple(state: OverviewState, currentClick: OverviewStats) {
  if (currentClick !== 'Views' && state.showViews) setPosition(state, getPosition(state, 'Views') === 'left' ? 'right' : 'left', currentClick)
  if (currentClick !== 'Clicks' && state.showClicks) setPosition(state, getPosition(state, 'Clicks') === 'left' ? 'right' : 'left', currentClick)
  if (currentClick !== 'Spend' && state.showSpend) setPosition(state, getPosition(state, 'Spend') === 'left' ? 'right' : 'left', currentClick)
  if (currentClick !== 'Ctr' && state.showCtr) setPosition(state, getPosition(state, 'Ctr') === 'left' ? 'right' : 'left', currentClick)
  if (currentClick !== 'Cpc' && state.showCpc) setPosition(state, getPosition(state, 'Cpc') === 'left' ? 'right' : 'left', currentClick)
  if (currentClick !== 'Cpm' && state.showCpm) setPosition(state, getPosition(state, 'Cpm') === 'left' ? 'right' : 'left', currentClick)
}

function turnOnToggle(state: OverviewState, currentClick: OverviewStats) {
  if (currentClick !== 'Views' && state.showViews && state.lastClick !== 'Views') {
    state.showViews = false
    setPosition(state, getPosition(state, 'Views'), currentClick)
  }
  if (currentClick !== 'Clicks' && state.showClicks && state.lastClick !== 'Clicks') {
    state.showClicks = false
    setPosition(state, getPosition(state, 'Clicks'), currentClick)
  }
  if (currentClick !== 'Spend' && state.showSpend && state.lastClick !== 'Spend') {
    state.showSpend = false
    setPosition(state, getPosition(state, 'Spend'), currentClick)
  }
  if (currentClick !== 'Ctr' && state.showCtr && state.lastClick !== 'Ctr') {
    state.showCtr = false
    setPosition(state, getPosition(state, 'Ctr'), currentClick)
  }
  if (currentClick !== 'Cpc' && state.showCpc && state.lastClick !== 'Cpc') {
    state.showCpc = false
    setPosition(state, getPosition(state, 'Cpc'), currentClick)
  }
  if (currentClick !== 'Cpm' && state.showCpm && state.lastClick !== 'Cpm') {
    state.showCpm = false
    setPosition(state, getPosition(state, 'Cpm'), currentClick)
  }
}

const overview = createSlice({
  name: 'overview',
  initialState,
  reducers: {
    toggleViews(state: OverviewState) {
      const count = getCount(state, 'Views')

      if (count === 0) {
        state.positionViews = 'left'
      }
      if (count === 1) {
        turnOnSimple(state, 'Views')
      }
      if (count > 1) {
        turnOnToggle(state, 'Views')
      }
      state.lastClick = 'Views'
      state.showViews = !state.showViews
    },
    toggleClicks(state: OverviewState) {
      const count = getCount(state, 'Clicks')

      if (count === 0) {
        state.positionClicks = 'left'
      }
      if (count === 1) {
        turnOnSimple(state, 'Clicks')
      }
      if (count > 1) {
        turnOnToggle(state, 'Clicks')
      }
      state.lastClick = 'Clicks'
      state.showClicks = !state.showClicks
    },
    toggleSpend(state: OverviewState) {
      const count = getCount(state, 'Spend')

      if (count === 0) {
        state.positionSpend = 'left'
      }
      if (count === 1) {
        turnOnSimple(state, 'Spend')
      }
      if (count > 1) {
        turnOnToggle(state, 'Spend')
      }
      state.lastClick = 'Spend'
      state.showSpend = !state.showSpend
    },
    toggleCtr(state: OverviewState) {
      const count = getCount(state, 'Ctr')

      if (count === 0) {
        state.positionCtr = 'left'
      }
      if (count === 1) {
        turnOnSimple(state, 'Ctr')
      }
      if (count > 1) {
        turnOnToggle(state, 'Ctr')
      }
      state.lastClick = 'Ctr'
      state.showCtr = !state.showCtr
    },
    toggleCpm(state: OverviewState) {
      const count = getCount(state, 'Cpm')

      if (count === 0) {
        state.positionCpm = 'left'
      }
      if (count === 1) {
        turnOnSimple(state, 'Cpm')
      }
      if (count > 1) {
        turnOnToggle(state, 'Cpm')
      }
      state.lastClick = 'Cpm'
      state.showCpm = !state.showCpm
    },
    toggleCpc(state: OverviewState) {
      const count = getCount(state, 'Cpc')

      if (count === 0) {
        state.positionCpc = 'left'
      }
      if (count === 1) {
        turnOnSimple(state, 'Cpc')
      }
      if (count > 1) {
        turnOnToggle(state, 'Cpc')
      }
      state.lastClick = 'Cpc'
      state.showCpc = !state.showCpc
    }
  }
})

export const { toggleViews, toggleClicks, toggleCpc, toggleCpm, toggleCtr, toggleSpend } = overview.actions

export default overview.reducer
