import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiSpacer, EuiButton, EuiFlexGroup, EuiText } from '@elastic/eui'

import { apiPostOrganizationRestart } from 'api/adcritterApi'
import { logout, showErrorToast, updateOrganizationState } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { AcConfirmModal } from 'components/Modals/AcConfirmModal'
import { fetchOrganizationPlatformAccess } from 'features/organization/organizationSlice'
import history from 'services/HistoryService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

const RestartForm = () => {
  const dispatch = useDispatch()
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [prepareCaptureFlag, setPrepareCaptureFlag] = useState(false)
  const organization = useSelector((state: RootState) => state.organization)
  const { currentOrganization } = useSelector((state: RootState) => state.app)
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  const whiteLabel = useWhiteLabel()
  const [confirmationVisible, setConfirmationVisible] = useState(false)

  useEffect(() => {
    dispatch(fetchOrganizationPlatformAccess())
  }, [currentOrganization, dispatch])

  const onLogoutClick = () => {
    dispatch(logout())
    history.push('/login')
  }

  const restartSubscription = () => {
    apiPostOrganizationRestart().then(result => {
      if (result?.errors?.length > 0) {
        dispatch(showErrorToast('Payment Failed.'))
        setPrepareCaptureFlag(!prepareCaptureFlag)
      } else {
        dispatch(updateOrganizationState(result.data))
        history.push(`/dashboard`)
      }
      setIsProcessing(false)
    })

    return false
  }

  if (organization.platformAccess == null) return null

  return (
    <React.Fragment>
      <EuiText size={'s'}>
        <p>Would you like to reactivate your {whiteLabel?.getName()} Platform subscription?</p>
      </EuiText>
      <EuiSpacer size={'xl'} />
      <EuiFlexGroup justifyContent='spaceEvenly'>
        <EuiButton id='restart' fill isDisabled={isProcessing} onClick={onLogoutClick}>
          Logout
        </EuiButton>
        <EuiButton id='restart' fill isDisabled={isProcessing} onClick={() => setConfirmationVisible(true)}>
          Reactivate Subscription
        </EuiButton>
      </EuiFlexGroup>
      {confirmationVisible && <AcConfirmModal title={'Reactivate Subscription'} message={`You will be charged ${formatter.format(organization.platformAccess.nextBillAmount)} to reactivate your subscription. Are you sure you want to continue?`} onCancel={() => setConfirmationVisible(false)} onConfirm={restartSubscription} confirmButtonText={'Confirm'} cancelButtonText={'Cancel'} />}
    </React.Fragment>
  )
}

export default RestartForm
