import { Formik } from 'formik'
import Konva from 'konva'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiAccordion, EuiButton, EuiColorPicker, EuiFieldNumber, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSpacer, EuiSuperSelect, EuiText, useGeneratedHtmlId } from '@elastic/eui'
import { EuiSuperSelectOption } from '@elastic/eui/src/components/form/super_select/super_select_control'

import { apiGetBillboardById } from 'api/adcritterApi'
import { BillboardCustomization } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import { BillboardEditor } from 'components/KonvaTools/BillboardEditor'
import ChooseImageFlyout from 'components/chooseImage/ChooseImageFlyout'
import { resetSearchImage } from 'components/chooseImage/searchImageSlice'
import { BillboardDefaults } from 'definitions/BillboardDefaults'
import history from 'services/HistoryService'
import { GetBase64Size, ISize, ScaleSizes } from 'utils/imageUtils'

import { resetCreateAd, setPhoto, uploadBillboard } from './createAdBillboardRetailSlice'

const CreateStep: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [billboard] = useState<BillboardCustomization>()
  const createAd = useSelector((state: RootState) => state.createAdBillboardRetail)
  const campaign = useSelector((state: RootState) => state.campaign)
  const chosenPhoto = useSelector((state: RootState) => state.searchImage.chosenImage)
  const simpleAccordionId = useGeneratedHtmlId({ prefix: 'simpleAccordion' })
  const [isUploading] = useState(false)
  const stageRef = React.useRef<Konva.Stage>(null)
  const [selected, setSelected] = useState<boolean>(true)
  const [initial, setInitial] = useState<BillboardCustomization>({
    ...BillboardDefaults.GetDefaultBillboard(),
    campaignIds: [campaign.campaign!.id]
  })
  const [takingPicture, setTakingPicture] = useState(false)

  useEffect(() => {
    if (chosenPhoto) {
      dispatch(setPhoto(chosenPhoto))
      GetBase64Size(chosenPhoto.highQualityUrl, (size: ISize) => {
        setInitial(i => {
          i.logo.sampleUrl = chosenPhoto.highQualityUrl
          const scaledSize = ScaleSizes(i.photo!.width ?? 0, i.photo!.height ?? 0, size.Width, size.Height)
          i.photo!.width = scaledSize.Width
          i.photo!.height = scaledSize.Height
          return i
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenPhoto, dispatch])

  useEffect(() => {
    if (currentAccount) {
      if (createAd.create && createAd.create.backgroundUrl) {
        setInitial(createAd.create)
      } else if (createAd.billboard) {
        setInitial(createAd.billboard)
      }
    }
  }, [createAd.billboard, currentAccount, createAd.create])

  useEffect(() => {
    if (currentAccount && !createAd.create && !createAd.billboard) {
      apiGetBillboardById(currentAccount!.id!, createAd.billboard!.id!).then(result => {
        BillboardDefaults.LoadBillboard(initial, result.data, setInitial)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAd.billboard, currentAccount, dispatch, createAd.create])

  const billboardSchema = Yup.object().shape({
    headline: Yup.object(),
    companyName: Yup.object(),
    phone: Yup.object(),
    location: Yup.object(),
    websiteUrl: Yup.object(),
    logo: Yup.object(),
    photo: Yup.object(),
    backgroundUrl: Yup.string()
  })

  useEffect(() => {
    if (!createAd.isCreating && createAd.doneUploading) {
      history.push(`/campaigns/edit/${campaign.campaign!.id}/details`)
    }
  }, [createAd.isCreating, createAd.doneUploading, campaign])

  useEffect(() => {
    if (!selected && takingPicture && stageRef.current) {
      const billboardClone = { ...initial }
      billboardClone.campaignIds = [campaign.campaign!.id]

      billboardClone.previewUrl = stageRef.current.toDataURL({ pixelRatio: BillboardDefaults.GetCaptureScaling() })

      dispatch(uploadBillboard(currentAccount!.id, campaign.campaign!.id, billboardClone))

      setSelected(true)
      setTakingPicture(false)
    }
  }, [selected, takingPicture, campaign.campaign, currentAccount, dispatch, initial])

  const postData = () => {
    setSelected(false)
    setTakingPicture(true)
  }

  const gotoSuggestions = () => {
    dispatch(resetCreateAd)
    dispatch(resetSearchImage())
    history.push('/campaigns/billboards/public/suggestions')
  }

  const fontFaces = BillboardDefaults.GetFontFaces()

  const alignOptions: EuiSuperSelectOption<string>[] = [
    { value: 'left', inputDisplay: <span>Left</span> },
    { value: 'center', inputDisplay: <span>Center</span> },
    { value: 'right', inputDisplay: <span>Right</span> }
  ]

  return (
    <React.Fragment>
      <Helmet>
        <title>Billboard Ad Builder</title>
        <link href='https://fonts.googleapis.com/css2?family=Roboto&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css2?family=Merienda&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css2?family=Noticia+Text&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css2?family=Comfortaa&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Comfortaa&display=swap' rel='stylesheet' />
      </Helmet>
      <Formik initialValues={initial} enableReinitialize validationSchema={billboardSchema} onSubmit={() => {}}>
        {props => (
          <React.Fragment>
            <EuiText>
              <h3>Edit Your Billboard Ad</h3>
            </EuiText>
            <EuiSpacer size='m' />
            <EuiFlexGroup style={{ overflow: 'auto' }}>
              <EuiFlexItem>
                <EuiText size='s'>
                  <h4>Billboard Content</h4>
                </EuiText>
                <EuiSpacer size='s' />
                {initial.headline.selected && (
                  <React.Fragment>
                    <EuiFieldText
                      name='headline.sampleText'
                      value={initial.headline.sampleText}
                      onChange={e => {
                        let tempState = { ...initial }
                        tempState.headline = { ...initial.headline }
                        tempState.headline.sampleText = e.target.value
                        setInitial(tempState)
                      }}
                      fullWidth
                    />
                    <EuiSpacer size='s' />
                    <EuiAccordion id={simpleAccordionId} buttonContent='Headline Font Options'>
                      <EuiSpacer size='s' />
                      <EuiFormRow style={{ marginLeft: 10 }} fullWidth>
                        <EuiFlexGroup>
                          <EuiFlexItem>
                            <EuiFormRow fullWidth label='Font Style' error={billboard?.headline?.fontFace || billboard?.headline?.fontSize}>
                              <EuiSuperSelect
                                options={fontFaces}
                                valueOfSelected={initial.headline.fontFace}
                                onChange={e => {
                                  let tempState = { ...initial }
                                  tempState.headline = { ...initial.headline }
                                  tempState.headline.fontFace = e
                                  setInitial(tempState)
                                }}
                                fullWidth
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFormRow label='Font Size' fullWidth style={{ minWidth: 60 }}>
                              <EuiFieldNumber
                                name='headline.fontSize'
                                value={initial.headline.fontSize}
                                onChange={e => {
                                  let tempState = { ...initial }
                                  tempState.headline = { ...initial.headline }
                                  tempState.headline.fontSize = parseInt(e.target.value)
                                  setInitial(tempState)
                                }}
                                fullWidth
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFormRow label='Font Color' fullWidth>
                              <EuiColorPicker
                                color={initial.headline.fontColor}
                                onChange={e => {
                                  let tempState = { ...initial }
                                  tempState.headline = { ...initial.headline }
                                  tempState.headline.fontColor = e
                                  setInitial(tempState)
                                }}
                                fullWidth
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFormRow fullWidth label='Alignment' error={billboard?.headline?.align}>
                              <EuiSuperSelect
                                fullWidth
                                name='headlineAlign'
                                valueOfSelected={initial.headline.align}
                                options={alignOptions}
                                onChange={e => {
                                  setInitial(prevState => ({
                                    ...prevState,
                                    headline: {
                                      ...prevState.headline,
                                      align: e
                                    }
                                  }))
                                }}
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFormRow>
                    </EuiAccordion>
                  </React.Fragment>
                )}
                {initial.companyName.selected && (
                  <React.Fragment>
                    <EuiSpacer />
                    <EuiFormRow label='Company Name' fullWidth>
                      <EuiFieldText
                        name='companyName.sampleText'
                        value={initial.companyName.sampleText}
                        onChange={e => {
                          let tempState = { ...initial }
                          tempState.companyName = { ...initial.companyName }
                          tempState.companyName.sampleText = e.target.value
                          setInitial(tempState)
                        }}
                        fullWidth
                      />
                    </EuiFormRow>
                    <EuiSpacer size='s' />
                    <EuiAccordion id={simpleAccordionId} buttonContent='Company Name Font Options'>
                      <EuiSpacer size='s' />
                      <EuiFormRow style={{ marginLeft: 10 }} fullWidth>
                        <EuiFlexGroup>
                          <EuiFlexItem>
                            <EuiFormRow label='Font Style' fullWidth>
                              <EuiSuperSelect
                                options={fontFaces}
                                valueOfSelected={initial.companyName.fontFace}
                                onChange={e => {
                                  let tempState = { ...initial }
                                  tempState.companyName = { ...initial.companyName }
                                  tempState.companyName.fontFace = e
                                  setInitial(tempState)
                                }}
                                fullWidth
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFormRow label='Font Size' fullWidth style={{ minWidth: 60 }}>
                              <EuiFieldNumber
                                name='companyName.fontSize'
                                value={initial.companyName.fontSize}
                                onChange={e => {
                                  let tempState = { ...initial }
                                  tempState.companyName = { ...initial.companyName }
                                  tempState.companyName.fontSize = parseInt(e.target.value)
                                  setInitial(tempState)
                                }}
                                fullWidth
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFormRow label='Font Color' fullWidth>
                              <EuiColorPicker
                                color={initial.companyName.fontColor}
                                onChange={e => {
                                  let tempState = { ...initial }
                                  tempState.companyName = { ...initial.companyName }
                                  tempState.companyName.fontColor = e
                                  setInitial(tempState)
                                }}
                                fullWidth
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFormRow fullWidth label='Alignment' error={billboard?.companyName?.align}>
                              <EuiSuperSelect
                                fullWidth
                                name='companyNameAlign'
                                valueOfSelected={initial.companyName?.align}
                                options={alignOptions}
                                onChange={e => {
                                  setInitial(prevState => ({
                                    ...prevState,
                                    companyName: {
                                      ...prevState.companyName,
                                      align: e
                                    }
                                  }))
                                }}
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFormRow>
                    </EuiAccordion>
                    <EuiSpacer />
                    <EuiFormRow label='Phone' fullWidth>
                      <EuiFieldText
                        name='phone.sampleText'
                        value={initial.phone.sampleText}
                        onChange={e => {
                          let tempState = { ...initial }
                          tempState.phone = { ...initial.phone }
                          tempState.phone.sampleText = e.target.value
                          setInitial(tempState)
                        }}
                        fullWidth
                      />
                    </EuiFormRow>
                    <EuiSpacer size='s' />
                    <EuiAccordion id={simpleAccordionId} buttonContent='Phone Font Options'>
                      <EuiSpacer size='s' />
                      <EuiFormRow style={{ marginLeft: 10 }} fullWidth>
                        <EuiFlexGroup>
                          <EuiFlexItem>
                            <EuiFormRow label='Font Style' fullWidth>
                              <EuiSuperSelect
                                options={fontFaces}
                                valueOfSelected={initial.phone.fontFace}
                                onChange={e => {
                                  let tempState = { ...initial }
                                  tempState.phone = { ...initial.phone }
                                  tempState.phone.fontFace = e
                                  setInitial(tempState)
                                }}
                                fullWidth
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFormRow label='Font Size' fullWidth style={{ minWidth: 60 }}>
                              <EuiFieldNumber
                                name='phone.fontSize'
                                value={initial.phone.fontSize}
                                onChange={e => {
                                  let tempState = { ...initial }
                                  tempState.phone = { ...initial.phone }
                                  tempState.phone.fontSize = parseInt(e.target.value)
                                  setInitial(tempState)
                                }}
                                fullWidth
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFormRow label='Font Color' fullWidth>
                              <EuiColorPicker
                                color={initial.phone.fontColor}
                                onChange={e => {
                                  let tempState = { ...initial }
                                  tempState.phone = { ...initial.phone }
                                  tempState.phone.fontColor = e
                                  setInitial(tempState)
                                }}
                                fullWidth
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFormRow fullWidth label='Alignment' error={billboard?.phone?.align}>
                              <EuiSuperSelect
                                fullWidth
                                name='phoneAlign'
                                valueOfSelected={initial.phone?.align}
                                options={alignOptions}
                                onChange={e => {
                                  setInitial(prevState => ({
                                    ...prevState,
                                    phone: {
                                      ...prevState.phone,
                                      align: e
                                    }
                                  }))
                                }}
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFormRow>
                    </EuiAccordion>
                  </React.Fragment>
                )}
                {initial.website.selected && (
                  <React.Fragment>
                    <EuiSpacer />
                    <EuiFormRow label='Website URL' fullWidth>
                      <EuiFieldText
                        name='website.sampleText'
                        value={initial.website.sampleText}
                        onChange={e => {
                          let tempState = { ...initial }
                          tempState.website = { ...initial.website }
                          tempState.website.sampleText = e.target.value
                          setInitial(tempState)
                        }}
                        fullWidth
                      />
                    </EuiFormRow>
                    <EuiSpacer size='s' />
                    <EuiAccordion id={simpleAccordionId} buttonContent='Website Font Options'>
                      <EuiSpacer size='s' />
                      <EuiFormRow style={{ marginLeft: 10 }} fullWidth>
                        <EuiFlexGroup>
                          <EuiFlexItem>
                            <EuiFormRow fullWidth label='Font Style'>
                              <EuiSuperSelect
                                options={fontFaces}
                                valueOfSelected={initial.website.fontFace}
                                onChange={e => {
                                  let tempState = { ...initial }
                                  tempState.website = { ...initial.website }
                                  tempState.website.fontFace = e
                                  setInitial(tempState)
                                }}
                                fullWidth
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFormRow label='Font Size' fullWidth style={{ minWidth: 60 }}>
                              <EuiFieldNumber
                                name='website.FontSize'
                                value={initial.website.fontSize}
                                onChange={e => {
                                  let tempState = { ...initial }
                                  tempState.website = { ...initial.website }
                                  tempState.website.fontSize = parseInt(e.target.value)
                                  setInitial(tempState)
                                }}
                                fullWidth
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFormRow label='Font Color' fullWidth>
                              <EuiColorPicker
                                color={initial.website.fontColor}
                                onChange={e => {
                                  let tempState = { ...initial }
                                  tempState.website = { ...initial.website }
                                  tempState.website.fontColor = e
                                  setInitial(tempState)
                                }}
                                fullWidth
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFormRow fullWidth label='Alignment' error={billboard?.website?.align}>
                              <EuiSuperSelect
                                fullWidth
                                name='websiteAlign'
                                valueOfSelected={initial.website?.align}
                                options={alignOptions}
                                onChange={e => {
                                  setInitial(prevState => ({
                                    ...prevState,
                                    website: {
                                      ...prevState.website,
                                      align: e
                                    }
                                  }))
                                }}
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFormRow>
                    </EuiAccordion>
                  </React.Fragment>
                )}
                {initial.location.selected && (
                  <>
                    <EuiSpacer />
                    <EuiFormRow label='Location' fullWidth>
                      <EuiFieldText
                        name='location.sampleText'
                        value={initial.location.sampleText}
                        onChange={e => {
                          let tempState = { ...initial }
                          tempState.location = { ...initial.location }
                          tempState.location.sampleText = e.target.value
                          setInitial(tempState)
                        }}
                        fullWidth
                      />
                    </EuiFormRow>
                    <EuiSpacer size='s' />
                    <EuiAccordion id={simpleAccordionId} buttonContent='Location Font Options'>
                      <EuiSpacer size='s' />
                      <EuiFormRow style={{ marginLeft: 10 }} fullWidth>
                        <EuiFlexGroup>
                          <EuiFlexItem>
                            <EuiFormRow fullWidth label='Font Style'>
                              <EuiSuperSelect
                                options={fontFaces}
                                valueOfSelected={initial.location.fontFace}
                                onChange={e => {
                                  let tempState = { ...initial }
                                  tempState.location = { ...initial.location }
                                  tempState.location.fontFace = e
                                  setInitial(tempState)
                                }}
                                fullWidth
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFormRow label='Font Size' fullWidth style={{ minWidth: 60 }}>
                              <EuiFieldNumber
                                name='location.fontSize'
                                value={initial.location.fontSize}
                                onChange={e => {
                                  let tempState = { ...initial }
                                  tempState.location = { ...initial.location }
                                  tempState.location.fontSize = parseInt(e.target.value)
                                  setInitial(tempState)
                                }}
                                fullWidth
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFormRow label='Font Color' fullWidth>
                              <EuiColorPicker
                                color={initial.location.fontColor}
                                onChange={e => {
                                  let tempState = { ...initial }
                                  tempState.location = { ...initial.location }
                                  tempState.location.fontColor = e
                                  setInitial(tempState)
                                }}
                                fullWidth
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFormRow fullWidth label='Alignment' error={billboard?.website?.align}>
                              <EuiSuperSelect
                                fullWidth
                                name='locationAlign'
                                valueOfSelected={initial.location?.align}
                                options={alignOptions}
                                onChange={e => {
                                  setInitial(prevState => ({
                                    ...prevState,
                                    location: {
                                      ...prevState.location,
                                      align: e
                                    }
                                  }))
                                }}
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFormRow>
                    </EuiAccordion>
                    <EuiSpacer />
                  </>
                )}
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText size='s'>
                  <h4>Preview Your Billboard Ad</h4>
                </EuiText>
                <EuiSpacer size='s' />
                {initial.backgroundUrl && <BillboardEditor billboard={initial} onUpdateBillboard={setInitial} chosenPhoto={chosenPhoto ?? undefined} selected={selected} stageRef={stageRef} />}
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
            {initial.photo.selected && (
              <React.Fragment>
                <EuiSpacer />
                <EuiText size='s'>
                  <h4>Additional Photo</h4>
                </EuiText>
                <EuiSpacer size='s' />
                <div className='euiFilePicker euiFilePicker--fullWidth euiFilePicker--large euiFilePicker-hasFiles'>
                  <div className='euiFiePicker__wrap'>
                    <div className='euiFilePicker__prompt'>
                      <img src={chosenPhoto ? chosenPhoto.highQualityUrl : initial.photo.sampleUrl} alt={`${createAd.photo?.id}`} width={82} height={82} style={{ borderRadius: 8 }} />
                      <ChooseImageFlyout defaultSearch={''} />
                    </div>
                  </div>
                </div>
                <EuiSpacer size='s' />
              </React.Fragment>
            )}
            <EuiSpacer />
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButton id='back' fill type='button' color='text' onClick={gotoSuggestions} iconType='arrowLeft' iconSide='left'>
                  Back
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton id='continue' isLoading={props.isSubmitting} fill type='submit' isDisabled={isUploading} onClick={postData}>
                  Submit
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </React.Fragment>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default CreateStep
