import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { DisplayDetails } from 'workflows/CampaignCreateWorkflow'

import { EuiButton, EuiCallOut, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHideFor, EuiIcon, EuiSelect, EuiShowFor, EuiSpacer, EuiText, EuiTitle, EuiToolTip } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'

import { RootState } from 'app/rootReducer'
import { AcImageUploader, UploadedImage } from 'components/Basic/AcImageUploader'
import { ICampaignBuilderWorkflowStep } from 'features/builders/createCampaign/creativeBuilder/CreativeBuilderWorkflowManager'
import { useCampaignBuilderActions } from 'features/builders/createCampaign/useCampaignBuilderActions'
import { displayAdSizes } from 'utils/imageUtils'

export const AdUploadInternetPage: React.FC<ICampaignBuilderWorkflowStep> = props => {
  const { createDisplayComplete } = useCampaignBuilderActions()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [size, setSize] = useState('300x250')
  const [isValidUpload, setIsValidUpload] = useState(true)
  const [isValidFile, setIsValidFile] = useState(true)
  const {
    creativeInfo: { displayDetails, creativeId },
    businessInfo
  } = useSelector((state: RootState) => state.campaignCreateWorkflow)
  const [adBuilder, setAdBuilder] = useState<DisplayDetails>({
    fileName: '',
    displayImageUrl: '',
    displayWidth: 0,
    displayHeight: 0,
    clickUrl: ''
  })

  useEffect(() => {
    if ((displayDetails && creativeId) || businessInfo) {
      const details = displayDetails?.find(d => d.creativeId === creativeId)
      setAdBuilder({ ...details, clickUrl: details?.clickUrl ?? businessInfo?.websiteUrl })
    }
  }, [displayDetails, creativeId, businessInfo])

  // 300 MB
  const acceptedFileSize = 300 * 1000000

  const goToStart = () => {
    props.onBack?.()
  }

  const onUploadedImagesChange = (images: UploadedImage[]) => {
    if (images.length > 0 && isValidDimensions(images[0])) {
      const info: DisplayDetails = {
        fileName: images[0].fileName.split('.')[0],
        displayImageUrl: images[0].url,
        clickUrl: adBuilder.clickUrl,
        displayWidth: images[0].width,
        displayHeight: images[0].height
      }
      setAdBuilder(info)
      setIsValidFile(true)
    } else {
      setIsValidFile(false)
    }
  }

  const isValidDimensions = (image: UploadedImage) => {
    return displayAdSizes.some(s => s.width === image.width && s.height === image.height)
  }

  const onSave = () => {
    createDisplayComplete(adBuilder)
  }

  const isValid = () => {
    return (adBuilder.displayImageUrl ?? '') !== '' && adBuilder.displayWidth !== 0 && adBuilder.displayHeight !== 0 && (adBuilder.clickUrl ?? '').length > 0
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Upload Display Ad</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Upload Your Ad</h3>
      </EuiTitle>
      <EuiSpacer size='m' />
      <EuiFlexGroup>
        <EuiFlexItem style={{ marginLeft: 25 }}>
          <EuiFormRow fullWidth label={'Uploaded File'}>
            <AcImageUploader
              maxFileSize={acceptedFileSize}
              onUploadedUrlsChange={onUploadedImagesChange}
              onIsValidFileChange={isValidUpload => {
                setIsValidUpload(isValidUpload)
              }}
              accountId={currentAccount!.id}
            />
          </EuiFormRow>
          <EuiSpacer size='m' />
          <EuiCallOut title='Uploaded Image Requirements: ' color='primary' iconType='importAction'>
            <EuiText size='s'>
              <p>
                <strong>Acceptable image dimensions:</strong> (Dimensions must be exact).
                <br />
                {displayAdSizes.map(s => `${s.width}x${s.height}`).join(', ')} <br />
                <strong>Max file size:</strong> {acceptedFileSize / 1000000}MB or less <br />
                <strong>Accepted file formats:</strong> PNG, JPEG, JPG or GIF.
              </p>
            </EuiText>
          </EuiCallOut>
          <EuiSpacer size='m' />

          {(!isValidUpload || !isValidFile) && (
            <EuiCallOut title='Sorry, we ran into an issue uploading ur file.' iconType='document' color='warning'>
              <p>Check the file type and its dimensions to make sure it is an acceptable image.</p>
            </EuiCallOut>
          )}
          <EuiFormRow label='Size' fullWidth>
            <EuiSelect
              name='callToAction'
              options={displayAdSizes.map(
                x =>
                  ({
                    value: `${x.width}x${x.height}`,
                    label: `${x.width}x${x.height}`,
                    text: `${x.width}x${x.height}`
                  } as EuiSelectOption)
              )}
              value={size}
              onChange={v => setSize(v.target.value)}
              fullWidth
            />
          </EuiFormRow>
          <EuiFormRow
            label='Destination URL'
            fullWidth
            labelAppend={
              <EuiText style={{ display: 'flex', flexDirection: 'row' }}>
                <EuiToolTip position='bottom' content='The page users will land on after they click the ad.'>
                  <EuiIcon type='iInCircle' color='success' style={{ marginLeft: 5, marginBottom: 8 }} />
                </EuiToolTip>
                <EuiText size='xs'>({(adBuilder.clickUrl || '').length}/2048)</EuiText>
              </EuiText>
            }>
            <EuiFieldText
              name='destinationUrl'
              value={adBuilder.clickUrl}
              placeholder='https://www.domain.com'
              onChange={e => {
                setAdBuilder({ ...adBuilder, clickUrl: e.target.value })
              }}
              fullWidth
            />
          </EuiFormRow>
        </EuiFlexItem>

        <EuiFlexItem style={{ paddingLeft: 50, paddingRight: 50 }}>
          <EuiText size='s'>
            <h4>Preview Your Display Ad</h4>
          </EuiText>
          <EuiSpacer size='s' />
          <EuiFlexItem>
            {adBuilder.displayImageUrl && adBuilder.displayWidth && adBuilder.displayHeight ? <img src={adBuilder.displayImageUrl} alt={'uploaded display ad'} style={{ width: adBuilder.displayWidth, height: adBuilder.displayHeight, backgroundColor: 'lightgrey', borderRadius: 8 }} /> : <div style={{ width: 300, height: 250, backgroundColor: 'lightgrey', borderRadius: 8, textAlign: 'center', color: 'white', fontSize: 50, paddingTop: 90 }}> 300x250 </div>}
          </EuiFlexItem>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size='xl' />

      <EuiShowFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='continue' fill onClick={onSave} isDisabled={!isValid()}>
              Continue to Targeting
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={goToStart}>
              Back
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={goToStart} iconType='arrowLeft' iconSide='left'>
              Back
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='continue' fill iconType='arrowRight' iconSide='right' onClick={onSave} isDisabled={!isValid()}>
              Continue to Targeting
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiHideFor>
    </React.Fragment>
  )
}
