import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiLoadingContent, EuiSpacer, EuiText } from '@elastic/eui'

import { Campaign, CampaignActiveStatus, CampaignStatus, CampaignType } from 'api/interfaces'
import { campaignsApi, useGetCampaignQuery, useUpdateCampaignMutation } from 'api/rtkQueryApi/platform/campaignsApi'
import { showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import BudgetChangeModal from 'components/Modals/BudgetChangeModal'
import { BbMinTotalBudget, BbMinWeeklyBudget, BudgetMethod, CampaignBudget, CampaignBudgetSetter, InternetMinTotalBudget, InternetMinWeeklyBudget, TvMinTotalBudget, TvMinWeeklyBudget } from 'features/campaigns/CampaignBudgetSetter'
import UnpaidCallOut from 'features/campaigns/campaign/UnpaidCallOut'
import { fetchCampaign } from 'features/campaigns/campaign/campaignSlice'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

export interface ICampaignDetailsDrawer {
  campaign: Campaign
}

export const CampaignDetailsDrawer: React.FC<ICampaignDetailsDrawer> = props => {
  const campaignQuery = useGetCampaignQuery({ accountId: props.campaign.accountId, campaignId: props.campaign.id })
  const [updateCampaign, updateCampaignQuery] = useUpdateCampaignMutation()
  const dispatch = useDispatch()
  const [amount, setAmount] = useState<number>(0)
  const [isValid, setIsValid] = useState<boolean>(true)
  const { currentAccount, isVendastaUser, isVendastaPartnerUser, isStaffUser } = useSelector((state: RootState) => state.app)

  const [name, setName] = useState<string>('')
  const [maxCpm, setMaxCpm] = useState<number>(0)

  const [isBudgetChangeModalVisible, setIsBudgetChangeModalVisible] = useState(false)
  const [campaignBudget, setCampaignBudget] = useState<CampaignBudget>({
    budget: 0,
    method: BudgetMethod.Indefinite
  })
  const whiteLabel = useWhiteLabel()

  useEffect(() => {
    if (campaignQuery.data) {
      const campaign = campaignQuery.data
      setCampaignBudget({
        budget: campaign.budget,
        start: campaign.startDate ? moment.utc(campaign.startDate) : undefined,
        end: campaign.endDate ? moment.utc(campaign.endDate) : undefined,
        method: whiteLabel?.isAgencies() && campaign.startDate ? BudgetMethod.Dates : BudgetMethod.Indefinite
      })
      setName(campaign.name)
      setMaxCpm(campaign.maxCpm ?? 0)
    }
  }, [campaignQuery.data])

  useEffect(() => {
    setIsValid(isValidBudget(campaignBudget))
  }, [campaignBudget])

  const onBudgetChangeModalComplete = (shouldFetchCampaign: boolean) => {
    if (shouldFetchCampaign) {
      dispatch(fetchCampaign(currentAccount!.id, props.campaign.id))
      dispatch(campaignsApi.util.invalidateTags([{ type: 'Campaign', id: props.campaign.id }]))
    }
    setIsBudgetChangeModalVisible(false)
  }

  const onBudgetChangeModalCancel = () => {
    setIsBudgetChangeModalVisible(false)
  }

  const showBudgetChangeModal = (amount: number) => {
    if (campaignQuery.data) {
      setAmount(amount)
      setIsBudgetChangeModalVisible(true)
    }
  }

  const onSave = () => {
    if (!campaignQuery.data) {
      return
    }
    const amount = campaignBudget.budget - campaignQuery.data.budget
    if (amount > 0 && !campaignQuery.data.canRemove && campaignQuery.data.activeStatus === CampaignActiveStatus.Active) {
      showBudgetChangeModal(amount)
    } else {
      updateCampaign({ accountId: currentAccount!.id, campaignId: campaignQuery.data.id, name: name, budget: campaignBudget.budget, maxCpm, startDate: campaignBudget.start, endDate: campaignBudget.end }).then(() => {
        dispatch(showSuccessToast('Saved Campaign'))
        dispatch(fetchCampaign(currentAccount!.id, props.campaign.id))
      })
    }
  }

  const getMinWeeklyBudget = () => {
    if (props.campaign.type === CampaignType.Internet) {
      return InternetMinWeeklyBudget
    } else if (props.campaign.type === CampaignType.TV) {
      return TvMinWeeklyBudget
    } else if (props.campaign.type === CampaignType.Billboard) {
      return BbMinWeeklyBudget
    }
    return TvMinWeeklyBudget
  }

  const getMinTotalBudget = () => {
    if (props.campaign.type === CampaignType.Internet) {
      return InternetMinTotalBudget
    } else if (props.campaign.type === CampaignType.TV) {
      return TvMinTotalBudget
    } else if (props.campaign.type === CampaignType.Billboard) {
      return BbMinTotalBudget
    }
    return TvMinTotalBudget
  }

  const getMinStartDate = () => {
    if (!props.campaign?.activated || !props.campaign.startDate) {
      return isStaffUser ? moment() : moment().add(3, 'day')
    }

    const startDate = moment(props.campaign.startDate)
    if (startDate < moment()) {
      return startDate
    }

    return moment().add(3, 'day') < startDate ? moment().add(3, 'day') : startDate
  }

  const isValidBudget = (budget: CampaignBudget): boolean => {
    if (budget.method === BudgetMethod.Indefinite) {
      return budget.budget >= getMinWeeklyBudget() && !budget.start && !budget.end
    } else if (budget.method === BudgetMethod.Dates) {
      return !!(budget.budget >= getMinTotalBudget() && budget.start && budget.end && moment(budget.start).isBefore(budget.end) && (moment(budget.start).isSameOrAfter(moment(props.campaign.startDate), 'day') || moment(budget.start).endOf('day').isAfter(getMinStartDate().startOf('day'))))
    }

    return false
  }

  const onBudgetChange = (budget: CampaignBudget) => {
    setCampaignBudget(budget)
  }

  if (campaignQuery.isLoading || !campaignQuery.data) {
    return <EuiLoadingContent lines={10} />
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <React.Fragment>
            {campaignQuery.data.status === CampaignStatus.PaymentFailed && !campaignQuery.data.isUnpaidSubscriptionPayment && (
              <React.Fragment>
                <UnpaidCallOut campaignId={campaignQuery.data.id} hasBundle={campaignQuery.data.hasBundle} />
                <EuiSpacer />
              </React.Fragment>
            )}
            <EuiFormRow label='Campaign Name'>
              <EuiFieldText name='name' value={name} onChange={e => setName(e.target.value)} placeholder='' fullWidth />
            </EuiFormRow>
            <EuiSpacer />
            {(!isVendastaUser || isVendastaPartnerUser) && campaignQuery.data && campaignBudget && <CampaignBudgetSetter campaign={campaignQuery.data} campaignBudget={campaignBudget} onBudgetChange={onBudgetChange} />}
            <EuiFormRow>
              <EuiButton id='save' fill onClick={onSave} disabled={!isValid} isLoading={updateCampaignQuery.isLoading}>
                Save
              </EuiButton>
            </EuiFormRow>
          </React.Fragment>
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: 260 }}>
          <EuiText size='xs'>
            <h3>Best Practices</h3>
            <h5>Running Campaigns</h5>
            <p>While campaigns can be edited anytime, it is generally a best practice to change things slowly and carefully. For example, making frequent changes to a campaign's targeting or budget settings can hinder the machine learning and optimization algorithms.</p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <BudgetChangeModal campaign={campaignQuery.data} isBudgetChangeModalVisible={isBudgetChangeModalVisible} name={name} budget={campaignBudget.budget} differenceAmount={amount} onConfirmComplete={onBudgetChangeModalComplete} onCancel={onBudgetChangeModalCancel} />
    </React.Fragment>
  )
}
