import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiFlyout, EuiFlyoutBody, EuiPortal } from '@elastic/eui'

import { setSelfServePackagesFlyoutOpen } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { SelfServicePackagesPageCheck } from 'features/selfServicePackages/SelfServicePackagesPageCheck'
import SelfServicePackagesAvailablePage from 'features/selfServicePackages/pages/SelfServicePackagesAvailablePage'
import SelfServicePackagesPage from 'features/selfServicePackages/pages/SelfServicePackagesPage'

export enum PageShown {
  ReadyPackages,
  ReservedPackages
}

export const SelfServicePackagesFlyoutPage: FC = () => {
  const dispatch = useDispatch()
  const { selfServePackagesFlyoutOpen } = useSelector((state: RootState) => state.app)
  const [pageShown, setPageShown] = React.useState<PageShown>(PageShown.ReadyPackages)

  const closeFlyout = () => dispatch(setSelfServePackagesFlyoutOpen(false))

  const onViewAvailablePackagesClick = () => {
    setPageShown(PageShown.ReservedPackages)
  }

  const onToPackagesClick = () => {
    setPageShown(PageShown.ReadyPackages)
  }

  if (selfServePackagesFlyoutOpen) {
    return (
      <EuiPortal>
        <EuiFlyout onClose={closeFlyout} size='l' aria-labelledby='flyoutLargeTitle' ownFocus hideCloseButton>
          <EuiFlyoutBody>
            <SelfServicePackagesPageCheck>
              <React.Fragment>
                {pageShown === PageShown.ReadyPackages && <SelfServicePackagesPage onViewAvailablePackagesClick={onViewAvailablePackagesClick} />}
                {pageShown === PageShown.ReservedPackages && <SelfServicePackagesAvailablePage onToPackagesClick={onToPackagesClick} />}
              </React.Fragment>
            </SelfServicePackagesPageCheck>
          </EuiFlyoutBody>
        </EuiFlyout>
      </EuiPortal>
    )
  }

  return <React.Fragment />
}
