import React from 'react'
import { Helmet } from 'react-helmet'

import { EuiSpacer, EuiPanel, EuiTitle } from '@elastic/eui'

import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import RestartForm from './RestartForm'

const RestartPage: React.FC = () => {
  const whiteLabel = useWhiteLabel()

  return (
    <React.Fragment>
      <Helmet>
        <title>Restart</title>
      </Helmet>
      <div
        style={{
          width: '100%',
          height: '100%',
          minHeight: 500,
          background: 'linear-gradient(0deg, #D3DAE6 50%, #232e40 50%)',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: 'repeat(3, 1fr)',
          justifyItems: 'center',
          alignItems: 'center'
        }}>
        <div style={{ width: 450, height: 500, gridRow: '2/3' }}>
          <div style={{ width: 450, textAlign: 'center' }}>
            <img src={whiteLabel?.getLogo()} alt={whiteLabel?.getName()} style={{ marginBottom: 25, width: 220 }} />
          </div>
          <EuiPanel style={{ width: 450 }} hasShadow={true}>
            <EuiTitle>
              <h2>Restart Your Platform Access</h2>
            </EuiTitle>
            <EuiSpacer />
            <RestartForm />
          </EuiPanel>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RestartPage
