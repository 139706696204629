import React from 'react'
import { Helmet } from 'react-helmet'

import { EuiText, EuiSpacer, EuiLink, EuiTitle, EuiPanel } from '@elastic/eui'

import history from 'services/HistoryService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import ForgotForm from './ForgotForm'

const ForgotPage: React.FC = () => {
  const whiteLabel = useWhiteLabel()

  return (
    <React.Fragment>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <div
        style={{
          width: '100%',
          height: '100%',
          minHeight: 400,
          background: 'linear-gradient(0deg, #D3DAE6 50%, #232e40 50%)',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: 'repeat(3, 1fr)',
          justifyItems: 'center',
          alignItems: 'center'
        }}>
        <div style={{ width: 380, height: 400, gridRow: '2/3' }}>
          <div style={{ width: 380, textAlign: 'center' }}>
            <img src={whiteLabel?.getLogo()} alt={whiteLabel?.getName()} style={{ marginBottom: 15, width: 220 }} />
          </div>
          <EuiPanel style={{ width: 380 }} hasShadow={true}>
            <EuiTitle>
              <h2>Password Recovery</h2>
            </EuiTitle>
            <ForgotForm />
            <EuiSpacer />

            <EuiText size='xs'>
              If you remember your password, please{' '}
              <EuiLink
                color='success'
                onClick={() => {
                  history.push('/login')
                }}>
                Log In Here
              </EuiLink>
            </EuiText>
          </EuiPanel>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ForgotPage
