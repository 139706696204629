import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, useLocation } from 'react-router-dom'

import { RootState } from 'app/rootReducer'
import { useVendastaCheckIsAuthenticated } from 'features/vendasta/login/useVendastaCheckIsAuthenticated'

export default function PrivateRoute({ children, ...rest }: any) {
  const { pathname, search } = useLocation()
  const { isLoggedIn } = useSelector((state: RootState) => state.app)
  useVendastaCheckIsAuthenticated()

  return <Route {...rest}>{isLoggedIn ? children : <Redirect to={`/login?redirect=${encodeURIComponent(pathname + search)}`} />}</Route>
}
