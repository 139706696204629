import React from 'react'
import { Helmet } from 'react-helmet'

import { EuiButton, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiHideFor, EuiShowFor, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { ICampaignBuilderWorkflowStep } from 'features/builders/createCampaign/creativeBuilder/CreativeBuilderWorkflowManager'
import { useCampaignBuilderActions } from 'features/builders/createCampaign/useCampaignBuilderActions'

export const CustomAdPage: React.FC<ICampaignBuilderWorkflowStep> = props => {
  const { customAdContinue } = useCampaignBuilderActions()
  const onBackClick = () => {
    props.onBack?.()
  }

  const onContinueClick = () => {
    customAdContinue()
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Custom Ads</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Custom Ads</h3>
      </EuiTitle>
      <EuiSpacer />
      <EuiCallOut title='We Will Contact You'>
        <EuiText size='s'>
          <p>In order to create custom ads for your business, we will need to contact you to gather details (so we can make great ads).</p>
          <p>Please continue setting up your campaign and at the end of the process we will verify your contact information so your campaign advisor can get in touch.</p>
        </EuiText>
      </EuiCallOut>
      <EuiSpacer />

      <EuiShowFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='submit' fill onClick={onContinueClick}>
              Continue to Targeting
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={onBackClick}>
              Back
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={onBackClick} iconType='arrowLeft' iconSide='left'>
              Back
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='submit' fill onClick={onContinueClick} iconType='arrowRight' iconSide='right'>
              Continue to Targeting
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiHideFor>
    </React.Fragment>
  )
}
