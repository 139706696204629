import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import YupPassword from 'yup-password'

import { EuiText, EuiSpacer, EuiLink, EuiTitle, EuiPanel, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiFieldText, EuiFieldPassword, EuiButton, EuiCard } from '@elastic/eui'

import { ApiError, apiVistaSignUp } from 'api/adcritterApi'
import { BusinessType } from 'api/interfaces'
import { updateAppState } from 'app/appSlice'
import { BusinessTypeFinder } from 'components/finders/BusinessTypeFinder'
import history from 'services/HistoryService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

YupPassword(Yup)

interface FormValues {
  firstName: string
  lastName: string
  company: string
  email: string
  password: string
  website: string
  businessType: string
}

const VistaSignUpPage: React.FC = () => {
  const dispatch = useDispatch()
  const whiteLabel = useWhiteLabel()
  const [selectedBusinessType, setSelectedBusinessType] = useState<BusinessType | null>(null)

  const signUpSchema = Yup.object().shape({
    firstName: Yup.string().max(128).required('Please enter your first name'),
    lastName: Yup.string().max(128).required('Please enter your last name'),
    company: Yup.string().max(128).required('Please enter a company name'),
    website: Yup.string().max(2048).required('Please enter a website').lowercase().trim(),
    email: Yup.string().email().required('Please enter your email').lowercase().trim(),
    password: Yup.string().required('Please enter a password').min(6, 'Password is too short, minimum of 6 characters').minUppercase(1, 'Password must contain at least 1 upper case letter').minLowercase(1, 'Password must contain at least 1 lower case letter').minNumbers(1, 'Password must contain at least 1 number'),
    businessType: Yup.string().required('Please choose a business type')
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      company: '',
      website: '',
      businessType: ''
    },
    validationSchema: signUpSchema,
    onSubmit: (values: FormValues) => {
      apiVistaSignUp(values.firstName, values.lastName, values.company, values.website, values.email, values.password, values.businessType)
        .then(authResponse => {
          if (authResponse.errors) {
            if (authResponse.errors[0].name) {
              authResponse.errors.forEach(function (e: ApiError) {
                formik.setFieldError(e.name, e.message)
              })
            } else {
              formik.setStatus(authResponse.errors[0].message)
            }
          } else {
            dispatch(
              updateAppState({
                isLoggedIn: true,
                accessToken: authResponse.data.accessToken,
                account: authResponse.data.accounts[0],
                organization: authResponse.data.organization,
                user: {
                  name: authResponse.data.name,
                  email: authResponse.data.email,
                  roles: authResponse.data.roles,
                  picture: authResponse.data.picture,
                  canAccessAllAccounts: authResponse.data.canAccessAllAccounts
                },
                isInSignupProcess: authResponse.data.isInSignupProcess
              })
            )
            history.push('/build/internet/vista/start')
          }
          formik.setSubmitting(false)
        })
        .catch(authResponse => {
          formik.setSubmitting(false)
          authResponse.errors.forEach(function (e: ApiError) {
            formik.setFieldError(e.name, e.message)
          })
        })
    }
  })

  const selectBusinessType = (selected: BusinessType) => {
    setSelectedBusinessType(selected)
    formik.setFieldValue('businessType', selected.id)
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#040f4e',
        minHeight: 980,
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'repeat(3, 1fr)',
        justifyItems: 'center',
        alignItems: 'center'
      }}>
      <div style={{ gridRow: '2/3', width: '100%', padding: 24, height: '100%' }}>
        <EuiFlexGroup responsive={true}>
          <EuiFlexItem grow={1}>
            <img src={whiteLabel?.getSquareLogo()} alt={whiteLabel?.getName()} style={{ marginBottom: 25, width: 320 }} />
            <EuiText color='white'>
              <EuiTitle>
                <h1>Get Started</h1>
              </EuiTitle>
              <EuiTitle size='xxs'>
                <h3>Tell us something about your business.</h3>
              </EuiTitle>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={2}>
            <EuiPanel hasShadow={true}>
              <EuiTitle size='xxs'>
                <h2>Signup</h2>
              </EuiTitle>
              <EuiSpacer />
              <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.status && !!formik.errors}>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow label='First Name' fullWidth isInvalid={formik.touched.firstName && !!formik.errors.firstName} error={formik.errors.firstName}>
                      <EuiFieldText name='firstName' value={formik.values.firstName} onChange={formik.handleChange} placeholder='' fullWidth isInvalid={formik.touched.firstName && !!formik.errors.firstName} />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow label='Last Name' fullWidth isInvalid={formik.touched.lastName && !!formik.errors.lastName} error={formik.errors.lastName}>
                      <EuiFieldText name='lastName' value={formik.values.lastName} onChange={formik.handleChange} placeholder='' fullWidth isInvalid={formik.touched.lastName && !!formik.errors.lastName} />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer size='m' />
                <EuiFormRow label='Company' fullWidth isInvalid={formik.touched.company && !!formik.errors.company} error={formik.errors.company}>
                  <EuiFieldText name='company' value={formik.values.company} onChange={formik.handleChange} placeholder='' fullWidth isInvalid={formik.touched.company && !!formik.errors.company} />
                </EuiFormRow>
                <EuiFormRow label='Website' fullWidth isInvalid={formik.touched.website && !!formik.errors.website} error={formik.errors.website}>
                  <EuiFieldText name='website' value={formik.values.website} onChange={formik.handleChange} placeholder='https://www.domain.com' fullWidth isInvalid={formik.touched.website && !!formik.errors.website} />
                </EuiFormRow>
                <EuiSpacer size='xl' />
                <EuiFormRow label='Email' fullWidth isInvalid={formik.touched.email && !!formik.errors.email} error={formik.errors.email}>
                  <EuiFieldText name='email' value={formik.values.email} onChange={formik.handleChange} icon='email' placeholder='username@domain.com' fullWidth isInvalid={formik.touched.email && !!formik.errors.email} />
                </EuiFormRow>
                <EuiFormRow label='Password' fullWidth isInvalid={formik.touched.password && !!formik.errors.password} error={formik.errors.password}>
                  <EuiFieldPassword name='password' value={formik.values.password} fullWidth isInvalid={formik.touched.password && !!formik.errors.password} onChange={formik.handleChange} />
                </EuiFormRow>
                <EuiSpacer size='xl' />
                <EuiText size='s'>To create the best ads for your company, please tell us what type of business you want to advertise for.</EuiText>
                <EuiSpacer size='s' />
                <EuiFormRow label='Business Type' fullWidth isInvalid={formik.touched.businessType && !!formik.errors.businessType} error={formik.errors.businessType}>
                  <React.Fragment>
                    {selectedBusinessType && (
                      <React.Fragment>
                        <EuiCard
                          display='primary'
                          layout='horizontal'
                          paddingSize='s'
                          titleSize='xs'
                          icon={
                            <div>
                              <img alt='' src={selectedBusinessType.imageUrl} />
                            </div>
                          }
                          title={selectedBusinessType.name}
                          description={selectedBusinessType.businessTypeCategories.join(', ')}
                        />
                        <EuiSpacer size='s' />
                        <EuiLink color='success' onClick={() => setSelectedBusinessType(null)}>
                          Clear selected business type
                        </EuiLink>
                      </React.Fragment>
                    )}
                    {!selectedBusinessType && <BusinessTypeFinder onBusinessTypeClicked={selectBusinessType} isInvalid={formik.touched.businessType && !!formik.errors.businessType} />}
                  </React.Fragment>
                </EuiFormRow>
                <EuiSpacer />
                <EuiButton id='signUp' fill type='submit' isLoading={formik.isSubmitting}>
                  Next
                </EuiButton>
              </EuiForm>

              <EuiSpacer />
              <EuiText size='xs'>
                By clicking, you agree to our{' '}
                <EuiLink color='success' href='https://adcritter.com/terms' external target='_blank'>
                  Terms
                </EuiLink>{' '}
                &{' '}
                <EuiLink color='success' href='https://adcritter.com/privacy' external target='_blank'>
                  Privacy Policy
                </EuiLink>
              </EuiText>
              <EuiSpacer size='s' />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </div>
  )
}

export default VistaSignUpPage
