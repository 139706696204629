import React, { FC } from 'react'

import { EuiSpacer } from '@elastic/eui'

import { InsightsConfiguration } from 'api/interfaces'
import { useGetCampaignInsightsConfigurationQuery, useGetCampaignInsightsTimeToBehaviorModificationCurrentMonthQuery } from 'api/rtkQueryApi/platform/campaignInsightsApi'
import InsightsTimeToBehaviorModificiationProgress from 'components/insights/Charts/InsightsTimeToBehaviorModificationChart'
import InsightsPanel from 'components/insights/Components/InsightsPanel'
import { timeToBehaviorButtonColor, timeToBehaviorButtonTextColor, timeToBehaviorDescription, timeToBehaviorRightPanelColor, timeToBehaviorRightPanelDescription } from 'components/insights/InsightsConstants'

export interface InsightsTimeToBehaviorModificationProps {
  isLoading: boolean
  timeToBehaviorCurrentMonth?: number
  config?: InsightsConfiguration
}

const InsightsTimeToBehaviorModification: FC<InsightsTimeToBehaviorModificationProps> = ({ isLoading, timeToBehaviorCurrentMonth, config }) => {
  let remainingMonths
  if (isLoading || !config || typeof timeToBehaviorCurrentMonth === undefined) {
    remainingMonths = 0
  } else {
    remainingMonths = config.timeToBehaviorMilestoneBehaviorModified - (timeToBehaviorCurrentMonth ?? 0)

    if (remainingMonths <= 0) {
      remainingMonths = 0
    }
  }

  return (
    <InsightsPanel isLoading={isLoading} title='Time to Behavior Modification' description={timeToBehaviorDescription} buttonColor={timeToBehaviorButtonColor} buttonTextColor={timeToBehaviorButtonTextColor} rightPanelTitle={remainingMonths.toLocaleString()} rightPanelTitleDescription='months' rightPanelDescription={timeToBehaviorRightPanelDescription} rightPanelColor={timeToBehaviorRightPanelColor}>
      <EuiSpacer size='s' />

      <InsightsTimeToBehaviorModificiationProgress isLoading={isLoading} config={config} timeToBehaviorCurrentMonth={timeToBehaviorCurrentMonth} />

      <EuiSpacer size='s' />
    </InsightsPanel>
  )
}

export default InsightsTimeToBehaviorModification
