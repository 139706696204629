import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSpacer, EuiText, EuiTextArea, EuiTitle } from '@elastic/eui'

import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

import { Questionnaire, setQuestionnaire } from './createAdBillboardRetailSlice'

const QuestionnaireStep: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const createAd = useSelector((state: RootState) => state.createAdBillboardRetail)
  const [initialValues, setInitialValues] = useState<Questionnaire>({
    companyName: '',
    website: '',
    mainProduct: '',
    why: '',
    whatElse: '',
    phone: '',
    name: ''
  })

  useEffect(() => {
    if (createAd.questionnaire) {
      setInitialValues(createAd.questionnaire)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAd.questionnaire, currentAccount])

  const questionnaireSchema = Yup.object().shape({
    companyName: Yup.string().required('Please enter your company name'),
    website: Yup.string().required('Please enter your URL'),
    mainProduct: Yup.string().required('Please tell us your main product/service'),
    why: Yup.string().required('Please tell us why people buy from you'),
    whatElse: Yup.string(),
    phone: Yup.string().required('Please provide your phone number'),
    name: Yup.string().required('Please provide your name')
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: questionnaireSchema,
    onSubmit: (values: Questionnaire) => {
      dispatch(setQuestionnaire(values))
      history.push('/campaigns/billboards/public/done')
    }
  })

  const gotoStart = () => {
    history.push('/campaigns/billboards/public/start')
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Custom Billboards</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Custom Billboards</h3>
      </EuiTitle>
      <EuiText size='s'>
        <p>Answer the questions below and our ad team will make you a suite of custom billboards. Allow 2-3 business days.</p>
      </EuiText>
      <EuiSpacer />

      <React.Fragment>
        <EuiFormRow label='Company Name' helpText='Your company name, as it should appear in billboards.' fullWidth isInvalid={formik.touched.companyName && !!formik.errors.companyName} error={formik.errors.companyName}>
          <EuiFieldText name='companyName' value={formik.values.companyName} isInvalid={formik.touched.companyName && !!formik.errors.companyName} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth />
        </EuiFormRow>
        <EuiFormRow label='Website' helpText='Where we can learn about your business.' fullWidth isInvalid={formik.touched.website && !!formik.errors.website} error={formik.errors.website}>
          <EuiFieldText name='website' value={formik.values.website} onChange={formik.handleChange} fullWidth isInvalid={formik.touched.website && !!formik.errors.website} />
        </EuiFormRow>
        <EuiSpacer />
        <EuiFormRow label='What is the main product/service you want to advertise?' fullWidth isInvalid={formik.touched.mainProduct && !!formik.errors.mainProduct} error={formik.errors.mainProduct}>
          <EuiTextArea name='mainProduct' value={formik.values.mainProduct} onChange={formik.handleChange} fullWidth isInvalid={formik.touched.mainProduct && !!formik.errors.mainProduct} />
        </EuiFormRow>
        <EuiFormRow label='Why do people choose to buy from you?' fullWidth isInvalid={formik.touched.why && !!formik.errors.why} error={formik.errors.why}>
          <EuiTextArea name='why' value={formik.values.why} onChange={formik.handleChange} fullWidth isInvalid={formik.touched.why && !!formik.errors.why} />
        </EuiFormRow>
        <EuiFormRow label='What else should we know about your business?' fullWidth isInvalid={formik.touched.whatElse && !!formik.errors.whatElse} error={formik.errors.whatElse}>
          <EuiTextArea name='whatElse' value={formik.values.whatElse} onChange={formik.handleChange} fullWidth isInvalid={formik.touched.whatElse && !!formik.errors.whatElse} />
        </EuiFormRow>
        <EuiSpacer />
        <EuiText size='xs' color={'subdued'}>
          <span color={''}>Best way to reach you to discuss these ads</span>
        </EuiText>
        <EuiSpacer size='m' />
        <div style={{ marginLeft: 20 }}>
          <EuiFormRow label='Name' fullWidth isInvalid={formik.touched.name && !!formik.errors.name} error={formik.errors.name}>
            <EuiFieldText name='name' value={formik.values.name} onChange={formik.handleChange} fullWidth isInvalid={formik.touched.name && !!formik.errors.name} />
          </EuiFormRow>
          <EuiFormRow label='Phone' fullWidth isInvalid={formik.touched.phone && !!formik.errors.phone} error={formik.errors.phone}>
            <EuiFieldText name='phone' value={formik.values.phone} onChange={formik.handleChange} fullWidth isInvalid={formik.touched.phone && !!formik.errors.phone} />
          </EuiFormRow>
        </div>
      </React.Fragment>

      <EuiSpacer />

      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton id='back' fill type='button' color='text' onClick={gotoStart} iconType='arrowLeft' iconSide='left'>
            Back
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton id='submit' fill type='submit' isLoading={formik.isSubmitting} iconType='arrowRight' iconSide='right' onClick={formik.submitForm}>
            Submit to Ad Team
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default QuestionnaireStep
