import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { EuiPage, EuiPageBody } from '@elastic/eui'

import InvoicePage from 'features/invoices/InvoicePage'

const InvoicesPage: React.FC = () => {
  const { path } = useRouteMatch()

  return (
    <EuiPage className='euiPage--platform' style={{ backgroundColor: '#f8fafd' }}>
      <EuiPageBody>
        <Switch>
          <Route path={`${path}/:invoiceHandle`}>
            <InvoicePage />
          </Route>
        </Switch>
      </EuiPageBody>
    </EuiPage>
  )
}

export default InvoicesPage
