import React, { useEffect, useState } from 'react'
import { NativeDetails } from 'workflows/CampaignCreateWorkflow'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiImage, EuiPanel, EuiSpacer, EuiText, htmlIdGenerator } from '@elastic/eui'

export enum PreviewSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}
export interface INativePreview {
  nativeDetails: NativeDetails
  previewSize: PreviewSize
}

export const NativePreview: React.FC<INativePreview> = props => {
  const divId = htmlIdGenerator()()
  const [width, setWidth] = useState(0)
  const [imgUrl, setImgUrl] = useState('/1200x667.png')
  const [useDiv, setUseDiv] = useState(false)

  useEffect(() => {
    setWidth(document.getElementById(divId)?.clientWidth || 0)
  }, [divId])

  useEffect(() => {
    const image = props.nativeDetails.acImage
    if (image) {
      setUseDiv(!!image.thumbnailWidth)
      setImgUrl(image.highQualityUrl)
    }
  }, [props.nativeDetails.acImage])

  const ad = props.nativeDetails

  return (
    <EuiFlexGroup style={{ marginTop: 20 }}>
      <EuiFlexItem grow={true} />
      {props.previewSize === PreviewSize.Large && (
        <EuiFlexItem grow={false} style={{ width: 520 }}>
          <EuiPanel paddingSize='none'>
            <EuiFlexGroup responsive={false} style={{ paddingTop: 10 }}>
              <EuiFlexItem style={{ paddingLeft: 10, paddingRight: 10 }}>
                <EuiText size='s' color='warning'>
                  <strong>Sponsored</strong>
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup responsive={false}>
              <EuiFlexItem style={{ paddingRight: 10, paddingLeft: 10, paddingBottom: 10 }}>
                <EuiText size='s'>
                  <h3>{ad?.headline || 'Attention Grabbing Headline'}</h3>
                  <p>{ad?.body || 'One or two sentences describing your product or service.'}</p>
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
            {useDiv && (
              <div id={divId} style={{ height: (ad.acImage?.thumbnailHeight! / ad.acImage?.thumbnailWidth!) * width, overflow: 'hidden' }}>
                <img alt='ad' src={imgUrl} width={'100%'} />
              </div>
            )}
            {!useDiv && <EuiImage alt='ad' url={imgUrl} size='fullWidth' />}
            <EuiSpacer size='s' />
            <EuiFlexGroup responsive={false} style={{ paddingBottom: 10 }}>
              <EuiFlexItem grow={3} style={{ paddingLeft: 10, justifyContent: 'center' }}>
                <EuiText size='m' color='subdued'>
                  <strong>{ad?.companyName || 'Your Company Name'}</strong>
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={1} style={{ paddingRight: 10 }}>
                <EuiButton id='callToAction' href={ad?.clickUrl || 'https://google.com'} target='_blank' fullWidth={false} style={{ borderRadius: 0 }}>
                  {ad?.buttonText || 'Learn More'}
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
      )}
      {props.previewSize === PreviewSize.Medium && (
        <EuiFlexItem grow={false} style={{ width: 300 }}>
          <EuiPanel paddingSize='none'>
            <EuiFlexGroup responsive={false} style={{ paddingTop: 10 }}>
              <EuiFlexItem style={{ paddingLeft: 10, paddingRight: 10 }}>
                <EuiText size='s' color='warning'>
                  <strong>Sponsored</strong>
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup responsive={false}>
              <EuiFlexItem style={{ paddingRight: 10, paddingLeft: 10, paddingBottom: 10 }}>
                <EuiText size='s'>
                  <h3>{ad?.headline || 'Attention Grabbing Headline'}</h3>
                  <p>{ad?.body || 'One or two sentences describing your product or service.'}</p>
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
            {useDiv && (
              <div id={divId} style={{ height: (ad.acImage!.thumbnailHeight! / ad.acImage!.thumbnailWidth!) * width, overflow: 'hidden' }}>
                <img alt='ad' src={imgUrl} width={'100%'} />
              </div>
            )}
            {!useDiv && <EuiImage alt='ad' url={imgUrl} size='fullWidth' />}
            <EuiSpacer size='s' />
            <EuiFlexGroup responsive={false} style={{ paddingBottom: 10 }}>
              <EuiFlexItem grow={3} style={{ paddingLeft: 10, justifyContent: 'center' }}>
                <EuiText size='m' color='subdued'>
                  <strong>{ad?.companyName || 'Your Company Name'}</strong>
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={1} style={{ paddingRight: 10 }}>
                <EuiButton id='callToAction' href={ad?.clickUrl || 'https://google.com'} target='_blank' fullWidth={false} style={{ borderRadius: 0 }}>
                  {ad?.buttonText || 'Learn more'}
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
      )}
      {props.previewSize === PreviewSize.Small && (
        <EuiFlexItem grow={false} style={{ width: 320 }}>
          <EuiPanel paddingSize='none'>
            <EuiFlexGroup responsive={false} style={{ padding: 10 }} gutterSize='none'>
              <EuiFlexItem style={{ width: 100 }} grow={false}>
                {useDiv && (
                  <div id={divId} style={{ height: (ad.acImage!.thumbnailHeight! / ad.acImage!.thumbnailWidth!) * width, overflow: 'hidden' }}>
                    <img alt='ad' src={imgUrl} width={'100%'} />
                  </div>
                )}
                {!useDiv && <EuiImage alt='ad' url={imgUrl} size='fullWidth' />}
              </EuiFlexItem>
              <EuiFlexItem style={{ marginLeft: 10 }}>
                <EuiText size='m'>
                  <h5>{ad?.headline || 'Attention Grabbing Headline'}</h5>
                </EuiText>
                <EuiSpacer size='s' />
                <EuiText size='xs' color='subdued'>
                  <strong>{ad?.companyName || 'Your Company Name'}</strong>
                </EuiText>
                <EuiSpacer size='s' />
                <EuiText size='xs' color='warning'>
                  <strong>Ad</strong>
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
      )}
      <EuiFlexItem grow={true} />
    </EuiFlexGroup>
  )
}
