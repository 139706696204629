import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiLoadingContent, EuiShowFor, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { AdImagePlacement, BillboardAdImageType, BillboardSuggestion } from 'api/interfaces'
import { useGetBusinessTypeQuery } from 'api/rtkQueryApi/platform/businessTypesApi'
import { RootState } from 'app/rootReducer'
import { BillboardCard } from 'components/Cards/BillboardCard'
import history from 'services/HistoryService'

import { fetchBillboards, setPreviewUrl, setBillboard, setCreate, setLoaded, setBusinessType, setSuggest } from './builderBillboardRetailSlice'

const SuggestionsStep: React.FC = () => {
  const dispatch = useDispatch()
  const builder = useSelector((state: RootState) => state.builderBillboardRetail)
  const [chosenBillboard, setChosenBillboard] = useState<BillboardSuggestion>()
  const { currentAccount, isShopifyUser } = useSelector((state: RootState) => state.app)
  const campaign = useSelector((state: RootState) => state.campaign)
  const chosenBusinessTypeResponse = useGetBusinessTypeQuery({ id: currentAccount?.businessTypeId ?? '' }, { skip: !!builder.businessType || !currentAccount?.businessTypeId })

  useEffect(() => {
    if (chosenBusinessTypeResponse.data) {
      dispatch(setBusinessType(chosenBusinessTypeResponse.data))
    }
  }, [chosenBusinessTypeResponse.data])

  useEffect(() => {
    if (!builder.suggest) {
      dispatch(
        setSuggest({
          companyName: currentAccount?.name ?? '',
          cityName: currentAccount?.city ?? ''
        })
      )
    }
  }, [currentAccount])

  useEffect(() => {
    if (!builder.billboards && !builder.isLoadingBillboards && builder.businessType && currentAccount) {
      dispatch(fetchBillboards(currentAccount.id, builder.businessType.id, currentAccount.name, currentAccount.city))
    }
  }, [dispatch, currentAccount, builder.businessType, builder.billboards, builder.isLoadingBillboards])

  useEffect(() => {
    if (chosenBillboard && currentAccount && builder.suggest) {
      dispatch(setBillboard(chosenBillboard))
      let website = ''
      if (currentAccount.website) {
        try {
          let domain = new URL(currentAccount.website)
          website = domain.host
        } catch (TypeError) {
          website = currentAccount.website
        }
      }
      dispatch(
        setCreate({
          headline: {
            fontFace: 'roboto',
            fontSize: 75,
            fontColor: '#000000',
            sampleText: '',
            selected: true,
            x: 50,
            y: 50,
            width: 200,
            height: 60,
            align: 'left'
          },
          companyName: {
            fontFace: 'roboto',
            fontSize: 75,
            fontColor: '#000000',
            sampleText: '',
            selected: true,
            x: 50,
            y: 110,
            width: 200,
            height: 60,
            align: 'left'
          },
          phone: {
            fontFace: 'roboto',
            fontSize: 75,
            fontColor: '#000000',
            sampleText: '',
            selected: true,
            x: 50,
            y: 170,
            width: 200,
            height: 60,
            align: 'left'
          },
          location: {
            fontFace: 'roboto',
            fontSize: 75,
            fontColor: '#000000',
            sampleText: '',
            selected: true,
            x: 1100,
            y: 110,
            width: 200,
            height: 60,
            align: 'left'
          },
          website: {
            fontFace: 'roboto',
            fontSize: 75,
            fontColor: '#000000',
            sampleText: website,
            selected: true,
            x: 1100,
            y: 110,
            width: 200,
            height: 60,
            align: 'left'
          },
          backgroundUrl: '',
          uploadedUrl: '',
          previewUrl: '',
          photo: {
            sampleUrl: 'https://devopenshotstorage.blob.core.windows.net/75015b99-5e46-4fdd-bbeb-6adeb6cfc565/41d18b34-7d9d-4989-a5bf-c520b04e7dbb.jpg',
            x: 600,
            y: 50
          },
          logo: {
            sampleUrl: 'https://devopenshotstorage.blob.core.windows.net/75015b99-5e46-4fdd-bbeb-6adeb6cfc565/41d18b34-7d9d-4989-a5bf-c520b04e7dbb.jpg',
            x: 1200,
            y: 300
          },
          campaignIds: [campaign.campaign?.id!],
          billboardImageType: BillboardAdImageType.None,
          imageLocation: AdImagePlacement.Right,
          id: chosenBillboard.id,
          name: chosenBillboard.name,
          type: chosenBillboard.type,
          quality: chosenBillboard.quality,
          kind: chosenBillboard.kind,
          thumbnailUrl: chosenBillboard.thumbnailUrl,
          status: chosenBillboard.status
        })
      )
      dispatch(setPreviewUrl(chosenBillboard.previewUrl))
      history.push('/build/billboards/public/create')
    }
  }, [dispatch, currentAccount, chosenBillboard, campaign.campaign, builder.suggest])

  const gotoSuggest = () => {
    history.push('/build/billboards/public/suggest')
  }

  const gotoStart = () => {
    history.push('/build/billboards/public/start')
  }

  const onBackClick = () => {
    if (isShopifyUser) {
      gotoStart()
    } else {
      gotoSuggest()
    }
  }

  const suggestedBillboards = (() => {
    return builder.billboards?.map((b, i) => {
      let displayBillboard: BillboardSuggestion = { ...b }
      displayBillboard.headline = { ...displayBillboard.headline }
      displayBillboard.headline.sampleText = b.suggestedHeader
      if (currentAccount?.name) {
        displayBillboard.companyName = { ...displayBillboard.companyName }
        displayBillboard.companyName.sampleText = currentAccount?.name
      }
      if (currentAccount?.website) {
        displayBillboard.website = { ...displayBillboard.website }
        displayBillboard.website.sampleText = currentAccount?.website
      }
      if (displayBillboard.headline.width === 0) displayBillboard.headline.width = 200
      if (displayBillboard.headline.height === 0) displayBillboard.headline.height = 200
      return (
        <BillboardCard
          key={i}
          billboard={displayBillboard}
          header={currentAccount?.name}
          onUseClick={() => {
            setChosenBillboard(displayBillboard)
            setLoaded(false)
          }}
        />
      )
    })
  })()

  return (
    <React.Fragment>
      <Helmet>
        <title>Billboards</title>
        <link href='https://fonts.googleapis.com/css2?family=Roboto&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css2?family=Merienda&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css2?family=Noticia+Text&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css2?family=Comfortaa&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Comfortaa&display=swap' rel='stylesheet' />
      </Helmet>
      <EuiTitle size='s'>
        <h3>Billboards</h3>
      </EuiTitle>
      <EuiText size='s'>
        <p>Browse and edit these suggested billboards.</p>
      </EuiText>
      <EuiSpacer />

      {builder.isLoadingBillboards && <EuiLoadingContent lines={3} />}
      {builder.billboards && (
        <React.Fragment>
          {builder.billboards && builder.billboards.length > 0 && (
            <React.Fragment>
              <EuiShowFor sizes={['s', 'xs']}>
                <EuiFlexGrid columns={1} responsive={true}>
                  {suggestedBillboards}
                </EuiFlexGrid>
              </EuiShowFor>
              <EuiShowFor sizes={['l', 'm']}>
                <EuiFlexGrid columns={2} responsive={true}>
                  {suggestedBillboards}
                </EuiFlexGrid>
              </EuiShowFor>
              <EuiShowFor sizes={['xl']}>
                <EuiFlexGrid columns={3} responsive={true}>
                  {suggestedBillboards}
                </EuiFlexGrid>
              </EuiShowFor>
            </React.Fragment>
          )}
          <EuiSpacer />
        </React.Fragment>
      )}

      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton id='back' fill type='button' color='text' onClick={onBackClick} iconType='arrowLeft' iconSide='left'>
            Back
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default SuggestionsStep
