import React, { FC } from 'react'

import { ReportAd } from 'api/interfaces'
import ReportDataGrid, { ReportDataGridSorting } from 'features/reports/ReportDataGrid'

export interface ReportAdGridProps {
  data: ReportAd[]
}

const ReportAdGrid: FC<ReportAdGridProps> = ({ data }) => {
  const defaultSorting = [
    {
      id: 'campaignName',
      direction: 'asc'
    },
    {
      id: 'forDate',
      direction: 'desc'
    }
  ] as ReportDataGridSorting[]

  const columns = [
    {
      id: 'accountId',
      displayAsText: 'Account Id',
      hidden: true
    },
    {
      id: 'accountName',
      displayAsText: 'Account Name',
      hidden: true
    },
    {
      id: 'campaignId',
      displayAsText: 'Campaign Id',
      hidden: true
    },
    {
      id: 'campaignName',
      displayAsText: 'Campaign Name'
    },
    {
      id: 'adId',
      displayAsText: 'Ad Id',
      hidden: true
    },
    {
      id: 'adHeadline',
      displayAsText: 'Ad Headline'
    },
    {
      id: 'adBody',
      displayAsText: 'Ad Body'
    },
    {
      id: 'adCallToAction',
      displayAsText: 'Ad Call-To-Action'
    },
    {
      id: 'adImageUrl',
      displayAsText: 'Ad Image'
    },
    {
      id: 'adVideoUrl',
      displayAsText: 'Ad Video'
    },
    {
      id: 'adDestinationUrl',
      displayAsText: 'Ad Click URL'
    },
    {
      id: 'forDate',
      displayAsText: 'Date',
      schema: 'date'
    },
    {
      id: 'views',
      displayAsText: 'Views'
    },
    {
      id: 'clicks',
      displayAsText: 'Clicks'
    },
    {
      id: 'ctr',
      displayAsText: 'CTR %'
    },
    {
      id: 'spend',
      displayAsText: 'Spend'
    },
    {
      id: 'cpc',
      displayAsText: 'CPC'
    },
    {
      id: 'cpm',
      displayAsText: 'CPM'
    }
  ]

  return <ReportDataGrid data={data} columns={columns} defaultSorting={defaultSorting} />
}

export default ReportAdGrid
