import { BaseBundle, CommissionDefinition } from 'api/interfaces/Bundle'

export interface Plan extends BaseBundle {
  organizationManagerId: string
  campaignAdvisorId: string
  commissionDefinition: CommissionDefinition
  planStatus?: PlanStatus
}

export enum PlanStatus {
  Offered = 'Offered',
  Proposed = 'Proposed',
  VerbalAccept = 'VerbalAccept',
  Stalled = 'Stalled'
}
