import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiCallOut, EuiCheckableCard, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiLoadingContent, EuiRadioGroup, EuiRadioGroupOption, EuiSpacer, EuiTitle, htmlIdGenerator } from '@elastic/eui'

import { CampaignActiveStatus } from 'api/interfaces'
import { bundlesApi, useGetAvailableCampaignSlotsForAccountQuery } from 'api/rtkQueryApi/platform/bundlesApi'
import { showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { BillboardBuilderState, resetBuilder } from 'features/builders/billboards/retail/builderBillboardRetailSlice'
import { createBundleCampaign, InternetBuilderState } from 'features/builders/internet/retail/builderInternetRetailSlice'
import { TvBuilderState } from 'features/builders/tv/retail/builderTvRetailSlice'
import history from 'services/HistoryService'
import { CampaignType } from 'utils/CampaignType'

export interface ActivateVendastaCampaignStepParams {
  campaignType: CampaignType
}

export const ActivateVendastaCampaignStep: React.FC<ActivateVendastaCampaignStepParams> = ({ campaignType }) => {
  const dispatch = useDispatch()
  const builderInternetRetail = useSelector((state: RootState) => state.builderInternetRetail)
  const builderTvRetail = useSelector((state: RootState) => state.builderTvRetail)
  const builderBillboardRetail = useSelector((state: RootState) => state.builderBillboardRetail)
  const { currentAccount, currentOrganization, isVendastaUser, isVendastaPartnerUser } = useSelector((state: RootState) => state.app)
  const campaignSlotsResults = useGetAvailableCampaignSlotsForAccountQuery({ organizationId: currentOrganization?.id ?? '', campaignType: campaignType, accountId: currentAccount?.id ?? '' })
  const [selectedCampaignSlotId, setSelectedCampaignSlotId] = useState<string>('')
  const [campaignName, setCampaignName] = useState('')
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  const [saving, setSaving] = useState(false)

  const hasCampaignSlots = campaignSlotsResults.data?.length ?? 0 > 0
  const customInternet = campaignType === CampaignType.Internet && builderInternetRetail.builderStyle === 'Custom'
  const customBillboard = campaignType === CampaignType.Billboard && builderBillboardRetail.builderStyle === 'Custom'
  const customTv = campaignType === CampaignType.TV && builderTvRetail.builderStyle !== 'Upload'

  let currentBuilder: InternetBuilderState | TvBuilderState | BillboardBuilderState
  switch (campaignType) {
    case CampaignType.Internet:
      currentBuilder = builderInternetRetail
      break
    case CampaignType.TV:
      currentBuilder = builderTvRetail
      break
    case CampaignType.Billboard:
      currentBuilder = builderBillboardRetail
      break
  }

  useEffect(() => {
    const campaignSlotName = campaignSlotsResults.data?.find(slot => slot.id.length > 0 && slot.id === selectedCampaignSlotId)?.name
    if (selectedCampaignSlotId.length <= 0 || !campaignSlotName || campaignSlotName.length <= 0) {
      return
    }

    setCampaignName(campaignSlotName)
  }, [selectedCampaignSlotId, campaignSlotsResults])

  useEffect(() => {
    if (currentBuilder.result) {
      dispatch(
        bundlesApi.util.invalidateTags([
          {
            type: 'CampaignSlotsForAccount',
            id: `${currentOrganization?.id ?? ''}, ${campaignType}, ${currentAccount?.id ?? ''}`
          }
        ])
      )

      if (currentBuilder.result.campaignActiveStatus === CampaignActiveStatus.Inactive) {
        dispatch(showSuccessToast('Successfully saved your campaign'))
        history.push(`/campaigns/edit/${currentBuilder.result.campaignId}/details`)
      }
      if (currentBuilder.result.campaignActiveStatus === CampaignActiveStatus.Active) {
        dispatch(showSuccessToast('Successfully activated your campaign'))
        history.push(`/build/finished/${currentBuilder.result.campaignId}/${currentBuilder.result.campaignType}`)
      }
      dispatch(resetBuilder())
    }
  }, [dispatch, currentBuilder.result, campaignType, currentAccount, currentOrganization])

  const create = (saveForLater: boolean) => {
    if (currentAccount?.id) {
      setSaving(true)
      dispatch(createBundleCampaign(campaignType, currentAccount.id, campaignName, selectedCampaignSlotId, saveForLater))
    }
  }

  const isBundleLoading = campaignSlotsResults.isLoading || campaignSlotsResults.isFetching
  const availableCampaignSlots: EuiRadioGroupOption[] =
    campaignSlotsResults.data?.map(slot => {
      let label = slot.name ?? ''

      if (!isVendastaUser || (isVendastaUser && isVendastaPartnerUser)) {
        label += ` (${formatter.format(slot.budget)})`
      }

      return {
        id: slot.id,
        label: label,
        disabled: false
      }
    }) ?? []

  const onBackClick = () => {
    switch (campaignType) {
      case CampaignType.TV:
        history.push('/build/tv/public/deliver')
        break
      case CampaignType.Billboard:
        history.push('/build/billboards/public/target')
        break
      case CampaignType.Internet:
        history.push('/build/internet/public/deliver')
        break
    }
  }

  return (
    <React.Fragment>
      {isBundleLoading ? (
        <EuiLoadingContent />
      ) : (
        <React.Fragment>
          <Helmet>
            <title>{customInternet || customBillboard || customTv ? 'Submit' : 'Activate'} Your Campaign</title>
          </Helmet>
          <EuiTitle size='s'>
            <h2>{customInternet || customBillboard || customTv ? 'Submit' : 'Activate'} Your Campaign</h2>
          </EuiTitle>
          <EuiSpacer size='m' />

          {isVendastaUser && !hasCampaignSlots ? (
            <React.Fragment>
              <EuiFlexItem grow={true}>
                <EuiCallOut color='warning' iconType='alert' title='Please purchase an AdCritter campaign add-on to activate a campaign.' />
              </EuiFlexItem>
              <EuiSpacer />
            </React.Fragment>
          ) : (
            <EuiFlexGroup direction='column'>
              {!isVendastaUser && (
                <EuiFlexItem>
                  <EuiFormRow label='Campaign Name'>
                    <EuiFieldText isLoading={isBundleLoading} value={campaignName} />
                  </EuiFormRow>
                </EuiFlexItem>
              )}
              <EuiFlexItem>
                <EuiCheckableCard id={htmlIdGenerator()()} label={<strong>Use Plan Credit</strong>} name={'bundle'} checked={true} onChange={() => {}}>
                  <EuiFormRow label='Select available plan credit' fullWidth>
                    <EuiRadioGroup options={availableCampaignSlots} onChange={id => setSelectedCampaignSlotId(id)} idSelected={selectedCampaignSlotId} />
                  </EuiFormRow>
                </EuiCheckableCard>
              </EuiFlexItem>
            </EuiFlexGroup>
          )}
        </React.Fragment>
      )}
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton id='back' fill color='text' iconType='arrowLeft' iconSide='left' isDisabled={currentBuilder.isCreating} onClick={onBackClick}>
            Back
          </EuiButton>
        </EuiFlexItem>
        {!isVendastaUser || (isVendastaUser && hasCampaignSlots) ? (
          <React.Fragment>
            {(customInternet || customBillboard || customTv) && (
              <EuiFlexItem grow={false}>
                <EuiButton
                  id='save'
                  iconType='save'
                  iconSide='left'
                  isLoading={saving}
                  disabled={!selectedCampaignSlotId || saving}
                  onClick={() => {
                    create(true)
                  }}>
                  Submit to Ad Team
                </EuiButton>
              </EuiFlexItem>
            )}
            {!customInternet && !customBillboard && !customTv && (
              <EuiFlexItem grow={false}>
                <EuiButton
                  id='activate'
                  fill
                  iconType='check'
                  isLoading={saving}
                  disabled={!selectedCampaignSlotId || saving}
                  onClick={() => {
                    create(false)
                  }}>
                  Activate
                </EuiButton>
              </EuiFlexItem>
            )}
          </React.Fragment>
        ) : (
          <EuiFlexItem grow={false} />
        )}
      </EuiFlexGroup>
    </React.Fragment>
  )
}
