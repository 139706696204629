import React, { FC, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { EuiButtonEmpty } from '@elastic/eui'

import { Invoice } from 'api/interfaces'
import { useLazyGetTempKeyForAccountAndInvoiceHandleQuery } from 'api/rtkQueryApi/platform/accountsApi'
import config from 'app/config'
import { RootState } from 'app/rootReducer'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

export interface ViewInvoiceLinkProps {
  invoiceHandle: string
}

const ViewInvoiceLink: FC<ViewInvoiceLinkProps> = ({ invoiceHandle }) => {
  const whiteLabel = useWhiteLabel()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [triggerGetTempKey, tempKeyResults] = useLazyGetTempKeyForAccountAndInvoiceHandleQuery()

  useEffect(() => {
    if (tempKeyResults.isFetching || !tempKeyResults.data) {
      return
    }

    const { accountId, invoiceHandle, tempKey } = tempKeyResults.data

    window.open(`${config.api.BASEURL}invoice/${accountId}/${invoiceHandle}?tempKey=${tempKey}`, '_blank', 'noopener,noreferrer')
  }, [tempKeyResults])

  const onViewInvoiceClick = useCallback(() => {
    if (!currentAccount || !whiteLabel) {
      return
    }

    triggerGetTempKey({ accountId: currentAccount.id, invoiceHandle: invoiceHandle, isAgency: whiteLabel.isAgencies() }, false)
  }, [currentAccount, whiteLabel, triggerGetTempKey])

  return (
    <EuiButtonEmpty onClick={onViewInvoiceClick} iconSize='s' iconSide='right' iconType='popout'>
      View Invoice
    </EuiButtonEmpty>
  )
}

export default ViewInvoiceLink
