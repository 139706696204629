import React, { FC } from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiStat, EuiText, EuiTitle } from '@elastic/eui'

export interface InsightsQuickInfoProps {
  isLoading: boolean
  title: string
  description: string
  titleDescription?: string
  titleDescriptionColor?: string
  link?: string
  linkText?: string
  linkTextColor?: string
}

const InsightsQuickInfo: FC<InsightsQuickInfoProps> = ({ isLoading, title, description, titleDescription, titleDescriptionColor, link, linkText, linkTextColor }) => {
  return (
    <EuiFlexGroup alignItems='center' direction='column' gutterSize='l'>
      <EuiFlexItem>
        <EuiText size='m'>
          <span style={{ fontSize: '1.15em', fontWeight: '525' }}>{description}</span>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup alignItems='center' gutterSize='m'>
          <EuiFlexItem></EuiFlexItem>
          {isLoading ? (
            <EuiStat isLoading={isLoading} description='' title='Loading' titleSize='l' />
          ) : (
            <React.Fragment>
              <EuiFlexItem>
                <EuiTitle>
                  <span style={{ whiteSpace: 'nowrap', fontSize: '3.75em', fontWeight: '900' }}>{title}</span>
                </EuiTitle>
              </EuiFlexItem>
              {titleDescription && titleDescriptionColor && (
                <EuiFlexItem>
                  {' '}
                  <EuiText style={{ color: titleDescriptionColor }}>{titleDescription}</EuiText>
                </EuiFlexItem>
              )}
            </React.Fragment>
          )}
          <EuiFlexItem></EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      {link && linkText && linkTextColor && (
        <EuiFlexItem>
          <EuiLink href={link} style={{ color: linkTextColor, textDecorationLine: 'underline', whiteSpace: 'nowrap' }} target='_blank'>
            {linkText}
          </EuiLink>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}

export default InsightsQuickInfo
