import React from 'react'

import { EuiCallOut, EuiFormRow, EuiRadio, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { LocationTargeting, LocationTargetingType } from 'api/interfaces/CampaignTargeting'
import { NewCityFinder } from 'components/finders/NewCityFinder'
import { NewDmaFinder } from 'components/finders/NewDmaFinder'
import { NewPostalCodeFinder } from 'components/finders/NewPostalCodeFinder'
import { NewRegionFinder } from 'components/finders/NewRegionFinder'
import { NewCityTargeter } from 'components/targeters/NewCityTargeter'
import { NewDmaTargeter } from 'components/targeters/NewDmaTargeter'
import { NewRegionTargeter } from 'components/targeters/NewRegionTargeter'
import { ZipTargeter } from 'components/targeters/ZipTargeter'

export interface ILocationRestrictionTargeter {
  targetingDetails: LocationTargeting
  setTargetingDetails: (details: LocationTargeting) => void
  title?: string
}

export const LocationRestrictionTargeter: React.FC<ILocationRestrictionTargeter> = props => {
  const { targetingDetails, setTargetingDetails } = props
  return (
    <React.Fragment>
      <EuiTitle size='s'>
        <span>{props.title}</span>
      </EuiTitle>
      <EuiSpacer size='s' />

      <EuiRadio
        id='usa'
        name='locationType'
        value='USA'
        label={
          <EuiText size='s'>
            <strong>Entire USA</strong>
          </EuiText>
        }
        checked={targetingDetails.targetingType === LocationTargetingType.USA}
        onChange={() => {
          setTargetingDetails({ ...targetingDetails, targetingType: LocationTargetingType.USA })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='state'
        name='locationType'
        value='State'
        label={
          <EuiText size='s'>
            <strong>State(s)</strong>
          </EuiText>
        }
        checked={targetingDetails.targetingType === LocationTargetingType.Region}
        onChange={() => {
          setTargetingDetails({ ...targetingDetails, targetingType: LocationTargetingType.Region })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='dma'
        name='locationType'
        value='DMA'
        label={
          <EuiText size='s'>
            <strong>Metro Area(s) / DMA(s)</strong>
          </EuiText>
        }
        checked={targetingDetails.targetingType === LocationTargetingType.DMA}
        onChange={() => {
          setTargetingDetails({ ...targetingDetails, targetingType: LocationTargetingType.DMA })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='city'
        name='locationType'
        value='City'
        label={
          <EuiText size='s'>
            <strong>City(s)</strong>
          </EuiText>
        }
        checked={targetingDetails.targetingType === LocationTargetingType.City}
        onChange={() => {
          setTargetingDetails({ ...targetingDetails, targetingType: LocationTargetingType.City })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='zip'
        name='locationType'
        value='Zip'
        label={
          <EuiText size='s'>
            <strong>Zip Code(s)</strong>
          </EuiText>
        }
        checked={targetingDetails.targetingType === LocationTargetingType.Zip}
        onChange={() => {
          setTargetingDetails({
            ...targetingDetails,
            targetingType: LocationTargetingType.Zip
          })
        }}
      />
      {targetingDetails.targetingType === LocationTargetingType.Region && (
        <React.Fragment>
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. states below.' />
          <EuiSpacer size='s' />

          <EuiFormRow id='stateFinder' label='Search for a state' fullWidth>
            <NewRegionFinder
              addRegion={regionToAdd => {
                setTargetingDetails({ ...targetingDetails, regions: [...targetingDetails.regions.filter(s => s.id !== regionToAdd.id), regionToAdd] })
              }}
              isInvalid={false}
            />
          </EuiFormRow>
          {targetingDetails.regions.length > 0 && (
            <EuiFormRow label='Your campaign will target these states' fullWidth>
              <NewRegionTargeter
                regions={targetingDetails.regions}
                onRegionRemoved={regionId => {
                  setTargetingDetails({
                    ...targetingDetails,
                    regions: targetingDetails.regions.filter(s => s.id !== regionId)
                  })
                }}
              />
            </EuiFormRow>
          )}
        </React.Fragment>
      )}

      {targetingDetails.targetingType === LocationTargetingType.DMA && (
        <React.Fragment>
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more metro areas / DMAs below.' />
          <EuiSpacer size='s' />

          <EuiFormRow id='dmaFinder' label='Search for a metro area' fullWidth>
            <NewDmaFinder
              addDma={dmaToAdd => {
                setTargetingDetails({ ...targetingDetails, dmas: [...targetingDetails.dmas.filter(s => s.id !== dmaToAdd.id), dmaToAdd] })
              }}
              isInvalid={false}
            />
          </EuiFormRow>
          {targetingDetails.dmas.length > 0 && (
            <EuiFormRow label='Your campaign will target these metro areas' fullWidth>
              <NewDmaTargeter
                dmas={targetingDetails.dmas}
                onDmaRemoved={dmaId => {
                  setTargetingDetails({
                    ...targetingDetails,
                    dmas: targetingDetails.dmas.filter(s => s.id !== dmaId)
                  })
                }}
              />
            </EuiFormRow>
          )}
        </React.Fragment>
      )}
      {targetingDetails.targetingType === LocationTargetingType.City && (
        <React.Fragment>
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. cities by name.' />
          <EuiSpacer size='s' />

          <EuiFormRow id='cityFinder' label='Search for a city' fullWidth>
            <NewCityFinder
              onCityClicked={city => {
                setTargetingDetails({ ...targetingDetails, cities: [...targetingDetails.cities.filter(c => c.id !== city.id), city] })
              }}
              isInvalid={false}
            />
          </EuiFormRow>
          {targetingDetails.cities.length > 0 && (
            <EuiFormRow label='Your campaign will target these cities' fullWidth>
              <NewCityTargeter
                cities={targetingDetails.cities}
                onCityRemoved={cityId => {
                  setTargetingDetails({
                    ...targetingDetails,
                    cities: targetingDetails.cities.filter(c => c.id !== cityId)
                  })
                }}
              />
            </EuiFormRow>
          )}
        </React.Fragment>
      )}
      {targetingDetails.targetingType === LocationTargetingType.Zip && (
        <React.Fragment>
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. zip codes.' />
          <EuiSpacer size='s' />

          <EuiFormRow id='zipFinder' label='Search for a zip code' fullWidth>
            <NewPostalCodeFinder
              onPostalCodeClicked={zip => {
                setTargetingDetails({ ...targetingDetails, zips: [...targetingDetails.zips.filter(z => z.id !== zip.id), zip] })
              }}
              isInvalid={false}
            />
          </EuiFormRow>
          {targetingDetails.zips.length > 0 && (
            <EuiFormRow label='Your campaign will target these zip codes' fullWidth>
              <ZipTargeter
                zips={targetingDetails.zips.map(z => ({ id: z.id.toString(), name: z.name }))}
                onZipRemoved={zip => {
                  setTargetingDetails({
                    ...targetingDetails,
                    zips: targetingDetails.zips.filter(z => z.id.toString() !== zip.id)
                  })
                }}
              />
            </EuiFormRow>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
