import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { useGetIsVendastaAuthenticatedQuery } from 'api/rtkQueryApi/platform/authApi'
import { logout, setReturnUrlState } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { deleteVendastaAccessToken } from 'services/LocalStoreManagerService'

export const useVendastaCheckIsAuthenticated = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { currentAccount, isLoggedIn, isVendastaUser, vendastaAccessToken } = useSelector((state: RootState) => state.app)
  const {
    isLoading: isVendastaAuthenticatedLoading,
    isFetching: isVendastaAuthenticatedFetching,
    data: isVendastaAuthenticated
  } = useGetIsVendastaAuthenticatedQuery(
    { vendastaAccessToken: vendastaAccessToken!, vendastaAccountId: currentAccount?.strawberryFieldAccountId ?? '' },
    {
      skip: !isLoggedIn || !currentAccount || !currentAccount.strawberryFieldAccountId || currentAccount.strawberryFieldAccountId.length <= 0 || !vendastaAccessToken
    }
  )
  const isVendastaAuthenticatedDone = !isVendastaAuthenticatedLoading && !isVendastaAuthenticatedFetching

  useEffect(() => {
    if (!isLoggedIn || !isVendastaUser || !isVendastaAuthenticatedDone || !isVendastaAuthenticated) {
      return
    }

    if (!isVendastaAuthenticated.isAuthenticated) {
      const vendastaAccountId = currentAccount?.strawberryFieldAccountId ?? ''

      if (isVendastaAuthenticated.isThirdPartyTokenValid || vendastaAccountId.length <= 0) {
        dispatch(logout())
      } else {
        deleteVendastaAccessToken()
        dispatch(setReturnUrlState(location.pathname))
        window.location.assign(`/strawberryFieldLogin?account_id=${vendastaAccountId}`)
      }
    }
  }, [isLoggedIn, isVendastaUser, isVendastaAuthenticatedDone, isVendastaAuthenticated])

  return isVendastaAuthenticatedDone
}
