import React, { FC } from 'react'

import { ReportCampaignDimension } from 'api/interfaces'
import ReportDataGrid, { ReportDataGridColumn, ReportDataGridSorting } from 'features/reports/ReportDataGrid'

export interface ReportCampaignDimensionGridProps {
  data: ReportCampaignDimension[]
  dimension: string
}

const ReportCampaignDimensionGrid: FC<ReportCampaignDimensionGridProps> = ({ data, dimension }) => {
  const defaultSorting = [
    {
      id: 'campaignName',
      direction: 'asc'
    },
    {
      id: 'fromDate',
      direction: 'desc'
    },
    {
      id: 'dimensionValue',
      direction: 'asc'
    }
  ] as ReportDataGridSorting[]

  const columns: ReportDataGridColumn[] = [
    {
      id: 'accountId',
      displayAsText: 'Account Id',
      hidden: true
    },
    {
      id: 'accountName',
      displayAsText: 'Account Name',
      hidden: true
    },
    {
      id: 'campaignId',
      displayAsText: 'Campaign Id',
      hidden: true
    },
    {
      id: 'campaignName',
      displayAsText: 'Campaign Name'
    },
    {
      id: 'dimensionValue',
      displayAsText: dimension
    },
    {
      id: 'fromDate',
      displayAsText: 'From'
    },
    {
      id: 'toDate',
      displayAsText: 'To'
    },
    {
      id: 'views',
      displayAsText: 'Views'
    },
    {
      id: 'clicks',
      displayAsText: 'Clicks'
    },
    {
      id: 'ctr',
      displayAsText: 'CTR %'
    },
    {
      id: 'spend',
      displayAsText: 'Spend'
    },
    {
      id: 'cpc',
      displayAsText: 'CPC'
    },
    {
      id: 'cpm',
      displayAsText: 'CPM'
    }
  ]

  return <ReportDataGrid data={data} columns={columns} defaultSorting={defaultSorting} />
}

export default ReportCampaignDimensionGrid
