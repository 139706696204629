import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { EuiCard, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

import { startCreateAd as startBillboardRetailCreateAd } from './createAd/billboards/retail/createAdBillboardRetailSlice'
import { startCreateAd as startInternetRetailCreateAd } from './createAd/internet/retail/createAdInternetRetailSlice'

const StartCreateAdPage: React.FC = () => {
  const dispatch = useDispatch()
  const { currentOrganization } = useSelector((state: RootState) => state.app)
  const [isGreatClips, setIsGreatClips] = useState<boolean | undefined>()

  useEffect(() => {
    if (currentOrganization) {
      setIsGreatClips(currentOrganization.targetingTemplates.indexOf('greatclips-recruitment') >= 0)
    }
  }, [currentOrganization])

  const setInternet = () => {
    if (typeof isGreatClips !== 'undefined' && !isGreatClips) {
      dispatch(startInternetRetailCreateAd())
    } else {
      history.push(`/campaigns/internet/start`)
    }
  }

  const setBillboard = () => {
    dispatch(startBillboardRetailCreateAd())
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Choose Your Campaign</title>
      </Helmet>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle>
            <h1>New Ad</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>
      <EuiPanel grow={false} className='eui-xScroll'>
        <EuiSpacer />
        <EuiFlexGroup>
          <EuiFlexItem grow={true} />
          <EuiFlexItem grow={false} style={{ width: 280 }}>
            <EuiCard icon={<EuiIcon size='xxl' type='globe' color='success' />} title='Internet' description='Create a new Internet Ad ad' onClick={setInternet} footer={<EuiText size='s'>Create, target and deliver internet ads to drive your business.</EuiText>} />
          </EuiFlexItem>
          <EuiFlexItem grow={true} />
        </EuiFlexGroup>
        <EuiSpacer />
        <EuiFlexGroup>
          <EuiFlexItem grow={true} />
          <EuiFlexItem grow={false} style={{ width: 280 }}>
            <EuiCard icon={<EuiIcon size='xxl' type='fullScreen' color='success' />} title='Billboards' description='Create a new Billboard ad' onClick={setBillboard} footer={<EuiText size='s'>Create and deliver large-format digital billboard ads to drive your business.</EuiText>} />
          </EuiFlexItem>
          <EuiFlexItem grow={true} />
        </EuiFlexGroup>
        <EuiSpacer />
      </EuiPanel>
    </React.Fragment>
  )
}

export default StartCreateAdPage
