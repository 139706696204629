import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { apiPostInternetVistaBuilder } from 'api/adcritterApi'
import { AcImage, Ad, BuilderResult, BusinessType, VistaBuilder, VistaTargeting } from 'api/interfaces'
import { dashboardApi } from 'api/rtkQueryApi/platform/dashboardApi'
import { setIsInSignupProcess } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { AppThunk } from 'app/store'
import { fetchCampaigns } from 'features/campaigns/campaigns/campaignsSlice'
import history from 'services/HistoryService'
import localStoreManagerService from 'services/LocalStoreManagerService'

export enum BuilderStep {
  Suggestions = 10,
  Create = 30,
  Target = 40,
  Activate = 99
}

interface BuilderState {
  builderStep: BuilderStep
  businessType: BusinessType | null
  ad: Ad | null
  image: AcImage | null
  vistaTargeting: VistaTargeting | null
  isCreating: boolean
  result: BuilderResult | null
  name: string | null
  budget: number | null
}

const initialState: BuilderState = {
  builderStep: localStoreManagerService.getData('builderVistaRetail.builderStep') || BuilderStep.Suggestions,
  businessType: localStoreManagerService.getData('builderVistaRetail.businessType'),
  ad: localStoreManagerService.getData('builderVistaRetail.ad'),
  image: localStoreManagerService.getData('builderVistaRetail.image'),
  vistaTargeting: localStoreManagerService.getData('builderVistaRetail.vistaTargeting'),
  isCreating: false,
  result: null,
  name: localStoreManagerService.getData('builderVistaRetail.name'),
  budget: localStoreManagerService.getData('builderVistaRetail.budget')
}

function reset(state: BuilderState) {
  state.builderStep = BuilderStep.Suggestions
  state.businessType = null
  state.ad = null
  state.image = null
  state.vistaTargeting = null
  state.isCreating = false
  state.result = null
  state.name = null
  state.budget = null

  localStoreManagerService.deleteData('builderVistaRetail.builderStep')
  localStoreManagerService.deleteData('builderVistaRetail.businessType')
  localStoreManagerService.deleteData('builderVistaRetail.ad')
  localStoreManagerService.deleteData('builderVistaRetail.image')
  localStoreManagerService.deleteData('builderVistaRetail.vistaTargeting')
  localStoreManagerService.deleteData('builderVistaRetail.name')
  localStoreManagerService.deleteData('builderVistaRetail.budget')
}

const builderInternetVista = createSlice({
  name: 'builderInternetVista',
  initialState,
  reducers: {
    startBuilder(state: BuilderState) {
      reset(state)
      history.push('/build/internet/vista/suggestions')
    },
    resetBuilder(state: BuilderState) {
      reset(state)
    },
    setBuilderStep(state: BuilderState, { payload }: PayloadAction<BuilderStep>) {
      state.builderStep = payload
      localStoreManagerService.saveSessionData('builderVistaRetail.builderStep', payload)
    },
    setBusinessType(state: BuilderState, { payload }: PayloadAction<BusinessType>) {
      state.businessType = payload
      localStoreManagerService.saveSessionData('builderVistaRetail.businessType', payload)
    },
    setAd(state: BuilderState, { payload }: PayloadAction<Ad>) {
      state.ad = payload
      localStoreManagerService.saveSessionData('builderVistaRetail.ad', payload)
    },
    setImage(state: BuilderState, { payload }: PayloadAction<AcImage>) {
      state.image = payload
      localStoreManagerService.saveSessionData('builderVistaRetail.image', payload)
    },
    setVistaTargeting(state: BuilderState, { payload }: PayloadAction<VistaTargeting>) {
      state.vistaTargeting = payload
      localStoreManagerService.saveSessionData('builderVistaRetail.vistaTargeting', payload)
    },
    startCreating(state: BuilderState) {
      state.isCreating = true
    },
    finishedCreating(state: BuilderState, { payload }: PayloadAction<BuilderResult>) {
      state.result = payload
      state.isCreating = false
    },
    setNameAndBudget(state: BuilderState, { payload }: PayloadAction<{ Name: string; Budget: number }>) {
      state.name = payload.Name
      state.budget = payload.Budget
      localStoreManagerService.saveSessionData('builderVistaRetail.name', payload.Name)
      localStoreManagerService.saveSessionData('builderVistaRetail.budget', payload.Budget)
    }
  }
})

export const { startBuilder, resetBuilder, setBuilderStep, setBusinessType, setVistaTargeting, setAd, setImage, startCreating, finishedCreating, setNameAndBudget } = builderInternetVista.actions

export default builderInternetVista.reducer

const getInternetVistaBuilder = (state: RootState) => state.builderInternetVista

export const createCampaign =
  (accountId: string, campaignName: string, budget: number, paymentMethodId: string | null, saveForLater: boolean): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startCreating())
    const builder = getInternetVistaBuilder(getState())

    const response = await apiPostInternetVistaBuilder(accountId, {
      campaignName: campaignName,
      budget: budget,
      highQualityUrl: builder.image!.highQualityUrl,
      squareUrl: builder.image!.squareUrl,
      largeSquareUrl: builder.image?.largeSquareUrl,
      thumbnailUrl: builder.image!.thumbnailUrl,
      mobileUrl: builder.image!.mobileUrl,
      imageId: builder.image!.id,
      isPurchased: builder.image!.isPurchased,
      headline: builder.ad!.headline,
      body: builder.ad!.body,
      companyName: builder.ad!.companyName,
      callToAction: builder.ad!.callToAction,
      destinationUrl: builder.ad!.destinationUrl,
      locationType: builder.vistaTargeting!.locationType,
      states: builder.vistaTargeting!.states,
      dmas: builder.vistaTargeting!.dmas,
      cities: builder.vistaTargeting!.cities.map(c => c.id),
      keywords: builder.vistaTargeting!.keywords,
      negativeKeywords: [],
      paymentMethodId: paymentMethodId,
      setupIntentId: null,
      saveForLater: saveForLater
    } as VistaBuilder)
    dispatch(finishedCreating(response.data))
    dispatch(setIsInSignupProcess(false))
    dispatch(fetchCampaigns(accountId))
    dispatch(dashboardApi.util.invalidateTags([{ type: 'DashboardFilter', id: accountId }]))
  }
