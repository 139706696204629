import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { apiGetBillboardPreviewUrl, apiGetSuggestedBillboards, apiPostBillboardCreateAdRetail } from 'api/adcritterApi'
import { AcImage, BillboardCustomization, BillboardSuggestion, BusinessType, City, Zip } from 'api/interfaces'
import { showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { AppThunk } from 'app/store'
import { resetSearchImage } from 'components/chooseImage/searchImageSlice'
import history from 'services/HistoryService'
import localStoreManagerService from 'services/LocalStoreManagerService'

import { fetchCampaign } from '../../../campaign/campaignSlice'

export enum CreateAdStyle {
  Suggested,
  Custom,
  Upload
}

export enum CreateAdStep {
  Start = 10,
  Suggest = 20,
  Questionnaire = 21,
  QuestionnaireDone = 22,
  Suggestions = 23,
  Create = 30,
  Upload = 31,
  Activate = 99
}

export interface Suggest {
  companyName: string
  cityName: string
}

export interface Questionnaire {
  companyName: string
  website: string
  mainProduct: string
  why: string
  whatElse: string
  phone: string
  name: string
}

export interface Targeting {
  locationType: string | null
  states: Array<string>
  dmas: Array<string>
  cities: Array<City>
  zips: Array<Zip>
}

interface CreateAdState {
  createAdStep: CreateAdStep
  createAdStyle: CreateAdStyle
  businessType: BusinessType | null
  suggest: Suggest | null
  questionnaire: Questionnaire | null
  images: AcImage[] | null
  isLoadingImages: boolean
  image: AcImage | null
  billboards: BillboardSuggestion[] | null
  billboard: BillboardCustomization | null
  isLoadingBillboards: boolean
  create: BillboardCustomization | null
  previewUrl: string | null
  isLoadingPreviewUrl: boolean
  uploadedUrl: string | null
  isCreating: boolean
  photo: AcImage | null
  backgroundImage: AcImage | null
  doneUploading: boolean
}

const initialState: CreateAdState = {
  createAdStep: localStoreManagerService.getData('createAdBillboardRetail.createAdStep') || CreateAdStep.Start,
  createAdStyle: localStoreManagerService.getData('createAdBillboardRetail.createAdStyle') || CreateAdStyle.Suggested,
  businessType: localStoreManagerService.getData('createAdBillboardRetail.businessType'),
  suggest: localStoreManagerService.getData('createAdBillboardRetail.suggest'),
  questionnaire: localStoreManagerService.getData('createAdBillboardRetail.questionnaire'),
  images: localStoreManagerService.getData('createAdBillboardRetail.images') || [],
  isLoadingImages: false,
  image: localStoreManagerService.getData('createAdBillboardRetail.image'),
  billboards: localStoreManagerService.getData('createAdBillboardRetail.billboards'),
  billboard: localStoreManagerService.getData('createAdBillboardRetail.billboard'),
  isLoadingBillboards: false,
  previewUrl: localStoreManagerService.getData('createAdBillboardRetail.previewUrl'),
  isLoadingPreviewUrl: false,
  create: localStoreManagerService.getData('createAdBillboardRetail.create'),
  uploadedUrl: localStoreManagerService.getData('createAdBillboardRetail.uploadedUrl'),
  photo: localStoreManagerService.getData('createAdBillboardRetail.photo'),
  backgroundImage: localStoreManagerService.getData('builderBillboardRetail.backgroundImage'),
  isCreating: false,
  doneUploading: false
}

function reset(state: CreateAdState) {
  state.createAdStep = CreateAdStep.Start
  state.createAdStyle = CreateAdStyle.Suggested
  state.businessType = null
  state.suggest = null
  state.questionnaire = null
  state.images = []
  state.image = null
  state.isLoadingImages = false
  state.billboards = null
  state.billboard = null
  state.isLoadingBillboards = false
  state.uploadedUrl = null
  state.previewUrl = null
  state.isLoadingPreviewUrl = false
  state.photo = null
  state.backgroundImage = null
  state.create = null
  state.isCreating = false
  state.doneUploading = false

  localStoreManagerService.deleteData('createAdBillboardRetail.createAdStep')
  localStoreManagerService.deleteData('createAdBillboardRetail.createAdStyle')
  localStoreManagerService.deleteData('createAdBillboardRetail.businessType')
  localStoreManagerService.deleteData('createAdBillboardRetail.suggest')
  localStoreManagerService.deleteData('createAdBillboardRetail.questionnaire')
  localStoreManagerService.deleteData('createAdBillboardRetail.images')
  localStoreManagerService.deleteData('createAdBillboardRetail.billboards')
  localStoreManagerService.deleteData('createAdBillboard.billboard')
  localStoreManagerService.deleteData('createAdBillboardRetail.create')
  localStoreManagerService.deleteData('createAdBillboardRetail.previewUrl')
  localStoreManagerService.deleteData('createAdBillboardRetail.uploadedUrl')
  localStoreManagerService.deleteData('createAdBillboardRetail.targeting')
  localStoreManagerService.deleteData('builderBillboardRetail.photo')
}

const createAdBillboardRetail = createSlice({
  name: 'createAdBillboardRetail',
  initialState,
  reducers: {
    startCreateAd(state: CreateAdState) {
      reset(state)
      history.push('/campaigns/billboards/public/start')
    },
    resetCreateAd(state: CreateAdState) {
      reset(state)
    },
    setCreateAdStep(state: CreateAdState, { payload }: PayloadAction<CreateAdStep>) {
      state.createAdStep = payload
      localStoreManagerService.saveSessionData('createAdBillboardRetail.createAdStep', payload)
    },
    setCreateAdStyle(state: CreateAdState, { payload }: PayloadAction<CreateAdStyle>) {
      state.createAdStyle = payload
      localStoreManagerService.saveSessionData('createAdBillboardRetail.createAdStyle', payload)
    },
    setBusinessType(state: CreateAdState, { payload }: PayloadAction<BusinessType>) {
      state.businessType = payload
      localStoreManagerService.saveSessionData('createAdBillboardRetail.businessType', payload)
    },
    setBillboard(state: CreateAdState, { payload }: PayloadAction<BillboardCustomization>) {
      state.billboard = payload
      localStoreManagerService.saveSessionData('builderBillboardRetail.billboard', payload)
    },
    setPreviewUrl(state: CreateAdState, { payload }: PayloadAction<string>) {
      state.previewUrl = payload
    },
    setSuggest(state: CreateAdState, { payload }: PayloadAction<Suggest>) {
      state.suggest = payload
      localStoreManagerService.saveSessionData('createAdBillboardRetail.suggest', payload)
    },
    setQuestionnaire(state: CreateAdState, { payload }: PayloadAction<Questionnaire>) {
      state.questionnaire = payload
      localStoreManagerService.saveSessionData('createAdBillboardRetail.questionnaire', payload)
    },
    setPhoto(state: CreateAdState, { payload }: PayloadAction<AcImage | null>) {
      if (payload) {
        state.photo = payload
      } else {
        state.photo = null
      }
      localStoreManagerService.saveSessionData('createAdBillboardRetail.photo', payload)
    },
    setImage(state: CreateAdState, { payload }: PayloadAction<AcImage>) {
      state.image = payload
      localStoreManagerService.saveSessionData('createAdBillboardRetail.image', payload)
    },
    startLoadingBillboards(state: CreateAdState) {
      state.billboards = []
      state.isLoadingBillboards = true
    },
    finishedLoadingBillboards(state: CreateAdState, { payload }: PayloadAction<BillboardSuggestion[]>) {
      state.billboards = payload
      state.isLoadingBillboards = false
      localStoreManagerService.saveSessionData('createAdBillboardRetail.billboards', payload)
    },
    startLoadingPreviewUrl(state: CreateAdState) {
      state.isLoadingPreviewUrl = true
    },
    finishedLoadingPreviewUrl(state: CreateAdState, { payload }: PayloadAction<string>) {
      state.previewUrl = payload
      state.isLoadingPreviewUrl = false
      localStoreManagerService.saveSessionData('createAdBillboardRetail.images', payload)
    },
    setCreate(state: CreateAdState, { payload }: PayloadAction<BillboardCustomization>) {
      state.create = payload
      localStoreManagerService.saveSessionData('createAdBillboardRetail.create', payload)
    },
    setUploadedUrl(state: CreateAdState, { payload }: PayloadAction<string>) {
      state.uploadedUrl = payload
      localStoreManagerService.saveSessionData('createAdBillboardRetail.uploadedUrl', payload)
    },
    startCreating(state: CreateAdState) {
      state.isCreating = true
    },
    doneCreating(state: CreateAdState) {
      state.isCreating = false
      state.doneUploading = true
    }
  }
})

export const { startCreateAd, resetCreateAd, setCreateAdStep, setCreateAdStyle, setBusinessType, setBillboard, finishedLoadingBillboards, startLoadingBillboards, setPreviewUrl, setPhoto, setSuggest, setQuestionnaire, setImage, startLoadingPreviewUrl, finishedLoadingPreviewUrl, setCreate, setUploadedUrl, startCreating, doneCreating } = createAdBillboardRetail.actions

export const fetchBillboards =
  (accountId: string, businessTypeId: string, companyName: string, city: string | null): AppThunk =>
  async dispatch => {
    dispatch(startLoadingBillboards())
    const response = await apiGetSuggestedBillboards(accountId, businessTypeId, companyName, city)
    dispatch(finishedLoadingBillboards(response.data))
  }

const getBillboardRetailCreateAd = (state: RootState) => state.createAdBillboardRetail

export const fetchPreviewUrl =
  (accountId: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startLoadingPreviewUrl())
    const createAd = getBillboardRetailCreateAd(getState())
    const response = await apiGetBillboardPreviewUrl(accountId, createAd.create! as BillboardCustomization)
    dispatch(finishedLoadingPreviewUrl(response.data))
  }

export const uploadBillboard =
  (accountId: string, campaignId: string, billboard: BillboardCustomization): AppThunk =>
  async dispatch => {
    dispatch(startCreating())
    apiPostBillboardCreateAdRetail(accountId, billboard)
      .then(() => {
        dispatch(showSuccessToast('Created new billboard ad'))
        dispatch(fetchCampaign(accountId, campaignId))
        dispatch(resetSearchImage())
        dispatch(doneCreating())
      })
      .then(() => {
        dispatch(resetCreateAd())
      })
  }

export default createAdBillboardRetail.reducer
