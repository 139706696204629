import { FieldArray, getIn } from 'formik'
import React from 'react'
import { useSelector } from 'react-redux'

import { EuiButtonEmpty, EuiButtonIcon, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSelect, EuiSpacer, EuiToolTip } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'

import { Address } from 'api/interfaces'
import { SmartTargeting } from 'api/interfaces/CampaignTargeting'
import { RootState } from 'app/rootReducer'

export interface ICompetitorTargeter {
  smartTargeting: SmartTargeting
  setSmartTargeting: (smartTargeting: SmartTargeting) => void
}

export const CompetitorTargeter: React.FC<ICompetitorTargeter> = ({ smartTargeting, setSmartTargeting }) => {
  const dictionary = useSelector((state: RootState) => state.dictionary)

  return (
    <React.Fragment>
      {smartTargeting.competitors &&
        smartTargeting.competitors.map((competitor, index) => (
          <EuiFlexGroup gutterSize='xs' key={index} style={{ marginLeft: -30, marginTop: 5, marginBottom: 5 }}>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup alignItems='center'>
                <EuiFlexItem grow={false}>
                  <EuiToolTip position='top' content='Remove this Competitor'>
                    <EuiButtonIcon
                      id='removeCompetitor'
                      iconType='cross'
                      aria-label='remove'
                      color='warning'
                      onClick={() => {
                        const updatedCompetitors = [...smartTargeting.competitors]
                        updatedCompetitors.splice(index, 1)
                        setSmartTargeting({ ...smartTargeting, competitors: updatedCompetitors })
                      }}
                    />
                  </EuiToolTip>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label={index === 0 ? 'Competitor Name' : ''} fullWidth display='rowCompressed' isInvalid={!competitor.name}>
                <EuiFieldText
                  fullWidth
                  value={competitor.name}
                  onChange={e => {
                    const updatedCompetitors = [...smartTargeting.competitors]
                    updatedCompetitors[index].name = e.target.value
                    setSmartTargeting({ ...smartTargeting, competitors: updatedCompetitors })
                  }}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label={index === 0 ? 'Street Address' : ''} fullWidth display='rowCompressed' isInvalid={!competitor.streetAddress}>
                <EuiFieldText
                  fullWidth
                  value={competitor.streetAddress}
                  onChange={e => {
                    const updatedCompetitors = [...smartTargeting.competitors]
                    updatedCompetitors[index].streetAddress = e.target.value
                    setSmartTargeting({ ...smartTargeting, competitors: updatedCompetitors })
                  }}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label={index === 0 ? 'City' : ''} fullWidth display='rowCompressed' isInvalid={!competitor.city}>
                <EuiFieldText
                  fullWidth
                  value={competitor.city}
                  onChange={e => {
                    const updatedCompetitors = [...smartTargeting.competitors]
                    updatedCompetitors[index].city = e.target.value
                    setSmartTargeting({ ...smartTargeting, competitors: updatedCompetitors })
                  }}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label={index === 0 ? 'State' : ''} display='rowCompressed' fullWidth isInvalid={!competitor.state}>
                <EuiSelect
                  isLoading={dictionary.isLoadingStates}
                  options={dictionary.states.map(
                    s =>
                      ({
                        value: s.code,
                        label: s.name,
                        text: s.name
                      } as EuiSelectOption)
                  )}
                  value={competitor.state}
                  onChange={e => {
                    const updatedCompetitors = [...smartTargeting.competitors]
                    updatedCompetitors[index].state = e.target.value
                    setSmartTargeting({ ...smartTargeting, competitors: updatedCompetitors })
                  }}
                  fullWidth
                  hasNoInitialSelection={true}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label={index === 0 ? 'Zip Code' : ''} fullWidth display='rowCompressed' isInvalid={!competitor.zip}>
                <EuiFieldText
                  fullWidth
                  value={competitor.zip}
                  onChange={e => {
                    const updatedCompetitors = [...smartTargeting.competitors]
                    updatedCompetitors[index].zip = e.target.value
                    setSmartTargeting({ ...smartTargeting, competitors: updatedCompetitors })
                  }}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
        ))}
      <EuiSpacer size='s' />
      <EuiButtonEmpty
        id='addCompetitor'
        size='s'
        onClick={() => {
          setSmartTargeting({
            ...smartTargeting,
            competitors: [
              ...smartTargeting.competitors,
              {
                name: '',
                streetAddress: '',
                city: '',
                state: '',
                zip: '',
                country: 'usa'
              }
            ]
          })
        }}
        iconType='listAdd'>
        Add a Competitor Location
      </EuiButtonEmpty>
    </React.Fragment>
  )
}
