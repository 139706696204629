import moment from 'moment/moment'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHorizontalRule, EuiSpacer, EuiStat, EuiText, EuiTitle } from '@elastic/eui'

import { Account, SelfServicePackage, SelfServicePackageType } from 'api/interfaces'
import { useAssignSelfServicePackageMutation } from 'api/rtkQueryApi/platform/bundlesApi'
import { RootState } from 'app/rootReducer'
import HandleWrapInPanel from 'components/HandleWrapInPanel'
import SelfServicePackageDetailsFlyout from 'features/selfServicePackages/flyouts/SelfServicePackageDetailsFlyout'

export interface AvailableSelfServicePackageItemLargeProps {
  selfServicePackage: SelfServicePackage
  onAssigned?: () => void
  wrapInPanel?: boolean
}

const AvailableSelfServicePackageItemLarge: FC<AvailableSelfServicePackageItemLargeProps> = ({ selfServicePackage, onAssigned, wrapInPanel }) => {
  const { currentOrganization } = useSelector((state: RootState) => state.app)
  const [assignBundle] = useAssignSelfServicePackageMutation()
  const [showViewPackage, setShowViewPackage] = useState<boolean>(false)

  const onCloseFlyout = () => {
    setShowViewPackage(false)
  }

  const onUseEditClick = () => {
    setShowViewPackage(true)
  }

  const onAccountConfirm = (account: Account) => {
    assignBundle({ bundleId: selfServicePackage.id, accountId: account.id }).then(() => {
      setShowViewPackage(false)
      onAssigned?.()
    })
  }

  return (
    <React.Fragment>
      <HandleWrapInPanel wrapInPanel={wrapInPanel ?? false} panelProps={{ hasShadow: false, hasBorder: true }}>
        <EuiFlexGroup gutterSize='none' direction='column' style={{ width: '100%', height: '100%' }}>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup alignItems='center'>
              <EuiFlexItem grow={false}>
                <EuiTitle size={'xs'}>
                  <h3>{selfServicePackage.salesTitle}</h3>
                </EuiTitle>
              </EuiFlexItem>

              <EuiFlexItem grow={true} />

              <EuiFlexItem grow={false}>
                <EuiText size='xs' color='subdued'>
                  Id:{'\u00A0'}
                  {selfServicePackage.id.slice(0, 8)}
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiHorizontalRule margin={'xs'} />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiFormRow fullWidth>
              <React.Fragment>
                {selfServicePackage.salesDescription &&
                  selfServicePackage.salesDescription.split('\n').map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        {i > 0 && <EuiSpacer size={'xs'} />}
                        {item.length > 0 && <EuiText size={'s'}>{item}</EuiText>}
                      </React.Fragment>
                    )
                  })}
              </React.Fragment>
            </EuiFormRow>
          </EuiFlexItem>

          <EuiFlexItem grow={true} />

          <EuiSpacer size='xl' />

          <EuiFlexItem grow={false}>
            <EuiFormRow>
              <EuiText size={'s'}>
                <b>Reserved for {currentOrganization?.name}</b>

                <EuiSpacer size='xs' />

                <b>
                  <em>until {moment(selfServicePackage.expirationDate).format('MM/DD/yyyy')}</em>
                </b>
              </EuiText>
            </EuiFormRow>

            <EuiSpacer size={'m'} />

            <EuiFlexGroup>
              {selfServicePackage.selfServicePackageType === SelfServicePackageType.Specific ? (
                <EuiFlexItem grow={false}>
                  <EuiStat titleSize={'xs'} description={'Budget'} title={`$${selfServicePackage.price.toLocaleString()}`} />
                </EuiFlexItem>
              ) : (
                <React.Fragment>
                  <EuiFlexItem grow={false}>
                    <EuiStat titleSize={'xs'} description={'Minimum Budget'} title={`$${selfServicePackage.minimumAmount?.toLocaleString()}/${selfServicePackage.billingSchedule.intervalUnit.toLowerCase()}`} />
                  </EuiFlexItem>
                </React.Fragment>
              )}
              <EuiFlexItem />
              <EuiFlexItem grow={false}>
                <EuiFlexGroup direction='column' gutterSize='xs' justifyContent='spaceAround'>
                  <EuiFlexItem grow={false}>
                    <EuiButton fill onClick={onUseEditClick} size={'s'}>
                      Use/Edit
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </HandleWrapInPanel>
      {showViewPackage && selfServicePackage && <SelfServicePackageDetailsFlyout selfServicePackage={selfServicePackage} closeFlyout={onCloseFlyout} />}
    </React.Fragment>
  )
}

export default AvailableSelfServicePackageItemLarge
