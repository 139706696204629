import React, { useEffect } from 'react'

import { EuiCard, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiSpacer, EuiText } from '@elastic/eui'
import { IconType } from '@elastic/eui/src/components/icon/icon'

export interface IAcCard {
  id: string
  iconType: IconType
  title: string
  description: string
  onClick: () => void
  footer: string
}

export enum LayoutType {
  Grid = 'grid',
  GridReverse = 'gridReverse'
}

export interface IAcCardSelector {
  cardsPerRow: number
  cards: IAcCard[]
  layoutType?: LayoutType
}

export const AcCardSelector: React.FC<IAcCardSelector> = props => {
  const [groupedCards, setGroupedCards] = React.useState<IAcCard[][]>([])

  useEffect(() => {
    const items = [...props.cards]
    const groups: IAcCard[][] = []
    if (props.layoutType && props.layoutType === LayoutType.GridReverse) {
      const remainder = items.length % props.cardsPerRow
      if (remainder > 0) {
        groups.push(items.splice(0, remainder))
      }
      for (let i = 0; i < items.length; i += props.cardsPerRow) {
        groups.push(items.splice(0, props.cardsPerRow))
      }
    } else {
      for (let i = 0; i < props.cards.length; i += props.cardsPerRow) {
        groups.push(items.splice(0, props.cardsPerRow))
      }
    }
    setGroupedCards(groups)
  }, [props.cards, props.cardsPerRow, props.layoutType])

  return (
    <React.Fragment>
      {groupedCards.map((group, index) => (
        <React.Fragment key={index}>
          <EuiFlexGroup>
            <EuiFlexItem />
            {group.map(card => (
              <EuiFlexItem grow={false} style={{ width: 280 }} key={card.id}>
                <EuiCard id={card.id} icon={<EuiIcon size='xxl' type={card.iconType} color='success' />} title={card.title} description={card.description} onClick={card.onClick} footer={<EuiText size='s'>{card.footer}</EuiText>} />
              </EuiFlexItem>
            ))}
            <EuiFlexItem />
          </EuiFlexGroup>
          <EuiSpacer />
        </React.Fragment>
      ))}
    </React.Fragment>
  )
}
