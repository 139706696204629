import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiButton, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiFormRow, EuiText, EuiTitle } from '@elastic/eui'

import { useCreateDisplayAdsMutation } from 'api/rtkQueryApi/platform/creativesApi'
import { RootState } from 'app/rootReducer'
import { AcImageUploader, UploadedImage } from 'components/Basic/AcImageUploader'
import { DisplayAdPanelPreview } from 'components/adCreation/DisplayAdPanelPreview'

export interface AcceptedSize {
  width: number
  height: number
}
export interface IUploadDisplayAdsFlyout {
  onClose: () => void
  onUploaded: () => void
  defaultWebsiteLink?: string
}
export const UploadDisplayAdsFlyout: FC<IUploadDisplayAdsFlyout> = props => {
  const campaign = useSelector((state: RootState) => state.campaign)
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [isValidUpload, setIsValidUpload] = useState<boolean>(true)
  const [websiteLink, setWebsiteLink] = useState<string>('')
  const [uploadedImages, setUploadedImages] = useState<UploadedImage[]>([])
  const [invalidImages, setInvalidImages] = useState<UploadedImage[]>([])
  const [createDisplayAds, createDisplayAdsRequest] = useCreateDisplayAdsMutation()

  useEffect(() => {
    if (props.defaultWebsiteLink) {
      setWebsiteLink(props.defaultWebsiteLink)
    }
  }, [props.defaultWebsiteLink])

  const acceptedSizes = useMemo<AcceptedSize[]>(
    () => [
      { width: 160, height: 600 },
      { width: 300, height: 50 },
      { width: 300, height: 250 },
      { width: 300, height: 600 },
      { width: 320, height: 50 },
      { width: 336, height: 280 },
      { width: 728, height: 90 },
      { width: 970, height: 90 },
      { width: 970, height: 250 }
    ],
    []
  )

  // 300 MB
  const acceptedFileSize = 300 * 1000000

  const onUploadedImagesChange = (images: UploadedImage[]) => {
    setUploadedImages([...uploadedImages.filter(u => images.every(i => i.fileName != u.fileName)), ...images])
    setInvalidImages([...invalidImages.filter(u => images.every(i => i.fileName != u.fileName)), ...images.filter(i => !isValidDimensions(i))])
  }

  const onImageClick = (url: string) => {
    window.open(url, 'sharer', 'toolbar=0,status=0,width=548,height=325')
  }

  const onImageRemove = (fileName: string) => {
    setUploadedImages([...uploadedImages.filter(u => u.fileName != fileName)])
    setInvalidImages([...invalidImages.filter(u => u.fileName != fileName)])
  }

  const getFileType = (fileName: string) => {
    const parts = fileName.split('.')
    if (parts.length === 1 || (parts[0] === '' && parts.length === 2)) {
      return ''
    }
    return parts.pop()
  }

  const onUploadClick = async () => {
    const imagesToUpload = uploadedImages.filter(u => !invalidImages.some(i => i.fileName === u.fileName))
    if (canUpload()) {
      createDisplayAds({
        accountId: currentAccount!.id,
        campaignId: campaign.campaign!.id,
        displayAds: imagesToUpload.map(image => ({
          name: image.fileName,
          destinationUrl: websiteLink,
          companyName: currentAccount!.name,
          uploadedUrl: image.url,
          htmlTag: '',
          width: image.width,
          height: image.height,
          mimeType: `image/${getFileType(image.fileName)}`,
          campaignIds: [campaign.campaign!.id]
        }))
      }).then(() => {
        props.onUploaded()
      })
    }
  }

  const isValidUrl = (urlString: string) => {
    try {
      return Boolean(new URL(urlString))
    } catch (e) {
      return false
    }
  }

  const isValidDimensions = (image: UploadedImage) => {
    return acceptedSizes.some(s => s.width === image.width && s.height === image.height)
  }

  const canUpload = useCallback(() => {
    return uploadedImages.filter(u => !invalidImages.some(i => i === u)).length > 0 && isValidUrl(websiteLink) && isValidUpload && campaign.campaign?.id
  }, [uploadedImages, invalidImages, websiteLink, isValidUpload])

  return (
    <EuiFlyout onClose={props.onClose} size='m' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
      <EuiFlyoutHeader>
        <EuiTitle size='s'>
          <h2>Upload Display Ads</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiFormRow label={'Website Link'} isInvalid={!isValidUrl(websiteLink)}>
          <EuiFieldText placeholder='https://...' onChange={e => setWebsiteLink(e.target.value)} value={websiteLink} />
        </EuiFormRow>
        <EuiFormRow>
          <AcImageUploader maxFileSize={acceptedFileSize} onUploadedUrlsChange={onUploadedImagesChange} onIsValidFileChange={isValidUpload => setIsValidUpload(isValidUpload)} accountId={currentAccount!.id} allowMultiple />
        </EuiFormRow>
        {!isValidUpload && (
          <EuiFormRow fullWidth>
            <EuiText color={'danger'} size={'s'}>
              <p>Issue uploading images. Sizes must be less than {(acceptedFileSize / 1000000).toLocaleString()} MB</p>
            </EuiText>
          </EuiFormRow>
        )}
        <EuiFormRow>
          <EuiButton disabled={!canUpload()} onClick={onUploadClick} isLoading={createDisplayAdsRequest.isLoading}>
            Upload
          </EuiButton>
        </EuiFormRow>
        <EuiFormRow label={`Upload Preview (${uploadedImages.length})`} fullWidth>
          <EuiFlexGroup wrap>
            {uploadedImages.map(image => (
              <EuiFlexItem grow={false} key={image.fileName}>
                <DisplayAdPanelPreview url={image.url} displaySize={140} widthDescription={image.width} heightDescription={image.height} onImageClick={onImageClick} showAsInvalid={invalidImages.some(u => u.fileName === image.fileName)} onImageRemove={() => onImageRemove(image.fileName)} />
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
        </EuiFormRow>
      </EuiFlyoutBody>
    </EuiFlyout>
  )
}
