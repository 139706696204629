import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiButton, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiText } from '@elastic/eui'

import { RootState } from 'app/rootReducer'
import { AcImageUploader, UploadedImage } from 'components/Basic/AcImageUploader'
import { DisplayAdPanelPreview } from 'components/adCreation/DisplayAdPanelPreview'
import { displayAdSizes } from 'utils/imageUtils'

export interface IDisplayAdUploader {
  websiteLink: string
  onUpdateWebsiteLink: (link: string) => void
  onUploadClick: (images: UploadedImage[]) => void
  uploadedImages: UploadedImage[]
  onUploadedImagesChange: (images: UploadedImage[]) => void
  invalidImages: UploadedImage[]
  onInvalidImagesChange: (images: UploadedImage[]) => void
  isLoading?: boolean
  isDisabled?: boolean
}

export const DisplayAdUploader: React.FC<IDisplayAdUploader> = props => {
  const [isValidUpload, setIsValidUpload] = useState<boolean>(true)
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const onWebsiteLinkChange = (link: string) => {
    props.onUpdateWebsiteLink(link)
  }

  const onImageClick = (url: string) => {
    window.open(url, 'sharer', 'toolbar=0,status=0,width=548,height=325')
  }

  const onUploadedImagesChange = (images: UploadedImage[]) => {
    props.onUploadedImagesChange([...props.uploadedImages.filter(u => images.every(i => i.fileName != u.fileName)), ...images])
    props.onInvalidImagesChange([...props.invalidImages.filter(u => images.every(i => i.fileName != u.fileName)), ...images.filter(i => !isValidDimensions(i))])
  }

  const onImageRemove = (fileName: string) => {
    props.onUploadedImagesChange([...props.uploadedImages.filter(u => u.fileName != fileName)])
    props.onInvalidImagesChange([...props.invalidImages.filter(u => u.fileName != fileName)])
  }

  const onUploadClick = async () => {
    const imagesToUpload = props.uploadedImages.filter(u => !props.invalidImages.some(i => i.fileName === u.fileName))
    if (canUpload()) {
      props.onUploadClick(imagesToUpload)
    }
  }

  // 300 MB
  const acceptedFileSize = 300 * 1000000

  const isValidUrl = (urlString: string) => {
    try {
      return Boolean(new URL(urlString))
    } catch (e) {
      return false
    }
  }

  const isValidDimensions = (image: UploadedImage) => {
    return displayAdSizes.some(s => s.width === image.width && s.height === image.height)
  }

  const canUpload = useCallback(() => {
    return props.uploadedImages.filter(u => !props.invalidImages.some(i => i === u)).length > 0 && isValidUrl(props.websiteLink) && isValidUpload
  }, [props.uploadedImages, props.invalidImages, props.websiteLink, isValidUpload])

  return (
    <React.Fragment>
      <EuiFormRow label={'Website Link'} isInvalid={!isValidUrl(props.websiteLink)}>
        <EuiFieldText placeholder='https://...' onChange={e => onWebsiteLinkChange(e.target.value)} value={props.websiteLink} />
      </EuiFormRow>
      <EuiFormRow label={'Display Ads'}>
        <AcImageUploader maxFileSize={acceptedFileSize} onUploadedUrlsChange={onUploadedImagesChange} onIsValidFileChange={isValidUpload => setIsValidUpload(isValidUpload)} accountId={currentAccount!.id} allowMultiple />
      </EuiFormRow>
      {!isValidUpload && (
        <EuiFormRow fullWidth>
          <EuiText color={'danger'} size={'s'}>
            <p>Issue uploading images. Sizes must be less than {(acceptedFileSize / 1000000).toLocaleString()} MB</p>
          </EuiText>
        </EuiFormRow>
      )}
      <EuiFormRow label={`Upload Preview (${props.uploadedImages.length})`} fullWidth>
        <EuiFlexGroup wrap>
          {props.uploadedImages.map(image => (
            <EuiFlexItem grow={false} key={image.fileName}>
              <DisplayAdPanelPreview url={image.url} displaySize={140} widthDescription={image.width} heightDescription={image.height} onImageClick={onImageClick} showAsInvalid={props.invalidImages.some(u => u.fileName === image.fileName)} onImageRemove={() => onImageRemove(image.fileName)} />
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiFormRow>
        <EuiButton disabled={!canUpload()} onClick={onUploadClick} isLoading={props.isLoading} isDisabled={props.isDisabled}>
          Submit to Ad Team
        </EuiButton>
      </EuiFormRow>
    </React.Fragment>
  )
}
