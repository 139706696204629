import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiImage, EuiLoadingSpinner, EuiPanel, EuiText } from '@elastic/eui'

import { AdView } from 'api/interfaces/AdViewer'
import { RootState } from 'app/rootReducer'

export interface KeyedAdView extends AdView {
  key: string
}

export interface IAcCarousel {
  adViews: KeyedAdView[]
  currentKey: string
  onAdViewChange: (key: string) => void
}

export const AcCarousel: React.FC<IAcCarousel> = props => {
  const [selectedAdView, setSelectedAdView] = useState(props.adViews.find(adView => adView.key === props.currentKey))
  const { screenSize } = useSelector((state: RootState) => state.events)
  const [visibleCellCount, setVisibleCellCount] = useState(3)
  const [leftVisibleCellIndex, setLeftVisibleCellIndex] = useState(0)
  const [visibleAdViews, setVisibleAdViews] = useState<KeyedAdView[]>([])
  const [selectedAdViewVisibleIndex, setSelectedAdViewVisibleIndex] = useState(0)

  useEffect(() => {
    setSelectedAdView(props.adViews.find(adView => adView.key === props.currentKey))
  }, [props.adViews, props.currentKey])

  useEffect(() => {
    if (screenSize.sm) {
      setVisibleCellCount(1)
    } else if (screenSize.md) {
      setVisibleCellCount(3)
    } else if (screenSize.lg) {
      setVisibleCellCount(5)
    }
  }, [screenSize.sm, screenSize.lg, screenSize.md])

  useEffect(() => {
    if (props.adViews.length > 0) {
      const newVisibleAdViews: KeyedAdView[] = []
      const startIndex = leftVisibleCellIndex
      const endIndex = leftVisibleCellIndex + visibleCellCount > props.adViews.length ? props.adViews.length - 1 : leftVisibleCellIndex + visibleCellCount - 1

      for (let i = startIndex; i <= endIndex; i++) {
        newVisibleAdViews.push(props.adViews[i])
      }

      const alreadyAdded = newVisibleAdViews.length
      for (let i = 0; i < visibleCellCount - alreadyAdded; i++) {
        newVisibleAdViews.push(props.adViews[i])
      }
      setVisibleAdViews(newVisibleAdViews)
    }
  }, [props.adViews, visibleCellCount, leftVisibleCellIndex])

  useEffect(() => {
    setSelectedAdViewVisibleIndex(0)
  }, [visibleCellCount])

  useEffect(() => {
    if (selectedAdViewVisibleIndex < 0) {
      return
    } else if (selectedAdViewVisibleIndex >= visibleAdViews.length && visibleAdViews.length > 0) {
      setSelectedAdViewVisibleIndex(Math.ceil(visibleAdViews.length / 2) - 1)
    } else if (!!visibleAdViews[selectedAdViewVisibleIndex]) {
      props.onAdViewChange(visibleAdViews[selectedAdViewVisibleIndex].key)
    }
  }, [visibleAdViews, selectedAdViewVisibleIndex])

  const CycleRight = () => {
    let newLeftCellIndex = 0
    if (leftVisibleCellIndex === props.adViews.length - 1) {
      newLeftCellIndex = 0
    } else {
      newLeftCellIndex = leftVisibleCellIndex + 1
    }
    setLeftVisibleCellIndex(newLeftCellIndex)
  }

  const CycleLeft = () => {
    let newLeftCellIndex = 0
    if (leftVisibleCellIndex === 0) {
      newLeftCellIndex = props.adViews.length - 1
    } else {
      newLeftCellIndex = leftVisibleCellIndex - 1
    }
    setLeftVisibleCellIndex(newLeftCellIndex)
  }

  if (!selectedAdView) {
    return (
      <EuiFlexGroup responsive={false} justifyContent={'center'} direction={'column'} style={{ height: screenSize.sm ? 160 : 240, margin: 8 }}>
        <EuiFlexItem>
          <EuiLoadingSpinner />
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  const getDisplayDimensions = (adView: AdView, maxWidth: number, maxHeight: number) => {
    const widthRatio = maxWidth / adView.width
    const heightRatio = maxHeight / adView.height
    let ratio = 1

    if (adView.width > maxWidth && adView.height > maxHeight) {
      ratio = Math.min(widthRatio, heightRatio)
    } else if (adView.width > maxWidth) {
      ratio = widthRatio
    } else if (adView.height > maxHeight) {
      ratio = heightRatio
    }

    return {
      width: adView.width * ratio,
      height: adView.height * ratio
    }
  }

  const { width, height } = getDisplayDimensions(selectedAdView, screenSize.sm ? 200 : 400, screenSize.sm ? 140 : screenSize.md ? 170 : 200)
  const cellWidth = 120

  const onAdViewCellClicked = (index: number, selectedKey: string) => {
    setSelectedAdViewVisibleIndex(index)
    props.onAdViewChange(selectedKey)
  }

  return (
    <React.Fragment>
      <EuiFlexGroup responsive={false} justifyContent={'center'} direction={'column'} style={{ height: screenSize.sm ? 160 : screenSize.md ? 200 : 240, margin: 8 }}>
        <EuiFlexItem>
          <span style={{ margin: 'auto', display: 'block', width: width, height: height }}>
            <EuiImage src={selectedAdView.adImageSrc} alt={selectedAdView.key} size={'original'} allowFullScreen />
          </span>
        </EuiFlexItem>
        <EuiFlexItem />
      </EuiFlexGroup>
      <EuiFlexGroup justifyContent={'center'}>
        <EuiFlexItem grow={false}>
          <EuiText style={{ textAlign: 'center' }}>Click on the icons below to view your ads</EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup responsive={false} justifyContent={'center'}>
        {visibleAdViews.length < props.adViews.length && (
          <EuiFlexItem grow={false} style={{ verticalAlign: 'middle' }}>
            <EuiButtonIcon onClick={CycleLeft} iconType={'arrowLeft'} style={{ marginTop: 'auto', marginBottom: 'auto' }} aria-label={'Previous'} />
          </EuiFlexItem>
        )}
        {visibleAdViews.map((adView, index) => {
          const { width, height } = getDisplayDimensions(adView, cellWidth - 20, cellWidth - 20)
          return (
            <EuiFlexItem key={adView.key} grow={false}>
              <EuiPanel hasShadow={false} hasBorder={true} onClick={() => onAdViewCellClicked(index, adView.key)} style={{ width: cellWidth, height: cellWidth, padding: 0, backgroundColor: index === selectedAdViewVisibleIndex ? '#97c4ff' : undefined }}>
                <span style={{ margin: 'auto', display: 'block', textAlign: 'center' }}>
                  <EuiImage src={adView.adImageSrc} alt={adView.key} width={width} height={height} />
                </span>
              </EuiPanel>
            </EuiFlexItem>
          )
        })}
        {visibleAdViews.length < props.adViews.length && (
          <EuiFlexItem grow={false}>
            <EuiButtonIcon onClick={CycleRight} iconType={'arrowRight'} style={{ marginTop: 'auto', marginBottom: 'auto' }} aria-label={'Next'} />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </React.Fragment>
  )
}
