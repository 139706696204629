import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeError } from '@stripe/stripe-js'
import React, { useState } from 'react'

import { EuiButton, EuiSpacer } from '@elastic/eui'

export interface IACPaymentElement {
  return_url?: string
  onError?: (error: StripeError) => void
  preSubmitAsync?: () => Promise<void>
  preSubmit?: () => void
}

export const ACPaymentElement: React.FC<IACPaymentElement> = props => {
  const stripe = useStripe()
  const elements = useElements()

  const [loading, setLoading] = useState<boolean>(false)

  const stripeConfirmSetup = () => {
    if (stripe && elements) {
      stripe
        .confirmSetup({
          elements,
          confirmParams: {
            return_url: props.return_url ?? window.location.href
          }
        })
        .then(result => {
          const error = result.error
          if (error) {
            props.onError?.(error)
            setLoading(false)
          }
        })
    }
  }

  const onAddPaymentMethod = () => {
    if (stripe && elements) {
      setLoading(true)
      props.preSubmit?.()
      if (props.preSubmitAsync) {
        props.preSubmitAsync().then(() => {
          stripeConfirmSetup()
        })
      } else {
        stripeConfirmSetup()
      }
    }
  }

  return (
    <React.Fragment>
      <PaymentElement />
      <EuiSpacer />
      <EuiButton onClick={onAddPaymentMethod} disabled={!stripe || !elements} isLoading={loading}>
        Submit
      </EuiButton>
    </React.Fragment>
  )
}
