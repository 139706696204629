import React, { FC } from 'react'

import { Chart, LIGHT_THEME, PartialTheme, Settings, AreaSeries, ScaleType, Position, Axis, RecursivePartial, AreaSeriesStyle } from '@elastic/charts'
import { CurveType } from '@elastic/charts/dist/utils/curves'
import { EuiLoadingChart, useGeneratedHtmlId } from '@elastic/eui'

import { Insights } from 'api/interfaces/Insights'
import { primaryTrajectoryColor, repetitionChartArea, repetitionChartLine, secondaryTrajectoryColor } from 'components/insights/InsightsConstants'

export interface InsightsRepetitionChartProps {
  isLoading: boolean
  repetitionTarget: number
  repetition?: Insights
}

const InsightsRepetitionChart: FC<InsightsRepetitionChartProps> = ({ isLoading, repetitionTarget, repetition }) => {
  const chartId = useGeneratedHtmlId()

  const target = repetitionTarget
  const chartData = [] as any[]
  const chartTrajectoryData = [] as any[]

  const chartBridgeData = [] as any[]
  let firstBridgeKey = ''
  let firstBridgeVal = -1

  let missingMonths = [] as string[]
  let lastVal = 0

  for (const item in repetition?.byMonth) {
    let yVal: number | null = repetition?.byMonth[item]?.value ?? 0

    if (yVal === -1) {
      missingMonths.push(item)
      continue
    }

    firstBridgeKey = item
    firstBridgeVal = yVal
    lastVal = yVal
    chartData.push({ x: item, y: yVal })
  }

  const ratio = Math.round((target - lastVal) / missingMonths.length)
  let i = 1

  missingMonths.map(m => {
    let yVal = ratio * i + lastVal

    if (chartTrajectoryData.length <= 0) {
      if (firstBridgeKey.length > 0 && firstBridgeVal !== -1) {
        chartBridgeData.push({ x: firstBridgeKey, y: firstBridgeVal })
      }

      chartBridgeData.push({ x: m, y: yVal })
    }

    if (i != missingMonths.length) {
      chartTrajectoryData.push({ x: m, y: yVal })
    } else {
      chartTrajectoryData.push({ x: m, y: target })
    }

    i++
  })

  const theme: PartialTheme = {
    axes: {
      tickLabel: {
        fontSize: 13
      }
    },
    areaSeriesStyle: {
      line: {
        strokeWidth: 3,
        stroke: repetitionChartLine
      },
      area: {
        fill: repetitionChartArea
      },
      point: {
        strokeWidth: 3,
        radius: 5,
        visible: true,
        fill: repetitionChartArea
      }
    },
    colors: {
      vizColors: [repetitionChartLine]
    }
  }

  const bridgeTheme: RecursivePartial<AreaSeriesStyle> = {
    line: {
      stroke: primaryTrajectoryColor
    },
    area: {
      fill: primaryTrajectoryColor
    },
    point: {
      stroke: secondaryTrajectoryColor,
      fill: primaryTrajectoryColor,
      visible: false
    }
  }

  const trajectoryTheme: RecursivePartial<AreaSeriesStyle> = {
    line: {
      stroke: primaryTrajectoryColor
    },
    area: {
      fill: primaryTrajectoryColor
    },
    point: {
      stroke: primaryTrajectoryColor,
      fill: primaryTrajectoryColor
    }
  }

  if (isLoading) {
    return <EuiLoadingChart size='l' />
  }

  return (
    <Chart id={chartId} size={{ height: 150 }}>
      <Settings theme={theme} baseTheme={LIGHT_THEME} />
      <AreaSeries data={chartData} id='hold' filterSeriesInTooltip={_ => false} xAccessor='x' yAccessors={['y']} xScaleType={ScaleType.Ordinal} yScaleType={ScaleType.Linear} curve={CurveType.CURVE_MONOTONE_X} />
      <AreaSeries data={chartBridgeData} id='bridge' filterSeriesInTooltip={_ => false} xAccessor='x' yAccessors={['y']} xScaleType={ScaleType.Ordinal} yScaleType={ScaleType.Linear} curve={CurveType.CURVE_MONOTONE_X} areaSeriesStyle={bridgeTheme} />
      <AreaSeries data={chartData} id='Avg saturation' xAccessor='x' yAccessors={['y']} xScaleType={ScaleType.Ordinal} yScaleType={ScaleType.Linear} curve={CurveType.CURVE_MONOTONE_X} />
      <AreaSeries data={chartTrajectoryData} id='Avg saturation predicted' xAccessor='x' yAccessors={['y']} xScaleType={ScaleType.Ordinal} yScaleType={ScaleType.Linear} curve={CurveType.CURVE_MONOTONE_X} color={[primaryTrajectoryColor]} areaSeriesStyle={trajectoryTheme} />
      <Axis id='avg. saturation' position={Position.Left} />
      <Axis id='date' position={Position.Bottom} tickFormat={d => d?.toString()} />
    </Chart>
  )
}

export default InsightsRepetitionChart
