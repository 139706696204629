import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ScreenSize {
  sm: boolean
  md: boolean
  lg: boolean
  width: number
  height: number
}

export type EventsState = {
  screenSize: ScreenSize
}

const initialState: EventsState = {
  screenSize: {
    sm: false,
    md: false,
    lg: false,
    width: 0,
    height: 0
  }
}
const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    updateScreenSize: (state, action: PayloadAction<ScreenSize>) => {
      state.screenSize = action.payload
    }
  }
})

export const { updateScreenSize } = eventsSlice.actions

export default eventsSlice.reducer
