import React from 'react'

import { EuiConfirmModal, EuiOverlayMask } from '@elastic/eui'

import { PaymentMethodPicker, usePaymentMethodPickerState } from 'components/payments/PaymentMethodPicker'

export interface IAcPaymentModal {
  title: string
  message: string
  confirmButtonText: string
  cancelButtonText: string
  onCancel: () => void
  onConfirm: (paymentMethodId: string) => void
  isLoading?: boolean
}

export const AcPaymentModal: React.FC<IAcPaymentModal> = props => {
  const [{ selectedPaymentMethodId }, setPaymentMethodPickerState] = usePaymentMethodPickerState()

  const onConfirm = () => {
    props.onConfirm(selectedPaymentMethodId)
  }

  const onCancel = () => {
    if (!props.isLoading) {
      props.onCancel()
    }
  }

  return (
    <EuiOverlayMask>
      <EuiConfirmModal title={props.title} onConfirm={onConfirm} onCancel={onCancel} confirmButtonText={props.confirmButtonText} cancelButtonText={props.cancelButtonText} isLoading={props.isLoading} confirmButtonDisabled={!selectedPaymentMethodId}>
        <p>{props.message}</p>
        <PaymentMethodPicker paymentMethodPickerState={{ selectedPaymentMethodId, selectedCampaignSlotId: '' }} updatePaymentMethodPickerState={setPaymentMethodPickerState} />
      </EuiConfirmModal>
    </EuiOverlayMask>
  )
}
