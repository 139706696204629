import { Account } from 'api/interfaces'
import { TempKeyForAccountAndInvoiceHandle } from 'api/interfaces/TempKeyForAccountAndInvoiceHandle'
import { defaultTransformFn, IBaseQuery, platformApi } from 'api/rtkQueryApi/platform/platformApi'
import { CreateAccountRequest } from 'api/rtkQueryApi/requestModels/AccountModels'

export interface IGetTempKeyForAccountAndInvoiceHandleQuery extends IBaseQuery {
  accountId: string
  invoiceHandle: string
}

const accountsTags = platformApi.enhanceEndpoints({ addTagTypes: ['Accounts', 'Account', 'TempKeyForAccountAndInvoiceHandle'] })

const accountsApi = accountsTags.injectEndpoints({
  endpoints: build => ({
    getAccounts: build.query<Account[], IBaseQuery>({
      query: () => ({
        url: `accounts`,
        method: 'GET',
        isContext: true
      }),
      transformResponse: defaultTransformFn,
      providesTags: result => (result ? [...result.map(({ id }) => ({ type: 'Account' as const, id: id })), { type: 'Accounts' }] : [{ type: 'Accounts' }])
    }),
    createAccount: build.mutation<Account, CreateAccountRequest>({
      query: createAccountRequest => ({
        url: `accounts`,
        method: 'POST',
        data: createAccountRequest,
        isContext: true
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: () => [{ type: 'Accounts' }]
    }),
    getTempKeyForAccountAndInvoiceHandle: build.query<TempKeyForAccountAndInvoiceHandle, IGetTempKeyForAccountAndInvoiceHandleQuery>({
      query: ({ accountId, invoiceHandle, isAgency }) => {
        return {
          url: `accounts/${accountId}/${invoiceHandle}/tempKey`,
          method: 'GET',
          isContext: true,
          isAgency: isAgency
        }
      },
      transformResponse: (response: { data: string }, meta, { accountId, invoiceHandle }) => {
        return {
          tempKey: response.data,
          accountId,
          invoiceHandle
        }
      }
    })
  }),
  overrideExisting: false
})

export const { useGetAccountsQuery, useCreateAccountMutation, useLazyGetTempKeyForAccountAndInvoiceHandleQuery } = accountsApi
