import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'

import { apiGetOrganizationSubscriptions, apiCancelOrganizationSubscriptions, apiGetOrganizationUsers, apiPostOrganizationUsers, apiGetAccountCampaignInvoices, apiGetOrganizationCreditNotes, apiReactivateOrganizationSubscriptions, apiGetOrganizationSubscriptionInvoices } from 'api/adcritterApi'
import { Invoice, PaymentMethod, Subscription, User, CreditNote } from 'api/interfaces'
import { AppThunk } from 'app/store'

interface OrganizationState {
  isOpen: boolean
  isLoading: boolean
  isProcessing: boolean
  errors: Error[] | null
  paymentMethods: PaymentMethod[]
  platformAccess: Subscription | null
  token: string
  users: User[]
  invoices: Invoice[]
  creditNotes: CreditNote[]
}

const initialState: OrganizationState = {
  isOpen: false,
  isLoading: false,
  isProcessing: false,
  errors: null,
  paymentMethods: [],
  platformAccess: null,
  token: '',
  users: [],
  invoices: [],
  creditNotes: []
}

const organization = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    open(state: OrganizationState) {
      state.isOpen = true
    },
    close(state: OrganizationState) {
      state.isOpen = false
    },
    loadingStarted(state: OrganizationState) {
      state.isLoading = true
    },
    loadingFailed(state: OrganizationState, { payload }: PayloadAction<Error[]>) {
      state.isLoading = false
      state.errors = payload
    },
    processingStarted(state: OrganizationState) {
      state.isProcessing = true
    },
    processingFinished(state: OrganizationState) {
      state.isProcessing = false
    },
    processingFailed(state, { payload }: PayloadAction<Error[]>) {
      state.isProcessing = false
      state.errors = payload
    },
    paymentMethodsLoaded(state: OrganizationState, { payload }: PayloadAction<PaymentMethod[]>) {
      state.errors = []
      state.isLoading = false
      state.isProcessing = false
      state.paymentMethods = payload
    },
    paymentMethodSetup(state: OrganizationState, { payload }: PayloadAction<string>) {
      state.errors = []
      state.isProcessing = false
      state.token = payload
    },
    paymentMethodClear(state: OrganizationState) {
      state.errors = []
      state.token = ''
    },
    platformAccessLoaded(state: OrganizationState, { payload }: PayloadAction<Subscription>) {
      state.errors = []
      state.isLoading = false
      state.isProcessing = false
      state.platformAccess = payload
    },
    usersLoaded(state: OrganizationState, { payload }: PayloadAction<User[]>) {
      state.errors = []
      state.isLoading = false
      state.isProcessing = false
      state.users = payload
    },
    invoicesLoaded(state: OrganizationState, { payload }: PayloadAction<Invoice[]>) {
      state.errors = []
      state.isLoading = false
      state.isProcessing = false
      state.invoices = payload
    },
    creditNotesLoaded(state: OrganizationState, { payload }: PayloadAction<CreditNote[]>) {
      state.errors = []
      state.isLoading = false
      state.isProcessing = false
      state.creditNotes = payload
    }
  }
})

export const { open, close, loadingStarted, loadingFailed, processingStarted, processingFinished, processingFailed, paymentMethodsLoaded, paymentMethodSetup, paymentMethodClear, platformAccessLoaded, usersLoaded, invoicesLoaded, creditNotesLoaded } = organization.actions

export default organization.reducer

export const fetchOrganizationPlatformAccess = (): AppThunk => async dispatch => {
  dispatch(loadingStarted())
  const platformAccessFuncs: Promise<any>[] = [apiGetOrganizationSubscriptions(), apiGetOrganizationSubscriptionInvoices()]
  const [subscription, invoices] = await Promise.all(platformAccessFuncs)
  if (subscription.errors) {
    dispatch(loadingFailed(subscription.errors.concat(invoices.errors)))
  } else {
    let result = subscription.data
    result.invoices = invoices.data.sort((a: Invoice, b: Invoice) => ((a.issueDate ?? moment()) < (b.issueDate ?? moment()) ? 1 : -1))
    dispatch(platformAccessLoaded(subscription.data))
  }
}

export const cancelOrganizationPlatformAccess = (): AppThunk => async dispatch => {
  dispatch(loadingStarted())
  const response = await apiCancelOrganizationSubscriptions()
  if (response.errors) {
    dispatch(loadingFailed(response.errors))
  } else {
    dispatch(platformAccessLoaded(response.data))
  }
}

export const reactivateOrganizationPlatformAccess = (): AppThunk => async dispatch => {
  dispatch(loadingStarted())
  const response = await apiReactivateOrganizationSubscriptions()
  if (response.errors) {
    dispatch(loadingFailed(response.errors))
  } else {
    dispatch(platformAccessLoaded(response.data))
  }
}

export const fetchOrganizationUsers = (): AppThunk => async dispatch => {
  dispatch(loadingStarted())
  const response = await apiGetOrganizationUsers()
  if (response.errors) {
    dispatch(loadingFailed(response.errors))
  } else {
    dispatch(usersLoaded(response.data))
  }
}

export const inviteOrganizationUser =
  (firstName: string, lastName: string, email: string, password: string): AppThunk =>
  async dispatch => {
    dispatch(loadingStarted())
    const response = await apiPostOrganizationUsers(firstName, lastName, email, password)
    if (response.errors) {
      dispatch(loadingFailed(response.errors))
    } else {
      dispatch(usersLoaded(response.data))
    }
  }

export const fetchOrganizationInvoices =
  (accountId: string): AppThunk =>
  async dispatch => {
    dispatch(loadingStarted())
    const response = await apiGetAccountCampaignInvoices(accountId)
    if (response.errors) {
      dispatch(loadingFailed(response.errors))
    } else {
      response.data.sort((a: Invoice, b: Invoice) => ((a.issueDate ?? moment()) < (b.issueDate ?? moment()) ? 1 : -1))
      dispatch(invoicesLoaded(response.data))
    }
  }

export const fetchOrganizationCreditNotes = (): AppThunk => async dispatch => {
  dispatch(loadingStarted())
  const response = await apiGetOrganizationCreditNotes()
  if (response.errors) {
    dispatch(loadingFailed(response.errors))
  } else {
    dispatch(creditNotesLoaded(response.data))
  }
}
