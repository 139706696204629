import ReactPixel from 'react-facebook-pixel'

import { AuthResponse } from 'api/interfaces'
import config from 'app/config'

export const useUpdatePixelAfterLogin = () => {
  return (authResponse: AuthResponse) => {
    ReactPixel.fbq('init', config.facebook.PIXEL_ID, {
      external_id: authResponse.id,
      em: authResponse.email
    })
  }
}
