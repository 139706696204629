import { createSlice } from '@reduxjs/toolkit'

type OverviewStats = 'Views' | 'Spend' | 'Cpm'
type AxisPosition = 'left' | 'right'

interface TvOverviewState {
  showViews: boolean
  showSpend: boolean
  showCpm: boolean
  positionViews: AxisPosition
  positionSpend: AxisPosition
  positionCpm: AxisPosition
  lastClick: OverviewStats
}

const initialState: TvOverviewState = {
  showViews: true,
  showSpend: true,
  showCpm: false,
  positionViews: 'left',
  positionSpend: 'right',
  positionCpm: 'left',
  lastClick: 'Spend'
}

function getCount(state: TvOverviewState, currentClick: OverviewStats) {
  let count = 0
  if (currentClick !== 'Views' && state.showViews) count++
  if (currentClick !== 'Spend' && state.showSpend) count++
  if (currentClick !== 'Cpm' && state.showCpm) count++

  return count
}

function setPosition(state: TvOverviewState, position: AxisPosition, currentClick: OverviewStats) {
  switch (currentClick) {
    case 'Views':
      state.positionViews = position
      break
    case 'Spend':
      state.positionSpend = position
      break
    case 'Cpm':
      state.positionCpm = position
      break
  }
}

function getPosition(state: TvOverviewState, currentClick: OverviewStats) {
  switch (currentClick) {
    case 'Views':
      return state.positionViews
    case 'Spend':
      return state.positionSpend
    case 'Cpm':
      return state.positionCpm
  }
}

function turnOnSimple(state: TvOverviewState, currentClick: OverviewStats) {
  if (currentClick !== 'Views' && state.showViews) setPosition(state, getPosition(state, 'Views') === 'left' ? 'right' : 'left', currentClick)
  if (currentClick !== 'Spend' && state.showSpend) setPosition(state, getPosition(state, 'Spend') === 'left' ? 'right' : 'left', currentClick)
  if (currentClick !== 'Cpm' && state.showCpm) setPosition(state, getPosition(state, 'Cpm') === 'left' ? 'right' : 'left', currentClick)
}

function turnOnToggle(state: TvOverviewState, currentClick: OverviewStats) {
  if (currentClick !== 'Views' && state.showViews && state.lastClick !== 'Views') {
    state.showViews = false
    setPosition(state, getPosition(state, 'Views'), currentClick)
  }
  if (currentClick !== 'Spend' && state.showSpend && state.lastClick !== 'Spend') {
    state.showSpend = false
    setPosition(state, getPosition(state, 'Spend'), currentClick)
  }
  if (currentClick !== 'Cpm' && state.showCpm && state.lastClick !== 'Cpm') {
    state.showCpm = false
    setPosition(state, getPosition(state, 'Cpm'), currentClick)
  }
}

const tvOverview = createSlice({
  name: 'tvOverview',
  initialState,
  reducers: {
    toggleViews(state: TvOverviewState) {
      const count = getCount(state, 'Views')

      if (count === 0) {
        state.positionViews = 'left'
      }
      if (count === 1) {
        turnOnSimple(state, 'Views')
      }
      if (count > 1) {
        turnOnToggle(state, 'Views')
      }
      state.lastClick = 'Views'
      state.showViews = !state.showViews
    },
    toggleSpend(state: TvOverviewState) {
      const count = getCount(state, 'Spend')

      if (count === 0) {
        state.positionSpend = 'left'
      }
      if (count === 1) {
        turnOnSimple(state, 'Spend')
      }
      if (count > 1) {
        turnOnToggle(state, 'Spend')
      }
      state.lastClick = 'Spend'
      state.showSpend = !state.showSpend
    },
    toggleCpm(state: TvOverviewState) {
      const count = getCount(state, 'Cpm')

      if (count === 0) {
        state.positionCpm = 'left'
      }
      if (count === 1) {
        turnOnSimple(state, 'Cpm')
      }
      if (count > 1) {
        turnOnToggle(state, 'Cpm')
      }
      state.lastClick = 'Cpm'
      state.showCpm = !state.showCpm
    }
  }
})

export const { toggleViews, toggleCpm, toggleSpend } = tvOverview.actions

export default tvOverview.reducer
