import { createApi } from '@reduxjs/toolkit/query/react'

import { axiosBaseQuery } from 'api/rtkQueryApi/platform/axiosBaseQuery'

export interface IBaseQuery {
  isAgency?: boolean
}

export interface IBaseWithAccountQuery extends IBaseQuery {
  accountId: string
}

export function defaultTransformFn<T>(response: { data: T }) {
  return response.data
}

export const platformApi = createApi({
  reducerPath: 'platformApi',
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({})
})
