import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiForm, EuiSpacer } from '@elastic/eui'

import { apiGetOrganizationPrepareUnpaidSubscription, apiPostOrganizationRestart } from 'api/adcritterApi'
import { refreshOrganizationAndAccount, setPaidSubscription, showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { PaymentMethodPicker, usePaymentMethodPickerState } from 'components/payments/PaymentMethodPicker'

const UnpaidSubscriptionCallOut = () => {
  const dispatch = useDispatch()
  const { currentOrganization, currentAccount } = useSelector((state: RootState) => state.app)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [amount, setAmount] = useState<number>(0)
  const [paymentMethodPickerState, setPaymentMethodPickerState] = usePaymentMethodPickerState()

  useEffect(() => {
    if (currentOrganization && currentAccount) {
      apiGetOrganizationPrepareUnpaidSubscription().then(result => {
        if (result.data.isStillUnpaid) {
          setAmount(result.data.amount)
        } else {
          dispatch(setPaidSubscription())
          dispatch(refreshOrganizationAndAccount(currentAccount.id))
        }
      })
    }
  }, [dispatch, currentOrganization, currentAccount])

  const restartSubscription = () => {
    if (paymentMethodPickerState.selectedPaymentMethodId) {
      setIsProcessing(true)
      apiPostOrganizationRestart(paymentMethodPickerState.selectedPaymentMethodId).then(() => {
        dispatch(showSuccessToast('Successfully paid for your platform subscription'))
        setIsProcessing(false)
        dispatch(setPaidSubscription())
        dispatch(refreshOrganizationAndAccount(currentAccount!.id))
      })
    }
  }

  return (
    <React.Fragment>
      <EuiCallOut title='Please update your payment method to continue using AdCritter.' iconType='alert' color='warning'>
        <p>AdCritter has been trying to settle payment for your monthly platform fee, but unfortunately your payment method on file was declined.</p>
        <p>Please select a valid payment method below and click the pay button to resume your campaign(s).</p>
        <EuiForm component='form'>
          {currentAccount?.id && <PaymentMethodPicker paymentMethodPickerState={paymentMethodPickerState} updatePaymentMethodPickerState={setPaymentMethodPickerState} />}
          <EuiSpacer />
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton id='pay' type='submit' onClick={restartSubscription} isLoading={isProcessing} isDisabled={!paymentMethodPickerState.selectedPaymentMethodId} color='warning'>
                Pay {amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} Now
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>
      </EuiCallOut>
      <EuiSpacer />
    </React.Fragment>
  )
}

export default UnpaidSubscriptionCallOut
