export interface ISize {
  Width: number
  Height: number
}

export const displayAdSizes = [
  { width: 160, height: 600 },
  { width: 300, height: 50 },
  { width: 300, height: 250 },
  { width: 300, height: 600 },
  { width: 320, height: 50 },
  { width: 336, height: 280 },
  { width: 728, height: 90 },
  { width: 970, height: 90 },
  { width: 970, height: 250 }
]

export const getAdSizeFromFileName = (fileName: string) => {
  return displayAdSizes.find(size => {
    const regex = new RegExp(`${size.width}\s*x\s*${size.height}`, 'gi')
    return fileName.match(regex)
  })
}

export const ScaleSizes = (maxWidth: number, maxHeight: number, width: number, height: number): ISize => {
  let newWidth = width
  let newHeight = height

  let widthRatio = width / maxWidth
  let heightRatio = height / maxHeight
  if (widthRatio > 1 || heightRatio > 1) {
    let divisor = widthRatio > heightRatio ? widthRatio : heightRatio

    newWidth = newWidth / divisor
    newHeight = newHeight / divisor
  }
  return { Width: newWidth, Height: newHeight }
}

export const GetBase64Size = (base64: string, onGetSize: (size: ISize) => void): void => {
  const i = new Image()

  i.onload = function () {
    onGetSize({ Width: i.width, Height: i.height })
  }

  i.src = base64
}

export const scaleImage = (file: File): Promise<Blob | null> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    const blobImgUrl = URL.createObjectURL(file)

    img.onload = () => {
      if (displayAdSizes.some(s => s.width === img.width && s.height === img.height)) {
        resolve(file)
        return
      }

      const newSize = getAdSizeFromFileName(file.name)

      if (!newSize) {
        resolve(file)
        return
      }

      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      if (!ctx) {
        reject('Could not get canvas context')
        return
      }

      canvas.width = newSize.width
      canvas.height = newSize.height

      ctx.drawImage(img, 0, 0, newSize.width, newSize.height)
      canvas.toBlob(blob => {
        resolve(blob)
      })
    }

    img.src = blobImgUrl
  })
}
