import React, { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { EuiPage, EuiPageHeader, EuiPageHeaderSection, EuiTitle, EuiPageBody, EuiFlexItem, EuiSpacer, EuiLoadingChart, EuiFlexGroup, EuiCallOut } from '@elastic/eui'

import { useGetCampaignInsightsAdFatigueQuery, useGetCampaignInsightsConfigurationQuery, useGetCampaignInsightsOverviewQuery, useGetCampaignInsightsRepetitionQuery, useGetCampaignInsightsSaturationQuery, useGetCampaignInsightsTimeToBehaviorModificationCurrentMonthQuery, useLazyGetCampaignInsightsConfigurationQuery } from 'api/rtkQueryApi/platform/campaignInsightsApi'
import { useGetCreativesQuery } from 'api/rtkQueryApi/platform/creativesApi'
import { RootState } from 'app/rootReducer'
import CampaignInsightsOverview from 'components/insights/Panels/CampaignInsightsOverview'
import InsightsAdFatigue from 'components/insights/Panels/InsightsAdFatigue'
import InsightsRepetition from 'components/insights/Panels/InsightsRepetition'
import InsightsSaturation from 'components/insights/Panels/InsightsSaturation'
import InsightsTimeToBehaviorModification from 'components/insights/Panels/InsightsTimeToBehaviorModification'
import { fetchCampaign } from 'features/campaigns/campaign/campaignSlice'

interface CampaignInsightsPageParams {
  campaignId: string
}

const CampaignInsightsPage: FC = () => {
  const dispatch = useDispatch()
  const { campaignId } = useParams<CampaignInsightsPageParams>()
  const { currentAccount, currentOrganization } = useSelector((state: RootState) => state.app)
  const { isLoadingCampaign, campaign } = useSelector((state: RootState) => state.campaign)

  const query = { accountId: currentAccount?.id ?? '', campaignId: campaignId ?? '' }
  const skip = { skip: !currentAccount || !currentOrganization || !campaignId }

  const { isLoading: isLoadingCreatives, data: creatives } = useGetCreativesQuery(query, skip)

  const { isLoading: isLoadingConfig, data: config } = useGetCampaignInsightsConfigurationQuery(query, skip)
  const { isLoading: isLoadingOverview, data: overview } = useGetCampaignInsightsOverviewQuery(query, skip)
  const { isLoading: isLoadingRepetition, data: repetition } = useGetCampaignInsightsRepetitionQuery(query, skip)
  const { isLoading: isLoadingSaturation, data: saturation } = useGetCampaignInsightsSaturationQuery(query, skip)
  const { isLoading: isLoadingFatigue, data: adFatigue } = useGetCampaignInsightsAdFatigueQuery(query, skip)
  const { isLoading: isLoadingTimeToBehaviorCurrentMonth, data: timeToBehaviorCurrentMonth } = useGetCampaignInsightsTimeToBehaviorModificationCurrentMonthQuery(query, skip)

  useEffect(() => {
    if (currentAccount && currentOrganization && campaignId) {
      dispatch(fetchCampaign(currentAccount.id, campaignId))
    }
  }, [currentAccount, currentOrganization, campaignId, dispatch])

  if (isLoadingCampaign || !currentAccount || !currentOrganization || !campaign || campaign.id !== campaignId || isLoadingConfig || !config) {
    return (
      <EuiFlexGroup justifyContent='spaceAround' alignItems='flexStart'>
        <EuiFlexItem grow={false}>
          <EuiSpacer />
          <EuiLoadingChart size='xl' />
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Insights</title>
      </Helmet>
      <EuiPage className='euiPage--platform'>
        <EuiPageBody>
          <EuiCallOut size='s' color='warning' title='This report is currently in beta.' iconType='beaker' />

          <EuiSpacer size='xl' />

          <EuiPageHeader>
            <EuiPageHeaderSection>
              <EuiTitle>
                <h1>Campaign Insights - {campaign.name}</h1>
              </EuiTitle>
            </EuiPageHeaderSection>
          </EuiPageHeader>
          <CampaignInsightsOverview isLoading={isLoadingOverview} overview={overview} />

          <EuiSpacer size='xl' />

          <InsightsRepetition isLoading={isLoadingConfig || isLoadingRepetition} repetition={repetition} config={config} />

          <EuiSpacer size='xl' />

          <InsightsSaturation isLoading={isLoadingConfig || isLoadingSaturation} saturation={saturation} config={config} />

          <EuiSpacer size='xl' />

          <InsightsAdFatigue isLoading={isLoadingCreatives || isLoadingConfig || isLoadingFatigue} adFatigue={adFatigue} creatives={creatives} />

          <EuiSpacer size='xl' />

          <InsightsTimeToBehaviorModification isLoading={isLoadingConfig || isLoadingTimeToBehaviorCurrentMonth} timeToBehaviorCurrentMonth={timeToBehaviorCurrentMonth} config={config} />
        </EuiPageBody>
      </EuiPage>
    </React.Fragment>
  )
}

export default CampaignInsightsPage
