import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useRouteMatch, Switch, Route } from 'react-router-dom'

import { EuiPage, EuiPageBody } from '@elastic/eui'

import { RootState } from 'app/rootReducer'

import AccountDrawer from './AccountDrawer'

export const SettingsPage: React.FC = () => {
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const { path } = useRouteMatch()

  let content

  if (currentAccount == null) {
    content = <React.Fragment></React.Fragment>
  } else {
    content = (
      <EuiPage className='euiPage--platform'>
        <EuiPageBody>
          <Switch>
            <Route exact path={path}>
              <AccountDrawer />
            </Route>
          </Switch>
        </EuiPageBody>
      </EuiPage>
    )
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Account Settings</title>
      </Helmet>
      {content}
    </React.Fragment>
  )
}

export default SettingsPage
