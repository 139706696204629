import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { EuiCard, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiSpacer, EuiText, EuiTitle, EuiPageBody } from '@elastic/eui'

import { RootState } from 'app/rootReducer'
import { fetchCampaigns } from 'features/campaigns/campaigns/campaignsSlice'
import history from 'services/HistoryService'

import { startBuilder as startBillboardRetailBuilder } from './billboards/retail/builderBillboardRetailSlice'
import { startBuilder as startInternetRetailBuilder } from './internet/retail/builderInternetRetailSlice'
import { startBuilder as startInternetVistaBuilder } from './internet/vista/builderInternetVistaSlice'
import { startBuilder as startTvRetailBuilder } from './tv/retail/builderTvRetailSlice'

const StartThirdPartyBuilderPage: React.FC = () => {
  const dispatch = useDispatch()
  const { currentOrganization, currentAccount } = useSelector((state: RootState) => state.app)
  const [isGreatClips, setIsGreatClips] = useState<boolean | undefined>()
  const [isVista, setIsVista] = useState<boolean | undefined>()

  useEffect(() => {
    if (currentOrganization) {
      setIsGreatClips(currentOrganization.targetingTemplates.indexOf('greatclips-recruitment') >= 0)
      setIsVista(currentOrganization.targetingTemplates.indexOf('Vista') >= 0)
    }
  }, [currentOrganization])

  useEffect(() => {
    if (currentAccount) {
      dispatch(fetchCampaigns(currentAccount.id))
    }
  }, [dispatch, currentAccount])

  const setTargetedTv = () => {
    if (typeof isGreatClips !== 'undefined' && !isGreatClips) {
      dispatch(startTvRetailBuilder())
    } else {
      history.push('/build/tv/start')
    }
  }

  const setInternet = () => {
    if (typeof isGreatClips !== 'undefined' && !isGreatClips && typeof isVista !== 'undefined') {
      if (!isVista) {
        dispatch(startInternetRetailBuilder())
      } else if (isVista) {
        dispatch(startInternetVistaBuilder())
      }
    } else {
      history.push('/build/internet/start')
    }
  }

  const setBillboard = () => {
    dispatch(startBillboardRetailBuilder())
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Choose Your Campaign</title>
      </Helmet>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle>
            <h1>New Campaign</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>
      <EuiPageBody>
        <EuiPanel grow={false} className='eui-xScroll'>
          <EuiSpacer />
          <EuiFlexGroup>
            <EuiFlexItem grow={true} />
            <EuiFlexItem grow={false} style={{ width: 280 }}>
              <EuiCard id='tvTile' icon={<EuiIcon size='xxl' type='play' color='success' />} title='Targeted TV' description='Create a new Targeted TV campaign' onClick={setTargetedTv} footer={<EuiText size='s'>Create, target and deliver TV commercials to drive your business.</EuiText>} />
            </EuiFlexItem>
            <EuiFlexItem grow={false} style={{ width: 280 }}>
              <EuiCard id='internetTile' icon={<EuiIcon size='xxl' type='globe' color='success' />} title='Internet' description='Create a new Internet Ad campaign' onClick={setInternet} footer={<EuiText size='s'>Create, target and deliver internet ads to drive your business.</EuiText>} />
            </EuiFlexItem>
            <EuiFlexItem grow={true} />
          </EuiFlexGroup>
          <EuiSpacer />
          <EuiFlexGroup>
            <EuiFlexItem grow={true} />
            <EuiFlexItem grow={false} style={{ width: 280 }}>
              <EuiCard id='billboardTile' icon={<EuiIcon size='xxl' type='fullScreen' color='success' />} title='Billboards' description='Create a new Billboard campaign' onClick={setBillboard} footer={<EuiText size='s'>Create and deliver large-format digital billboard ads to drive your business.</EuiText>} />
            </EuiFlexItem>
            <EuiFlexItem grow={true} />
          </EuiFlexGroup>
          <EuiSpacer />
        </EuiPanel>
      </EuiPageBody>
    </React.Fragment>
  )
}

export default StartThirdPartyBuilderPage
