import React from 'react'

import { EuiSuperSelectOption } from '@elastic/eui/src/components/form/super_select/super_select_control'

import { AdImagePlacement, BillboardAdImageType, BillboardCustomization } from '../api/interfaces'

class billboardDefaults {
  private defaultLogoWidth: number = 144
  private defaultPhotoHeight: number = 200
  private defaultLogoHeight: number = 144
  private defaultPhotoWidth: number = 200
  private defaultTextContentWidth: number = 200
  private defaultTextContentHeight: number = 60
  private defaultTextAlign: string = 'left'

  private billboardWHRatio: number = 7 / 2

  private captureScaling: number = 4

  private fontFaces: EuiSuperSelectOption<string>[] = [
    { value: 'roboto', inputDisplay: <span style={{ fontFamily: 'Roboto' }}>Roboto</span> },
    { value: 'noto serif', inputDisplay: <span style={{ fontFamily: 'Noto Serif' }}>Noto Serif</span> },
    {
      value: 'cormorant garamond',
      inputDisplay: <span style={{ fontFamily: 'Cormorant Garamond' }}>Cormorant Garamond</span>
    },
    { value: 'amatic sc', inputDisplay: <span style={{ fontFamily: 'Amatic SC' }}>Amatic SC</span> },
    {
      value: 'Frank Ruhl Libre',
      inputDisplay: <span style={{ fontFamily: 'Frank Ruhl Libre' }}>Frank Ruhl Libre</span>
    },
    { value: 'Merienda', inputDisplay: <span style={{ fontFamily: 'Merienda' }}>Merienda</span> },
    { value: 'Noticia Text', inputDisplay: <span style={{ fontFamily: 'Noticia Text' }}>Noticia Text</span> },
    { value: 'Comfortaa', inputDisplay: <span style={{ fontFamily: 'Comfortaa' }}>Comfortaa</span> },
    { value: 'alfa slab one', inputDisplay: <span style={{ fontFamily: 'Alfa Slab One' }}>Alfa Slab One</span> }
  ]

  private alignOptions: EuiSuperSelectOption<string>[] = [
    { value: 'left', inputDisplay: <span>Left</span> },
    { value: 'center', inputDisplay: <span>Center</span> },
    { value: 'right', inputDisplay: <span>Right</span> }
  ]

  private defaultBillboard: BillboardCustomization = {
    backgroundUrl: '',
    billboardImageType: BillboardAdImageType.None,
    headline: {
      selected: false,
      fontFace: 'roboto',
      fontSize: 60,
      fontColor: '#000000',
      sampleText: '{Headline}',
      isDragging: false,
      x: 50,
      y: 50,
      width: this.defaultTextContentWidth,
      height: this.defaultTextContentHeight,
      align: this.defaultTextAlign
    },
    photo: {
      selected: false,
      sampleUrl: 'https://devopenshotstorage.blob.core.windows.net/41576445-3b3c-4913-9dfa-1d09bdc39033/83d0ea77-a165-4b4e-9c3d-e1397c3cd2ed.png',
      isDragging: false,
      width: this.defaultPhotoWidth,
      height: this.defaultPhotoHeight,
      x: 600,
      y: 50
    },
    logo: {
      selected: false,
      sampleUrl: 'https://devopenshotstorage.blob.core.windows.net/75015b99-5e46-4fdd-bbeb-6adeb6cfc565/41d18b34-7d9d-4989-a5bf-c520b04e7dbb.jpg',
      isDragging: false,
      width: this.defaultLogoWidth,
      height: this.defaultLogoHeight,
      x: 1200,
      y: 300
    },
    companyName: {
      selected: false,
      fontFace: 'roboto',
      fontSize: 60,
      fontColor: '#000000',
      sampleText: '{Company Name}',
      isDragging: false,
      x: 50,
      y: 110,
      width: this.defaultTextContentWidth,
      height: this.defaultTextContentHeight,
      align: this.defaultTextAlign
    },
    phone: {
      selected: false,
      fontFace: 'roboto',
      fontSize: 60,
      fontColor: '#000000',
      sampleText: '{Phone}',
      isDragging: false,
      x: 50,
      y: 170,
      width: this.defaultTextContentWidth,
      height: this.defaultTextContentHeight,
      align: this.defaultTextAlign
    },
    website: {
      selected: false,
      fontFace: 'roboto',
      fontSize: 60,
      fontColor: '#000000',
      sampleText: '{Website}',
      isDragging: false,
      x: 1100,
      y: 50,
      width: this.defaultTextContentWidth,
      height: this.defaultTextContentHeight,
      align: this.defaultTextAlign
    },
    location: {
      selected: false,
      fontFace: 'roboto',
      fontSize: 60,
      fontColor: '#000000',
      sampleText: '{Location}',
      isDragging: false,
      x: 1100,
      y: 110,
      width: this.defaultTextContentWidth,
      height: this.defaultTextContentHeight,
      align: this.defaultTextAlign
    },
    previewUrl: '',
    uploadedUrl: '',
    campaignIds: [],
    imageLocation: AdImagePlacement.Right,
    id: '',
    name: '',
    type: '',
    quality: '',
    kind: '',
    thumbnailUrl: '',
    status: ''
  }

  public GetDefaultLogoWidth = () => {
    return this.defaultLogoWidth
  }
  public GetDefaultPhotoHeight = () => {
    return this.defaultPhotoHeight
  }
  public GetDefaultLogoHeight = () => {
    return this.defaultLogoHeight
  }
  public GetDefaultPhotoWidth = () => {
    return this.defaultPhotoWidth
  }

  public GetDefaultBillboard = () => {
    return this.defaultBillboard
  }

  public GetFontFaces = () => {
    return this.fontFaces
  }

  public GetAlignOptions = () => {
    return this.alignOptions
  }

  public GetBillboardWHRatio = () => {
    return this.billboardWHRatio
  }

  public GetDefaultTextContentWidth = () => {
    return this.defaultTextContentWidth
  }

  public GetDefaultTextContentHeight = () => {
    return this.defaultTextContentHeight
  }

  public GetDefaultAlign = () => {
    return this.defaultTextAlign
  }

  public GetCaptureScaling = () => {
    return this.captureScaling
  }

  public LoadBillboard = (initial: BillboardCustomization, newBillboard: BillboardCustomization, onMerge: (merged: BillboardCustomization) => void) => {
    if (newBillboard.backgroundUrl) {
      onMerge({
        backgroundUrl: newBillboard.backgroundUrl,
        billboardImageType: newBillboard.billboardImageType,
        logo: {
          selected: newBillboard.logo.selected,
          sampleUrl: newBillboard.logo.sampleUrl,
          isDragging: initial.logo.isDragging,
          width: initial.logo.width,
          height: initial.logo.height,
          x: newBillboard.logo.x,
          y: newBillboard.logo.y
        },
        photo: {
          selected: newBillboard.photo.selected,
          sampleUrl: newBillboard.photo.sampleUrl,
          isDragging: initial.photo.isDragging,
          width: initial.photo.width,
          height: initial.photo.height,
          x: newBillboard.photo.x,
          y: newBillboard.photo.y
        },
        companyName: {
          selected: newBillboard.companyName.selected,
          fontFace: newBillboard.companyName.fontFace,
          fontSize: newBillboard.companyName.fontSize,
          fontColor: newBillboard.companyName.fontColor,
          sampleText: newBillboard.companyName.sampleText,
          isDragging: initial.companyName.isDragging,
          x: newBillboard.companyName.x,
          y: newBillboard.companyName.y,
          width: newBillboard.companyName.width,
          height: newBillboard.companyName.height,
          align: newBillboard.companyName.align ? newBillboard.companyName.align : initial.companyName.align
        },
        headline: {
          selected: newBillboard.headline.selected,
          fontFace: newBillboard.headline.fontFace,
          fontSize: newBillboard.headline.fontSize,
          fontColor: newBillboard.headline.fontColor,
          sampleText: newBillboard.headline.sampleText,
          isDragging: initial.headline.isDragging,
          x: newBillboard.headline.x,
          y: newBillboard.headline.y,
          width: newBillboard.headline.width,
          height: newBillboard.headline.height,
          align: newBillboard.headline.align ? newBillboard.headline.align : initial.headline.align
        },
        phone: {
          selected: newBillboard.phone.selected,
          fontFace: newBillboard.phone.fontFace,
          fontSize: newBillboard.phone.fontSize,
          fontColor: newBillboard.phone.fontColor,
          sampleText: newBillboard.phone.sampleText,
          isDragging: initial.phone.isDragging,
          x: newBillboard.phone.x,
          y: newBillboard.phone.y,
          width: newBillboard.phone.width,
          height: newBillboard.phone.height,
          align: newBillboard.phone.align ? newBillboard.phone.align : initial.phone.align
        },
        website: {
          selected: newBillboard.website.selected,
          fontFace: newBillboard.website.fontFace,
          fontSize: newBillboard.website.fontSize,
          fontColor: newBillboard.website.fontColor,
          sampleText: newBillboard.website.sampleText,
          isDragging: initial.website.isDragging,
          x: newBillboard.website.x,
          y: newBillboard.website.y,
          width: newBillboard.website.width,
          height: newBillboard.website.height,
          align: newBillboard.website.align ? newBillboard.website.align : initial.website.align
        },
        location: {
          selected: newBillboard.location.selected,
          fontFace: newBillboard.location.fontFace,
          fontSize: newBillboard.location.fontSize,
          fontColor: newBillboard.location.fontColor,
          sampleText: newBillboard.location.sampleText,
          isDragging: initial.location.isDragging,
          x: newBillboard.location.x,
          y: newBillboard.location.y,
          width: newBillboard.location.width,
          height: newBillboard.location.height,
          align: newBillboard.location.align ? newBillboard.location.align : initial.location.align
        },
        previewUrl: newBillboard.previewUrl,
        uploadedUrl: newBillboard.uploadedUrl,
        campaignIds: initial.campaignIds,
        imageLocation: AdImagePlacement.Right,
        id: initial.id,
        name: initial.name,
        type: initial.type,
        quality: initial.quality,
        kind: initial.kind,
        thumbnailUrl: initial.thumbnailUrl,
        status: initial.status
      })
    }
  }
}

export const BillboardDefaults = new billboardDefaults()
