import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiFlexGroup, EuiFlexItem, EuiText, EuiSpacer, EuiForm, EuiFormRow, EuiFieldText, EuiButton, EuiTextArea, EuiSelect, EuiTabbedContent, EuiIcon, EuiToolTip, EuiCallOut, EuiTitle } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'
import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { Ad } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import ChooseImageFlyout from 'components/chooseImage/ChooseImageFlyout'
import { fetchUploadedImages, resetSearchImage } from 'components/chooseImage/searchImageSlice'
import { DesktopDisplay, DesktopSearch } from 'components/previews'
import history from 'services/HistoryService'

import { setAd, setImage } from './builderInternetVistaSlice'

const CreateStep: React.FC = () => {
  const dispatch = useDispatch()
  const builder = useSelector((state: RootState) => state.builderInternetVista)
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const chosenImage = useSelector((state: RootState) => state.searchImage.chosenImage)
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [imageError, setImageError] = useState<string | null>(null)

  const adSchema = Yup.object().shape({
    headline: Yup.string().required('Please enter a headline').max(30),
    body: Yup.string().required('Please enter the body copy').max(90),
    companyName: Yup.string().required('Please enter your company name').max(30),
    callToAction: Yup.string().required('Please select a call-to-action'),
    destinationUrl: Yup.string()
      .matches(/^(https:\/\/)/, 'Please enter a valid website URL including https:// (ex. https://www.domain.com)')
      .url('Please enter a valid website URL including https:// (ex. https://www.domain.com)')
      .required('Please enter the website URL including https:// (ex. https://www.domain.com)')
      .max(2048)
  })

  const formik = useFormik({
    initialValues: builder.ad!,
    enableReinitialize: true,
    validationSchema: adSchema,
    onSubmit: (values: Ad) => {
      let errorFound = false
      if (!builder.image) {
        setImageError('Please select an image')
        errorFound = true
      }
      if (!errorFound) {
        dispatch(setAd(values))
        history.push('/build/internet/vista/target')
      }
    }
  })

  useEffect(() => {
    if (chosenImage) dispatch(setImage(chosenImage))
  }, [chosenImage, dispatch])

  useEffect(() => {
    if (currentAccount) dispatch(fetchUploadedImages(currentAccount.id))
  }, [currentAccount, dispatch])

  const previewTabs: Array<EuiTabbedContentTab> = [
    {
      id: 'DesktopWeb',
      name: 'Google Display Example',
      content: <DesktopDisplay ad={formik.values} image={builder.image} />
    }
  ]

  const searchTabs: Array<EuiTabbedContentTab> = [
    {
      id: 'DesktopSearch',
      name: 'Google Search Preview',
      content: <DesktopSearch ad={formik.values} image={builder.image} />
    }
  ]

  const returnChoose = () => {
    dispatch(resetSearchImage())
    history.push('/build/internet/vista/suggestions')
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Ad Builder</title>
      </Helmet>
      <EuiFlexGroup responsive={false}>
        <EuiFlexItem>
          <EuiTitle size='s'>
            <h3>Ad Builder</h3>
          </EuiTitle>
          <EuiSpacer />

          <EuiFlexGroup responsive={false}>
            <EuiFlexItem grow={true} style={{ minWidth: 280 }}>
              <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
                {!builder.image && (
                  <React.Fragment>
                    <EuiText size='s'>
                      <h4>Ad Image</h4>
                    </EuiText>
                    <EuiSpacer size='s' />
                    <div className={!!imageError ? 'euiFormRow euiFormRow--fullWidth euiFormRow-isInvalid' : 'euiFormRow euiFormRow--fullWidth'}>
                      <div className='euiFormRow__fieldWrapper'>
                        <div className={!!imageError ? 'euiFilePicker euiFilePicker--large euiFilePicker--fullWidth euiFilePicker-isInvalid' : 'euiFilePicker euiFilePicker--large euiFilePicker--fullWidth'}>
                          <div className='euiFilePicker__wrap'>
                            <div className='euiFilePicker__prompt'>
                              <EuiIcon type='image' className='euiFilePicker__icon' size='l' aria-label='choose image' />
                              <ChooseImageFlyout defaultSearch={''} />
                            </div>
                          </div>
                        </div>
                      </div>
                      {!!imageError && <div className='euiFormErrorText euiFormRow__text'>{imageError}</div>}
                      <div className='euiFormHelpText euiFormRow__text'>Search millions of available photos for your ad (if you prefer, upload an existing image.)</div>
                    </div>
                  </React.Fragment>
                )}
                {builder.image && (
                  <React.Fragment>
                    <EuiText size='s'>
                      <h4>Ad Image</h4>
                    </EuiText>
                    <EuiSpacer size='s' />
                    <div className='euiFilePicker euiFilePicker--fullWidth euiFilePicker--large euiFilePicker-hasFiles'>
                      <div className='euiFiePicker__wrap'>
                        <div className='euiFilePicker__prompt'>
                          {builder.image.isPurchased && (
                            <div
                              style={{
                                width: 82,
                                height: (82 / (builder.image.thumbnailWidth || 1)) * (builder.image.thumbnailHeight || 0),
                                borderRadius: 8,
                                marginTop: 10,
                                overflow: 'hidden'
                              }}>
                              <img src={builder.image.thumbnailUrl} alt={builder.image.id} width={82} />
                            </div>
                          )}
                          {!builder.image.isPurchased && <img src={builder.image.thumbnailUrl} alt={builder.image.id} style={{ width: 82, borderRadius: 8, marginTop: 10 }} />}
                          <EuiSpacer size={'s'} />
                          <ChooseImageFlyout defaultSearch={''} />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <EuiSpacer size='m' />
                <EuiText size='s'>
                  <h4>Ad Text & Links</h4>
                </EuiText>
                <EuiSpacer size='s' />
                <EuiFormRow
                  label='Headline'
                  fullWidth
                  isInvalid={formik.touched.headline && !!formik.errors.headline}
                  error={formik.errors.headline}
                  labelAppend={
                    <EuiText size='xs' color={(formik.values?.headline || '').length <= 30 ? 'default' : 'warning'}>
                      ({(formik.values?.headline || '').length}/30)
                    </EuiText>
                  }>
                  <EuiFieldText name='headline' defaultValue={formik.values?.headline} placeholder='Your attention-grabbing headline.' onChange={formik.handleChange} fullWidth isInvalid={formik.touched.headline && !!formik.errors.headline} />
                </EuiFormRow>

                <EuiFormRow
                  label='Body'
                  fullWidth
                  isInvalid={formik.touched.body && !!formik.errors.body}
                  error={formik.errors.body}
                  labelAppend={
                    <EuiText size='xs' color={formik.values.body.length <= 90 ? 'default' : 'warning'}>
                      ({formik.values.body.length}/90)
                    </EuiText>
                  }>
                  <EuiTextArea name='body' defaultValue={formik.values.body} placeholder='Your one or two sentence description of your product or service.' onChange={formik.handleChange} resize='none' rows={3} fullWidth isInvalid={formik.touched.body && !!formik.errors.body} />
                </EuiFormRow>

                <EuiFormRow
                  label='Company Name'
                  fullWidth
                  isInvalid={formik.touched.companyName && !!formik.errors.companyName}
                  error={formik.errors.companyName}
                  labelAppend={
                    <EuiText size='xs' color={formik.values.companyName.length <= 30 ? 'default' : 'warning'}>
                      <EuiToolTip position='bottom' content='This should match the content of the ad and landing page.'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 2 }} color='success' />
                      </EuiToolTip>
                      ({formik.values.companyName.length}/30)
                    </EuiText>
                  }>
                  <EuiFieldText name='companyName' defaultValue={formik.values.companyName} placeholder='Your Company Name' onChange={formik.handleChange} fullWidth isInvalid={formik.touched.companyName && !!formik.errors.companyName} />
                </EuiFormRow>

                <EuiFormRow label='Call-to-Action Button' fullWidth isInvalid={formik.touched.callToAction && !!formik.errors.callToAction} error={formik.errors.callToAction}>
                  <EuiSelect
                    name='callToAction'
                    isLoading={dictionary.isLoadingCallToActions}
                    options={dictionary.callToActions.map(
                      ca =>
                        ({
                          value: ca,
                          label: ca,
                          text: ca
                        } as EuiSelectOption)
                    )}
                    value={formik.values.callToAction}
                    onChange={formik.handleChange}
                    fullWidth
                    isInvalid={formik.touched.callToAction && !!formik.errors.callToAction}
                    hasNoInitialSelection={true}
                  />
                </EuiFormRow>

                <EuiFormRow
                  label='Website URL'
                  fullWidth
                  isInvalid={formik.touched.destinationUrl && !!formik.errors.destinationUrl}
                  error={formik.errors.destinationUrl}
                  labelAppend={
                    <EuiText size='xs' color={(formik.values.destinationUrl || '').length <= 2048 ? 'default' : 'warning'}>
                      <EuiToolTip position='bottom' content='This should be the page that you want users to land on when they click on this ad.'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 2 }} color='success' />
                      </EuiToolTip>
                      ({(formik.values.destinationUrl || '').length}/2048)
                    </EuiText>
                  }>
                  <EuiFieldText name='destinationUrl' defaultValue={formik.values.destinationUrl} placeholder='https://www.domain.com' onChange={formik.handleChange} fullWidth isInvalid={formik.touched.destinationUrl && !!formik.errors.destinationUrl} />
                </EuiFormRow>

                <EuiSpacer size='m' />
              </EuiForm>
            </EuiFlexItem>
            <EuiFlexItem grow={false} style={{ width: 540 }}>
              <EuiTabbedContent tabs={previewTabs} />
              {builder.image?.isPurchased && (
                <React.Fragment>
                  <EuiSpacer />
                  <EuiCallOut title='Upon activating this ad, the watermark will be removed from your image' color='success' size='s' iconType='alert' />
                </React.Fragment>
              )}
              <EuiSpacer />
              <EuiTabbedContent tabs={searchTabs} />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton id='back' fill type='button' color='text' onClick={returnChoose} iconType='arrowLeft' iconSide='left'>
                Back
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton fill type='submit' isLoading={formik.isSubmitting} iconType='arrowRight' iconSide='right' onClick={formik.submitForm}>
                Continue to Targeting
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default CreateStep
