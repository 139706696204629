import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Moment } from 'moment'

import { apiGetBillboardPreviewUrl, apiGetSuggestedBillboards, apiPostBillboardBuilderRetail } from 'api/adcritterApi'
import { AcImage, BillboardCustomization, BillboardSuggestion, BillboardUploaded, BuilderResult, BusinessType, CityTarget, DmaTarget, PostalCodeTarget, RegionTarget } from 'api/interfaces'
import { dashboardApi } from 'api/rtkQueryApi/platform/dashboardApi'
import { RootState } from 'app/rootReducer'
import { AppThunk } from 'app/store'
import { fetchCampaigns } from 'features/campaigns/campaigns/campaignsSlice'
import history from 'services/HistoryService'
import localStoreManagerService from 'services/LocalStoreManagerService'

export enum BuilderStyle {
  Suggested = 'Suggested',
  Build = 'Build',
  Upload = 'Upload',
  Custom = 'Custom'
}

export enum BuilderStep {
  Start = 10,
  Suggest = 20,
  Questionnaire = 21,
  Suggestions = 22,
  Create = 30,
  Upload = 31,
  Target = 40,
  Activate = 99
}

export interface Suggest {
  companyName: string
  cityName: string
}

export interface Targeting {
  locationType: string | null
  states: Array<RegionTarget>
  dmas: Array<DmaTarget>
  cities: Array<CityTarget>
  zips: Array<PostalCodeTarget>
}

export interface BillboardBuilderState {
  builderStep: BuilderStep
  builderStyle: BuilderStyle
  businessType: BusinessType | null
  suggest: Suggest | null
  billboards: BillboardSuggestion[] | null
  billboard: BillboardCustomization | null
  isLoadingBillboards: boolean
  images: AcImage[] | null
  isLoadingImages: boolean
  backgroundImage: AcImage | null
  create: BillboardCustomization | null
  previewUrl: string | null
  isLoadingPreviewUrl: boolean
  uploadedBillboardUrl: BillboardUploaded | null
  targeting: Targeting | null
  isCreating: boolean
  result: BuilderResult | null
  photo: AcImage | null
  loaded: boolean
  name: string | null
  budget: number | null
  totalBudget: number | null
}

const initialState: BillboardBuilderState = {
  builderStep: localStoreManagerService.getData('builderBillboardRetail.builderStep') || BuilderStep.Start,
  builderStyle: localStoreManagerService.getData('builderBillboardRetail.builderStyle') || BuilderStyle.Suggested,
  businessType: localStoreManagerService.getData('builderBillboardRetail.businessType'),
  suggest: localStoreManagerService.getData('builderBillboardRetail.suggest'),
  isLoadingBillboards: false,
  billboards: localStoreManagerService.getData('builderBillboardRetail.billboards'),
  billboard: localStoreManagerService.getData('builderBillboardRetail.billboard'),
  images: localStoreManagerService.getData('builderBillboardRetail.images') || [],
  isLoadingImages: false,
  backgroundImage: localStoreManagerService.getData('builderBillboardRetail.backgroundImage'),
  previewUrl: localStoreManagerService.getData('builderBillboardRetail.previewUrl'),
  isLoadingPreviewUrl: false,
  create: localStoreManagerService.getData('builderBillboardRetail.create'),
  uploadedBillboardUrl: localStoreManagerService.getData('builderBillboardRetail.uploadedBillboardUrl'),
  targeting: localStoreManagerService.getData('builderBillboardRetail.targeting'),
  isCreating: false,
  result: null,
  photo: localStoreManagerService.getData('builderBillboardRetail.photo'),
  loaded: localStoreManagerService.getData('builderBillboardRetail.back'),
  name: localStoreManagerService.getData('builderBillboardRetail.name'),
  budget: localStoreManagerService.getData('builderBillboardRetail.budget'),
  totalBudget: localStoreManagerService.getData('builderBillboardRetail.totalBudget')
}

function reset(state: BillboardBuilderState) {
  state.builderStep = BuilderStep.Start
  state.builderStyle = BuilderStyle.Suggested
  state.businessType = null
  state.suggest = null
  state.isLoadingBillboards = false
  state.billboards = null
  state.billboard = null
  state.images = []
  state.backgroundImage = null
  state.isLoadingImages = false
  state.uploadedBillboardUrl = null
  state.previewUrl = null
  state.isLoadingPreviewUrl = false
  state.create = null
  state.targeting = null
  state.isCreating = false
  state.result = null
  state.photo = null
  state.loaded = false
  state.name = null
  state.budget = null
  state.totalBudget = null

  localStoreManagerService.deleteData('builderBillboardRetail.builderStep')
  localStoreManagerService.deleteData('builderBillboardRetail.builderStyle')
  localStoreManagerService.deleteData('builderBillboardRetail.businessType')
  localStoreManagerService.deleteData('builderBillboardRetail.suggest')
  localStoreManagerService.deleteData('builderBillboardRetail.questionnaire')
  localStoreManagerService.deleteData('builderBillboardRetail.billboards')
  localStoreManagerService.deleteData('builderBillboardRetail.billboard')
  localStoreManagerService.deleteData('builderBillboardRetail.images')
  localStoreManagerService.deleteData('builderBillboardRetail.create')
  localStoreManagerService.deleteData('builderBillboardRetail.previewUrl')
  localStoreManagerService.deleteData('builderBillboardRetail.uploadedBillboardUrl')
  localStoreManagerService.deleteData('builderBillboardRetail.targeting')
  localStoreManagerService.deleteData('builderBillboardRetail.photo')
  localStoreManagerService.deleteData('builderBillboardRetail.loaded')
  localStoreManagerService.deleteData('builderBillboardRetail.name')
  localStoreManagerService.deleteData('builderBillboardRetail.budget')
  localStoreManagerService.deleteData('builderBillboardRetail.totalBudget')
}

const builderBillboardRetail = createSlice({
  name: 'builderBillboardRetail',
  initialState,
  reducers: {
    startBuilder(state: BillboardBuilderState) {
      reset(state)
      history.push('/build/billboards/public/start')
    },
    resetBuilder(state: BillboardBuilderState) {
      reset(state)
    },
    setBuilderStep(state: BillboardBuilderState, { payload }: PayloadAction<BuilderStep>) {
      state.builderStep = payload
      localStoreManagerService.saveSessionData('builderBillboardRetail.builderStep', payload)
    },
    setBuilderStyle(state: BillboardBuilderState, { payload }: PayloadAction<BuilderStyle>) {
      state.builderStyle = payload
      localStoreManagerService.saveSessionData('builderBillboardRetail.builderStyle', payload)
    },
    setTargeting(state: BillboardBuilderState, { payload }: PayloadAction<Targeting>) {
      state.targeting = payload
      localStoreManagerService.saveSessionData('builderBillboardRetail.targeting', payload)
    },
    setBusinessType(state: BillboardBuilderState, { payload }: PayloadAction<BusinessType>) {
      state.businessType = payload
      localStoreManagerService.saveSessionData('builderBillboardRetail.businessType', payload)
    },
    setBillboard(state: BillboardBuilderState, { payload }: PayloadAction<BillboardCustomization>) {
      state.billboard = payload
      localStoreManagerService.saveSessionData('builderBillboardRetail.billboard', payload)
    },
    setPreviewUrl(state: BillboardBuilderState, { payload }: PayloadAction<string>) {
      state.previewUrl = payload
    },
    setSuggest(state: BillboardBuilderState, { payload }: PayloadAction<Suggest>) {
      state.suggest = payload
      localStoreManagerService.saveSessionData('builderBillboardRetail.suggest', payload)
    },
    startLoadingBillboards(state: BillboardBuilderState) {
      state.billboards = []
      state.isLoadingBillboards = true
    },
    finishedLoadingBillboards(state: BillboardBuilderState, { payload }: PayloadAction<BillboardSuggestion[]>) {
      state.billboards = payload
      state.isLoadingBillboards = false
      localStoreManagerService.saveSessionData('builderBillboardRetail.billboards', payload)
    },
    setBackgroundImage(state: BillboardBuilderState, { payload }: PayloadAction<AcImage>) {
      state.backgroundImage = payload
      localStoreManagerService.saveSessionData('builderBillboardRetail.image', payload)
    },
    startLoadingImages(state: BillboardBuilderState) {
      state.isLoadingImages = true
    },
    finishedLoadingImages(state: BillboardBuilderState, { payload }: PayloadAction<AcImage[]>) {
      state.images = payload
      state.isLoadingImages = false
      localStoreManagerService.saveSessionData('builderBillboardRetail.images', payload)
    },
    startLoadingPreviewUrl(state: BillboardBuilderState) {
      state.isLoadingPreviewUrl = true
    },
    finishedLoadingPreviewUrl(state: BillboardBuilderState, { payload }: PayloadAction<string>) {
      state.previewUrl = payload
      state.isLoadingPreviewUrl = false
      localStoreManagerService.saveSessionData('builderBillboardRetail.images', payload)
    },
    setCreate(state: BillboardBuilderState, { payload }: PayloadAction<BillboardCustomization>) {
      state.create = payload
      localStoreManagerService.saveSessionData('builderBillboardRetail.create', payload)
    },
    setUploadedUrl(state: BillboardBuilderState, { payload }: PayloadAction<BillboardUploaded | null>) {
      state.uploadedBillboardUrl = payload
      localStoreManagerService.saveSessionData('builderBillboardRetail.uploadedBillboardUrl', payload)
    },
    setPhoto(state: BillboardBuilderState, { payload }: PayloadAction<AcImage | null>) {
      if (payload) {
        state.photo = payload
      } else {
        state.photo = null
      }
      localStoreManagerService.saveSessionData('builderBillboardRetail.photo', payload)
    },
    startCreating(state: BillboardBuilderState) {
      state.isCreating = true
    },
    finishedCreating(state: BillboardBuilderState, { payload }: PayloadAction<BuilderResult>) {
      state.result = payload
      state.isCreating = false
    },
    setNameAndBudget(state: BillboardBuilderState, { payload }: PayloadAction<{ name: string; budget: number; totalBudget: number }>) {
      state.name = payload.name
      state.budget = payload.budget
      state.totalBudget = payload.totalBudget
      localStoreManagerService.saveSessionData('builderBillboardRetail.name', payload.name)
      localStoreManagerService.saveSessionData('builderBillboardRetail.budget', payload.budget)
      localStoreManagerService.saveSessionData('builderBillboardRetail.totalBudget', payload.totalBudget)
    },
    setLoaded(state: BillboardBuilderState, { payload }: PayloadAction<boolean>) {
      state.loaded = payload
    }
  }
})

export const { setLoaded, startBuilder, resetBuilder, setBuilderStep, setBuilderStyle, setBusinessType, setSuggest, finishedLoadingBillboards, startLoadingBillboards, setPhoto, setBillboard, setPreviewUrl, finishedLoadingImages, setBackgroundImage, startLoadingPreviewUrl, finishedLoadingPreviewUrl, setCreate, setUploadedUrl, setTargeting, startCreating, finishedCreating, setNameAndBudget } = builderBillboardRetail.actions

export const fetchBillboards =
  (accountId: string, businessTypeId: string, companyName: string, city: string | null): AppThunk =>
  async dispatch => {
    dispatch(startLoadingBillboards())
    const response = await apiGetSuggestedBillboards(accountId, businessTypeId, companyName, city)
    dispatch(finishedLoadingBillboards(response.data))
  }

const getBillboardRetailBuilder = (state: RootState) => state.builderBillboardRetail

export const fetchPreviewUrl =
  (accountId: string): AppThunk =>
  async (dispatch, getState) => {
    const builder = getBillboardRetailBuilder(getState())
    const response = await apiGetBillboardPreviewUrl(accountId, builder.create! as BillboardCustomization)
    dispatch(finishedLoadingPreviewUrl(response.data))
  }

export const createBillboardPlanCampaign = (accountId: string, campaignName: string, campaignSlotId: string | null, saveForLater: boolean): AppThunk => {
  return createCampaign(accountId, campaignName, 0, 0, false, null, saveForLater, '', '', '', null, null, campaignSlotId)
}

export const createBillboardQuickCampaign = (accountId: string, campaignName: string, campaignSlotId: string | null, saveForLater: boolean, budget: number, isDays30?: boolean): AppThunk => {
  return createCampaign(accountId, campaignName, budget, 0, false, null, saveForLater, '', '', '', null, null, campaignSlotId, isDays30)
}

export const createCampaign =
  (accountId: string, campaignName: string, budget: number, maxCpm: number | null, isTotalBudget: boolean, paymentMethodId: string | null, saveForLater: boolean, name: string | null, phone: string | null, email: string | null, start: Moment | null, end: Moment | null, campaignSlotId: string | null, isDays30?: boolean): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startCreating())
    const isVendastaUser = getState().app.isVendastaUser
    const builder = getBillboardRetailBuilder(getState())

    const response = await apiPostBillboardBuilderRetail(accountId, {
      campaignName: campaignName,
      budget: budget,
      maxCpm: maxCpm,
      isTotalBudget: isTotalBudget,
      isDays30: isDays30 ?? false,
      businessTypeId: builder.businessType?.id,
      city: null,
      headline: {
        fontFace: builder.create?.headline.fontFace,
        fontSize: builder.create?.headline.fontSize,
        fontColor: builder.create?.headline.fontColor,
        sampleText: builder.create?.headline.sampleText,
        selected: builder.create?.headline.selected,
        x: builder.create?.headline.x,
        y: builder.create?.headline.y,
        width: builder.create?.headline.width,
        height: builder.create?.headline.height,
        align: builder.create?.headline.align
      },
      companyName: {
        fontFace: builder.create?.companyName.fontFace,
        fontSize: builder.create?.companyName.fontSize,
        fontColor: builder.create?.companyName.fontColor,
        sampleText: builder.create?.companyName.sampleText,
        selected: builder.create?.companyName.selected,
        x: builder.create?.companyName.x,
        y: builder.create?.companyName.y,
        width: builder.create?.companyName.width,
        height: builder.create?.companyName.height,
        align: builder.create?.companyName.align
      },
      phone: {
        fontFace: builder.create?.phone.fontFace,
        fontSize: builder.create?.phone.fontSize,
        fontColor: builder.create?.phone.fontColor,
        sampleText: builder.create?.phone.sampleText,
        selected: builder.create?.phone.selected,
        x: builder.create?.phone.x,
        y: builder.create?.phone.y,
        width: builder.create?.phone.width,
        height: builder.create?.phone.height,
        align: builder.create?.phone.align
      },
      location: {
        fontFace: builder.create?.location.fontFace,
        fontSize: builder.create?.location.fontSize,
        fontColor: builder.create?.location.fontColor,
        sampleText: builder.create?.location.sampleText,
        selected: builder.create?.location.selected,
        x: builder.create?.location.x,
        y: builder.create?.location.y,
        width: builder.create?.location.width,
        height: builder.create?.location.height,
        align: builder.create?.location.align
      },
      website: {
        fontFace: builder.create?.website.fontFace,
        fontSize: builder.create?.website.fontSize,
        fontColor: builder.create?.website.fontColor,
        sampleText: builder.create?.website.sampleText,
        selected: builder.create?.website.selected,
        x: builder.create?.website.x,
        y: builder.create?.website.y,
        width: builder.create?.website.width,
        height: builder.create?.website.height,
        align: builder.create?.website.align
      },
      uploadedUrl: builder.uploadedBillboardUrl?.uploadedUrl!,
      locationType: builder.targeting!.locationType,
      locations: {
        countries: [],
        regions: builder.targeting!.states,
        dmas: builder.targeting!.dmas,
        cities: builder.targeting!.cities,
        postalCodes: builder.targeting!.zips
      },
      states: [],
      dmas: [],
      cities: [],
      zips: [],
      previewUrl: builder.create?.previewUrl,
      backgroundUrl: builder.backgroundImage?.highQualityUrl,
      logo: {
        sampleUrl: builder.create?.logo?.sampleUrl,
        selected: builder.create?.logo?.selected,
        x: builder.create?.logo?.x,
        y: builder.create?.logo?.y,
        width: builder.create?.logo?.width,
        height: builder.create?.logo?.height
      },
      photo: {
        sampleUrl: builder.create?.photo?.sampleUrl,
        selected: builder.create?.photo?.selected,
        x: builder.create?.photo?.x,
        y: builder.create?.photo?.y,
        width: builder.create?.photo?.width,
        height: builder.create?.photo?.height
      },
      paymentMethodId: paymentMethodId,
      setupIntentId: null,
      saveForLater: saveForLater,
      hasCreative: builder.builderStyle !== BuilderStyle.Custom,
      contactName: name,
      contactEmail: email,
      contactPhone: phone,
      startDate: start,
      endDate: end,
      campaignSlotId: campaignSlotId,
      isStrawberryFieldUser: isVendastaUser
    })
    dispatch(finishedCreating(response.data))
    dispatch(fetchCampaigns(accountId))
    dispatch(dashboardApi.util.invalidateTags([{ type: 'DashboardFilter', id: accountId }]))
  }

export default builderBillboardRetail.reducer
