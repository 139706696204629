import React, { FC, useEffect } from 'react'

import { EuiComboBox } from '@elastic/eui'
import { EuiComboBoxOptionOption } from '@elastic/eui/src/components/combo_box/types'

import { Account, SelfServicePackage } from 'api/interfaces'
import { useCreateAccountMutation, useGetAccountsQuery } from 'api/rtkQueryApi/platform/accountsApi'

export interface AccountPickerProps {
  selfServicePackage: SelfServicePackage
  selectedAccount: Account | undefined
  setSelectedAccount: (account?: Account) => void
  disabled?: boolean
  fullWidth?: boolean
}

const AccountPicker: FC<AccountPickerProps> = ({ selfServicePackage, selectedAccount, setSelectedAccount, disabled, fullWidth }) => {
  const { isFetching: isAccountsFetching, data: accounts } = useGetAccountsQuery({})
  const [createAccount, createAccountRequest] = useCreateAccountMutation()

  useEffect(() => {
    if (accounts) {
      if (selfServicePackage.accountId) {
        setSelectedAccount(accounts.find(a => a.id === selfServicePackage.accountId))
      }
    }
  }, [accounts])

  useEffect(() => {
    if (createAccountRequest.data) {
      setSelectedAccount(createAccountRequest.data)
    }
  }, [createAccountRequest.data])

  const onAccountSelected = (options: EuiComboBoxOptionOption<string>[]) => {
    setSelectedAccount(accounts?.find(a => a.id === options[0]?.value))
  }

  const onCreateOption = (value: string) => {
    setSelectedAccount(undefined)

    createAccount({ name: value })
  }

  const options = accounts?.map(a => ({ label: a.name, value: a.id })) ?? []
  const selectedOptions = selectedAccount ? [{ label: selectedAccount.name, value: selectedAccount.id }] : []
  const isComboBoxLoading = isAccountsFetching || createAccountRequest.isLoading

  return <EuiComboBox options={options} singleSelection={{ asPlainText: true }} selectedOptions={selectedOptions} onChange={onAccountSelected} isLoading={isComboBoxLoading} onCreateOption={onCreateOption} placeholder='Start typing...' isDisabled={disabled} fullWidth={fullWidth} />
}

export default AccountPicker
