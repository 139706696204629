import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { EuiTitle, EuiPageHeader, EuiFlexItem, EuiFlexGroup, EuiLoadingContent, EuiSpacer, EuiInMemoryTable, EuiFlexGrid } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { apiGetOrganizationInvoices } from 'api/adcritterApi'
import { Invoice, InvoiceLine } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

interface InvoicePageParams {
  invoiceHandle: string
}

const InvoicePage: React.FC = () => {
  let { invoiceHandle } = useParams<InvoicePageParams>()
  const { currentOrganization, currentAccount } = useSelector((state: RootState) => state.app)
  const whiteLabel = useWhiteLabel()
  const [invoice, setInvoice] = useState<Invoice>()
  const [invoicesLoaded, setInvoicesLoaded] = useState<boolean>(false)
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const columns: Array<EuiBasicTableColumn<InvoiceLine>> = [
    {
      name: 'Title',
      render: (il: InvoiceLine) => il.title
    },
    {
      name: `Description`,
      render: (il: InvoiceLine) => il.description
    },
    {
      name: `Amount`,
      render: (il: InvoiceLine) => formatter.format(il.subTotalAmount),
      align: 'right'
    }
  ]

  useEffect(() => {
    if (currentAccount?.id) {
      apiGetOrganizationInvoices(currentAccount.id).then(result => {
        let currentInvoice = result.data.find(x => x.handle == invoiceHandle)
        setInvoice(currentInvoice)
        setInvoicesLoaded(true)
      })
    }
  }, [currentAccount])
  const css = `
        .table-color div .euiTable {
            background-color: inherit;
        }
        `
  return (
    <EuiFlexGroup>
      <style>{css}</style>
      <EuiFlexItem style={{ backgroundColor: '#f8fafd' }}>
        <EuiPageHeader>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              padding: '10px'
            }}>
            <EuiTitle>
              <h1>Invoice</h1>
            </EuiTitle>
            <img src={whiteLabel?.getBlackTextLogo()} alt={whiteLabel?.getName()} style={{ width: 220 }} />
          </div>
        </EuiPageHeader>

        <div style={{ minWidth: '250px', borderBottom: '1px solid #D3DAE6', height: '1px' }} />
        <EuiSpacer />
        {!invoicesLoaded ? (
          <div style={{ marginTop: '30px' }}>
            <EuiLoadingContent lines={3} />
          </div>
        ) : (
          <EuiFlexGroup direction={'column'} style={{ margin: '10px' }}>
            <EuiFlexItem grow={false}>
              <EuiFlexGrid columns={2}>
                <EuiFlexItem>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div>
                      <b style={{ paddingRight: '5px' }}>Invoice Date</b> {moment.utc(invoice?.issueDate).format('MMM Do YYYY')}
                    </div>
                  </div>
                </EuiFlexItem>
                <EuiFlexItem style={{ minWidth: '400px' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div>
                      <b style={{ paddingRight: '5px' }}>Invoice Number</b> {invoice?.handle}
                    </div>
                  </div>
                </EuiFlexItem>
                <EuiFlexItem style={{ minWidth: '400px' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div>
                      <b style={{ paddingRight: '5px' }}>Prepared For: </b>
                      {currentOrganization?.name}
                    </div>
                  </div>
                </EuiFlexItem>
              </EuiFlexGrid>
              <EuiSpacer size={'xl'} />

              <EuiInMemoryTable className={'table-color'} items={invoice?.lines ?? []} columns={columns} />

              <EuiSpacer />
              <EuiFlexGroup>
                <EuiFlexItem style={{ minWidth: '200px' }}>
                  {invoice?.paidDate !== null && (
                    <EuiFlexGroup alignItems={'flexEnd'}>
                      <EuiFlexItem>
                        <span
                          style={{
                            transform: 'rotate(12deg)',
                            color: '#357960',
                            fontSize: '3rem',
                            fontWeight: '700',
                            border: '0.25rem solid #357960',
                            display: 'inline-block',
                            padding: '0.25rem 1rem',
                            textTransform: 'uppercase',
                            borderRadius: '1rem',
                            fontFamily: 'Courier',
                            mixBlendMode: 'multiply',
                            width: 'fit-content'
                          }}>
                          Paid
                        </span>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  )}
                </EuiFlexItem>
                <EuiFlexItem>
                  <div>
                    <EuiFlexGroup justifyContent='flexEnd'>
                      <EuiFlexItem grow={false}>Total</EuiFlexItem>
                      <EuiFlexItem style={{ minWidth: '90px', textAlign: 'end' }} grow={false}>
                        {formatter.format(invoice?.totalAmount ?? 0)}
                      </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup justifyContent='flexEnd'>
                      <EuiFlexItem grow={false}>{invoice?.paidDate && moment.utc(invoice?.paidDate).format('MMM Do')} Payment</EuiFlexItem>
                      <EuiFlexItem style={{ minWidth: '90px', textAlign: 'end' }} grow={false}>
                        {formatter.format(invoice?.paidAmount ?? 0)}
                      </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup justifyContent='flexEnd'>
                      <EuiFlexItem style={{ minWidth: '250px', textAlign: 'end', borderBottom: '1px solid #D3DAE6' }} grow={false} />
                    </EuiFlexGroup>
                    <EuiFlexGroup justifyContent='flexEnd'>
                      <EuiFlexItem grow={false} style={{ fontWeight: 'bold' }}>
                        Balance Due
                      </EuiFlexItem>
                      <EuiFlexItem style={{ minWidth: '90px', textAlign: 'end' }} grow={false}>
                        {formatter.format(invoice?.dueAmount ?? 0)}
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </div>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default InvoicePage
