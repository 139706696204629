import { BlobServiceClient, BlockBlobClient } from '@azure/storage-blob'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { VideoDetails } from 'workflows/CampaignCreateWorkflow'

import { EuiButton, EuiCallOut, EuiFilePicker, EuiFlexGroup, EuiFlexItem, EuiHideFor, EuiShowFor, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { apiGetTvBuilderUpload } from 'api/adcritterApi'
import { RootState } from 'app/rootReducer'
import { ICampaignBuilderWorkflowStep } from 'features/builders/createCampaign/creativeBuilder/CreativeBuilderWorkflowManager'
import { useCampaignBuilderActions } from 'features/builders/createCampaign/useCampaignBuilderActions'

export const AdUploadTvPage: React.FC<ICampaignBuilderWorkflowStep> = props => {
  const emptyVideoDetails: VideoDetails = {
    baseVideoId: '',
    creativeId: '',
    kind: '',
    name: '',
    previewUrl: '',
    quality: '',
    script: '',
    sections: [],
    thumbnailUrl: '',
    type: '',
    uploadedUrl: ''
  }

  const { createTvComplete } = useCampaignBuilderActions()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [destination, setDestination] = useState<string | null>(null)
  const [blobClient, setBlobClient] = useState<BlockBlobClient | null>(null)
  const [isUploading, setIsUploading] = useState(false)
  const [hasFile, setHasFile] = useState(false)
  const [adDetails, setAdDetails] = useState<VideoDetails>(emptyVideoDetails)

  useEffect(() => {
    if (currentAccount) {
      apiGetTvBuilderUpload(currentAccount.id).then(data => setDestination(data.data))
    }
  }, [currentAccount])

  useEffect(() => {
    if (destination && currentAccount) {
      const storageClient = new BlobServiceClient(destination)
      const containerClient = storageClient.getContainerClient(currentAccount.id)
      const blockBlobClient = containerClient.getBlockBlobClient(uuidv4() + '.mp4')
      setBlobClient(blockBlobClient)
    }
  }, [currentAccount, destination])

  const onFileChange = (files: FileList | null) => {
    if (files && files.length === 1) {
      setIsUploading(true)
      let url = blobClient!.url.split('?')[0]
      blobClient!
        .uploadData(files[0], {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20
        })
        .then(() => {
          setIsUploading(false)
          setAdDetails({
            baseVideoId: '',
            creativeId: '',
            kind: '',
            name: files[0].name.split('.')[0],
            previewUrl: '',
            quality: '',
            script: '',
            sections: [],
            thumbnailUrl: '',
            type: '',
            uploadedUrl: url
          })
        })
        .then(() => {
          setHasFile(true)
        })
    }
    if (files && files.length === 0) {
      setAdDetails(emptyVideoDetails)
      setHasFile(false)
    }
  }

  const gotoTargeting = () => {
    createTvComplete(adDetails)
  }

  const onBackClick = () => {
    props.onBack?.()
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Upload Commercial</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Upload Commercial</h3>
      </EuiTitle>
      <EuiSpacer size='m' />
      <EuiFlexGroup>
        <EuiFlexItem style={{ marginLeft: 25 }}>
          <EuiCallOut title='Start by uploading your commercial, making sure it meets all requirements.' color='primary' iconType='importAction'>
            <EuiText size='s'>
              <p>Upload a 15 or 30 second commercial.</p>
            </EuiText>
          </EuiCallOut>
          <EuiSpacer size='xl' />

          <EuiFilePicker id='upload' fullWidth display='large' multiple={false} initialPromptText='Select or drag and drop file here' onChange={onFileChange} isLoading={isUploading} />
          <EuiSpacer />
          <EuiCallOut title='Requirements: ' color='primary' iconType='importAction'>
            <EuiSpacer />
            <EuiText size='s'>
              <p style={{ marginLeft: 30, marginTop: -20 }}>
                <strong>Time:</strong> 15 or 30 seconds. (Exactly.)
                <br />
                <strong>FPS:</strong> 29.97
                <br />
                <strong>Aspect Ratio:</strong> 16:9
                <br />
                <strong>Resolution:</strong> 720p pr 1080p
                <br />
                <strong>Audio:</strong> AAC
                <br />
                <strong>Bitrate:</strong> 1,000kps or higher
              </p>
            </EuiText>
          </EuiCallOut>

          <EuiSpacer size='xl' />
        </EuiFlexItem>

        <EuiFlexItem style={{ paddingLeft: 50, paddingRight: 50 }}>
          <EuiText size='s'>
            <h4>Preview Your Commercial</h4>
          </EuiText>
          <EuiSpacer size='s' />
          <EuiFlexItem>
            {adDetails?.uploadedUrl && (
              <video id='uploadedVideo' controls width='100%'>
                <source src={adDetails.uploadedUrl} type='video/mp4' />
              </video>
            )}
            {!adDetails?.uploadedUrl && (
              <video width='100%' controls>
                <source src={''} type='video/mp4' />
              </video>
            )}
          </EuiFlexItem>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiShowFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='continue' fill type='submit' isDisabled={!hasFile} onClick={gotoTargeting}>
              Continue to Targeting
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={onBackClick}>
              Back
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={onBackClick} iconType='arrowLeft' iconSide='left'>
              Back
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='continue' fill type='submit' isDisabled={!hasFile} iconType='arrowRight' iconSide='right' onClick={gotoTargeting}>
              Continue to Targeting
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiHideFor>
    </React.Fragment>
  )
}
