import { useSelector } from 'react-redux'

import { useGetOrganizationQuery } from 'api/rtkQueryApi/platform/organizationsApi'
import { RootState } from 'app/rootReducer'

export const useIsAgency = () => {
  const { isLoggedIn } = useSelector((state: RootState) => state.app)
  const organizationQuery = useGetOrganizationQuery(undefined, { skip: !isLoggedIn })
  return !!organizationQuery.data?.isAgency
}
