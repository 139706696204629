import { FieldArray, FormikProps, getIn } from 'formik'
import React from 'react'
import { useSelector } from 'react-redux'

import { EuiButtonEmpty, EuiButtonIcon, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSelect, EuiSpacer, EuiToolTip } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'

import { Address, StandardTargeting } from 'api/interfaces'
import { RootState } from 'app/rootReducer'

export interface CompetitorsProps {
  formik: FormikProps<StandardTargeting>
}

const Competitors = ({ formik }: CompetitorsProps) => {
  const dictionary = useSelector((state: RootState) => state.dictionary)

  return (
    <FieldArray
      name='competitors'
      render={arrayHelpers => (
        <React.Fragment>
          {formik.values.competitors &&
            formik.values.competitors.length > 0 &&
            formik.values.competitors.map((competitor, index) => (
              <EuiFlexGroup gutterSize='xs' key={index} style={{ marginLeft: -30, marginTop: 5, marginBottom: 5 }}>
                <EuiFlexItem grow={false}>
                  <EuiFlexGroup alignItems='center'>
                    <EuiFlexItem grow={false}>
                      <EuiToolTip position='top' content='Remove this Competitor'>
                        <EuiButtonIcon
                          id='removeCompetitor'
                          iconType='cross'
                          aria-label='remove'
                          color='warning'
                          onClick={() => {
                            arrayHelpers.remove(index)
                          }}
                        />
                      </EuiToolTip>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label={index === 0 ? 'Competitor Name' : ''} fullWidth display='rowCompressed' isInvalid={getIn(formik.touched, `competitors[${index}].name`) && !!getIn(formik.errors, `competitors[${index}].name`)} error={getIn(formik.errors, `competitors[${index}].name`)}>
                    <EuiFieldText fullWidth name={`competitors[${index}].name`} value={getIn(formik.values, `competitors[${index}].name`)} onChange={formik.handleChange} isInvalid={getIn(formik.touched, `competitors[${index}].name`) && !!getIn(formik.errors, `competitors[${index}].name`)} />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label={index === 0 ? 'Street Address' : ''} fullWidth display='rowCompressed' isInvalid={getIn(formik.touched, `competitors[${index}].streetAddress`) && !!getIn(formik.errors, `competitors[${index}].streetAddress`)} error={getIn(formik.errors, `competitors[${index}].streetAddress`)}>
                    <EuiFieldText fullWidth name={`competitors[${index}].streetAddress`} value={getIn(formik.values, `competitors[${index}].streetAddress`)} onChange={formik.handleChange} isInvalid={getIn(formik.touched, `competitors[${index}].streetAddress`) && !!getIn(formik.errors, `competitors[${index}].streetAddress`)} />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label={index === 0 ? 'City' : ''} fullWidth display='rowCompressed' isInvalid={getIn(formik.touched, `competitors[${index}].city`) && !!getIn(formik.errors, `competitors[${index}].city`)} error={getIn(formik.errors, `competitors[${index}].city`)}>
                    <EuiFieldText fullWidth name={`competitors[${index}].city`} value={getIn(formik.values, `competitors[${index}].city`)} onChange={formik.handleChange} isInvalid={getIn(formik.touched, `competitors[${index}].city`) && !!getIn(formik.errors, `competitors[${index}].city`)} />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label={index === 0 ? 'State' : ''} display='rowCompressed' fullWidth isInvalid={getIn(formik.touched, `competitors[${index}].state`) && !!getIn(formik.errors, `competitors[${index}].state`)} error={getIn(formik.errors, `competitors[${index}].state`)}>
                    <EuiSelect
                      name={`competitors[${index}].state`}
                      isLoading={dictionary.isLoadingStates}
                      options={dictionary.states.map(
                        s =>
                          ({
                            value: s.code,
                            label: s.name,
                            text: s.name
                          } as EuiSelectOption)
                      )}
                      value={getIn(formik.values, `competitors[${index}].state`)}
                      onChange={formik.handleChange}
                      fullWidth
                      isInvalid={getIn(formik.touched, `competitors[${index}].state`) && !!getIn(formik.errors, `competitors[${index}].state`)}
                      hasNoInitialSelection={true}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label={index === 0 ? 'Zip Code' : ''} fullWidth display='rowCompressed' isInvalid={getIn(formik.touched, `competitors[${index}].zip`) && !!getIn(formik.errors, `competitors[${index}].zip`)} error={getIn(formik.errors, `competitors[${index}].zip`)}>
                    <EuiFieldText fullWidth name={`competitors[${index}].zip`} value={getIn(formik.values, `competitors[${index}].zip`)} onChange={formik.handleChange} isInvalid={getIn(formik.touched, `competitors[${index}].zip`) && !!getIn(formik.errors, `competitors[${index}].zip`)} />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            ))}
          <EuiSpacer size='s' />
          <EuiButtonEmpty
            id='addCompetitor'
            size='s'
            onClick={() => {
              arrayHelpers.push({
                name: '',
                streetAddress: '',
                city: '',
                state: '',
                zip: '',
                country: 'usa'
              } as Address)
            }}
            iconType='listAdd'>
            Add a Competitor Location
          </EuiButtonEmpty>
        </React.Fragment>
      )}
    />
  )
}

export default Competitors
