import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiFieldSearch, EuiInputPopover, EuiSelectable, EuiText, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { RegionTarget } from 'api/interfaces'
import { clearRegionTargets, fetchRegionTargets, filterRegionTargets } from 'app/dictionarySlice'
import { RootState } from 'app/rootReducer'
import useDebounce from 'utils/useDebounce'

interface NewRegionFinderProps {
  isInvalid: boolean

  addRegion(region: RegionTarget): void
}

export const NewRegionFinder = ({ addRegion, isInvalid }: NewRegionFinderProps) => {
  const dispatch = useDispatch()
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const [regionSearchTerm, setRegionSearchTerm] = useState<string>('')
  const debouncedRegionSearchTerm = useDebounce(regionSearchTerm, 100)
  const [searchRegionPopoverOpen, setSearchRegionPopoverOpen] = useState<boolean>(false)
  const [regions, setRegions] = useState<Array<EuiSelectableOption>>([])

  const onSearchRegion = () => {
    setSearchRegionPopoverOpen(false)
  }

  const onSearchRegionChange = (hint: string) => {
    setRegionSearchTerm(hint)
  }

  useEffect(() => {
    dispatch(fetchRegionTargets())
  }, [])

  useEffect(() => {
    if (debouncedRegionSearchTerm) {
      dispatch(filterRegionTargets(debouncedRegionSearchTerm))
    } else {
      dispatch(clearRegionTargets())
    }
  }, [debouncedRegionSearchTerm, dispatch])

  useEffect(() => {
    if (dictionary.regionTargetsFiltered) {
      setRegions(
        dictionary.regionTargetsFiltered.map(
          s =>
            ({
              label: s.name,
              key: s.code,
              checked: undefined,
              onClick: () => {
                addRegion(s)
                setSearchRegionPopoverOpen(false)
                setRegionSearchTerm('')
              }
            } as EuiSelectableOption)
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dictionary.regionTargetsFiltered, addRegion])

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          value={regionSearchTerm}
          isLoading={dictionary.isLoadingRegionTargets}
          onChange={v => onSearchRegionChange(v.target.value)}
          onFocus={() => setSearchRegionPopoverOpen(true)}
          incremental={false}
          onSearch={() => onSearchRegion()}
          placeholder='Type to begin search...'
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
          isInvalid={isInvalid}
        />
      }
      isOpen={searchRegionPopoverOpen}
      closePopover={() => setSearchRegionPopoverOpen(false)}>
      {dictionary.regionTargetsFiltered && dictionary.regionTargetsFiltered.length ? (
        <EuiSelectable singleSelection options={regions}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search... results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
