import { isNaN } from 'formik'
import React, { FC, useState } from 'react'

import { EuiButton, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiFormRow, EuiHorizontalRule, EuiLoadingContent, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { BundleStatus, SelfServicePackage, SelfServicePackageType } from 'api/interfaces'
import { PaymentMethodPicker, usePaymentMethodPickerState } from 'components/payments/PaymentMethodPicker'
import AccountPicker from 'features/selfServicePackages/components/AccountPicker'
import SelfServicePackageDetailsSlotRow from 'features/selfServicePackages/components/SelfServicePackageDetailsSlotRow'
import { useCampaignSlotsState } from 'features/selfServicePackages/hooks/useCampaignSlotsState'
import { useSelfServicePackageDetailsFlyout } from 'features/selfServicePackages/hooks/useEffectsSelfServicePackageDetailsFlyout'

export interface SelfServicePackageDetailsFlyoutProps {
  selfServicePackage: SelfServicePackage
  closeFlyout: () => void
}

const SelfServicePackageDetailsFlyout: FC<SelfServicePackageDetailsFlyoutProps> = ({ selfServicePackage, closeFlyout }) => {
  const formatter = new Intl.NumberFormat('en-US')
  const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })

  const [packageName, setPackageName] = useState<string>(selfServicePackage.name)
  const [showPackageBudgetError, setShowPackageBudgetError] = useState(false)

  const {
    accounts,
    saveBundleMutation: [saveBundle, saveBundleRequest],
    saveForLaterBundleMutation: [saveForLaterBundle, saveForLaterBundleRequest],
    activateBundleMutation: [activateBundle, activateBundleRequest],
    selectedAccountState: [selectedAccount, setSelectedAccount]
  } = useSelfServicePackageDetailsFlyout(selfServicePackage, closeFlyout)

  const {
    packageBudgetState: [packageBudget, setPackageBudget],
    paidCampaignSlots: paidCampaignSlots,
    matchingCampaignSlots: matchingCampaignSlots,
    updateCampaignSlot
  } = useCampaignSlotsState(selfServicePackage)

  const [{ selectedPaymentMethodId }, setPaymentMethodPickerState] = usePaymentMethodPickerState()

  const onPackageBudgetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 0) {
      setPackageBudget(undefined)
      return
    }

    const parsed = parseFloat(e.target.value.replaceAll(',', ''))

    if (isNaN(parsed)) {
      setPackageBudget(packageBudget)
      return
    }

    setPackageBudget(parsed)
  }

  const onLaunchClick = async () => {
    if (!packageBudget) {
      return
    }

    await activateBundle({
      accountId: selectedAccount?.id,
      bundleId: selfServicePackage.id,
      name: packageName,
      price: packageBudget,
      paymentMethodId: selectedPaymentMethodId,
      campaignSlots: allSlots.map(slot => {
        return {
          campaignType: slot.campaignType,
          budget: slot.budget,
          isBonus: slot.isBonus,
          assignedCampaignId: slot.campaignId
        }
      })
    })
  }

  const onSaveClick = () => {
    if (bundleStatus === BundleStatus.New) {
      saveForLaterBundle({
        accountId: selectedAccount?.id,
        bundleId: selfServicePackage.id,
        name: packageName,
        price: packageBudget ?? 0,
        paymentMethodId: selectedPaymentMethodId,
        campaignSlots: allSlots.map(slot => {
          return {
            campaignType: slot.campaignType,
            budget: slot.budget,
            isBonus: slot.isBonus,
            assignedCampaignId: slot.campaignId
          }
        })
      })
    } else {
      saveBundle({
        accountId: selfServicePackage.accountId,
        bundleId: selfServicePackage.id,
        name: packageName,
        assignCampaignSlots: allSlots.map(slot => {
          return {
            campaignSlotId: slot.id,
            campaignId: slot.campaignId
          }
        })
      })
    }
  }

  const allSlots = paidCampaignSlots.concat(matchingCampaignSlots)

  const bundleStatus = selfServicePackage.bundleStatus
  const matchingPercentage = selfServicePackage.matchingPercentage ?? 1
  const minimumAmount = selfServicePackage.minimumAmount ?? 0
  const isPackageBudgetInvalid = selfServicePackage.selfServicePackageType !== SelfServicePackageType.Specific && !!packageBudget && packageBudget < minimumAmount
  const packageBudgetInvalidMessage = `The minimum budget for this package is ${currencyFormatter.format(minimumAmount)}`

  const assignClientDisabled = bundleStatus !== BundleStatus.New
  const setPackageBudgetDisabled = selfServicePackage.selfServicePackageType === SelfServicePackageType.Specific || bundleStatus !== BundleStatus.New

  const launchDisabled = activateBundleRequest.isLoading || saveBundleRequest.isLoading || saveForLaterBundleRequest.isLoading || !packageBudget || !selectedAccount || !selectedPaymentMethodId
  const saveDisabled = activateBundleRequest.isLoading || saveBundleRequest.isLoading || saveForLaterBundleRequest.isLoading || !selectedAccount

  if (!accounts) {
    return <EuiLoadingContent lines={6} />
  }

  return (
    <EuiFlyout size={'100%'} maxWidth={700} onClose={closeFlyout}>
      <EuiFlyoutHeader>
        <EuiTitle>
          <h2>{selfServicePackage.salesTitle}</h2>
        </EuiTitle>

        <EuiSpacer size={'s'} />

        {selfServicePackage.salesDescription &&
          selfServicePackage.salesDescription.split('\n').map((item, i) => {
            return (
              <React.Fragment key={i}>
                {i > 0 && <EuiSpacer size={'xs'} />}
                {item.length > 0 && <EuiText size={'s'}>{item}</EuiText>}
              </React.Fragment>
            )
          })}
      </EuiFlyoutHeader>

      <EuiFlyoutBody>
        <EuiFormRow fullWidth>
          <EuiFlexGroup alignItems='center' gutterSize='m'>
            <EuiFlexItem grow={3}>
              <EuiTitle size='xs'>
                <h4>Assigned Client:</h4>
              </EuiTitle>
            </EuiFlexItem>

            <EuiFlexItem grow={6}>
              <AccountPicker selfServicePackage={selfServicePackage} selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} disabled={assignClientDisabled} fullWidth />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>

        <EuiFormRow fullWidth>
          <EuiFlexGroup alignItems='center' gutterSize='m'>
            <EuiFlexItem grow={3}>
              <EuiTitle size='xs'>
                <h4>Package Name:</h4>
              </EuiTitle>
            </EuiFlexItem>

            <EuiFlexItem grow={6}>
              <EuiFieldText value={packageName} placeholder={'Package Name'} onChange={e => setPackageName(e.target.value)} isInvalid={!packageName} fullWidth />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>

        {allSlots.map((slot, index) => {
          const bundleSlot = selfServicePackage.campaignSlots.find(s => s.id === slot.id)
          const disable = !selectedAccount || (bundleStatus !== BundleStatus.New && !!bundleSlot?.campaignId)

          return (
            <EuiFormRow fullWidth>
              <SelfServicePackageDetailsSlotRow index={index} accountId={selectedAccount?.id} campaignSlot={slot} campaignSlots={allSlots} updateCampaignSlot={updateCampaignSlot} disabled={disable} />
            </EuiFormRow>
          )
        })}

        <EuiHorizontalRule margin='l' />

        {/* 1: probably its own component */}
        <EuiFormRow isInvalid={isPackageBudgetInvalid && showPackageBudgetError} error={packageBudgetInvalidMessage} fullWidth>
          <EuiFlexGroup alignItems='center' gutterSize='m'>
            <EuiFlexItem grow={3}>
              <EuiTitle size='xs'>
                <h4>Set Package Budget:</h4>
              </EuiTitle>
            </EuiFlexItem>

            <EuiFlexItem grow={6}>
              <EuiFieldText prepend='$' append={`/ ${selfServicePackage.billingSchedule.intervalUnit.toLowerCase()}`} value={packageBudget ? formatter.format(packageBudget) : ''} onChange={onPackageBudgetChange} isInvalid={isPackageBudgetInvalid} onFocus={() => setShowPackageBudgetError(false)} onBlur={() => setShowPackageBudgetError(true)} disabled={setPackageBudgetDisabled} fullWidth />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>

        <EuiSpacer size='xl' />

        <EuiFormRow fullWidth>
          <EuiText size='m' style={{ marginLeft: '.5em' }}>
            <i>
              <b>{selfServicePackage.bundleStatus === BundleStatus.New || selfServicePackage.bundleStatus === BundleStatus.PaymentFailed ? 'This package will include:' : 'This package includes:'}</b>
            </i>
          </EuiText>
        </EuiFormRow>

        {/* 2: probably its own component */}
        {allSlots.map(slot => {
          let isBonus = slot.isBonus
          let budget: number | undefined

          if (selfServicePackage.selfServicePackageType === SelfServicePackageType.Specific) {
            budget = slot.budget
          } else if (packageBudget && packageBudget >= minimumAmount) {
            if (isBonus && matchingCampaignSlots.length > 0) {
              budget = (packageBudget * matchingPercentage) / matchingCampaignSlots.length
            } else if (!isBonus && paidCampaignSlots.length > 0) {
              budget = packageBudget / paidCampaignSlots.length
            }
          }

          return (
            <EuiFormRow fullWidth>
              <EuiFlexGroup alignItems='center' gutterSize='m'>
                <EuiFlexItem grow={true}>
                  <EuiText size='m'>
                    <span style={{ marginLeft: '1.5em' }}>
                      {isBonus ? 'Free Incentive' : 'Paid'} Campaign ({slot.campaignType}):
                    </span>
                  </EuiText>
                </EuiFlexItem>

                <EuiFlexItem grow={true}>
                  <EuiFlexGroup justifyContent='spaceAround'>
                    <EuiFlexItem grow={false}>+</EuiFlexItem>

                    <EuiFlexItem grow={true}>
                      <EuiText size={'s'}>{!isPackageBudgetInvalid && budget ? currencyFormatter.format(budget) : 'N/A'}</EuiText>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFormRow>
          )
        })}
        {/* 2: probably its own component */}

        <EuiFormRow fullWidth>
          <EuiFlexGroup alignItems='flexStart' gutterSize='m'>
            <EuiFlexItem grow={5} />

            <EuiFlexItem grow={4}>
              <EuiHorizontalRule margin='xs' size='half' />
            </EuiFlexItem>

            <EuiFlexItem grow={3} />
          </EuiFlexGroup>
        </EuiFormRow>

        <EuiFormRow fullWidth>
          <EuiFlexGroup alignItems='center' gutterSize='m'>
            <EuiFlexItem grow={true}>
              <EuiTitle size='xs'>
                <h4 style={{ marginLeft: '1.5em' }}>Total Media Cost:</h4>
              </EuiTitle>
            </EuiFlexItem>

            <EuiFlexItem grow={true}>
              <EuiFlexGroup justifyContent='spaceAround'>
                <EuiFlexItem grow={false}>{'\u00A0\u00A0'}</EuiFlexItem>

                <EuiFlexItem grow={true}>
                  <EuiText size={'s'}>
                    <b>{!isPackageBudgetInvalid && packageBudget ? currencyFormatter.format(packageBudget + packageBudget * matchingPercentage) : 'N/A'}</b>
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>

        <EuiFormRow fullWidth>
          <EuiFlexGroup alignItems='center' gutterSize='m'>
            <EuiFlexItem grow={true}>
              <EuiTitle size='xs'>
                <h4 style={{ marginLeft: '1.5em' }}>Total Cost:</h4>
              </EuiTitle>
            </EuiFlexItem>

            <EuiFlexItem grow={true}>
              <EuiFlexGroup justifyContent='spaceAround'>
                <EuiFlexItem grow={false}>{'\u00A0\u00A0'}</EuiFlexItem>

                <EuiFlexItem grow={true}>
                  <EuiText size={'s'}>
                    <b>{!isPackageBudgetInvalid && packageBudget ? currencyFormatter.format(packageBudget) : 'N/A'}</b>
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>
        {/* 1: probably its own component */}

        {selectedAccount && (bundleStatus === BundleStatus.New || bundleStatus === BundleStatus.PaymentFailed) && (
          <>
            <EuiHorizontalRule margin='l' />

            <EuiFormRow fullWidth>
              <PaymentMethodPicker disableBundlePayment account={selectedAccount} paymentMethodPickerState={{ selectedPaymentMethodId, selectedCampaignSlotId: '' }} updatePaymentMethodPickerState={setPaymentMethodPickerState} buttonSize='s' />
            </EuiFormRow>
          </>
        )}
      </EuiFlyoutBody>

      <EuiFlyoutFooter>
        <EuiFormRow fullWidth>
          <EuiFlexGroup gutterSize='l'>
            {(bundleStatus === BundleStatus.New || bundleStatus === BundleStatus.PaymentFailed) && (
              <EuiFlexItem grow={false}>
                <EuiButton id='launch' fill isLoading={activateBundleRequest.isLoading} onClick={onLaunchClick} disabled={launchDisabled}>
                  Launch
                </EuiButton>
              </EuiFlexItem>
            )}
            <EuiFlexItem grow={false}>
              <EuiButton id='save' isLoading={saveBundleRequest.isLoading} onClick={onSaveClick} disabled={saveDisabled}>
                Save
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>
      </EuiFlyoutFooter>
    </EuiFlyout>
  )
}

export default SelfServicePackageDetailsFlyout
