import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { BuilderCampaignType } from 'workflows/CampaignCreateWorkflow'

import { EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiRadio, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { CampaignType, FrequencyUnit, PermissionType, ThirdPartyAudience } from 'api/interfaces'
import { AdPlacementType, CampaignDelivery } from 'api/interfaces/CampaignDelivery'
import { AudienceTargeting, LocationTargeting, LocationTargetingType, TargetingType } from 'api/interfaces/CampaignTargeting'
import { RootState } from 'app/rootReducer'
import { AcFileUploader } from 'components/Basic/AcFileUploader'
import { NewSegmentFinder } from 'components/finders/NewSegmentFinder'
import { BackNextFooter } from 'components/navigation/BackNextFooter'
import { AudienceTargeterDetailed } from 'components/targeters/AudienceTargeterDetailed'
import { DefaultDayParts } from 'components/targeters/DayPartPicker'
import { DeliveryTargeter } from 'components/targeters/DeliveryTargeter'
import { DemographicPrioritizationTargeter } from 'components/targeters/DemographicPrioritizationTargeter'
import { LocationRestrictionTargeter } from 'components/targeters/LocationRestrictionTargeter'
import { ICampaignBuilderWorkflowStep } from 'features/builders/createCampaign/creativeBuilder/CreativeBuilderWorkflowManager'
import { useCampaignBuilderActions } from 'features/builders/createCampaign/useCampaignBuilderActions'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

export const TargetAudiencePage: React.FC<ICampaignBuilderWorkflowStep> = props => {
  const {
    campaignTargeting: { targetingType }
  } = useSelector((state: RootState) => state.campaignCreateWorkflow)

  const { targetAudienceComplete } = useCampaignBuilderActions()
  const [isInvalidUpload, setIsInvalidUpload] = useState(false)
  const [targetingDetails, setTargetingDetails] = useState<LocationTargeting>({
    targetingType: LocationTargetingType.Region,
    regions: [],
    dmas: [],
    cities: [],
    zips: [],
    addresses: []
  })
  const [audienceDetails, setAudienceDetails] = useState<AudienceTargeting>({
    demographics: {
      ageRanges: [],
      genders: [],
      householdIncomes: []
    },
    segments: []
  })
  const [deliveryDetails, setDeliveryDetails] = useState<CampaignDelivery>({
    dayParts: DefaultDayParts,
    frequency: 12,
    frequencyUnit: FrequencyUnit.week,
    inventorySettings: { targetApp: false, targetWeb: true },
    deviceSettings: { targetPc: true, targetPhone: true, targetTablet: true },
    placement: AdPlacementType.Everywhere,
    categories: [],
    specific: []
  })

  const {
    campaignType,
    campaignTargeting: { locationTargeting, audienceTargeting, campaignDelivery }
  } = useSelector((state: RootState) => state.campaignCreateWorkflow)

  useEffect(() => {
    if (locationTargeting) {
      setTargetingDetails(_.cloneDeep(locationTargeting))
    }
  }, [locationTargeting])

  useEffect(() => {
    if (audienceTargeting) {
      setAudienceDetails(_.cloneDeep(audienceTargeting))
    }
  }, [audienceTargeting])

  useEffect(() => {
    if (campaignDelivery) {
      setDeliveryDetails(_.cloneDeep(campaignDelivery))
    }
  }, [campaignDelivery])

  const whiteLabel = useWhiteLabel()
  const onBack = () => {
    props.onBack?.()
  }

  const onContinueClick = () => {
    targetAudienceComplete(targetingDetails, audienceDetails, deliveryDetails)
  }

  const isValid = targetingType === TargetingType.CustomAudience ? !isInvalidUpload && (audienceDetails.uploadedAudienceUrl ?? '').length > 0 : audienceDetails.segments.length > 0

  return (
    <React.Fragment>
      <Helmet>
        <title>Advanced Audience Targeting</title>
      </Helmet>
      <EuiFlexGroup>
        <EuiFlexItem grow={true}>
          <EuiTitle size='s'>
            <h3>Audience Targeting</h3>
          </EuiTitle>
          <EuiText size='s'>
            <div>
              {targetingType === TargetingType.CustomAudience && <>We accept data in any format. No need to create a special file.</>}
              {targetingType === TargetingType.Audience && (
                <>
                  Define the audience you want to target.
                  <br />
                  Build your audience from over 200,000 data segments, demographic restrictions, and location targeting.
                </>
              )}
            </div>
          </EuiText>
          <EuiSpacer />
          {targetingType === TargetingType.CustomAudience && (
            <React.Fragment>
              <EuiFormRow fullWidth label='File' helpText='Please only upload plain text files, with one record per line.'>
                <AcFileUploader
                  maxFileSize={10000000}
                  allowedFileTypes={['text/plain', 'text/csv']}
                  onUploadedUrlChange={url => {
                    setAudienceDetails({ ...audienceDetails, uploadedAudienceUrl: url })
                  }}
                  onIsValidFileChange={isValid => {
                    setIsInvalidUpload(!isValid)
                  }}
                />
              </EuiFormRow>
              {isInvalidUpload && <EuiCallOut title='Please upload a csv under 10MB' iconType='document' color='warning' />}
            </React.Fragment>
          )}
          {targetingType === TargetingType.Audience && (
            <React.Fragment>
              <EuiCallOut size='s' iconType='users' color='success' title='Search and select one or more individual characteristics' />
              <EuiSpacer size='s' />
              <EuiFormRow id='audienceFinder' label={`Search for individual characteristics`} helpText={audienceDetails.segments.length < 1 ? 'At least one characteristic is required' : undefined} fullWidth>
                <NewSegmentFinder
                  campaignType={campaignType === BuilderCampaignType.Native || campaignType === BuilderCampaignType.Display ? CampaignType.Internet : campaignType === BuilderCampaignType.TV ? CampaignType.TV : CampaignType.Billboard}
                  isInvalid={false}
                  onSegmentClicked={segment => {
                    setAudienceDetails({ ...audienceDetails, segments: [...audienceDetails.segments.filter(s => s.id !== segment.id), segment] })
                  }}
                />
              </EuiFormRow>
              {audienceDetails.segments.length > 0 && (
                <EuiFormRow label='Your campaign will prioritize individuals with any of these characteristics' fullWidth>
                  <AudienceTargeterDetailed
                    audiences={audienceDetails.segments.map(s => {
                      return { id: s.id, name: s.name, description: s.description } as ThirdPartyAudience
                    })}
                    onAudienceRemoved={audience => {
                      const updatedSegments = audienceDetails.segments.filter(s => s.id !== audience.id)
                      setAudienceDetails({ ...audienceDetails, segments: updatedSegments })
                    }}
                  />
                </EuiFormRow>
              )}
              <EuiSpacer size='xl' />
              <LocationRestrictionTargeter targetingDetails={targetingDetails} setTargetingDetails={setTargetingDetails} title={'Location Restriction'} />
              <EuiSpacer />
              <DemographicPrioritizationTargeter audienceDetails={audienceDetails} setAudienceDetails={setAudienceDetails} />
            </React.Fragment>
          )}
          {campaignType !== BuilderCampaignType.Billboard && whiteLabel?.isAgencies() && <DeliveryTargeter deliveryDetails={deliveryDetails} setDeliveryDetails={setDeliveryDetails} title={'Advanced Targeting Options'} campaignType={campaignType} />}
          <EuiSpacer />
          {!isValid && (
            <React.Fragment>
              <EuiText color='warning' size={'xs'}>
                Please define at least one audience.
              </EuiText>
              <EuiSpacer size={'xs'} />
            </React.Fragment>
          )}
          <BackNextFooter onBack={onBack} onNext={onContinueClick} isNextDisabled={!isValid} />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: 260 }}>
          <EuiText size='xs'>
            <h3>Best Practices</h3>
            <h5>Demographic Restriction</h5>
            <p>Keep in mind that specifying one or more age(s), gender(s), and household income levels will reduce the size of the audience your ads will reach.</p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}
