import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { BuilderCampaignType } from 'workflows/CampaignCreateWorkflow'

import { EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { FrequencyUnit, PermissionType } from 'api/interfaces'
import { AdPlacementType, CampaignDelivery } from 'api/interfaces/CampaignDelivery'
import { AudienceTargeting, LocationTargeting, LocationTargetingType } from 'api/interfaces/CampaignTargeting'
import { useGetOrganizationQuery } from 'api/rtkQueryApi/platform/organizationsApi'
import { RootState } from 'app/rootReducer'
import { AcFileUploader } from 'components/Basic/AcFileUploader'
import { BackNextFooter } from 'components/navigation/BackNextFooter'
import { AudienceTargeter } from 'components/targeters'
import { DefaultDayParts } from 'components/targeters/DayPartPicker'
import { DeliveryTargeter } from 'components/targeters/DeliveryTargeter'
import { DemographicPrioritizationTargeter } from 'components/targeters/DemographicPrioritizationTargeter'
import { LocationTargeter } from 'components/targeters/LocationTargeter'
import { ICampaignBuilderWorkflowStep } from 'features/builders/createCampaign/creativeBuilder/CreativeBuilderWorkflowManager'
import { useCampaignBuilderActions } from 'features/builders/createCampaign/useCampaignBuilderActions'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

export const TargetLocationPage: React.FC<ICampaignBuilderWorkflowStep> = props => {
  const { targetLocationComplete } = useCampaignBuilderActions()
  const organizationQuery = useGetOrganizationQuery()
  const [isInvalidUpload, setIsInvalidUpload] = useState(false)
  const [targetingDetails, setTargetingDetails] = useState<LocationTargeting>({
    targetingType: LocationTargetingType.Region,
    regions: [],
    dmas: [],
    cities: [],
    zips: [],
    addresses: []
  })
  const [audienceDetails, setAudienceDetails] = useState<AudienceTargeting>({
    demographics: {
      ageRanges: [],
      genders: [],
      householdIncomes: []
    },
    segments: []
  })
  const [deliveryDetails, setDeliveryDetails] = useState<CampaignDelivery>({
    dayParts: DefaultDayParts,
    frequency: 0,
    frequencyUnit: FrequencyUnit.hour,
    inventorySettings: { targetApp: false, targetWeb: true },
    deviceSettings: { targetPc: true, targetPhone: true, targetTablet: true },
    placement: AdPlacementType.Everywhere,
    categories: [],
    specific: []
  })

  const {
    campaignType,
    campaignTargeting: { locationTargeting, audienceTargeting, campaignDelivery }
  } = useSelector((state: RootState) => state.campaignCreateWorkflow)

  useEffect(() => {
    if (locationTargeting) {
      let cloned = _.cloneDeep(locationTargeting)
      if (campaignType === BuilderCampaignType.TV) {
        if (cloned.targetingType !== LocationTargetingType.DMA && cloned.targetingType !== LocationTargetingType.Region && cloned.targetingType !== LocationTargetingType.USA && cloned.targetingType !== LocationTargetingType.Zip && cloned.targetingType !== LocationTargetingType.City) {
          cloned.targetingType = LocationTargetingType.Region
        }
      } else if (campaignType === BuilderCampaignType.Billboard) {
        if (cloned.targetingType !== LocationTargetingType.Zip && cloned.targetingType !== LocationTargetingType.DMA && cloned.targetingType !== LocationTargetingType.Region && cloned.targetingType !== LocationTargetingType.USA) {
          cloned.targetingType = LocationTargetingType.Region
        }
      }
      setTargetingDetails(cloned)
    }
  }, [locationTargeting, campaignType])

  useEffect(() => {
    if (audienceTargeting) {
      setAudienceDetails(_.cloneDeep(audienceTargeting))
    }
  }, [audienceTargeting, campaignType])

  useEffect(() => {
    if (campaignDelivery) {
      setDeliveryDetails(_.cloneDeep(campaignDelivery))
    }
  }, [campaignDelivery, campaignType])

  const whiteLabel = useWhiteLabel()

  const onBack = () => {
    props.onBack?.()
  }

  const onContinueClick = () => {
    targetLocationComplete(targetingDetails, audienceDetails, deliveryDetails)
  }

  const isValid = useCallback(() => {
    if (targetingDetails.targetingType === LocationTargetingType.Address && targetingDetails.addresses?.length === 0) {
      return false
    }
    if (targetingDetails.targetingType === LocationTargetingType.City && targetingDetails.cities.length === 0) {
      return false
    }
    if (targetingDetails.targetingType === LocationTargetingType.DMA && targetingDetails.dmas.length === 0) {
      return false
    }
    if (targetingDetails.targetingType === LocationTargetingType.Region && targetingDetails.regions.length === 0) {
      return false
    }
    if (targetingDetails.targetingType === LocationTargetingType.Zip && targetingDetails.zips.length === 0) {
      return false
    }
    if (targetingDetails.targetingType == LocationTargetingType.Map) {
      return true
    }

    return true
  }, [targetingDetails])

  return (
    <React.Fragment>
      <Helmet>
        <title>Advanced Location Targeting</title>
      </Helmet>
      <EuiFlexGroup>
        <EuiFlexItem grow={true}>
          <EuiTitle size='s'>
            <h3>Location Targeting</h3>
          </EuiTitle>
          <EuiText size='s'>
            <div>
              Define the location(s) you want to target.
              <br />
              {(campaignType === BuilderCampaignType.Native || campaignType === BuilderCampaignType.Display) && <>You can target specific geographic areas below and then further target individuals based on specific characteristics in the Traffic Prioritization section below.</>}
            </div>
          </EuiText>
          <EuiSpacer />
          <LocationTargeter targetingDetails={targetingDetails} setTargetingDetails={setTargetingDetails} campaignType={campaignType} />
          <EuiSpacer />
          {campaignType === BuilderCampaignType.TV && organizationQuery.data?.permissions?.some(p => p.permissionType === PermissionType.UploadAudience) && (
            <React.Fragment>
              <EuiTitle size='s'>
                <span>Optional: Upload Target Audience</span>
              </EuiTitle>
              <EuiSpacer size='s' />
              <EuiFormRow label='Upload an audience to prioritize traffic'>
                <React.Fragment>
                  <a href='/AudienceSampleUpload.csv' download={'AudienceSampleUpload.csv'}>
                    Template
                  </a>
                  <EuiSpacer size='s' />
                  <AcFileUploader
                    maxFileSize={10000000}
                    allowedFileTypes={['text/plain', 'text/csv']}
                    onUploadedUrlChange={url => {
                      setTargetingDetails({
                        ...targetingDetails,
                        uploadedAudienceUrl: url
                      })
                    }}
                    onIsValidFileChange={isValid => {
                      setIsInvalidUpload(!isValid)
                    }}
                  />
                  {isInvalidUpload && <EuiCallOut title='Please upload a csv under 10MB' iconType='document' color='warning' />}
                </React.Fragment>
              </EuiFormRow>
              <EuiSpacer size='xl' />
            </React.Fragment>
          )}
          {campaignType !== BuilderCampaignType.Billboard && (
            <React.Fragment>
              <DemographicPrioritizationTargeter audienceDetails={audienceDetails} setAudienceDetails={setAudienceDetails} />
              <AudienceTargeter audienceDetails={audienceDetails} setAudienceDetails={setAudienceDetails} campaignType={campaignType} title={'Optional: Audience Refinement'} />
              {whiteLabel?.isAgencies() && <DeliveryTargeter deliveryDetails={deliveryDetails} setDeliveryDetails={setDeliveryDetails} title={'Advanced Targeting Options'} campaignType={campaignType} />}
            </React.Fragment>
          )}
          <EuiSpacer />{' '}
          {!isValid() && (
            <React.Fragment>
              <EuiText color='warning' size={'xs'}>
                Please select at least one location.
              </EuiText>
              <EuiSpacer size={'xs'} />
            </React.Fragment>
          )}
          <BackNextFooter onBack={onBack} onNext={onContinueClick} isNextDisabled={!isValid()} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}
