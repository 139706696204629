import React from 'react'

import { EuiFormRow, EuiSpacer, EuiTitle } from '@elastic/eui'

import { AudienceTargeting } from 'api/interfaces/CampaignTargeting'
import { AgeRangeTargeter } from 'components/targeters/AgeRangeTargeter'
import { GenderTargeter } from 'components/targeters/GenderTargeter'
import { HouseholdIncomeTargeter } from 'components/targeters/HouseholdIncomeTargeter'

export interface IDemographicPrioritizationTargeter {
  audienceDetails: AudienceTargeting
  setAudienceDetails: (audience: AudienceTargeting) => void
}

export const DemographicPrioritizationTargeter: React.FC<IDemographicPrioritizationTargeter> = props => {
  const demographics = props.audienceDetails.demographics
  const onAgeRangesChanged = (ageRanges: string[]) => {
    props.setAudienceDetails({ ...props.audienceDetails, demographics: { ...props.audienceDetails.demographics, ageRanges } })
  }

  const onGendersChanged = (genders: string[]) => {
    props.setAudienceDetails({ ...props.audienceDetails, demographics: { ...props.audienceDetails.demographics, genders } })
  }

  const onHouseholdIncomesChanged = (householdIncomes: string[]) => {
    props.setAudienceDetails({ ...props.audienceDetails, demographics: { ...props.audienceDetails.demographics, householdIncomes } })
  }

  return (
    <React.Fragment>
      <EuiTitle size='s'>
        <span>Demographic Prioritization</span>
      </EuiTitle>
      <EuiSpacer size='s' />
      <EuiFormRow label='Age ranges' fullWidth>
        <AgeRangeTargeter ageRanges={demographics.ageRanges} setAgeRanges={onAgeRangesChanged} />
      </EuiFormRow>
      <EuiSpacer size='s' />
      <EuiFormRow label='Gender' fullWidth>
        <GenderTargeter genders={demographics.genders} setGenders={onGendersChanged} />
      </EuiFormRow>
      <EuiSpacer size='s' />
      <EuiFormRow label='Household income' fullWidth>
        <HouseholdIncomeTargeter householdIncomes={demographics.householdIncomes} setHouseholdIncomes={onHouseholdIncomesChanged} />
      </EuiFormRow>
      <EuiSpacer />
    </React.Fragment>
  )
}
