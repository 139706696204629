import { Circle, CircleProps } from '@react-google-maps/api'
import React, { FC, useCallback, useRef, useState } from 'react'

export interface AcCircleProps extends CircleProps {
  id: string
  onCircleChange: (circle: google.maps.Circle) => void
}

const AcCircle: FC<AcCircleProps> = ({ id, onLoad, onCircleChange, ...props }) => {
  const [isDragging, setIsDragging] = useState(false)

  const circleRef = useRef<google.maps.Circle | null>()

  const updateCircle = useCallback(() => {
    const circle = circleRef.current

    if (circle) {
      onCircleChange(circle)
    }
  }, [onCircleChange])

  const onLoadOverride = useCallback(
    (circle: google.maps.Circle) => {
      circleRef.current = circle

      if (onLoad) {
        onLoad(circle)
      }
    },
    [onLoad]
  )

  const onDragStart = useCallback(() => {
    setIsDragging(true)
  }, [])

  const onDragEnd = useCallback(() => {
    setIsDragging(false)
    updateCircle()
  }, [updateCircle])

  const onCenterChanged = useCallback(() => {
    if (isDragging) {
      return
    }

    updateCircle()
  }, [isDragging, updateCircle])

  const onRadiusChanged = useCallback(() => {
    updateCircle()
  }, [updateCircle])

  const onUnmount = useCallback(() => {
    circleRef.current = null
  }, [])

  return <Circle {...props} onLoad={onLoadOverride} onDragStart={onDragStart} onDragEnd={onDragEnd} onCenterChanged={onCenterChanged} onRadiusChanged={onRadiusChanged} onUnmount={onUnmount} />
}

export default AcCircle
