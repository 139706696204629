import Konva from 'konva'
import React from 'react'
import { Transformer } from 'react-konva'

import { BillboardImageComponent } from '../../api/interfaces'
import { ACImage } from './ACImage'

interface IResizableBillboardImageComponent {
  imageComponent: BillboardImageComponent
  backgroundImageUrl: string
  onDragEnd: (event: Konva.KonvaEventObject<DragEvent>) => void
  onChange?: (x: number, y: number, width: number, height: number) => void
  isSelected?: boolean
  onClick?: () => void
  name?: string
  draggable?: boolean
}

const ResizableBillboardImageComponent: React.FC<IResizableBillboardImageComponent> = props => {
  const shapeRef = React.useRef<any>()
  const trRef = React.useRef<any>()

  React.useEffect(() => {
    if (props.isSelected) {
      trRef.current?.nodes([shapeRef.current])
      trRef.current?.getLayer().batchDraw()
    }
  }, [props.isSelected])

  return (
    <React.Fragment>
      <ACImage
        getImageRef={imageRef => {
          shapeRef.current = imageRef.current
        }}
        backgroundImageUrl={props.backgroundImageUrl}
        height={props.imageComponent.height}
        width={props.imageComponent.width}
        x={props.imageComponent.x}
        y={props.imageComponent.y}
        draggable={props.draggable}
        onDragEnd={props.onDragEnd}
        onClick={props.onClick}
        onTap={props.onClick}
        name={props.name}
        onTransformEnd={() => {
          const node = shapeRef.current
          if (!node) {
            return
          }
          const scaleX = node.scaleX()
          const scaleY = node.scaleY()

          node.scaleX(1)
          node.scaleY(1)
          if (props.onChange) {
            props.onChange(node.x(), node.y(), Math.max(5, node.width() * scaleX), Math.max(node.height() * scaleY))
          }
        }}
      />
      {props.isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox
            }
            return newBox
          }}
        />
      )}
    </React.Fragment>
  )
}

export default ResizableBillboardImageComponent
