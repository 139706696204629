import React, { FC } from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiLoadingChart, EuiSpacer } from '@elastic/eui'

const AcLoadingChart: FC = () => {
  return (
    <EuiFlexGroup justifyContent='spaceAround' alignItems='flexStart'>
      <EuiFlexItem grow={false}>
        <EuiSpacer />
        <EuiLoadingChart size='xl' />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default AcLoadingChart
