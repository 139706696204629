import React from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'

import { EuiCard, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPageBody, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { startBuilder as startBillboardRetailBuilder } from 'features/builders/billboards/retail/builderBillboardRetailSlice'
import { startBuilder as startInternetRetailBuilder } from 'features/builders/internet/retail/builderInternetRetailSlice'
import { startBuilder as startTvRetailBuilder } from 'features/builders/tv/retail/builderTvRetailSlice'

export const ShopifyStartBuilderPage: React.FC = () => {
  const dispatch = useDispatch()
  const setTargetedTv = () => {
    dispatch(startTvRetailBuilder())
  }

  const setInternet = () => {
    dispatch(startInternetRetailBuilder())
  }

  const setBillboard = () => {
    dispatch(startBillboardRetailBuilder())
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Choose Your Campaign</title>
      </Helmet>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle>
            <h1>New Campaign</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>

      <EuiPageBody>
        <EuiPanel grow={false} className='eui-xScroll'>
          <EuiSpacer />
          <EuiFlexGroup justifyContent={'spaceAround'}>
            <EuiFlexItem grow={false} style={{ width: 584 }}>
              <EuiCard id='tvTile' icon={<EuiIcon size='xxl' type='watchesApp' color='success' />} title='Connected TV with Retargeting' description='Create a new Targeted TV campaign' onClick={setTargetedTv} footer={<EuiText size='s'>Create, target and deliver TV commercials to drive your business.</EuiText>} />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
          <EuiFlexGroup>
            <EuiFlexItem grow={true} />
            <EuiFlexItem grow={false} style={{ width: 280 }}>
              <EuiCard id='internetTile' icon={<EuiIcon size='xxl' type='globe' color='success' />} title='Internet' description='Create a new Internet Ad campaign' onClick={setInternet} footer={<EuiText size='s'>Create, target and deliver internet ads to drive your business.</EuiText>} />
            </EuiFlexItem>
            <EuiFlexItem grow={false} style={{ width: 280 }}>
              <EuiCard id='billboardTile' icon={<EuiIcon size='xxl' type='fullScreen' color='success' />} title='Billboards' description='Create a new Billboard campaign' onClick={setBillboard} footer={<EuiText size='s'>Create and deliver large-format digital billboard ads to drive your business.</EuiText>} />
            </EuiFlexItem>
            <EuiFlexItem grow={true} />
          </EuiFlexGroup>
          <EuiSpacer />
        </EuiPanel>
      </EuiPageBody>
    </React.Fragment>
  )
}
