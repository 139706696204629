import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { removeReturnUrlState } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

export const useReturnUrl = () => {
  const dispatch = useDispatch()
  const { returnUrl } = useSelector((state: RootState) => state.app)
  const { pathname } = useLocation()

  useEffect(() => {
    if (returnUrl && !pathname.includes('strawberryField')) {
      dispatch(removeReturnUrlState())
      history.push(returnUrl)
    }
  }, [returnUrl])

  return returnUrl
}
