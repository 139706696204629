import React from 'react'

import { EuiListGroup, EuiListGroupItem } from '@elastic/eui'

interface DmaTargeterProps {
  dmas: Array<string>

  onDmaRemoved(dma: string): void
}

export const DmaTargeter = ({ dmas, onDmaRemoved }: DmaTargeterProps) => {
  return (
    <React.Fragment>
      {dmas && dmas.length > 0 && (
        <EuiListGroup bordered={true} maxWidth={false} size='s'>
          {dmas.map(w => (
            <EuiListGroupItem
              size='s'
              key={w}
              label={`${w}`}
              extraAction={{
                onClick: () => {
                  onDmaRemoved(w)
                },
                iconType: 'cross',
                iconSize: 's',
                'aria-label': 'Remove',
                alwaysShow: true
              }}
            />
          ))}
        </EuiListGroup>
      )}
    </React.Fragment>
  )
}
