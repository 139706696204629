import { OrganizationPrepareCapture, PaymentMethod } from 'api/interfaces'
import { defaultTransformFn, platformApi } from 'api/rtkQueryApi/platform/platformApi'

const paymentMethodsTags = platformApi.enhanceEndpoints({ addTagTypes: ['Cards', 'BankAccounts', 'PaymentMethods'] })

export const paymentMethodsApi = paymentMethodsTags.injectEndpoints({
  endpoints: build => ({
    preparePaymentMethod: build.query<OrganizationPrepareCapture, { accountId: string }>({
      query: ({ accountId }) => {
        return {
          url: `accounts/${accountId}/capture`,
          method: 'POST',
          data: {},
          isContext: true
        }
      },
      keepUnusedDataFor: 0,
      transformResponse: defaultTransformFn
    }),
    getCards: build.query<PaymentMethod[], { accountId: string }>({
      query: ({ accountId }) => {
        return {
          url: `accounts/${accountId}/cards`,
          method: 'GET',
          isContext: true
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { accountId }) => [{ type: 'Cards', id: accountId }]
    }),
    getBankAccounts: build.query<PaymentMethod[], { accountId: string }>({
      query: ({ accountId }) => {
        return {
          url: `accounts/${accountId}/bankAccounts`,
          method: 'GET',
          isContext: true
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { accountId }) => [{ type: 'BankAccounts', id: accountId }]
    }),
    getPaymentMethods: build.query<PaymentMethod[], { accountId: string }>({
      query: ({ accountId }) => {
        return {
          url: `accounts/${accountId}/paymentMethods`,
          method: 'GET',
          isContext: true
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { accountId }) => [{ type: 'PaymentMethods', id: accountId }]
    }),
    deletePaymentMethod: build.mutation<void, { accountId: string; paymentMethodId: string }>({
      query: ({ accountId, paymentMethodId }) => ({
        url: `accounts/${accountId}/paymentMethods/${paymentMethodId}`,
        method: 'DELETE',
        isContext: true
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { accountId }) => [
        { type: 'PaymentMethods', id: accountId },
        { type: 'BankAccounts', id: accountId },
        { type: 'Cards', id: accountId }
      ]
    }),
    assignDefaultPaymentMethod: build.mutation<void, { accountId: string; paymentMethodId: string }>({
      query: ({ accountId, paymentMethodId }) => ({
        url: `accounts/${accountId}/paymentMethods/${paymentMethodId}/makeDefault`,
        method: 'POST',
        data: {},
        isContext: true
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { accountId }) => [
        { type: 'PaymentMethods', id: accountId },
        { type: 'BankAccounts', id: accountId },
        { type: 'Cards', id: accountId }
      ]
    })
  })
})

export const { usePreparePaymentMethodQuery, useLazyPreparePaymentMethodQuery, useGetPaymentMethodsQuery, useGetCardsQuery, useGetBankAccountsQuery, useDeletePaymentMethodMutation, useAssignDefaultPaymentMethodMutation } = paymentMethodsApi
