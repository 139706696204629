import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { EuiCodeBlock, EuiPage, EuiPageBody, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiSpacer, EuiText, EuiTitle, EuiCode } from '@elastic/eui'

import { RootState } from 'app/rootReducer'

export const ShopifyTrackingPage: React.FC = () => {
  const { currentAccount } = useSelector((state: RootState) => state.app)

  return (
    <React.Fragment>
      <Helmet>
        <title>Tracking</title>
      </Helmet>
      <EuiPage className='euiPage--platform'>
        <EuiPageBody>
          <EuiPageHeader>
            <EuiPageHeaderSection>
              <EuiTitle>
                <h1>Tracking</h1>
              </EuiTitle>
            </EuiPageHeaderSection>
          </EuiPageHeader>
          <EuiPanel grow={false}>
            <EuiText>
              <p>Enabling AdCritter Tracking on your store will improve campaign performance, track user behaviors, allow you to create retargeting campaigns, and record analytics for reporting purposes.</p>

              <h3>Enable AdCritter Tracking</h3>
              <EuiSpacer />
              <p>
                <b>1</b>. From your Shopify admin, click <b>Settings {'>'} Apps and sales channels.</b>
              </p>
              <p>
                <b>2</b>. From the <b>Apps and sales channels</b> page, click <b>Online store.</b>
              </p>
              <p>
                <b>3</b>. Click <b>Open sales channel.</b>
              </p>
              <p>
                <b>4</b>. Click <b>Themes.</b>
              </p>
              <p>
                <b>5</b>. Click <b>Actions {'>'} Edit code.</b>
              </p>
              <p>
                <b>6</b>. In the <b>Layout</b> section, click <b>{'{/}'} theme.liquid.</b>
              </p>
              <p>
                <b>7</b>. Scroll to the bottom of the theme.liquid file. Paste the code below directly above <EuiCode>{'</body></html>'}</EuiCode>
              </p>
              <EuiCodeBlock language='html' fontSize='m' paddingSize='m' color='dark' isCopyable>{`<script src="https://apxl.io/script.js"></script>
<script>
    apxlTag.config("${currentAccount!.id}");
</script>`}</EuiCodeBlock>
            </EuiText>
          </EuiPanel>
        </EuiPageBody>
      </EuiPage>
    </React.Fragment>
  )
}
