import React, { useState } from 'react'

import { EuiButton, EuiFormRow, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle } from '@elastic/eui'

import { Account, SelfServicePackage } from 'api/interfaces'
import AccountPicker from 'features/selfServicePackages/components/AccountPicker'

export interface IAccountPickerModal {
  selfServicePackage: SelfServicePackage
  onModalClose: () => void
  onAccountConfirm: (account: Account) => void
  isLoading?: boolean
}

export const AccountPickerModal: React.FC<IAccountPickerModal> = ({ selfServicePackage, ...props }) => {
  const [selectedAccount, setSelectedAccount] = useState<Account>()

  const closeModal = () => {
    props.onModalClose()
  }

  const onConfirm = () => {
    if (selectedAccount) {
      props.onAccountConfirm(selectedAccount)
    }
  }

  return (
    <EuiModal onClose={closeModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>{selfServicePackage.salesTitle}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiFormRow label='Connect this package to the following client:'>
          <AccountPicker selfServicePackage={selfServicePackage} selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} />
        </EuiFormRow>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButton onClick={onConfirm} disabled={!selectedAccount} fill isLoading={props.isLoading}>
          Connect
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  )
}
