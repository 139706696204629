import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { BusinessInfo, CreativeBuilderStep } from 'workflows/CampaignCreateWorkflow'

import { EuiButton, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHideFor, EuiShowFor, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { apiGetBusinessType, apiPutAccountBusinessType } from 'api/adcritterApi'
import { BusinessType } from 'api/interfaces'
import { updateAccountState } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { AcBusinessTypePicker } from 'components/Basic/AcBusinessTypePicker'
import { setBusinessInfo, setCreativeBuilderStep } from 'features/builders/CampaignCreateSlice'
import { ICampaignBuilderWorkflowStep } from 'features/builders/createCampaign/creativeBuilder/CreativeBuilderWorkflowManager'

export const BusinessInfoPage: React.FC<ICampaignBuilderWorkflowStep> = props => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [chosenBusinessType, setChosenBusinessType] = useState<BusinessType>()
  const { businessInfo } = useSelector((state: RootState) => state.campaignCreateWorkflow)
  const [businessTypeError, setBusinessTypeError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [info, setInfo] = useState<BusinessInfo>({})

  useEffect(() => {
    setInfo({ ...info, businessTypeId: chosenBusinessType?.id })
  }, [chosenBusinessType])

  useEffect(() => {
    const i = {
      ...businessInfo
    }
    if (currentAccount?.businessTypeId && !businessInfo?.businessTypeId) {
      i.businessTypeId = currentAccount.businessTypeId
    }

    if (i.businessTypeId) {
      setIsLoading(true)
      apiGetBusinessType(i.businessTypeId)
        .then(result => setChosenBusinessType(result.data))
        .finally(() => setIsLoading(false))
    }

    if (currentAccount?.city && !businessInfo?.cityName) {
      i.cityName = currentAccount.city
    }

    if (currentAccount?.name && !businessInfo?.companyName) {
      i.companyName = currentAccount.name
    }

    if (currentAccount?.website && !businessInfo?.websiteUrl) {
      i.websiteUrl = currentAccount.website
    }

    setInfo(i)
  }, [currentAccount, businessInfo])

  const onSave = () => {
    if (!chosenBusinessType) {
      setBusinessTypeError(true)
      return
    }
    if (!currentAccount!.businessTypeId) {
      apiPutAccountBusinessType(currentAccount!.id, chosenBusinessType!.id).then(result => dispatch(updateAccountState(result.data)))
    }

    dispatch(setCreativeBuilderStep(CreativeBuilderStep.Suggestions))
    dispatch(setBusinessInfo({ ...info }))
  }

  const onBack = () => {
    props.onBack?.()
  }

  const chooseBusinessType = (businessType?: BusinessType) => {
    setChosenBusinessType(businessType)
    setInfo({ ...info, businessTypeId: businessType?.id })
    setBusinessTypeError(false)
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Suggested Ads</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Suggested Ads</h3>
      </EuiTitle>
      <EuiText size='s'>
        <p>First, tell us about your business</p>
      </EuiText>
      <EuiSpacer />

      <EuiFormRow label='Business Type' fullWidth isInvalid={businessTypeError}>
        <AcBusinessTypePicker selectedBusinessType={chosenBusinessType} onBusinessTypeChange={chooseBusinessType} isInvalid={businessTypeError} />
      </EuiFormRow>
      <EuiFormRow label='Company Name' fullWidth helpText='This will be the company name that appears in your ads'>
        <EuiFieldText
          name='companyName'
          value={info.companyName}
          onChange={e => {
            setInfo({ ...info, companyName: e.target.value })
          }}
          fullWidth
        />
      </EuiFormRow>
      <EuiFormRow label='City' fullWidth helpText='Your business city as it should appear in local ads'>
        <EuiFieldText
          name='city'
          value={info.cityName}
          onChange={e => {
            setInfo({ ...info, cityName: e.target.value })
          }}
          fullWidth
        />
      </EuiFormRow>
      <EuiSpacer />

      <EuiShowFor sizes={['xs', 's']}>
        <EuiFlexGroup direction='row'>
          <EuiFlexItem grow={false}>
            <EuiButton id='submit' fill isDisabled={isLoading} onClick={onSave}>
              Continue to Choose Ads
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={onBack}>
              Back
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={onBack} iconType='arrowLeft' iconSide='left'>
              Back
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='submit' fill isDisabled={isLoading} iconType='arrowRight' iconSide='right' onClick={onSave}>
              Continue to Choose Ads
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiHideFor>
    </React.Fragment>
  )
}
