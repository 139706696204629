import { InsightsConfiguration } from 'api/interfaces'
import { BundleInsightsOverviewData } from 'api/interfaces/BundleInsightsOverviewData'
import { Insights } from 'api/interfaces/Insights'
import { defaultTransformFn, IBaseWithAccountQuery, platformApi } from 'api/rtkQueryApi/platform/platformApi'

const bundleInsightsTags = platformApi.enhanceEndpoints({ addTagTypes: ['CanSeeBundleInsights', 'BundleInsightsConfiguration', 'BundleInsightsOverview', 'BundleInsightsRepetition', 'BundleInsightsSaturation', 'BundleInsightsTimeToBehaviorModification'] })

const bundleInsightsApi = bundleInsightsTags.injectEndpoints({
  endpoints: build => ({
    getCanSeeBundleInsights: build.query<boolean, IBaseWithAccountQuery>({
      query: ({ accountId, isAgency }) => ({
        url: `accounts/${accountId}/bundles/insights/canSeeBundleInsights`,
        method: 'GET',
        isContext: true,
        isAgency
      }),
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'CanSeeBundleInsights' }]
    }),
    getBundleInsightsConfiguration: build.query<InsightsConfiguration, IBaseWithAccountQuery>({
      query: ({ accountId, isAgency }) => ({
        url: `accounts/${accountId}/bundles/insights/configuration`,
        method: 'GET',
        isContext: true,
        isAgency
      }),
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'BundleInsightsConfiguration' }]
    }),
    getBundleInsightsOverview: build.query<BundleInsightsOverviewData, IBaseWithAccountQuery>({
      query: ({ accountId, isAgency }) => {
        return {
          url: `accounts/${accountId}/bundles/insights/overview`,
          method: 'GET',
          isContext: true,
          isAgency
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error) => ['BundleInsightsOverview']
    }),
    getBundleInsightsRepetition: build.query<Insights, IBaseWithAccountQuery>({
      query: ({ accountId, isAgency }) => {
        return {
          url: `accounts/${accountId}/bundles/insights/repetition`,
          method: 'GET',
          isContext: true,
          isAgency
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error) => ['BundleInsightsRepetition']
    }),
    getBundleInsightsSaturation: build.query<Insights, IBaseWithAccountQuery>({
      query: ({ accountId, isAgency }) => {
        return {
          url: `accounts/${accountId}/bundles/insights/saturation`,
          method: 'GET',
          isContext: true,
          isAgency
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error) => ['BundleInsightsSaturation']
    }),
    getBundleInsightsTimeToBehaviorModificationCurrentMonth: build.query<number, IBaseWithAccountQuery>({
      query: ({ accountId, isAgency }) => {
        return {
          url: `accounts/${accountId}/bundles/insights/timeToBehaviorModification`,
          method: 'GET',
          isContext: true,
          isAgency
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error) => ['BundleInsightsTimeToBehaviorModification']
    })
  }),
  overrideExisting: false
})

export const { useGetCanSeeBundleInsightsQuery, useGetBundleInsightsConfigurationQuery, useGetBundleInsightsOverviewQuery, useGetBundleInsightsRepetitionQuery, useGetBundleInsightsSaturationQuery, useGetBundleInsightsTimeToBehaviorModificationCurrentMonthQuery } = bundleInsightsApi
