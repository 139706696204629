import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { apiDeleteAd, apiGetAds, apiGetBusinessType, apiGetCampaign, apiPutAdStatusActive, apiPutAdStatusPaused } from 'api/adcritterApi'
import { BusinessType, Campaign, Creative, CreativeStatus } from 'api/interfaces'
import { AppThunk } from 'app/store'

export interface AdState {
  id: string
  checked: boolean
  disabled: boolean
  loading: boolean
  canRemove: boolean
  type: string
  status: CreativeStatus
  rejectionReasons: string[]
  name: string
  headline: string | null
  body: string | null
  sections: string[] | null
  thumbnailUrl: string
  mediaType: string
  uploadedUrl: string
}

interface CampaignState {
  isLoadingCampaign: boolean
  campaign: Campaign | null
  isLoadingBusinessType: boolean
  businessType: BusinessType | null
  isLoadingAds: boolean
  ads: Creative[]
  adStates: AdState[]
  isRemovingAd: boolean
}

const initialState: CampaignState = {
  isLoadingCampaign: false,
  campaign: null,
  isLoadingBusinessType: false,
  businessType: null,
  isLoadingAds: false,
  ads: [],
  adStates: [],
  isRemovingAd: false
}

const campaign = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    startLoadingCampaign(state: CampaignState) {
      state.isLoadingCampaign = true
    },
    finishedLoadingCampaign(state: CampaignState, { payload }: PayloadAction<Campaign>) {
      state.isLoadingCampaign = false
      state.campaign = payload
    },
    startLoadingBusinessType(state: CampaignState) {
      state.isLoadingBusinessType = true
    },
    finishedLoadingBusinessType(state: CampaignState, { payload }: PayloadAction<BusinessType>) {
      state.isLoadingBusinessType = false
      state.businessType = payload
    },
    startLoadingAds(state: CampaignState) {
      state.isLoadingAds = true
      state.adStates = []
    },
    finishedLoadingAds(state: CampaignState, { payload }: PayloadAction<Creative[]>) {
      state.isLoadingAds = false
      state.ads = payload
      state.adStates = payload.map(a => ({
        id: a.id,
        type: a.type,
        checked: a.status !== CreativeStatus.Inactive && a.status !== CreativeStatus.Deleted,
        status: a.status,
        disabled: !a.canEditStatus,
        canRemove: a.canRemove,
        rejectionReasons: a.auditReasons,
        name: a.name ?? '',
        headline: a.nativeDetails?.headline,
        body: a.nativeDetails?.body,
        thumbnailUrl: a.thumbnailUrl ? a.thumbnailUrl : a.displayAdDetails?.uploadedUrl,
        sections: a.tvCommercialDetails?.sections,
        loading: false,
        mediaType: a.mediaType,
        uploadedUrl: a.tvCommercialUploadedDetails?.uploadedUrl!
      }))
    },
    disableAdState(state: CampaignState, { payload }: PayloadAction<string>) {
      const index = state.adStates.map(a => a.id).indexOf(payload)
      const adState = { ...state.adStates[index], disabled: true, loading: true }
      const newAdStates = [...state.adStates]
      newAdStates.splice(index, 1, adState)
      state.adStates = newAdStates
    },
    replaceAdState(state: CampaignState, { payload }: PayloadAction<AdState>) {
      const index = state.adStates.map(a => a.id).indexOf(payload.id)
      const newAdStates = [...state.adStates]
      newAdStates.splice(index, 1, payload)
      state.adStates = newAdStates
    },
    startRemovingAd(state: CampaignState) {
      state.isRemovingAd = true
    },
    finishedRemovingAd(state: CampaignState) {
      state.isRemovingAd = false
    }
  }
})

export const { startLoadingCampaign, finishedLoadingCampaign, startLoadingBusinessType, finishedLoadingBusinessType, startLoadingAds, finishedLoadingAds, disableAdState, replaceAdState, startRemovingAd, finishedRemovingAd } = campaign.actions

export default campaign.reducer

export const fetchCampaign =
  (accountId: string, campaignId: string): AppThunk =>
  async dispatch => {
    dispatch(startLoadingCampaign())
    dispatch(startLoadingBusinessType())
    dispatch(startLoadingAds())
    const response = await apiGetCampaign(accountId, campaignId)
    dispatch(finishedLoadingCampaign(response.data))
    dispatch(fetchBusinessType(response.data.businessTypeId))
    dispatch(fetchAds(accountId, campaignId))
  }

export const fetchBusinessType =
  (businessTypeId: string): AppThunk =>
  async dispatch => {
    dispatch(startLoadingBusinessType())
    const response = await apiGetBusinessType(businessTypeId)
    dispatch(finishedLoadingBusinessType(response.data))
  }

export const fetchAds =
  (accountId: string, campaignId: string): AppThunk =>
  async dispatch => {
    dispatch(startLoadingAds())
    const response = await apiGetAds(accountId, campaignId)
    dispatch(finishedLoadingAds(response.data))
  }

export const pauseAd =
  (accountId: string, campaignId: string, adId: string): AppThunk =>
  async dispatch => {
    dispatch(disableAdState(adId))
    const response = await apiPutAdStatusPaused(accountId, adId, campaignId)
    dispatch(
      replaceAdState({
        id: response.data.id,
        name: response.data.name ?? '',
        type: response.data.type,
        checked: response.data.status !== CreativeStatus.Inactive && response.data.status !== CreativeStatus.Deleted,
        status: response.data.status,
        disabled: !response.data.canEditStatus,
        canRemove: response.data.canRemove,
        loading: false,
        rejectionReasons: response.data.auditReasons,
        headline: response.data.nativeDetails?.headline,
        body: response.data.nativeDetails?.body,
        thumbnailUrl: response.data.thumbnailUrl ? response.data.thumbnailUrl : response.data.displayAdDetails?.uploadedUrl,
        sections: response.data.tvCommercialDetails?.sections,
        mediaType: response.data.mediaType,
        uploadedUrl: response.data.tvCommercialUploadedDetails?.uploadedUrl!
      })
    )
  }

export const startAd =
  (accountId: string, campaignId: string, adId: string): AppThunk =>
  async dispatch => {
    dispatch(disableAdState(adId))
    const response = await apiPutAdStatusActive(accountId, adId, campaignId)
    dispatch(
      replaceAdState({
        id: response.data.id,
        name: response.data.name ?? '',
        type: response.data.type,
        checked: response.data.status !== CreativeStatus.Inactive && response.data.status !== CreativeStatus.Deleted,
        status: response.data.status,
        disabled: !response.data.canEditStatus,
        canRemove: response.data.canRemove,
        loading: false,
        rejectionReasons: response.data.auditReasons,
        headline: response.data.nativeDetails?.headline,
        body: response.data.nativeDetails?.body,
        thumbnailUrl: response.data.thumbnailUrl ? response.data.thumbnailUrl : response.data.displayAdDetails?.uploadedUrl,
        sections: response.data.tvCommercialDetails?.sections,
        mediaType: response.data.mediaType,
        uploadedUrl: response.data.tvCommercialUploadedDetails?.uploadedUrl!
      })
    )
  }

export const removeAd =
  (accountId: string, campaignId: string, adId: string): AppThunk =>
  async dispatch => {
    dispatch(startRemovingAd())
    dispatch(startLoadingAds())
    await apiDeleteAd(accountId, adId)
    dispatch(finishedRemovingAd())
    const response = await apiGetAds(accountId, campaignId)
    dispatch(finishedLoadingAds(response.data))
  }
