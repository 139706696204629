import { BillboardGeo, BillboardGeosInRange } from 'api/interfaces'
import { defaultTransformFn, platformApi } from 'api/rtkQueryApi/platform/platformApi'

const geoDataApiTags = platformApi.enhanceEndpoints({ addTagTypes: ['BillboardGeosInRange'] })

const gepDataApi = geoDataApiTags.injectEndpoints({
  endpoints: build => ({
    getBillboardGeosInRange: build.query<BillboardGeo[], BillboardGeosInRange>({
      query: geosInRange => {
        return {
          url: `data/billboardsInRange`,
          method: 'POST',
          isContext: true,
          data: geosInRange
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, geosInRange) => [{ type: 'BillboardGeosInRange', id: `Lng:${geosInRange.lng} Lat:${geosInRange.lat} Range:${geosInRange.radiusMeters}` }]
    })
  })
})

export const { useLazyGetBillboardGeosInRangeQuery } = gepDataApi
