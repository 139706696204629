import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { apiGetAccountUploadedImages, apiGetImageSearch, apiGetImageSearchHints } from 'api/adcritterApi'
import { AcImage } from 'api/interfaces'
import { AppThunk } from 'app/store'

interface SearchImageState {
  isLoadingHints: boolean
  hints: string[]
  isSearchingImages: boolean
  searchImages: AcImage[]
  isLoadingUploadedImages: boolean
  uploadedImages: AcImage[]
  pageSize: number
  currentPage: number
  totalCount: number
  chosenImage: AcImage | null
}

const initialState: SearchImageState = {
  isLoadingHints: false,
  hints: [],
  isSearchingImages: false,
  searchImages: [],
  isLoadingUploadedImages: false,
  uploadedImages: [],
  pageSize: 36,
  currentPage: 1,
  totalCount: 0,
  chosenImage: null
}

function reset(state: SearchImageState) {
  state.isLoadingHints = false
  state.hints = []
  state.isSearchingImages = false
  state.searchImages = []
  state.isLoadingUploadedImages = false
  state.uploadedImages = []
  state.pageSize = 36
  state.currentPage = 1
  state.totalCount = 0
  state.chosenImage = null
}

type SearchImagesFinishedAction = PayloadAction<{
  images: AcImage[]
  totalCount: number
}>

const searchImage = createSlice({
  name: 'searchImage',
  initialState,
  reducers: {
    resetSearchImage(state: SearchImageState) {
      reset(state)
    },
    startLoadingHints(state: SearchImageState) {
      state.isLoadingHints = true
    },
    finishedLoadingHints(state: SearchImageState, { payload }: PayloadAction<string[]>) {
      state.isLoadingHints = false
      state.hints = payload
    },
    startSearchingImages(state: SearchImageState) {
      state.isSearchingImages = true
    },
    finishedSearchingImages(state: SearchImageState, { payload }: SearchImagesFinishedAction) {
      state.isSearchingImages = false
      state.searchImages = payload.images
      state.totalCount = payload.totalCount
    },
    startLoadingUploadedImages(state: SearchImageState) {
      state.isLoadingUploadedImages = true
    },
    finishedLoadingUploadedImages(state: SearchImageState, { payload }: PayloadAction<AcImage[]>) {
      state.isLoadingUploadedImages = false
      state.uploadedImages = payload
    },
    setPageSearchingImages(state: SearchImageState, { payload }: PayloadAction<number>) {
      state.currentPage = payload
    },
    chooseImage(state: SearchImageState, { payload }: PayloadAction<AcImage>) {
      state.chosenImage = payload
    }
  }
})

export const { resetSearchImage, startLoadingHints, finishedLoadingHints, startSearchingImages, finishedSearchingImages, setPageSearchingImages, startLoadingUploadedImages, finishedLoadingUploadedImages, chooseImage } = searchImage.actions

export default searchImage.reducer

export const fetchHints =
  (prefix: string): AppThunk =>
  async dispatch => {
    dispatch(startLoadingHints())
    const response = await apiGetImageSearchHints(prefix)
    dispatch(finishedLoadingHints(response.data))
  }

export const searchImages =
  (search: string, pageSize: number, currentPage: number): AppThunk =>
  async dispatch => {
    dispatch(startSearchingImages())
    dispatch(setPageSearchingImages(currentPage))
    const response = await apiGetImageSearch(search, pageSize, currentPage)
    dispatch(finishedSearchingImages(response.data))
  }

export const fetchUploadedImages =
  (accountId: string): AppThunk =>
  async dispatch => {
    dispatch(startLoadingUploadedImages())
    const response = await apiGetAccountUploadedImages(accountId)
    dispatch(finishedLoadingUploadedImages(response.data))
  }
