import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiButton, EuiSteps, EuiText, EuiSpacer, EuiTitle, EuiFlyout, EuiFlyoutHeader, EuiFlyoutBody, EuiFlyoutFooter, EuiFlexGroup, EuiFlexItem, EuiImage, EuiCheckbox, htmlIdGenerator, EuiDescriptionList } from '@elastic/eui'
import { EuiContainedStepProps } from '@elastic/eui/src/components/steps/steps'

import { useGetShopifyPremierBusinessTypesQuery } from 'api/rtkQueryApi/platform/businessTypesApi'
import { RootState } from 'app/rootReducer'

export interface IShopifyLandingModal {
  onClose?: () => void
  onGetStarted?: (showWelcomeToAdCritter: boolean) => void
}

export const ShopifyLandingPanel: React.FC<IShopifyLandingModal> = props => {
  const { showWelcome } = useSelector((state: RootState) => state.app)
  const [isDontShowAgainChecked, setIsDontShowAgainChecked] = useState(!showWelcome)
  useGetShopifyPremierBusinessTypesQuery()

  const closeModal = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const getStartedClick = () => {
    if (props.onGetStarted) {
      props.onGetStarted(isDontShowAgainChecked)
    }
  }

  const dontShowAgainClick = () => {
    setIsDontShowAgainChecked(!isDontShowAgainChecked)
  }

  const getStartedSteps: EuiContainedStepProps[] = [
    {
      title: 'Create',
      children: (
        <EuiText>
          <p>Select your business type and choose one of the pre-made commercials, or upload your own. Need some extra help? Request a custom commercial and AdCritter will make it for free.</p>
        </EuiText>
      )
    },
    {
      title: 'Target',
      children: (
        <EuiText>
          <p>Target your ideal buyers based on location, interests, buying behavior and more. Thousands of audience types are available. Or upload an audience of your own. Then choose where you want your ads to run.</p>
        </EuiText>
      )
    },
    {
      title: 'Re-Target (optional)',
      children: (
        <EuiText>
          <p>Optionally, you can install the AdCritter pixel in your store to activate TV retargeting. Your commercial will run in homes of visitors to your site.</p>
        </EuiText>
      )
    }
  ]

  const budgetNotes = [
    {
      title: 'Software is Free for Shopify Users',
      description: 'The AdCritter Platform is normally $149/month, but it is free for all Shopify store owners.'
    },
    {
      title: 'You set the advertising budget',
      description: 'Once you’re ready to run your campaign, you set the budget. Shopify will bill your account for the amount you choose.'
    }
  ]

  return (
    <EuiFlyout onClose={closeModal} ownFocus hideCloseButton>
      <EuiFlyoutHeader hasBorder>
        <EuiFlexGroup alignItems={'center'}>
          <EuiFlexItem grow={false}>
            <EuiImage size={64} alt='AdCritter Gus Logo' src='/favicon.ico' />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiTitle size='l'>
              <h2>Welcome to AdCritter</h2>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiText color='subdued' style={{ marginLeft: 88, marginTop: -28 }}>
          <p>The Small Business Ad Platform</p>
        </EuiText>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiTitle>
          <h1>How Connected TV Advertising helps you grow your store</h1>
        </EuiTitle>
        <EuiSpacer />
        <EuiText>
          <p>Easily advertise your store on the major streaming TV networks while targeting your most likely buyers.</p>
          <p>AdCritter includes pre-made commercials, free custom commercials, detailed targeting capabilities, retargeting, and the ability to choose where your commercial appears.</p>
        </EuiText>
        <EuiSpacer size='xl' />
        <EuiTitle>
          <h1>Pricing</h1>
        </EuiTitle>
        <EuiSpacer />
        <EuiDescriptionList style={{ paddingLeft: 20 }} listItems={budgetNotes} />
        <EuiSpacer size='xl' />
        <EuiTitle>
          <h1>Getting Started is as easy as 1-2-3</h1>
        </EuiTitle>
        <EuiSpacer />
        <EuiSteps steps={getStartedSteps} />
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent='spaceBetween'>
          <EuiFlexItem grow={false}>
            <EuiButton onClick={getStartedClick} fill>
              Get Started
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <EuiCheckbox id={htmlIdGenerator()()} checked={isDontShowAgainChecked} onChange={dontShowAgainClick} label={"Don't show this again"} />
          </EuiFlexItem>
          <EuiFlexItem />
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </EuiFlyout>
  )
}
