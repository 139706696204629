import { useFormik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'

import { EuiForm, EuiFormRow, EuiFieldText, EuiSpacer, EuiButton, EuiText } from '@elastic/eui'

import { apiForgot } from 'api/adcritterApi'

import EmailConfirmation from './EmailConfirmation'

interface FormValues {
  email: string
}

const ForgotForm = () => {
  const [emailSent, setEmailSent] = useState(false)

  const forgotSchema = Yup.object().shape({
    email: Yup.string().email().required('Please Enter Your Email Address').lowercase().trim()
  })

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: forgotSchema,
    onSubmit: (values: FormValues) => {
      apiForgot(values.email)
        .then(response => {
          formik.setSubmitting(false)
          formik.setStatus(null)
        })
        .catch(response => {
          formik.setSubmitting(false)
          formik.setStatus(response.errors.first)
        })
      setEmailSent(true)
    }
  })

  return (
    <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.status && !!formik.errors} error={formik.status}>
      <EuiText size='xs'>
        <p>Please enter your email address below. If we recognize the address provided, you will receive a message with a link to reset your password.</p>
      </EuiText>
      <EuiSpacer />
      <EuiFormRow label='Email' fullWidth isInvalid={formik.touched.email && !!formik.errors.email} error={formik.errors.email}>
        <EuiFieldText name='email' value={formik.values.email} onChange={formik.handleChange} icon='email' placeholder='username@domain.com' fullWidth isInvalid={formik.touched.email && !!formik.errors.email} />
      </EuiFormRow>
      <EuiSpacer />
      <EuiButton id='send' fill type='submit' isLoading={formik.isSubmitting}>
        Send
      </EuiButton>
      {emailSent && (
        <EmailConfirmation
          onCancel={() => {
            setEmailSent(false)
          }}
        />
      )}
    </EuiForm>
  )
}

export default ForgotForm
