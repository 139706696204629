import { AdViewer } from 'api/interfaces/AdViewer'
import { defaultTransformFn, platformApi } from 'api/rtkQueryApi/platform/platformApi'

const adViewerTags = platformApi.enhanceEndpoints({ addTagTypes: ['Campaign'] })

const adViewerApi = adViewerTags.injectEndpoints({
  endpoints: build => ({
    getCampaignAdViewer: build.query<AdViewer, { campaignId: string }>({
      query: request => {
        return {
          url: `adViewer/${request.campaignId}/adViews`,
          method: 'GET',
          isContext: false,
          isPublic: true
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, request) => [{ type: 'Campaign', id: request.campaignId }]
    })
  }),
  overrideExisting: false
})
export const { useGetCampaignAdViewerQuery } = adViewerApi
