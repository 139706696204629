import React, { FC } from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, EuiTitle, EuiSplitPanel } from '@elastic/eui'

import InsightsQuickInfo from 'components/insights/Components/InsightsQuickInfo'
import ReportButtonGroup from 'components/insights/Components/ReportButtonGroup'
import { grayTextColor, rightPanelLinkTextColor } from 'components/insights/InsightsConstants'

export interface InsightsRepetitionProps {
  isLoading: boolean
  title: string
  description: string
  buttonColor: string
  buttonTextColor: string
  rightPanelTitle: string
  rightPanelTitleDescription?: string
  rightPanelDescription: string
  rightPanelLink?: string
  rightPanelLinkText?: string
  rightPanelColor: string
}

const InsightsPanel: FC<InsightsRepetitionProps> = ({ isLoading, title, description, buttonColor, buttonTextColor, rightPanelTitle, rightPanelTitleDescription, rightPanelDescription, rightPanelLink, rightPanelLinkText, rightPanelColor, children }) => {
  const exportToPdf = () => {}

  return (
    <EuiSplitPanel.Outer direction='row' grow={false}>
      <EuiSplitPanel.Inner>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiTitle size='m'>
                  <h1>{title}</h1>
                </EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem grow={false} style={{ display: 'none' }}>
                <ReportButtonGroup buttonColor={buttonColor} buttonTextColor={buttonTextColor} exportToPdf={exportToPdf} />
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer size='m' />

            <EuiFlexItem>
              <EuiText size='m' color={grayTextColor}>
                {description}
              </EuiText>
            </EuiFlexItem>

            <EuiSpacer size='m' />

            <EuiFlexItem>{children}</EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiSplitPanel.Inner>
      <EuiSplitPanel.Inner style={{ backgroundColor: rightPanelColor, minWidth: '20em', display: 'flex', alignItems: 'center' }} grow={false} paddingSize='l'>
        <InsightsQuickInfo isLoading={isLoading} description={rightPanelDescription} title={rightPanelTitle} titleDescription={rightPanelTitleDescription} titleDescriptionColor={grayTextColor} link={rightPanelLink} linkText={rightPanelLinkText} linkTextColor={rightPanelLinkTextColor} />
      </EuiSplitPanel.Inner>
    </EuiSplitPanel.Outer>
  )
}

export default InsightsPanel
