import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiButtonEmpty, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiLoadingContent, EuiSelect, EuiSpacer, EuiText } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select/select'

import { ApiError, apiPutCampaign } from 'api/adcritterApi'
import { showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'

import { fetchCampaign } from '../campaignSlice'

interface FormValues {
  name: string
  monthlyImpressions: number
}

const DetailsMonthlyImpressionsDrawer: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount, currentOrganization } = useSelector((state: RootState) => state.app)
  const campaign = useSelector((state: RootState) => state.campaign)
  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    monthlyImpressions: 70
  })

  useEffect(() => {
    if (campaign.campaign && currentOrganization) {
      setFormValues({
        name: campaign.campaign.name,
        monthlyImpressions: campaign.campaign.monthlyImpressions ?? 0
      })
    }
  }, [campaign.campaign, currentOrganization])

  const detailSchema = Yup.object().shape({
    name: Yup.string().max(128).required('Please Enter the Campaign Name'),
    monthlyImpressions: Yup.number().min(100000).required('Please desired monthly impressions')
  })

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: detailSchema,
    onSubmit: (values: FormValues) => {
      apiPutCampaign(currentAccount!.id, campaign.campaign!.id, values.name, 0, 0, undefined, undefined, values.monthlyImpressions)
        .then(() => {
          dispatch(showSuccessToast('Saved Campaign'))
          dispatch(fetchCampaign(currentAccount!.id, campaign.campaign!.id))
          formik.setSubmitting(false)
          formik.setStatus(null)
        })
        .catch(response => {
          formik.setSubmitting(false)
          response.errors.forEach(function (error: ApiError) {
            formik.setFieldError(error.name, error.message)
          })
        })
    }
  })

  const packages: EuiSelectOption[] = [
    { value: '65000', text: '60k-70k impressions/month ($500)' },
    { value: '100000', text: '90k-100k impressions/month ($750)' },
    { value: '130000', text: '120k-140k impressions/month ($1,000)' },
    { value: '200000', text: '180k-210k impressions/month ($1,500)' }
  ]

  let content

  if (campaign.isLoadingCampaign) {
    content = <EuiLoadingContent lines={3} />
  } else {
    content = (
      <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
        <EuiFormRow label='Campaign Name' fullWidth isInvalid={formik.touched.name && !!formik.errors.name} error={formik.errors.name}>
          <EuiFieldText name='name' value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='' fullWidth isInvalid={formik.touched.name && !!formik.errors.name} />
        </EuiFormRow>
        <React.Fragment>
          <EuiSpacer />
          <EuiFormRow label='Selected Business Type' fullWidth>
            <EuiFieldText
              readOnly
              fullWidth
              value={campaign.businessType?.name}
              append={
                <EuiButtonEmpty id='change' disabled={true}>
                  change
                </EuiButtonEmpty>
              }
              isLoading={campaign.isLoadingBusinessType}
            />
          </EuiFormRow>
        </React.Fragment>
        <EuiSpacer />
        <EuiFormRow label='Monthly Impressions (100,000 min)' fullWidth isInvalid={formik.touched.monthlyImpressions && !!formik.errors.monthlyImpressions} error={formik.errors.monthlyImpressions}>
          <EuiSelect name='monthlyImpressions' isInvalid={!!formik.errors.monthlyImpressions} options={packages} value={formik.values.monthlyImpressions.toString()} onChange={e => formik.setFieldValue('monthlyImpressions', Number.parseInt(e.target.value))} />
        </EuiFormRow>
        <EuiSpacer />

        <EuiButton id='save' fill type='submit' isLoading={formik.isSubmitting}>
          Save
        </EuiButton>
      </EuiForm>
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>{content}</EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: 260 }}>
          <EuiText size='xs'>
            <h3>Best Practices</h3>
            <h5>Running Campaigns</h5>
            <p>While campaigns can be edited anytime, it is generally a best practice to change things slowly and carefully. For example, making frequent changes to a campaign's targeting or budget settings can hinder the machine learning and optimization algorithms.</p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default DetailsMonthlyImpressionsDrawer
