import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiLoadingChart, EuiPage, EuiPageBody, EuiPageHeader, EuiPageHeaderSection, EuiSpacer, EuiTitle } from '@elastic/eui'

import { useGetBundleInsightsConfigurationQuery, useGetBundleInsightsOverviewQuery, useGetBundleInsightsRepetitionQuery, useGetBundleInsightsSaturationQuery, useGetBundleInsightsTimeToBehaviorModificationCurrentMonthQuery, useGetCanSeeBundleInsightsQuery } from 'api/rtkQueryApi/platform/bundleInsightsApi'
import { RootState } from 'app/rootReducer'
import AcLoadingChart from 'components/AcLoadingChart'
import BundleInsightsOverview from 'components/insights/Panels/BundleInsightsOverview'
import InsightsRepetition from 'components/insights/Panels/InsightsRepetition'
import InsightsSaturation from 'components/insights/Panels/InsightsSaturation'
import InsightsTimeToBehaviorModification from 'components/insights/Panels/InsightsTimeToBehaviorModification'

const BundleInsightsPage: FC = () => {
  const { currentAccount, currentOrganization } = useSelector((state: RootState) => state.app)

  const query = { accountId: currentAccount?.id ?? '' }
  const skip = { skip: !currentAccount || !currentOrganization }

  const { isLoading: isLoadingConfig, data: config } = useGetBundleInsightsConfigurationQuery(query, skip)
  const { isLoading: isLoadingOverview, data: overview } = useGetBundleInsightsOverviewQuery(query, skip)
  const { isLoading: isLoadingRepetition, data: repetition } = useGetBundleInsightsRepetitionQuery(query, skip)
  const { isLoading: isLoadingSaturation, data: saturation } = useGetBundleInsightsSaturationQuery(query, skip)
  const { isLoading: isLoadingTimeToBehaviorCurrentMonth, data: timeToBehaviorCurrentMonth } = useGetBundleInsightsTimeToBehaviorModificationCurrentMonthQuery(query, skip)

  if (!currentAccount || !currentOrganization || isLoadingConfig || !config) {
    return <AcLoadingChart />
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Insights</title>
      </Helmet>
      <EuiPage className='euiPage--platform'>
        <EuiPageBody>
          <EuiPageHeader>
            <EuiPageHeaderSection>
              <EuiTitle>
                <h1>Insights</h1>
              </EuiTitle>
            </EuiPageHeaderSection>
          </EuiPageHeader>
          <InsightsRepetition isLoading={isLoadingConfig || isLoadingRepetition} repetition={repetition} config={config} />

          <EuiSpacer size='xl' />

          <InsightsSaturation isLoading={isLoadingConfig || isLoadingSaturation} saturation={saturation} config={config} />

          <EuiSpacer size='xl' />

          <InsightsTimeToBehaviorModification isLoading={isLoadingConfig || isLoadingTimeToBehaviorCurrentMonth} timeToBehaviorCurrentMonth={timeToBehaviorCurrentMonth} config={config} />
        </EuiPageBody>
      </EuiPage>
    </React.Fragment>
  )
}

export default BundleInsightsPage
