import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiCallOut, EuiCheckbox, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHideFor, EuiLink, EuiListGroup, EuiListGroupItem, EuiLoadingSpinner, EuiShowFor, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { AdvancedTargeting } from 'api/interfaces'
import { useGetRetargetingCampaignsQuery } from 'api/rtkQueryApi/platform/campaignsApi'
import { RootState } from 'app/rootReducer'
import { DefaultDayParts } from 'components/targeters/DayPartPicker'
import { AdvancedTargetingType, setAdvancedTargeting } from 'features/builders/internet/retail/builderInternetRetailSlice'
import history from 'services/HistoryService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

export const RetargetAdvancedStep: React.FC = () => {
  const builder = useSelector((state: RootState) => state.builderInternetRetail)
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const whiteLabel = useWhiteLabel()
  const dispatch = useDispatch()
  const getCampaignsQuery = useGetRetargetingCampaignsQuery({ accountId: currentAccount?.id ?? '', isAgency: whiteLabel?.isAgencies() })
  const [selectedCampaignIds, setSelectedCampaignIds] = useState<string[]>(builder.advancedTargeting?.retargetingCampaignIds ?? [])
  const [targetEntireSite, setTargetEntireSite] = useState<boolean>(builder.advancedTargeting?.retargetingType === 'All')
  const [targetSpecificPages, setTargetSpecificPages] = useState<boolean>(builder.advancedTargeting?.retargetingType === 'Specific')
  const [websiteUrls, setWebsiteUrls] = useState<string[]>(builder.advancedTargeting?.urlParts ?? [])
  const [websiteUrl, setWebsiteUrl] = useState<string>()

  const canContinue = selectedCampaignIds.length > 0 || targetSpecificPages || targetEntireSite

  const returnTargetingChooseStep = () => {
    history.push('/build/internet/public/target')
  }

  const onCampaignSelected = (optionId: string) => {
    if (selectedCampaignIds.some(id => id === optionId)) {
      setSelectedCampaignIds(selectedCampaignIds.filter(id => id !== optionId))
    } else {
      setSelectedCampaignIds([...selectedCampaignIds, optionId])
    }
  }

  const onUrlAdded = () => {
    if (websiteUrlIsValid) {
      setWebsiteUrls([...websiteUrls.filter(url => url !== websiteUrl), websiteUrl])
      setWebsiteUrl('')
    }
  }

  const onUrlRemoved = (url: string) => {
    setWebsiteUrls(websiteUrls.filter(u => u !== url))
  }

  const onNextClick = () => {
    const targeting: AdvancedTargeting = {
      ...builder.advancedTargeting!,
      advancedTargetingType: AdvancedTargetingType.Retarget,
      locationType: 'USA',
      locations: {
        countries: [],
        regions: [],
        dmas: [],
        cities: [],
        postalCodes: []
      },
      segments: [],
      states: [],
      dmas: [],
      cities: [],
      zips: [],
      geoFences: [],
      geoCircles: [],
      geoRectangles: [],
      geoPolygons: [],
      audiences: [],
      inventorySettings: null,
      deviceSettings: null,
      frequency: null,
      frequencyUnit: null,
      audienceType: 'Retargeting',
      retargetingType: targetEntireSite ? 'All' : targetSpecificPages ? 'Specific' : null,
      urlParts: targetSpecificPages ? websiteUrls : [],
      retargetingCampaignIds: selectedCampaignIds,
      dayParts: DefaultDayParts
    }
    dispatch(setAdvancedTargeting(targeting))
    history.push('/build/internet/public/deliver')
  }

  const websiteUrlIsValid = websiteUrl && websiteUrl.length > 0 && websiteUrl.startsWith('https://')

  return (
    <React.Fragment>
      <Helmet>
        <title>Retargeting</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Retarget my campaigns</h3>
      </EuiTitle>
      <EuiText size='s'>Select the campaigns you want to retarget.</EuiText>
      {getCampaignsQuery.data && getCampaignsQuery.data.length === 0 && (
        <EuiCallOut title='You have no campaigns to retarget' color={'primary'}>
          <p>Retargeting requires a previously active TV or Internet campaign to have generated an audience.</p>
        </EuiCallOut>
      )}
      <EuiSpacer />
      <EuiFormRow>
        <div style={{ maxHeight: 600, overflow: 'auto' }}>{getCampaignsQuery.data ? getCampaignsQuery.data.map(campaign => <EuiCheckbox id={campaign.id} label={`${campaign.name} ($${campaign.budget} ${campaign.type})`} checked={selectedCampaignIds.some(id => id === campaign.id)} onChange={() => onCampaignSelected(campaign.id)} />) : getCampaignsQuery.isLoading ? <EuiLoadingSpinner /> : <React.Fragment />}</div>
      </EuiFormRow>
      <EuiSpacer />
      <EuiTitle size='s'>
        <h3>Retarget my website</h3>
      </EuiTitle>
      <EuiText size='s'>Select how you want to retarget your website</EuiText>
      {!currentAccount?.hasVisits && (
        <EuiCallOut title='The Audience Pixel must be installed onto the site you wish to retarget' color={'primary'}>
          <p>
            To install the audience pixel and enable website retargeting, check out the{' '}
            <EuiLink target='_blank' href={'/audiencePixel'} color={'accent'}>
              Audience Pixel Tab
            </EuiLink>
          </p>
        </EuiCallOut>
      )}
      <EuiSpacer />
      <EuiFormRow label={'Target an audience generated from your website'}>
        <React.Fragment>
          <EuiCheckbox
            id='website'
            label='Entire Site'
            checked={targetEntireSite}
            onChange={() => {
              setTargetEntireSite(!targetEntireSite)
            }}
            disabled={targetSpecificPages || !currentAccount?.hasVisits}
          />
          <EuiCheckbox
            id='specificPages'
            label='Specific Pages'
            checked={targetSpecificPages}
            onChange={() => {
              setTargetSpecificPages(!targetSpecificPages)
            }}
            disabled={targetEntireSite || !currentAccount?.hasVisits}
          />
        </React.Fragment>
      </EuiFormRow>
      {targetSpecificPages && (
        <React.Fragment>
          <EuiFormRow label={"Add the url's of the pages you would like to target"} helpText={!!websiteUrl && websiteUrl.length > 0 && !websiteUrlIsValid ? 'Url must begin with https://' : undefined}>
            <EuiFieldText
              placeholder='https://'
              value={websiteUrl}
              onChange={e => {
                setWebsiteUrl(e.target.value)
              }}
              isInvalid={!!websiteUrl && websiteUrl.length > 0 && !websiteUrlIsValid}
              append={
                <EuiButton size='s' disabled={!websiteUrlIsValid} onClick={onUrlAdded}>
                  Add
                </EuiButton>
              }
            />
          </EuiFormRow>
          <EuiListGroup>
            {websiteUrls.map(url => (
              <EuiListGroupItem
                key={url}
                label={url}
                extraAction={{
                  color: 'text',
                  onClick: () => onUrlRemoved(url),
                  iconType: 'cross',
                  iconSize: 's',
                  'aria-label': 'Favorite link2',
                  alwaysShow: true
                }}
              />
            ))}
          </EuiListGroup>
        </React.Fragment>
      )}
      <EuiSpacer />
      <EuiShowFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='finalize' fill type='submit' isLoading={getCampaignsQuery.isLoading} onClick={onNextClick} isDisabled={!canContinue}>
              Continue
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={returnTargetingChooseStep}>
              Back
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={returnTargetingChooseStep} iconType='arrowLeft' iconSide='left'>
              Back
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='finalize' fill type='submit' isLoading={getCampaignsQuery.isLoading} isDisabled={!canContinue} iconType='arrowRight' iconSide='right' onClick={onNextClick}>
              Continue
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiHideFor>
    </React.Fragment>
  )
}
