import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCard, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiLink, EuiPage, EuiPageBody, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiSelect, EuiSpacer, EuiTitle } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'

import { ApiError, apiPostAccounts } from 'api/adcritterApi'
import { BusinessType } from 'api/interfaces'
import { showSuccessToast, updateAccountState } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { BusinessTypeFinder } from 'components/finders/BusinessTypeFinder'
import history from 'services/HistoryService'

interface FormValues {
  businessTypeId: string
  name: string
  streetAddress: string
  city: string
  state: string
  zip: string
  country: string
  website: string
}

export const CreatePage: React.FC = () => {
  const dispatch = useDispatch()
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const [businessType, setBusinessType] = useState<BusinessType | null>(null)

  const accountSchema = Yup.object().shape({
    businessTypeId: Yup.string(),
    name: Yup.string().max(128).required('Please enter the company name'),
    streetAddress: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zip: Yup.string(),
    website: Yup.string().url('Please enter a valid URL')
  })

  let formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      businessTypeId: '',
      name: '',
      streetAddress: '',
      city: '',
      state: '',
      country: 'usa',
      zip: '',
      website: ''
    } as FormValues,
    validationSchema: accountSchema,
    onSubmit: (values: FormValues) => {
      apiPostAccounts(values.businessTypeId, values.name, values.streetAddress, values.city, values.state, values.zip, values.country, values.website)
        .then(response => {
          formik.setStatus(null)
          dispatch(updateAccountState(response.data))
          dispatch(showSuccessToast('Created Account'))
          history.push('/dashboard')
          formik.setSubmitting(false)
        })
        .catch(response => {
          formik.setSubmitting(false)
          response.errors.forEach(function (e: ApiError) {
            formik.setFieldError(e.name, e.message)
          })
        })
    }
  })

  const chooseBusinessType = (businessType: BusinessType | null) => {
    if (businessType) {
      setBusinessType(businessType)
      formik.setFieldValue('businessTypeId', businessType.id, true)
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Create Account</title>
      </Helmet>
      <EuiPage className='euiPage--platform'>
        <EuiPageBody>
          <EuiPageHeader>
            <EuiPageHeaderSection>
              <EuiTitle>
                <h1>Create Account</h1>
              </EuiTitle>
            </EuiPageHeaderSection>
          </EuiPageHeader>
          <EuiPanel grow={false}>
            <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
              <EuiFormRow label='Business Type' fullWidth isInvalid={formik.touched.businessTypeId && !!formik.errors.businessTypeId} error={formik.errors.businessTypeId}>
                <React.Fragment>
                  {businessType && (
                    <React.Fragment>
                      <EuiCard
                        display='primary'
                        layout='horizontal'
                        paddingSize='s'
                        titleSize='xs'
                        icon={
                          <div>
                            <img alt='' src={businessType.imageUrl} />
                          </div>
                        }
                        title={businessType.name}
                        description={businessType.businessTypeCategories.join(', ')}
                      />
                      <EuiSpacer size='s' />
                      <EuiLink color='success' onClick={() => setBusinessType(null)}>
                        Clear selected business type
                      </EuiLink>
                    </React.Fragment>
                  )}
                  {!businessType && <BusinessTypeFinder onBusinessTypeClicked={chooseBusinessType} isInvalid={formik.touched.businessTypeId && !!formik.errors.businessTypeId} />}
                </React.Fragment>
              </EuiFormRow>

              <EuiFormRow label='Business Name' fullWidth isInvalid={formik.touched.name && !!formik.errors.name} error={formik.errors.name}>
                <EuiFieldText name='name' value={formik.values.name} onChange={formik.handleChange} placeholder='' fullWidth isInvalid={formik.touched.name && !!formik.errors.name} />
              </EuiFormRow>
              <EuiSpacer />

              <EuiFormRow label='Street Address' fullWidth isInvalid={formik.touched.streetAddress && !!formik.errors.streetAddress} error={formik.errors.streetAddress}>
                <EuiFieldText name='streetAddress' value={formik.values.streetAddress} onChange={formik.handleChange} placeholder='' fullWidth isInvalid={formik.touched.streetAddress && !!formik.errors.streetAddress} />
              </EuiFormRow>
              <EuiSpacer size='s' />

              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow label='City' fullWidth isInvalid={formik.touched.city && !!formik.errors.city} error={formik.errors.city}>
                    <EuiFieldText name='city' value={formik.values.city} onChange={formik.handleChange} placeholder='' fullWidth isInvalid={formik.touched.city && !!formik.errors.city} />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label='State' fullWidth isInvalid={formik.touched.state && !!formik.errors.state} error={formik.errors.state}>
                    <EuiSelect
                      name='state'
                      isLoading={dictionary.isLoadingStates}
                      options={dictionary.states.map(
                        s =>
                          ({
                            value: s.code,
                            label: s.name
                          } as EuiSelectOption)
                      )}
                      value={formik.values.state}
                      onChange={value => formik.setFieldValue('state', value.target.value, true)}
                      fullWidth
                      isInvalid={formik.touched.state && !!formik.errors.state}
                      hasNoInitialSelection={true}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label='Zip' fullWidth isInvalid={formik.touched.zip && !!formik.errors.zip} error={formik.errors.zip}>
                    <EuiFieldText name='zip' value={formik.values.zip} onChange={formik.handleChange} placeholder='' fullWidth isInvalid={formik.touched.zip && !!formik.errors.zip} />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiSpacer />

              <EuiFormRow label='Website' fullWidth isInvalid={formik.touched.website && !!formik.errors.website} error={formik.errors.website}>
                <EuiFieldText name='website' value={formik.values.website} onChange={formik.handleChange} placeholder='' fullWidth isInvalid={formik.touched.website && !!formik.errors.website} />
              </EuiFormRow>
              <EuiSpacer />

              <EuiButton id='create' fill type='submit' isLoading={formik.isSubmitting}>
                Create
              </EuiButton>
            </EuiForm>
          </EuiPanel>
        </EuiPageBody>
      </EuiPage>
    </React.Fragment>
  )
}

export default CreatePage
