import { AnalyticsResult, DashboardFilter } from 'api/interfaces'
import { CampaignSpendReport, InvoiceDocument, MonthLedger } from 'api/interfaces/InvoiceDocument'
import { BaseQuery } from 'api/rtkQueryApi/platform/axiosBaseQuery'
import { defaultTransformFn, platformApi } from 'api/rtkQueryApi/platform/platformApi'

interface IGetAnalyticsQuery extends BaseQuery {
  start: string
  end: string
  campaignIds: string[]
  adIds: string[]
  label: string
}

interface IPostTouchDashboardMutation {
  isAgency: boolean
  clck?: string | null
}

const dashboardTags = platformApi.enhanceEndpoints({ addTagTypes: ['DashboardFilter', 'DashboardAnalytics', 'InvoiceDocument', 'CampaignSpendReport', 'MonthLedgerAll', 'MonthLedger'] })

export const dashboardApi = dashboardTags.injectEndpoints({
  endpoints: build => {
    return {
      getDashboardFilters: build.query<DashboardFilter, BaseQuery>({
        query: ({ accountId, isAgency }) => ({
          url: `analytics/${accountId}/filters`,
          method: 'GET',
          isContext: true,
          isAgency
        }),
        transformResponse: defaultTransformFn,
        providesTags: (result, error, { accountId }) => [{ type: 'DashboardFilter', id: accountId }]
      }),
      getDashboardAnalytics: build.query<AnalyticsResult, IGetAnalyticsQuery>({
        query: ({ accountId, start, end, campaignIds, adIds, label, isAgency }) => ({
          url: `analytics/${accountId}`,
          method: 'POST',
          data: {
            start: start,
            end: end,
            campaignIds,
            adIds,
            label
          },
          isContext: true,
          isAgency
        }),
        transformResponse: defaultTransformFn
      }),
      postTouchDashboard: build.mutation<boolean, IPostTouchDashboardMutation>({
        query: ({ isAgency, clck }) => ({
          url: `organizations/touchDashboard?clck=${clck}`,
          method: 'POST',
          isContext: true,
          isAgency
        }),
        transformResponse: defaultTransformFn
      }),
      getInvoiceDoc: build.query<InvoiceDocument, void>({
        query: () => {
          return {
            url: `invoiceDocument`,
            method: 'GET',
            isContext: true
          }
        },
        transformResponse: defaultTransformFn,
        providesTags: () => [{ type: 'InvoiceDocument' }]
      }),
      getCampaignSpendReport: build.query<CampaignSpendReport[], void>({
        query: () => {
          return {
            url: `invoiceDocument/campaignSpendReport`,
            method: 'GET',
            isContext: true
          }
        },
        transformResponse: defaultTransformFn,
        providesTags: () => [{ type: 'CampaignSpendReport' }]
      }),
      getMonthlyLedgerAll: build.query<MonthLedger[], void>({
        query: () => {
          return {
            url: `invoiceDocument/monthLedgerAll`,
            method: 'GET',
            isContext: true
          }
        },
        transformResponse: defaultTransformFn,
        providesTags: () => [{ type: 'MonthLedgerAll' }]
      }),
      getMonthlyLedger: build.query<MonthLedger, { month: number; year: number }>({
        query: ({ month, year }) => {
          return {
            url: `invoiceDocument/monthLedger?month=${month}&year=${year}`,
            method: 'GET',
            isContext: true
          }
        },
        transformResponse: defaultTransformFn,
        providesTags: () => [{ type: 'MonthLedger' }]
      })
    }
  },
  overrideExisting: false
})

export const { useGetDashboardFiltersQuery, useGetDashboardAnalyticsQuery, usePostTouchDashboardMutation, useGetInvoiceDocQuery, useGetCampaignSpendReportQuery, useGetMonthlyLedgerAllQuery, useGetMonthlyLedgerQuery } = dashboardApi
