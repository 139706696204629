import moment from 'moment'

import { CampaignSlot, Bundle, BundleType, BundleStatus, BundleActivateRequest, BundleUpdateRequest, BundleSaveRequest, BundleSaveForLaterRequest } from 'api/interfaces'
import { SelfServicePackage, SelfServicePackageReserveRequest, SelfServicePackageStatus } from 'api/interfaces/SelfServicePackage'
import { campaignsApi } from 'api/rtkQueryApi/platform/campaignsApi'
import { defaultTransformFn } from 'api/rtkQueryApi/platform/platformApi'
import { CampaignType } from 'utils/CampaignType'

const bundleTags = campaignsApi.enhanceEndpoints({ addTagTypes: ['CampaignSlotsForAccount', 'OrganizationBundles', 'ReservedSelfServePackages', 'NonReservedSelfServePackages'] })

export const bundlesApi = bundleTags.injectEndpoints({
  endpoints: build => {
    return {
      getAvailableCampaignSlotsForAccount: build.query<CampaignSlot[], { organizationId: string; campaignType: CampaignType; accountId: string }>({
        query: ({ organizationId, campaignType, accountId }) => ({
          url: `bundles/${organizationId}/slots/${campaignType}?accountId=${accountId}`,
          method: 'GET',
          isContext: true,
          isAgency: false
        }),
        keepUnusedDataFor: 300,
        transformResponse: defaultTransformFn,
        providesTags: (result, error, { organizationId, campaignType, accountId }) => [
          {
            type: 'CampaignSlotsForAccount',
            id: `${organizationId}, ${campaignType}, ${accountId}`
          }
        ]
      }),
      getOrganizationBundles: build.query<Bundle[], void>({
        query: () => ({
          url: `organizations/bundles`,
          method: 'GET',
          isContext: true,
          isAgency: false
        }),
        transformResponse: defaultTransformFn,
        providesTags: () => [
          {
            type: 'OrganizationBundles'
          }
        ]
      }),
      getReservedSelfServicePackages: build.query<SelfServicePackage[], void>({
        query: () => ({
          url: `organizations/bundles`,
          method: 'GET',
          isContext: true,
          isAgency: false
        }),
        transformResponse: (response: { data: Bundle[] }) => {
          const selfServicePackages = response.data.filter(b => b.bundleType === BundleType.SelfServicePackage && b.bundleStatus === BundleStatus.New && !b.accountId).map(bundle => bundle as SelfServicePackage)
          return selfServicePackages.filter(p => p.selfServicePackageStatus === SelfServicePackageStatus.Reserved && (!p.expirationDate || moment(p.expirationDate).add(30, 'days').isAfter(moment())))
        },
        providesTags: () => [
          {
            type: 'ReservedSelfServePackages'
          }
        ]
      }),
      getNonReservedSelfServicePackages: build.query<SelfServicePackage[], void>({
        query: () => ({
          url: `organizations/bundles`,
          method: 'GET',
          isContext: true,
          isAgency: false
        }),
        transformResponse: (response: { data: Bundle[] }) => {
          const selfServicePackages = response.data.filter(b => b.bundleType === BundleType.SelfServicePackage).map(bundle => bundle as SelfServicePackage)
          return selfServicePackages.filter(p => p.selfServicePackageStatus !== SelfServicePackageStatus.Reserved)
        },
        providesTags: () => [
          {
            type: 'NonReservedSelfServePackages'
          }
        ]
      }),
      assignSelfServicePackage: build.mutation<void, { bundleId: string; accountId: string }>({
        query: ({ bundleId, accountId }) => ({
          url: `bundles/${bundleId}/assignAccount`,
          method: 'POST',
          data: {
            accountId: accountId
          },
          isContext: true
        }),
        invalidatesTags: (result, error, { accountId }) => ['ReservedSelfServePackages', 'NonReservedSelfServePackages', { type: 'Campaigns', id: accountId }]
      }),
      assignCampaignToCampaignSlot: build.mutation<void, { accountId: string; campaignId: string; campaignSlotId: string }>({
        query: ({ accountId, campaignId, campaignSlotId }) => ({
          url: `accounts/${accountId}/campaigns/${campaignId}/activate`,
          method: 'POST',
          data: {
            campaignSlotId
          },
          isContext: true
        }),
        invalidatesTags: (result, error, { accountId }) => ['NonReservedSelfServePackages', 'OrganizationBundles', { type: 'Campaigns', id: accountId }]
      }),
      activateBundle: build.mutation<void, BundleActivateRequest>({
        query: request => ({
          url: `bundles/${request.bundleId}/activate`,
          method: 'POST',
          data: request,
          isContext: true
        }),
        invalidatesTags: (result, error, { accountId }) => ['ReservedSelfServePackages', 'NonReservedSelfServePackages', { type: 'Campaigns', id: accountId }]
      }),
      saveBundle: build.mutation<void, BundleSaveRequest>({
        query: request => ({
          url: `bundles/${request.bundleId}/save`,
          method: 'POST',
          data: request,
          isContext: true
        }),
        invalidatesTags: (result, error, { accountId }) => ['ReservedSelfServePackages', 'NonReservedSelfServePackages', { type: 'Campaigns', id: accountId }]
      }),
      saveForLaterBundle: build.mutation<void, BundleSaveForLaterRequest>({
        query: request => ({
          url: `bundles/${request.bundleId}/saveForLater`,
          method: 'POST',
          data: request,
          isContext: true
        }),
        invalidatesTags: (result, error, { accountId }) => ['ReservedSelfServePackages', 'NonReservedSelfServePackages', { type: 'Campaigns', id: accountId }]
      }),
      reserveSelfServicePackage: build.mutation<void, SelfServicePackageReserveRequest>({
        query: request => ({
          url: `bundles/${request.bundleId}/reserve`,
          method: 'POST',
          data: request,
          isContext: true
        }),
        invalidatesTags: (result, error, { accountId }) => ['ReservedSelfServePackages', 'NonReservedSelfServePackages', { type: 'Campaigns', id: accountId }]
      }),
      updateBundle: build.mutation<void, BundleUpdateRequest>({
        query: request => ({
          url: `bundles/${request.bundleId}`,
          method: 'PUT',
          data: request,
          isContext: true
        }),
        invalidatesTags: (result, error, { accountId }) => ['ReservedSelfServePackages', 'NonReservedSelfServePackages', { type: 'Campaigns', id: accountId }]
      })
    }
  },
  overrideExisting: false
})

export const { useActivateBundleMutation, useAssignCampaignToCampaignSlotMutation, useGetAvailableCampaignSlotsForAccountQuery, useGetOrganizationBundlesQuery, useGetReservedSelfServicePackagesQuery, useGetNonReservedSelfServicePackagesQuery, useAssignSelfServicePackageMutation, useUpdateBundleMutation, useSaveBundleMutation, useSaveForLaterBundleMutation } = bundlesApi
