import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useGetAuthConfigQuery } from 'api/rtkQueryApi/platform/authApi'
import { RootState } from 'app/rootReducer'
import { VENDASTA_ACCOUNT_ID } from 'features/vendasta/login/useVendastaSSO'

export const useVendastaRenderNavbar = (isVendastaAuthenticatedDone: boolean) => {
  const { isVendastaUser, vendastaNavBarUrl } = useSelector((state: RootState) => state.app)
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const [hasRenderedThirdPartyNav, setHasRenderedThirdPartyNav] = useState(false)
  const authConfigResults = useGetAuthConfigQuery(undefined, {
    skip: hasRenderedThirdPartyNav || !isVendastaUser || !vendastaNavBarUrl || !isVendastaAuthenticatedDone
  })

  useEffect(() => {
    const isAuthConfigReady = !authConfigResults.isLoading && !authConfigResults.isFetching && authConfigResults.data
    if (hasRenderedThirdPartyNav || !isVendastaUser || !vendastaNavBarUrl || !isVendastaAuthenticatedDone || !isAuthConfigReady) {
      return
    }

    const vendastaAccountId = query.get('account_id')
    const savedVendastaAccountId = localStorage.getItem(VENDASTA_ACCOUNT_ID)

    if (!vendastaAccountId && !savedVendastaAccountId) {
      return
    }

    const script = document.createElement('script')

    script.src = '//www.cdnstyles.com/static/product_navbar/v1/product_navbar.js'
    script.setAttribute('data-url', vendastaNavBarUrl)
    script.setAttribute('data-account-id', vendastaAccountId ?? savedVendastaAccountId ?? '')
    script.setAttribute('data-app-id', authConfigResults.data?.strawberryFieldMarketPlaceAppId ?? '')
    script.setAttribute('target-element-class', '87537b0e-802e-4b5c-bab0-265f0a68be24')

    document.body.appendChild(script)

    setHasRenderedThirdPartyNav(true)
  }, [authConfigResults, isVendastaUser, isVendastaAuthenticatedDone])
}
