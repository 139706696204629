import React, { PropsWithChildren, useEffect, useState } from 'react'

import { EuiCallOut, EuiInMemoryTable, EuiSearchBar, EuiSpacer } from '@elastic/eui'
import { EuiInMemoryTableProps } from '@elastic/eui/src/components/basic_table/in_memory_table'
import { EuiSearchBarOnChangeArgs, EuiSearchBarProps } from '@elastic/eui/src/components/search_bar/search_bar'

export type AcEuiInMemoryTableWithSearchProps<T> = {
  search: EuiSearchBarProps
  searchFields?: string[]
  queryError?: Error | null
} & EuiInMemoryTableProps<T>

export function AcEuiInMemoryTableWithSearch<T>({ search, searchFields, queryError, ...props }: PropsWithChildren<AcEuiInMemoryTableWithSearchProps<T>>) {
  const [queryText, setQueryText] = useState('')
  const [error, setError] = useState<Error | null>(null)
  const [queriedItems, setQueriedItems] = useState(props.items)

  const onChange = ({ queryText, error }: EuiSearchBarOnChangeArgs) => {
    if (error) {
      setError(error)
    } else {
      setError(null)
      setQueryText(queryText)
    }
  }

  useEffect(() => {
    if (search.onChange || !searchFields) {
      return
    }

    setQueriedItems(
      EuiSearchBar.Query.execute(queryText, props.items, {
        defaultFields: searchFields
      })
    )
  }, [search, searchFields, queryText, props.items])

  const RenderContent = () => {
    const err = queryError ?? error

    if (err) {
      return (
        <>
          <EuiCallOut color='danger' title={`Invalid search: ${err.message}`} />
          <EuiSpacer size='l' />
        </>
      )
    }

    if (search.onChange || !searchFields) {
      return <EuiInMemoryTable {...props} />
    }

    return <EuiInMemoryTable {...props} items={queriedItems} />
  }

  return (
    <>
      <EuiSearchBar {...search} onChange={search.onChange ?? onChange} />
      <EuiSpacer size='l' />
      <RenderContent />
    </>
  )
}
