import moment, { Moment } from 'moment'
import React, { useEffect } from 'react'

import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiSelect } from '@elastic/eui'

export interface IAcMonthPicker {
  selectedMonth: Month
  selectedYear: number
  onChange: (month: Month, year: number) => void
  maxDate?: Moment
  minDate?: Moment
  style?: React.CSSProperties
}

export enum Month {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December'
}
export const GetMonthNumber = (month: Month) => {
  switch (month) {
    case Month.January:
      return 0
    case Month.February:
      return 1
    case Month.March:
      return 2
    case Month.April:
      return 3
    case Month.May:
      return 4
    case Month.June:
      return 5
    case Month.July:
      return 6
    case Month.August:
      return 7
    case Month.September:
      return 8
    case Month.October:
      return 9
    case Month.November:
      return 10
    case Month.December:
      return 11
  }
  return 0
}

export const GetMonthByNumber = (month: number) => {
  switch (month) {
    case 0:
      return Month.January
    case 1:
      return Month.February
    case 2:
      return Month.March
    case 3:
      return Month.April
    case 4:
      return Month.May
    case 5:
      return Month.June
    case 6:
      return Month.July
    case 7:
      return Month.August
    case 8:
      return Month.September
    case 9:
      return Month.October
    case 10:
      return Month.November
    case 11:
      return Month.December
  }
  return Month.January
}

export const AcMonthPicker: React.FC<IAcMonthPicker> = props => {
  const [minDate, setMinDate] = React.useState<Moment>(props.minDate ? moment(props.minDate) : moment().add(-1, 'year'))
  const [maxDate, setMaxDate] = React.useState<Moment>(props.maxDate ? moment(props.maxDate) : moment())

  useEffect(() => {
    if (props.minDate) {
      setMinDate(props.minDate ? moment(props.minDate) : moment().add(-1, 'year'))
    }
  }, [props.minDate])

  useEffect(() => {
    if (props.maxDate) {
      setMaxDate(props.maxDate ? moment(props.maxDate) : moment())
    }
  }, [props.maxDate])

  useEffect(() => {
    if (props.selectedYear < minDate.year()) {
      props.onChange(props.selectedMonth, minDate.year())
    } else if (props.selectedYear > maxDate.year()) {
      props.onChange(props.selectedMonth, maxDate.year())
    } else if (GetMonthNumber(props.selectedMonth) < minDate.month() && props.selectedYear == minDate.year()) {
      props.onChange(GetMonthByNumber(minDate.month()), minDate.year())
    } else if (GetMonthNumber(props.selectedMonth) > maxDate.month() && props.selectedYear == maxDate.year()) {
      props.onChange(GetMonthByNumber(maxDate.month()), maxDate.year())
    }
  }, [props.selectedMonth, props.selectedYear])

  const minYear = minDate.year()
  const maxYear = maxDate.year()

  const yearOptions = Array.from({ length: maxYear + 1 - minYear }, (_, i) => minYear + i).map(year => ({ value: year, text: year.toString(), disabled: year < minYear || year > maxYear }))
  const monthOptions = Object.values(Month).map(month => ({ value: month, text: month, disabled: (props.selectedYear == minYear && GetMonthNumber(month) < minDate.month()) || (props.selectedYear == maxYear && GetMonthNumber(month) > maxDate.month()) }))

  const onNextMonthClick = () => {
    const monthIndex = Object.values(Month).indexOf(props.selectedMonth)
    const nextMonth = Object.values(Month)[(monthIndex + 1) % Object.values(Month).length]
    const nextYear = nextMonth === Month.January ? props.selectedYear + 1 : props.selectedYear

    props.onChange(nextMonth, nextYear)
  }

  const onPreviousMonthClick = () => {
    const monthIndex = Object.values(Month).indexOf(props.selectedMonth)
    const previousMonth = Object.values(Month)[(monthIndex - 1 + Object.values(Month).length) % Object.values(Month).length]
    const previousYear = previousMonth === Month.December ? props.selectedYear - 1 : props.selectedYear

    props.onChange(previousMonth, previousYear)
  }

  const onMonthSelect = (month: Month) => {
    props.onChange(month, props.selectedYear)
  }

  const onYearSelect = (year: number) => {
    props.onChange(props.selectedMonth, year)
  }
  return (
    <EuiFlexGroup gutterSize={'s'} style={props.style}>
      <EuiFlexItem grow={false}>
        <EuiButtonIcon iconType='arrowLeft' onClick={onPreviousMonthClick} style={{ marginTop: 4 }} aria-label={'minusMonth'} disabled={props.selectedYear < minYear || (GetMonthNumber(props.selectedMonth) <= minDate.month() && props.selectedYear == minYear)} />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiSelect style={{ width: 120 }} compressed options={monthOptions} value={props.selectedMonth} onChange={e => onMonthSelect(e.target.value as Month)} />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiSelect
          style={{ width: 80 }}
          compressed
          options={yearOptions}
          value={props.selectedYear}
          onChange={e => {
            const year = parseInt(e.target.value)
            onYearSelect(year)
          }}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonIcon iconType='arrowRight' onClick={onNextMonthClick} style={{ marginTop: 4 }} aria-label={'addMonth'} disabled={props.selectedYear > maxYear || (GetMonthNumber(props.selectedMonth) >= maxDate.month() && props.selectedYear == maxYear)} />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
