import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Axis, Chart, LIGHT_THEME, LineSeries, PartialTheme, Position, ScaleType, Settings } from '@elastic/charts'
import { CurveType } from '@elastic/charts/dist/utils/curves'
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiSwitch, EuiTitle, EuiToolTip, EuiHideFor, EuiShowFor, EuiText } from '@elastic/eui'
import { EuiBreakpointSize } from '@elastic/eui/src/services/breakpoint'

import { Analytics } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import { initializeCampaignCreate } from 'features/builders/CampaignCreateSlice'
import { CreateCampaignStorageManager } from 'features/builders/createCampaign/CreateCampaignStorageManager'
import history from 'services/HistoryService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import { toggleViews, toggleClicks, toggleCpc, toggleCpm, toggleCtr, toggleSpend } from './overviewSlice'

interface InternetOverviewChartProps {
  showCreate: boolean
  internetCampaigns: Analytics[]
}

const InternetOverviewChart: React.FC<InternetOverviewChartProps> = ({ showCreate, internetCampaigns }) => {
  const dispatch = useDispatch()
  const { isVendastaUser } = useSelector((state: RootState) => state.app)
  const overview = useSelector((state: RootState) => state.overview)
  const whiteLabel = useWhiteLabel()
  const [hideFor, setHideFor] = useState<EuiBreakpointSize[] | 'all' | 'none'>('none')

  useEffect(() => {
    if (showCreate) {
      setHideFor(['xs', 's'])
    }
  }, [showCreate])

  const startInternet = () => {
    dispatch(initializeCampaignCreate())
    CreateCampaignStorageManager.DeleteFromLocalStorage()
    history.push('/build')
  }

  const theme: PartialTheme = {
    lineSeriesStyle: {
      line: {
        strokeWidth: 3
      },
      point: { radius: 5 }
    }
  }

  return (
    <React.Fragment>
      <EuiShowFor sizes={['xs', 's']}>
        {!isVendastaUser && showCreate && (
          <EuiButton onClick={startInternet} color='primary' fill>
            Create Internet Campaign
          </EuiButton>
        )}
      </EuiShowFor>
      <EuiHideFor sizes={hideFor}>
        <EuiPanel grow={false} style={{ position: 'relative' }}>
          <EuiHideFor sizes={['xs', 's']}>
            {showCreate && (
              <div
                style={{
                  background: 'rgba(0,0,0,0.6)',
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  zIndex: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '6px',
                  marginLeft: -16
                }}>
                {isVendastaUser ? (
                  <EuiTitle size='m'>
                    <EuiText color='white'>No campaigns available</EuiText>
                  </EuiTitle>
                ) : (
                  <EuiButton onClick={startInternet} color='primary' fill>
                    Create Internet Campaign
                  </EuiButton>
                )}
              </div>
            )}
          </EuiHideFor>
          <EuiTitle size='xs'>
            <h4 style={{ whiteSpace: 'nowrap' }}>Internet Campaigns</h4>
          </EuiTitle>
          <EuiSpacer size='s' />
          <EuiHideFor sizes={['xs', 's']}>
            <EuiFlexGroup responsive={false}>
              <EuiFlexItem grow={false} style={{ borderBottom: '2px solid #f49342', paddingBottom: '3px' }}>
                <EuiSwitch label={'Views'} checked={overview.showViews} onChange={() => dispatch(toggleViews())} compressed={true} />
              </EuiFlexItem>
              <EuiFlexItem grow={false} style={{ borderBottom: '2px solid #2B70F7', paddingBottom: '3px' }}>
                <EuiSwitch label='Clicks' checked={overview.showClicks} onChange={() => dispatch(toggleClicks())} compressed={true} />
              </EuiFlexItem>
              {!whiteLabel?.hideAllSpend() && (
                <EuiFlexItem grow={false} style={{ borderBottom: '2px solid #9170B8', paddingBottom: '3px' }}>
                  <EuiSwitch label='Spend' checked={overview.showSpend} onChange={() => dispatch(toggleSpend())} compressed={true} />
                </EuiFlexItem>
              )}
              <EuiFlexItem grow={false} style={{ borderBottom: '2px solid #54B399', paddingBottom: '3px' }}>
                <EuiToolTip position='top' content='Click-through rate'>
                  <EuiSwitch label='CTR' checked={overview.showCtr} onChange={() => dispatch(toggleCtr())} compressed={true} />
                </EuiToolTip>
              </EuiFlexItem>
              {!whiteLabel?.hideAllSpend() && (
                <EuiFlexItem grow={false} style={{ borderBottom: '2px solid #AA6556', paddingBottom: '3px' }}>
                  <EuiToolTip position='top' content='Cost per click'>
                    <EuiSwitch label='CPC' checked={overview.showCpc} onChange={() => dispatch(toggleCpc())} compressed={true} />
                  </EuiToolTip>
                </EuiFlexItem>
              )}
              {!whiteLabel?.hideAllSpend() && (
                <EuiFlexItem grow={false} style={{ borderBottom: '2px solid #D6BF57' }}>
                  <EuiToolTip position='top' content='Cost per thousand views'>
                    <EuiSwitch label='CPM' checked={overview.showCpm} onChange={() => dispatch(toggleCpm())} compressed={true} />
                  </EuiToolTip>
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiHideFor>
          <Chart size={{ height: 230 }}>
            <Settings theme={theme} baseTheme={LIGHT_THEME} />
            {overview.showViews && <LineSeries data={internetCampaigns.map(a => ({ x: a.forDateAsString, y: a.impressions }))} id='Views' xAccessor='x' yAccessors={['y']} xScaleType={ScaleType.Ordinal} yScaleType={ScaleType.Linear} groupId='views' curve={CurveType.CURVE_MONOTONE_X} color={['#f49342']} />}
            {overview.showClicks && <LineSeries data={internetCampaigns.map(a => ({ x: a.forDateAsString, y: a.clicks }))} id='Clicks' xAccessor='x' yAccessors={['y']} xScaleType={ScaleType.Ordinal} yScaleType={ScaleType.Linear} groupId='clicks' curve={CurveType.CURVE_MONOTONE_X} color={['#2B70F7']} />}
            {!whiteLabel?.hideAllSpend() && overview.showSpend && <LineSeries data={internetCampaigns.map(a => ({ x: a.forDateAsString, y: a.spend }))} id='Spend' xAccessor='x' yAccessors={['y']} xScaleType={ScaleType.Ordinal} yScaleType={ScaleType.Linear} groupId='spend' curve={CurveType.CURVE_MONOTONE_X} color={['#9170B8']} />}
            {overview.showCtr && (
              <LineSeries
                data={internetCampaigns.map(a => ({
                  x: a.forDateAsString,
                  y: a.impressions === 0 ? 0 : (a.clicks / a.impressions) * 100
                }))}
                id='CTR'
                xAccessor='x'
                yAccessors={['y']}
                xScaleType={ScaleType.Ordinal}
                yScaleType={ScaleType.Linear}
                groupId='ctr'
                curve={CurveType.CURVE_MONOTONE_X}
                color={['#54B399']}
              />
            )}
            {!whiteLabel?.hideAllSpend() && overview.showCpm && (
              <LineSeries
                data={internetCampaigns.map(a => ({
                  x: a.forDateAsString,
                  y: a.impressions === 0 ? 0 : (a.spend / a.impressions) * 1000
                }))}
                id='CPM'
                xAccessor='x'
                yAccessors={['y']}
                xScaleType={ScaleType.Ordinal}
                yScaleType={ScaleType.Linear}
                groupId='cpm'
                curve={CurveType.CURVE_MONOTONE_X}
                color={['#D6BF57']}
              />
            )}
            {overview.showCpc && <LineSeries data={internetCampaigns.map(a => ({ x: a.forDateAsString, y: a.clicks === 0 ? 0 : a.spend / a.clicks }))} id='CPC' xAccessor='x' yAccessors={['y']} xScaleType={ScaleType.Ordinal} yScaleType={ScaleType.Linear} groupId='cpc' curve={CurveType.CURVE_MONOTONE_X} color={['#AA6556']} />}
            <Axis id='views' hide={!overview.showViews} title='Views' position={overview.positionViews} tickFormat={d => `${Number(d).toLocaleString()}`} groupId='views' />
            <Axis id='clicks' hide={!overview.showClicks} title='Clicks' position={overview.positionClicks} tickFormat={d => `${Number(d).toLocaleString()}`} groupId='clicks' />
            {!whiteLabel?.hideAllSpend() && <Axis id='spend' hide={!overview.showSpend} title='Spend' position={overview.positionSpend} tickFormat={d => `${Number(d).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`} groupId='spend' />}
            <Axis id='ctr' hide={!overview.showCtr} title='CTR' position={overview.positionCtr} tickFormat={d => `${Number(d).toFixed(2)}%`} groupId='ctr' />
            {!whiteLabel?.hideAllSpend() && <Axis id='cpm' hide={!overview.showCpm} title='CPM' position={overview.positionCpm} tickFormat={d => `${Number(d).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`} groupId='cpm' />}
            {!whiteLabel?.hideAllSpend() && <Axis id='cpc' hide={!overview.showCpc} title='CPC' position={overview.positionCpc} tickFormat={d => `${Number(d).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`} groupId='cpc' />}
            <Axis id='bottom' position={Position.Bottom} />
          </Chart>
        </EuiPanel>
      </EuiHideFor>
    </React.Fragment>
  )
}

export default InternetOverviewChart
