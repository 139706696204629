import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCard, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHideFor, EuiLink, EuiShowFor, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { apiGetBusinessType, apiPutAccountBusinessType } from 'api/adcritterApi'
import { BusinessType } from 'api/interfaces'
import { updateAccountState } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { BusinessTypeFinder } from 'components/finders/BusinessTypeFinder'
import history from 'services/HistoryService'

import { setBusinessType, setSuggest, Suggest, fetchBillboards } from './builderBillboardRetailSlice'

const SuggestStep: React.FC = () => {
  const dispatch = useDispatch()
  const builder = useSelector((state: RootState) => state.builderBillboardRetail)
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [chosenBusinessType, setChosenBusinessType] = useState<BusinessType | null>(null)
  const [businessTypeError, setBusinessTypeError] = useState(false)
  const [isLoadingBusinessType, setIsLoadingBusinessType] = useState(false)
  const [initialValues, setInitialValues] = useState<Suggest>({
    cityName: '',
    companyName: ''
  })

  useEffect(() => {
    if (builder.suggest) {
      setInitialValues(builder.suggest)
    } else if (currentAccount) {
      let initial = { ...initialValues }
      initial.companyName = currentAccount.name
      if (currentAccount.city && currentAccount.city !== '') {
        initial.cityName = currentAccount.city
        setInitialValues(initial)
      }
      setInitialValues(initial)
      if (currentAccount.businessTypeId) {
        setIsLoadingBusinessType(true)
        apiGetBusinessType(currentAccount.businessTypeId).then(result => {
          dispatch(setBusinessType(result.data))
          setIsLoadingBusinessType(false)
        })
      } else {
        setChosenBusinessType(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [builder.suggest, currentAccount])

  useEffect(() => {
    if (builder.businessType) {
      setChosenBusinessType(builder.businessType)
    }
  }, [builder.businessType])

  useEffect(() => {
    if (currentAccount && chosenBusinessType) {
      dispatch(fetchBillboards(currentAccount.id, chosenBusinessType.id, currentAccount.name, currentAccount.city))
    }
  }, [dispatch, currentAccount, chosenBusinessType])

  const suggestSchema = Yup.object().shape({
    city: Yup.string(),
    companyName: Yup.string().required('Please enter your Company Name')
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: suggestSchema,
    onSubmit: (values: Suggest) => {
      if (!chosenBusinessType) {
        setBusinessTypeError(true)
        formik.setSubmitting(false)
        return
      }
      dispatch(setBusinessType(chosenBusinessType))
      dispatch(setSuggest(values))
      if (!currentAccount!.businessTypeId) apiPutAccountBusinessType(currentAccount!.id, chosenBusinessType!.id).then(result => dispatch(updateAccountState(result.data)))
      history.push('/build/billboards/public/suggestions')
    }
  })

  const goBack = () => {
    history.push('/build/billboards/public/start')
  }

  const chooseBusinessType = (businessType: BusinessType | null) => {
    if (businessType) {
      setChosenBusinessType(businessType)
      setBusinessTypeError(false)
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Billboards</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Billboards</h3>
      </EuiTitle>
      <EuiText size='s'>
        <p>First, tell us about your business</p>
      </EuiText>
      <EuiSpacer />
      <div id='bizTypeField'>
        <EuiFormRow label='Business Type' fullWidth isInvalid={businessTypeError}>
          <React.Fragment>
            {chosenBusinessType && (
              <React.Fragment>
                <EuiCard
                  display='primary'
                  layout='horizontal'
                  paddingSize='s'
                  titleSize='xs'
                  icon={
                    <div>
                      <img alt='' src={chosenBusinessType.imageUrl} />
                    </div>
                  }
                  title={chosenBusinessType.name}
                  description={chosenBusinessType.businessTypeCategories.join(', ')}
                />
                <EuiSpacer size='s' />
                <EuiLink color='success' onClick={() => setChosenBusinessType(null)}>
                  Clear selected business type
                </EuiLink>
              </React.Fragment>
            )}
            {!chosenBusinessType && <BusinessTypeFinder onBusinessTypeClicked={chooseBusinessType} isInvalid={businessTypeError} />}
          </React.Fragment>
        </EuiFormRow>
        <EuiSpacer size='s' />
      </div>
      <EuiFormRow label='Company Name' fullWidth isInvalid={formik.touched.companyName && !!formik.errors.companyName} error={formik.errors.companyName} helpText='This will be the company name that appears in your ads'>
        <EuiFieldText name='companyName' value={formik.values.companyName} isInvalid={formik.touched.companyName && !!formik.errors.companyName} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth />
      </EuiFormRow>
      <EuiFormRow label='City' fullWidth helpText='Your city name as it should appear in local ads'>
        <EuiFieldText name='city' value={formik.values.cityName} onChange={formik.handleChange} fullWidth />
      </EuiFormRow>
      <EuiSpacer />

      <EuiShowFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='continue' fill type='submit' isLoading={formik.isSubmitting} isDisabled={isLoadingBusinessType} onClick={formik.submitForm}>
              Continue to Choose Ads
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={goBack}>
              Back
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={goBack} iconType='arrowLeft' iconSide='left'>
              Back
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='continue' fill type='submit' isLoading={formik.isSubmitting} isDisabled={isLoadingBusinessType} iconType='arrowRight' iconSide='right' onClick={formik.submitForm}>
              Continue to Choose Ads
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiHideFor>
    </React.Fragment>
  )
}

export default SuggestStep
