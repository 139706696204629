import React, { FC } from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiImage, EuiLoadingChart, EuiText } from '@elastic/eui'

import { Creative } from 'api/interfaces'
import { Insights } from 'api/interfaces/Insights'
import InsightsAdFatigueChart from 'components/insights/Charts/InsightsAdFatigueChart'
import { adFatigueHeadline, grayTextColor } from 'components/insights/InsightsConstants'

export interface InsightsAdFatigueSingleCreativeProps {
  isLoading: boolean
  creative: Creative
  adFatigue?: Insights
}

const InsightsAdFatigueSingleCreative: FC<InsightsAdFatigueSingleCreativeProps> = ({ isLoading, creative, adFatigue }) => {
  const fontWeight = '525'

  if (isLoading) {
    return <EuiLoadingChart size='l' />
  }

  return (
    <EuiFlexGroup>
      {creative.nativeDetails && (
        <React.Fragment>
          <EuiFlexItem grow={false}>
            <EuiImage alt='ad' url={creative.thumbnailUrl || '/82x82.png'} size='fullWidth' />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup direction='column' gutterSize='s' justifyContent='center'>
              <EuiFlexItem grow={false}>
                <EuiText size='xs' color={adFatigueHeadline} style={{ fontWeight }}>
                  {creative.nativeDetails.headline}
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText size='xs' color={grayTextColor} style={{ fontWeight }}>
                  {creative.nativeDetails.body}
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </React.Fragment>
      )}
      {!creative.nativeDetails && creative.displayAdDetails && (
        <React.Fragment>
          <EuiFlexItem>
            <EuiFlexGroup alignItems='center' justifyContent='center'>
              <EuiFlexItem grow={false} style={{ width: 50, minWidth: 50 }}>
                <EuiImage alt='ad' url={creative.displayAdDetails.uploadedUrl || '/82x82.png'} size='fullWidth' />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiText size='m' color={adFatigueHeadline} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', fontWeight }}>
                  {creative.name}
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </React.Fragment>
      )}
      <EuiFlexItem style={{ marginTop: 'auto' }}>
        <InsightsAdFatigueChart adFatigue={adFatigue} />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default InsightsAdFatigueSingleCreative
