import { useEffect, useState } from 'react'

import { Account, SelfServicePackage } from 'api/interfaces'
import { useGetAccountsQuery } from 'api/rtkQueryApi/platform/accountsApi'
import { useActivateBundleMutation, useSaveBundleMutation, useSaveForLaterBundleMutation } from 'api/rtkQueryApi/platform/bundlesApi'
import { paymentMethodsApi } from 'api/rtkQueryApi/platform/paymentMethodsApi'

export const useSelfServicePackageDetailsFlyout = (selfServicePackage: SelfServicePackage, onCloseFlyout: () => void) => {
  const prefetchPaymentMethods = paymentMethodsApi.usePrefetch('getPaymentMethods')
  const { data: accounts } = useGetAccountsQuery({})

  const saveBundleMutation = useSaveBundleMutation()
  const [saveBundle, saveBundleRequest] = saveBundleMutation

  const saveForLaterBundleMutation = useSaveForLaterBundleMutation()
  const [saveForLaterBundle, saveForLaterBundleRequest] = saveForLaterBundleMutation

  const activateBundleMutation = useActivateBundleMutation()
  const [activateBundle, activateBundleRequest] = activateBundleMutation

  const selectedAccountState = useState<Account>()
  const [selectedAccount, setSelectedAccount] = selectedAccountState

  useEffect(() => {
    if (accounts) {
      accounts.forEach(account => {
        prefetchPaymentMethods({ accountId: account.id })
      })

      const account = accounts.find(a => a.id === selfServicePackage.accountId)

      if (account) {
        setSelectedAccount(account)
      }
    }
  }, [accounts])

  useEffect(() => {
    if (activateBundleRequest.isSuccess || activateBundleRequest.isError || saveBundleRequest.isSuccess || saveBundleRequest.isError || saveForLaterBundleRequest.isSuccess || saveForLaterBundleRequest.isError) {
      onCloseFlyout()
    }
  }, [activateBundleRequest, saveBundleRequest, saveForLaterBundleRequest])

  return {
    accounts,
    saveBundleMutation: [saveBundle, saveBundleRequest] as typeof saveBundleMutation,
    saveForLaterBundleMutation: [saveForLaterBundle, saveForLaterBundleRequest] as typeof saveForLaterBundleMutation,
    activateBundleMutation: [activateBundle, activateBundleRequest] as typeof activateBundleMutation,
    selectedAccountState: [selectedAccount, setSelectedAccount] as typeof selectedAccountState
  }
}
