import { useEffect } from 'react'
import { useLocation } from 'react-router'

import { AuthConfig } from 'api/interfaces/AuthConfig'
import { useLazyGetAuthConfigQuery, useLazyVendastaAuthorizeQuery } from 'api/rtkQueryApi/platform/authApi'
import { AuthenticatorSource, useAuthenticationSwitch } from 'components/authentication/useAuthenticationSwitch'
import { useRedirectAfterLogin } from 'features/outside/login/utils/useRedirectAfterLogin'
import { useUpdateStateAfterLogin } from 'features/outside/login/utils/useUpdateAfterLogin'
import { useUpdatePixelAfterLogin } from 'features/outside/login/utils/useUpdatePixelAfterLogin'
import { useReturnUrl } from 'features/routing/useReturnUrl'
import { bufferToBase64UrlEncoded, createQueryParams, createRandomString, encode, sha256 } from 'utils/Auth0Utils'

export const VENDASTA_ACCOUNT_ID = 'strawberryFieldAccountId'

export const useVendastaSSO = () => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const returnUrl = useReturnUrl()

  const authenticationSwitchResult = useAuthenticationSwitch()
  const updateStateAfterLogin = useUpdateStateAfterLogin()
  const updatePixelAndInsightsAfterLogin = useUpdatePixelAfterLogin()
  const redirectAfterLogin = useRedirectAfterLogin()

  const [triggerGetAuthConfig, authConfigResults] = useLazyGetAuthConfigQuery()
  const [triggerVendastaAuthorize, vendastaAuthorizeResults] = useLazyVendastaAuthorizeQuery()

  const active = authenticationSwitchResult.authenticatorSource === AuthenticatorSource.Vendasta
  const redirect = authenticationSwitchResult.redirect ?? returnUrl ?? null

  useEffect(() => {
    const error = query.get('error')
    const code = query.get('code')
    const ignore = query.get('ignore')
    const vendastaAccountId = query.get('account_id')
    const savedVendastaAccountId = localStorage.getItem(VENDASTA_ACCOUNT_ID)

    if (error) {
      return
    }

    if (code) {
      doUseEffectWithCode(code)
    } else if (vendastaAccountId || (savedVendastaAccountId && !ignore)) {
      triggerGetAuthConfig(undefined, true)
    }
  }, [authenticationSwitchResult])

  useEffect(() => {
    const vendastaAccountId = query.get('account_id') ?? localStorage.getItem(VENDASTA_ACCOUNT_ID)
    if (authConfigResults.isLoading || !authConfigResults.data || !vendastaAccountId || !active) {
      return
    }

    doUseEffectLogin(vendastaAccountId, authConfigResults.data)
  }, [authConfigResults])

  useEffect(() => {
    if (vendastaAuthorizeResults.isLoading || !vendastaAuthorizeResults.data || !active) {
      return
    }

    updateStateAfterLogin(vendastaAuthorizeResults.data, query.get('account_id') ?? localStorage.getItem(VENDASTA_ACCOUNT_ID) ?? undefined)
    updatePixelAndInsightsAfterLogin(vendastaAuthorizeResults.data)
    redirectAfterLogin(redirect, vendastaAuthorizeResults.data)
  }, [vendastaAuthorizeResults])

  const doUseEffectWithCode = async (code: string) => {
    const vendastaAccountId = window.localStorage.getItem(VENDASTA_ACCOUNT_ID)
    const state = query.get('state')

    if (!state || !vendastaAccountId || !active) {
      return
    }

    const codeVerifier = window.localStorage.getItem(state)

    if (!codeVerifier) {
      return
    }

    try {
      const queryParams = createQueryParams({
        code: code,
        codeVerifier: codeVerifier,
        vendastaAccountId: vendastaAccountId
      })

      triggerVendastaAuthorize(queryParams, false)
    } finally {
      window.localStorage.removeItem(state)
    }
  }

  const doUseEffectLogin = async (vendastaAccountId: string, authConfig: AuthConfig) => {
    window.localStorage.setItem(VENDASTA_ACCOUNT_ID, vendastaAccountId)

    const stateIn = encode(createRandomString())
    const nonceIn = encode(createRandomString())
    const codeVerifier = createRandomString()
    const codeChallengeBuffer = await sha256(codeVerifier)
    const codeChallenge = bufferToBase64UrlEncoded(codeChallengeBuffer)

    const queryParams = createQueryParams({
      resource: vendastaAccountId,
      response_type: 'code',
      client_id: authConfig.clientId,
      connection: authConfig.connection,
      redirect_uri: authConfig.redirectUri,
      state: stateIn,
      nonce: nonceIn,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
      scope: authConfig.scope,
      audience: authConfig.audience
    })

    window.localStorage.setItem(stateIn, codeVerifier)

    window.location['assign'](`${authConfig.authorizeUri}?${queryParams}`)
  }
}
