import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

export enum AuthenticatorSource {
  Vendasta = 'StrawberryField',
  AdCritter = 'AdCritter'
}

export interface IAuthenticationSwitchResult {
  authenticatorSource: AuthenticatorSource
  redirect: string | null
}

export enum AuthenticatorRoutes {
  Vendasta = 'strawberryFieldLogin',
  AdCritter = 'login'
}

export const useAuthenticationSwitch = () => {
  const location = useLocation()

  const [authenticationSwitchResult, setAuthenticationSwitchResult] = useState<IAuthenticationSwitchResult>({
    authenticatorSource: AuthenticatorSource.AdCritter,
    redirect: null
  })

  useEffect(() => {
    const pathParts = location.pathname.split('/')
    const query = new URLSearchParams(location.search)
    const redirect = query.get('redirect')
    if (pathParts.length > 1) {
      if (pathParts[1] === AuthenticatorRoutes.Vendasta) {
        setAuthenticationSwitchResult({
          authenticatorSource: AuthenticatorSource.Vendasta,
          redirect: redirect
        })
      }
    }
  }, [location])

  return authenticationSwitchResult
}
