import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { EuiButton, EuiCallOut, EuiFilePicker, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, EuiTitle, EuiLoadingContent } from '@elastic/eui'

import { apiGetBillboardBuilderUpload, apiPostBillboardCreateAdRetail } from 'api/adcritterApi'
import { showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { BillboardDefaults } from 'definitions/BillboardDefaults'
import { fetchCampaign } from 'features/campaigns/campaign/campaignSlice'
import { resetCreateAd } from 'features/campaigns/createAd/billboards/retail/createAdBillboardRetailSlice'
import history from 'services/HistoryService'

import { setUploadedUrl } from './createAdBillboardRetailSlice'

const UploadStep: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [uploadedBillboardImg, setUploadedBillboardImg] = useState<string>('')
  const [containerClient, setContainerClient] = useState<ContainerClient | null>(null)
  const [isUploading, setIsUploading] = useState(false)
  const campaign = useSelector((state: RootState) => state.campaign)
  const [hasCorrectFile, setHasCorrectFile] = useState(false)
  const [fileType, setFileType] = useState('(Please upload a file)')
  const [fileSize, setFileSize] = useState<number>(0)
  const [hasFile, setHasFile] = useState(false)
  const [fileName, setFileName] = useState<string>('')
  const [height, setHeight] = useState<number>(0)
  const [width, setWidth] = useState<number>(0)

  const largeRectangle = {
    width: 1400,
    height: 400
  }

  const standardRectangle = {
    width: 840,
    height: 400
  }

  useEffect(() => {
    if (currentAccount) {
      apiGetBillboardBuilderUpload(currentAccount.id).then(data => {
        const storageClient = new BlobServiceClient(data.data)
        const containerClient = storageClient.getContainerClient(currentAccount.id)
        setContainerClient(containerClient)
      })
    }
  }, [currentAccount])

  function getMeta(url: any) {
    const img = new Image()
    img.src = url
    img.onload = function () {
      setHeight(img.height)
      setWidth(img.width)
      delay(function () {
        setHasFile(true)
      }, 750)
    }
  }

  const delay = (func: any, num: number) => {
    setTimeout(func, num)
  }

  useEffect(() => {
    fileType.toUpperCase()
    if ((fileType === 'PNG' || fileType === 'JPEG' || fileType === 'JPG') && ((width === largeRectangle.width && height === largeRectangle.height) || (width === standardRectangle.width && height === standardRectangle.height))) {
      setHasCorrectFile(true)
    }
  }, [fileType, fileSize, height, width, standardRectangle.width, standardRectangle.height, largeRectangle.width, largeRectangle.height])

  const onFileChange = (files: FileList | null) => {
    if (files && files.length === 1) {
      const blockBlobClient = containerClient!.getBlockBlobClient(uuidv4() + '.' + files[0].name.split('.').pop())!
      const url = blockBlobClient!.url.split('?')[0]
      let splitArrayLength = url.split('.').length
      let tempFileType = url.split('.')[splitArrayLength - 1].toUpperCase()
      setFileType(tempFileType)
      setFileName(files[0].name)
      let size = Math.round(files[0].size * 0.001)
      setFileSize(size)
      setIsUploading(true)
      blockBlobClient!
        .uploadData(files[0], {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20
        })
        .then(() => {
          setIsUploading(false)
          dispatch(setUploadedUrl(blockBlobClient!.url!))
          setUploadedBillboardImg(url)
          getMeta(url)
        })
        .then(() => {
          if (!(tempFileType === 'PNG' || tempFileType === 'JPEG' || tempFileType === 'JPG')) {
            setHasFile(true)
            setUploadedBillboardImg('')
          }
        })
    }
    if (files && files.length < 1) {
      setHeight(0)
      setWidth(0)
      setFileType('')
      setHasCorrectFile(false)
      setUploadedBillboardImg('')
      setHasFile(false)
    }
  }

  const submitBillboard = () => {
    const postBillboard = {
      ...BillboardDefaults.GetDefaultBillboard(),
      campaignIds: [campaign.campaign!.id],
      uploadedUrl: uploadedBillboardImg
    }
    apiPostBillboardCreateAdRetail(currentAccount!.id, postBillboard).then(() => {
      dispatch(showSuccessToast('Created new billboard ad'))
      dispatch(resetCreateAd())
      dispatch(fetchCampaign(currentAccount!.id, campaign.campaign!.id))
      history.push(`/campaigns/edit/${campaign.campaign!.id}/details`)
    })
  }

  const gotoStart = () => {
    history.push('/campaigns/billboards/public/start')
  }

  if (!containerClient) {
    return <EuiLoadingContent />
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Upload Billboard</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Upload Billboard</h3>
      </EuiTitle>
      <EuiSpacer size='m' />
      <EuiCallOut title='Start by uploading your ad, making sure it meets all requirements.' color='primary' iconType='importAction'>
        <p style={{ color: 'black', fontSize: '1.14286rem' }}>Recommendations:</p>
        <p style={{ marginLeft: 30, marginTop: -20 }}>
          Big fonts.
          <br />
          Few words. (Seven or less.)
          <br />
          Crisp, uncomplicated images.
        </p>
      </EuiCallOut>
      <EuiSpacer size='xl' />

      {uploadedBillboardImg !== '' && (width === standardRectangle.width || width === largeRectangle.width) && (height === standardRectangle.height || height === largeRectangle.height) && (
        <EuiFlexItem>
          <EuiText size='s'>
            <h4>Preview Your Billboard</h4>
          </EuiText>
          <EuiSpacer size='s' />
          <EuiFlexItem style={{ alignItems: 'center' }}>
            <img src={uploadedBillboardImg} alt={'uploaded billboard'} style={{ width: width / 1.5, height: height / 1.5, backgroundColor: 'lightgrey', borderRadius: 8 }} />
          </EuiFlexItem>
          <EuiSpacer size='l' />
        </EuiFlexItem>
      )}

      <EuiFilePicker id='upload' fullWidth display='large' multiple={false} initialPromptText='Select or drag and drop  file here' onChange={onFileChange} isLoading={isUploading} />
      <EuiSpacer size='m' />
      <EuiCallOut title='Requirements: ' color='primary' iconType='importAction'>
        <EuiSpacer />
        <section style={{ marginLeft: 30, marginTop: -20, marginBottom: 20 }}>
          <span style={{ color: 'black', fontSize: '1.14286rem' }}>Acceptable image dimensions:</span> (Dimensions must be exact).
          <section style={{ marginLeft: 30 }}>
            <EuiText>
              Large: {largeRectangle.width} x {largeRectangle.height} pixels{' '}
            </EuiText>
            <EuiText>
              Standard: {standardRectangle.width} x {standardRectangle.height} pixels{' '}
            </EuiText>
          </section>
        </section>
        <p style={{ marginLeft: 30, marginTop: -20 }}>
          <span style={{ color: 'black', fontSize: '1.14286rem' }}>Accepted file formats:</span> PNG, JPEG or JPG.
        </p>
      </EuiCallOut>
      <EuiSpacer size='m' />
      {hasFile && (
        <React.Fragment>
          {hasCorrectFile ? (
            <EuiCallOut title='Your File Has Been Read' iconType='document' color='success'>
              <p>You did everything right!</p>
            </EuiCallOut>
          ) : (
            <EuiCallOut title='Your file has not been uploaded because it had these issues: ' iconType='document' color='warning'>
              {!(fileType === 'PNG' || fileType === 'JPEG' || fileType === 'JPG') && (
                <p>
                  {fileName} is not an accepted file type (it's a .{fileType}). Please upload a file that is a <strong>PNG, JPEG or JPG</strong>.
                </p>
              )}
              {!((width === standardRectangle.width && height === standardRectangle.height) || (width === largeRectangle.width && height === largeRectangle.height)) && (
                <section>
                  {fileName} isn't one of the accepted image dimensions (it's {width} x {height}). Please upload a file that is one of the following dimensions:
                  <section style={{ marginLeft: 30 }}>
                    <EuiText size='s'>
                      Large Rectangle: {largeRectangle.width} x {largeRectangle.height} pixels{' '}
                    </EuiText>
                    <EuiText size='s'>
                      Standard Rectangle: {standardRectangle.width} x {standardRectangle.height} pixels{' '}
                    </EuiText>
                  </section>
                </section>
              )}
            </EuiCallOut>
          )}
        </React.Fragment>
      )}

      <EuiSpacer size='xl' />

      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton id='back' fill type='button' color='text' onClick={gotoStart} iconType='arrowLeft' iconSide='left'>
            Back
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton id='submit' fill type='submit' onClick={submitBillboard}>
            Submit
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default UploadStep
