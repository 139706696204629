import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiLoadingContent, EuiConfirmModal, EuiOverlayMask, EuiFlexGroup, EuiFlexItem, EuiCard, EuiIcon, EuiDescriptionList, EuiDescriptionListTitle, EuiDescriptionListDescription } from '@elastic/eui'

import { refreshOrganizationAndAccount, showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import UnpaidSubscriptionCallOut from './UnpaidSubscriptionCallOut'
import { cancelOrganizationPlatformAccess, fetchOrganizationPlatformAccess, close, reactivateOrganizationPlatformAccess } from './organizationSlice'

const PlatformAccessDrawer = () => {
  const dispatch = useDispatch()
  const organization = useSelector((state: RootState) => state.organization)
  const { currentOrganization, currentAccount, isUnpaidSubscription } = useSelector((state: RootState) => state.app)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isRestartModalVisible, setRestartIsModalVisible] = useState(false)
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  const whiteLabel = useWhiteLabel()
  const [status, setStatus] = useState('unknown')

  useEffect(() => {
    dispatch(fetchOrganizationPlatformAccess())
  }, [currentOrganization, dispatch])

  const closeModal = () => setIsModalVisible(false)
  const showModal = () => setIsModalVisible(true)

  const closeRestartModal = () => setRestartIsModalVisible(false)
  const showRestartModal = () => setRestartIsModalVisible(true)

  let modal

  if (organization.platformAccess != null && isModalVisible) {
    modal = (
      <EuiOverlayMask headerZindexLocation='above'>
        <EuiConfirmModal
          title='Are you sure you want to cancel this subscription?'
          onCancel={closeModal}
          onConfirm={() => {
            dispatch(cancelOrganizationPlatformAccess())
            closeModal()
            dispatch(close())
            dispatch(showSuccessToast('Successfully cancelled your platform access'))
            dispatch(refreshOrganizationAndAccount(currentAccount!.id))
          }}
          cancelButtonText={`No, keep ${whiteLabel?.getName()} active.`}
          confirmButtonText='Yes, cancel'
          confirmButtonDisabled={organization.isProcessing}
          buttonColor='danger'
          defaultFocusedButton='confirm'>
          {organization.platformAccess.status === 'trialing' ? (
            <p>Your subscription is currently in trial mode. If you cancel your subscription access now, your campaigns will continue to run until the remaining budget is spent, but you will be able to view reports until {moment(organization.platformAccess.nextBillDate).format('DD MMM yyyy')}</p>
          ) : (
            <p>Your subscription is currently active. If you cancel your subscription access now, your campaigns will continue to run until the remaining budget is spent, but you will be able to view reports until {moment(organization.platformAccess.nextBillDate).format('DD MMM yyyy')}</p>
          )}
        </EuiConfirmModal>
      </EuiOverlayMask>
    )
  }

  if (organization.platformAccess != null && isRestartModalVisible) {
    modal = (
      <EuiOverlayMask headerZindexLocation='above'>
        <EuiConfirmModal
          title='Are you sure you want to reactivate this subscription?'
          onCancel={closeRestartModal}
          onConfirm={() => {
            dispatch(reactivateOrganizationPlatformAccess())
            closeRestartModal()
            dispatch(close())
            dispatch(showSuccessToast('Successfully reactivated your platform access'))
          }}
          cancelButtonText={`No, keep ${whiteLabel?.getName()} cancelled.`}
          confirmButtonText='Yes, reactivate'
          confirmButtonDisabled={organization.isProcessing}
          buttonColor='success'
          defaultFocusedButton='confirm'>
          <p>
            Your subscription is currently cancelled. Would you like to reactivate your access to the {whiteLabel?.getName()} Platform. Your platform access will of {formatter.format(organization.platformAccess.nextBillAmount)} be charged on {moment(organization.platformAccess.nextBillDate).format('DD MMM yyyy')}.
          </p>
        </EuiConfirmModal>
      </EuiOverlayMask>
    )
  }

  const onCancel = () => {
    showModal()
  }

  const onRestart = () => {
    showRestartModal()
  }

  useEffect(() => {
    if (organization.platformAccess) {
      if (organization.platformAccess.cancelAtPeriodEnd) {
        setStatus('Canceled')
      } else {
        switch (organization.platformAccess.status) {
          case 'trialing':
            setStatus('Trialing')
            break
          case 'active':
            setStatus('Active')
            break
          case 'incomplete_expired':
          case 'past_due':
          case 'unpaid':
            setStatus('Payment Required')
            break
          case 'canceled':
            setStatus('Canceled')
        }
      }
    }
  }, [organization.platformAccess])

  const footer = (
    <React.Fragment>
      {organization.platformAccess && !currentOrganization!.hasBundle && organization.platformAccess.status !== 'canceled' && !organization.platformAccess.cancelAtPeriodEnd && (
        <EuiButton id='cancel' size='s' color='warning' onClick={onCancel}>
          Cancel Subscription
        </EuiButton>
      )}
      {organization.platformAccess && (organization.platformAccess.status === 'canceled' || organization.platformAccess.cancelAtPeriodEnd) && (
        <EuiButton id='reactivate' size='s' color='success' disabled={organization.paymentMethods?.length === 0} onClick={onRestart}>
          Reactivate Subscription
        </EuiButton>
      )}
      {organization.paymentMethods?.length === 0 && (organization?.platformAccess?.status === 'canceled' || organization?.platformAccess?.cancelAtPeriodEnd) && <div>Please add a payment method</div>}
    </React.Fragment>
  )

  let content

  if (organization.platformAccess == null || !currentOrganization || organization.platformAccess.invoices == null || organization.isLoading) {
    content = <EuiLoadingContent lines={3} />
  } else {
    content = (
      <React.Fragment>
        {isUnpaidSubscription && <UnpaidSubscriptionCallOut />}
        <EuiFlexGroup>
          <EuiFlexItem style={{ maxWidth: 300 }}>
            <EuiCard icon={<EuiIcon size='l' type='asterisk' />} description={organization.platformAccess.level} title='Subscription Plan' betaBadgeProps={{ label: organization.platformAccess.status === 'trialing' ? 'Trial' : '' }} footer={footer}>
              <EuiDescriptionList>
                <EuiDescriptionListTitle>Status</EuiDescriptionListTitle>
                <EuiDescriptionListDescription>{status}</EuiDescriptionListDescription>
                {(organization.platformAccess.status === 'active' || organization.platformAccess.status === 'trialing' || organization.platformAccess.status === 'past_due') && !organization.platformAccess.cancelAtPeriodEnd && (
                  <React.Fragment>
                    <EuiDescriptionListTitle>Next Invoice Date</EuiDescriptionListTitle>
                    <EuiDescriptionListDescription>{moment(organization.platformAccess.nextBillDate).format('DD MMM yyyy')}</EuiDescriptionListDescription>
                    <EuiDescriptionListTitle>Next Invoice Amount</EuiDescriptionListTitle>
                    <EuiDescriptionListDescription>{formatter.format(organization.platformAccess.nextBillAmount)}</EuiDescriptionListDescription>
                  </React.Fragment>
                )}
                {organization.platformAccess.status === 'canceled' ||
                  (organization.platformAccess.cancelAtPeriodEnd && (
                    <React.Fragment>
                      <EuiDescriptionListTitle>Canceled Date</EuiDescriptionListTitle>
                      <EuiDescriptionListDescription>{moment(organization.platformAccess.canceledDate).format('DD MMM yyyy')}</EuiDescriptionListDescription>
                      {organization.platformAccess.cancelAtPeriodEnd && (
                        <React.Fragment>
                          <EuiDescriptionListTitle>Access Allowed Until Date</EuiDescriptionListTitle>
                          <EuiDescriptionListDescription>{moment(organization.platformAccess.nextBillDate).format('DD MMM yyyy')}</EuiDescriptionListDescription>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ))}
              </EuiDescriptionList>
            </EuiCard>
          </EuiFlexItem>
        </EuiFlexGroup>
        {modal}
      </React.Fragment>
    )
  }

  return <div>{content}</div>
}

export default PlatformAccessDrawer
