import React from 'react'

import { EuiListGroup, EuiListGroupItem } from '@elastic/eui'

import { CityTarget } from 'api/interfaces'

interface NewCityTargeterProps {
  cities: Array<CityTarget>

  onCityRemoved(cityId: number): void
}

export const NewCityTargeter = ({ cities, onCityRemoved }: NewCityTargeterProps) => {
  return (
    <React.Fragment>
      {cities && cities.length > 0 && (
        <EuiListGroup bordered={true} maxWidth={false} size='s'>
          {cities.map(w => (
            <EuiListGroupItem
              size='s'
              key={w.id.toString()}
              label={`${w.name}, ${w.regionCode}`}
              extraAction={{
                onClick: () => {
                  onCityRemoved(w.id)
                },
                iconType: 'cross',
                iconSize: 's',
                'aria-label': 'Remove',
                alwaysShow: true
              }}
            />
          ))}
        </EuiListGroup>
      )}
    </React.Fragment>
  )
}
