import React, { FC } from 'react'

import { EuiButtonEmpty, EuiInMemoryTable, EuiLink, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'
import { EuiDataGridColumn } from '@elastic/eui/src/components/datagrid/data_grid_types'

export interface ReportDataGridColumn extends EuiDataGridColumn {
  hidden?: boolean
}

export interface ReportDataGridSorting {
  id: string
  direction: 'asc' | 'desc'
}

export interface ReportDataGridProps {
  data: any[]
  columns: ReportDataGridColumn[]
  defaultSorting: Array<ReportDataGridSorting>
}

const ReportDataGrid: FC<ReportDataGridProps> = ({ data, columns, defaultSorting }) => {
  const gridColumns: Array<EuiBasicTableColumn<ReportDataGridColumn>> =
    columns
      .filter(c => !c.hidden)
      .map(c => ({
        field: c.id,
        name: c.displayAsText,
        render: (value: any) => {
          if (typeof value === 'string' && value.startsWith('http')) {
            return (
              <EuiLink href={value} target='_blank'>
                Link
              </EuiLink>
            )
          }
          return value
        },
        sortable: true
      })) || []

  const exportCsv = () => {
    let csvContent = 'data:text/csv;charset=utf-8,'
    csvContent +=
      columns
        .map(c => c.displayAsText)
        .map(String)
        .map(t => t?.replaceAll('"', '""'))
        .map(t => `"${t}"`)
        .join(',') + '\n'
    data.forEach((row: any) => {
      csvContent +=
        columns
          .map(c => row[c.id])
          .map(String)
          .map(t => t?.replaceAll('"', '""'))
          .map(t => `"${t}"`)
          .join(',') + '\n'
    })
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'report.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const pagination = {
    initialPageSize: 25,
    pageSizeOptions: [10, 25, 50, 100]
  }

  const sorting = {
    enableAllColumns: true,
    sort: defaultSorting[0]
      ? {
          field: defaultSorting[0]?.id,
          direction: defaultSorting[0]?.direction === 'asc' ? SortDirection.ASC : SortDirection.DESC
        }
      : {
          field: '',
          direction: SortDirection.ASC
        }
  }

  return (
    <React.Fragment>
      <EuiButtonEmpty onClick={exportCsv} iconType='exportAction' color='primary'>
        Export
      </EuiButtonEmpty>
      <EuiInMemoryTable className={'table-color'} items={data} columns={gridColumns} compressed pagination={pagination} sorting={sorting} />
    </React.Fragment>
  )
}

export default ReportDataGrid
