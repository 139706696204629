import { Moment } from 'moment/moment'
import React from 'react'

import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiInMemoryTable, EuiLink, EuiLoadingSpinner, EuiText, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'
import { Pagination } from '@elastic/eui/src/components/basic_table/pagination_bar'

import { formatCurrency } from 'utils/Formatters'

export interface IBudgetGrid {
  isLoading?: boolean
  pagination?: Pagination
  defaultSortField?: string
  gridItems: GridItem[]
}

export enum GridItemType {
  Campaign = 'Campaign',
  Invoice = 'Invoice',
  Match = 'Match'
}

export interface GridItem {
  id: string
  name: string
  allocated: number
  start?: Moment
  end?: Moment
  activated?: Moment
  preMatchBudget?: number
  finalCost?: number
  status?: string
  type: GridItemType
}

export const BudgetGrid: React.FC<IBudgetGrid> = props => {
  const columns: Array<EuiBasicTableColumn<GridItem>> = [
    {
      name: 'Name',
      sortable: (item: GridItem) => item.name,
      render: (item: GridItem) => (
        <EuiText size={'s'} color={item.type === GridItemType.Invoice ? 'warning' : item.type === GridItemType.Match ? 'success' : undefined}>
          {item.type === GridItemType.Campaign ? (
            <EuiLink href={`/campaigns/edit/${item.id}`} target={'_blank'}>
              {item.name}
            </EuiLink>
          ) : (
            item.name
          )}
        </EuiText>
      )
    },
    {
      name: `Allocated Budget`,
      field: 'allocated',
      align: 'right',
      render: (allocated: number) => formatCurrency(allocated),
      sortable: true
    },
    {
      name: `Activated`,
      align: 'center',
      field: 'activated',
      render: (activated: Moment) => (activated ? activated.format('MM/DD/YYYY') : ''),
      sortable: true
    },
    {
      name: `Start`,
      align: 'center',
      field: 'start',
      render: (start: Moment) => (start ? start.format('MM/DD/YYYY') : ''),
      sortable: true
    },
    {
      name: `End`,
      align: 'center',
      field: 'end',
      render: (end: Moment) => (end ? end.format('MM/DD/YYYY') : ''),
      sortable: true
    },
    {
      name: `Spend To Date`,
      align: 'right',
      field: 'finalCost',
      render: (finalCost: number) => (finalCost ? formatCurrency(finalCost) : ''),
      sortable: true
    },
    {
      name: `Status`,
      align: 'center',
      field: 'status',
      sortable: true
    }
  ]

  const pagination = props.pagination ?? {
    initialPageSize: 50,
    pageSizeOptions: [25, 50, 100]
  }

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: true,
    sort: {
      field: props.defaultSortField ?? 'activated',
      direction: SortDirection.ASC
    }
  }

  const exportCsv = () => {
    let csvContent = 'data:text/csv;charset=utf-8,'
    csvContent += 'Name,Allocated Budget,Activated,Start,End,Spend To Date,Status\n'
    props.gridItems.forEach(item => {
      csvContent += `${item.name},${item.allocated},${item.activated?.format('MM/DD/YYYY')},${item.start?.format('MM/DD/YYYY')},${item.end?.format('MM/DD/YYYY')},${item.finalCost},${item.status}\n`
    })
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'report.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <React.Fragment>
      {props.isLoading ? (
        <EuiLoadingSpinner size='xl' />
      ) : (
        <React.Fragment>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty iconType={'download'} size={'s'} onClick={exportCsv}>
                Export
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiInMemoryTable className={'table-color'} items={props.gridItems} columns={columns} pagination={pagination} sorting={sorting} />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
