import { BillboardImageComponent, BillboardTextComponent } from './Billboard'

export interface Ad {
  id: string
  headline: string
  body: string
  companyName: string
  callToAction: string
  destinationUrl: string
}

export enum CreativeStatus {
  PendingApproval = 'PendingApproval',
  PendingNetworkApproval = 'PendingNetworkApproval',
  Live = 'Live',
  Disapproved = 'Disapproved',
  Inactive = 'Inactive',
  Deleted = 'Deleted',
  WaitingForTVOutro = 'WaitingForTVOutro'
}

export interface Creative {
  id: string
  type: string
  name: string | null
  mediaType: string
  highQualityUrl: string
  destinationUrl: string
  status: CreativeStatus
  canEditStatus: boolean
  canRemove: boolean
  auditStatus: string
  auditReasons: Array<string>
  thumbnailUrl: string
  squareUrl: string
  largeSquareUrl: string
  nativeDetails: NativeDetails
  tvCommercialDetails: TvCommercialDetails
  tvCommercialUploadedDetails: TvCommercialUploadedDetails
  billboardDetails: BillboardDetails
  displayAdDetails: DisplayAdDetails
}

export interface NativeDetails {
  headline: string
  body: string
  companyName: string
  callToAction: string
  displayUrl: string
}

export interface TvCommercialDetails {
  companyName: string
  sections: string[]
  finalUrl: string | null
  previewUrl: string
}

export interface TvCommercialUploadedDetails {
  finalUrl: string | null
  previewUrl: string | null
  uploadedUrl: string | null
}

export interface BillboardDetails {
  headline: BillboardTextComponent
  companyName: BillboardTextComponent
  phone: BillboardTextComponent
  location: BillboardTextComponent
  website: BillboardTextComponent
  backgroundUrl: string | null
  logo: BillboardImageComponent | null
  photo: BillboardImageComponent | null
  uploadedUrl: string | null
  previewUrl: string
}

export interface DisplayAdDetails {
  uploadedUrl: string
  htmlTag: string
  width: number
  height: number
  destinationUrl: string
  displayAdName: string
  mimeType: string
}
