export interface SalesAgencyDetails {
  id: string
  organizationId: string
  cohortStatus: CohortStatus
  cohortName: string
  cohortId: string
}

export interface SalesAgencyInformation {
  businessName: string
  businessPhone: string
  website: string
  timezone: string
  address: string
  cityName: string
  state: string
  zipCode: string
  contactName: string
  contactEmail: string
  contactPhone: string
}

export enum CohortStatus {
  Offered = 'Offered',
  Accepted = 'Accepted',
  Declined = 'Declined',
  Rejected = 'Rejected',
  Pending = 'Pending'
}
