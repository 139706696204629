import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCheckbox, EuiFlexGrid, EuiFlexItem, EuiForm, EuiIcon, EuiCheckableCard, EuiLoadingContent, EuiSpacer, EuiText, EuiTitle, EuiToolTip, htmlIdGenerator } from '@elastic/eui'

import { ApiError, apiPutCampaignAdPlacement } from 'api/adcritterApi'
import { showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'

import { fetchCampaign } from '../campaignSlice'

interface FormValues {
  placement: string
  categories: Array<string>
}

const AdPlacementDrawer: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const campaign = useSelector((state: RootState) => state.campaign)
  const [formValues, setFormValues] = useState<FormValues>({
    placement: 'Everywhere',
    categories: []
  })

  useEffect(() => {
    if (campaign.campaign) {
      setFormValues({
        placement: campaign.campaign.placement,
        categories: campaign.campaign.categories
      })
    }
  }, [campaign.campaign])

  const detailSchema = Yup.object().shape({
    placement: Yup.string().required('Please choose where your ads will be placed'),
    categories: Yup.array().when(['placement'], {
      is: (placement: string) => placement === 'Categories',
      then: Yup.array().min(1, 'Please Select at Least 1 Category').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    })
  })

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: detailSchema,
    onSubmit: (values: FormValues) => {
      apiPutCampaignAdPlacement(currentAccount!.id, campaign.campaign!.id, values.placement, values.categories, [])
        .then(() => {
          dispatch(showSuccessToast('Saved Ad Placement'))
          dispatch(fetchCampaign(currentAccount!.id, campaign.campaign!.id))
          formik.setSubmitting(false)
          formik.setStatus(null)
        })
        .catch(response => {
          formik.setSubmitting(false)
          response.errors.forEach(function (error: ApiError) {
            formik.setFieldError(error.name, error.message)
          })
        })
    }
  })

  const updateCategories = (isChecked: boolean, category: string) => {
    if (isChecked && formik.values.categories.filter(c => c === category).length === 0) {
      const newCategories = [...formik.values.categories]
      newCategories.push(category)
      formik.setFieldValue('categories', newCategories, true)
    } else if (!isChecked && formik.values.categories.filter(c => c === category).length > 0) {
      const index = formik.values.categories.indexOf(category)
      const newCategories = [...formik.values.categories]
      newCategories.splice(index, 1)
      formik.setFieldValue('categories', newCategories, true)
    }
  }

  let content

  if (campaign.isLoadingCampaign) {
    content = <EuiLoadingContent lines={3} />
  } else {
    content = (
      <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
        <EuiTitle size='xs'>
          <h2>Ad Placement</h2>
        </EuiTitle>
        <EuiSpacer size='s' />
        <EuiText size='s'>
          <p>Prioritize where your ads appear on the Internet.</p>
        </EuiText>
        <EuiSpacer />

        <EuiCheckableCard
          id='everywhere'
          name='placement'
          value='Everywhere'
          label={
            <React.Fragment>
              <strong>Prioritize highest performing sites. (Recommended)</strong>{' '}
              <EuiToolTip position='bottom' content='Prioritizes all top networks to deliver top results.'>
                <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
              </EuiToolTip>
              <EuiSpacer size='s' />
              <EuiText size='s' color={formik.values.placement !== 'Everywhere' ? 'subdued' : 'primary'}>
                Prioritizes the best performing networks for your target audience. Automatically self-optimizes for best results.
              </EuiText>
            </React.Fragment>
          }
          checked={formik.values.placement === 'Everywhere'}
          onChange={() => {
            formik.setFieldValue('placement', 'Everywhere', true)
          }}
        />
        <EuiSpacer size='m' />
        <EuiCheckableCard
          id='categories'
          name='placement'
          value='Categories'
          label={<strong>Prioritize specific categories of networks.</strong>}
          checked={formik.values.placement === 'Categories'}
          onChange={() => {
            formik.setFieldValue('placement', 'Categories', true)
            if (formik.values.categories.length === 0) {
              formik.setFieldValue('categories', ['Major', 'Sports', 'Lifestyle', 'Entertainment', 'Family', 'News', true])
            }
          }}>
          <EuiSpacer size='s' />
          <div style={{ marginLeft: 35 }}>
            <EuiFlexGrid columns={3} gutterSize='s'>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Major')
                  }}
                  disabled={formik.values.placement !== 'Categories'}
                  checked={formik.values.categories.filter(c => c === 'Major').length > 0}
                  label='Major Networks'
                />
                <EuiText size='xs' style={{ marginLeft: 25 }} color={formik.values.placement !== 'Categories' ? 'subdued' : 'primary'}>
                  Includes NBC, ABC, ESPN, Fox, MSNBC and more...
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Sports')
                  }}
                  disabled={formik.values.placement !== 'Categories'}
                  checked={formik.values.categories.filter(c => c === 'Sports').length > 0}
                  label='Sports Networks'
                />
                <EuiText size='xs' style={{ marginLeft: 25 }} color={formik.values.placement !== 'Categories' ? 'subdued' : 'primary'}>
                  Includes ESPN, NBCSN, Fox Sports, ACC, MLB, NBA, NFL, Big Ten and more...
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Lifestyle')
                  }}
                  disabled={formik.values.placement !== 'Categories'}
                  checked={formik.values.categories.filter(c => c === 'Lifestyle').length > 0}
                  label='Lifestyle Networks'
                />
                <EuiText size='xs' style={{ marginLeft: 25 }} color={formik.values.placement !== 'Categories' ? 'subdued' : 'primary'}>
                  Includes HGTV, Food Network, DIY, Cooking Channel, Travel Channel and more...
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Entertainment')
                  }}
                  disabled={formik.values.placement !== 'Categories'}
                  checked={formik.values.categories.filter(c => c === 'Entertainment').length > 0}
                  label='Entertainment Networks'
                />
                <EuiText size='xs' style={{ marginLeft: 25 }} color={formik.values.placement !== 'Categories' ? 'subdued' : 'primary'}>
                  Includes A&E, Lifetime, TLC, E! Entertainment, Bravo, Ion Television, Oxygen, USA Network and more...
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Family')
                  }}
                  disabled={formik.values.placement !== 'Categories'}
                  checked={formik.values.categories.filter(c => c === 'Family').length > 0}
                  label='Family Friendly Networks'
                />
                <EuiText size='xs' style={{ marginLeft: 25 }} color={formik.values.placement !== 'Categories' ? 'subdued' : 'primary'}>
                  Includes Discovery, Animal Planet, National Geographic, History Channel and more...
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'News')
                  }}
                  disabled={formik.values.placement !== 'Categories'}
                  checked={formik.values.categories.filter(c => c === 'News').length > 0}
                  label='News Networks'
                />
                <EuiText size='xs' style={{ marginLeft: 25 }} color={formik.values.placement !== 'Categories' ? 'subdued' : 'primary'}>
                  Includes Bloomberg Television, CNBC, Fox Business, MSNBC, Fox News, MSN, Weather Channel and more...
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGrid>
          </div>
        </EuiCheckableCard>
        <EuiSpacer />

        <EuiButton fill type='submit' isLoading={formik.isSubmitting}>
          Save
        </EuiButton>
      </EuiForm>
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      {content}
    </React.Fragment>
  )
}

export default AdPlacementDrawer
