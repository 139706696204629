import React from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer } from '@elastic/eui'

export const OfferPanel: React.FC = props => {
  return (
    <React.Fragment>
      <EuiSpacer size={'xl'} />
      <EuiFlexGroup>
        <EuiFlexItem grow={1} />
        <EuiFlexItem grow={3}>
          <EuiPanel style={{ paddingLeft: 48, paddingRight: 48, paddingTop: 36 }}>{props.children}</EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem grow={1} />
      </EuiFlexGroup>
    </React.Fragment>
  )
}
