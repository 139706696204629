import moment from 'moment'
import { CampaignCreateWorkflow } from 'workflows/CampaignCreateWorkflow'

import { Campaign, DayPartitioning, DeviceSettings, FrequencyUnit, IGeoTargeting, InventorySettings, SegmentTarget, ThirdPartyAudience, UpdateAdvancedTargeting, UpdateCampaignNameAndBudgetResponse } from 'api/interfaces'
import { PaymentInfo } from 'api/interfaces/PaymentInfo'
import { mobileDashboardApi } from 'api/rtkQueryApi/platform/mobileDashboardApi'
import { defaultTransformFn, platformApi } from 'api/rtkQueryApi/platform/platformApi'
import { LocationType } from 'components/targeters/GeoSelectionTargeter'
import { AdPaymentType } from 'features/builders/internet/retail/builderInternetRetailSlice'

export interface IGetCampaignsQuery {
  accountId: string
  isAgency?: boolean
}

export interface IGetCampaignQuery {
  accountId: string
  campaignId?: string
  isAgency?: boolean
}

export interface IAudienceTargeting {
  ageRanges: string[]
  genders: string[]
  householdIncomes: string[]
  thirdPartyAudiences: ThirdPartyAudience[]
  segments: SegmentTarget[]
}

export interface AdvancedTargetingOptions {
  dayParts: DayPartitioning
  inventorySettings: InventorySettings
  deviceSettings: DeviceSettings
  frequency: number
  frequencyUnit: FrequencyUnit
}

export interface IUpdateCampaignNameAndBudgetMutation {
  accountId: string
  campaignId: string
  name: string
  budget: number
}

const campaignsTags = platformApi.enhanceEndpoints({ addTagTypes: ['Campaigns', 'EndingOnByCampaignId', 'UsingUpdatedTargeting', 'RetargetingCampaigns', 'Campaign'] })

export const campaignsApi = campaignsTags.injectEndpoints({
  endpoints: build => ({
    getCampaigns: build.query<Campaign[], IGetCampaignsQuery>({
      query: ({ accountId, isAgency }) => {
        return {
          url: `accounts/${accountId}/campaigns`,
          method: 'GET',
          isContext: true,
          isAgency
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { accountId }) => [{ type: 'Campaigns', id: accountId }],
      async onQueryStarted({ accountId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(mobileDashboardApi.util.invalidateTags([{ type: 'MobileDashboard', id: accountId }]))
        } catch {}
      }
    }),
    getCampaign: build.query<Campaign, { accountId: string; campaignId: string }>({
      query: request => {
        return {
          url: `accounts/${request.accountId}/campaigns/${request.campaignId}`,
          method: 'GET',
          isContext: true
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, request) => [{ type: 'Campaign', id: request.campaignId }]
    }),
    getEndingOnDateByCampaignId: build.query<moment.Moment, IGetCampaignQuery>({
      query: ({ accountId, campaignId, isAgency }) => {
        return {
          url: `accounts/${accountId}/campaigns/${campaignId}/endingOnDate`,
          method: 'GET',
          isContext: true,
          isAgency
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { campaignId }) => [{ type: 'EndingOnByCampaignId', id: campaignId }]
    }),
    getRetargetingCampaigns: build.query<Campaign[], IGetCampaignQuery>({
      query: ({ accountId, isAgency }) => {
        return {
          url: `accounts/${accountId}/campaigns/retargetingCampaigns`,
          method: 'GET',
          isContext: true,
          isAgency
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { accountId }) => [{ type: 'RetargetingCampaigns', id: accountId }]
    }),

    usingUpdatedTargeting: build.query<boolean, { accountId: string; campaignId: string }>({
      query: request => {
        return {
          url: `accounts/${request.accountId}/campaigns/${request.campaignId}/usingUpdatedTargeting`,
          method: 'GET',
          isContext: true
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, request) => [{ type: 'UsingUpdatedTargeting', id: request.campaignId }]
    }),
    updateCampaignAdvancedTargeting: build.mutation<Campaign, { accountId: string; campaignId: string; advancedTargeting: UpdateAdvancedTargeting }>({
      query: ({ accountId, campaignId, advancedTargeting }) => ({
        url: `accounts/${accountId}/campaigns/${campaignId}/advancedTargeting`,
        method: 'PUT',
        data: advancedTargeting,
        isContext: true
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { accountId }) => [{ type: 'Campaigns', id: accountId }]
    }),
    updateCampaignLocationTargeting: build.mutation<Campaign, { accountId: string; campaignId: string; targeting: IGeoTargeting; locationType: LocationType; audience: IAudienceTargeting; advancedOptions?: AdvancedTargetingOptions }>({
      query: ({ accountId, campaignId, targeting, locationType, audience, advancedOptions }) => ({
        url: `accounts/${accountId}/campaigns/${campaignId}/updateLocationTargeting`,
        method: 'POST',
        data: {
          locations: targeting.locations,
          geoFences: targeting.geoFences,
          geoCircles: targeting.geoCircles,
          geoRectangles: targeting.geoRectangles,
          geoPolygons: targeting.geoPolygons,
          locationType: locationType,
          ageRanges: audience.ageRanges,
          genders: audience.genders,
          householdIncomes: audience.householdIncomes,
          audiences: audience.thirdPartyAudiences,
          segments: audience.segments,
          advancedTargetingOptions: advancedOptions
        },
        isContext: true
      }),
      transformResponse: defaultTransformFn
    }),
    reactivateCampaign: build.mutation<Campaign, { accountId: string; campaignId: string; paymentMethodId: string }>({
      query: ({ accountId, campaignId, paymentMethodId }) => ({
        url: `accounts/${accountId}/campaigns/${campaignId}/reactivateCampaign?paymentMethodId=${paymentMethodId}`,
        method: 'POST',
        data: {},
        isContext: true
      }),
      transformResponse: defaultTransformFn
    }),
    updateCampaignNameAndBudget: build.mutation<UpdateCampaignNameAndBudgetResponse, IUpdateCampaignNameAndBudgetMutation>({
      query: ({ accountId, campaignId, name, budget }) => ({
        url: `accounts/${accountId}/campaigns/${campaignId}/updateCampaignNameAndBudget`,
        method: 'PUT',
        data: {
          name,
          budget
        },
        isContext: true
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { campaignId }) => [{ type: 'Campaign', id: campaignId }]
    }),
    updateCampaign: build.mutation<Campaign, { accountId: string; campaignId: string; name: string; budget: number; maxCpm?: number; startDate?: moment.Moment; endDate?: moment.Moment; monthlyImpressions?: number }>({
      query: request => ({
        url: `accounts/${request.accountId}/campaigns/${request.campaignId}`,
        method: 'PUT',
        data: {
          name: request.name,
          budget: request.budget,
          monthlyImpressions: request.monthlyImpressions,
          startDate: request.startDate,
          endDate: request.endDate,
          maxCpm: request.maxCpm
        },
        isContext: true
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, request) => [
        { type: 'Campaign', id: request.campaignId },
        { type: 'Campaigns', id: request.accountId }
      ]
    }),
    createCampaign: build.mutation<Campaign, { accountId: string; saveForLater: boolean; paymentInfo: PaymentInfo; campaignCreate: CampaignCreateWorkflow }>({
      query: request => ({
        url: `accounts/${request.accountId}/campaigns/create`,
        method: 'POST',
        data: {
          id: request.campaignCreate.campaignInfo.id,
          name: request.campaignCreate.campaignInfo.name,
          campaignType: request.campaignCreate.campaignType,
          budget: request.campaignCreate.campaignInfo.budget,
          contactName: request.campaignCreate.campaignInfo.contactName,
          phone: request.campaignCreate.campaignInfo.phone,
          email: request.campaignCreate.campaignInfo.email,
          startDate: request.campaignCreate.campaignInfo.startDate,
          endDate: request.campaignCreate.campaignInfo.endDate,
          isTotalBudget: request.campaignCreate.campaignInfo.isTotalBudget,
          campaignTargeting: request.campaignCreate.campaignTargeting,
          creativeInfo: request.campaignCreate.creativeInfo,
          businessInfo: request.campaignCreate.businessInfo,
          paymentMethodId: request.paymentInfo.paymentMethodType === AdPaymentType.Standard ? request.paymentInfo.paymentMethodId : undefined,
          paymentMethodType: request.paymentInfo.paymentMethodType,
          bundleId: request.paymentInfo.paymentMethodType === AdPaymentType.Bundle ? request.paymentInfo.bundleId : undefined,
          campaignSlotId: request.paymentInfo.paymentMethodType === AdPaymentType.Bundle ? request.paymentInfo.campaignSlotId : undefined,
          saveForLater: request.saveForLater,
          budgetFrequency: request.paymentInfo.budgetFrequency
        },
        isContext: true
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, request) => [{ type: 'Campaigns', id: request.accountId }]
    })
  })
})

export const { useGetCampaignQuery, useGetEndingOnDateByCampaignIdQuery, useUpdateCampaignAdvancedTargetingMutation, useGetRetargetingCampaignsQuery, useUsingUpdatedTargetingQuery, useUpdateCampaignLocationTargetingMutation, useGetCampaignsQuery, useReactivateCampaignMutation, useUpdateCampaignNameAndBudgetMutation, useUpdateCampaignMutation, useCreateCampaignMutation } = campaignsApi
