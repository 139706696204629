import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CampaignCreateWorkflow } from 'workflows/CampaignCreateWorkflow'

import { RootState } from 'app/rootReducer'
import { setCampaignCreateWorkflow } from 'features/builders/CampaignCreateSlice'
import { CreateCampaignStorageManager } from 'features/builders/createCampaign/CreateCampaignStorageManager'

export const useCampaignCreateSyncManager = () => {
  const campaignWorkflow = useSelector((state: RootState) => state.campaignCreateWorkflow)
  const dispatch = useDispatch()
  const storageManager = CreateCampaignStorageManager

  const saveToLocalStorage = (state: CampaignCreateWorkflow) => {
    storageManager.SaveToLocalStorage(state)
  }

  const loadFromLocalStorage = () => {
    return storageManager.LoadFromLocalStorage()
  }

  useEffect(() => {
    const localState = loadFromLocalStorage()
    if (localState) {
      dispatch(setCampaignCreateWorkflow(localState))
    }
  }, [])

  useEffect(() => {
    saveToLocalStorage(campaignWorkflow)
  }, [campaignWorkflow])
}
