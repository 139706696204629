import FilePondPluginFileValidatationType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
// @ts-ignore
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size'
import React, { useEffect, useRef, useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiImage, EuiLoadingSpinner, EuiPortal, EuiSpacer, EuiTab, EuiTabs, EuiText, EuiTitle, EuiCallOut, EuiLink, EuiTextColor } from '@elastic/eui'

import { AcImage } from 'api/interfaces'
import config from 'app/config'
import { RootState } from 'app/rootReducer'
import { getAccessToken } from 'services/LocalStoreManagerService'
import useDebounce from 'utils/useDebounce'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import { fetchHints, fetchUploadedImages, searchImages, chooseImage } from './searchImageSlice'

registerPlugin(FilePondPluginFileValidatationType, FilePondPluginImageValidateSize, FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

export interface ChooseImageFlyoutProps {
  defaultSearch: string
}

const ChooseImageFlyout = ({ defaultSearch }: ChooseImageFlyoutProps) => {
  const dispatch = useDispatch()
  const searchImage = useSelector((state: RootState) => state.searchImage)
  const { currentOrganization, currentAccount } = useSelector((state: RootState) => state.app)
  const [isFlyoutVisible, setIsFlyoutVisible] = useState<boolean>(false)
  const [selectedImage, setSelectedImage] = useState<AcImage | null>(null)
  const [selectedTab, setSelectedTab] = useState<string>('uploadImageTab')
  const [searchPopoverOpen, setSearchPopoverOpen] = useState<boolean>(false)
  const [searchHint, setSearchHint] = useState<string>('')
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const debouncedSearchHint = useDebounce(searchHint, 500)

  const pondRef = useRef<FilePond>(null)

  useEffect(() => {
    if (pondRef.current && selectedTab !== 'uploadImageTab') {
      pondRef.current!.removeFiles()
    }
  }, [selectedTab])

  useEffect(() => {
    dispatch(fetchUploadedImages(currentAccount!.id))
  }, [dispatch, currentAccount])

  const onSelectedTabChanged = (id: string) => {
    setSelectedTab(id)
  }
  const closeFlyout = () => setIsFlyoutVisible(false)

  const saveFlyout = () => {
    if (selectedImage) {
      dispatch(chooseImage(selectedImage))
    }
    setIsFlyoutVisible(false)
  }

  const showFlyout = () => {
    setIsFlyoutVisible(true)
  }

  const onSearchHintClick = (hint: string) => {
    setSearchHint(hint)
    setSearchPopoverOpen(false)
    dispatch(searchImages(hint, searchImage.pageSize, 1))
  }

  const onSearch = () => {
    setSearchPopoverOpen(false)
    dispatch(searchImages(searchHint, searchImage.pageSize, 1))
  }

  const onSearchChange = (hint: string) => {
    setSearchHint(hint)
  }

  useEffect(() => {
    if (debouncedSearchHint) {
      dispatch(fetchHints(debouncedSearchHint))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchHint, dispatch])

  const goToPage = (pageNumber: number) => {
    dispatch(searchImages(searchHint, searchImage.pageSize, pageNumber))
  }

  const onButtonClick = () => setIsPopoverOpen(isPopoverOpen => !isPopoverOpen)
  const closePopover = () => setIsPopoverOpen(false)

  let flyout
  if (isFlyoutVisible) {
    flyout = (
      <EuiPortal>
        <EuiFlyout onClose={closeFlyout} size='l' aria-labelledby='flyoutLargeTitle' className='flyout'>
          <EuiFlyoutHeader hasBorder>
            <EuiTitle size='s'>
              <h3>Image Selection</h3>
            </EuiTitle>
            <EuiSpacer size='m' />
            <EuiTabs style={{ marginBottom: '-25px' }}>
              <EuiTab isSelected={selectedTab === 'uploadImageTab'} key={2} id='Upload' aria-controls='uploadImageTabPanel' onClick={() => onSelectedTabChanged('uploadImageTab')}>
                Upload Your Own Image
              </EuiTab>
            </EuiTabs>
          </EuiFlyoutHeader>
          <EuiFlyoutBody
            banner={
              <React.Fragment>
                {selectedTab === 'uploadImageTab' && (
                  <EuiCallOut title='Upload an Image' iconType='image' color='success'>
                    <EuiTextColor color='subdued'>
                      <p>All text should be entered in the Headline and Body fields for your ad.</p>
                      <p>
                        <strong>IMPORTANT: Do not upload images with text.</strong>
                      </p>
                      <p>
                        Images must be a minimum of <strong>1200x667</strong> pixels. The following file types are accepted: png, jpeg, jpg, gif.
                      </p>
                    </EuiTextColor>
                  </EuiCallOut>
                )}
              </React.Fragment>
            }>
            <div role='tabpanel' id='uploadImageTabPanel' aria-labelledby='uploadImageTab' hidden={selectedTab !== 'uploadImageTab'}>
              <FilePond
                ref={pondRef}
                allowMultiple={false}
                acceptedFileTypes={['image/png', 'image/jpeg', 'image/gif']}
                server={{
                  process: {
                    url: `${config.api.BASEURL}accounts/${currentAccount?.id || ''}/images/upload`,
                    method: 'POST',
                    headers: {
                      Authorization: `Bearer ${getAccessToken()}`,
                      'AC-OrganizationId': currentOrganization?.id || ''
                    },
                    onload: res => {
                      pondRef.current!.removeFiles()
                      dispatch(fetchUploadedImages(currentAccount!.id))
                      return res.key
                    }
                  }
                }}
                allowImageValidateSize={true}
                imageValidateSizeMaxWidth={2000}
                imageValidateSizeMaxHeight={2000}
                allowImagePreview={true}
                imagePreviewHeight={240}
                stylePanelLayout='compact'
                styleLoadIndicatorPosition='center bottom'
              />
              {searchImage.uploadedImages.length > 0 && (
                <EuiText size='s'>
                  <h4>Uploaded Images</h4>
                </EuiText>
              )}
              {searchImage.isLoadingUploadedImages ? (
                <EuiLoadingSpinner size='xl' />
              ) : (
                <EuiFlexGrid>
                  {searchImage.uploadedImages.map(i => (
                    <EuiFlexItem key={i.id}>
                      <EuiImage alt={i.id} url={i.squareUrl} size='s' hasShadow={true} onClick={() => setSelectedImage(i)} className={selectedImage === i ? 'selectableImage selectableImage-selected' : 'selectableImage'} />
                    </EuiFlexItem>
                  ))}
                </EuiFlexGrid>
              )}
            </div>
          </EuiFlyoutBody>
          <EuiFlyoutFooter>
            <EuiFlexGroup justifyContent='spaceBetween'>
              <EuiFlexItem grow={false}>
                <EuiButton id='choose' onClick={saveFlyout} fill disabled={selectedImage == null}>
                  Choose
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlyoutFooter>
        </EuiFlyout>
      </EuiPortal>
    )
  }

  return (
    <React.Fragment>
      {searchImage.chosenImage === null ? (
        <EuiLink onClick={showFlyout} className='euiFilePicker__clearButton' id='selectImage'>
          Choose Image
        </EuiLink>
      ) : (
        <EuiLink onClick={showFlyout} className='euiFilePicker__clearButton' id='changeImage'>
          Change Image
        </EuiLink>
      )}
      {flyout}
    </React.Fragment>
  )
}

export default ChooseImageFlyout
