import { MobileDashboardAnalytic } from 'api/interfaces/MobileDashboardAnalytic'
import { BaseQuery } from 'api/rtkQueryApi/platform/axiosBaseQuery'
import { defaultTransformFn, platformApi } from 'api/rtkQueryApi/platform/platformApi'

const mobileDashboardTags = platformApi.enhanceEndpoints({ addTagTypes: ['MobileDashboard'] })

export const mobileDashboardApi = mobileDashboardTags.injectEndpoints({
  endpoints: build => ({
    getMobileDashboardAnalytics: build.query<MobileDashboardAnalytic[], BaseQuery>({
      query: ({ accountId, isAgency }) => ({
        url: `${accountId}/mobile/dashboard`,
        method: 'GET',
        isContext: true,
        isAgency: isAgency
      }),
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { accountId }) => [{ type: 'MobileDashboard', id: accountId }]
    })
  }),
  overrideExisting: false
})

export const { useGetMobileDashboardAnalyticsQuery } = mobileDashboardApi
