import React from 'react'

import { EuiSpacer } from '@elastic/eui'

import { AcCardSelector, IAcCard } from 'components/Collections/AcCardSelector'
import { useCampaignBuilderActions } from 'features/builders/createCampaign/useCampaignBuilderActions'

const CampaignTypeSelectPage: React.FC = () => {
  const { beginTvCampaign, beginInternetCampaign, beginBillboardCampaign } = useCampaignBuilderActions()

  const cards: IAcCard[] = [
    {
      id: 'tvTile',
      iconType: 'play',
      title: 'Targeted TV',
      description: 'Create a new Targeted TV campaign',
      onClick: beginTvCampaign,
      footer: 'Create, target and deliver TV commercials to drive your business.'
    },
    {
      id: 'internetTile',
      iconType: 'globe',
      title: 'Internet',
      description: 'Create a new Internet Ad campaign',
      onClick: beginInternetCampaign,
      footer: 'Create, target and deliver internet ads to drive your business.'
    },
    {
      id: 'billboardTile',
      iconType: 'fullScreen',
      title: 'Billboards',
      description: 'Create a new Billboard campaign',
      onClick: beginBillboardCampaign,
      footer: 'Create and deliver large-format digital billboard ads to drive your business.'
    }
  ]

  return (
    <React.Fragment>
      <EuiSpacer />
      <AcCardSelector cards={cards} cardsPerRow={2} />
      <EuiSpacer />
    </React.Fragment>
  )
}

export default CampaignTypeSelectPage
