import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiPortal, EuiSpacer, EuiTitle } from '@elastic/eui'

import { useGetAccountsQuery } from 'api/rtkQueryApi/platform/accountsApi'
import { hideAccountsFlyout, showAddAccountFlyout } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import AccountsGrid from 'features/accounts/AccountsGrid'
import AddAccountFlyout from 'features/accounts/AddAccount'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

const AccountsFlyout: FC = () => {
  const dispatch = useDispatch()
  const { accountsFlyoutOpen } = useSelector((state: RootState) => state.app)

  const closeFlyout = () => dispatch(hideAccountsFlyout())

  let flyout
  if (accountsFlyoutOpen) {
    flyout = (
      <EuiPortal>
        <EuiFlyout onClose={closeFlyout} size='l' aria-labelledby='flyoutLargeTitle' ownFocus hideCloseButton>
          <EuiFlyoutHeader hasBorder>
            <EuiTitle>
              <h4>Clients</h4>
            </EuiTitle>

            <EuiSpacer size='m' />

            <EuiButtonEmpty iconType='plusInCircle' iconSide='left' onClick={() => dispatch(showAddAccountFlyout())}>
              Add Client
            </EuiButtonEmpty>
          </EuiFlyoutHeader>

          <EuiFlyoutBody>
            <AccountsGrid />
          </EuiFlyoutBody>

          <EuiFlyoutFooter>
            <EuiFlexGroup justifyContent='spaceBetween'>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty id='close' iconType='cross' onClick={closeFlyout} flush='left'>
                  Close
                </EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlyoutFooter>
        </EuiFlyout>
      </EuiPortal>
    )
  }

  return (
    <React.Fragment>
      {flyout}
      <AddAccountFlyout />
    </React.Fragment>
  )
}

export default AccountsFlyout
