import React from 'react'

import { EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiHideFor, EuiIcon, EuiImage, EuiPanel, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { IAcDictionary } from 'utils/AcDictionary'

import ClearChannel from './ClearChannel.png'
import LamarPNG from './Lamar.png'
import OutfrontPNG from './Outfront.png'

export interface BillboardLegendProps {
  colorMap: IAcDictionary
}

export const BillboardLegend: React.FC<BillboardLegendProps> = ({ colorMap }) => {
  return (
    <EuiHideFor sizes={['xs', 's']}>
      <EuiSpacer />
      <EuiFlexGroup style={{ flexGrow: 0 }}>
        <EuiFlexItem>
          <EuiPanel>
            <EuiTitle size='xs'>
              <h4>Digital Billboard Legend</h4>
            </EuiTitle>
            <EuiSpacer size='s' />
            <EuiFlexGrid columns={4} gutterSize='none'>
              <EuiFlexItem>
                <EuiFlexGroup direction='column' gutterSize='none'>
                  <EuiFlexItem>
                    <EuiFlexGroup gutterSize='none'>
                      <EuiFlexItem grow={false} style={{ width: 22 }}>
                        <EuiIcon type='dot' size='l' color={colorMap['Lamar'] as string} />
                      </EuiFlexItem>
                      <EuiFlexItem grow={true}>
                        <EuiText size='s' style={{ marginTop: 2 }}>
                          <strong>Lamar Advertising</strong>
                        </EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText size='xs' style={{ marginLeft: 24 }}>
                      The largest network of digital billboards in the United States.
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiImage alt={'Lamar'} src={LamarPNG} size='s' style={{ marginLeft: 20, width: 120, height: 23 }} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFlexGroup direction='column' gutterSize='none'>
                  <EuiFlexItem>
                    <EuiFlexGroup gutterSize='none'>
                      <EuiFlexItem grow={false} style={{ width: 22 }}>
                        <EuiIcon type='dot' size='l' color={colorMap['Outfront'] as string} />
                      </EuiFlexItem>
                      <EuiFlexItem grow={true}>
                        <EuiText size='s' style={{ marginTop: 2 }}>
                          <strong>Outfront Media</strong>
                        </EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText size='xs' style={{ marginLeft: 24 }}>
                      Digital billboards in top markets across the country.
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiImage alt={'Outfront'} src={OutfrontPNG} size='s' style={{ marginLeft: 11, width: 120, height: 23 }} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFlexGroup direction='column' gutterSize='none'>
                  <EuiFlexItem>
                    <EuiFlexGroup gutterSize='none'>
                      <EuiFlexItem grow={false} style={{ width: 22 }}>
                        <EuiIcon type='dot' size='l' color={colorMap['ClearChannel'] as string} />
                      </EuiFlexItem>
                      <EuiFlexItem grow={true}>
                        <EuiText size='s' style={{ marginTop: 2 }}>
                          <strong>Clear Channel Outdoor</strong>
                        </EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText size='xs' style={{ marginLeft: 24 }}>
                      The fastest growing network of digital billboards in the U.S.
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiImage alt={'Clear Channel'} src={ClearChannel} size='s' style={{ marginLeft: 20, width: 120, height: 23 }} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFlexGroup direction='column' gutterSize='none'>
                  <EuiFlexItem>
                    <EuiFlexGroup gutterSize='none'>
                      <EuiFlexItem grow={false} style={{ width: 22 }}>
                        <EuiIcon type='dot' size='l' color={colorMap['Other'] as string} />
                      </EuiFlexItem>
                      <EuiFlexItem grow={true}>
                        <EuiText size='s' style={{ marginTop: 2 }}>
                          <strong>Other</strong>
                        </EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText size='xs' style={{ marginLeft: 24 }}>
                      AdCritter has access to 100% of the digital billboards in the U.S.
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem />
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGrid>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiHideFor>
  )
}
