import React, { FC } from 'react'

import { AreaSeries, Axis, Chart, LIGHT_THEME, PartialTheme, Position, ScaleType, Settings } from '@elastic/charts'
import { CurveType } from '@elastic/charts/dist/utils/curves'
import { useGeneratedHtmlId } from '@elastic/eui'

import { Insights } from 'api/interfaces/Insights'
import { adFatigueChartFatigueArea, adFatigueChartViewArea } from 'components/insights/InsightsConstants'

export interface InsightsAdFatigueChartProps {
  adFatigue?: Insights
}

const InsightsAdFatigueChart: FC<InsightsAdFatigueChartProps> = ({ adFatigue }) => {
  const chartId = useGeneratedHtmlId()

  const chartDataNonFatigued = [] as any[]
  const chartDataFatigued = [] as any[]

  for (const item in adFatigue?.byMonth) {
    let yValNonFatigued: number | null = adFatigue?.byMonth[item].value ?? 0
    let yValFatigued: number | null = adFatigue?.byMonth[item].fatigue ?? 0

    if (yValNonFatigued === -1) {
      yValNonFatigued = null
      yValFatigued = null
    }

    chartDataNonFatigued.push({ x: item, y: yValNonFatigued })
    chartDataFatigued.push({ x: item, y: yValFatigued })
  }

  const theme: PartialTheme = {
    areaSeriesStyle: {
      line: {
        strokeWidth: 0
      }
    }
  }

  return (
    <Chart id={chartId} size={{ height: 100 }}>
      <Settings theme={theme} baseTheme={LIGHT_THEME} />
      <AreaSeries data={chartDataNonFatigued} id='Views' xAccessor='x' yAccessors={['y']} xScaleType={ScaleType.Ordinal} yScaleType={ScaleType.Linear} curve={CurveType.CURVE_MONOTONE_X} stackAccessors={[0]} color={[adFatigueChartViewArea]} />
      <AreaSeries data={chartDataFatigued} id='Fatigued' xAccessor='x' yAccessors={['y']} xScaleType={ScaleType.Ordinal} yScaleType={ScaleType.Linear} curve={CurveType.CURVE_MONOTONE_X} stackAccessors={[0]} color={[adFatigueChartFatigueArea]} />
      <Axis id='date' position={Position.Bottom} tickFormat={d => d.toString()} />
    </Chart>
  )
}

export default InsightsAdFatigueChart
