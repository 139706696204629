import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiFieldSearch, EuiInputPopover, EuiSelectable, EuiText, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { CityTarget } from 'api/interfaces'
import { clearCityTargets, fetchCityTargets } from 'app/dictionarySlice'
import { RootState } from 'app/rootReducer'
import useDebounce from 'utils/useDebounce'

interface NewCityTargeterProps {
  isInvalid: boolean
  onCityClicked(city: CityTarget): void
}

export const NewCityFinder = ({ onCityClicked, isInvalid }: NewCityTargeterProps) => {
  const dispatch = useDispatch()
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const [citySearchTerm, setCitySearchTerm] = useState<string>('')
  const debouncedCitySearchTerm = useDebounce(citySearchTerm, 750)
  const [searchCityPopoverOpen, setSearchCityPopoverOpen] = useState<boolean>(false)
  const [cities, setCities] = useState<Array<EuiSelectableOption>>([])

  const onSearchCity = () => {
    setSearchCityPopoverOpen(false)
  }

  const onSearchCityChange = (hint: string) => {
    setCitySearchTerm(hint)
  }

  const onSearchCityClick = useCallback(
    (city: CityTarget) => {
      setSearchCityPopoverOpen(false)
      setCitySearchTerm('')
      onCityClicked(city)
    },
    [onCityClicked]
  )

  useEffect(() => {
    if (debouncedCitySearchTerm) {
      dispatch(fetchCityTargets(debouncedCitySearchTerm))
    } else {
      dispatch(clearCityTargets())
    }
  }, [debouncedCitySearchTerm, dispatch])

  useEffect(() => {
    if (dictionary.cityTargets) {
      setCities(
        dictionary.cityTargets.map(
          c =>
            ({
              label: `${c.name}, ${c.regionCode}`,
              key: c.id.toString(),
              checked: undefined,
              onClick: () => onSearchCityClick(c)
            } as EuiSelectableOption)
        )
      )
    }
  }, [dictionary.cityTargets, onSearchCityClick])

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          value={citySearchTerm}
          isLoading={dictionary.isLoadingCityTargets}
          onChange={v => onSearchCityChange(v.target.value)}
          onFocus={() => setSearchCityPopoverOpen(true)}
          incremental={false}
          onSearch={() => onSearchCity()}
          placeholder='Enter one or more cities...'
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
          isInvalid={isInvalid}
        />
      }
      isOpen={searchCityPopoverOpen}
      closePopover={() => setSearchCityPopoverOpen(false)}>
      {dictionary.cityTargets && dictionary.cityTargets.length ? (
        <EuiSelectable singleSelection options={cities}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search... results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
