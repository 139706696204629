import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDebounce from 'utils/useDebounce'

import { EuiFieldSearch, EuiInputPopover, EuiSelectable, EuiText, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { Zip } from 'api/interfaces'
import { clearZips, fetchZips } from 'app/dictionarySlice'
import { RootState } from 'app/rootReducer'

interface ZipTargeterProps {
  isInvalid: boolean

  onZipClicked(zip: Zip): void
}

export const ZipFinder = ({ onZipClicked, isInvalid }: ZipTargeterProps) => {
  const dispatch = useDispatch()
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const [zipSearchTerm, setZipSearchTerm] = useState<string>('')
  const debouncedZipSearchTerm = useDebounce(zipSearchTerm, 750)
  const [searchZipPopoverOpen, setSearchZipPopoverOpen] = useState<boolean>(false)
  const [zips, setZips] = useState<Array<EuiSelectableOption>>([])

  const onSearchZip = () => {
    setSearchZipPopoverOpen(false)
  }

  const onSearchZipChange = (hint: string) => {
    setZipSearchTerm(hint)
  }

  const onSearchZipClick = useCallback(
    (zip: Zip) => {
      setSearchZipPopoverOpen(false)
      setZipSearchTerm('')
      onZipClicked(zip)
    },
    [onZipClicked]
  )

  useEffect(() => {
    if (debouncedZipSearchTerm) {
      dispatch(fetchZips(debouncedZipSearchTerm))
    } else {
      dispatch(clearZips())
    }
  }, [debouncedZipSearchTerm, dispatch])

  useEffect(() => {
    if (dictionary.zips) {
      setZips(
        dictionary.zips.map(
          z =>
            ({
              label: z.name,
              key: z.id.toString(),
              checked: undefined,
              onClick: () => onSearchZipClick(z)
            } as EuiSelectableOption)
        )
      )
    }
  }, [dictionary.zips, onSearchZipClick])

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          value={zipSearchTerm}
          isLoading={dictionary.isLoadingZips}
          onChange={v => onSearchZipChange(v.target.value)}
          onFocus={() => setSearchZipPopoverOpen(true)}
          incremental={false}
          onSearch={() => onSearchZip()}
          placeholder='Type to begin search...'
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
          isInvalid={isInvalid}
        />
      }
      isOpen={searchZipPopoverOpen}
      closePopover={() => setSearchZipPopoverOpen(false)}>
      {dictionary.zips && dictionary.zips.length ? (
        <EuiSelectable singleSelection options={zips}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search... results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
