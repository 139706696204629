import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { useShopifyCreateMutation } from 'api/rtkQueryApi/platform/shopifyApi'
import { updateAppState } from 'app/appSlice'
import { LoggingUserInPage } from 'components/authentication/loggingUserInPage'
import history from 'services/HistoryService'

const AuthenticatePage: React.FC = () => {
  const { search } = useLocation()
  const dispatch = useDispatch()
  const [createShopifyUser, createShopifyUserResponse] = useShopifyCreateMutation()

  useEffect(() => {
    if (search) {
      createShopifyUser({ query: search })
    }
  }, [search])

  useEffect(() => {
    if (createShopifyUserResponse.data && !createShopifyUserResponse.isLoading) {
      const createData = createShopifyUserResponse.data
      if (!!createData.accessToken) {
        dispatch(
          updateAppState({
            isLoggedIn: true,
            accessToken: createData.accessToken,
            account: createData.accounts[0],
            organization: createData.organization,
            user: {
              name: createData.name,
              email: createData.email,
              roles: createData.roles,
              picture: createData.picture,
              canAccessAllAccounts: createData.canAccessAllAccounts
            },
            isInSignupProcess: createData.isInSignupProcess,
            isNewlyCreated: createData.isNewlyCreated
          })
        )
        history.push('/dashboard?showWelcome=true')
      } else if (!!createData.redirectUrl) {
        window.location.assign(createData.redirectUrl)
      }
    }
  }, [createShopifyUserResponse])

  return <LoggingUserInPage />
}

export default AuthenticatePage
