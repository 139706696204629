import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiRadio, EuiSpacer, EuiText, EuiTextArea, EuiTitle } from '@elastic/eui'

import { VistaTargeting, City } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import { CityFinder, DmaFinder, StateFinder } from 'components/finders'
import { CityTargeter, DmaTargeter, StateTargeter } from 'components/targeters'
import history from 'services/HistoryService'

import { setVistaTargeting } from './builderInternetVistaSlice'

interface FormValues {
  locationType: string
  states: Array<string>
  dmas: Array<string>
  cities: Array<City>
  keywords: string
}

const TargetStep: React.FC = () => {
  const dispatch = useDispatch()
  const builder = useSelector((state: RootState) => state.builderInternetVista)
  const [initialValues, setInitialValues] = useState<FormValues>({
    locationType: 'City',
    states: [],
    dmas: [],
    cities: [],
    keywords: ''
  })

  const advancedLocationTargetingSchema = Yup.object().shape({
    locationType: Yup.string().required(),
    states: Yup.array().when('locationType', {
      is: 'State',
      then: Yup.array().min(1, 'Please select at least 1 state').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    dmas: Yup.array().when('locationType', {
      is: 'DMA',
      then: Yup.array().min(1, 'Please select at least 1 metro area / DMA').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    cities: Yup.array().when('locationType', {
      is: 'City',
      then: Yup.array().min(1, 'Please add at least 1 city').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    keywords: Yup.string().required('Please enter at least 1 keyword')
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: advancedLocationTargetingSchema,
    onSubmit: (values: FormValues) => {
      const targeting: VistaTargeting = {
        ...builder.vistaTargeting!,
        locationType: values.locationType,
        states: values.states,
        dmas: values.dmas,
        cities: values.cities,
        keywords: values.keywords
          .split('\n')
          .map(k => k.trim().toLowerCase())
          .filter(k => k !== ''),
        negativeKeywords: []
      }
      dispatch(setVistaTargeting(targeting))
      history.push('/build/internet/vista/activate')
    }
  })

  useEffect(() => {
    if (builder.vistaTargeting) {
      setInitialValues({
        locationType: builder.vistaTargeting.locationType,
        states: builder.vistaTargeting.states,
        dmas: builder.vistaTargeting.dmas,
        cities: builder.vistaTargeting.cities,
        keywords: builder.vistaTargeting.keywords.join('\n')
      })
    }

    if (builder.businessType && builder.businessType.keywords && (!builder.vistaTargeting || builder.vistaTargeting.keywords.length === 0)) {
      setInitialValues(prev => {
        let iv = Object.assign({}, prev)
        if (builder.businessType) {
          iv.keywords = builder.businessType.keywords.concat(builder.businessType.additionalKeywords).join('\n')
        }
        return iv
      })
    }
  }, [builder.vistaTargeting, builder.businessType])

  const returnCreateStep = () => {
    history.push('/build/internet/vista/create')
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Targeting</title>
      </Helmet>
      <EuiFlexGroup>
        <EuiFlexItem grow={true}>
          <EuiTitle size='s'>
            <h3>Location</h3>
          </EuiTitle>
          <EuiText size='s'>
            <div>Define the location(s) you want to target. You can target specific cities below.</div>
          </EuiText>
          <EuiSpacer size='s' />
          <FormikProvider value={formik}>
            <EuiForm id='targetingForm' component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.status && !!formik.errors} error={formik.status}>
              <EuiRadio
                id='state'
                name='locationType'
                value='State'
                label={
                  <EuiText size='s'>
                    <strong>Target by State</strong> (one or more U.S. States)
                  </EuiText>
                }
                checked={formik.values.locationType === 'State'}
                onChange={() => {
                  formik.setFieldValue('locationType', 'State', true)
                }}
              />
              <EuiSpacer size='s' />
              <EuiRadio
                id='dma'
                name='locationType'
                value='DMA'
                label={
                  <EuiText size='s'>
                    <strong>Target by Metro Area / DMA</strong> (one or more)
                  </EuiText>
                }
                checked={formik.values.locationType === 'DMA'}
                onChange={() => {
                  formik.setFieldValue('locationType', 'DMA', true)
                }}
              />
              <EuiSpacer size='s' />
              <EuiRadio
                id='city'
                name='locationType'
                value='City'
                label={
                  <EuiText size='s'>
                    <strong>Target by City</strong> (one or more)
                  </EuiText>
                }
                checked={formik.values.locationType === 'City'}
                onChange={() => {
                  formik.setFieldValue('locationType', 'City', true)
                }}
              />
              <EuiSpacer />

              <div hidden={formik.values.locationType !== 'State'}>
                <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. states below.' />
                <EuiSpacer size='s' />

                <EuiFormRow id='stateFinder' label='Search for a state' fullWidth isInvalid={!!formik.touched.states && !!formik.errors.states} error={formik.errors.states}>
                  <StateFinder addState={stateToAdd => formik.setFieldValue('states', [...formik.values.states, stateToAdd])} isInvalid={!!formik.touched.states && !!formik.errors.states} />
                </EuiFormRow>
                {formik.values.states && formik.values.states.length > 0 && (
                  <EuiFormRow label='Your campaign will target these states' fullWidth>
                    <StateTargeter
                      states={formik.values.states}
                      onStateRemoved={state =>
                        formik.setFieldValue(
                          'states',
                          formik.values.states.filter(x => x !== state),
                          true
                        )
                      }
                    />
                  </EuiFormRow>
                )}
              </div>

              <div hidden={formik.values.locationType !== 'DMA'}>
                <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more metro areas / DMAs below.' />
                <EuiSpacer size='s' />

                <EuiFormRow id='dmaFinder' label='Search for a metro area' fullWidth isInvalid={!!formik.touched.dmas && !!formik.errors.dmas} error={formik.errors.dmas}>
                  <DmaFinder addDma={dmaToAdd => formik.setFieldValue('dmas', [...formik.values.dmas, dmaToAdd])} isInvalid={!!formik.touched.dmas && !!formik.errors.dmas} />
                </EuiFormRow>
                {formik.values.dmas && formik.values.dmas.length > 0 && (
                  <EuiFormRow label='Your campaign will target these metro areas' fullWidth>
                    <DmaTargeter
                      dmas={formik.values.dmas}
                      onDmaRemoved={dma =>
                        formik.setFieldValue(
                          'dmas',
                          formik.values.dmas.filter(x => x !== dma),
                          true
                        )
                      }
                    />
                  </EuiFormRow>
                )}
              </div>

              <div hidden={formik.values.locationType !== 'City'}>
                <EuiFormRow id='cityFinder' label='Cities:' fullWidth isInvalid={!!formik.touched.cities && !!formik.errors.cities} error={formik.errors.cities}>
                  <CityFinder onCityClicked={city => formik.setFieldValue('cities', [...formik.values.cities.filter(c => c.id !== city.id), city])} isInvalid={!!formik.touched.cities && !!formik.errors.cities} />
                </EuiFormRow>
                {formik.values.cities && formik.values.cities.length > 0 && (
                  <EuiFormRow label='Your campaign will target these cities' fullWidth>
                    <CityTargeter
                      cities={formik.values.cities}
                      onCityRemoved={city =>
                        formik.setFieldValue(
                          'cities',
                          formik.values.cities.filter(c => c.id !== city.id)
                        )
                      }
                    />
                  </EuiFormRow>
                )}
              </div>
              <EuiSpacer />

              <EuiTitle size='s'>
                <h3>Keywords</h3>
              </EuiTitle>
              <EuiText size='s'>
                <div>Define the keywords your ideal audience uses.</div>
              </EuiText>
              <EuiSpacer size='s' />
              <EuiFormRow label='Keywords' fullWidth helpText='One keyword per line' isInvalid={formik.touched.keywords && !!formik.errors.keywords} error={formik.errors.keywords}>
                <EuiTextArea name='keywords' value={formik.values.keywords} fullWidth isInvalid={formik.touched.keywords && !!formik.errors.keywords} onBlur={formik.handleBlur} onChange={x => formik.setFieldValue('keywords', x.target.value.replace(',', '\n').toLowerCase())} rows={10} />
              </EuiFormRow>
              <EuiSpacer />

              <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  <EuiButton id='back' fill type='button' color='text' onClick={returnCreateStep} iconType='arrowLeft' iconSide='left'>
                    Back
                  </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton id='finalize' fill type='submit' isLoading={formik.isSubmitting} iconType='arrowRight' iconSide='right' onClick={formik.submitForm}>
                    Continue
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiForm>
          </FormikProvider>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default TargetStep
