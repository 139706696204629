import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiLoadingContent, EuiRadio, EuiSpacer, EuiText, EuiTextArea, EuiTitle } from '@elastic/eui'

import { ApiError, apiPutCampaignVistaTargeting } from 'api/adcritterApi'
import { City } from 'api/interfaces'
import { showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { CityFinder, DmaFinder, StateFinder } from 'components/finders'
import { CityTargeter, DmaTargeter, StateTargeter } from 'components/targeters'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import { finishedLoadingCampaign } from '../campaignSlice'

interface FormValues {
  locationType: string
  states: Array<string>
  dmas: Array<string>
  cities: Array<City>
  keywords: string
}

const VistaDrawer: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const campaign = useSelector((state: RootState) => state.campaign)
  const [formValues, setFormValues] = useState<FormValues>({
    locationType: 'City',
    states: [],
    dmas: [],
    cities: [],
    keywords: ''
  })
  const whiteLabel = useWhiteLabel()

  useEffect(() => {
    if (campaign.campaign && campaign.campaign.advancedTargeting) {
      setFormValues({
        locationType: campaign.campaign.advancedTargeting.locationType ?? 'City',
        states: campaign.campaign.advancedTargeting.states,
        dmas: campaign.campaign.advancedTargeting.dmas,
        cities: campaign.campaign.advancedTargeting.cities,
        keywords: campaign.campaign.advancedTargeting.keywords.join('\n')
      })
    }
  }, [campaign.campaign])

  const advancedLocationSchema = Yup.object().shape({
    locationType: Yup.string().required(),
    states: Yup.array().when('locationType', {
      is: 'State',
      then: Yup.array().min(1, 'Please select at least 1 state').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    dmas: Yup.array().when('locationType', {
      is: 'DMA',
      then: Yup.array().min(1, 'Please select at least 1 DMA/metro area').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    cities: Yup.array().when('locationType', {
      is: 'City',
      then: Yup.array().min(1, 'Please add at least 1 city').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    keywords: Yup.string().required('Please enter at least 1 keyword')
  })

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: advancedLocationSchema,
    onSubmit: (values: FormValues) => {
      apiPutCampaignVistaTargeting(currentAccount!.id, campaign.campaign!.id, {
        locationType: values.locationType,
        states: values.states,
        dmas: values.dmas,
        cities: values.cities.map(c => c.id),
        keywords: values.keywords
          .split('\n')
          .map(k => k.trim().toLowerCase())
          .filter(k => k !== '')
      })
        .then(response => {
          dispatch(showSuccessToast('Saved targeting'))
          dispatch(finishedLoadingCampaign(response.data))
          formik.setSubmitting(false)
          formik.setStatus(null)
        })
        .catch(response => {
          formik.setSubmitting(false)
          response.errors.forEach(function (error: ApiError) {
            formik.setFieldError(error.name, error.message)
          })
        })
    }
  })

  let content

  if (campaign.isLoadingCampaign) {
    content = <EuiLoadingContent lines={3} />
  } else {
    content = (
      <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
        <EuiRadio
          id='state'
          name='locationType'
          value='State'
          label={
            <EuiText size='s'>
              <strong>Targeting Using State</strong> (one or more U.S. States)
            </EuiText>
          }
          checked={formik.values.locationType === 'State'}
          onChange={() => {
            formik.setFieldValue('locationType', 'State', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='dma'
          name='locationType'
          value='DMA'
          label={
            <EuiText size='s'>
              <strong>Targeting Using DMA/Metro Area</strong> (one or more DMAs)
            </EuiText>
          }
          checked={formik.values.locationType === 'DMA'}
          onChange={() => {
            formik.setFieldValue('locationType', 'DMA', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='city'
          name='locationType'
          value='City'
          label={
            <EuiText size='s'>
              <strong>Targeting Using City</strong> (one or more City names)
            </EuiText>
          }
          checked={formik.values.locationType === 'City'}
          onChange={() => {
            formik.setFieldValue('locationType', 'City', true)
          }}
        />

        <EuiSpacer />

        <div hidden={formik.values.locationType !== 'State'}>
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can select U.S. States below, multiple States can be targeted at once.' />
          <EuiSpacer size='s' />

          <EuiFormRow label='Search for a State' fullWidth isInvalid={!!formik.errors.states} error={formik.errors.states}>
            <StateFinder addState={stateToAdd => formik.setFieldValue('states', [...formik.values.states, stateToAdd])} isInvalid={!!formik.errors.states} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these States:' fullWidth>
            <StateTargeter
              states={formik.values.states}
              onStateRemoved={stateToRemove =>
                formik.setFieldValue(
                  'states',
                  formik.values.states.filter(x => x !== stateToRemove),
                  true
                )
              }
            />
          </EuiFormRow>
        </div>

        <div hidden={formik.values.locationType !== 'DMA'}>
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can select a DMA/Metro Area(s) below, multiple DMAs can be targeted at once.' />
          <EuiSpacer size='s' />

          <EuiFormRow label='Search for a DMA' fullWidth isInvalid={!!formik.errors.dmas} error={formik.errors.dmas}>
            <DmaFinder addDma={dmaToAdd => formik.setFieldValue('dmas', [...formik.values.dmas, dmaToAdd])} isInvalid={!!formik.errors.dmas} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these DMAs:' fullWidth>
            <DmaTargeter
              dmas={formik.values.dmas}
              onDmaRemoved={dmaToRemove =>
                formik.setFieldValue(
                  'dmas',
                  formik.values.dmas.filter(x => x !== dmaToRemove),
                  true
                )
              }
            />
          </EuiFormRow>
        </div>

        <div hidden={formik.values.locationType !== 'City'}>
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can search for U.S. Cities below, multiple Cities can be targeted at once.' />
          <EuiSpacer size='s' />

          <EuiFormRow label='Search for a city' fullWidth isInvalid={!!formik.errors.cities} error={formik.errors.cities}>
            <CityFinder onCityClicked={city => formik.setFieldValue('cities', [...formik.values.cities, city])} isInvalid={!!formik.errors.cities} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these cities:' fullWidth>
            <CityTargeter
              cities={formik.values.cities}
              onCityRemoved={city => {
                formik.setFieldValue(
                  'cities',
                  formik.values.cities.filter(c => c.id !== city.id)
                )
              }}
            />
          </EuiFormRow>
        </div>
        <EuiSpacer />

        <EuiTitle size='s'>
          <h3>Keyword Targeting</h3>
        </EuiTitle>
        <EuiText size='s'>
          <div>Define the keywords your ideal audience uses.</div>
        </EuiText>
        <EuiSpacer size='s' />
        <EuiFormRow label='Keywords' fullWidth helpText='One keyword per line' isInvalid={formik.touched.keywords && !!formik.errors.keywords} error={formik.errors.keywords}>
          <EuiTextArea name='keywords' value={formik.values.keywords} fullWidth isInvalid={formik.touched.keywords && !!formik.errors.keywords} onBlur={formik.handleBlur} onChange={x => formik.setFieldValue('keywords', x.target.value.replace(',', '\n').toLowerCase())} rows={10} />
        </EuiFormRow>
        <EuiSpacer />

        <EuiButton id='save' fill type='submit' isLoading={formik.isSubmitting}>
          Save
        </EuiButton>
      </EuiForm>
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>{content}</EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: 260 }}>
          <EuiText size='xs'>
            <h3>Best Practices</h3>
            <h5>Bulk Targeting by Zip/Address</h5>
            <p>If you are wanting to target a long list of Zip Codes or Street Addresses, you may want to consider using the Upload an Audience option instead of entering them all by hand. Either will work just fine, but the bulk upload feature may save you time.</p>
            <h5>About Traffic Prioritization</h5>
            <p>{whiteLabel?.getName()} uses the characteristics you identify in Traffic Prioritization to favor people in the targeted location(s) who match your desired audience. For example, you could enter ‘golf’ to prioritize people in the specified geographic area interested in golfing.</p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default VistaDrawer
