import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiConfirmModal, EuiLoadingContent } from '@elastic/eui'

import { Campaign } from 'api/interfaces'
import { useUpdateCampaignNameAndBudgetMutation } from 'api/rtkQueryApi/platform/campaignsApi'
import { showErrorToast, showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'

export interface BudgetChangeModalProps {
  campaign: Campaign | null
  isBudgetChangeModalVisible: boolean
  name: string
  budget: number
  differenceAmount: number
  onConfirmComplete: (shouldRefetchCampaign: boolean) => void
  onCancel: () => void
}

const BudgetChangeModal: FC<BudgetChangeModalProps> = ({ campaign, isBudgetChangeModalVisible, name, budget, differenceAmount, onConfirmComplete, onCancel }) => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [updateCampaignNameAndBudget, updateCampaignNameAndBudgetRequest] = useUpdateCampaignNameAndBudgetMutation()

  useEffect(() => {
    if (updateCampaignNameAndBudgetRequest.isUninitialized || updateCampaignNameAndBudgetRequest.isLoading || !updateCampaignNameAndBudgetRequest.data) {
      return
    }

    if (updateCampaignNameAndBudgetRequest.error) {
      dispatch(showErrorToast('Campaign Failed to Saved'))
      onConfirmComplete(false)

      return
    }

    const { success, didAttemptToCharge } = updateCampaignNameAndBudgetRequest.data

    if (success) {
      if (didAttemptToCharge) {
        dispatch(showSuccessToast('Charged Payment Card and Saved Campaign'))
      } else {
        dispatch(showSuccessToast('Saved Campaign'))
      }

      onConfirmComplete(true)
    } else {
      if (didAttemptToCharge) {
        dispatch(showErrorToast('Failed to Charge Payment Card'))
      } else {
        dispatch(showErrorToast('Campaign Failed to Saved'))
      }

      onConfirmComplete(false)
    }
  }, [updateCampaignNameAndBudgetRequest])

  const increaseBudgetBilling = () => {
    if (!currentAccount || !campaign) {
      return
    }

    updateCampaignNameAndBudget({
      accountId: currentAccount.id,
      campaignId: campaign.id,
      name: name,
      budget: budget
    })
  }

  let budgetChangeModal

  if (isBudgetChangeModalVisible) {
    const title = 'Are you sure you want to increase the budget for this campaign?'
    const message = `To increase your campaign budget, we will charge your card ${differenceAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}, which is the pro rata amount until your campaign is next billed.`

    const confirmButtonText = 'Increase Budget'
    const onConfirm = () => {
      increaseBudgetBilling()
    }

    const cancelButtonText = 'Cancel'

    if (differenceAmount <= 0) {
      budgetChangeModal = <EuiLoadingContent lines={3} />
    } else {
      budgetChangeModal = (
        <EuiConfirmModal title={title} onConfirm={onConfirm} onCancel={onCancel} confirmButtonText={confirmButtonText} cancelButtonText={cancelButtonText} isLoading={updateCampaignNameAndBudgetRequest.isLoading}>
          <p>{message}</p>
        </EuiConfirmModal>
      )
    }
  }

  return budgetChangeModal ?? <></>
}

export default BudgetChangeModal
