import React, { ReactNode, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'

import { EuiPageBody, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiTitle } from '@elastic/eui'

import config from 'app/config'

import StartStep from './10_StartStep'
import SuggestStep from './20_SuggestStep'
import QuestionnaireStep from './21_QuestionnaireStep'
import QuestionnaireDoneStep from './22_QuestionnaireDoneStep'
import SuggestionsStep from './23_SuggestionsStep'
import CreateStep from './30_CreateStep'
import UploadStep from './31_UploadStep'
import { CreateAdStep, setCreateAdStep } from './createAdBillboardRetailSlice'

interface CreateAdPageParams {
  step: string
}

const Page: React.FC = () => {
  const dispatch = useDispatch()
  const { step } = useParams<CreateAdPageParams>()
  const [content, setContent] = useState<ReactNode | null>()

  useEffect(() => {
    switch (step) {
      default:
        setContent(<StartStep />)
        dispatch(setCreateAdStep(CreateAdStep.Start))
        break
      case 'suggest':
        if (config.features.billboardSuggestionsEnabled) {
          setContent(<SuggestStep />)
          dispatch(setCreateAdStep(CreateAdStep.Start))
        }
        break
      case 'questionnaire':
        setContent(<QuestionnaireStep />)
        dispatch(setCreateAdStep(CreateAdStep.Questionnaire))
        break
      case 'done':
        setContent(<QuestionnaireDoneStep />)
        dispatch(setCreateAdStep(CreateAdStep.QuestionnaireDone))
        break
      case 'suggestions':
        if (config.features.billboardSuggestionsEnabled) {
          setContent(<SuggestionsStep />)
          dispatch(setCreateAdStep(CreateAdStep.Suggestions))
        }
        break
      case 'create':
        setContent(<CreateStep />)
        dispatch(setCreateAdStep(CreateAdStep.Create))
        break
      case 'upload':
        setContent(<UploadStep />)
        dispatch(setCreateAdStep(CreateAdStep.Upload))
        break
    }
  }, [step, dispatch])

  return (
    <React.Fragment>
      <EuiPageBody>
        <EuiPageHeader>
          <EuiPageHeaderSection>
            <EuiTitle>
              <h1>New Billboard Ad</h1>
            </EuiTitle>
          </EuiPageHeaderSection>
        </EuiPageHeader>

        <EuiPanel grow={false} className='eui-xScroll'>
          {content}
        </EuiPanel>
      </EuiPageBody>
    </React.Fragment>
  )
}

export default Page
