import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { EuiLoadingContent, EuiPanel, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { apiLoginWithRefreshToken } from 'api/adcritterApi'
import { updateAccountsState, updateAppState } from 'app/appSlice'
import { fetchDictionary } from 'app/dictionarySlice'
import history from 'services/HistoryService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

const RefreshTokenLoginPage: React.FC = () => {
  const dispatch = useDispatch()
  let { search } = useLocation()
  const query = new URLSearchParams(search)
  const token = query.get('token')
  const whiteLabel = useWhiteLabel()

  useEffect(() => {
    if (token) {
      apiLoginWithRefreshToken(decodeURIComponent(token)).then(async authResponse => {
        if (authResponse.errors) {
          history.push('/login')
        } else {
          dispatch(
            updateAppState({
              isLoggedIn: true,
              accessToken: authResponse.data.accessToken,
              account: authResponse.data.accounts[0],
              organization: authResponse.data.organization,
              user: {
                name: authResponse.data.name,
                email: authResponse.data.email,
                roles: authResponse.data.roles,
                picture: authResponse.data.picture,
                canAccessAllAccounts: authResponse.data.canAccessAllAccounts
              },
              isInSignupProcess: authResponse.data.isInSignupProcess
            })
          )
          dispatch(updateAccountsState(authResponse.data.accounts))
          dispatch(fetchDictionary())

          history.push('/')
        }
      })
    }
  }, [token, dispatch])

  return (
    <React.Fragment>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div
        style={{
          width: '100%',
          height: '100%',
          minHeight: 500,
          background: 'linear-gradient(0deg, #D3DAE6 50%, #232e40 50%)',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: 'repeat(3, 1fr)',
          justifyItems: 'center',
          alignItems: 'center'
        }}>
        <div style={{ height: 500, width: 350, gridRow: '2/3' }}>
          <div style={{ textAlign: 'center' }}>
            <img src={whiteLabel?.getLogo()} alt={whiteLabel?.getName()} style={{ marginBottom: 25, width: 220 }} />
          </div>
          <EuiPanel hasShadow={true}>
            <EuiTitle>
              <h2>Logging you in...</h2>
            </EuiTitle>
            <EuiSpacer />
            <EuiLoadingContent />
            <EuiSpacer />
            <EuiText size='s'>
              <p>Please wait while we access your dashboard.</p>
            </EuiText>
          </EuiPanel>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RefreshTokenLoginPage
