import { FieldArray, getIn, useFormik, FormikProvider } from 'formik'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ReactPlayer from 'react-player'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHideFor, EuiShowFor, EuiSpacer, EuiText, EuiTextArea, EuiTitle } from '@elastic/eui'

import { apiGetTvCommercialVideoGenericPreviewUrl, apiGetTvCommercialVideoPreviewUrl } from 'api/adcritterApi'
import { TvCommercialCustomization, TvCommercialPreviewCustomization } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

import { AdPlacementType, setCreate, setDeliver } from './builderTvRetailSlice'

const createTextSchema = Yup.object().shape({
  sections: Yup.array().of(Yup.string().required('Please enter the text to show for this section'))
})

const createVoiceSchema = Yup.object().shape({
  script: Yup.string().nullable()
})

const CreateStep: React.FC = () => {
  const processingUrl = 'https://video.adcritter.com/000-templates/processing.mp4'
  const dispatch = useDispatch()
  const builder = useSelector((state: RootState) => state.builderTvRetail)
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [isExportingVideo, setIsExportingVideo] = useState(false)
  const [previewUrl, setPreviewUrl] = useState<string>(processingUrl)
  const [playing, setPlaying] = useState(false)
  const [loop, setLoop] = useState(true)
  const [controls, setControls] = useState(false)
  const [schema, setSchema] = useState<Yup.AnySchema>(createTextSchema)

  useEffect(() => {
    if (builder.tvCommercial) {
      setSchema(builder.tvCommercial.type === 'VoiceOver' ? createVoiceSchema : createTextSchema)
    }
  }, [builder.tvCommercial])

  useEffect(() => {
    if (!currentAccount || !builder.tvCommercial || !builder.businessType) {
      return
    }

    if (!builder.previewUrl) {
      setIsExportingVideo(true)
      setPreviewUrl(processingUrl)
      setLoop(true)
      setPlaying(true)
      setControls(false)
      apiGetTvCommercialVideoGenericPreviewUrl(currentAccount.id, builder.tvCommercial.id, builder.businessType.id).then(result => {
        const checkExist = setInterval(async function () {
          if (doesVideoExist(result.data)) {
            clearInterval(checkExist)
            setPreviewUrl(result.data)
            setLoop(false)
            setControls(true)
            setPlaying(false)
            setIsExportingVideo(false)
          }
        }, 1000)
      })
    } else {
      setPreviewUrl(builder.previewUrl)
      setLoop(false)
      setControls(true)
    }
  }, [currentAccount, builder.previewUrl, builder.tvCommercial, builder.businessType])

  const formik = useFormik({
    initialValues: builder.create!,
    validationSchema: schema,
    onSubmit: (values: TvCommercialCustomization) => {
      dispatch(setCreate(values))
      dispatch(setDeliver({ placement: AdPlacementType.Everywhere, categories: [] }))
      history.push('/build/tv/public/target')
    }
  })

  const doesVideoExist = (url: string) => {
    let http = new XMLHttpRequest()
    http.open('HEAD', url, false)
    try {
      http.send()
      return http.status === 200
    } catch {
      return false
    }
  }

  const refreshVideoUrl = () => {
    dispatch(setCreate(formik.values))
    setIsExportingVideo(true)
    setPreviewUrl(processingUrl)
    setLoop(true)
    setPlaying(true)
    setControls(false)
    apiGetTvCommercialVideoPreviewUrl(currentAccount!.id, builder.tvCommercial!.id, { sections: formik.values.sections } as TvCommercialPreviewCustomization).then(result => {
      const checkExist = setInterval(async function () {
        if (doesVideoExist(result.data)) {
          clearInterval(checkExist)
          setPreviewUrl(result.data)
          setLoop(false)
          setControls(true)
          setPlaying(false)
          setIsExportingVideo(false)
        }
      }, 1000)
    })
  }

  const gotoSuggestions = () => {
    history.push('/build/tv/public/suggestions')
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>TV Commercial Builder</title>
      </Helmet>
      <FormikProvider value={formik}>
        <React.Fragment>
          <EuiFlexGroup responsive={false}>
            <EuiFlexItem grow={true} style={{ minWidth: 280 }}>
              <EuiTitle size='s'>
                <h3>Commercial Editor</h3>
              </EuiTitle>
              <EuiText size='s'>
                <p>Edit the text of your TV commercial, or leave it as-is.</p>
              </EuiText>
              <EuiSpacer />

              {builder.tvCommercial && builder.tvCommercial.type === 'Text' && (
                <React.Fragment>
                  <EuiText size='s'>
                    <h4>Commercial Text</h4>
                  </EuiText>
                  <EuiSpacer size='m' />

                  <FieldArray
                    name='sections'
                    render={() => (
                      <React.Fragment>
                        {formik.values.sections &&
                          formik.values.sections.length > 0 &&
                          formik.values.sections.map((section: string, index: number) => (
                            <EuiFormRow key={`section${index}`} label={`Section ${index + 1}`} fullWidth isInvalid={getIn(formik.touched, `sections[${index}]`) && !!getIn(formik.errors, `sections[${index}]`)} error={getIn(formik.errors, `sections[${index}]`)}>
                              <EuiTextArea name={`sections[${index}]`} value={getIn(formik.values, `sections[${index}]`).replace('\\n', '\n')} onChange={e => formik.setFieldValue(`sections[${index}]`, e.target.value.replace('\n', '\\n'))} isInvalid={getIn(formik.touched, `sections[${index}]`) && !!getIn(formik.errors, `sections[${index}]`)} rows={2} fullWidth />
                            </EuiFormRow>
                          ))}
                      </React.Fragment>
                    )}
                  />
                </React.Fragment>
              )}
              {builder.tvCommercial && builder.tvCommercial.type === 'VoiceOver' && (
                <React.Fragment>
                  <EuiText size='s'>
                    <h4>Commercial Script</h4>
                  </EuiText>
                  <EuiText size='xs' color={'subdued'}>
                    <span>If you change the script below, We will re-record the voice over with your changes. Small changes are best.</span>
                  </EuiText>
                  <EuiSpacer size='m' />
                  <EuiFormRow label='Script' fullWidth isInvalid={formik.touched.script && !!formik.errors.name} error={formik.errors.script}>
                    <EuiTextArea name='script' value={formik.values.script ?? ''} onChange={formik.handleChange} fullWidth isInvalid={formik.touched.script && !!formik.errors.script} rows={10} />
                  </EuiFormRow>
                </React.Fragment>
              )}
            </EuiFlexItem>
            <EuiFlexItem grow={false} style={{ width: 640 }}>
              <EuiText size='s'>
                <h4>Preview Your TV Commercial</h4>
              </EuiText>
              <EuiSpacer size='m' />
              <ReactPlayer url={previewUrl} playing={playing} controls={controls} loop={loop} />
              {(builder.isLoadingPreviewUrl || isExportingVideo) && (
                <React.Fragment>
                  <EuiSpacer size='m' />
                  <EuiCallOut title='Please wait while your commercial is updated. Allow approx. 30 secs.' size='s' iconType='scale' color='primary' />
                </React.Fragment>
              )}
              <EuiSpacer size='m' />
              {builder.tvCommercial && builder.tvCommercial.type === 'Text' && (
                <EuiFlexGroup gutterSize='none' style={{ flexGrow: 0 }}>
                  <EuiFlexItem style={{ width: 200 }} grow={false}>
                    <EuiButton id='refresh' color='success' size='s' onClick={refreshVideoUrl} style={{ width: 200 }} fill disabled={isExportingVideo}>
                      Update With My Text
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />

          <EuiShowFor sizes={['xs', 's']}>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButton id='continue' fill type='submit' isLoading={formik.isSubmitting} onClick={formik.submitForm}>
                  Continue to Targeting
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton id='back' fill type='button' color='text' onClick={gotoSuggestions}>
                  Back
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiShowFor>

          <EuiHideFor sizes={['xs', 's']}>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButton id='back' fill type='button' color='text' onClick={gotoSuggestions} iconType='arrowLeft' iconSide='left'>
                  Back
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton id='continue' fill type='submit' isLoading={formik.isSubmitting} iconType='arrowRight' iconSide='right' onClick={formik.submitForm}>
                  Continue to Targeting
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiHideFor>
        </React.Fragment>
      </FormikProvider>
    </React.Fragment>
  )
}

export default CreateStep
