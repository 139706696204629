import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiCard, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiSpacer, EuiText } from '@elastic/eui'

import config from 'app/config'
import { RootState } from 'app/rootReducer'
import { fetchCampaigns } from 'features/campaigns/campaigns/campaignsSlice'
import history from 'services/HistoryService'

import { BuilderStyle, setBuilderStyle } from './builderBillboardRetailSlice'

const StartStep: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)

  useEffect(() => {
    if (currentAccount) {
      dispatch(fetchCampaigns(currentAccount.id))
    }
  }, [dispatch, currentAccount])

  const gotoSuggest = () => {
    dispatch(setBuilderStyle(BuilderStyle.Suggested))
    history.push('/build/billboards/public/suggest')
  }

  const gotoUpload = () => {
    dispatch(setBuilderStyle(BuilderStyle.Upload))
    history.push('/build/billboards/public/upload')
  }

  const gotoQuestionnaire = () => {
    dispatch(setBuilderStyle(BuilderStyle.Custom))
    history.push('/build/billboards/public/questionnaire')
  }

  const gotoStart = () => {
    history.push('/build')
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>Start</title>
      </Helmet>
      <EuiFlexGroup>
        <EuiFlexItem grow={true} />
        {config.features.billboardSuggestionsEnabled && (
          <EuiFlexItem grow={false} style={{ width: 280 }}>
            <EuiCard id='preMadeBillboardTile' icon={<EuiIcon size='xxl' type='grid' color='success' />} title='Pre-Made Billboards' description='Pre-made billboards for your business.' onClick={gotoSuggest} footer={<EuiText size='s'>Browse billboards and pick one you like. You can use it as-is or easily customize it for your business.</EuiText>} />
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false} style={{ width: 280 }}>
          <EuiCard id='uploadBillboardTile' icon={<EuiIcon size='xxl' type='play' color='success' />} title='Upload a Billboard' description='Upload an ad.' onClick={gotoUpload} footer={<EuiText size='s'>Do you have your own billboard ad? Upload it here.</EuiText>} />
        </EuiFlexItem>
        <EuiFlexItem grow={true} />
      </EuiFlexGroup>
      <EuiSpacer size='m' />
      <EuiFlexGroup>
        <EuiFlexItem grow={true} />
        <EuiFlexItem grow={false} style={{ width: 280 }}>
          <EuiCard id='customBillboardTile' icon={<EuiIcon size='xxl' type='pencil' color='success' />} title='Custom Billboards' description='Let us make you some billboards.' onClick={gotoQuestionnaire} footer={<EuiText size='s'>Answer a few questions and we'll make some custom billboards just for you.</EuiText>} />
        </EuiFlexItem>
        <EuiFlexItem grow={true} />
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiButton fill type='button' color='text' onClick={gotoStart} iconType='arrowLeft' iconSide='left'>
            Back
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default StartStep
