import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AuthResponse } from 'api/interfaces'
import { logout, setUnpaidSubscription, showAccountsFlyout, updateAccountsState, updateAppState } from 'app/appSlice'
import { fetchDictionary } from 'app/dictionarySlice'
import { RootState } from 'app/rootReducer'
import { VENDASTA_ACCOUNT_ID } from 'features/vendasta/login/useVendastaSSO'
import { useIsAgency } from 'whiteLabel/useIsAgency'

export const useUpdateStateAfterLogin = () => {
  const dispatch = useDispatch()
  const { isLoggedIn } = useSelector((state: RootState) => state.app)
  const [wasLoggedOut, setWasLoggedOut] = useState(false)
  const isAgency = useIsAgency()

  useEffect(() => {
    if (!isLoggedIn) {
      setWasLoggedOut(true)
    }
  }, [isLoggedIn])

  useEffect(() => {
    handleAfterLogin()
  }, [wasLoggedOut, isLoggedIn, isAgency])

  const handleAfterLogin = useCallback(() => {
    if (wasLoggedOut && isLoggedIn) {
      if (isAgency) {
        dispatch(showAccountsFlyout())
      }
      setWasLoggedOut(false)
    }
  }, [wasLoggedOut, isLoggedIn, isAgency])

  return (authResponse: AuthResponse, vendastaAccountId?: string) => {
    let account = authResponse.accounts[0]

    if (vendastaAccountId) {
      account = authResponse.accounts.find(a => a.strawberryFieldAccountId === localStorage.getItem(VENDASTA_ACCOUNT_ID)) ?? account
    }

    dispatch(logout())

    dispatch(
      updateAppState({
        isLoggedIn: true,
        accessToken: authResponse.accessToken,
        account: account,
        organization: authResponse.organization,
        user: {
          name: authResponse.name,
          email: authResponse.email,
          roles: authResponse.roles,
          picture: authResponse.picture,
          canAccessAllAccounts: authResponse.canAccessAllAccounts
        },
        isInSignupProcess: authResponse.isInSignupProcess,
        vendastaNavBarUrl: authResponse.strawberryFieldNavBarUrl,
        vendastaAccessToken: authResponse.strawberryFieldAccessToken
      })
    )
    dispatch(updateAccountsState(authResponse.accounts))
    dispatch(fetchDictionary())

    if (authResponse.organization.hasUnpaidSubscription) {
      dispatch(setUnpaidSubscription())
    }
  }
}
