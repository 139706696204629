import React, { FC } from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiLoadingChart, EuiProgress, EuiText } from '@elastic/eui'

import { CampaignInsightsConfiguration } from 'api/interfaces/CampaignInsightsConfiguration'
import { timeToBehaviorChartLoading, timeToBehaviorChartText } from 'components/insights/InsightsConstants'

export interface InsightsTimeToBehaviorModificationChartProps {
  isLoading: boolean
  config?: CampaignInsightsConfiguration
  timeToBehaviorCurrentMonth?: number
}

const InsightsTimeToBehaviorModificationChart: FC<InsightsTimeToBehaviorModificationChartProps> = ({ isLoading, config, timeToBehaviorCurrentMonth }) => {
  const chartLabelText = (text: string, milestone: number) => {
    if (milestone > (timeToBehaviorCurrentMonth ?? 0)) {
      return (
        <EuiText size='s' style={{ fontWeight: '525' }}>
          {text}
        </EuiText>
      )
    }

    return (
      <EuiText color={timeToBehaviorChartText} size='s' style={{ fontWeight: '525' }}>
        {text}
      </EuiText>
    )
  }

  if (isLoading || !config || typeof timeToBehaviorCurrentMonth === undefined) {
    return <EuiLoadingChart size='l' />
  }

  return (
    <EuiFlexGroup direction='column'>
      <EuiFlexItem>
        <EuiProgress color={timeToBehaviorChartLoading} value={timeToBehaviorCurrentMonth === 0 ? 0.1 : timeToBehaviorCurrentMonth} max={config.timeToBehaviorMilestoneBehaviorModified} size='m' />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup>
          <EuiFlexItem>{chartLabelText('Campaign Started', 0)}</EuiFlexItem>
          <EuiFlexItem>{chartLabelText('Audience noticing', config.timeToBehaviorMilestoneAudienceNoticing)}</EuiFlexItem>
          <EuiFlexItem>{chartLabelText('Improved brand recall', config.timeToBehaviorMilestoneImprovedBrandRecall)}</EuiFlexItem>
          <EuiFlexItem grow={false}>{chartLabelText('Behavior modified', config.timeToBehaviorMilestoneBehaviorModified)}</EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default InsightsTimeToBehaviorModificationChart
