import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiHeader, EuiHeaderSection, EuiImage, EuiLink, EuiPageBody, EuiPanel, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { useGetCampaignAdViewerQuery } from 'api/rtkQueryApi/platform/adViewerApi'
import { RootState } from 'app/rootReducer'
import { AcCarousel, KeyedAdView } from 'components/Collections/AcCarousel'

interface CampaignAdsViewerParams {
  campaignId: string
}
export const CampaignAdsViewer: React.FC = () => {
  let { campaignId } = useParams<CampaignAdsViewerParams>()
  const getCampaignAdsQuery = useGetCampaignAdViewerQuery({ campaignId: campaignId })
  const [adPreviews, setAdPreviews] = React.useState<KeyedAdView[]>([])
  const [selectedAdKey, setSelectedAdKey] = React.useState<string>('0')
  const { screenSize } = useSelector((state: RootState) => state.events)

  useEffect(() => {
    if (getCampaignAdsQuery.data) {
      setAdPreviews(
        getCampaignAdsQuery.data.adViews.map((adView, index) => {
          return {
            key: `${index}`,
            adImageSrc: adView.adImageSrc,
            width: adView.width,
            height: adView.height
          }
        })
      )

      if (!document.getElementById('pxl-img') && getCampaignAdsQuery.data.pxlUid) {
        let pimg = document.createElement("img");
        pimg.width = 1;
        pimg.height = 1;
        pimg.id = 'pxl-img'
        pimg.src = `https://ib.adnxs.com/pixie?pi=${getCampaignAdsQuery.data.pxlUid}&e=PageView&script=0`;
        pimg.referrerPolicy = "strict-origin-when-cross-origin";
        document.body.appendChild(pimg);
      }
    }
  }, [getCampaignAdsQuery.data])

  return (
    <EuiFlexGroup direction={'column'} gutterSize={'none'} style={{ height: '100%' }} responsive={false}>
      <EuiFlexItem grow={false}>
        <EuiHeader position='fixed' className='euiHeader--platform' style={{ margin: 0, border: 0 }}>
          <EuiHeaderSection grow={false}>
            <EuiLink href={'https://adcritter.com/'}>
              <EuiImage src={'/adcritterHeader_Banner.svg'} alt={'header'} style={{ margin: 8, width: 180 }} />
            </EuiLink>
          </EuiHeaderSection>
        </EuiHeader>
      </EuiFlexItem>
      <EuiFlexItem>
        <div style={{ position: 'absolute', width: '100%', height: screenSize.height, left: 0, top: 0 }} aria-hidden='true'>
          <img src={'/business_planning_hands.jpeg'} alt='Business Planning Hands' style={{ objectFit: 'cover', height: '100vh', width: '100%', verticalAlign: 'middle', display: 'block' }} />
        </div>
        <div style={{ position: 'absolute', width: '100%', height: screenSize.height, left: 0, top: 0, backgroundColor: '#00000088' }} aria-hidden='true' />
        <EuiPageBody style={{ paddingTop: 48, paddingBottom: 48 }}>
          <EuiFlexGroup gutterSize={'none'} responsive={false}>
            <EuiFlexItem>
              <EuiPanel style={{ width: screenSize.sm ? '80%' : screenSize.md ? '70%' : '60%', backgroundColor: '#f3e0cce0', overflow: 'auto', marginLeft: 'auto', marginRight: 'auto', maxHeight: screenSize.sm ? screenSize.height * 0.9 : screenSize.height * 0.8, zIndex: 100 }}>
                <EuiFlexGroup justifyContent={'center'}>
                  <EuiFlexItem grow={false}>
                    <EuiTitle size={'l'}>
                      <h2 style={{ textAlign: 'center' }}>Additional Ad Preview</h2>
                    </EuiTitle>
                    <EuiSpacer size={'s'} />
                    <EuiText style={{ textAlign: 'center' }} size={'s'}>
                      The expanded portfolio of your original ad is below. You can customize which appear in your campaign in the{' '}
                      <EuiLink href={`/campaigns/edit/${campaignId}/details`} color={'success'}>
                        AdCritter Platform
                      </EuiLink>{' '}
                      if you wish.
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer size={'xl'} />
                <AcCarousel adViews={adPreviews} onAdViewChange={key => setSelectedAdKey(key)} currentKey={selectedAdKey} />
                <EuiSpacer />
                <EuiFlexGroup justifyContent={'center'} direction={'column'}>
                  <EuiFlexItem grow={false}>
                    <EuiText style={{ textAlign: 'center' }} size={'s'}>
                      <b>Important:</b> If you would like fully custom ads created for your campaign,{' '}
                      <EuiLink href={`https://calendly.com/adcritter-exec/ads`} color={'success'}>
                        schedule a call
                      </EuiLink>{' '}
                      with your Account Manager to discuss low-cost options.
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiLink href={`/campaigns/edit/${campaignId}/details`} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                      <EuiButton onClick={() => {}} color={'primary'} fill>
                        View in Platform
                      </EuiButton>
                    </EuiLink>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPageBody>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
