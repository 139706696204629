import { Moment } from 'moment'

import { CampaignType } from 'api/interfaces/Campaign'
import { Package } from 'api/interfaces/Package'
import { Plan } from 'api/interfaces/Plan'
import { VendastaPackage } from 'api/interfaces/VendastaPackage'

export type Bundle = Package | Plan | VendastaPackage

export interface BaseBundle {
  id: string
  name: string
  salesTitle: string
  salesDescription: string
  organizationId: string
  accountId: string
  bundleStatus: BundleStatus
  bundleActiveStatus: BundleActiveStatus
  bundleType: BundleType
  bundleCategory: BundleCategory
  targetingPriority: TargetingPriority
  price: number
  isRollover: boolean
  isIndefinite: boolean
  campaignSlots: CampaignSlot[]
  incentives: Incentive[]
  billingSchedule: ScheduleEvent
  isWatchListed: boolean
  created: Moment | null
  updated: Moment | null
  createdBy: string
  updatedBy: string
}

export interface Incentive {}

export interface CommissionDefinition {}

export interface ScheduleEvent {
  id: string
  isIndefinite: boolean
  intervalUnit: IntervalUnit
  intervalUnitFrequency: number
  totalIntervals: number
  intervalsCompleted: number
  thirdPartyReferenceId: string
  lastIntervalDate: Moment | null
  nextEventDate: Moment | null
  createdDate: Moment | null
  startDate: Moment | null
  updatedAt: Moment | null
  createdBy: string
  updatedBy: string
}

export interface CampaignSlot {
  id: string
  name?: string
  budget: number
  createdDate?: Moment
  campaignId?: string
  isBonus: boolean
  campaignType: CampaignType
  status?: CampaignSlotStatus
  flightSchedule?: ScheduleEvent
}

export interface BundleActivateRequest extends BundleSaveForLaterRequest {}

export interface BundleSaveRequest {
  accountId: string
  bundleId: string
  name: string
  assignCampaignSlots: CampaignSlotAssignRequest[]
}

export interface BundleSaveForLaterRequest {
  accountId?: string
  bundleId: string
  name: string
  price: number
  paymentMethodId: string
  campaignSlots: CampaignSlotCreateRequest[]
}

export interface BundleUpdateRequest {
  accountId: string
  bundleId: string
  name: string
}

export interface CampaignSlotCreateRequest {
  campaignType: CampaignType
  budget: number
  isBonus: boolean
  assignedCampaignId?: string
}

export interface CampaignSlotAssignRequest {
  campaignSlotId: string
  campaignId: string | undefined
}

export enum IntervalUnit {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month'
}

export enum BundleCategory {
  Regular = 'Regular',
  LeadGeneration = 'LeadGeneration',
  Event = 'Event',
  ShortTerm = 'ShortTerm',
  Political = 'Political'
}

export enum TargetingPriority {
  Location = 'Location',
  AudienceSegment = 'AudienceSegment'
}

export enum BundleStatus {
  New = 'New',
  RollingOver = 'RollingOver',
  Onboarding = 'Onboarding',
  Onboarded = 'Onboarded',
  EndingOn = 'EndingOn',
  Ended = 'Ended',
  PartiallyOnboarded = 'PartiallyOnboarded',
  Live = 'Live',
  PaymentRetrying = 'PaymentRetrying',
  PaymentFailed = 'PaymentFailed',
  Completed = 'Completed',
  OnHoldUntil = 'OnHoldUntil',
  Deleted = 'Deleted'
}

export enum BundleActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum CampaignSlotStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum BundleType {
  Package = 'Package',
  Plan = 'Plan',
  VendastaPackage = 'VendastaPackage',
  SelfServicePackage = 'SelfServicePackage'
}

export interface InsightsConfiguration {
  repetitionTarget: number
  saturationTargetPercent: number
  timeToBehaviorMilestoneAudienceNoticing: number
  timeToBehaviorMilestoneImprovedBrandRecall: number
  timeToBehaviorMilestoneBehaviorModified: number
}
