import React from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText, EuiTextAlign } from '@elastic/eui'

import { PreviewProps } from './index'

export const DesktopSearch = ({ ad }: PreviewProps) => {
  return (
    <EuiFlexGroup responsive={false} style={{ marginTop: 20 }}>
      <EuiFlexItem grow={true}></EuiFlexItem>
      <EuiFlexItem grow={false} style={{ width: 582 }}>
        <EuiPanel paddingSize='none'>
          <EuiFlexGroup responsive={false} style={{ padding: 10 }} gutterSize='none'>
            <EuiFlexItem grow={false} style={{ marginLeft: 10 }}>
              <EuiFlexGroup responsive={false} gutterSize='none'>
                <EuiFlexItem grow={false}>
                  <EuiText size='s'>
                    <EuiTextAlign textAlign='right'>
                      <strong>Ad</strong> <span style={{ padding: '0 5px', fontWeight: 'bolder' }}>·</span>
                    </EuiTextAlign>
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem className='eui-textTruncate'>
                  <EuiText size='s'>{ad?.destinationUrl || 'https://yourwebsite.com'}</EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiText size='m' className='eui-textTruncate' color='darkblue'>
                <strong>{ad?.headline || 'Attention Grabbing Headline.'}</strong>
              </EuiText>
              <EuiText size='s' className='eui-textTruncate' color='subdued'>
                {ad?.body || 'One or two sentences describing your product or service.'}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexItem>
      <EuiFlexItem grow={true}></EuiFlexItem>
    </EuiFlexGroup>
  )
}
