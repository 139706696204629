import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router'

import { EuiText, EuiSpacer, EuiLink, EuiPanel, EuiTitle } from '@elastic/eui'

import config from 'app/config'
import { getRememberedEmail } from 'services/LocalStoreManagerService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import LoginForm from './LoginForm'

const LoginPage: React.FC = () => {
  const rememberedEmail = getRememberedEmail()
  const whiteLabel = useWhiteLabel()
  let { search } = useLocation()
  const query = new URLSearchParams(search)
  const redirect = query.get('redirect')
  const email = query.get('email')

  return (
    <React.Fragment>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className='login'>
        <div style={{ height: 500, width: 350, gridRow: '2/3' }}>
          <div style={{ textAlign: 'center' }}>
            <img src={whiteLabel?.getSquareLogo()} alt={whiteLabel?.getName()} style={{ marginBottom: 25, width: 300 }} />
          </div>
          <EuiPanel hasShadow={true}>
            <EuiTitle>
              <h2>Log In</h2>
            </EuiTitle>
            <EuiSpacer />
            <LoginForm rememberedEmail={rememberedEmail || ''} redirect={redirect} emailInQuery={email} />
            <EuiSpacer />

            {!whiteLabel?.disableSignup() && (
              <EuiText size='xs'>
                If you don't have an account, please{' '}
                <EuiLink color='success' target='_blank' href={config.signupUrl}>
                  Sign Up Here
                </EuiLink>
              </EuiText>
            )}
          </EuiPanel>
        </div>
      </div>
    </React.Fragment>
  )
}

export default LoginPage
