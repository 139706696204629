import moment from 'moment'

import { CampaignStatus, CreativeStatus } from 'api/interfaces'

export const InsertSpaces = (value: string) => {
  value = value.replace(/([a-z])([A-Z])/g, '$1 $2')
  value = value.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
  return value
}

export const CampaignStatusToString = (campaign: { status: CampaignStatus; endingOnDate: moment.Moment | null }) => {
  if (campaign.status !== CampaignStatus.EndingOn || !campaign.endingOnDate) {
    return InsertSpaces(campaign.status)
  }

  return `Ending On ${formatEndingOnDate(moment(campaign.endingOnDate))}`
}

export const CampaignStatusToElasticColor = (status: CampaignStatus) => {
  switch (status) {
    case CampaignStatus.NoApprovedAds:
    case CampaignStatus.PaymentFailed:
      return 'danger'
    case CampaignStatus.EndingOn:
    case CampaignStatus.CampaignEnded:
    case CampaignStatus.SavedForLater:
    case CampaignStatus.PendingAdApproval:
    case CampaignStatus.Archived:
    case CampaignStatus.Deleted:
    case CampaignStatus.WaitingForCustomAds:
    case CampaignStatus.WaitingForTVOutro:
      return 'default'
    case CampaignStatus.Live:
      return 'success'
    case CampaignStatus.Scheduled:
    case CampaignStatus.PendingNetworkApproval:
    default:
      return 'warning'
  }
}

export const CreativeStatusToString = (status: CreativeStatus) => {
  return InsertSpaces(status)
}

export const CreativeStatusToElasticColor = (status: CreativeStatus) => {
  switch (status) {
    case CreativeStatus.PendingApproval:
    case CreativeStatus.WaitingForTVOutro:
      return 'default'
    case CreativeStatus.Live:
      return 'success'
    case CreativeStatus.Disapproved:
      return 'danger'
    case CreativeStatus.PendingNetworkApproval:
    case CreativeStatus.Inactive:
      return 'warning'
  }
}

export const formatEndingOnDate = (date: moment.Moment | null) => {
  if (!date) {
    return ''
  }

  return moment(date).format('MMMM Do YYYY')
}
