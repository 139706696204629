import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import config from 'app/config'
import { RootState } from 'app/rootReducer'

export const useFirePixelsOnLogin = () => {
  const { isLoggedIn, currentAccount, currentUser } = useSelector((state: RootState) => state.app)

  useEffect(() => {
    if (isLoggedIn && !!currentAccount?.id && !!currentUser && !currentUser.roles.includes('staff') && !document.getElementById('mpio-pixel') && config.modernIoPostcard) {
      let script = document.createElement('script')
      script.id = 'mpio-pixel'
      script.src = `https://cdata.mpio.io/js/${config.modernIoPostcard}.js`
      script.async = true
      script.defer = true
      document.body.appendChild(script)
    }
  }, [isLoggedIn, currentAccount?.id, currentUser])
}
