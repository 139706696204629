import { BusinessType, SuggestedAudiencesResponse } from 'api/interfaces'
import { defaultTransformFn, platformApi } from 'api/rtkQueryApi/platform/platformApi'

const businessTypesTags = platformApi.enhanceEndpoints({ addTagTypes: ['BusinessTypes', 'BusinessType', 'ShopifyPremierBusinessTypes', 'SuggestedAudienceIds'] })

export const businessTypesApi = businessTypesTags.injectEndpoints({
  endpoints: build => {
    return {
      getBusinessTypes: build.query<BusinessType[], { query?: string }>({
        query: ({ query }) => ({
          url: `businessTypes/search?query=${query}`,
          method: 'GET',
          isContext: true,
          isAgency: false
        }),
        transformResponse: defaultTransformFn,
        providesTags: (result, error, { query }) => [
          {
            type: 'BusinessTypes',
            id: query
          }
        ]
      }),
      getBusinessType: build.query<BusinessType, { id: string }>({
        query: ({ id }) => ({
          url: `businessTypes/${id}`,
          method: 'GET',
          isContext: true,
          isAgency: false
        }),
        transformResponse: defaultTransformFn,
        providesTags: (result, error, { id }) => [
          {
            type: 'BusinessType',
            id: id
          }
        ]
      }),
      getShopifyPremierBusinessTypes: build.query<BusinessType[], void>({
        query: () => ({
          url: `businessTypes/shopify`,
          method: 'GET',
          isContext: true,
          isAgency: false
        }),
        transformResponse: defaultTransformFn,
        providesTags: () => [{ type: 'ShopifyPremierBusinessTypes' }]
      }),
      getBusinessTypeSuggestedAudiences: build.query<SuggestedAudiencesResponse, { businessTypeId: string }>({
        query: ({ businessTypeId }) => ({
          url: `businessTypes/${businessTypeId}/suggestedAudiences`,
          method: 'GET',
          isContext: true,
          isAgency: false
        }),
        transformResponse: defaultTransformFn,
        providesTags: (result, error, { businessTypeId }) => [
          {
            type: 'SuggestedAudienceIds',
            id: businessTypeId
          }
        ]
      })
    }
  },
  overrideExisting: false
})

export const { useGetBusinessTypesQuery, useGetShopifyPremierBusinessTypesQuery, useGetBusinessTypeSuggestedAudiencesQuery, useGetBusinessTypeQuery } = businessTypesApi
