export interface IAdvertisingPackage {
  categoryName: string
  name: string
  description: string
  descriptionSubtext: string
  priceDescription: string
  price: number
}

export const VideoPackages: IAdvertisingPackage[] = [
  {
    categoryName: 'Targeted TV',
    name: 'Basic',
    description: '1,000 Households',
    descriptionSubtext: 'will see your ad 10 times/mo.*',
    priceDescription: '$500',
    price: 500
  },
  {
    categoryName: 'Targeted TV',
    name: 'Standard',
    description: '2,500 Households',
    descriptionSubtext: 'will see your ad 10 times/mo.*',
    priceDescription: '$1000',
    price: 1000
  },
  {
    categoryName: 'Targeted TV',
    name: 'Premium',
    description: '7,000 Households',
    descriptionSubtext: 'will see your ad 10 times/mo.*',
    priceDescription: '$2500',
    price: 2500
  }
]

export const InternetPackages: IAdvertisingPackage[] = [
  {
    categoryName: 'Internet',
    name: 'Basic',
    description: '65K - 150K',
    descriptionSubtext: 'Monthly Impressions*',
    priceDescription: '$300',
    price: 300
  },
  {
    categoryName: 'Internet',
    name: 'Standard',
    description: '150K - 325K',
    descriptionSubtext: 'Monthly Impressions*',
    priceDescription: '$650',
    price: 650
  },
  {
    categoryName: 'Internet',
    name: 'Premium',
    description: '325K - 750K',
    descriptionSubtext: 'Monthly Impressions*',
    priceDescription: '$1500',
    price: 1500
  }
]

export const BillboardPackages: IAdvertisingPackage[] = [
  {
    categoryName: 'Digital Billboards',
    name: 'Basic',
    description: '35K - 45K',
    descriptionSubtext: 'Monthly Views',
    priceDescription: '$750',
    price: 750
  },
  {
    categoryName: 'Digital Billboards',
    name: 'Standard',
    description: '75K - 100K',
    descriptionSubtext: 'Monthly Views',
    priceDescription: '$1500',
    price: 1500
  },
  {
    categoryName: 'Digital Billboards',
    name: 'Premium',
    description: '130K - 180K',
    descriptionSubtext: 'Monthly Views',
    priceDescription: '$3000',
    price: 3000
  }
]
