import React, { useEffect, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiForm, EuiSpacer } from '@elastic/eui'

import { apiPostCampaignActivate, apiPrepareNewPaymentProviderPaymentMethod } from 'api/adcritterApi'
import { useGetOrganizationBundlesQuery } from 'api/rtkQueryApi/platform/bundlesApi'
import { campaignsApi } from 'api/rtkQueryApi/platform/campaignsApi'
import { useUpdateUsageCapMutation } from 'api/rtkQueryApi/platform/shopifyApi'
import { setReturnUrlState, showErrorToast, showSuccessToast } from 'app/appSlice'
import config from 'app/config'
import { RootState } from 'app/rootReducer'
import { PaymentMethodPicker, usePaymentMethodPickerState } from 'components/payments/PaymentMethodPicker'
import { AdPaymentType } from 'features/builders/internet/retail/builderInternetRetailSlice'

import { fetchCampaign } from './campaignSlice'

interface ActivatePageProps {
  campaignId: string
  amount: number
  campaignType: string
  budgetFrequency: string
}

const ActivateCallOut = ({ campaignId, amount, campaignType }: ActivatePageProps) => {
  const dispatch = useDispatch()
  const { campaign } = useSelector((state: RootState) => state.campaign)
  const { currentAccount, currentOrganization, isVendastaUser, isShopifyUser, isStaffUser } = useSelector((state: RootState) => state.app)
  const organizationBundles = useGetOrganizationBundlesQuery(undefined, { skip: !currentOrganization })
  const [updateUsageCap, updateUsageCapRequest] = useUpdateUsageCapMutation()
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [errors, setErrors] = useState<string | undefined>(undefined)
  const [{ selectedPaymentMethodId, selectedCampaignSlotId }, setPaymentMethodPickerState] = usePaymentMethodPickerState()
  const [paymentType, setPaymentType] = useState<AdPaymentType>(AdPaymentType.Standard)
  const [redirectUrl, setRedirectUrl] = useState<string>()
  const [showIncreaseCap, setShowIncreaseCap] = useState(false)
  const [updateAppSpendCap, setUpdateAppSpendCap] = useState<number>()
  const [showCreateRecurringCharge, setShowCreateRecurringCharge] = useState(false)

  useEffect(() => {
    const canUseBundles = isVendastaUser || isStaffUser

    if (organizationBundles.data && !organizationBundles.isLoading && !organizationBundles.isFetching && canUseBundles) {
      const availableCampaignSlotOptions = []
      for (let bundle of organizationBundles.data) {
        for (let slot of bundle.campaignSlots) {
          if (slot.campaignId == campaignId) {
            setPaymentMethodPickerState({
              selectedPaymentMethodId: '',
              selectedCampaignSlotId: slot.id
            })
            availableCampaignSlotOptions.splice(0, availableCampaignSlotOptions.length)
            availableCampaignSlotOptions.push({ id: slot.id, label: `${bundle.name ? bundle.name + ': ' : ''}$${slot.budget} ${slot.campaignType}` })
            break
          } else if (!slot.campaignId && slot.campaignType === campaignType) {
            availableCampaignSlotOptions.push({ id: slot.id, label: `${bundle.name ? bundle.name + ': ' : ''}$${slot.budget} ${slot.campaignType}` })
          }
        }
      }
    }
  }, [organizationBundles])

  const activateCampaignWithStripe = () => {
    if (paymentType === AdPaymentType.Bundle) {
      activateCampaign()
    } else {
      setIsProcessing(true)

      if (config.gtag.ANALYTICSID) {
        ReactGA.event('add_payment_info', {
          value: amount,
          currency: 'USD',
          items: [{ item_name: 'TV Campaign' }],
          send_to: config.gtag.ANALYTICSID
        })
      }
      ReactPixel.track('AddPaymentInfo', { value: amount, campaign_type: 'TV Campaign' })
      activateCampaign()
    }
  }

  useEffect(() => {
    if (selectedCampaignSlotId.length > 0 && isStaffUser) {
      setPaymentType(AdPaymentType.Bundle)
    } else {
      setPaymentType(AdPaymentType.Standard)
    }
  }, [selectedPaymentMethodId, selectedCampaignSlotId])

  useEffect(() => {
    if (isShopifyUser && currentAccount?.id) {
      apiPrepareNewPaymentProviderPaymentMethod(currentAccount.id).then(result => {
        setErrors(result.data.error)
        if (result.data.needsConfirmation && result.data.redirectUrl.length > 0) {
          setRedirectUrl(result.data.redirectUrl)
          setShowCreateRecurringCharge(true)
        } else if (result.data.remainingBalance !== null && result.data.remainingBalance !== undefined && result.data.remainingBalance < amount) {
          setShowIncreaseCap(true)
          setUpdateAppSpendCap((result.data.cappedAmount ?? 0) + (amount - result.data.remainingBalance))
        } else {
          setPaymentMethodPickerState({
            selectedPaymentMethodId: result.data.paymentMethodId,
            selectedCampaignSlotId: ''
          })
        }
      })
    }
  }, [currentAccount, isShopifyUser])

  const activateCampaign = () => {
    setIsProcessing(true)
    if (!isProcessing) {
      let selectedSlot = paymentType === AdPaymentType.Bundle ? selectedCampaignSlotId : null
      apiPostCampaignActivate(currentAccount!.id, campaignId, selectedPaymentMethodId, selectedSlot)
        .then(data => {
          if (data.data.success) {
            if (paymentType !== AdPaymentType.Bundle) {
              if (config.gtag.ANALYTICSID) {
                ReactGA.event('purchase', { value: amount, currency: 'USD', transaction_id: campaignId, items: [{ item_name: `${campaignType} Campaign` }], send_to: config.gtag.ANALYTICSID })
              }
              if (config.gtag.ADWORDSID) {
                ReactGA.event('conversion', { transaction_id: campaignId, send_to: 'AW-732436947/mEO9CMKO6NUBENOzoN0C' })
              }
              if (config.facebook.PIXEL_ID) {
                ReactPixel.track('Purchase', { value: amount, currency: 'USD', content_type: `${campaignType} Campaign` })
              }
              if (config.linkedin.PARTNER_ID && config.linkedin.PURCHASE_CONVERSION_ID) {
                window.lintrk('track', { conversion_id: config.linkedin.PURCHASE_CONVERSION_ID })
              }
            }

            dispatch(showSuccessToast('Successfully activated your campaign'))
            dispatch(fetchCampaign(currentAccount!.id, campaignId))
            dispatch(campaignsApi.util.invalidateTags([{ type: 'Campaign', id: campaignId }, { type: 'Campaigns' }]))
          } else {
            if (!isVendastaUser && !isShopifyUser) {
              dispatch(showErrorToast('Payment Failed'))
            }
          }
        })
        .finally(() => {
          setIsProcessing(false)
        })
    }
  }

  const paymentRedirect = () => {
    if (!!redirectUrl) {
      dispatch(setReturnUrlState(`/campaigns/edit/` + campaignId))
      window.location.assign(redirectUrl)
    }
  }

  const spendCapRedirect = () => {
    if (!!updateAppSpendCap) {
      updateUsageCap({ newCapAmount: updateAppSpendCap }).then(result => {
        if (!('error' in result)) {
          dispatch(setReturnUrlState(`/campaigns/edit/` + campaignId))
          window.location.assign(result.data.redirectUrl)
        }
      })
    }
  }

  if (isVendastaUser) {
    return (
      <React.Fragment>
        <EuiCallOut title='This campaign still needs to be activated before it will run.' iconType='play' color='success'>
          <EuiSpacer />

          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton id='activate' fill onClick={activateCampaign} isLoading={isProcessing} isDisabled={!selectedPaymentMethodId && !selectedCampaignSlotId} color='success'>
                Activate
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiCallOut>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <EuiCallOut title='This campaign still needs to be activated before it will run.' iconType='play' color='success'>
        <React.Fragment>
          <p>
            When you activate this campaign, you will be charged{' '}
            {amount.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD'
            })}{' '}
            and your ads will be submitted to the ad exchanges for approval. As soon as your ads are approved they will start running.
          </p>
          <EuiForm component='form' isInvalid={!!errors}>
            {currentAccount?.id && !isShopifyUser && <PaymentMethodPicker campaignType={campaign?.type} paymentMethodPickerState={{ selectedPaymentMethodId, selectedCampaignSlotId }} updatePaymentMethodPickerState={setPaymentMethodPickerState} includedCampaignId={campaign?.id} />}
            <EuiSpacer />
            <EuiFlexGroup>
              {!showCreateRecurringCharge && !showIncreaseCap && (
                <EuiFlexItem grow={false}>
                  <EuiButton id='activate' fill onClick={activateCampaignWithStripe} isLoading={isProcessing} isDisabled={!selectedPaymentMethodId && !selectedCampaignSlotId} color='success'>
                    Activate
                  </EuiButton>
                </EuiFlexItem>
              )}
              {showCreateRecurringCharge && (
                <EuiFlexItem grow={false}>
                  <EuiButton id='activate' fill onClick={paymentRedirect} color='success'>
                    Setup Payment
                  </EuiButton>
                </EuiFlexItem>
              )}
              {showIncreaseCap && (
                <EuiFlexItem grow={false}>
                  <EuiButton id='activate' fill onClick={spendCapRedirect} isLoading={updateUsageCapRequest.isLoading} color='success'>
                    Increase App Spending Limit
                  </EuiButton>
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiForm>
        </React.Fragment>
      </EuiCallOut>
    </React.Fragment>
  )
}

export default ActivateCallOut
