import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiFieldSearch, EuiInputPopover, EuiSelectable, EuiText, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { filterStates, clearStates } from 'app/dictionarySlice'
import { RootState } from 'app/rootReducer'

import useDebounce from '../../utils/useDebounce'

interface StateTargeterProps {
  isInvalid: boolean

  addState(state: string): void
}

export const StateFinder = ({ addState, isInvalid }: StateTargeterProps) => {
  const dispatch = useDispatch()
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const [stateSearchTerm, setStateSearchTerm] = useState<string>('')
  const debouncedStateSearchTerm = useDebounce(stateSearchTerm, 100)
  const [searchStatePopoverOpen, setSearchStatePopoverOpen] = useState<boolean>(false)
  const [states, setStates] = useState<Array<EuiSelectableOption>>([])

  const onSearchState = () => {
    setSearchStatePopoverOpen(false)
  }

  const onSearchStateChange = (hint: string) => {
    setStateSearchTerm(hint)
  }

  useEffect(() => {
    if (debouncedStateSearchTerm) {
      dispatch(filterStates(debouncedStateSearchTerm))
    } else {
      dispatch(clearStates())
    }
  }, [debouncedStateSearchTerm, dispatch])

  useEffect(() => {
    if (dictionary.statesFiltered) {
      setStates(
        dictionary.statesFiltered.map(
          s =>
            ({
              label: s.name,
              key: s.code,
              checked: undefined,
              onClick: () => {
                addState(s.code)
                setSearchStatePopoverOpen(false)
                setStateSearchTerm('')
              }
            } as EuiSelectableOption)
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dictionary.statesFiltered])

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          value={stateSearchTerm}
          isLoading={dictionary.isLoadingStates}
          onChange={v => onSearchStateChange(v.target.value)}
          onFocus={() => setSearchStatePopoverOpen(true)}
          incremental={false}
          onSearch={() => onSearchState()}
          placeholder='Type to begin search...'
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
          isInvalid={isInvalid}
        />
      }
      isOpen={searchStatePopoverOpen}
      closePopover={() => setSearchStatePopoverOpen(false)}>
      {dictionary.statesFiltered && dictionary.statesFiltered.length ? (
        <EuiSelectable singleSelection options={states}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search... results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
