import { useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiForm, EuiSpacer } from '@elastic/eui'

import { apiGetCampaignPrepareUnpaid, apiPostPayUnpaidCampaign, apiPostPayUnpaidBundle } from 'api/adcritterApi'
import { showErrorToast, showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { PaymentMethodPicker, usePaymentMethodPickerState } from 'components/payments/PaymentMethodPicker'

import { fetchCampaign } from './campaignSlice'

interface UnpaidCallOutProps {
  campaignId: string
  hasBundle: boolean
}

const UnpaidCallOut = ({ campaignId, hasBundle }: UnpaidCallOutProps) => {
  const dispatch = useDispatch()
  const { currentAccount, currentOrganization } = useSelector((state: RootState) => state.app)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [amount, setAmount] = useState<number>(0)
  const [{ selectedPaymentMethodId }, setPaymentMethodPickerState] = usePaymentMethodPickerState()
  const [callPrepare, setCallPrepare] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    if (currentAccount) {
      apiGetCampaignPrepareUnpaid(currentAccount.id, campaignId).then(result => {
        if (result.data.isStillUnpaid) {
          setAmount(result.data.amount)
        } else {
          dispatch(fetchCampaign(currentAccount.id, campaignId))
        }
      })
    }
  }, [dispatch, currentAccount, campaignId, callPrepare])

  const restartCampaign = () => {
    setIsProcessing(true)
    if (!stripe || !elements) {
      return
    }

    if (hasBundle) {
      apiPostPayUnpaidBundle(currentAccount!.id, campaignId, selectedPaymentMethodId)
        .then(res => {
          if (res.errors?.length > 0) {
            dispatch(showErrorToast('Payment Failed'))
            setCallPrepare(!callPrepare)
          } else {
            dispatch(showSuccessToast('Successfully restarted your plan'))
            dispatch(fetchCampaign(currentAccount!.id, campaignId))
          }
        })
        .finally(() => {
          setIsProcessing(false)
        })
    } else {
      apiPostPayUnpaidCampaign(currentAccount!.id, campaignId, selectedPaymentMethodId)
        .then(res => {
          if (res.errors?.length > 0) {
            dispatch(showErrorToast('Payment Failed'))
            setCallPrepare(!callPrepare)
          } else {
            dispatch(showSuccessToast('Successfully restarted your campaign'))
            dispatch(fetchCampaign(currentAccount!.id, campaignId))
          }
        })
        .finally(() => {
          setIsProcessing(false)
        })
    }
  }

  return (
    <React.Fragment>
      <EuiCallOut title='Please update your payment method to keep this campaign running.' iconType='stop' color='warning'>
        <p>AdCritter has been trying to settle charges related to your ad spend, but unfortunately your payment method on file was declined.</p>
        {!hasBundle ? <p>Please select a valid payment method below and click the pay button to resume this campaign.</p> : <p>Please select a valid payment method below and click the pay button to resume this plan.</p>}
        <EuiForm component='form'>
          <PaymentMethodPicker paymentMethodPickerState={{ selectedPaymentMethodId, selectedCampaignSlotId: '' }} updatePaymentMethodPickerState={setPaymentMethodPickerState} />
          <EuiSpacer />
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton id='pay' type='submit' onClick={() => restartCampaign()} isLoading={isProcessing} isDisabled={!selectedPaymentMethodId} color='warning'>
                Pay {amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} Now
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>
      </EuiCallOut>
    </React.Fragment>
  )
}

export default UnpaidCallOut
