import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCheckableCard, EuiCheckbox, EuiFieldText, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHideFor, EuiIcon, EuiListGroup, EuiListGroupItem, EuiShowFor, EuiSpacer, EuiText, EuiTitle, EuiToolTip, htmlIdGenerator } from '@elastic/eui'

import { RootState } from 'app/rootReducer'
import { TargetingType } from 'definitions/BuilderDefinitions'
import history from 'services/HistoryService'

import { AdPlacementType, AdvancedTargetingType, setDeliver } from './builderInternetRetailSlice'

const DeliverStep: React.FC = () => {
  const dispatch = useDispatch()
  const builder = useSelector((state: RootState) => state.builderInternetRetail)
  const [newSite, setNewSite] = useState<string>('')
  const [invalidSite, setInvalidSite] = useState<boolean>(false)
  const [placement, setPlacement] = useState(AdPlacementType.Everywhere)
  const [categories, setCategories] = useState<string[]>([])
  const [specific, setSpecific] = useState<string[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (builder.deliver && !isSubmitting) {
      setPlacement(builder.deliver.placement ?? AdPlacementType.Everywhere)
      setCategories(builder.deliver.categories ?? [])
      setSpecific(builder.deliver.specific ?? [])
    }
  }, [builder.deliver, isSubmitting])

  const siteSchema = Yup.object().shape({
    site: Yup.string()
      .required()
      .matches(/^(?!-)(?:(?:[a-zA-Z\d][a-zA-Z\d-]{0,61})?[a-zA-Z\d]\.){1,126}(?!\d+)[a-zA-Z\d]{1,63}$/, 'Please Enter a Valid Site')
  })

  const gotoActivateStep = () => {
    setIsSubmitting(true)
    dispatch(setDeliver({ placement, categories, specific }))
    history.push('/build/internet/public/activate')
    setIsSubmitting(false)
  }

  const returnPreviousStep = () => {
    switch (builder.targetingType) {
      case TargetingType.Standard:
        history.push('/build/internet/public/target-smart')
        break
      case TargetingType.Advanced:
        switch (builder.advancedTargeting!.advancedTargetingType) {
          case AdvancedTargetingType.Location:
            history.push('/build/internet/public/target-location')
            break
          case AdvancedTargetingType.Audience:
            history.push('/build/internet/public/target-audience')
            break
          case AdvancedTargetingType.Search:
            history.push('/build/internet/public/target-search')
            break
          case AdvancedTargetingType.Retarget:
            history.push('/build/internet/public/target-retarget')
            break
        }
        break
    }
  }

  const updateCategories = (isChecked: boolean, category: string) => {
    if (isChecked && categories.filter(c => c === category).length === 0) {
      const newCategories = [...categories]
      newCategories.push(category)
      setCategories(newCategories)
      if (placement !== 'Categories') {
        setPlacement(AdPlacementType.Categories)
      }
    } else if (!isChecked && categories.filter(c => c === category).length > 0) {
      const index = categories.indexOf(category)
      const newCategories = [...categories]
      newCategories.splice(index, 1)
      setCategories(newCategories)
      if (newCategories.length === 0) {
        setPlacement(AdPlacementType.Everywhere)
      }
    }
  }

  const addNewSite = () => {
    siteSchema.isValid({ site: newSite }).then(function (valid) {
      if (valid) {
        setInvalidSite(false)
        const sites = [...specific]
        sites.push(newSite)
        setSpecific(sites)
        setNewSite('')
      } else {
        setInvalidSite(true)
      }
    })
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Deliver</title>
      </Helmet>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size='s'>
            <h3>Ad Placement</h3>
          </EuiTitle>
          <EuiText size='s'>
            <div>Prioritize where your ads appear on the Internet.</div>
          </EuiText>
          <EuiSpacer />
          <EuiCheckableCard
            id={htmlIdGenerator()()}
            label={<strong>Prioritize highest performing sites. (Recommended)</strong>}
            name={'placement'}
            checked={placement === AdPlacementType.Everywhere}
            onChange={() => {
              setPlacement(AdPlacementType.Everywhere)
              if (categories.length > 0) {
                setCategories([])
              }
            }}>
            <EuiText size='s' color={placement !== AdPlacementType.Everywhere ? 'subdued' : 'primary'}>
              Prioritizes all top brand-safe sites and categories to deliver top results.
            </EuiText>
          </EuiCheckableCard>
          <EuiSpacer size='m' />
          <EuiCheckableCard
            className='categoriesCheckBox'
             disabled={true}
            id={htmlIdGenerator()()}
            label={<strong>Prioritize specific categories of sites</strong>}
            name={'placement'}
            checked={placement === AdPlacementType.Categories}
            onChange={() => {
              setPlacement(AdPlacementType.Categories)
              if (categories.length === 0) {
                setCategories(['Business', 'Social', 'Portals', 'Lifestyle', 'Sports', 'Celebrity', 'News', 'Finance', 'Weather', 'Community', 'Entertainment'])
              }
            }}>
            <EuiFlexGrid columns={3} gutterSize='s'>
              <EuiFlexItem>
                <EuiCheckbox
                  className=''
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Business')
                  }}
                  disabled={placement !== 'Categories'}
                  checked={categories.filter(c => c === 'Business').length > 0}
                  label={
                    <React.Fragment>
                      Business{' '}
                      <EuiToolTip position='bottom' content='Prioritizes top business sites such as Wall Street Journal, CNN, Fox Business, MSN, Business Insider, Forbes and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Social')
                  }}
                  disabled={placement !== 'Categories'}
                  checked={categories.filter(c => c === 'Social').length > 0}
                  label={
                    <React.Fragment>
                      Social{' '}
                      <EuiToolTip position='bottom' content='Prioritizes social sites and user generated content platforms such as Reddit, Tumblr, specialty blogs and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Portals')
                  }}
                  disabled={placement !== 'Categories'}
                  checked={categories.filter(c => c === 'Portals').length > 0}
                  label={
                    <React.Fragment>
                      Portals{' '}
                      <EuiToolTip position='bottom' content='Prioritizes web portal sites and top homepages such as MSN, Yahoo, AOL, Daily Mail, Bing and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Lifestyle')
                  }}
                  disabled={placement !== 'Categories'}
                  checked={categories.filter(c => c === 'Lifestyle').length > 0}
                  label={
                    <React.Fragment>
                      Lifestyle{' '}
                      <EuiToolTip position='bottom' content='Prioritizes lifestyle sites such as inStyle, Travel &amp; Leisure, People, Mashable, POPSUGAR, USA Today and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Sports')
                  }}
                  disabled={placement !== 'Categories'}
                  checked={categories.filter(c => c === 'Sports').length > 0}
                  label={
                    <React.Fragment>
                      Sports{' '}
                      <EuiToolTip position='bottom' content='Prioritizes top sports sites such as ESPN, Yahoo! Sports, CBS Sports, NFL, Rivals, Bleacher Report and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Celebrity')
                  }}
                  disabled={placement !== 'Categories'}
                  checked={categories.filter(c => c === 'Celebrity').length > 0}
                  label={
                    <React.Fragment>
                      Celebrity{' '}
                      <EuiToolTip position='bottom' content='Prioritizes premium sites featuring celebrity lifestyle content such as People, TMZ, E! News, Perez Hilton, too fab more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'News')
                  }}
                  disabled={placement !== 'Categories'}
                  checked={categories.filter(c => c === 'News').length > 0}
                  label={
                    <React.Fragment>
                      News{' '}
                      <EuiToolTip position='bottom' content='Prioritizes top news sites such as Newsweek, CBS, Huffington Post, Fox News, ABC News, local news outlets and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Finance')
                  }}
                  disabled={placement !== 'Categories'}
                  checked={categories.filter(c => c === 'Finance').length > 0}
                  label={
                    <React.Fragment>
                      Finance{' '}
                      <EuiToolTip position='bottom' content='Prioritizes financial sites such as Wall Street Journal, MarketWatch, Financial Times, Yahoo! Finance, Bloomberg, CNN Money and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Weather')
                  }}
                  disabled={placement !== 'Categories'}
                  checked={categories.filter(c => c === 'Weather').length > 0}
                  label={
                    <React.Fragment>
                      Weather{' '}
                      <EuiToolTip position='bottom' content='Prioritizes weather sites such as The Weather Channel, Accuweather, WeatherBug and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Community')
                  }}
                  disabled={placement !== 'Categories'}
                  checked={categories.filter(c => c === 'Community').length > 0}
                  label={
                    <React.Fragment>
                      Community{' '}
                      <EuiToolTip position='bottom' content='Prioritizes community sites such as NextDoor, Reddit, MSN, AOL, Yahoo!, specialty blogs and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, 'Entertainment')
                  }}
                  disabled={placement !== 'Categories'}
                  checked={categories.filter(c => c === 'Entertainment').length > 0}
                  label={
                    <React.Fragment>
                      Entertainment{' '}
                      <EuiToolTip position='bottom' content='Prioritizes entertainment sites such as Entertainment Weekly, Fandango, Thrillist, E! News, Variety, Rolling Stone and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
            </EuiFlexGrid>
          </EuiCheckableCard>
          <EuiSpacer size='m' />

          <EuiCheckableCard
            className='specificCheckBox'
            disabled={true}
            id={htmlIdGenerator()()}
            label={<strong>Prioritize a list of specific sites.</strong>}
            name={'placement'}
            checked={placement === AdPlacementType.Specific}
            onChange={() => {
              setPlacement(AdPlacementType.Specific)
            }}>
            <EuiText size='s' color={placement !== 'Specific' ? 'subdued' : 'primary'}>
              Enter the sites you want to prioritize.
            </EuiText>
            <div hidden={placement !== 'Specific'} style={{ marginLeft: 35, marginTop: 20 }}>
              <EuiFormRow
                label='Enter a site'
                isInvalid={invalidSite}
                error={'Please enter a valid site'}
                fullWidth
                labelAppend={
                  <EuiText size='xs'>
                    <EuiToolTip position='bottom' content='Examples: people.com, si.com, parents.com, motortrend.com'>
                      <EuiIcon type='iInCircle' style={{ marginBottom: 2 }} />
                    </EuiToolTip>
                  </EuiText>
                }>
                <EuiFieldText
                  value={newSite}
                  onChange={v => setNewSite(v.target.value)}
                  isInvalid={invalidSite}
                  fullWidth
                  append={
                    <EuiButton id='add' onClick={addNewSite} type='button'>
                      Add
                    </EuiButton>
                  }
                />
              </EuiFormRow>
              <EuiSpacer size='s' />
              <EuiListGroup bordered={true} maxWidth={false} hidden={specific.length === 0}>
                {specific.map(s => (
                  <EuiListGroupItem
                    key={s}
                    label={s}
                    extraAction={{
                      onClick: () => {
                        setSpecific(specific.filter(x => x !== s))
                      },
                      iconType: 'cross',
                      iconSize: 's',
                      'aria-label': 'Remove',
                      alwaysShow: true
                    }}
                  />
                ))}
              </EuiListGroup>
            </div>
          </EuiCheckableCard>
          <EuiSpacer />

          <EuiShowFor sizes={['xs', 's']}>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButton id='reviewAndFinalizeBtn' fill isLoading={isSubmitting} onClick={gotoActivateStep}>
                  Review &amp; Finalize
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton id='back' fill type='button' color='text' onClick={returnPreviousStep}>
                  Back
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiShowFor>

          <EuiHideFor sizes={['xs', 's']}>
            <EuiFlexGroup responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiButton id='back' fill type='button' color='text' onClick={returnPreviousStep} iconType='arrowLeft' iconSide='left'>
                  Back
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton id='reviewAndFinalizeBtn' fill isLoading={isSubmitting} onClick={gotoActivateStep} iconType='arrowRight' iconSide='right'>
                  Review &amp; Finalize
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiHideFor>
        </EuiFlexItem>
        <EuiHideFor sizes={['s']}>
          <EuiFlexItem grow={false} style={{ width: 260 }}>
            <EuiText size='xs'>
              <h3>Best Practices</h3>
              <h5>How Much to Budget</h5>
              <p>Starting small is always a good idea. Once you have determined your ads are performing as intended, you can always increase the budget or launch additional campaigns.</p>
            </EuiText>
          </EuiFlexItem>
        </EuiHideFor>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default DeliverStep
