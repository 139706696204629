import React from 'react'
import { Helmet } from 'react-helmet'

import { EuiFormRow, EuiLoadingSpinner, EuiSpacer, EuiTitle } from '@elastic/eui'

export const FinalizingCampaign: React.FC = () => {
  return (
    <React.Fragment>
      <React.Fragment>
        <Helmet>
          <title>Finalizing Your Campaign</title>
        </Helmet>
        <EuiTitle size='s'>
          <h2>Finalizing Your Campaign</h2>
        </EuiTitle>
        <EuiSpacer />
        <EuiFormRow fullWidth>
          <EuiLoadingSpinner size='xl' />
        </EuiFormRow>
        <EuiSpacer />
      </React.Fragment>
    </React.Fragment>
  )
}
