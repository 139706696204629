import React, { createContext, useContext } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from 'app/rootReducer'
import { useIsAgency } from 'whiteLabel/useIsAgency'

import { WhiteLabel } from './WhiteLabel'

const WhiteLabelContext = createContext<WhiteLabel | undefined>(undefined)

export const useWhiteLabel = () => useContext(WhiteLabelContext)

interface Props {
  children: any
}

const AdCritterTheme = React.lazy(() => import('./AdCritterTheme'))
const AgenciesTheme = React.lazy(() => import('./AgenciesTheme'))
const VistaTheme = React.lazy(() => import('./VistaTheme'))

export const WhiteLabelProvider: React.FC<Props> = ({ children }) => {
  const { isVendastaUser, isVendastaPartnerUser } = useSelector((state: RootState) => state.app)
  const url = window.location.href
  let domain = new URL(url)
  const [whiteLabel, setWhiteLabel] = React.useState<WhiteLabel>(new WhiteLabel(domain.hostname, domain.port, isVendastaUser, isVendastaPartnerUser, false))
  const isAgency = useIsAgency()

  React.useEffect(() => {
    setWhiteLabel(new WhiteLabel(domain.hostname, domain.port, isVendastaUser, isVendastaPartnerUser, isAgency))
  }, [isAgency])

  return (
    <WhiteLabelContext.Provider value={whiteLabel}>
      <React.Suspense fallback={<React.Fragment />}>
        {whiteLabel.getTheme() === 'adcritter' && <AdCritterTheme />}
        {whiteLabel.getTheme() === 'agencies' && <AgenciesTheme />}
        {whiteLabel.getTheme() === 'vista' && <VistaTheme />}
      </React.Suspense>
      {children}
    </WhiteLabelContext.Provider>
  )
}
