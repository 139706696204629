import axios, { AxiosRequestConfig } from 'axios'
import moment, { Moment } from 'moment'

import config from 'app/config'
import history from 'services/HistoryService'
import localStoreManagerService, { deleteAccessToken, deleteCurrentAccount, deleteCurrentOrganization, deleteCurrentUser, deleteVendastaNavBarUrl, getAccessToken, getCurrentOrganization } from 'services/LocalStoreManagerService'

import {
  Account,
  AddressAutocomplete,
  AddressPlace,
  AdvancedBuilder,
  AnalyticsResult,
  AuthResponse,
  BillboardBuilder,
  BillboardCustomization,
  BuilderResult,
  BusinessType,
  Campaign,
  CampaignPrepareStatusActive,
  CampaignPrepareUnpaid,
  City,
  CreateAd,
  CreateAdDisplayAd,
  Creative,
  CreditNote,
  DashboardFilter,
  Dma,
  AcImage,
  ImageSearch,
  Invoice,
  Organization,
  OrganizationPrepareCapture,
  OrganizationPrepareCaptureResult,
  OrganizationPrepareUnpaid,
  PaymentMethod,
  PaymentMethodSetup,
  ReportAccount,
  ReportAd,
  ReportCampaign,
  StandardBuilder,
  StandardTargeting,
  State,
  Subscription,
  Suggestion,
  ThirdPartyAudience,
  Timezone,
  TvCommercial,
  TvBuilderRetail,
  UpdateAdvancedTargeting,
  UpdateBillboardTargeting,
  UpdateTvTargeting,
  User,
  Zip,
  TvCommercialPreviewCustomization,
  BillboardSuggestion,
  VistaBuilder,
  UpdateVistaTargeting,
  ReportCampaignDimension,
  CreateAdTvAd,
  RegionTarget,
  CountryTarget,
  CityTarget,
  PostalCodeTarget,
  DmaTarget,
  IntervalUnit
} from './interfaces'

export let getCookie = function (name: string) {
  let nameEQ = name + '='
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

const addHeaders = (config: AxiosRequestConfig) => {
  let fbp = getCookie('_fbp')
  let fbc = getCookie('_fbc')

  if (fbp && fbc) {
    config.headers!['AC-P'] = fbp
    config.headers!['AC-C'] = fbc
  }

  config.headers!['AC-URL'] = window.location.toString()
  return Promise.resolve(config)
}

export interface ApiError {
  code: string
  name: string
  message: string
  description: string
}

export interface ApiResponse<T> {
  errors: ApiError[]
  data: T
  timestamp: string
}

const openAxios = (isAgency: boolean = false) => {
  const instance = axios.create({
    baseURL: `${config.api.BASEURL}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'AC-Agency': isAgency ? 'true' : 'false'
    },
    validateStatus: function (status) {
      return status >= 200 && status < 500
    }
  })

  instance.interceptors.request.use(addHeaders)

  return instance
}

export const publicAxios = () => {
  const instance = axios.create({
    baseURL: `${config.api.BASEURL}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })

  instance.interceptors.request.use(addHeaders)

  instance.interceptors.response.use(
    response => {
      return response
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  return instance
}

export const securedAxios = (isAgency: boolean = false) => {
  const instance = axios.create({
    baseURL: `${config.api.BASEURL}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
      'AC-Agency': isAgency ? 'true' : 'false'
    }
  })

  instance.interceptors.request.use(addHeaders)

  instance.interceptors.response.use(
    response => {
      return response
    },
    function (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        deleteAccessToken()
        deleteCurrentAccount()
        deleteVendastaNavBarUrl()
        deleteCurrentOrganization()
        deleteCurrentUser()
        localStoreManagerService.clearSessionStorage()
        history.push('/login')
      }

      return Promise.reject(error)
    }
  )

  return instance
}

export const securedContextAxios = () => {
  const currentOrganization = getCurrentOrganization()
  const currentOrganizationId = currentOrganization === null ? '' : currentOrganization.id
  const instance = axios.create({
    baseURL: `${config.api.BASEURL}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
      'AC-Agency': currentOrganization?.isAgency ? 'true' : 'false',
      'AC-OrganizationId': `${currentOrganizationId}`
    }
  })

  instance.interceptors.request.use(addHeaders)

  instance.interceptors.response.use(
    response => {
      return response
    },
    function (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        deleteAccessToken()
        deleteCurrentAccount()
        deleteVendastaNavBarUrl()
        deleteCurrentOrganization()
        deleteCurrentUser()
        localStoreManagerService.clearSessionStorage()
        history.push('/login')
      }

      return Promise.reject(error)
    }
  )

  return instance
}

export async function apiLogin(email: string, password: string) {
  const url = `users/auth`
  const { data } = await openAxios().post<ApiResponse<AuthResponse>>(url, {
    email,
    password
  })

  return data
}

export async function apiChangePassword(password: string) {
  const url = `users/changeCredentials`

  const { data } = await securedContextAxios().post<ApiResponse<boolean>>(url, {
    password: password
  })

  return data
}

export async function apiLoginWithRefreshToken(token: string) {
  const url = `users/auth`

  const { data } = await openAxios().post<ApiResponse<AuthResponse>>(url, {
    refreshToken: token
  })

  return data
}

export async function apiVistaSignUp(firstName: string, lastName: string, company: string, website: string, email: string, password: string, businessTypeId: string) {
  const url = `users/signup-vista`

  const { data } = await openAxios().post<ApiResponse<AuthResponse>>(url, {
    firstName,
    lastName,
    company,
    website,
    email,
    password,
    businessTypeId
  })

  return data
}

export async function apiForgot(email: string) {
  const url = `users/forgot`

  const { data } = await openAxios().post<ApiResponse<boolean>>(url, {
    email
  })

  return data
}

export async function apiGetOrganization() {
  const url = `organizations`

  const { data } = await securedContextAxios().get<ApiResponse<Organization>>(url)

  return data
}

export async function apiGetOrganizationDirect(organizationId: string) {
  const url = `organizations/${organizationId}/direct`

  const { data } = await securedContextAxios().get<ApiResponse<Organization>>(url)

  return data
}

export async function apiPutOrganization(name: string, billingEmail: string) {
  const url = `organizations`

  const { data } = await securedContextAxios().put<ApiResponse<Organization>>(url, {
    name,
    billingEmail
  })

  return data
}

export async function apiGetOrganizationPaymentMethods(accountId: string) {
  const url = `accounts/${accountId}/paymentMethods`

  const { data } = await securedContextAxios().get<ApiResponse<PaymentMethod[]>>(url)

  return data
}

export async function apiMakeDefaultOrganizationPaymentMethods(accountId: string, paymentMethodId: string) {
  const url = `accounts/${accountId}/paymentMethods/${paymentMethodId}/makeDefault`

  const { data } = await securedContextAxios().post<ApiResponse<PaymentMethod[]>>(url, {})

  return data
}

export async function apiDeleteOrganizationPaymentMethods(accountId: string, paymentMethodId: string) {
  const url = `accounts/${accountId}/paymentMethods/${paymentMethodId}`

  const { data } = await securedContextAxios().delete<ApiResponse<PaymentMethod[]>>(url)

  return data
}

export async function apiGetOrganizationSubscriptions() {
  const url = `organizations/subscriptions`

  const { data } = await securedContextAxios().get<ApiResponse<Subscription>>(url)

  return data
}

export async function apiGetOrganizationSubscriptionInvoices() {
  const url = `organizations/subscriptions/invoices`

  const { data } = await securedContextAxios().get<ApiResponse<Invoice[]>>(url)

  return data
}

export async function apiCancelOrganizationSubscriptions() {
  const url = `organizations/subscriptions`

  const { data } = await securedContextAxios().delete<ApiResponse<Subscription>>(url)

  return data
}

export async function apiGetOrganizationPrepareUnpaidSubscription() {
  const url = `organizations/unpaid/prepare`

  const { data } = await securedContextAxios().get<ApiResponse<OrganizationPrepareUnpaid>>(url)

  return data
}

export async function apiPostOrganizationRestart(paymentMethodId?: string) {
  const url = `organizations/restart`

  const { data } = await securedContextAxios().post<ApiResponse<Organization>>(url, { paymentMethodId: paymentMethodId })

  return data
}

export async function apiReactivateOrganizationSubscriptions() {
  const url = `organizations/subscriptions`

  const { data } = await securedContextAxios().put<ApiResponse<Subscription>>(url)

  return data
}

export async function apiGetOrganizationUsers() {
  const url = `organizations/users`

  const { data } = await securedContextAxios().get<ApiResponse<User[]>>(url)

  return data
}

export async function apiPostOrganizationUsers(firstName: string, lastName: string, email: string, password: string) {
  const url = `organizations/users`

  const { data } = await securedContextAxios().post<ApiResponse<User[]>>(url, { firstName, lastName, email, password })

  return data
}

export async function apiGetAccountCampaignInvoices(accountId: string) {
  const url = `accounts/${accountId}/campaigns/invoices`

  const { data } = await securedContextAxios().get<ApiResponse<Invoice[]>>(url)

  return data
}

export async function apiGetOrganizationInvoices(accountId: string) {
  const url = `accounts/${accountId}/invoices`

  const { data } = await securedContextAxios().get<ApiResponse<Invoice[]>>(url)

  return data
}

export async function apiGetOrganizationCreditNotes() {
  const url = `organizations/creditNotes`

  const { data } = await securedContextAxios().get<ApiResponse<CreditNote[]>>(url)

  return data
}

export async function apiAddStripCardToCustomer(accountId: string, stripePaymentMethodId: string) {
  const url = `accounts/${accountId}/addStripeCard`

  const { data } = await securedContextAxios().post<ApiResponse<boolean>>(url, { stripePaymentMethodId } as any)

  return data
}

export async function apiGetAccounts() {
  const url = `accounts`

  const { data } = await securedContextAxios().get<ApiResponse<Account[]>>(url)

  return data
}

export async function apiGetAccount(accountId: string) {
  const url = `accounts/${accountId}`

  const { data } = await securedContextAxios().get<ApiResponse<Account>>(url)

  return data
}

export async function apiGetAccountDirect(accountId: string) {
  const url = `accounts/${accountId}/direct`

  const { data } = await securedAxios().get<ApiResponse<Account>>(url)

  return data
}

export async function apiGetAccountsByOrganizationId(organizationId: string) {
  const url = `accounts/list?organizationId=${organizationId}`

  const { data } = await securedContextAxios().get<ApiResponse<Account[]>>(url)

  return data.data
}

export async function apiPostAccounts(businessTypeId: string, name: string, streetAddress: string, city: string, state: string, zip: string, country: string, website: string) {
  const url = `accounts`

  const { data } = await securedContextAxios().post<ApiResponse<Account>>(url, {
    businessTypeId,
    name,
    streetAddress,
    city,
    state,
    zip,
    country,
    website
  })

  return data
}

export async function apiPutAccounts(accountId: string, businessTypeId: string | null, name: string, streetAddress: string, city: string, state: string, zip: string, country: string, website: string) {
  const url = `accounts/${accountId}`

  const { data } = await securedContextAxios().put<ApiResponse<Account>>(url, {
    businessTypeId,
    name,
    streetAddress,
    city,
    state,
    zip,
    country,
    website
  })

  return data
}

export async function apiPutAccountAddress(accountId: string, streetAddress: string, city: string, state: string, zip: string, country: string) {
  const url = `accounts/${accountId}/address`

  const { data } = await securedContextAxios().put<ApiResponse<Account>>(url, {
    streetAddress,
    city,
    state,
    zip,
    country
  })

  return data
}

export async function apiPutAccountBusinessType(accountId: string, businessTypeId: string) {
  const url = `accounts/${accountId}/businessType`

  const { data } = await securedContextAxios().put<ApiResponse<Account>>(url, {
    businessTypeId
  })

  return data
}

export async function apiGetAccountPaymentMethods(accountId: string) {
  const url = `accounts/${accountId}/paymentMethods`

  const { data } = await securedContextAxios().get<ApiResponse<PaymentMethod[]>>(url)

  return data
}

export async function apiSetupAccountPaymentMethods(accountId: string) {
  const url = `accounts/${accountId}/paymentMethods/setup`

  const { data } = await securedContextAxios().get<ApiResponse<PaymentMethodSetup>>(url)

  return data
}

export async function apiMakeDefaultAccountPaymentMethods(accountId: string, paymentMethodId: string) {
  const url = `accounts/${accountId}/paymentMethods/${paymentMethodId}/makeDefault`

  const { data } = await securedContextAxios().post<ApiResponse<PaymentMethod[]>>(url, {})

  return data
}

export async function apiDeleteAccountPaymentMethods(accountId: string, paymentMethodId: string) {
  const url = `accounts/${accountId}/paymentMethods/${paymentMethodId}`

  const { data } = await securedContextAxios().delete<ApiResponse<PaymentMethod[]>>(url)

  return data
}

export async function apiGetAccountInvoices(accountId: string) {
  const url = `accounts/${accountId}/invoices`

  const { data } = await securedContextAxios().get<ApiResponse<Invoice[]>>(url)

  return data
}

export async function apiGetAccountCreditNotes(accountId: string) {
  const url = `accounts/${accountId}/creditNotes`

  const { data } = await securedContextAxios().get<ApiResponse<CreditNote[]>>(url)

  return data
}

export async function apiGetAccountUploadedImages(accountId: string) {
  const url = `accounts/${accountId}/images`

  const { data } = await securedContextAxios().get<ApiResponse<AcImage[]>>(url)

  return data
}

export async function apiGetBusinessType(businessTypeId: string) {
  const url = `businessTypes/${businessTypeId}`

  const { data } = await openAxios().get<ApiResponse<BusinessType>>(url)

  return data
}

export async function apiSearchBusinessTypes(query: string) {
  const url = `businessTypes/search?query=${query}`

  const { data } = await openAxios().get<ApiResponse<BusinessType[]>>(url)

  return data
}

export async function apiGetImages(businessTypeId: string) {
  const url = `images?businessTypeId=${businessTypeId}`

  const { data } = await securedAxios().get<ApiResponse<AcImage[]>>(url)

  return data
}

export async function apiGetImageSearchHints(prefix: string) {
  const url = `images/search/hints?prefix=${prefix}`

  const { data } = await securedAxios().get<ApiResponse<string[]>>(url)

  return data
}

export async function apiGetImageSearch(search: string, pageSize: number, currentPage: number) {
  const url = `images/search?search=${search}&pageSize=${pageSize}&currentPage=${currentPage}`

  const { data } = await securedAxios().get<ApiResponse<ImageSearch>>(url)

  return data
}

export async function apiGetCampaigns(accountId: string) {
  const url = `accounts/${accountId}/campaigns`

  const { data } = await securedContextAxios().get<ApiResponse<Campaign[]>>(url)
  return data
}

export async function apiGetCampaign(accountId: string, campaignId: string) {
  const url = `accounts/${accountId}/campaigns/${campaignId}`

  const { data } = await securedContextAxios().get<ApiResponse<Campaign>>(url)
  return data
}

export async function apiPutCampaign(accountId: string, campaignId: string, name: string, budget: number, maxCpm?: number, startDate?: moment.Moment, endDate?: moment.Moment, monthlyImpressions: number | null = null) {
  const url = `accounts/${accountId}/campaigns/${campaignId}`

  const { data } = await securedContextAxios().put<ApiResponse<Campaign>>(url, {
    name,
    budget,
    monthlyImpressions,
    startDate,
    endDate,
    maxCpm
  })

  return data
}

export async function apiPutCampaignAdPlacement(accountId: string, campaignId: string, placement: string, categories: Array<string>, specific: Array<string>) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/adPlacement`

  const { data } = await securedContextAxios().put<ApiResponse<Campaign>>(url, {
    placement,
    categories,
    specific
  })

  return data
}

export async function apiPutCampaignStandardTargeting(accountId: string, campaignId: string, standardTargeting: StandardTargeting) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/standardTargeting`

  const { data } = await securedContextAxios().put<ApiResponse<Campaign>>(url, standardTargeting)

  return data
}

export async function apiPutCampaignAdvancedTargeting(accountId: string, campaignId: string, advancedTargeting: UpdateAdvancedTargeting) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/advancedTargeting`

  const { data } = await securedContextAxios().put<ApiResponse<Campaign>>(url, advancedTargeting)

  return data
}

export async function apiPutCampaignVistaTargeting(accountId: string, campaignId: string, advancedTargeting: UpdateVistaTargeting) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/vistaTargeting`

  const { data } = await securedContextAxios().put<ApiResponse<Campaign>>(url, advancedTargeting)

  return data
}

export async function apiPutCampaignTvTargeting(accountId: string, campaignId: string, tvTargeting: UpdateTvTargeting) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/tvTargeting`

  const { data } = await securedContextAxios().put<ApiResponse<Campaign>>(url, tvTargeting)

  return data
}

export async function apiPutCampaignBillboardTargeting(accountId: string, campaignId: string, billboardTargeting: UpdateBillboardTargeting) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/billboardTargeting`

  const { data } = await securedContextAxios().put<ApiResponse<Campaign>>(url, billboardTargeting)

  return data
}

export async function apiPutCampaignStatusPaused(accountId: string, campaignId: string) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/status/pause`

  const { data } = await securedContextAxios().put<ApiResponse<Campaign>>(url, {})

  return data
}

export async function apiPutCampaignStatusActive(accountId: string, campaignId: string) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/status/active`

  const { data } = await securedContextAxios().put<ApiResponse<Campaign>>(url, {})

  return data
}

export async function apiReactivateCampaign(accountId: string, campaignId: string, paymentMethodId: string) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/reactivateCampaign?paymentMethodId=${paymentMethodId}`

  const { data } = await securedContextAxios().put<ApiResponse<Campaign>>(url, {})

  return data
}

export async function apiDeleteCampaign(accountId: string, campaignId: string) {
  const url = `accounts/${accountId}/campaigns/${campaignId}`

  const { data } = await securedContextAxios().delete<ApiResponse<Campaign>>(url, {})

  return data
}

export async function apiDeleteBundleWithOneCampaignByCampaignId(accountId: string, campaignId: string) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/bundles`

  const { data } = await securedContextAxios().delete<ApiResponse<Campaign>>(url, {})

  return data
}

export async function apiPutCampaignStatusArchived(accountId: string, campaignId: string) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/status/archive`

  const { data } = await securedContextAxios().put<ApiResponse<Campaign>>(url, {})

  return data
}

export async function apiPostAd(accountId: string, request: CreateAd) {
  const url = `accounts/${accountId}/creatives/native`

  const { data } = await securedContextAxios().post<ApiResponse<Creative>>(url, request)

  return data
}

export async function apiPostDisplayAd(accountId: string, request: CreateAdDisplayAd) {
  const url = `accounts/${accountId}/creatives/display`

  const { data } = await securedContextAxios().post<ApiResponse<Creative>>(url, request)

  return data
}

export async function apiPostTvAd(accountId: string, request: CreateAdTvAd) {
  const url = `accounts/${accountId}/creatives/tv`

  const { data } = await securedContextAxios().post<ApiResponse<Creative>>(url, request)

  return data
}

export async function apiDeleteAd(accountId: string, adId: string) {
  const url = `accounts/${accountId}/creatives/native/${adId}`

  await securedContextAxios().delete<ApiResponse<Creative>>(url)
}

export async function apiPutAdStatusPaused(accountId: string, adId: string, campaignId: string) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/creatives/native/${adId}/status/pause`

  const { data } = await securedContextAxios().put<ApiResponse<Creative>>(url, {})

  return data
}

export async function apiPutAdStatusActive(accountId: string, adId: string, campaignId: string) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/creatives/native/${adId}/status/active`

  const { data } = await securedContextAxios().put<ApiResponse<Creative>>(url, {})

  return data
}

export async function apiGetAds(accountId: string, campaignId: string) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/creatives`

  const { data } = await securedContextAxios().get<ApiResponse<Creative[]>>(url)

  return data
}

export async function apiGetAd(accountId: string, adId: string) {
  const url = `accounts/${accountId}/creatives/${adId}`

  const { data } = await securedContextAxios().get<ApiResponse<Creative>>(url)

  return data
}

export async function apiGetSuggestedBillboards(accountId: string, businessTypeId: string, companyName: string, city: string | null) {
  let url = `accounts/${accountId}/campaigns/builder/billboard/suggestions?businessTypeId=${businessTypeId}&companyName=${companyName}`

  if (city) url += `&city=${city}`

  const { data } = await securedContextAxios().get<ApiResponse<BillboardSuggestion[]>>(url)

  return data
}

export async function apiPostInternetRetailStandardBuilder(accountId: string, request: StandardBuilder) {
  const url = `accounts/${accountId}/campaigns/builder/retail/standard`

  const { data } = await securedContextAxios().post<ApiResponse<BuilderResult>>(url, request)

  return data
}

export async function apiPostInternetRetailAdvancedBuilder(accountId: string, request: AdvancedBuilder) {
  const url = `accounts/${accountId}/campaigns/builder/retail/advanced`

  const { data } = await securedContextAxios().post<ApiResponse<BuilderResult>>(url, request)

  return data
}

export async function apiPostInternetVistaBuilder(accountId: string, request: VistaBuilder) {
  const url = `accounts/${accountId}/campaigns/builder/vista/advanced`

  const { data } = await securedContextAxios().post<ApiResponse<BuilderResult>>(url, request)

  return data
}

export async function apiPostTvBuilderRetail(accountId: string, request: TvBuilderRetail) {
  const url = `accounts/${accountId}/campaigns/builder/tv/retail`

  const { data } = await securedContextAxios().post<ApiResponse<BuilderResult>>(url, request)

  return data
}

export async function apiPostBillboardBuilderRetail(accountId: string, request: BillboardBuilder) {
  const url = `accounts/${accountId}/campaigns/builder/billboard/retail`

  const { data } = await securedContextAxios().post<ApiResponse<BuilderResult>>(url, request)

  return data
}

export async function apiPostBillboardCreateAdRetail(accountId: string, request: BillboardCustomization) {
  const url = `accounts/${accountId}/creative/billboard/retail`

  const { data } = await securedContextAxios().post<ApiResponse<Creative>>(url, request)

  return data
}

export async function apiGetInternetBuilderUpload(accountId: string) {
  const url = `accounts/${accountId}/campaigns/builder/internet/upload`

  const { data } = await securedContextAxios().get<ApiResponse<string>>(url)

  return data
}

export async function apiGetTvBuilderUpload(accountId: string) {
  const url = `accounts/${accountId}/campaigns/builder/tv/upload`

  const { data } = await securedContextAxios().get<ApiResponse<string>>(url)

  return data
}

export async function apiGetBillboardBuilderUpload(accountId: string) {
  const url = `accounts/${accountId}/campaigns/builder/billboard/upload`

  const { data } = await securedContextAxios().get<ApiResponse<string>>(url)

  return data
}

export async function apiGetBillboardById(accountId: string, billboardId: string) {
  const url = `accounts/${accountId}/campaigns/builder/billboard/${billboardId}`
  const { data } = await securedContextAxios().get<ApiResponse<any>>(url)

  return data
}

export async function apiPrepareNewPaymentProviderPaymentMethod(accountId: string, cappedAmount?: number) {
  const url = `accounts/${accountId}/capture`

  const { data } = await securedContextAxios().post<ApiResponse<OrganizationPrepareCapture>>(url, { cappedAmount: cappedAmount })

  return data
}

export async function apiGetCampaignPrepareUnpaid(accountId: string, campaignId: string) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/unpaid/prepare`

  const { data } = await securedContextAxios().get<ApiResponse<CampaignPrepareUnpaid>>(url)

  return data
}

export async function apiPostPayUnpaidCampaign(accountId: string, campaignId: string, paymentMethodId: string) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/unpaid`

  const { data } = await securedContextAxios().post<ApiResponse<CampaignPrepareUnpaid>>(url, { paymentMethodId: paymentMethodId })

  return data
}

export async function apiPostPayUnpaidBundle(accountId: string, campaignId: string, paymentMethodId: string) {
  const url = `accounts/${accountId}/campaigns/bundle/${campaignId}/unpaid`

  const { data } = await securedContextAxios().post<ApiResponse<CampaignPrepareUnpaid>>(url, { paymentMethodId: paymentMethodId })

  return data
}

export async function apiPutCampaignIncreaseBudgetBilling(accountId: string, campaignId: string, budget: number, name: string) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/increaseBudgetBilling`

  const { data } = await securedContextAxios().put<ApiResponse<boolean>>(url, {
    budget,
    name
  } as any)

  return data
}

export async function apiPostCampaignDuplicate(accountId: string, campaignId: string, name: string) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/duplicate`

  const { data } = await securedContextAxios().post<ApiResponse<Campaign>>(url, { Name: name })

  return data
}

export async function apiGetCampaignPrepareStatusActive(accountId: string, campaignId: string) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/status/active/prepare`

  const { data } = await securedContextAxios().get<ApiResponse<CampaignPrepareStatusActive>>(url)

  return data
}

export async function apiPostCampaignActivate(accountId: string, campaignId: string, paymentMethodId: string, campaignSlotId: string | null) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/activate`

  const { data } = await securedContextAxios().post<ApiResponse<OrganizationPrepareCaptureResult>>(url, {
    paymentMethodId,
    campaignSlotId
  } as any)

  return data
}

export async function apiPostCampaignActivateNoCharge(accountId: string, campaignId: string) {
  const url = `accounts/${accountId}/campaigns/${campaignId}/activateNoCharge`

  const { data } = await securedContextAxios().post<ApiResponse<Campaign>>(url, {})

  return data
}

export async function apiGetDashboardFilters(accountId: string) {
  const url = `analytics/${accountId}/filters`

  const { data } = await securedContextAxios().get<ApiResponse<DashboardFilter>>(url)

  return data
}

export async function apiPostAnalytics(accountId: string, start: Moment, end: Moment, campaignIds: Array<string>, adIds: Array<string>, label: string) {
  const url = `analytics/${accountId}`

  const { data } = await securedContextAxios().post<ApiResponse<AnalyticsResult>>(url, {
    start: start.format('YYYY-MM-DD'),
    end: end.format('YYYY-MM-DD'),
    campaignIds,
    adIds,
    label
  })

  return data
}

export async function apiGetReportAd(accountId: string, campaignId: Array<string> | null, start: Moment, end: Moment) {
  const url = campaignId === null ? `reports/${accountId}/ads/?start=${start.format('YYYY-MM-DD')}&end=${end.format('YYYY-MM-DD')}` : `reports/${accountId}/ads?start=${start.format('YYYY-MM-DD')}&end=${end.format('YYYY-MM-DD')}&campaignId=${campaignId.join('&campaignId=')}`

  const { data } = await securedContextAxios().get<ApiResponse<ReportAd[]>>(url)

  return data
}

export async function apiGetReportCampaign(accountId: string, campaignId: Array<string> | null, start: Moment, end: Moment, campaignBreakDownBy: IntervalUnit) {
  const url = campaignId === null ? `reports/${accountId}/campaigns/?start=${start.format('YYYY-MM-DD')}&end=${end.format('YYYY-MM-DD')}&breakDownBy=${campaignBreakDownBy}` : `reports/${accountId}/campaigns?start=${start.format('YYYY-MM-DD')}&end=${end.format('YYYY-MM-DD')}&campaignId=${campaignId.join('&campaignId=')}&breakDownBy=${campaignBreakDownBy}`

  const { data } = await securedContextAxios().get<ApiResponse<ReportCampaign[]>>(url)

  return data
}

export async function apiGetReportCampaignDimension(accountId: string, campaignId: Array<string> | null, dimension: string, breakDownBy: IntervalUnit, start: Moment, end: Moment) {
  const url = campaignId === null ? `reports/${accountId}/campaigns/dimension/${dimension.toLowerCase()}/?breakDownBy=${breakDownBy}&start=${start.format('YYYY-MM-DD')}&end=${end.format('YYYY-MM-DD')}` : `reports/${accountId}/campaigns/dimension/${dimension.toLowerCase()}?breakDownBy=${breakDownBy}&start=${start.format('YYYY-MM-DD')}&end=${end.format('YYYY-MM-DD')}&campaignId=${campaignId.join('&campaignId=')}`

  const { data } = await securedContextAxios().get<ApiResponse<ReportCampaignDimension[]>>(url)

  return data
}
export async function apiGetReportAccount(accountId: string, start: Moment, end: Moment) {
  const url = `reports/${accountId}/accounts?start=${start.format('YYYY-MM-DD')}&end=${end.format('YYYY-MM-DD')}`

  const { data } = await securedContextAxios().get<ApiResponse<ReportAccount[]>>(url)

  return data
}

export async function apiGetSuggestion(accountId: string, businessTypeId: string, companyName: string, city: string | null, isShort: boolean = false) {
  let url = `accounts/${accountId}/campaigns/builder/suggestions/internet/general?businessTypeId=${businessTypeId}&companyName=${companyName}`

  if (city) url += `&city=${city}`
  if (isShort) url += `&isShort=true`

  const { data } = await securedContextAxios().get<ApiResponse<Suggestion>>(url)

  return data
}

export async function apiGetSuggestedTvCommercials(accountId: string, businessTypeId: string) {
  let url = `accounts/${accountId}/campaigns/builder/tv/suggestions?businessTypeId=${businessTypeId}`

  const { data } = await securedContextAxios().get<ApiResponse<TvCommercial[]>>(url)

  return data
}

export async function apiGetTvCommercialVideoPreviewUrl(accountId: string, tvCommercialId: string, customization: TvCommercialPreviewCustomization) {
  let url = `accounts/${accountId}/campaigns/builder/tv/suggestions/preview?tvCommercialId=${tvCommercialId}`

  const { data } = await securedContextAxios().post<ApiResponse<string>>(url, customization)

  return data
}

export async function apiGetTvCommercialVideoGenericPreviewUrl(accountId: string, tvCommercialId: string, businessTypeId: string) {
  let url = `accounts/${accountId}/campaigns/builder/tv/suggestions/genericpreview?tvCommercialId=${tvCommercialId}&businessTypeId=${businessTypeId}`

  const { data } = await securedContextAxios().get<ApiResponse<string>>(url)

  return data
}

export async function apiGetBillboardPreviewUrl(accountId: string, customization: BillboardCustomization) {
  let url = `accounts/${accountId}/campaigns/builder/billboard/suggestions/preview`

  const { data } = await securedContextAxios().post<ApiResponse<string>>(url, customization)

  return data
}

export async function apiGetDataCountryTargets() {
  const url = `data/countries/new`

  const { data } = await openAxios().get<ApiResponse<CountryTarget[]>>(url)

  return data
}

export async function apiGetDataRegionTargets(countryId: number) {
  const url = `data/${countryId}/regions/new`

  const { data } = await openAxios().get<ApiResponse<RegionTarget[]>>(url)

  return data
}

export async function apiGetDataStates(countryCode: string) {
  const url = `data/${countryCode}/states`

  const { data } = await openAxios().get<ApiResponse<State[]>>(url)

  return data
}

export async function apiGetDataDmaTargets(countryId: number) {
  const url = `data/${countryId}/dmas/new`

  const { data } = await openAxios().get<ApiResponse<DmaTarget[]>>(url)

  return data
}

export async function apiGetDataDmas(countryCode: string) {
  const url = `data/${countryCode}/dmas`

  const { data } = await openAxios().get<ApiResponse<Dma[]>>(url)

  return data
}

export async function apiGetDataCityTargets(countryId: number, query: string) {
  const url = `data/${countryId}/cities/new?query=${query}`

  const { data } = await securedAxios().get<ApiResponse<CityTarget[]>>(url)

  return data
}

export async function apiGetDataCities(countryCode: string, query: string) {
  const url = `data/${countryCode}/cities?query=${query}`

  const { data } = await securedAxios().get<ApiResponse<City[]>>(url)

  return data
}

export async function apiGetDataPostalCodeTargets(countryId: number, query: string) {
  const url = `data/${countryId}/postalcodes/new?query=${query}`

  const { data } = await securedAxios().get<ApiResponse<PostalCodeTarget[]>>(url)

  return data
}

export async function apiGetDataZips(countryCode: string, query: string) {
  const url = `data/${countryCode}/zips?query=${query}`

  const { data } = await securedAxios().get<ApiResponse<Zip[]>>(url)

  return data
}

export async function apiGetDataTimezones() {
  const url = `data/timezones`

  const { data } = await securedAxios().get<ApiResponse<Timezone[]>>(url)

  return data
}

export async function apiGetDataCallToActions() {
  const url = `data/callToActions`

  const { data } = await securedAxios().get<ApiResponse<string[]>>(url)

  return data
}

export async function apiGetDataAddressAutocomplete(query: string | null) {
  const url = `data/address/autocomplete?query=${query}`

  const { data } = await securedAxios().get<ApiResponse<AddressAutocomplete[]>>(url)

  return data
}

export async function apiGetDataAddressPlace(placeId: string | null) {
  const url = `data/address/${placeId}`

  const { data } = await securedAxios().get<ApiResponse<AddressPlace>>(url)

  return data
}

export async function apiGetDataAudiences(query: string | null) {
  const url = `data/audiences/thirdParty?query=${query}`

  const { data } = await securedAxios().get<ApiResponse<ThirdPartyAudience[]>>(url)

  return data
}

export async function apiGetDataTvAudiences(query: string | null) {
  const url = `data/audiences/tv?query=${query}`

  const { data } = await securedAxios().get<ApiResponse<ThirdPartyAudience[]>>(url)

  return data
}
