import { AuthResponse } from 'api/interfaces'
import { AuthConfig } from 'api/interfaces/AuthConfig'
import { VendastaAuthenticated } from 'api/interfaces/VendastaAuthenticated'
import { defaultTransformFn, platformApi } from 'api/rtkQueryApi/platform/platformApi'

export const VENDASTA_AUTHORIZE = 'StrawberryFieldAuthorize'
export const IS_VENDASTA_AUTHENTICATED = 'IsStrawberryFieldAuthenticated'

const VENDASTA_AUTH_URL = 'users/strawberryFieldAuth'
const VENDASTA_IS_AUTHENTICATED_URL = 'users/isStrawberryFieldAuthenticated'

export interface IIsVendastaAuthenticatedQuery {
  vendastaAccountId: string
  vendastaAccessToken: string
}

const authTags = platformApi.enhanceEndpoints({ addTagTypes: ['AuthConfig', VENDASTA_AUTHORIZE, IS_VENDASTA_AUTHENTICATED] })

const authApi = authTags.injectEndpoints({
  endpoints: build => ({
    getAuthConfig: build.query<AuthConfig, void>({
      query: () => {
        return {
          url: 'users/authconfig',
          method: 'GET',
          isContext: false
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'AuthConfig' }]
    }),
    vendastaAuthorize: build.query<AuthResponse, string>({
      query: queryParams => {
        return {
          url: `${VENDASTA_AUTH_URL}?${queryParams}`,
          method: 'GET',
          isContext: false
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: VENDASTA_AUTHORIZE }]
    }),
    getIsVendastaAuthenticated: build.query<VendastaAuthenticated, IIsVendastaAuthenticatedQuery>({
      query: ({ vendastaAccountId, vendastaAccessToken }) => {
        return {
          url: VENDASTA_IS_AUTHENTICATED_URL,
          method: 'POST',
          isContext: false,
          data: {
            strawberryFieldAccountId: vendastaAccountId,
            strawberryFieldAccessToken: vendastaAccessToken
          }
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: IS_VENDASTA_AUTHENTICATED }]
    })
  }),
  overrideExisting: false
})

export const { useGetAuthConfigQuery, useLazyGetAuthConfigQuery, useLazyVendastaAuthorizeQuery, useGetIsVendastaAuthenticatedQuery } = authApi
