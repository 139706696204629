import React from 'react'

import { EuiButton, EuiCard, EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui'

import { TvCommercial } from 'api/interfaces'

export interface IVideoCard {
  tvCommercial: TvCommercial
  onSelected: () => void
}
export const VideoCard: React.FC<IVideoCard> = props => {
  const onSelected = () => {
    props.onSelected()
  }

  return (
    <EuiFlexItem id='tvAdSuggestion' style={{ width: 640 }}>
      <EuiCard
        title={props.tvCommercial.name}
        textAlign={'left'}
        description={''}
        image={<img src={props.tvCommercial.thumbnailUrl || ''} alt='' />}
        footer={
          <EuiFlexGroup justifyContent='flexEnd'>
            <EuiFlexItem>
              <EuiText size='s'>{props.tvCommercial.kind === 'Seconds15' ? '15 seconds' : '30 seconds'}</EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton id='useEditBtn' color='primary' onClick={onSelected} style={{ width: 200 }} fill iconType='arrowRight' iconSide='right'>
                Use/Edit
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        }
      />
    </EuiFlexItem>
  )
}
