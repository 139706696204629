import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { apiGetImages, apiGetSuggestion } from 'api/adcritterApi'
import { AcImage, Suggestion, Template } from 'api/interfaces'
import { AppThunk } from 'app/store'

interface SuggestedState {
  isLoadingSuggestions: boolean
  headlines: Array<Template>
  bodies: Array<Template>
  isLoadingImages: boolean
  images: Array<AcImage>
}

const initialState: SuggestedState = {
  isLoadingSuggestions: false,
  headlines: [],
  bodies: [],
  isLoadingImages: false,
  images: []
}

const suggestions = createSlice({
  name: 'couponSuggestions',
  initialState,
  reducers: {
    startLoadingSuggestions(state: SuggestedState) {
      state.isLoadingSuggestions = true
    },
    finishedLoadingSuggestions(state: SuggestedState, { payload }: PayloadAction<Suggestion>) {
      state.headlines = payload.headlines
      state.bodies = payload.bodies
      state.isLoadingSuggestions = false
    },
    startLoadingImages(state: SuggestedState) {
      state.isLoadingImages = true
    },
    finishedLoadingImages(state: SuggestedState, { payload }: PayloadAction<AcImage[]>) {
      state.images = payload
      state.isLoadingImages = false
    },
    clearSuggestions(state: SuggestedState) {
      state.headlines = []
      state.bodies = []
      state.images = []
    }
  }
})

export const { startLoadingSuggestions, finishedLoadingSuggestions, startLoadingImages, finishedLoadingImages, clearSuggestions } = suggestions.actions

export default suggestions.reducer

export const fetchSuggestions =
  (accountId: string, businessTypeId: string, companyName: string, city: string | null, isShort: boolean = false): AppThunk =>
  async dispatch => {
    dispatch(startLoadingSuggestions())
    const response = await apiGetSuggestion(accountId, businessTypeId, companyName, city, isShort)
    dispatch(finishedLoadingSuggestions(response.data))
  }

export const fetchImages =
  (businessTypeId: string): AppThunk =>
  async dispatch => {
    dispatch(startLoadingImages())
    const response = await apiGetImages(businessTypeId)
    dispatch(finishedLoadingImages(response.data))
  }
