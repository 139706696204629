import { AcImage, CreateBulkDisplayAds, Creative, Suggestion, TvCommercial } from 'api/interfaces'
import { defaultTransformFn, platformApi } from 'api/rtkQueryApi/platform/platformApi'

export interface IGetCreativeQuery {
  accountId: string
  campaignId: string
  creativeId?: string
  isAgency?: boolean
}

const creativesTags = platformApi.enhanceEndpoints({ addTagTypes: ['Creatives', 'InternetTemplates', 'InternetImages', 'CampaignCreatives'] })

const creativesApi = creativesTags.injectEndpoints({
  endpoints: build => ({
    getCreatives: build.query<Creative[], IGetCreativeQuery>({
      query: ({ accountId, campaignId, isAgency }) => {
        return {
          url: `accounts/${accountId}/campaigns/${campaignId}/creatives`,
          method: 'GET',
          isContext: true,
          isAgency
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { campaignId }) => [{ type: 'Creatives', id: campaignId }]
    }),
    createDisplayAds: build.mutation<Creative, CreateBulkDisplayAds>({
      query: request => ({
        url: `accounts/${request.accountId}/creatives/bulk/display`,
        method: 'POST',
        data: {
          campaignId: request.campaignId,
          displayAds: request.displayAds
        },
        isContext: true
      }),
      invalidatesTags: (result, error, request) => [{ type: 'Creatives', id: request.campaignId }]
    }),
    getInternetTemplates: build.query<Suggestion, { accountId: string; businessTypeId: string; companyName: string; cityName?: string }>({
      query: ({ accountId, businessTypeId, companyName, cityName }) => {
        return {
          url: `accounts/${accountId}/campaigns/builder/suggestions/internet/general?businessTypeId=${businessTypeId}&companyName=${companyName}${cityName ? `&city=${cityName}` : ''}`,
          method: 'GET',
          isContext: true
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, request) => [{ type: 'InternetTemplates', id: JSON.stringify(request) }]
    }),
    getInternetImages: build.query<AcImage[], { businessTypeId: string }>({
      query: ({ businessTypeId }) => {
        return {
          url: `images?businessTypeId=${businessTypeId}`,
          method: 'GET',
          isContext: true
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, request) => [{ type: 'InternetImages', id: request.businessTypeId }]
    }),
    getTvCommercials: build.query<TvCommercial[], { accountId: string; businessTypeId: string }>({
      query: ({ accountId, businessTypeId }) => {
        return {
          url: `accounts/${accountId}/campaigns/builder/tv/suggestions?businessTypeId=${businessTypeId}`,
          method: 'GET',
          isContext: true
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, request) => [{ type: 'InternetImages', id: JSON.stringify(request) }]
    }),
    getCampaignCreatives: build.query<Creative[], { accountId: string; campaignId: string }>({
      query: ({ accountId, campaignId }) => {
        return {
          url: `accounts/${accountId}/campaigns/${campaignId}/creatives`,
          method: 'GET',
          isContext: true
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, request) => [{ type: 'CampaignCreatives', id: request.campaignId }]
    })
  }),
  overrideExisting: false
})

export const { useGetCreativesQuery, useCreateDisplayAdsMutation, useGetInternetTemplatesQuery, useGetInternetImagesQuery, useGetTvCommercialsQuery, useGetCampaignCreativesQuery } = creativesApi
