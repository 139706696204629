import React from 'react'
import { useSelector } from 'react-redux'

import { EuiListGroup, EuiListGroupItem } from '@elastic/eui'

import { RootState } from 'app/rootReducer'

interface StateTargeterProps {
  states: Array<string>

  onStateRemoved(state: string): void
}

export const StateTargeter = ({ states, onStateRemoved }: StateTargeterProps) => {
  const dictionary = useSelector((state: RootState) => state.dictionary)

  return (
    <React.Fragment>
      {!dictionary.isLoadingStates && states && states.length > 0 && (
        <EuiListGroup bordered maxWidth='100%' size='s'>
          {states.map(s => (
            <EuiListGroupItem
              size='s'
              key={s}
              label={`${dictionary.states.filter(state => state.code === s)[0].name}`}
              extraAction={{
                onClick: () => {
                  onStateRemoved(s)
                },
                iconType: 'cross',
                iconSize: 's',
                'aria-label': 'Remove',
                alwaysShow: true
              }}
            />
          ))}
        </EuiListGroup>
      )}
    </React.Fragment>
  )
}
