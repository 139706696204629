import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { EuiButton, EuiCallOut, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiModalBody, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { apiPrepareNewPaymentProviderPaymentMethod } from 'api/adcritterApi'
import { CampaignActiveStatus } from 'api/interfaces'
import { bundlesApi } from 'api/rtkQueryApi/platform/bundlesApi'
import { useUpdateUsageCapMutation } from 'api/rtkQueryApi/platform/shopifyApi'
import { setReturnUrlState, showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { CustomizationsContactForm } from 'components/Forms/CustomizationsContactForm'
import { AcConfirmModal } from 'components/Modals/AcConfirmModal'
import { PaymentOption } from 'components/shopify/PaymentOption'
import { TargetingType } from 'definitions/BuilderDefinitions'
import { BillboardPackages, IAdvertisingPackage, InternetPackages, VideoPackages } from 'definitions/ShopifyAdvertisingPackages'
import { FinalizingCampaign } from 'features/builders/FinalizingCampaign'
import { BillboardBuilderState, resetBuilder as resetBillboardBuilder, setNameAndBudget as setBillboardNameBudgetAndSetupIntentId } from 'features/builders/billboards/retail/builderBillboardRetailSlice'
import { AdvancedTargetingType, createQuickCampaign, InternetBuilderState, resetBuilder as resetInternetBuilder, setNameAndBudget as setInternetNameBudgetAndSetupIntentId } from 'features/builders/internet/retail/builderInternetRetailSlice'
import { resetBuilder as resetTvBuilder, setNameAndBudget as setTVNameBudgetAndSetupIntentId, TvBuilderState } from 'features/builders/tv/retail/builderTvRetailSlice'
import history from 'services/HistoryService'
import { CampaignType } from 'utils/CampaignType'
import { getMinPrice } from 'utils/ShopifyUtils'

export interface IActivateThirdPartyPaymentCampaignStep {
  campaignType: CampaignType
}

export const ActivateShopifyCampaignStep: React.FC<IActivateThirdPartyPaymentCampaignStep> = ({ campaignType }) => {
  const dispatch = useDispatch()
  const [redirectUrl, setRedirectUrl] = useState<string>()
  const [updateUsageCap] = useUpdateUsageCapMutation()
  const { campaigns } = useSelector((state: RootState) => state.campaigns)
  const builderInternetRetail = useSelector((state: RootState) => state.builderInternetRetail)
  const builderTvRetail = useSelector((state: RootState) => state.builderTvRetail)
  const builderBillboardRetail = useSelector((state: RootState) => state.builderBillboardRetail)
  const { currentAccount, currentOrganization } = useSelector((state: RootState) => state.app)
  const [showIncreaseCapModal, setShowIncreaseCapModal] = useState(false)
  const [showCreateRecurringChargeModal, setShowCreateRecurringChargeModal] = useState(false)
  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [contactName, setContactName] = useState<string>()
  const [contactPhone, setContactPhone] = useState<string>('')
  const [contactEmail, setContactEmail] = useState<string>('')
  const [selectedAdvertisingPackage, setSelectedAdvertisingPackage] = useState<IAdvertisingPackage>()
  const { search } = useLocation()
  const [activatingCampaign, setActivatingCampaign] = useState<boolean>(false)
  const [contactFormValid, setContactFormValid] = useState<boolean>(false)
  const [advertisingPackages, setAdvertisingPackages] = useState<IAdvertisingPackage[]>(() => {
    switch (campaignType) {
      case CampaignType.TV:
        return VideoPackages
      case CampaignType.Billboard:
        return BillboardPackages
      case CampaignType.Internet:
        return InternetPackages
    }
    return []
  })

  let currentBuilder: InternetBuilderState | TvBuilderState | BillboardBuilderState
  switch (campaignType) {
    case CampaignType.Internet:
      currentBuilder = builderInternetRetail
      break
    case CampaignType.TV:
      currentBuilder = builderTvRetail
      break
    case CampaignType.Billboard:
      currentBuilder = builderBillboardRetail
      break
  }

  const [campaignName, setCampaignName] = useState<string>(currentBuilder.name ?? '')
  const [budget, setBudget] = useState<number>(currentBuilder.budget ?? getMinPrice(campaignType))

  useEffect(() => {
    switch (campaignType) {
      case CampaignType.TV:
        setAdvertisingPackages(VideoPackages)
        break
      case CampaignType.Billboard:
        setAdvertisingPackages(BillboardPackages)
        break
      case CampaignType.Internet:
        setAdvertisingPackages(InternetPackages)
        break
      default:
        setAdvertisingPackages([])
        break
    }
  }, [campaignType])

  useEffect(() => {
    const query = new URLSearchParams(search)
    if (query.get('AutoCreate') && campaignName && budget > 0) {
      setActivatingCampaign(true)
      onActivateClick()
    }
  }, [search, campaignName, budget])

  const disableSubmit = !selectedAdvertisingPackage || !campaignName || (campaignType === CampaignType.TV && builderTvRetail.builderStyle !== 'Upload' && !contactFormValid)

  const onModifyChargeClick = () => {
    if (!redirectUrl) {
      return
    }
    SetNameBudgetAndSetupIntentId()
    setIsRedirecting(true)
    setShowIncreaseCapModal(false)
    setShowCreateRecurringChargeModal(false)
    switch (campaignType) {
      case CampaignType.TV:
        dispatch(setReturnUrlState(`/build/tv/public/activate?AutoCreate=true`))
        break
      case CampaignType.Billboard:
        dispatch(setReturnUrlState(`/build/billboards/public/activate?AutoCreate=true`))
        break
      case CampaignType.Internet:
        dispatch(setReturnUrlState(`/build/internet/public/activate?AutoCreate=true`))
        break
    }
    window.location.assign(redirectUrl)
  }

  const SetNameBudgetAndSetupIntentId = useCallback(() => {
    switch (campaignType) {
      case CampaignType.Internet:
        dispatch(setInternetNameBudgetAndSetupIntentId({ name: campaignName, budget: budget, totalBudget: budget }))
        break
      case CampaignType.TV:
        dispatch(setTVNameBudgetAndSetupIntentId({ name: campaignName, budget: budget, totalBudget: budget }))
        break
      case CampaignType.Billboard:
        dispatch(setBillboardNameBudgetAndSetupIntentId({ name: campaignName, budget: budget, totalBudget: budget }))
        break
    }
  }, [dispatch, campaignName, campaignType, budget])

  const ResetBuilder = useCallback(() => {
    switch (campaignType) {
      case CampaignType.Internet:
        dispatch(resetInternetBuilder())
        break
      case CampaignType.TV:
        dispatch(resetTvBuilder())
        break
      case CampaignType.Billboard:
        dispatch(resetBillboardBuilder())
        break
    }
  }, [dispatch, campaignType])

  useEffect(() => {
    if (currentAccount && currentOrganization && campaigns && !campaignName) {
      if (campaignType === CampaignType.TV) {
        const campaignsCount = campaigns.filter(c => c.type === 'TV').length
        setCampaignName(`Targeted TV Campaign ${campaignsCount + 1}`)
      } else if (campaignType === CampaignType.Internet) {
        if (builderInternetRetail.targetingType === TargetingType.Standard) {
          const campaignsCount = campaigns.filter(c => c.targetingTemplate === 'Standard').length
          setCampaignName(`Smart Targeter Campaign ${campaignsCount + 1}`)
        }
        if (builderInternetRetail.targetingType === TargetingType.Advanced) {
          switch (builderInternetRetail.advancedTargeting!.advancedTargetingType) {
            case AdvancedTargetingType.Location:
              const campaignsLocationCount = campaigns.filter(c => c.advancedTargeting?.advancedTargetingType === 'Location').length
              setCampaignName(`Location Based Campaign ${campaignsLocationCount + 1}`)
              break
            case AdvancedTargetingType.Audience:
              const campaignsAudienceCount = campaigns.filter(c => c.advancedTargeting?.advancedTargetingType === 'Audience').length
              setCampaignName(`Audience Based Campaign ${campaignsAudienceCount + 1}`)
              break
            case AdvancedTargetingType.Search:
              const campaignsSearchCount = campaigns.filter(c => c.advancedTargeting?.advancedTargetingType === 'Search').length
              setCampaignName(`Search Based Campaign ${campaignsSearchCount + 1}`)
              break
          }
        }
      } else if (campaignType === CampaignType.Billboard) {
        const campaignsCount = campaigns.filter(c => c.type === 'Billboard').length
        setCampaignName(`Billboard Campaign ${campaignsCount + 1}`)
      }
    }
  }, [campaigns, builderInternetRetail, campaignName, campaignType, currentAccount, currentOrganization])

  useEffect(() => {
    if (currentBuilder.result) {
      dispatch(
        bundlesApi.util.invalidateTags([
          {
            type: 'CampaignSlotsForAccount',
            id: `${currentOrganization?.id ?? ''}, ${campaignType}, ${currentAccount?.id ?? ''}`
          }
        ])
      )

      if (currentBuilder.result.campaignActiveStatus === CampaignActiveStatus.Inactive) {
        dispatch(showSuccessToast('Successfully saved your campaign'))
        history.push(`/campaigns/edit/${currentBuilder.result.campaignId}/details`)
      }
      if (currentBuilder.result.campaignActiveStatus === CampaignActiveStatus.Active) {
        dispatch(showSuccessToast('Successfully activated your campaign'))
        history.push(`/build/finished/${currentBuilder.result.campaignId}/${currentBuilder.result.campaignType}`)
      }
      ResetBuilder()
    }
  }, [dispatch, currentBuilder.result, campaignType, currentAccount, currentOrganization, ResetBuilder])

  const create = () => {
    if (currentAccount?.id) {
      setIsCreatingCampaign(true)
      dispatch(createQuickCampaign(campaignType, currentAccount.id, campaignName, budget, null, campaignType === CampaignType.TV && builderTvRetail.builderStyle !== 'Upload', contactName, contactPhone, contactEmail, true))
    }
  }

  const onBackClick = () => {
    switch (campaignType) {
      case CampaignType.TV:
        history.push('/build/tv/public/deliver')
        break
      case CampaignType.Billboard:
        history.push('/build/billboards/public/target')
        break
      case CampaignType.Internet:
        history.push('/build/internet/public/deliver')
        break
    }
  }

  const CreateNewShopifyRecurringCharge = (redirectUrl: string) => {
    setRedirectUrl(redirectUrl)
    setShowCreateRecurringChargeModal(true)
  }

  const IncreaseUsageCap = (newSpendCap: number) => {
    updateUsageCap({ newCapAmount: newSpendCap }).then(result => {
      if (!('error' in result)) {
        setRedirectUrl(result.data.redirectUrl)
        setShowIncreaseCapModal(true)
      }
    })
  }

  const onActivateClick = () => {
    setIsCreatingCampaign(true)
    if (!!currentAccount?.id) {
      apiPrepareNewPaymentProviderPaymentMethod(currentAccount!.id, budget).then(result => {
        if (result.data.needsConfirmation && result.data.redirectUrl.length > 0) {
          CreateNewShopifyRecurringCharge(result.data.redirectUrl)
        } else if (result.data.remainingBalance !== null && result.data.remainingBalance !== undefined && result.data.remainingBalance < budget) {
          IncreaseUsageCap((result.data.cappedAmount ?? 0) + (budget - result.data.remainingBalance))
        } else {
          create()
        }
      })
    }
  }

  const onPackageClick = (ap: IAdvertisingPackage) => {
    setBudget(ap.price)
    setSelectedAdvertisingPackage(ap)
  }

  return (
    <React.Fragment>
      {(!activatingCampaign && (
        <React.Fragment>
          <Helmet>
            <title>Submit Your Campaign</title>
          </Helmet>
          <EuiTitle size='s'>
            <h2>Submit Your Campaign</h2>
          </EuiTitle>
          <EuiSpacer />
          <EuiFormRow label='Campaign Name'>
            <EuiFieldText
              fullWidth
              value={campaignName}
              onChange={e => {
                setCampaignName(e.target.value)
              }}
              isInvalid={!campaignName}
            />
          </EuiFormRow>
          <EuiSpacer />
          <EuiTitle size='s'>
            <h2>Choose Your Monthly Budget</h2>
          </EuiTitle>
          <EuiText size={'s'}>
            <p>Cancel any time</p>
          </EuiText>
          <EuiSpacer />
          <EuiSpacer size='m' />
          <EuiFormRow isInvalid={false} error={false} fullWidth>
            <EuiFlexGroup gutterSize='l' justifyContent={'center'} wrap>
              {advertisingPackages.map(ap => (
                <EuiFlexItem grow={false} key={ap.name}>
                  <PaymentOption
                    categoryName={ap.categoryName}
                    isDisabled={isCreatingCampaign || isRedirecting}
                    name={ap.name}
                    valueOffered={ap.description}
                    frequencyDescription={ap.descriptionSubtext}
                    description={ap.description}
                    descriptionSubtext={ap.descriptionSubtext}
                    isSelected={ap === selectedAdvertisingPackage}
                    price={ap.priceDescription}
                    onSelected={() => {
                      onPackageClick(ap)
                    }}
                  />
                </EuiFlexItem>
              ))}
            </EuiFlexGroup>
          </EuiFormRow>
          <EuiSpacer />
          <EuiFormRow>
            <EuiText>
              <p>
                <b>*</b> or better :)
              </p>
            </EuiText>
          </EuiFormRow>
          <EuiSpacer />
          {campaignType === CampaignType.TV && (
            <React.Fragment>
              {builderTvRetail.builderStyle !== 'Upload' && (
                <React.Fragment>
                  <EuiCallOut title='Submitting Your Campaign' iconType='bell'>
                    <p>Upon submitting your campaign, AdCritter may contact you to finalize your customizations (outro, logo etc.).</p>
                    <p>Your card will not be charged until your commercial is finished and approved to run.</p>
                  </EuiCallOut>
                  <EuiSpacer />
                  <CustomizationsContactForm
                    header={'Customizations Contact'}
                    subtext={'Best contact to discuss your commercial.'}
                    name={contactName}
                    email={contactEmail}
                    phone={contactPhone}
                    onNameChange={value => {
                      setContactName(value)
                    }}
                    onPhoneChange={value => {
                      setContactPhone(value)
                    }}
                    onEmailChange={value => {
                      setContactEmail(value)
                    }}
                    onFormValidChange={valid => {
                      setContactFormValid(valid)
                    }}
                  />
                </React.Fragment>
              )}
              {builderTvRetail.builderStyle === 'Upload' && (
                <EuiCallOut title='Activating Your Campaign' iconType='bell'>
                  <p>Your commercial will be submitted for approval with all the major streaming TV exchanges. The approval process takes two to three business days to complete. Once approved, your commercial will begin to run automatically.</p>
                </EuiCallOut>
              )}
            </React.Fragment>
          )}
          <EuiSpacer />
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton id='back' fill color='text' iconType='arrowLeft' iconSide='left' onClick={onBackClick} disabled={isCreatingCampaign || isRedirecting}>
                Back
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton id='activate' fill iconType='check' isDisabled={disableSubmit} onClick={onActivateClick} isLoading={isCreatingCampaign} disabled={isCreatingCampaign || isRedirecting}>
                {campaignType === CampaignType.TV && builderTvRetail.builderStyle !== 'Upload' ? 'Submit To Ad Team' : 'Activate'}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </React.Fragment>
      )) || <FinalizingCampaign />}
      {showIncreaseCapModal && (
        <AcConfirmModal
          message={'To activate this campaign, click to approve the increase in your Shopify app spending limit.'}
          onCancel={() => {
            setIsCreatingCampaign(false)
            setShowIncreaseCapModal(false)
          }}
          title={'Activating This Campaign'}
          confirmButtonText={'Go to Shopify'}
          onConfirm={onModifyChargeClick}
        />
      )}
      {showCreateRecurringChargeModal && (
        <AcConfirmModal
          message={'To activate this campaign, you need to approve it in Shopify.'}
          onCancel={() => {
            setIsCreatingCampaign(false)
            setShowCreateRecurringChargeModal(false)
          }}
          maxWidth={400}
          title={'Activating This Campaign'}
          confirmButtonText={'Go to Shopify'}
          onConfirm={onModifyChargeClick}>
          <EuiModalBody style={{ paddingRight: '20px', paddingLeft: '20px' }}>
            <EuiText size={'s'}>
              <EuiFlexGroup gutterSize={'l'}>
                <EuiFlexItem>AdCritter Platform</EuiFlexItem>
                <EuiFlexItem>$0 / mo.</EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup gutterSize={'l'}>
                <EuiFlexItem>This campaign</EuiFlexItem>
                <EuiFlexItem>${budget} / mo.</EuiFlexItem>
              </EuiFlexGroup>
            </EuiText>
          </EuiModalBody>
        </AcConfirmModal>
      )}
    </React.Fragment>
  )
}
