import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Moment } from 'moment'

import { apiGetCampaigns, apiGetReportAccount, apiGetReportAd, apiGetReportCampaign, apiGetReportCampaignDimension } from 'api/adcritterApi'
import { Campaign, IntervalUnit, ReportAccount, ReportAd, ReportCampaign, ReportCampaignDimension } from 'api/interfaces'
import { AppThunk } from 'app/store'

interface ReportsState {
  isLoadingCampaigns: boolean
  campaigns: Campaign[]

  isLoadingReportAd: boolean
  reportAd: ReportAd[]
  isLoadingReportCampaign: boolean
  reportCampaign: ReportCampaign[]
  isLoadingReportCampaignDimension: boolean
  reportCampaignDimension: ReportCampaignDimension[]
  isLoadingReportAccount: boolean
  reportAccount: ReportAccount[]
}

const initialState: ReportsState = {
  isLoadingCampaigns: false,
  campaigns: [],
  isLoadingReportAd: false,
  reportAd: [],
  isLoadingReportCampaign: false,
  reportCampaign: [],
  isLoadingReportCampaignDimension: false,
  reportCampaignDimension: [],
  isLoadingReportAccount: false,
  reportAccount: []
}

const reports = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    startLoadingCampaigns(state: ReportsState) {
      state.isLoadingCampaigns = true
    },
    finishedLoadingCampaigns(state: ReportsState, { payload }: PayloadAction<Campaign[]>) {
      state.isLoadingCampaigns = false
      state.campaigns = payload
    },
    startLoadingReportAd(state: ReportsState) {
      state.isLoadingReportAd = true
    },
    finishedLoadingReportAd(state: ReportsState, { payload }: PayloadAction<ReportAd[]>) {
      state.isLoadingReportAd = false
      state.reportAd = payload
    },
    startLoadingReportCampaign(state: ReportsState) {
      state.isLoadingReportCampaign = true
    },
    finishedLoadingReportCampaign(state: ReportsState, { payload }: PayloadAction<ReportCampaign[]>) {
      state.isLoadingReportCampaign = false
      state.reportCampaign = payload
    },
    startLoadingReportCampaignDimension(state: ReportsState) {
      state.isLoadingReportCampaignDimension = true
    },
    finishedLoadingReportCampaignDimension(state: ReportsState, { payload }: PayloadAction<ReportCampaignDimension[]>) {
      state.isLoadingReportCampaignDimension = false
      state.reportCampaignDimension = payload
    },
    clearReportCampaignDimension(state: ReportsState) {
      state.isLoadingReportCampaignDimension = false
      state.reportCampaignDimension = []
    },
    startLoadingReportAccount(state: ReportsState) {
      state.isLoadingReportAccount = true
    },
    finishedLoadingReportAccount(state: ReportsState, { payload }: PayloadAction<ReportAccount[]>) {
      state.isLoadingReportAccount = false
      state.reportAccount = payload
    }
  }
})

export const { startLoadingCampaigns, finishedLoadingCampaigns, finishedLoadingReportAd, startLoadingReportAd, startLoadingReportAccount, startLoadingReportCampaign, finishedLoadingReportAccount, finishedLoadingReportCampaign, startLoadingReportCampaignDimension, finishedLoadingReportCampaignDimension, clearReportCampaignDimension } = reports.actions

export default reports.reducer

export const fetchCampaigns =
  (accountId: string): AppThunk =>
  async dispatch => {
    dispatch(startLoadingCampaigns())
    const response = await apiGetCampaigns(accountId)
    dispatch(finishedLoadingCampaigns(response.data))
  }

export const fetchReportAd =
  (accountId: string, adId: string[] | null, start: Moment, end: Moment): AppThunk =>
  async dispatch => {
    dispatch(startLoadingReportAd())
    const response = await apiGetReportAd(accountId, adId, start, end)
    dispatch(finishedLoadingReportAd(response.data))
  }

export const fetchReportCampaign =
  (accountId: string, campaignId: string[] | null, start: Moment, end: Moment, campaignBreakDownBy: IntervalUnit): AppThunk =>
  async dispatch => {
    dispatch(startLoadingReportCampaign())
    const response = await apiGetReportCampaign(accountId, campaignId, start, end, campaignBreakDownBy)
    dispatch(finishedLoadingReportCampaign(response.data))
  }

export const fetchReportCampaignDimension =
  (accountId: string, campaignId: string[] | null, dimension: string, breakDownBy: IntervalUnit, start: Moment, end: Moment): AppThunk =>
  async dispatch => {
    dispatch(startLoadingReportCampaignDimension())
    const response = await apiGetReportCampaignDimension(accountId, campaignId, dimension, breakDownBy, start, end)
    dispatch(finishedLoadingReportCampaignDimension(response.data))
  }
export const fetchReportAccount =
  (accountId: string, start: Moment, end: Moment): AppThunk =>
  async dispatch => {
    dispatch(startLoadingReportAccount())
    const response = await apiGetReportAccount(accountId, start, end)
    dispatch(finishedLoadingReportAccount(response.data))
  }
