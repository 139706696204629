import { Moment } from 'moment'
import moment from 'moment/moment'
import React, { useEffect } from 'react'

import { EuiButtonEmpty, EuiFieldSearch, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiInMemoryTable, EuiLoadingSpinner, EuiPanel, EuiSpacer, EuiStat, EuiSwitch, EuiText, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { LedgerRecord, LedgerRecordType, MonthLedger } from 'api/interfaces/InvoiceDocument'
import { useGetMonthlyLedgerQuery } from 'api/rtkQueryApi/platform/dashboardApi'
import { AcMonthPicker, GetMonthByNumber, GetMonthNumber, Month } from 'components/Basic/AcMonthPicker'
import { formatCurrency } from 'utils/Formatters'

export const MonthlyLedgerReport: React.FC = () => {
  const minInfusionDate = moment('2024/4/1')
  const [searchValue, setSearchValue] = React.useState<string>('')
  const [selectedMonth, setSelectedMonth] = React.useState<Month>(GetMonthByNumber(moment().add(-1, 'months').month()))
  const [selectedYear, setSelectedYear] = React.useState<number>(moment().add(-1, 'months').year())

  const monthlyLedgerQuery = useGetMonthlyLedgerQuery({ month: GetMonthNumber(selectedMonth) + 1, year: selectedYear })
  const [selectedLedger, setSelectedLedger] = React.useState<MonthLedger>()
  const [filteredLedgerRecords, setFilteredLedgerRecords] = React.useState<LedgerRecord[]>([])

  const [showCompleted, setShowCompleted] = React.useState<boolean>(true)
  const [showInvoices, setShowInvoices] = React.useState<boolean>(true)
  const [showActivated, setShowActivated] = React.useState<boolean>(true)

  useEffect(() => {
    if (monthlyLedgerQuery.data) {
      const thisMonthLedger = monthlyLedgerQuery.data
      if (thisMonthLedger) {
        setSelectedLedger(thisMonthLedger)
        let filteredRecords = thisMonthLedger.ledgerRecords.filter(r => r.name.toLowerCase().includes(searchValue.toLowerCase()))
        if (!showCompleted) {
          filteredRecords = filteredRecords.filter(r => r.ledgerRecordType !== LedgerRecordType.Completed)
        }
        if (!showActivated) {
          filteredRecords = filteredRecords.filter(r => r.ledgerRecordType !== LedgerRecordType.Activated)
        }
        if (!showInvoices) {
          filteredRecords = filteredRecords.filter(r => r.ledgerRecordType !== LedgerRecordType.Invoice)
        }
        setFilteredLedgerRecords(filteredRecords)
      } else {
        setSelectedLedger(undefined)
        setFilteredLedgerRecords([])
      }
    }
  }, [monthlyLedgerQuery.data, selectedMonth, selectedYear, searchValue, showCompleted, showActivated, showInvoices])

  const onMonthChange = (month: Month, year: number) => {
    setSelectedMonth(month)
    setSelectedYear(year)
  }

  const exportCsv = () => {
    let csvContent = 'data:text/csv;charset=utf-8,'
    csvContent += 'Name,Status,Record Date,Activated Budget,Final Spend,Overspend,Debit/Credit\n'
    filteredLedgerRecords.forEach(item => {
      const name = item.name.replace(/,/g, '').replace('#', '')
      csvContent += `${name},${item.ledgerRecordType},${item.recordDate ? moment(item.recordDate).utc().format('MM/DD/YYYY') : ''},${item.ledgerRecordType === LedgerRecordType.Completed ? item.activatedBudget : ''},${item.ledgerRecordType === LedgerRecordType.Completed ? item.finalSpend : ''},${item.ledgerRecordType === LedgerRecordType.Completed && item.finalSpend > item.activatedBudget ? (item.finalSpend - item.activatedBudget).toFixed(2) : ''},${
        (item.ledgerRecordType === LedgerRecordType.Activated ? '-' : '') + item.amount
      }\n`
    })
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'report.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const columns: Array<EuiBasicTableColumn<LedgerRecord>> = [
    {
      name: 'Name',
      sortable: (item: LedgerRecord) => item.name,
      render: (item: LedgerRecord) => <EuiText size={'s'}>{item.name}</EuiText>
    },
    {
      name: 'Status',
      sortable: (item: LedgerRecord) => item.ledgerRecordType,
      render: (item: LedgerRecord) => <EuiText size={'s'}>{item.ledgerRecordType}</EuiText>
    },
    {
      name: `Record Date`,
      field: 'recordDate',
      render: (recordDate: Moment) => (recordDate ? moment(recordDate).utc().format('MM/DD/YYYY') : ''),
      sortable: true
    },
    {
      name: `Activated Budget`,
      align: 'right',
      sortable: (item: LedgerRecord) => item.activatedBudget,
      render: (item: LedgerRecord) => (item.ledgerRecordType === LedgerRecordType.Completed ? <EuiText size={'s'}>{formatCurrency(item.activatedBudget)}</EuiText> : '')
    },
    {
      name: `Final Spend`,
      align: 'right',
      sortable: (item: LedgerRecord) => item.finalSpend,
      render: (item: LedgerRecord) => (item.ledgerRecordType === LedgerRecordType.Completed ? <EuiText size={'s'}>{formatCurrency(item.finalSpend)}</EuiText> : '')
    },
    {
      name: `Overspend`,
      align: 'right',
      sortable: (item: LedgerRecord) => item.finalSpend - item.activatedBudget,
      render: (item: LedgerRecord) => (item.ledgerRecordType === LedgerRecordType.Completed && item.finalSpend > item.activatedBudget ? <EuiText size={'s'}>{formatCurrency(item.finalSpend - item.activatedBudget)}</EuiText> : '')
    },
    {
      name: `Debit/Credit`,
      align: 'right',
      render: (item: LedgerRecord) => (
        <EuiText size={'s'} color={item.ledgerRecordType === LedgerRecordType.Activated ? 'danger' : 'success'}>
          {item.ledgerRecordType === LedgerRecordType.Activated ? '-' : '+'}
          {formatCurrency(item.amount)}
        </EuiText>
      ),
      sortable: (item: LedgerRecord) => item.amount
    }
  ]

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [25, 50, 100]
  }

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: true,
    sort: {
      field: 'recordDate' ?? 'activated',
      direction: SortDirection.ASC
    }
  }

  const endingBalance = selectedLedger ? selectedLedger.startingBalance + selectedLedger.invoiceSum + selectedLedger.underspend - selectedLedger.spend : 0

  return (
    <React.Fragment>
      {!monthlyLedgerQuery.isLoading && selectedLedger && (
        <EuiFlexGroup wrap gutterSize={'m'} responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiPanel hasShadow={false} hasBorder={true} style={{ width: 240 }}>
              <EuiStat title={formatCurrency(selectedLedger.startingBalance)} description={`Starting Balance`} titleSize={'m'} />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiPanel hasShadow={false} hasBorder={true} style={{ width: 240 }}>
              <EuiStat title={formatCurrency(selectedLedger.invoiceSum)} description={`Invoiced`} titleSize={'m'} />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiPanel hasShadow={false} hasBorder={true} style={{ width: 240 }}>
              <EuiStat title={formatCurrency(selectedLedger.spend)} description={`Final Spend`} titleSize={'m'} />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiPanel hasShadow={false} hasBorder={true} style={{ width: 240 }}>
              <EuiStat title={formatCurrency(selectedLedger.underspend)} description={`Underspend Credit`} titleSize={'m'} />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiPanel hasShadow={false} hasBorder={true} style={{ width: 240 }}>
              <EuiStat title={formatCurrency(endingBalance)} description={`Ending Balance`} titleSize={'m'} />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
      <EuiHorizontalRule />
      <EuiFlexGroup gutterSize={'m'} wrap responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiFieldSearch style={{ width: 240 }} placeholder={'Search'} value={searchValue} onChange={e => setSearchValue(e.target.value)} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <AcMonthPicker selectedMonth={selectedMonth} selectedYear={selectedYear} onChange={onMonthChange} style={{ paddingTop: 4 }} maxDate={moment().startOf('month').add(-1, 'days')} minDate={minInfusionDate} />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ paddingTop: 10 }}>
          <EuiSwitch label={'Show Activated'} checked={showActivated} onChange={() => setShowActivated(!showActivated)} />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ paddingTop: 10 }}>
          <EuiSwitch label={'Show Completed'} checked={showCompleted} onChange={() => setShowCompleted(!showCompleted)} />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ paddingTop: 10 }}>
          <EuiSwitch label={'Show Invoices'} checked={showInvoices} onChange={() => setShowInvoices(!showInvoices)} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <React.Fragment>
        {monthlyLedgerQuery.isLoading || monthlyLedgerQuery.isFetching ? (
          <EuiLoadingSpinner size='xl' />
        ) : (
          <React.Fragment>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty iconType={'download'} size={'s'} onClick={exportCsv}>
                  Export
                </EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiInMemoryTable className={'table-color'} items={filteredLedgerRecords} columns={columns} pagination={pagination} sorting={sorting} />
          </React.Fragment>
        )}
      </React.Fragment>
    </React.Fragment>
  )
}
