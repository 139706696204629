import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCheckbox, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiImage, EuiLoadingContent, EuiSelect, EuiSpacer, EuiSwitch, EuiText, EuiTitle, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'

import { ApiError, apiPutCampaignStandardTargeting } from 'api/adcritterApi'
import { StandardTargeting } from 'api/interfaces'
import { SmartTargeterAreaType } from 'api/interfaces/CampaignTargeting'
import { showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import Competitors from 'components/Competitors'
import WalkInAddresses from 'components/WalkInAddresses'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import { finishedLoadingCampaign } from '../campaignSlice'

const StandardDrawer: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const campaign = useSelector((state: RootState) => state.campaign)
  const [getAddress, setGetAddress] = useState(true)
  const [getStateOnly, setGetStateOnly] = useState(false)
  const [initialValues, setInitialValues] = useState<StandardTargeting>({
    competitors: [],
    competitorTargeting: false,
    targetArea: SmartTargeterAreaType.Mile5,
    streetAddress: '',
    city: '',
    state: 'AL',
    zip: '',
    walkInAddresses: [],
    walkInTargeting: false,
    walkInUsePrimary: true
  })
  const whiteLabel = useWhiteLabel()

  useEffect(() => {
    if (campaign?.campaign?.standardTargeting) {
      setInitialValues(campaign.campaign.standardTargeting)
      const targetArea = campaign.campaign.standardTargeting!.targetArea
      setGetAddress(targetArea === SmartTargeterAreaType.Mile5 || targetArea === SmartTargeterAreaType.Mile20 || targetArea === SmartTargeterAreaType.Mile50)
      setGetStateOnly(targetArea === SmartTargeterAreaType.State)
    }
  }, [campaign, campaign.campaign])

  const standardSchema = Yup.object().shape({
    targetArea: Yup.string().required('Please select the targeted area'),
    streetAddress: Yup.string()
      .nullable()
      .when('targetArea', {
        is: (targetArea: string) => targetArea === SmartTargeterAreaType.Mile5 || targetArea === SmartTargeterAreaType.Mile20 || targetArea === SmartTargeterAreaType.Mile50,
        then: Yup.string().required('Please Enter your Street Address')
      }),
    city: Yup.string()
      .nullable()
      .when('targetArea', {
        is: (targetArea: string) => targetArea === SmartTargeterAreaType.Mile5 || targetArea === SmartTargeterAreaType.Mile20 || targetArea === SmartTargeterAreaType.Mile50,
        then: Yup.string().required('Please Enter your City')
      }),
    state: Yup.string()
      .nullable()
      .when('targetArea', {
        is: (targetArea: string) => targetArea === SmartTargeterAreaType.Mile5 || targetArea === SmartTargeterAreaType.Mile20 || targetArea === SmartTargeterAreaType.Mile50,
        then: Yup.string().required('Please Enter your State')
      }),
    zip: Yup.string()
      .nullable()
      .when('targetArea', {
        is: (targetArea: string) => targetArea === SmartTargeterAreaType.Mile5 || targetArea === SmartTargeterAreaType.Mile20 || targetArea === SmartTargeterAreaType.Mile50,
        then: Yup.string().required('Please Enter your Zip')
      }),
    walkInTargeting: Yup.boolean().required(),
    walkInUsePrimary: Yup.boolean().required(),
    walkInAddresses: Yup.array().when(['walkInTargeting', 'walkInUsePrimary'], {
      is: (walkInTargeting: boolean, walkInUsePrimary: boolean) => walkInTargeting && !walkInUsePrimary,
      then: Yup.array()
        .min(1)
        .of(
          Yup.object().shape({
            streetAddress: Yup.string().required('Please enter a street address'),
            city: Yup.string().required('Please enter a city'),
            state: Yup.string().required('Please select a state'),
            zip: Yup.string().required('Please enter a walk-in zip')
          })
        )
    }),
    competitorTargeting: Yup.boolean().required(),
    competitors: Yup.array().when('competitorTargeting', {
      is: true,
      then: Yup.array()
        .min(1)
        .of(
          Yup.object().shape({
            name: Yup.string().max(64).required('Please enter a name'),
            streetAddress: Yup.string().max(120).required('Please enter a street address'),
            city: Yup.string().max(150).required('Please enter a city'),
            state: Yup.string().required('Please select a state'),
            zip: Yup.string().max(15).required('Please enter a zip')
          })
        ),
      otherwise: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().max(64),
          streetAddress: Yup.string().max(120),
          city: Yup.string().max(150),
          state: Yup.string(),
          zip: Yup.string().max(15)
        })
      )
    }),
    specialities: Yup.array().of(Yup.string())
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: standardSchema,
    onSubmit: (values: StandardTargeting) => {
      apiPutCampaignStandardTargeting(currentAccount!.id, campaign.campaign!.id, values)
        .then(response => {
          dispatch(showSuccessToast('Saved Smart Targeter Settings'))
          dispatch(finishedLoadingCampaign(response.data))
          formik.setSubmitting(false)
          formik.setStatus(null)
        })
        .catch(response => {
          formik.setSubmitting(false)
          response.errors.forEach(function (error: ApiError) {
            formik.setFieldError(error.name, error.message)
          })
        })
    }
  })

  const targetAreaOptions: Array<EuiSelectOption> = [
    {
      value: SmartTargeterAreaType.Mile5,
      text: 'up to 5 miles away from my address'
    },
    {
      value: SmartTargeterAreaType.Mile20,
      text: 'up to 20 miles away from my address'
    },
    {
      value: SmartTargeterAreaType.Mile50,
      text: 'up to 50 miles away from my address'
    },
    {
      value: SmartTargeterAreaType.State,
      text: `Statewide`
    },
    {
      value: SmartTargeterAreaType.Country,
      text: 'Entire USA'
    }
  ]

  const onTargetAreaChanged = (targetArea: string) => {
    formik.setFieldValue('targetArea', targetArea, true)
    setGetAddress(targetArea === SmartTargeterAreaType.Mile5 || targetArea === SmartTargeterAreaType.Mile20 || targetArea === SmartTargeterAreaType.Mile50)
    setGetStateOnly(targetArea === SmartTargeterAreaType.State)
  }

  let content

  if (campaign.isLoadingCampaign) {
    content = <EuiLoadingContent lines={3} />
  } else {
    content = (
      <FormikProvider value={formik}>
        <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
          <React.Fragment>
            {campaign.businessType && campaign.ads.length > 1 && (
              <React.Fragment>
                <EuiFlexGroup>
                  <EuiFlexItem grow={false}>
                    <EuiImage alt={campaign.businessType!.name} url={campaign.businessType!.imageUrl} style={{ width: 150 }} />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText size='s'>
                      <h3>{campaign.businessType!.name} Ad Targeting</h3>
                    </EuiText>
                    <EuiText size='s'>
                      <p>
                        {whiteLabel?.getName()} will automatically target your message to customers most likely to respond to ads for the business type: {campaign.businessType!.name}.
                        <br />
                        We do this using a combination of browsing history, search history, and past purchase behavior, as well as validated interest and demographic data.
                        <br />
                        We use machine learning to make adjustments based on the performance of all similar ad campaigns on the {whiteLabel?.getName()} platform, ensuring your targeting continually improves.
                      </p>
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer />
              </React.Fragment>
            )}

            <EuiText>
              <h4>How large of an area do you want to reach?</h4>
            </EuiText>
            <EuiSpacer size='s' />
            <EuiSelect name='targetArea' options={targetAreaOptions} value={formik.values.targetArea} onChange={e => onTargetAreaChanged(e.target.value)} fullWidth isInvalid={formik.touched.targetArea && !!formik.errors.targetArea} hasNoInitialSelection={true} />
            <EuiSpacer size='s' />
            <div hidden={!getAddress}>
              <EuiSpacer size='m' />
              <EuiFormRow label='Street Address' fullWidth isInvalid={formik.touched.streetAddress && !!formik.errors.streetAddress} error={formik.errors.streetAddress}>
                <EuiFieldText name='streetAddress' value={formik.values.streetAddress} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='' fullWidth isInvalid={formik.touched.streetAddress && !!formik.errors.streetAddress} />
              </EuiFormRow>
              <EuiSpacer size='s' />

              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow label='City' fullWidth isInvalid={formik.touched.city && !!formik.errors.city} error={formik.errors.city}>
                    <EuiFieldText name='city' value={formik.values.city} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='' fullWidth isInvalid={formik.touched.city && !!formik.errors.city} />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label='State' fullWidth isInvalid={formik.touched.state && !!formik.errors.state} error={formik.errors.state}>
                    <EuiSelect
                      name='state'
                      isLoading={dictionary.isLoadingStates}
                      options={dictionary.states.map(
                        s =>
                          ({
                            value: s.code,
                            label: s.name,
                            text: s.name
                          } as EuiSelectOption)
                      )}
                      value={formik.values.state}
                      onChange={value => formik.setFieldValue('state', value.target.value, true)}
                      onBlur={formik.handleBlur}
                      fullWidth
                      isInvalid={formik.touched.state && !!formik.errors.state}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label='Zip' fullWidth isInvalid={formik.touched.zip && !!formik.errors.zip} error={formik.errors.zip}>
                    <EuiFieldText name='zip' value={formik.values.zip} onChange={formik.handleChange} placeholder='' fullWidth isInvalid={formik.touched.zip && !!formik.errors.zip} />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
            <div hidden={!getStateOnly}>
              <EuiSpacer size='m' />
              <EuiFormRow label='State' fullWidth isInvalid={formik.touched.state && !!formik.errors.state} error={formik.errors.state}>
                <EuiSelect
                  name='state'
                  isLoading={dictionary.isLoadingStates}
                  options={dictionary.states.map(
                    s =>
                      ({
                        value: s.code,
                        label: s.name,
                        text: s.name
                      } as EuiSelectOption)
                  )}
                  value={formik.values.state}
                  onChange={value => formik.setFieldValue('state', value.target.value, true)}
                  onBlur={formik.handleBlur}
                  fullWidth
                  isInvalid={formik.touched.state && !!formik.errors.state}
                />
              </EuiFormRow>
            </div>
            <EuiSpacer size='xl' />
            <EuiTitle size='xs'>
              <h6>
                <EuiSwitch label='' checked={formik.values.walkInTargeting} onChange={e => formik.setFieldValue('walkInTargeting', e.target.checked, true)} />
                Walk-in customer targeting?
              </h6>
            </EuiTitle>
            <EuiSpacer size='s' />
            <div hidden={!formik.values.walkInTargeting} style={{ paddingLeft: 24 }}>
              <EuiSpacer size='s' />
              <EuiCheckbox id={htmlIdGenerator()()} label='Target your primary business address?' checked={formik.values.walkInUsePrimary} onChange={e => (formik.values.walkInUsePrimary = e.target.checked)} />
              <EuiSpacer size='s' />
              <WalkInAddresses formik={formik} />
            </div>
            <EuiSpacer size='xl' />
            <EuiTitle size='xs'>
              <h6>
                <EuiSwitch label='' checked={formik.values.competitorTargeting} onChange={e => formik.setFieldValue('competitorTargeting', e.target.checked, true)} />
                Competitor location targeting?
              </h6>
            </EuiTitle>
            <EuiSpacer size='s' />
            <div hidden={!formik.values.competitorTargeting} style={{ paddingLeft: 24 }}>
              <Competitors formik={formik} />
            </div>
          </React.Fragment>
          <EuiSpacer />
          <EuiButton id='save' fill type='submit' isLoading={formik.isSubmitting}>
            Save
          </EuiButton>
        </EuiForm>
      </FormikProvider>
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>{content}</EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: 260 }}>
          <EuiText size='xs'>
            <h3>Best Practices</h3>
            <h5>Making Changes</h5>
            <p>While you can always make changes to your campaign's targeting, it is also a good idea to allow the campaign to perform for several days between changes. Making changes too frequently can result in sporadic performance.</p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default StandardDrawer
