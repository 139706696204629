import React from 'react'
import { useSelector } from 'react-redux'

import { EuiListGroup, EuiListGroupItem } from '@elastic/eui'

import { RegionTarget } from 'api/interfaces'
import { RootState } from 'app/rootReducer'

interface NewRegionTargeterProps {
  regions: Array<RegionTarget>

  onRegionRemoved(regionId: number): void
}

export const NewRegionTargeter = ({ regions, onRegionRemoved }: NewRegionTargeterProps) => {
  const dictionary = useSelector((state: RootState) => state.dictionary)

  return (
    <React.Fragment>
      {!dictionary.isLoadingRegionTargets && regions && regions.length > 0 && (
        <EuiListGroup bordered maxWidth='100%' size='s'>
          {regions.map(r => (
            <EuiListGroupItem
              size='s'
              key={r.id}
              label={`${dictionary.regionTargets.filter(region => region.id === r.id)[0].name}`}
              extraAction={{
                onClick: () => {
                  onRegionRemoved(r.id)
                },
                iconType: 'cross',
                iconSize: 's',
                'aria-label': 'Remove',
                alwaysShow: true
              }}
            />
          ))}
        </EuiListGroup>
      )}
    </React.Fragment>
  )
}
