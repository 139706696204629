import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import React, { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { EuiFilePicker, EuiLoadingContent } from '@elastic/eui'

import { apiGetTvBuilderUpload } from 'api/adcritterApi'

export interface UploadedVideo {
  url: string
  fileName: string
  fileSize: number
}
export interface IAcVideoUploader {
  maxFileSize?: number
  allowedFileTypes?: string[]
  onUploadedUrlsChange: (urls: UploadedVideo[]) => void
  onIsValidFileChange: (isValidFile: boolean) => void
  allowMultiple?: boolean
  accountId: string
}

export const AcVideoUploader: React.FC<IAcVideoUploader> = props => {
  const filePickerRemoval = useRef<EuiFilePicker>(null)
  const [containerClient, setContainerClient] = useState<ContainerClient | null>(null)
  useEffect(() => {
    if (props.accountId) {
      apiGetTvBuilderUpload(props.accountId).then(data => {
        const storageClient = new BlobServiceClient(data.data)
        const containerClient = storageClient.getContainerClient(props.accountId)
        setContainerClient(containerClient)
      })
    }
  }, [props.accountId])

  const onFileChange = (files: FileList | null) => {
    if (!containerClient) {
      return
    }

    if (files && (props.allowMultiple || files.length === 1)) {
      if (props.allowedFileTypes) {
        if (!props.allowedFileTypes.includes(files[0].type)) {
          props.onIsValidFileChange(false)
          return
        }
      }

      const fileArray = Array.from(files)

      const promises: Promise<any>[] = []
      const uploadedFiles: UploadedVideo[] = []
      fileArray.forEach(file => {
        const blockBlobClient = containerClient.getBlockBlobClient(uuidv4() + '.mp4')
        const url = blockBlobClient!.url.split('?')[0]
        promises.push(
          new Promise(resolve => {
            blockBlobClient!.uploadData(file, { concurrency: 20 }).then(() => {
              uploadedFiles.push({
                url: url,
                fileName: file.name,
                fileSize: file.size
              })
              resolve('uploaded')
            })
          })
        )
      })

      Promise.allSettled(promises).then(() => {
        props.onUploadedUrlsChange(uploadedFiles)
        props.onIsValidFileChange(true)
      })
    }
  }

  if (!containerClient)
  {
    return <EuiLoadingContent />
  }

  return (
    <React.Fragment>
      <EuiFilePicker
        ref={filePickerRemoval}
        multiple={props.allowMultiple}
        fullWidth
        id='file'
        initialPromptText={'Select or drag and drop your Video' + (props.allowMultiple ? 's' : '')}
        onChange={files => {
          onFileChange(files)
        }}
        display='large'
        aria-label={'Select or drag and drop your file' + (props.allowMultiple ? 's' : '')}
      />
    </React.Fragment>
  )
}
