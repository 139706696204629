import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ReactPixel from 'react-facebook-pixel'

import { htmlIdGenerator } from '@elastic/eui'
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list'

import { apiGetAccount, apiGetOrganization } from 'api/adcritterApi'
import { Account, Organization, User } from 'api/interfaces'
import localStoreManagerService, {
  deleteAccessToken,
  deleteCurrentAccount,
  deleteCurrentOrganization,
  deleteCurrentUser,
  getAccessToken,
  getCurrentAccount,
  getCurrentOrganization,
  getCurrentUser,
  setAccessToken,
  setCurrentAccount,
  setCurrentOrganization,
  setCurrentUser,
  getIsInSignupProcess,
  setInSignupProcess,
  setVendastaNavBarUrl,
  getVendastaNavBarUrl,
  deleteVendastaNavBarUrl,
  getIsVendastaUser,
  getIsVendastaPartnerUser,
  VENDASTA_USER,
  VENDASTA_PARTNER_USER,
  getVendastaAccessToken,
  setVendastaAccessToken,
  deleteVendastaAccessToken,
  getIsShopifyUser,
  SHOPIFY_USER,
  getReturnUrl,
  deleteReturnUrl,
  setReturnUrl,
  getShowWelcome,
  setShowWelcome,
  STAFF_USER,
  getIsStaffUser,
  getAgencySpecialMatchingOffer,
  setAgencySpecialMatchingOffer
} from 'services/LocalStoreManagerService'

import config from './config'
import { AppThunk } from './store'

export type AppState = {
  isLoggedIn: boolean
  accessToken: string | null
  currentUser: User | null
  currentOrganization: Organization | null
  currentAccount: Account | null
  accounts: Account[]
  toasts: Toast[]
  isGoogleLoaded: boolean
  isUnpaidSubscription: boolean
  isInSignupProcess: boolean
  onlyUsesPlans: boolean
  isStaffUser: boolean
  isShopifyUser: boolean
  isVendastaUser: boolean
  isVendastaPartnerUser: boolean
  vendastaNavBarUrl?: string
  vendastaAccessToken?: string
  returnUrl?: string
  isNewlyCreated: boolean
  showWelcome: boolean
  accountsFlyoutOpen: boolean
  selfServePackagesFlyoutOpen: boolean
  addAccountFlyoutOpen: boolean
  agencySpecialMatchingOffer: boolean
}

const org = getCurrentOrganization()

const initialState: AppState = {
  isLoggedIn: !!getAccessToken(),
  accessToken: getAccessToken(),
  currentUser: getCurrentUser(),
  currentAccount: getCurrentAccount(),
  currentOrganization: org,
  accounts: [],
  toasts: [],
  isGoogleLoaded: false,
  isUnpaidSubscription: false,
  isInSignupProcess: getIsInSignupProcess() ?? false,
  onlyUsesPlans: getIsVendastaUser(),
  isStaffUser: getIsStaffUser(),
  isVendastaUser: getIsVendastaUser(),
  isVendastaPartnerUser: getIsVendastaPartnerUser(),
  isShopifyUser: getIsShopifyUser(),
  vendastaNavBarUrl: getVendastaNavBarUrl(),
  vendastaAccessToken: getVendastaAccessToken(),
  returnUrl: getReturnUrl(),
  isNewlyCreated: false,
  showWelcome: getShowWelcome(),
  accountsFlyoutOpen: false,
  selfServePackagesFlyoutOpen: false,
  addAccountFlyoutOpen: false,
  agencySpecialMatchingOffer: getAgencySpecialMatchingOffer()
}

type UpdateStateAction = PayloadAction<{
  isLoggedIn: boolean
  accessToken: string
  user: User
  organization: Organization
  account: Account
  isInSignupProcess: boolean
  vendastaNavBarUrl?: string
  vendastaAccessToken?: string
  isNewlyCreated?: boolean
}>

type UpdateOrganizationAndAccountStateAction = PayloadAction<{
  organization: Organization
  account: Account
}>

const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    updateApp(state: AppState, { payload }: UpdateStateAction) {
      setInSignupProcess(payload.isInSignupProcess)
      state.accessToken = payload.accessToken
      state.currentUser = payload.user
      state.currentOrganization = payload.organization
      state.currentAccount = payload.account
      state.isLoggedIn = payload.isLoggedIn
      state.isInSignupProcess = payload.isInSignupProcess
      state.vendastaNavBarUrl = payload.vendastaNavBarUrl
      state.vendastaAccessToken = payload.vendastaAccessToken
      state.isStaffUser = payload.user.roles?.includes(STAFF_USER) ?? false
      state.isVendastaUser = payload.user.roles?.includes(VENDASTA_USER) ?? false
      state.isVendastaPartnerUser = payload.user.roles?.includes(VENDASTA_PARTNER_USER) ?? false
      state.onlyUsesPlans = state.isVendastaUser
      state.isShopifyUser = payload.user.roles?.includes(SHOPIFY_USER) ?? false
      state.isNewlyCreated = payload.isNewlyCreated ?? false
    },
    updateOrganizationAndAccount(state: AppState, { payload }: UpdateOrganizationAndAccountStateAction) {
      state.currentOrganization = payload.organization
      state.currentAccount = payload.account
      state.isUnpaidSubscription = payload.organization.hasUnpaidSubscription

      state.isVendastaUser = !!payload.account?.strawberryFieldAccountId && !!state.currentUser?.roles?.includes(STAFF_USER)
      state.isVendastaPartnerUser = !!payload.account?.strawberryFieldAccountId && !!state.currentUser?.roles?.includes(STAFF_USER)
      state.isShopifyUser = payload.organization?.signupMethod === 'Shopify' && !!state.currentUser?.roles?.includes(STAFF_USER)
    },
    updateOrganization(state: AppState, { payload }: PayloadAction<Organization>) {
      setCurrentOrganization(payload)
      state.currentOrganization = payload
      state.isUnpaidSubscription = payload.hasUnpaidSubscription
    },
    updateAccount(state: AppState, { payload }: PayloadAction<Account>) {
      setCurrentAccount(payload)
      state.currentAccount = payload
    },
    updateAccounts(state: AppState, { payload }: PayloadAction<Account[]>) {
      state.accounts = payload
    },
    updateShowWelcome(state: AppState, { payload }: PayloadAction<boolean>) {
      setShowWelcome(payload)
      state.showWelcome = payload
    },
    logoutAccount(state: AppState) {
      state.isLoggedIn = false
      state.isUnpaidSubscription = false
      state.currentUser = null
      state.currentOrganization = null
      state.currentAccount = null
      state.accounts = []
    },
    showSuccessToast(state: AppState, { payload }: PayloadAction<string>) {
      state.toasts = [
        ...state.toasts,
        {
          id: htmlIdGenerator()(),
          title: 'Success',
          color: 'success',
          text: payload
        }
      ]
    },
    showWarningToast(state: AppState, { payload }: PayloadAction<string>) {
      state.toasts = [
        ...state.toasts,
        {
          id: htmlIdGenerator()(),
          title: 'Warning',
          color: 'warning',
          text: payload
        }
      ]
    },
    showErrorToast(state: AppState, { payload }: PayloadAction<string>) {
      state.toasts = [...state.toasts, { id: htmlIdGenerator()(), title: 'Error', color: 'danger', text: payload }]
    },
    removeToast(state: AppState, { payload }: PayloadAction<Toast>) {
      state.toasts = state.toasts.filter(toast => toast.id !== payload.id)
    },
    setGoogleLoaded(state: AppState) {
      state.isGoogleLoaded = true
    },
    setUnpaidSubscription(state: AppState) {
      state.isUnpaidSubscription = true
    },
    setPaidSubscription(state: AppState) {
      state.isUnpaidSubscription = false
    },
    setIsInSignupProcess(state: AppState, { payload }: PayloadAction<boolean>) {
      setInSignupProcess(payload)
      state.isInSignupProcess = payload
    },
    removeReturnUrl(state: AppState) {
      state.returnUrl = undefined
      deleteReturnUrl()
    },
    setReturnUrlAddress(state: AppState, { payload }: PayloadAction<string>) {
      setReturnUrl(payload)
    },
    setIsNewlyCreated(state: AppState, { payload }: PayloadAction<boolean>) {
      state.isNewlyCreated = payload
    },
    agencySpecialMatchingOffer(state: AppState) {
      state.accountsFlyoutOpen = true
      state.addAccountFlyoutOpen = true

      state.agencySpecialMatchingOffer = true
      setAgencySpecialMatchingOffer(state.agencySpecialMatchingOffer)
    },
    setSelfServePackagesFlyoutOpen(state: AppState, { payload }: PayloadAction<boolean>) {
      state.selfServePackagesFlyoutOpen = payload
    },
    showAccountsFlyout(state: AppState) {
      state.accountsFlyoutOpen = true
    },
    hideAccountsFlyout(state: AppState) {
      state.accountsFlyoutOpen = false

      state.agencySpecialMatchingOffer = false
      setAgencySpecialMatchingOffer(state.agencySpecialMatchingOffer)
    },
    showAddAccountFlyout(state: AppState) {
      state.addAccountFlyoutOpen = true
    },
    hideAddAccountFlyout(state: AppState) {
      state.addAccountFlyoutOpen = false
    }
  }
})

export const {
  updateApp,
  updateOrganizationAndAccount,
  updateOrganization,
  updateAccount,
  updateAccounts,
  showSuccessToast,
  showWarningToast,
  showErrorToast,
  removeToast,
  logoutAccount,
  setGoogleLoaded,
  setUnpaidSubscription,
  setPaidSubscription,
  setIsInSignupProcess,
  removeReturnUrl,
  setReturnUrlAddress,
  setIsNewlyCreated,
  updateShowWelcome,
  agencySpecialMatchingOffer,
  showAccountsFlyout,
  hideAccountsFlyout,
  showAddAccountFlyout,
  hideAddAccountFlyout,
  setSelfServePackagesFlyoutOpen
} = appSlice.actions

export default appSlice.reducer

export const DisplaySuccessToast =
  (message: string): AppThunk =>
  async dispatch => {
    dispatch(showSuccessToast(message))
  }

export const DisplayErrorToast =
  (message: string): AppThunk =>
  async dispatch => {
    dispatch(showErrorToast(message))
  }

export const refreshOrganizationAndAccount =
  (accountId: string): AppThunk =>
  async dispatch => {
    apiGetOrganization().then(result => {
      dispatch(updateOrganization(result.data))
      if (result.data.hasUnpaidSubscription) dispatch(setUnpaidSubscription())
    })
    apiGetAccount(accountId).then(result => dispatch(updateAccountState(result.data)))
  }

export const updateAppState =
  (updatedApp: { isLoggedIn: boolean; accessToken: string; user: User; organization: Organization; account: Account; isInSignupProcess: boolean; vendastaNavBarUrl?: string; vendastaAccessToken?: string; isNewlyCreated?: boolean }): AppThunk =>
  async dispatch => {
    setAccessToken(updatedApp.accessToken)
    setCurrentUser(updatedApp.user)
    setCurrentOrganization(updatedApp.organization)
    setCurrentAccount(updatedApp.account)
    setVendastaNavBarUrl(updatedApp.vendastaNavBarUrl)
    setVendastaAccessToken(updatedApp.vendastaAccessToken)
    dispatch(updateApp(updatedApp))
  }

export const updateOrganizationAndAccountState =
  (updateOrganizationAndAccountStateAction: { organization: Organization; account: Account }): AppThunk =>
  async dispatch => {
    setCurrentOrganization(updateOrganizationAndAccountStateAction.organization)
    setCurrentAccount(updateOrganizationAndAccountStateAction.account)
    dispatch(updateOrganizationAndAccount(updateOrganizationAndAccountStateAction))
  }

export const updateOrganizationState =
  (organization: Organization): AppThunk =>
  async dispatch => {
    dispatch(updateOrganization(organization))
  }

export const updateAccountState =
  (account: Account): AppThunk =>
  async dispatch => {
    dispatch(updateAccount(account))
  }

export const updateAccountsState =
  (accounts: Account[]): AppThunk =>
  async dispatch => {
    dispatch(updateAccounts(accounts))
  }

export const removeReturnUrlState = (): AppThunk => async dispatch => {
  dispatch(removeReturnUrl())
}

export const setReturnUrlState =
  (url: string): AppThunk =>
  async dispatch => {
    dispatch(setReturnUrlAddress(url))
  }

export const setIsNewlyCreatedState =
  (isNewlyCreated: boolean): AppThunk =>
  async dispatch => {
    dispatch(setIsNewlyCreated(isNewlyCreated))
  }

export const logout = (): AppThunk => async dispatch => {
  dispatch(logoutAccount())

  deleteAccessToken()
  deleteCurrentUser()
  deleteCurrentOrganization()
  deleteCurrentAccount()
  deleteVendastaNavBarUrl()
  deleteVendastaAccessToken()
  localStoreManagerService.clearSessionStorage()
  ReactPixel.fbq('init', config.facebook.PIXEL_ID)
}
