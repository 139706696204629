import React, { useState } from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiImage, EuiPanel, EuiText, EuiTitle } from '@elastic/eui'

import { BusinessType } from 'api/interfaces'

export interface ICategoryImageOption {
  title?: string
  description?: string
  businessType: BusinessType
  onClick?: () => void
  imageSrc?: string
  key?: string | number
  isSelected: boolean
}

// export const CategoryImageOption: React.FC<ICategoryImageOption> = props => {
//   return <EuiCard style={{ width: 300, marginLeft: 'auto', marginRight: 'auto' }} textAlign={'left'} image={<img style={{ height: 200 }} src={props.imageSrc || ''} alt='' />} title={props.title ?? ''} description={props.description ?? ''} key={props.key} />
// }

export const BusinessTypePanelOption: React.FC<ICategoryImageOption> = props => {
  const [isMouseOver, setIsMouseOver] = useState(false)

  const onCategoryClick = () => {
    if (props.onClick) {
      props.onClick()
    }
  }

  const onMouseOver = () => {
    setIsMouseOver(true)
  }

  const onMouseLeave = () => {
    setIsMouseOver(false)
  }

  const panelColor = (() => {
    if (props.isSelected) return isMouseOver ? 'success' : 'success'
    else {
      return isMouseOver ? 'primary' : undefined
    }
  })()

  return (
    <EuiPanel style={{ width: 300, marginLeft: 'auto', marginRight: 'auto', paddingTop: 12, paddingBottom: 12 }} color={panelColor} hasShadow={false} hasBorder={true}>
      <a role={'button'} onClick={onCategoryClick} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
        <EuiFlexGroup justifyContent={'spaceAround'}>
          <EuiFlexItem grow={false}>
            <EuiImage size={80} hasShadow alt={props.description ?? 'businessType'} src={props.imageSrc ?? ''} />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiTitle size={'xs'}>
              <h2>{props.title}</h2>
            </EuiTitle>
            <EuiText color={'default'} size={'s'}>
              <p>{props.description}</p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </a>
    </EuiPanel>
  )
}
