import React from 'react'

import { EuiFormRow, EuiSpacer } from '@elastic/eui'

import { AgeRangeTargeter } from 'components/targeters/AgeRangeTargeter'
import { GenderTargeter } from 'components/targeters/GenderTargeter'
import { HouseholdIncomeTargeter } from 'components/targeters/HouseholdIncomeTargeter'

export interface IDemographicsTargeter {
  ageRanges: string[]
  genders: string[]
  householdIncomes: string[]
  onAgeRangesChanged?: (ageRanges: string[]) => void
  onGendersChanged?: (genders: string[]) => void
  onHouseholdIncomesChanged?: (householdIncomes: string[]) => void
}

export const DemographicsTargeter: React.FC<IDemographicsTargeter> = props => {
  const onAgeRangesChanged = (ageRanges: string[]) => {
    props.onAgeRangesChanged?.(ageRanges)
  }

  const onGendersChanged = (genders: string[]) => {
    props.onGendersChanged?.(genders)
  }

  const onHouseholdIncomesChanged = (householdIncomes: string[]) => {
    props.onHouseholdIncomesChanged?.(householdIncomes)
  }

  return (
    <React.Fragment>
      <EuiFormRow label='Age ranges' fullWidth>
        <AgeRangeTargeter ageRanges={props.ageRanges} setAgeRanges={onAgeRangesChanged} />
      </EuiFormRow>
      <EuiSpacer size='s' />
      <EuiFormRow label='Gender' fullWidth>
        <GenderTargeter genders={props.genders} setGenders={onGendersChanged} />
      </EuiFormRow>
      <EuiSpacer size='s' />
      <EuiFormRow label='Household income' fullWidth>
        <HouseholdIncomeTargeter householdIncomes={props.householdIncomes} setHouseholdIncomes={onHouseholdIncomesChanged} />
      </EuiFormRow>
    </React.Fragment>
  )
}
