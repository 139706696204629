import React from 'react'

import { EuiConfirmModal, EuiOverlayMask, EuiText } from '@elastic/eui'

import history from 'services/HistoryService'

export interface IEmailConfirmation {
  onCancel: (event?: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>) => void
}

export const EmailConfirmation: React.FC<IEmailConfirmation> = props => {
  return (
    <EuiOverlayMask>
      <EuiConfirmModal
        id='forgotPasswordModal'
        title={'Your password recovery email has been sent'}
        onCancel={props.onCancel}
        confirmButtonText='Back to Login'
        defaultFocusedButton='confirm'
        buttonColor='primary'
        onConfirm={() => {
          history.push('/login')
        }}>
        <EuiText>If there is an account with the email address provided, you will receive a message with a link to reset your password. Please allow up to ten minutes, and check your junk and spam folders if you do not see the email in your inbox.</EuiText>
      </EuiConfirmModal>
    </EuiOverlayMask>
  )
}

export default EmailConfirmation
