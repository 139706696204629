import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Moment } from 'moment'

import { apiPostInternetRetailAdvancedBuilder, apiPostInternetRetailStandardBuilder } from 'api/adcritterApi'
import { AcImage, Ad, AdvancedBuilder, AdvancedTargeting, BuilderResult, BusinessType, DisplayAdDetails, StandardBuilder, StandardTargeting } from 'api/interfaces'
import { dashboardApi } from 'api/rtkQueryApi/platform/dashboardApi'
import { RootState } from 'app/rootReducer'
import { AppThunk } from 'app/store'
import { TargetingType } from 'definitions/BuilderDefinitions'
import { createBillboardPlanCampaign, createBillboardQuickCampaign } from 'features/builders/billboards/retail/builderBillboardRetailSlice'
import { createTvPlanCampaign, createTvQuickCampaign } from 'features/builders/tv/retail/builderTvRetailSlice'
import { fetchCampaigns } from 'features/campaigns/campaigns/campaignsSlice'
import history from 'services/HistoryService'
import localStoreManagerService from 'services/LocalStoreManagerService'
import { CampaignType } from 'utils/CampaignType'

export enum BuilderStep {
  Start = 10,
  Suggest = 12,
  Questionnaire = 13,
  Suggestions = 14,
  Create = 30,
  Upload = 31,
  Custom = 33,
  TargetChoose = 40,
  TargetStandard = 41,
  TargetAdvancedLocation = 42,
  TargetAdvancedAudience = 43,
  TargetAdvancedSearch = 44,
  TargetAdvancedRetarget = 45,
  Deliver = 50,
  Activate = 99
}

export enum BuilderStyle {
  Suggested = 'Suggested',
  Build = 'Build',
  Upload = 'Upload',
  Custom = 'Custom'
}

export interface Suggest {
  cityName: string
  companyName: string
}

export enum AdvancedTargetingType {
  Location = 'Location',
  Audience = 'Audience',
  Search = 'Search',
  Retarget = 'Retarget'
}

export enum AdPlacementType {
  Everywhere = 'Everywhere',
  Categories = 'Categories',
  Specific = 'Specific'
}

export enum AdPaymentType {
  Standard = 'Standard',
  Bundle = 'Bundle'
}

export interface Deliver {
  placement: AdPlacementType | null
  categories: Array<string>
  specific: Array<string>
}

export interface InternetBuilderState {
  builderStep: BuilderStep
  builderStyle: BuilderStyle
  suggest: Suggest | null
  businessType: BusinessType | null
  ad: Ad | null
  displayAd: DisplayAdDetails | null
  image: AcImage | null
  targetingType: TargetingType
  standardTargeting: StandardTargeting | null
  advancedTargeting: AdvancedTargeting | null
  deliver: Deliver | null
  isCreating: boolean
  result: BuilderResult | null
  name: string | null
  budget: number | null
  totalBudget: number | null
}

const initialState: InternetBuilderState = {
  builderStep: localStoreManagerService.getData('builderInternetRetail.builderStep') || BuilderStep.Start,
  builderStyle: localStoreManagerService.getData('builderInternetRetail.builderStyle') || BuilderStyle.Build,
  suggest: localStoreManagerService.getData('builderInternetRetail.suggest'),
  businessType: localStoreManagerService.getData('builderInternetRetail.businessType'),
  ad: localStoreManagerService.getData('builderInternetRetail.ad'),
  displayAd: localStoreManagerService.getData('builderInternetRetail.displayAd'),
  image: localStoreManagerService.getData('builderInternetRetail.image'),
  targetingType: localStoreManagerService.getData('builderInternetRetail.targetingType') || TargetingType.Standard,
  standardTargeting: localStoreManagerService.getData('builderInternetRetail.standardTargeting'),
  advancedTargeting: localStoreManagerService.getData('builderInternetRetail.advancedTargeting'),
  deliver: localStoreManagerService.getData('builderInternetRetail.deliver'),
  isCreating: false,
  result: null,
  name: localStoreManagerService.getData('builderInternetRetail.name'),
  budget: localStoreManagerService.getData('builderInternetRetail.budget'),
  totalBudget: localStoreManagerService.getData('builderInternetRetail.totalBudget')
}

function reset(state: InternetBuilderState) {
  state.builderStep = BuilderStep.Start
  state.builderStyle = BuilderStyle.Build
  state.suggest = null
  state.businessType = null
  state.ad = null
  state.displayAd = null
  state.image = null
  state.targetingType = TargetingType.Standard
  state.standardTargeting = null
  state.advancedTargeting = null
  state.deliver = null
  state.isCreating = false
  state.result = null
  state.name = null
  state.budget = null
  state.totalBudget = null

  localStoreManagerService.deleteData('builderInternetRetail.builderStep')
  localStoreManagerService.deleteData('builderInternetRetail.builderStyle')
  localStoreManagerService.deleteData('builderInternetRetail.suggest')
  localStoreManagerService.deleteData('builderInternetRetail.businessType')
  localStoreManagerService.deleteData('builderInternetRetail.ad')
  localStoreManagerService.deleteData('builderInternetRetail.displayAd')
  localStoreManagerService.deleteData('builderInternetRetail.image')
  localStoreManagerService.deleteData('builderInternetRetail.targetingType')
  localStoreManagerService.deleteData('builderInternetRetail.advancedTargetingType')
  localStoreManagerService.deleteData('builderInternetRetail.standardTargeting')
  localStoreManagerService.deleteData('builderInternetRetail.advancedTargeting')
  localStoreManagerService.deleteData('builderInternetRetail.deliver')
  localStoreManagerService.deleteData('builderInternetRetail.setupIntentId')
  localStoreManagerService.deleteData('builderInternetRetail.name')
  localStoreManagerService.deleteData('builderInternetRetail.budget')
  localStoreManagerService.deleteData('builderInternetRetail.totalBudget')
}

const builderInternetGeneral = createSlice({
  name: 'builderInternetGeneral',
  initialState,
  reducers: {
    startBuilder(state: InternetBuilderState) {
      reset(state)
      history.push('/build/internet/public/start')
    },
    resetBuilder(state: InternetBuilderState) {
      reset(state)
    },
    setBuilderStep(state: InternetBuilderState, { payload }: PayloadAction<BuilderStep>) {
      state.builderStep = payload
      localStoreManagerService.saveSessionData('builderInternetRetail.builderStep', payload)
    },
    setBuilderStyle(state: InternetBuilderState, { payload }: PayloadAction<BuilderStyle>) {
      state.builderStyle = payload
      localStoreManagerService.saveSessionData('builderInternetRetail.builderStyle', payload)
    },
    setSuggest(state: InternetBuilderState, { payload }: PayloadAction<Suggest>) {
      state.suggest = payload
      localStoreManagerService.saveSessionData('builderInternetRetail.suggest', payload)
    },
    setBusinessType(state: InternetBuilderState, { payload }: PayloadAction<BusinessType>) {
      state.businessType = payload
      localStoreManagerService.saveSessionData('builderInternetRetail.businessType', payload)
    },
    setAd(state: InternetBuilderState, { payload }: PayloadAction<Ad>) {
      state.ad = payload
      localStoreManagerService.saveSessionData('builderInternetRetail.ad', payload)
    },
    setDisplayAd(state: InternetBuilderState, { payload }: PayloadAction<DisplayAdDetails | null>) {
      state.displayAd = payload
      localStoreManagerService.saveSessionData('builderInternetRetail.displayAd', payload)
    },
    setImage(state: InternetBuilderState, { payload }: PayloadAction<AcImage>) {
      state.image = payload
      localStoreManagerService.saveSessionData('builderInternetRetail.image', payload)
    },
    setTargetingType(state: InternetBuilderState, { payload }: PayloadAction<TargetingType>) {
      state.targetingType = payload
      localStoreManagerService.saveSessionData('builderInternetRetail.targetingType', state.targetingType)
    },
    setStandardTargeting(state: InternetBuilderState, { payload }: PayloadAction<StandardTargeting>) {
      state.standardTargeting = payload
      localStoreManagerService.saveSessionData('builderInternetRetail.standardTargeting', payload)
    },
    setAdvancedTargeting(state: InternetBuilderState, { payload }: PayloadAction<AdvancedTargeting>) {
      state.advancedTargeting = payload
      localStoreManagerService.saveSessionData('builderInternetRetail.advancedTargeting', payload)
    },
    setDeliver(state: InternetBuilderState, { payload }: PayloadAction<Deliver>) {
      state.deliver = payload
      localStoreManagerService.saveSessionData('builderInternetRetail.deliver', payload)
    },
    startCreating(state: InternetBuilderState) {
      state.isCreating = true
    },
    setNameAndBudget(state: InternetBuilderState, { payload }: PayloadAction<{ name: string; budget: number; totalBudget: number }>) {
      state.name = payload.name
      state.budget = payload.budget
      state.totalBudget = payload.totalBudget
      localStoreManagerService.saveSessionData('builderInternetRetail.name', payload.name)
      localStoreManagerService.saveSessionData('builderInternetRetail.budget', payload.budget)
      localStoreManagerService.saveSessionData('builderInternetRetail.totalBudget', payload.totalBudget)
    },
    finishedCreating(state: InternetBuilderState, { payload }: PayloadAction<BuilderResult>) {
      state.result = payload
      state.isCreating = false
    }
  }
})

export const { startBuilder, resetBuilder, setBuilderStep, setBuilderStyle, setBusinessType, setSuggest, setAdvancedTargeting, setAd, setDisplayAd, setStandardTargeting, setImage, setTargetingType, setDeliver, startCreating, setNameAndBudget, finishedCreating } = builderInternetGeneral.actions

export default builderInternetGeneral.reducer

const getInternetRetailBuilder = (state: RootState) => state.builderInternetRetail

export const createBundleCampaign = (campaignType: CampaignType, accountId: string, campaignName: string, campaignSlotId: string | null, saveForLater: boolean, name?: string, phone?: string, email?: string): AppThunk => {
  if (campaignType === CampaignType.Internet) {
    return createInternetPlanCampaign(accountId, campaignName, campaignSlotId, saveForLater)
  } else if (campaignType === CampaignType.TV) {
    return createTvPlanCampaign(accountId, campaignName, campaignSlotId, saveForLater, name, phone, email)
  } else if (campaignType === CampaignType.Billboard) {
    return createBillboardPlanCampaign(accountId, campaignName, campaignSlotId, saveForLater)
  } else {
    return createInternetPlanCampaign(accountId, campaignName, campaignSlotId, saveForLater)
  }
}

export const createInternetPlanCampaign = (accountId: string, campaignName: string, campaignSlotId: string | null, saveForLater: boolean): AppThunk => {
  return createCampaign(accountId, campaignName, 0, 0, false, null, saveForLater, '', '', '', null, null, campaignSlotId)
}

export const createQuickCampaign = (campaignType: CampaignType, accountId: string, campaignName: string, budget: number, campaignSlotId: string | null, saveForLater: boolean, name?: string, phone?: string, email?: string, isDays30?: boolean): AppThunk => {
  if (campaignType === CampaignType.Internet) {
    return createInternetQuickCampaign(accountId, campaignName, campaignSlotId, saveForLater, budget, isDays30)
  } else if (campaignType === CampaignType.TV) {
    return createTvQuickCampaign(accountId, campaignName, campaignSlotId, saveForLater, budget, name, phone, email, isDays30)
  } else if (campaignType === CampaignType.Billboard) {
    return createBillboardQuickCampaign(accountId, campaignName, campaignSlotId, saveForLater, budget, isDays30)
  } else {
    return createInternetQuickCampaign(accountId, campaignName, campaignSlotId, saveForLater, budget, isDays30)
  }
}

export const createInternetQuickCampaign = (accountId: string, campaignName: string, campaignSlotId: string | null, saveForLater: boolean, budget: number, isDays30?: boolean): AppThunk => {
  return createCampaign(accountId, campaignName, budget, 0, false, null, saveForLater, '', '', '', null, null, campaignSlotId, isDays30)
}

export const createCampaign =
  (accountId: string, campaignName: string, budget: number, maxCpm: number | null, isTotalBudget: boolean, paymentMethodId: string | null, saveForLater: boolean, name: string | null, phone: string | null, email: string | null, start: Moment | null, end: Moment | null, campaignSlotId: string | null, isDays30?: boolean): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startCreating())
    const builder = getInternetRetailBuilder(getState())

    if (builder.targetingType === TargetingType.Standard) {
      const response = await apiPostInternetRetailStandardBuilder(accountId, {
        campaignName: campaignName,
        budget: budget,
        maxCpm: maxCpm,
        isTotalBudget: isTotalBudget,
        isDays30: isDays30 ?? false,
        businessTypeId: builder.businessType?.id ?? null,
        highQualityUrl: builder.image?.highQualityUrl,
        squareUrl: builder.image?.squareUrl,
        largeSquareUrl: builder.image?.largeSquareUrl,
        thumbnailUrl: builder.image?.thumbnailUrl,
        mobileUrl: builder.image?.mobileUrl,
        imageId: builder.image?.id,
        isPurchased: builder.image?.isPurchased,
        headline: builder.ad?.headline,
        body: builder.ad?.body,
        companyName: builder.ad?.companyName,
        callToAction: builder.ad?.callToAction,
        destinationUrl: builder.ad?.destinationUrl ? builder.ad?.destinationUrl : builder.displayAd?.destinationUrl,
        targetingType: builder.targetingType.toString(),
        targetArea: builder.standardTargeting!.targetArea,
        streetAddress: builder.standardTargeting!.streetAddress,
        city: builder.standardTargeting!.city,
        state: builder.standardTargeting!.state,
        zip: builder.standardTargeting!.zip,
        walkInTargeting: builder.standardTargeting!.walkInTargeting,
        walkInUsePrimary: builder.standardTargeting!.walkInUsePrimary,
        walkInAddresses: builder.standardTargeting!.walkInAddresses,
        competitorTargeting: builder.standardTargeting!.competitorTargeting,
        competitors: builder.standardTargeting!.competitors,
        placement: builder.deliver!.placement,
        categories: builder.deliver!.categories,
        paymentMethodId: paymentMethodId,
        setupIntentId: null,
        saveForLater: saveForLater,
        hasCreative: builder.builderStyle !== BuilderStyle.Custom,
        uploadedUrl: builder.displayAd?.uploadedUrl ?? null,
        htmlTag: builder.displayAd?.htmlTag ?? null,
        width: builder.displayAd?.width ?? 0,
        height: builder.displayAd?.height ?? 0,
        displayAdName: builder.displayAd?.displayAdName ?? null,
        mimeType: builder.displayAd?.mimeType ?? null,
        contactName: name,
        contactEmail: email,
        contactPhone: phone,
        startDate: start,
        endDate: end,
        campaignSlotId: campaignSlotId
      } as StandardBuilder)
      dispatch(finishedCreating(response.data))
    }
    if (builder.targetingType === TargetingType.Advanced) {
      const response = await apiPostInternetRetailAdvancedBuilder(accountId, {
        campaignName: campaignName,
        budget: budget,
        maxCpm: maxCpm,
        isTotalBudget: isTotalBudget,
        isDays30: isDays30 ?? false,
        businessTypeId: builder.businessType?.id ?? null,
        highQualityUrl: builder.image?.highQualityUrl,
        squareUrl: builder.image?.squareUrl,
        largeSquareUrl: builder.image?.largeSquareUrl,
        thumbnailUrl: builder.image?.thumbnailUrl,
        mobileUrl: builder.image?.mobileUrl,
        imageId: builder.image?.id,
        isPurchased: builder.image?.isPurchased,
        headline: builder.ad?.headline,
        body: builder.ad?.body,
        companyName: builder.ad?.companyName,
        callToAction: builder.ad?.callToAction,
        destinationUrl: builder.ad?.destinationUrl ? builder.ad?.destinationUrl : builder.displayAd?.destinationUrl,
        targetingType: builder.targetingType.toString(),
        advancedTargetingType: builder.advancedTargeting!.advancedTargetingType.toString(),
        locationType: builder.advancedTargeting!.locationType === 'Upload' ? 'Map' : builder.advancedTargeting!.locationType,
        states: builder.advancedTargeting!.states,
        dmas: builder.advancedTargeting!.dmas,
        locations: builder.advancedTargeting!.locations,
        segments: builder.advancedTargeting!.segments,
        cities: builder.advancedTargeting!.cities.map(c => c.id),
        zips: builder.advancedTargeting!.zips.map(z => z.id),
        geoFences: builder.advancedTargeting!.geoFences,
        geoCircles: builder.advancedTargeting!.geoCircles,
        geoRectangles: builder.advancedTargeting!.geoRectangles,
        geoPolygons: builder.advancedTargeting!.geoPolygons,
        audienceType: builder.advancedTargeting!.audienceType,
        uploadedAudienceUrl: builder.advancedTargeting!.uploadedAudienceUrl,
        audiences: builder.advancedTargeting!.audiences,
        ageRanges: builder.advancedTargeting!.ageRanges,
        genders: builder.advancedTargeting!.genders,
        householdIncomes: builder.advancedTargeting!.householdIncomes,
        inventorySettings:
          {
            targetWeb: builder.advancedTargeting!.inventorySettings?.targetWeb,
            targetApp: builder.advancedTargeting!.inventorySettings?.targetApp
          } ?? null,
        deviceSettings:
          {
            targetPc: builder.advancedTargeting!.deviceSettings?.targetPc,
            targetPhone: builder.advancedTargeting!.deviceSettings?.targetPhone,
            targetTablet: builder.advancedTargeting!.deviceSettings?.targetTablet
          } ?? null,
        frequency: builder.advancedTargeting!.frequency,
        frequencyUnit: builder.advancedTargeting!.frequencyUnit,
        uploadType: builder.advancedTargeting!.uploadType,
        uploadedData: builder.advancedTargeting!.uploadedData,
        retargetingType: builder.advancedTargeting!.retargetingType,
        urlParts: builder.advancedTargeting!.urlParts,
        retargetingCampaignIds: builder.advancedTargeting!.retargetingCampaignIds,
        keywords: builder.advancedTargeting!.keywords,
        negativeKeywords: builder.advancedTargeting!.negativeKeywords,
        placement: builder.deliver!.placement || 'Everywhere',
        categories: builder.deliver!.categories,
        paymentMethodId: paymentMethodId,
        setupIntentId: null,
        saveForLater: saveForLater,
        hasCreative: builder.builderStyle !== BuilderStyle.Custom,
        uploadedUrl: builder.displayAd?.uploadedUrl ?? null,
        htmlTag: builder.displayAd?.htmlTag ?? null,
        width: builder.displayAd?.width ?? 0,
        height: builder.displayAd?.height ?? 0,
        displayAdName: builder.displayAd?.displayAdName ?? null,
        mimeType: builder.displayAd?.mimeType ?? null,
        contactName: name,
        contactEmail: email,
        contactPhone: phone,
        startDate: start,
        endDate: end,
        dayParts: builder.advancedTargeting!.dayParts,
        campaignSlotId: campaignSlotId
      } as AdvancedBuilder)
      dispatch(finishedCreating(response.data))
    }

    dispatch(fetchCampaigns(accountId))
    dispatch(dashboardApi.util.invalidateTags([{ type: 'DashboardFilter', id: accountId }]))
  }
