import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiCallOut, EuiFieldNumber, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiHideFor, EuiRadio, EuiSelect, EuiShowFor, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { CampaignType, FrequencyUnit, PermissionType, ThirdPartyAudience } from 'api/interfaces'
import { useGetOrganizationQuery } from 'api/rtkQueryApi/platform/organizationsApi'
import config from 'app/config'
import { RootState } from 'app/rootReducer'
import { AcFileUploader } from 'components/Basic/AcFileUploader'
import { NewCityFinder } from 'components/finders/NewCityFinder'
import { NewDmaFinder } from 'components/finders/NewDmaFinder'
import { NewPostalCodeFinder } from 'components/finders/NewPostalCodeFinder'
import { NewRegionFinder } from 'components/finders/NewRegionFinder'
import { NewSegmentFinder } from 'components/finders/NewSegmentFinder'
import { AgeRangeTargeter, GenderTargeter, HouseholdIncomeTargeter, ZipTargeter } from 'components/targeters'
import { AudienceTargeterDetailed } from 'components/targeters/AudienceTargeterDetailed'
import { DayPartPicker, DefaultDayParts } from 'components/targeters/DayPartPicker'
import { NewCityTargeter } from 'components/targeters/NewCityTargeter'
import { NewDmaTargeter } from 'components/targeters/NewDmaTargeter'
import { NewRegionTargeter } from 'components/targeters/NewRegionTargeter'
import history from 'services/HistoryService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import { BuilderStyle, setTargeting, Targeting } from './builderTvRetailSlice'

const TargetStep: React.FC = () => {
  const dispatch = useDispatch()
  const organizationQuery = useGetOrganizationQuery(undefined)
  const builder = useSelector((state: RootState) => state.builderTvRetail)
  const [uploadedAudienceUrl, setUploadedAudienceUrl] = useState<string>()
  const [isInvalidUpload, setIsInvalidUpload] = useState(false)

  const [initialValues, setInitialValues] = useState<Targeting>({
    locationType: 'USA',
    cities: [],
    dmas: [],
    zips: [],
    ageRanges: ['all'],
    genders: ['all'],
    householdIncomes: ['all'],
    audiences: [],
    segments: [],
    states: [],
    locations: {
      countries: [],
      regions: [],
      dmas: [],
      cities: [],
      postalCodes: []
    },
    frequency: 0,
    frequencyUnit: FrequencyUnit.hour,
    dayParts: DefaultDayParts
  })
  const whiteLabel = useWhiteLabel()

  useEffect(() => {
    if (config.gtag.ANALYTICSID) {
      ReactGA.event('select_item', { items: [{ item_name: 'TV Campaign' }], send_to: config.gtag.ANALYTICSID })
    }

    ReactPixel.track('ViewContent', { content_type: 'TV Campaign' })

    if (config.linkedin.PARTNER_ID && config.linkedin.VIEW_CONTENT_CONVERSION_ID) {
      window.lintrk('track', { conversion_id: config.linkedin.VIEW_CONTENT_CONVERSION_ID })
    }
  }, [])

  useEffect(() => {
    if (builder.targeting) {
      setInitialValues(builder.targeting)
    }
  }, [builder.targeting])

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values: Targeting) => {
      const targeting = {
        ...values!,
        locations: {
          countries: [],
          regions: values.locations.regions,
          dmas: values.locations.dmas,
          cities: values.locations.cities,
          postalCodes: values.locations.postalCodes,
          uploadedAudienceUrl: uploadedAudienceUrl
        },
        states: [],
        dmas: [],
        cities: [],
        zips: [],
        audiences: [],
        segments: values.segments,
        frequency: whiteLabel?.isAgencies() ? values.frequency : null,
        frequencyUnit: whiteLabel?.isAgencies() ? values.frequencyUnit : null,
        uploadedAudienceUrl: undefined
      }
      dispatch(setTargeting(targeting))
      history.push('/build/tv/public/deliver')
    }
  })

  const gotoPrevious = () => {
    if (builder.builderStyle === BuilderStyle.Upload) {
      history.push('/build/tv/public/upload')
    } else if (builder.builderStyle === BuilderStyle.Custom) {
      history.push('/build/tv/public/questionnaire')
    } else {
      history.push('/build/tv/public/create')
    }
  }

  const frequencyOptions = [
    { value: FrequencyUnit.hour, text: 'Hour' },
    { value: FrequencyUnit.day, text: 'Day' },
    { value: FrequencyUnit.week, text: 'Week' }
  ]

  return (
    <React.Fragment>
      <Helmet>
        <title>TV Ad Builder</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Targeting</h3>
      </EuiTitle>
      <EuiText size='s'>
        <div>Define the location(s) you want to target.</div>
      </EuiText>
      <EuiSpacer />

      <FormikProvider value={formik}>
        <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.status && !!formik.errors} error={formik.status}>
          <EuiRadio
            id='country'
            name='locationType'
            value='USA'
            label={
              <EuiText size='s'>
                <strong>Target the USA</strong>
              </EuiText>
            }
            checked={formik.values.locationType === 'USA'}
            onChange={() => {
              formik.setFieldValue('locationType', 'USA', true)
            }}
          />
          <EuiSpacer size='s' />
          <EuiRadio
            id='state'
            name='locationType'
            value='State'
            label={
              <EuiText size='s'>
                <strong>Target by State</strong> (one or more U.S. States)
              </EuiText>
            }
            checked={formik.values.locationType === 'State'}
            onChange={() => {
              formik.setFieldValue('locationType', 'State', true)
            }}
          />
          <EuiSpacer size='s' />
          <EuiRadio
            id='dma'
            name='locationType'
            value='DMA'
            label={
              <EuiText size='s'>
                <strong>Target by Metro Area / DMA</strong> (one or more)
              </EuiText>
            }
            checked={formik.values.locationType === 'DMA'}
            onChange={() => {
              formik.setFieldValue('locationType', 'DMA', true)
            }}
          />
          <EuiSpacer size='s' />
          <EuiRadio
            id='city'
            name='locationType'
            value='City'
            label={
              <EuiText size='s'>
                <strong>Target by City</strong> (one or more)
              </EuiText>
            }
            checked={formik.values.locationType === 'City'}
            onChange={() => {
              formik.setFieldValue('locationType', 'City', true)
            }}
          />
          <EuiSpacer size='s' />
          <EuiRadio
            id='zip'
            name='locationType'
            value='Zip'
            label={
              <EuiText size='s'>
                <strong>Target by Zip Code</strong> (one or more)
              </EuiText>
            }
            checked={formik.values.locationType === 'Zip'}
            onChange={() => {
              formik.setFieldValue('locationType', 'Zip', true)
            }}
          />

          <div hidden={formik.values.locationType !== 'State'}>
            <EuiSpacer />
            <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. states below.' />
            <EuiSpacer size='s' />
            <EuiFormRow id='stateFinder' label='Search for a state' fullWidth>
              <NewRegionFinder
                addRegion={regionToAdd => {
                  formik.setFieldValue('locations.regions', [...formik.values.locations.regions, regionToAdd])
                }}
                isInvalid={false}
              />
            </EuiFormRow>
            {formik.values.locations.regions && formik.values.locations.regions.length > 0 && (
              <EuiFormRow label='Your campaign will target these states' fullWidth>
                <NewRegionTargeter
                  regions={formik.values.locations.regions}
                  onRegionRemoved={regionId => {
                    formik.setFieldValue(
                      'locations.regions',
                      formik.values.locations.regions.filter(x => x.id !== regionId),
                      true
                    )
                  }}
                />
              </EuiFormRow>
            )}
          </div>
          <div hidden={formik.values.locationType !== 'DMA'}>
            <EuiSpacer />
            <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more metro areas / DMAs below.' />
            <EuiSpacer size='s' />

            <EuiFormRow id='dmaFinder' label='Search for a metro area' fullWidth isInvalid={!!formik.touched.dmas && !!formik.errors.dmas} error={formik.errors.dmas}>
              <NewDmaFinder addDma={dmaToAdd => formik.setFieldValue('locations.dmas', [...formik.values.locations.dmas, dmaToAdd])} isInvalid={!!formik.touched.dmas && !!formik.errors.dmas} />
            </EuiFormRow>
            {formik.values.locations.dmas && formik.values.locations.dmas.length > 0 && (
              <EuiFormRow label='Your campaign will target these metro areas' fullWidth>
                <NewDmaTargeter
                  dmas={formik.values.locations.dmas}
                  onDmaRemoved={dmaId => {
                    formik.setFieldValue(
                      'locations.dmas',
                      formik.values.locations.dmas.filter(x => x.id !== dmaId),
                      true
                    )
                  }}
                />
              </EuiFormRow>
            )}
          </div>
          <div hidden={formik.values.locationType !== 'City'}>
            <EuiSpacer />
            <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. cities by name.' />
            <EuiSpacer size='s' />

            <EuiFormRow id='cityFinder' label='Search for a city' fullWidth isInvalid={!!formik.touched.cities && !!formik.errors.cities} error={formik.errors.cities}>
              <NewCityFinder onCityClicked={city => formik.setFieldValue('locations.cities', [...(formik.values.locations.cities.filter(c => c.id !== city.id) ?? []), city])} isInvalid={!!formik.touched.cities && !!formik.errors.cities} />
            </EuiFormRow>
            {formik.values.locations.cities && formik.values.locations.cities.length > 0 && (
              <EuiFormRow label='Your campaign will target these cities' fullWidth>
                <NewCityTargeter
                  cities={formik.values.locations.cities}
                  onCityRemoved={cityId => {
                    formik.setFieldValue(
                      'locations.cities',
                      formik.values.locations.cities.filter(c => c.id !== cityId)
                    )
                  }}
                />
              </EuiFormRow>
            )}
          </div>
          <div hidden={formik.values.locationType !== 'Zip'}>
            <EuiSpacer />
            <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. zip codes.' />
            <EuiSpacer size='s' />

            <EuiFormRow id='zipFinder' label='Search for a zip code' fullWidth>
              <NewPostalCodeFinder onPostalCodeClicked={zip => formik.setFieldValue('locations.postalCodes', [...formik.values.locations.postalCodes.filter(z => z.id !== zip.id), zip])} isInvalid={false} />
            </EuiFormRow>
            {formik.values.locations.postalCodes && formik.values.locations.postalCodes.length > 0 && (
              <EuiFormRow label='Your campaign will target these zip codes' fullWidth>
                <ZipTargeter
                  zips={formik.values.locations.postalCodes.map(z => ({ id: z.id.toString(), name: z.name }))}
                  onZipRemoved={zip => {
                    formik.setFieldValue(
                      'zips',
                      formik.values.locations.postalCodes.filter(z => z.id.toString() !== zip.id)
                    )
                  }}
                />
              </EuiFormRow>
            )}
          </div>
          <EuiSpacer size='xl' />
          <EuiTitle size='s'>
            <span>Demographic Prioritization</span>
          </EuiTitle>
          <EuiSpacer size='s' />
          <EuiFormRow label='Age ranges' fullWidth>
            <AgeRangeTargeter
              ageRanges={formik.values.ageRanges}
              setAgeRanges={ageRanges => {
                formik.setFieldValue('ageRanges', ageRanges, true)
              }}
            />
          </EuiFormRow>
          <EuiSpacer size='s' />
          <EuiFormRow label='Gender' fullWidth>
            <GenderTargeter
              genders={formik.values.genders}
              setGenders={genders => {
                formik.setFieldValue('genders', genders, true)
              }}
            />
          </EuiFormRow>
          <EuiSpacer size='s' />
          <EuiFormRow label='Household income' fullWidth>
            <HouseholdIncomeTargeter
              householdIncomes={formik.values.householdIncomes}
              setHouseholdIncomes={householdIncomes => {
                formik.setFieldValue('householdIncomes', householdIncomes, true)
              }}
            />
          </EuiFormRow>
          <EuiSpacer size='xl' />
          {organizationQuery.data?.permissions?.some(p => p.permissionType === PermissionType.UploadAudience) && (
            <React.Fragment>
              <EuiTitle size='s'>
                <span>Optional: Upload Target Audience</span>
              </EuiTitle>
              <EuiSpacer size='s' />
              <EuiFormRow label='Upload an audience to prioritize traffic'>
                <React.Fragment>
                  <a href='/AudienceSampleUpload.csv' download={'AudienceSampleUpload.csv'}>
                    Template
                  </a>
                  <EuiSpacer size='s' />
                  <AcFileUploader
                    maxFileSize={10000000}
                    allowedFileTypes={['text/plain', 'text/csv']}
                    onUploadedUrlChange={url => {
                      setUploadedAudienceUrl(url)
                    }}
                    onIsValidFileChange={isValid => {
                      setIsInvalidUpload(!isValid)
                    }}
                  />
                  {isInvalidUpload && <EuiCallOut title='Please upload a csv under 10MB' iconType='document' color='warning' />}
                </React.Fragment>
              </EuiFormRow>
              <EuiSpacer size='xl' />
            </React.Fragment>
          )}
          <EuiTitle size='s'>
            <span>Optional: Audience Refinement</span>
          </EuiTitle>
          <EuiSpacer size='s' />

          <EuiFormRow label='Search for unique audience characteristics (i.e. homeowners, luxury goods, etc.)' fullWidth>
            <NewSegmentFinder
              campaignType={CampaignType.TV}
              isInvalid={false}
              onSegmentClicked={segment => {
                formik.setFieldValue('segments', [...formik.values.segments.filter(s => s.id !== segment.id), segment])
              }}
            />
          </EuiFormRow>
          {formik.values.segments && formik.values.segments.length > 0 && (
            <EuiFormRow label='Your campaign will prioritize individuals in any of these categories' fullWidth>
              <AudienceTargeterDetailed
                audiences={formik.values.segments.map(s => {
                  return { id: s.id, name: s.name, description: s.description } as ThirdPartyAudience
                })}
                onAudienceRemoved={audience => {
                  formik.setFieldValue(
                    'segments',
                    formik.values.segments.filter(a => a.id !== audience.id)
                  )
                }}
              />
            </EuiFormRow>
          )}
          {whiteLabel?.isAgencies() && (
            <React.Fragment>
              <EuiSpacer size='xl' />
              <EuiTitle size='s'>
                <span>Advanced Targeting Options</span>
              </EuiTitle>
              <EuiSpacer size='s' />
              <EuiFormRow fullWidth label={'Select which hours of the day your ad will run. (Uses the audience time zone)'}>
                <DayPartPicker
                  dayParts={formik.values.dayParts}
                  onSelectedDayPartsChange={days => {
                    formik.setFieldValue('dayParts', days)
                  }}
                />
              </EuiFormRow>
              <EuiSpacer size='s' />
              <EuiFormRow label='Frequency Capping (0 is unlimited)'>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFieldNumber
                      name='frequency'
                      value={formik.values.frequency!}
                      onChange={e => {
                        formik.setFieldValue('frequency', Number(e.target.value))
                      }}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiText size='xs'>per</EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiSelect
                      options={frequencyOptions}
                      value={formik.values.frequencyUnit ?? 0}
                      onChange={e => {
                        formik.setFieldValue('frequencyUnit', e.target.value)
                      }}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFormRow>
            </React.Fragment>
          )}
          <EuiSpacer />
        </EuiForm>
      </FormikProvider>
      <EuiSpacer />

      <EuiShowFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='continue' fill type='submit' isLoading={formik.isSubmitting} onClick={formik.submitForm}>
              Continue
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={gotoPrevious}>
              Back
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={gotoPrevious} iconType='arrowLeft' iconSide='left'>
              Back
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='continue' fill type='submit' isLoading={formik.isSubmitting} iconType='arrowRight' iconSide='right' onClick={formik.submitForm}>
              Continue
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiHideFor>
    </React.Fragment>
  )
}

export default TargetStep
