import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiFlyout, EuiFlyoutBody, EuiPortal, EuiFlyoutFooter, EuiFlexGroup, EuiFlexItem, EuiButtonEmpty, EuiFlyoutHeader, EuiTitle, EuiTabs, EuiTab } from '@elastic/eui'

import { RootState } from 'app/rootReducer'
import { close } from 'features/organization/organizationSlice'
import { EndOfMonthReport } from 'features/reports/EndOfMonthReport'
import { ForecastReport } from 'features/reports/ForecastReport'
import { MonthlyLedgerReport } from 'features/reports/MonthlyLedgerReport'

import { ChangePasswordDrawer } from './ChangePasswordDrawer'
import InvoicesDrawer from './InvoicesDrawer'
import OrganizationDrawer from './OrganizationDrawer'
import PaymentMethodsDrawer from './PaymentMethodsDrawer'
import PlatformAccessDrawer from './PlatformAccessDrawer'
import UsersDrawer from './UsersDrawer'

interface TabProps {
  name: string
  id: string
}

export const AdministrationFlyout: React.FC = () => {
  const dispatch = useDispatch()
  const { currentOrganization, currentUser, isStaffUser } = useSelector((state: RootState) => state.app)
  const organization = useSelector((state: RootState) => state.organization)
  const [selectedTabId, setSelectedTabId] = useState('details')
  const [tabs, setTabs] = useState<Array<TabProps>>([])

  const closeFlyout = () => dispatch(close())

  useEffect(() => {
    if (currentOrganization !== null) {
      let tabsArray: Array<TabProps> = [
        {
          name: 'Contact Details',
          id: 'details'
        },
        {
          name: 'Subscription',
          id: 'subscription'
        },
        {
          name: 'Change Password',
          id: 'changePassword'
        },
        {
          name: 'Payment Methods',
          id: 'paymentMethods'
        },
        {
          name: 'Users',
          id: 'users'
        },
        {
          name: 'Invoices',
          id: 'invoices'
        },
        ...(currentOrganization?.id === 'afa76f00-644f-4c1e-8bdc-855f240dd5a9' && (isStaffUser || currentUser?.roles.includes('infusion-pro'))
          ? [
              {
                name: 'Forecast',
                id: 'forecast'
              },
              {
                name: 'End of Month',
                id: 'endOfMonth'
              },
              {
                name: 'Ledger',
                id: 'ledger'
              }
            ]
          : [])
      ]

      setTabs(tabsArray)
    }
  }, [currentOrganization])

  let flyout
  if (organization.isOpen) {
    flyout = (
      <EuiPortal>
        <EuiFlyout onClose={closeFlyout} size='l' aria-labelledby='flyoutLargeTitle' ownFocus hideCloseButton>
          <EuiFlyoutHeader hasBorder>
            <EuiTitle>
              <h4>Platform Management</h4>
            </EuiTitle>
            <EuiTabs style={{ marginBottom: '-25px' }}>
              {tabs.map((t, index) => (
                <EuiTab key={index} isSelected={t.id === selectedTabId} onClick={() => setSelectedTabId(t.id)}>
                  {t.name}
                </EuiTab>
              ))}
            </EuiTabs>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>
            {selectedTabId === 'details' && <OrganizationDrawer />}
            {selectedTabId === 'subscription' && <PlatformAccessDrawer />}
            {selectedTabId === 'paymentMethods' && <PaymentMethodsDrawer />}
            {selectedTabId === 'users' && <UsersDrawer />}
            {selectedTabId === 'invoices' && <InvoicesDrawer />}
            {selectedTabId === 'changePassword' && <ChangePasswordDrawer />}
            {currentOrganization?.id === 'afa76f00-644f-4c1e-8bdc-855f240dd5a9' && selectedTabId === 'forecast' && <ForecastReport />}
            {currentOrganization?.id === 'afa76f00-644f-4c1e-8bdc-855f240dd5a9' && selectedTabId === 'endOfMonth' && <EndOfMonthReport />}
            {currentOrganization?.id === 'afa76f00-644f-4c1e-8bdc-855f240dd5a9' && selectedTabId === 'ledger' && <MonthlyLedgerReport />}
          </EuiFlyoutBody>
          <EuiFlyoutFooter>
            <EuiFlexGroup justifyContent='spaceBetween'>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty id='close' iconType='cross' onClick={closeFlyout} flush='left'>
                  Close
                </EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlyoutFooter>
        </EuiFlyout>
      </EuiPortal>
    )
  }

  return <React.Fragment>{flyout}</React.Fragment>
}

export default AdministrationFlyout
