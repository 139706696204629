import React from 'react'
import { useSelector } from 'react-redux'

import { EuiLoadingContent, EuiFlexGroup, EuiPanel, EuiFlexItem, EuiSpacer, EuiText } from '@elastic/eui'

import { RootState } from 'app/rootReducer'

const View: React.FC = () => {
  const { ad } = useSelector((state: RootState) => state)
  return (
    <EuiPanel grow={false}>
      <EuiText>
        <h4>Billboard Details</h4>
      </EuiText>
      <EuiSpacer />
      <EuiFlexGroup style={{ overflow: 'auto' }}>
        <EuiFlexItem>{!ad || !ad.ad ? <EuiLoadingContent /> : <img style={{ width: '100%' }} alt='billboard' src={ad.ad.billboardDetails.previewUrl ? ad.ad.billboardDetails.previewUrl : ad.ad.billboardDetails.uploadedUrl!} />}</EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )
}

export default View
