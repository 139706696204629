import React, { FC } from 'react'

import { EuiButton, EuiLink } from '@elastic/eui'
import { ButtonColor } from '@elastic/eui/src/components/button/button'
import { EuiLinkColor } from '@elastic/eui/src/components/link/link'

export interface IScheduleMeetingButton {
  textOverride?: string
  linkOverride?: string
  color?: ButtonColor
  linkColor?: EuiLinkColor
  isLink?: boolean
}
const ScheduleMeetingButton: FC<IScheduleMeetingButton> = ({ textOverride, linkOverride, color, linkColor, isLink }) => {
  if (isLink) {
    return (
      <EuiLink id='createCampaignBtn' key='createCampaign' href='https://calendly.com/adcritter-exec/platform' target='_blank' color={linkColor ?? 'success'}>
        <span>{textOverride ? textOverride : 'Schedule a Meeting'}</span>
      </EuiLink>
    )
  }

  return (
    <EuiButton id='createCampaignBtn' size='s' key='createCampaign' href={linkOverride ?? 'https://calendly.com/adcritter-exec/platform'} target='_blank' color={color ?? 'accent'} fill>
      <span style={{ color: 'white' }}>{textOverride ? textOverride : 'Schedule a Meeting'}</span>
    </EuiButton>
  )
}

export default ScheduleMeetingButton
