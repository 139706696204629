import React from 'react'
import { useSelector } from 'react-redux'

import { EuiLoadingSpinner } from '@elastic/eui'

import { useGetNonReservedSelfServicePackagesQuery, useGetReservedSelfServicePackagesQuery } from 'api/rtkQueryApi/platform/bundlesApi'
import { useCanAccessAgencyPackagesQuery } from 'api/rtkQueryApi/platform/organizationsApi'
import { RootState } from 'app/rootReducer'
import SelfServicePackagesFallbackPage from 'features/selfServicePackages/pages/SelfServicePackagesFallbackPage'
import history from 'services/HistoryService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

export const SelfServicePackagesPageCheck: React.FC = props => {
  const canAccessAgencyPackages = useCanAccessAgencyPackagesQuery()
  const { currentOrganization } = useSelector((state: RootState) => state.app)
  const whiteLabel = useWhiteLabel()

  const availablePackagesQuery = useGetReservedSelfServicePackagesQuery(undefined, { skip: !currentOrganization || !whiteLabel?.isAgencies(), pollingInterval: 300000 })
  const claimedPackagesQuery = useGetNonReservedSelfServicePackagesQuery(undefined, { skip: !currentOrganization || !whiteLabel?.isAgencies() })

  React.useEffect(() => {
    if (!whiteLabel?.isAgencies() || (!canAccessAgencyPackages.data && !canAccessAgencyPackages.isLoading)) {
      history.push('/dashboard')
    }
  }, [whiteLabel, canAccessAgencyPackages])

  if (canAccessAgencyPackages.isLoading || availablePackagesQuery.isLoading || claimedPackagesQuery.isLoading) {
    return <EuiLoadingSpinner />
  }

  if ((availablePackagesQuery.data?.length ?? 0) + (claimedPackagesQuery.data?.length ?? 0) === 0) {
    return <SelfServicePackagesFallbackPage />
  }

  return <React.Fragment>{props.children}</React.Fragment>
}
