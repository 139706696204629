import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

import { EuiButton, EuiCallOut, EuiFieldNumber, EuiFilePicker, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiLoadingContent, EuiRadio, EuiSelect, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { ApiError, apiGetTvBuilderUpload, apiPutCampaignTvTargeting } from 'api/adcritterApi'
import { City, DayPartitioning, FrequencyUnit, ThirdPartyAudience, Zip } from 'api/interfaces'
import { showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { TvAudienceFinder, CityFinder, ZipFinder, DmaFinder, StateFinder } from 'components/finders'
import { AgeRangeTargeter, CityTargeter, DmaTargeter, GenderTargeter, HouseholdIncomeTargeter, StateTargeter, ZipTargeter } from 'components/targeters'
import { AudienceTargeterDetailed } from 'components/targeters/AudienceTargeterDetailed'
import { DayPartPicker, DefaultDayParts } from 'components/targeters/DayPartPicker'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import { finishedLoadingCampaign } from '../campaignSlice'

interface FormValues {
  locationType: string
  states: Array<string>
  dmas: Array<string>
  cities: Array<City>
  zips: Array<Zip>
  ageRanges: string[]
  genders: string[]
  householdIncomes: string[]
  audiences: Array<ThirdPartyAudience>
  frequency: number
  frequencyUnit: FrequencyUnit
  dayParts: DayPartitioning
  uploadedAudienceUrl?: string
}

const TvTargetingDrawer: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount, isShopifyUser } = useSelector((state: RootState) => state.app)
  const campaign = useSelector((state: RootState) => state.campaign)
  const [containerClient, setContainerClient] = useState<ContainerClient | null>(null)
  const [formValues, setFormValues] = useState<FormValues>({
    locationType: 'USA',
    states: [],
    dmas: [],
    cities: [],
    zips: [],
    ageRanges: ['all'],
    genders: ['all'],
    householdIncomes: ['all'],
    audiences: [],
    frequency: 0,
    frequencyUnit: FrequencyUnit.hour,
    dayParts: DefaultDayParts
  })
  const whiteLabel = useWhiteLabel()

  useEffect(() => {
    if (campaign.campaign && campaign.campaign.tvTargeting) {
      setFormValues({
        locationType: campaign.campaign.tvTargeting.locationType ?? 'USA',
        states: campaign.campaign.tvTargeting!.states,
        dmas: campaign.campaign.tvTargeting.dmas,
        cities: campaign.campaign.tvTargeting.cities,
        zips: campaign.campaign.tvTargeting.zips,
        ageRanges: campaign.campaign.tvTargeting.ageRanges,
        genders: campaign.campaign.tvTargeting.genders,
        householdIncomes: campaign.campaign.tvTargeting.householdIncomes,
        audiences: campaign.campaign.tvTargeting!.audiences,
        frequency: campaign.campaign.tvTargeting!.frequency ?? 0,
        frequencyUnit: campaign.campaign.tvTargeting!.frequencyUnit ?? FrequencyUnit.hour,
        dayParts: campaign.campaign.tvTargeting!.dayParts ?? DefaultDayParts,
        uploadedAudienceUrl: campaign.campaign.tvTargeting!.uploadedAudienceUrl
      })
    }
  }, [campaign.campaign])

  const advancedLocationSchema = Yup.object().shape({
    locationType: Yup.string().required(),
    states: Yup.array().when('locationType', {
      is: 'State',
      then: Yup.array().min(1, 'Please Select at Least 1 State').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    dmas: Yup.array().when('locationType', {
      is: 'DMA',
      then: Yup.array().min(1, 'Please Select at Least 1 DMA/Metro Area').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    cities: Yup.array().when('locationType', {
      is: 'City',
      then: Yup.array().min(1, 'Please Add at Least 1 City').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    zips: Yup.array().when('locationType', {
      is: 'Zip',
      then: Yup.array().min(1, 'Please Add at Least 1 Zip').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    ageRanges: Yup.array().of(Yup.string()),
    genders: Yup.array().of(Yup.string()),
    householdIncomes: Yup.array().of(Yup.string()),
    audiences: Yup.array().of(Yup.object()),
    frequency: Yup.number(),
    frequencyUnit: Yup.number()
  })

  useEffect(() => {
    if (currentAccount) {
      apiGetTvBuilderUpload(currentAccount.id).then(data => {
        const storageClient = new BlobServiceClient(data.data)
        const containerClient = storageClient.getContainerClient(currentAccount.id)
        setContainerClient(containerClient)
      })
    }
  }, [currentAccount])

  const onFileChange = (files: FileList | null) => {
    if (files && files.length === 1) {
      if (files[0].size > 500000) {
        return
      }
      const blockBlobClient = containerClient!.getBlockBlobClient(uuidv4() + '.' + files[0].name.split('.').pop())!
      const url = blockBlobClient!.url.split('?')[0]
      setFormValues({ ...formValues, uploadedAudienceUrl: url })
      blockBlobClient!.uploadData(files[0], {
        concurrency: 20
      })
    }
    if (files && files.length < 1) {
      setFormValues({ ...formValues, uploadedAudienceUrl: undefined })
    }
  }

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: advancedLocationSchema,
    onSubmit: (values: FormValues) => {
      apiPutCampaignTvTargeting(currentAccount!.id, campaign.campaign!.id, {
        locationType: values.locationType,
        states: values.states,
        dmas: values.dmas,
        cities: values.cities.map(c => c.id),
        zips: values.zips.map(z => z.id),
        audiences: values.audiences,
        ageRanges: values.ageRanges,
        genders: values.genders,
        householdIncomes: values.householdIncomes,
        frequency: whiteLabel?.isAgencies() ? values.frequency : null,
        frequencyUnit: whiteLabel?.isAgencies() ? values.frequencyUnit : null,
        dayParts: values.dayParts,
        uploadedAudienceUrl: values.uploadedAudienceUrl
      })
        .then(response => {
          dispatch(showSuccessToast('Saved TV targeting'))
          dispatch(finishedLoadingCampaign(response.data))
          formik.setSubmitting(false)
          formik.setStatus(null)
        })
        .catch(response => {
          formik.setSubmitting(false)
          response.errors.forEach(function (error: ApiError) {
            formik.setFieldError(error.name, error.message)
          })
        })
    }
  })

  const frequencyOptions = [
    { value: FrequencyUnit.hour, text: 'Hour' },
    { value: FrequencyUnit.day, text: 'Day' },
    { value: FrequencyUnit.week, text: 'Week' }
  ]

  let content

  if (campaign.isLoadingCampaign) {
    content = <EuiLoadingContent lines={3} />
  } else {
    content = (
      <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
        <EuiRadio
          id='usa'
          name='locationType'
          value='USA'
          label={
            <EuiText size='s'>
              <strong>Targeting the USA</strong>
            </EuiText>
          }
          checked={formik.values.locationType === 'USA'}
          onChange={() => {
            formik.setFieldValue('locationType', 'USA', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='state'
          name='locationType'
          value='State'
          label={
            <EuiText size='s'>
              <strong>Targeting Using State</strong> (one or more U.S. States)
            </EuiText>
          }
          checked={formik.values.locationType === 'State'}
          onChange={() => {
            formik.setFieldValue('locationType', 'State', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='dma'
          name='locationType'
          value='DMA'
          label={
            <EuiText size='s'>
              <strong>Targeting Using DMA/Metro Area</strong> (one or more DMAs)
            </EuiText>
          }
          checked={formik.values.locationType === 'DMA'}
          onChange={() => {
            formik.setFieldValue('locationType', 'DMA', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='city'
          name='locationType'
          value='City'
          label={
            <EuiText size='s'>
              <strong>Targeting Using City</strong> (one or more City names)
            </EuiText>
          }
          checked={formik.values.locationType === 'City'}
          onChange={() => {
            formik.setFieldValue('locationType', 'City', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='zip'
          name='locationType'
          value='Zip'
          label={
            <EuiText size='s'>
              <strong>Targeting Using Zip Code</strong> (one or more Zip Codes)
            </EuiText>
          }
          checked={formik.values.locationType === 'Zip'}
          onChange={() => {
            formik.setFieldValue('locationType', 'Zip', true)
          }}
        />

        <div hidden={formik.values.locationType !== 'State'}>
          <EuiSpacer />
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can select U.S. States below, multiple States can be targeted at once.' />
          <EuiSpacer size='s' />

          <EuiFormRow label='Search for a State' fullWidth isInvalid={!!formik.errors.states} error={formik.errors.states}>
            <StateFinder addState={stateToAdd => formik.setFieldValue('states', [...formik.values.states, stateToAdd])} isInvalid={!!formik.errors.states} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these States:' fullWidth>
            <StateTargeter
              states={formik.values.states}
              onStateRemoved={stateToRemove =>
                formik.setFieldValue(
                  'states',
                  formik.values.states.filter(x => x !== stateToRemove),
                  true
                )
              }
            />
          </EuiFormRow>
        </div>

        <div hidden={formik.values.locationType !== 'DMA'}>
          <EuiSpacer />
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can select a DMA/Metro Area(s) below, multiple DMAs can be targeted at once.' />
          <EuiSpacer size='s' />

          <EuiFormRow label='Search for a DMA' fullWidth isInvalid={!!formik.errors.dmas} error={formik.errors.dmas}>
            <DmaFinder addDma={dmaToAdd => formik.setFieldValue('dmas', [...formik.values.dmas, dmaToAdd])} isInvalid={!!formik.errors.dmas} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these DMAs:' fullWidth>
            <DmaTargeter
              dmas={formik.values.dmas}
              onDmaRemoved={dmaToRemove =>
                formik.setFieldValue(
                  'dmas',
                  formik.values.dmas.filter(x => x !== dmaToRemove),
                  true
                )
              }
            />
          </EuiFormRow>
        </div>

        <div hidden={formik.values.locationType !== 'City'}>
          <EuiSpacer />
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can search for U.S. Cities below, multiple Cities can be targeted at once.' />
          <EuiSpacer size='s' />

          <EuiFormRow label='Search for a city' fullWidth isInvalid={!!formik.errors.cities} error={formik.errors.cities}>
            <CityFinder onCityClicked={city => formik.setFieldValue('cities', [...formik.values.cities, city])} isInvalid={!!formik.errors.cities} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these cities:' fullWidth>
            <CityTargeter
              cities={formik.values.cities}
              onCityRemoved={city => {
                formik.setFieldValue(
                  'cities',
                  formik.values.cities.filter(c => c.id !== city.id)
                )
              }}
            />
          </EuiFormRow>
        </div>

        <div hidden={formik.values.locationType !== 'Zip'}>
          <EuiSpacer />
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can search for U.S. Zip Codes below, multiple Zip Codes can be targeted at once.' />
          <EuiSpacer size='s' />

          <EuiFormRow label='Search for a zip code' fullWidth isInvalid={!!formik.errors.zips} error={formik.errors.zips}>
            <ZipFinder onZipClicked={zip => formik.setFieldValue('zips', [...formik.values.zips, zip])} isInvalid={!!formik.errors.zips} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these zip codes:' fullWidth>
            <ZipTargeter
              zips={formik.values.zips}
              onZipRemoved={zip => {
                formik.setFieldValue(
                  'zips',
                  formik.values.zips.filter(z => z.id !== zip.id)
                )
              }}
            />
          </EuiFormRow>
        </div>

        <EuiSpacer />

        <EuiTitle size='s'>
          <span>Demographic Prioritization</span>
        </EuiTitle>
        <EuiSpacer size='s' />
        <EuiFormRow label='Age ranges' fullWidth>
          <AgeRangeTargeter
            ageRanges={formik.values.ageRanges}
            setAgeRanges={ageRanges => {
              formik.setFieldValue('ageRanges', ageRanges, true)
            }}
          />
        </EuiFormRow>
        <EuiSpacer size='s' />
        <EuiFormRow label='Gender' fullWidth>
          <GenderTargeter
            genders={formik.values.genders}
            setGenders={genders => {
              formik.setFieldValue('genders', genders, true)
            }}
          />
        </EuiFormRow>
        <EuiSpacer size='s' />
        <EuiFormRow label='Household income' fullWidth>
          <HouseholdIncomeTargeter
            householdIncomes={formik.values.householdIncomes}
            setHouseholdIncomes={householdIncomes => {
              formik.setFieldValue('householdIncomes', householdIncomes, true)
            }}
          />
        </EuiFormRow>
        <EuiSpacer size='xl' />

        <EuiTitle size='xs'>
          <h2>Traffic Prioritization</h2>
        </EuiTitle>
        <EuiSpacer size='s' />

        <EuiFormRow label='Search for individual characteristics to refine your ideal audience.' fullWidth>
          <TvAudienceFinder onAudienceClicked={audience => formik.setFieldValue('audiences', [...formik.values.audiences, audience])} isInvalid={false} />
        </EuiFormRow>
        <EuiFormRow label='Your campaign will prioritize individuals in any of these categories:' fullWidth>
          <AudienceTargeterDetailed
            audiences={formik.values.audiences}
            onAudienceRemoved={audience => {
              formik.setFieldValue(
                'audiences',
                formik.values.audiences.filter(a => a.id !== audience.id)
              )
            }}
          />
        </EuiFormRow>
        {!isShopifyUser && (
          <React.Fragment>
            <EuiSpacer size='xl' />
            <EuiTitle size='s'>
              <span>Optional: Upload Target Audience</span>
            </EuiTitle>
            {formik.values.uploadedAudienceUrl && (
              <React.Fragment>
                <EuiFormRow>
                  <a href={formik.values.uploadedAudienceUrl} target={'_blank'}>
                    Link
                  </a>
                </EuiFormRow>
              </React.Fragment>
            )}
            <EuiFormRow label='Upload an Audience'>
              <EuiFilePicker
                id='file'
                initialPromptText='Select or drag and drop your text file'
                onChange={files => {
                  onFileChange(files)
                }}
                display='large'
                aria-label='Select or drag and drop your text file'
              />
            </EuiFormRow>
          </React.Fragment>
        )}

        {whiteLabel?.isAgencies() && (
          <React.Fragment>
            <EuiSpacer size='xl' />
            <EuiTitle size='xs'>
              <span>Advanced Targeting Options</span>
            </EuiTitle>
            <EuiSpacer size='s' />
            <EuiFormRow fullWidth label={'Select which hours of the day your ad will run. (Uses the audience time zone)'}>
              <DayPartPicker
                dayParts={formik.values.dayParts}
                onSelectedDayPartsChange={days => {
                  formik.setFieldValue('dayParts', days)
                }}
              />
            </EuiFormRow>
            <EuiSpacer size='s' />
            <EuiFormRow label='Frequency Capping (0 is unlimited)'>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFieldNumber
                    name='frequency'
                    value={formik.values.frequency!}
                    onChange={e => {
                      formik.setFieldValue('frequency', Number(e.target.value))
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiText size='xs'>per</EuiText>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiSelect
                    options={frequencyOptions}
                    value={formik.values.frequencyUnit}
                    onChange={e => {
                      formik.setFieldValue('frequencyUnit', e.target.value)
                    }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFormRow>
          </React.Fragment>
        )}
        <EuiSpacer />

        <EuiButton id='save' fill type='submit' isLoading={formik.isSubmitting}>
          Save
        </EuiButton>
      </EuiForm>
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      {content}
    </React.Fragment>
  )
}

export default TvTargetingDrawer
