import Konva from 'konva'
import React from 'react'

import { EuiButton, EuiCard, EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui'

import { BillboardSuggestion } from '../../api/interfaces'
import { BillboardEditor } from '../KonvaTools/BillboardEditor'

interface BillboardCardParams {
  billboard: BillboardSuggestion
  onUseClick: () => void
  width?: number
  header?: string
}

export const BillboardCard: React.FC<BillboardCardParams> = ({ billboard, onUseClick, width, header }) => {
  const displayWidth = width ?? 640
  const stageRef = React.useRef<Konva.Stage>(null)
  const billboardHeight = displayWidth / (7 / 2)
  return (
    <EuiFlexItem id='billboardSuggestion' style={{ width: displayWidth }}>
      <EuiCard
        title={header ?? ''}
        textAlign={'left'}
        description={''}
        image={<BillboardEditor billboard={billboard} onUpdateBillboard={() => {}} disableEdit width={displayWidth} height={billboardHeight} stageRef={stageRef} />}
        footer={
          <EuiFlexGroup justifyContent='flexEnd'>
            <EuiFlexItem>
              <EuiText size='s'>{billboard.kind === 'Large' ? 'Large' : '1400x400'}</EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton
                id='useEditBtn'
                color='primary'
                onClick={() => {
                  onUseClick()
                }}
                style={{ width: 200 }}
                fill
                iconType='arrowRight'
                iconSide='right'>
                Use/Edit
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        }
      />
    </EuiFlexItem>
  )
}
