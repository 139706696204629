import { CampaignCreateWorkflow } from 'workflows/CampaignCreateWorkflow'

import { CampaignBuilderActivateResponse } from 'api/interfaces'
import { PaymentInfo } from 'api/interfaces/PaymentInfo'
import { defaultTransformFn, platformApi } from 'api/rtkQueryApi/platform/platformApi'

const campaignBuilderTags = platformApi.enhanceEndpoints({ addTagTypes: ['CampaignBuilder'] })

const campaignBuilderApi = campaignBuilderTags.injectEndpoints({
  endpoints: build => ({
    getCampaignBuilders: build.query<CampaignCreateWorkflow[], { accountId: string }>({
      query: request => {
        return {
          url: `accounts/${request.accountId}/builder/campaign`,
          method: 'GET',
          isContext: true
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, request) => [{ type: 'CampaignBuilder', id: request.accountId }]
    }),
    getCampaignBuilder: build.query<CampaignCreateWorkflow[], { accountId: string; builderId: string }>({
      query: request => {
        return {
          url: `accounts/${request.accountId}/builder/campaign/${request.builderId}`,
          method: 'GET',
          isContext: true
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, request) => [{ type: 'CampaignBuilder', id: request.builderId }]
    }),
    upsertCampaignBuilder: build.mutation<CampaignCreateWorkflow, { accountId: string; workflow: CampaignCreateWorkflow }>({
      query: request => ({
        url: `accounts/${request.accountId}/builder/campaign`,
        method: 'POST',
        data: request.workflow,
        isContext: true
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, request) => [{ type: 'CampaignBuilder', id: request.accountId }]
    }),
    campaignBuilderActivate: build.mutation<CampaignBuilderActivateResponse, { accountId: string; workflow: CampaignCreateWorkflow; purchaseDetails: PaymentInfo; isSaveForLater: boolean }>({
      query: request => ({
        url: `accounts/${request.accountId}/builder/campaign/activate`,
        method: 'POST',
        data: {
          campaignBuilder: request.workflow,
          purchaseDetails: request.purchaseDetails,
          isSaveForLater: request.isSaveForLater
        },
        isContext: true
      }),
      transformResponse: defaultTransformFn
    })
  }),
  overrideExisting: false
})

export const { useGetCampaignBuildersQuery, useGetCampaignBuilderQuery, useUpsertCampaignBuilderMutation, useCampaignBuilderActivateMutation } = campaignBuilderApi
