import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCheckableCard, EuiCheckbox, EuiFieldText, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHideFor, EuiIcon, EuiListGroup, EuiListGroupItem, EuiShowFor, EuiSpacer, EuiText, EuiTitle, EuiToolTip, htmlIdGenerator } from '@elastic/eui'

import { FrequencyUnit, InternetAdPlacementCategory } from 'api/interfaces'
import { CampaignDelivery } from 'api/interfaces/CampaignDelivery'
import { RootState } from 'app/rootReducer'
import { DefaultDayParts } from 'components/targeters/DayPartPicker'
import { ICampaignBuilderWorkflowStep } from 'features/builders/createCampaign/creativeBuilder/CreativeBuilderWorkflowManager'
import { useCampaignBuilderActions } from 'features/builders/createCampaign/useCampaignBuilderActions'
import { AdPlacementType } from 'features/builders/internet/retail/builderInternetRetailSlice'

export const DeliveryInternetPage: React.FC<ICampaignBuilderWorkflowStep> = props => {
  const { deliveryComplete } = useCampaignBuilderActions()
  const [newSite, setNewSite] = useState<string>('')
  const [invalidSite, setInvalidSite] = useState<boolean>(false)
  const [deliveryDetails, setDeliveryDetails] = useState<CampaignDelivery>({
    dayParts: DefaultDayParts,
    frequency: 12,
    frequencyUnit: FrequencyUnit.week,
    inventorySettings: { targetApp: false, targetWeb: true },
    deviceSettings: { targetPc: true, targetPhone: true, targetTablet: true },
    placement: AdPlacementType.Everywhere,
    categories: [],
    specific: []
  })

  const {
    campaignTargeting: { campaignDelivery }
  } = useSelector((state: RootState) => state.campaignCreateWorkflow)

  useEffect(() => {
    if (campaignDelivery) {
      setDeliveryDetails(campaignDelivery)
    }
  }, [campaignDelivery])

  const siteSchema = Yup.object().shape({
    site: Yup.string()
      .required()
      .matches(/^(?!-)(?:(?:[a-zA-Z\d][a-zA-Z\d-]{0,61})?[a-zA-Z\d]\.){1,126}(?!\d+)[a-zA-Z\d]{1,63}$/, 'Please Enter a Valid Site')
  })

  const gotoActivateStep = () => {
    deliveryComplete(deliveryDetails)
  }

  const returnPreviousStep = () => {
    props.onBack?.()
  }

  const updateCategories = (isChecked: boolean, category: InternetAdPlacementCategory) => {
    if (isChecked) {
      setDeliveryDetails({ ...deliveryDetails, categories: [...deliveryDetails.categories, category] })
    } else {
      setDeliveryDetails({ ...deliveryDetails, categories: deliveryDetails.categories.filter(c => c !== category) })
    }
  }

  const addNewSite = () => {
    siteSchema.isValid({ site: newSite }).then(function (valid) {
      if (valid) {
        setInvalidSite(false)
        setDeliveryDetails({ ...deliveryDetails, specific: [...deliveryDetails.specific, newSite] })
        setNewSite('')
      } else {
        setInvalidSite(true)
      }
    })
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Deliver</title>
      </Helmet>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size='s'>
            <h3>Ad Placement</h3>
          </EuiTitle>
          <EuiText size='s'>
            <div>Prioritize where your ads appear on the Internet.</div>
          </EuiText>
          <EuiSpacer />
          <EuiCheckableCard
            id={htmlIdGenerator()()}
            label={<strong>Prioritize highest performing sites. (Recommended)</strong>}
            name={'placement'}
            checked={deliveryDetails.placement === AdPlacementType.Everywhere}
            onChange={() => {
              setDeliveryDetails({ ...deliveryDetails, placement: AdPlacementType.Everywhere })
            }}>
            <EuiText size='s' color={deliveryDetails.placement !== AdPlacementType.Everywhere ? 'subdued' : 'primary'}>
              Prioritizes all top brand-safe sites and categories to deliver top results.
            </EuiText>
          </EuiCheckableCard>
          <EuiSpacer size='m' />
          <EuiCheckableCard
            className='categoriesCheckBox'
            disabled={true}
            id={htmlIdGenerator()()}
            label={<strong>Prioritize specific categories of sites</strong>}
            name={'placement'}
            checked={deliveryDetails.placement === AdPlacementType.Categories}
            onChange={() => {
              setDeliveryDetails({
                ...deliveryDetails,
                placement: AdPlacementType.Categories,
                categories: [InternetAdPlacementCategory.Business, InternetAdPlacementCategory.Social, InternetAdPlacementCategory.Portals, InternetAdPlacementCategory.Lifestyle, InternetAdPlacementCategory.Sports, InternetAdPlacementCategory.Celebrity, InternetAdPlacementCategory.News, InternetAdPlacementCategory.Finance, InternetAdPlacementCategory.Weather, InternetAdPlacementCategory.Community, InternetAdPlacementCategory.Entertainment]
              })
            }}>
            <EuiFlexGrid columns={3} gutterSize='s'>
              <EuiFlexItem>
                <EuiCheckbox
                  className=''
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, InternetAdPlacementCategory.Business)
                  }}
                  disabled={deliveryDetails.placement !== 'Categories'}
                  checked={deliveryDetails.categories.filter(c => c === InternetAdPlacementCategory.Business).length > 0}
                  label={
                    <React.Fragment>
                      Business{' '}
                      <EuiToolTip position='bottom' content='Prioritizes top business sites such as Wall Street Journal, CNN, Fox Business, MSN, Business Insider, Forbes and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, InternetAdPlacementCategory.Social)
                  }}
                  disabled={deliveryDetails.placement !== 'Categories'}
                  checked={deliveryDetails.categories.filter(c => c === InternetAdPlacementCategory.Social).length > 0}
                  label={
                    <React.Fragment>
                      Social{' '}
                      <EuiToolTip position='bottom' content='Prioritizes social sites and user generated content platforms such as Reddit, Tumblr, specialty blogs and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, InternetAdPlacementCategory.Portals)
                  }}
                  disabled={deliveryDetails.placement !== 'Categories'}
                  checked={deliveryDetails.categories.filter(c => c === InternetAdPlacementCategory.Portals).length > 0}
                  label={
                    <React.Fragment>
                      Portals{' '}
                      <EuiToolTip position='bottom' content='Prioritizes web portal sites and top homepages such as MSN, Yahoo, AOL, Daily Mail, Bing and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, InternetAdPlacementCategory.Lifestyle)
                  }}
                  disabled={deliveryDetails.placement !== 'Categories'}
                  checked={deliveryDetails.categories.filter(c => c === InternetAdPlacementCategory.Lifestyle).length > 0}
                  label={
                    <React.Fragment>
                      Lifestyle{' '}
                      <EuiToolTip position='bottom' content='Prioritizes lifestyle sites such as inStyle, Travel &amp; Leisure, People, Mashable, POPSUGAR, USA Today and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, InternetAdPlacementCategory.Sports)
                  }}
                  disabled={deliveryDetails.placement !== 'Categories'}
                  checked={deliveryDetails.categories.filter(c => c === InternetAdPlacementCategory.Sports).length > 0}
                  label={
                    <React.Fragment>
                      Sports{' '}
                      <EuiToolTip position='bottom' content='Prioritizes top sports sites such as ESPN, Yahoo! Sports, CBS Sports, NFL, Rivals, Bleacher Report and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, InternetAdPlacementCategory.Celebrity)
                  }}
                  disabled={deliveryDetails.placement !== 'Categories'}
                  checked={deliveryDetails.categories.filter(c => c === InternetAdPlacementCategory.Celebrity).length > 0}
                  label={
                    <React.Fragment>
                      Celebrity{' '}
                      <EuiToolTip position='bottom' content='Prioritizes premium sites featuring celebrity lifestyle content such as People, TMZ, E! News, Perez Hilton, too fab more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, InternetAdPlacementCategory.News)
                  }}
                  disabled={deliveryDetails.placement !== 'Categories'}
                  checked={deliveryDetails.categories.filter(c => c === InternetAdPlacementCategory.News).length > 0}
                  label={
                    <React.Fragment>
                      News{' '}
                      <EuiToolTip position='bottom' content='Prioritizes top news sites such as Newsweek, CBS, Huffington Post, Fox News, ABC News, local news outlets and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, InternetAdPlacementCategory.Finance)
                  }}
                  disabled={deliveryDetails.placement !== 'Categories'}
                  checked={deliveryDetails.categories.filter(c => c === InternetAdPlacementCategory.Finance).length > 0}
                  label={
                    <React.Fragment>
                      Finance{' '}
                      <EuiToolTip position='bottom' content='Prioritizes financial sites such as Wall Street Journal, MarketWatch, Financial Times, Yahoo! Finance, Bloomberg, CNN Money and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, InternetAdPlacementCategory.Weather)
                  }}
                  disabled={deliveryDetails.placement !== 'Categories'}
                  checked={deliveryDetails.categories.filter(c => c === InternetAdPlacementCategory.Weather).length > 0}
                  label={
                    <React.Fragment>
                      Weather{' '}
                      <EuiToolTip position='bottom' content='Prioritizes weather sites such as The Weather Channel, Accuweather, WeatherBug and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, InternetAdPlacementCategory.Community)
                  }}
                  disabled={deliveryDetails.placement !== 'Categories'}
                  checked={deliveryDetails.categories.filter(c => c === InternetAdPlacementCategory.Community).length > 0}
                  label={
                    <React.Fragment>
                      Community{' '}
                      <EuiToolTip position='bottom' content='Prioritizes community sites such as NextDoor, Reddit, MSN, AOL, Yahoo!, specialty blogs and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  onChange={e => {
                    updateCategories(e.target.checked, InternetAdPlacementCategory.Entertainment)
                  }}
                  disabled={deliveryDetails.placement !== 'Categories'}
                  checked={deliveryDetails.categories.filter(c => c === InternetAdPlacementCategory.Entertainment).length > 0}
                  label={
                    <React.Fragment>
                      Entertainment{' '}
                      <EuiToolTip position='bottom' content='Prioritizes entertainment sites such as Entertainment Weekly, Fandango, Thrillist, E! News, Variety, Rolling Stone and more...'>
                        <EuiIcon type='iInCircle' style={{ marginBottom: 4 }} />
                      </EuiToolTip>
                    </React.Fragment>
                  }
                />
              </EuiFlexItem>
            </EuiFlexGrid>
          </EuiCheckableCard>
          <EuiSpacer size='m' />

          <EuiCheckableCard
            className='specificCheckBox'
            disabled={true}
            id={htmlIdGenerator()()}
            label={<strong>Prioritize a list of specific sites.</strong>}
            name={'placement'}
            checked={deliveryDetails.placement === AdPlacementType.Specific}
            onChange={() => {
              setDeliveryDetails({ ...deliveryDetails, placement: AdPlacementType.Specific })
            }}>
            <EuiText size='s' color={deliveryDetails.placement !== 'Specific' ? 'subdued' : 'primary'}>
              Enter the sites you want to prioritize.
            </EuiText>
            <div hidden={deliveryDetails.placement !== 'Specific'} style={{ marginLeft: 35, marginTop: 20 }}>
              <EuiFormRow
                label='Enter a site'
                isInvalid={invalidSite}
                error={'Please enter a valid site'}
                fullWidth
                labelAppend={
                  <EuiText size='xs'>
                    <EuiToolTip position='bottom' content='Examples: people.com, si.com, parents.com, motortrend.com'>
                      <EuiIcon type='iInCircle' style={{ marginBottom: 2 }} />
                    </EuiToolTip>
                  </EuiText>
                }>
                <EuiFieldText
                  value={newSite}
                  onChange={v => setNewSite(v.target.value)}
                  isInvalid={invalidSite}
                  fullWidth
                  append={
                    <EuiButton id='add' onClick={addNewSite} type='button'>
                      Add
                    </EuiButton>
                  }
                />
              </EuiFormRow>
              <EuiSpacer size='s' />
              <EuiListGroup bordered={true} maxWidth={false} hidden={deliveryDetails.specific.length === 0}>
                {deliveryDetails.specific.map(s => (
                  <EuiListGroupItem
                    key={s}
                    label={s}
                    extraAction={{
                      onClick: () => {
                        setDeliveryDetails({ ...deliveryDetails, specific: deliveryDetails.specific.filter(site => site !== s) })
                      },
                      iconType: 'cross',
                      iconSize: 's',
                      'aria-label': 'Remove',
                      alwaysShow: true
                    }}
                  />
                ))}
              </EuiListGroup>
            </div>
          </EuiCheckableCard>
          <EuiSpacer />

          <EuiShowFor sizes={['xs', 's']}>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButton id='reviewAndFinalizeBtn' fill onClick={gotoActivateStep}>
                  Review &amp; Finalize
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton id='back' fill type='button' color='text' onClick={returnPreviousStep}>
                  Back
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiShowFor>

          <EuiHideFor sizes={['xs', 's']}>
            <EuiFlexGroup responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiButton id='back' fill type='button' color='text' onClick={returnPreviousStep} iconType='arrowLeft' iconSide='left'>
                  Back
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton id='reviewAndFinalizeBtn' fill onClick={gotoActivateStep} iconType='arrowRight' iconSide='right'>
                  Review &amp; Finalize
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiHideFor>
        </EuiFlexItem>
        <EuiHideFor sizes={['s']}>
          <EuiFlexItem grow={false} style={{ width: 260 }}>
            <EuiText size='xs'>
              <h3>Best Practices</h3>
              <h5>How Much to Budget</h5>
              <p>Starting small is always a good idea. Once you have determined your ads are performing as intended, you can always increase the budget or launch additional campaigns.</p>
            </EuiText>
          </EuiFlexItem>
        </EuiHideFor>
      </EuiFlexGroup>
    </React.Fragment>
  )
}
