import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  EuiLoadingContent,
  EuiBasicTableColumn,
  EuiFormRow,
  EuiSelect,
  EuiSpacer,
  EuiInMemoryTable, SortDirection
} from '@elastic/eui'

import { Invoice } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import ViewInvoiceLink from 'components/ViewInvoiceLink'
import { fetchOrganizationInvoices } from './organizationSlice'
import { useIsAgency } from 'whiteLabel/useIsAgency'
import {
  useGetAllOrganizationInvoicesQuery,
  useGetOrganizationBillingCustomersQuery
} from 'api/rtkQueryApi/platform/organizationsApi'

const InvoicesDrawer = () => {
  const dispatch = useDispatch()
  const { currentOrganization, currentAccount } = useSelector((state: RootState) => state.app)
  const isAgency = useIsAgency()
  const billingCustomersQuery = useGetOrganizationBillingCustomersQuery(undefined)
  const organizationInvoicesQuery = useGetAllOrganizationInvoicesQuery(undefined, {skip: !currentOrganization})

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const [invoices, setInvoices] = useState<Invoice[]>([])
  const [customerOptions, setCustomerOptions] = useState<{ value: string; text: string }[]>([{ value: '', text: 'All' }])
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>('')

  useEffect(() => {
    if (billingCustomersQuery.data) {
      const options = billingCustomersQuery.data.map(account => ({ value: account.id, text: account.name }))
      if(options.length > 1) {
        options.push({ value: '', text: 'All' })
      }
      setCustomerOptions(options)
    }
  }, [billingCustomersQuery.data])

  useEffect(() => {
    dispatch(fetchOrganizationInvoices(currentAccount!.id))
  }, [currentOrganization, dispatch])

  useEffect(() => {
    const totalInvoices = organizationInvoicesQuery.data || []
    if(selectedCustomerId) {
      const filteredInvoices = totalInvoices.filter(invoice => invoice.customerId === selectedCustomerId)
      setInvoices(filteredInvoices)
    }
    else {
      setInvoices(totalInvoices)
    }
  }, [selectedCustomerId, billingCustomersQuery.data])

  const convertCamelCase = (camelCaseString: string) => {
    let spaceSeparatedString = camelCaseString.replace(/([A-Z])/g, ' $1')

    if (spaceSeparatedString.charAt(0) === ' ') {
      spaceSeparatedString = spaceSeparatedString.slice(1)
    }

    return spaceSeparatedString
  }

  const columns: Array<EuiBasicTableColumn<Invoice>> = [
    {
      name: 'Date',
      field: 'issueDate',
      render: (item: string) => moment(item).format('DD MMM yyyy'),
      sortable: true
    },
    {
      name: 'Number',
      field: 'handle',
      sortable: true
    },
    {
      name: 'Amount',
      field: 'totalAmount',
      render: (item: number) => formatter.format(item),
      sortable: true
    },
    {
      name: 'Status',
      field: 'status',
      render: (status: string) => convertCamelCase(status),
      sortable: true
    },
    {
      name: 'Customer',
      render: (invoice: Invoice) => {
        const customer = billingCustomersQuery.data?.find(customer => customer.id === invoice.customerId)
        return customer ? customer.name : ''
      },
      sortable: (invoice: Invoice) => {
        const customer = billingCustomersQuery.data?.find(customer => customer.id === invoice.customerId)
        return customer ? customer.name : ''
      }
    },
    {
      name: '',
      field: '',
      render: (invoice: Invoice) => <ViewInvoiceLink invoiceHandle={invoice.handle} />
    }
  ]

  const onCustomerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCustomerId(e.target.value)
  }

  let content

  const pagination = {
    pageSizeOptions: [10, 50, 100],
    initialPageSize: 50
  }

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: true,
    sort: {
      field: 'issueDate',
      direction: SortDirection.DESC
    }
  }

  if (organizationInvoicesQuery.isLoading) {
    content = <EuiLoadingContent lines={3} />
  } else {
    content = (
      <React.Fragment>
        {isAgency && customerOptions.length > 1 && (
          <React.Fragment>
            <EuiFormRow label={'Customer'}>
              <EuiSelect options={customerOptions} value={selectedCustomerId} onChange={onCustomerChange} />
            </EuiFormRow>
            <EuiSpacer />
          </React.Fragment>
        )}
        <EuiInMemoryTable pagination={pagination} loading={organizationInvoicesQuery.isLoading} sorting={sorting} items={invoices} columns={columns} />
      </React.Fragment>
    )
  }

  return <div>{content}</div>
}

export default InvoicesDrawer
