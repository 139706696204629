import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import React, { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { EuiFilePicker, EuiLoadingContent } from '@elastic/eui'

import { apiGetInternetBuilderUpload } from 'api/adcritterApi'
import { scaleImage } from 'utils/imageUtils'

export interface UploadedImage {
  url: string
  fileName: string
  width: number
  height: number
  fileSize: number
}
export interface IAcImageUploader {
  maxFileSize?: number
  allowedFileTypes?: string[]
  onUploadedUrlsChange: (urls: UploadedImage[]) => void
  onIsValidFileChange: (isValidFile: boolean) => void
  allowMultiple?: boolean
  accountId: string
}

export const AcImageUploader: React.FC<IAcImageUploader> = props => {
  const filePickerRemoval = useRef<EuiFilePicker>(null)
  const [containerClient, setContainerClient] = useState<ContainerClient | null>(null)
  useEffect(() => {
    if (props.accountId) {
      apiGetInternetBuilderUpload(props.accountId).then(data => {
        const storageClient = new BlobServiceClient(data.data)
        const containerClient = storageClient.getContainerClient(props.accountId)
        setContainerClient(containerClient)
      })
    }
  }, [props.accountId])

  const onFileChange = (files: FileList | null) => {
    if (files && (props.allowMultiple || files.length === 1)) {
      if (props.allowedFileTypes) {
        if (!props.allowedFileTypes.includes(files[0].type)) {
          props.onIsValidFileChange(false)
          return
        }
      }

      const fileArray = Array.from(files)

      const promises: Promise<any>[] = []
      const uploadedFiles: UploadedImage[] = []
      fileArray.forEach(file => {
        const blockBlobClient = containerClient!.getBlockBlobClient(uuidv4() + '.' + file.name.split('.').pop())!
        const url = blockBlobClient!.url.split('?')[0]
        promises.push(
          new Promise(resolve => {
            scaleImage(file).then(resizedBlob => {
              if (!resizedBlob) {
                props.onIsValidFileChange(false)
                return
              }

              if (props.maxFileSize) {
                if (props.maxFileSize && resizedBlob.size > props.maxFileSize) {
                  props.onIsValidFileChange(false)
                  return
                }
              }

              blockBlobClient!.uploadData(resizedBlob, { concurrency: 20 }).then(() => {
                const img = new Image()
                img.src = url
                img.onload = function () {
                  const height = img.height
                  const width = img.width
                  uploadedFiles.push({
                    url: url,
                    fileName: file.name,
                    width: width,
                    height: height,
                    fileSize: resizedBlob.size
                  })
                  resolve('uploaded')
                }
              })
            })
          })
        )
      })

      Promise.allSettled(promises).then(() => {
        props.onUploadedUrlsChange(uploadedFiles)
        props.onIsValidFileChange(true)
      })
    }
  }

  if (!containerClient)
  {
    return <EuiLoadingContent />
  }

  return (
    <React.Fragment>
      <EuiFilePicker
        ref={filePickerRemoval}
        multiple
        fullWidth
        id='file'
        initialPromptText='Select or drag and drop your Display Ads'
        onChange={files => {
          onFileChange(files)
        }}
        display='large'
        aria-label='Select or drag and drop your files'
      />
    </React.Fragment>
  )
}
