import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useShopifyRegisterMutation } from 'api/rtkQueryApi/platform/shopifyApi'
import { LoggingUserInPage } from 'components/authentication/loggingUserInPage'

const ShopifyEntryPage = () => {
  const { search } = useLocation()
  const [registerShopifyUser, registerShopifyUserResponse] = useShopifyRegisterMutation()

  useEffect(() => {
    if (search) {
      registerShopifyUser({ query: search })
    }
  }, [search])

  useEffect(() => {
    if (registerShopifyUserResponse.data && !registerShopifyUserResponse.isLoading) {
      window.location.assign(registerShopifyUserResponse.data.redirectUrl)
    }
  }, [registerShopifyUserResponse])

  return <LoggingUserInPage />
}

export default ShopifyEntryPage
