import { SegmentTarget } from 'api/interfaces/Campaign'
import { CampaignDelivery } from 'api/interfaces/CampaignDelivery'
import { CityTarget, PostalCodeTarget, RegionTarget } from 'api/interfaces/Geo'

export interface CampaignTargeting {
  targetingType: TargetingType
  smartTargeting: SmartTargeting
  locationTargeting: LocationTargeting
  audienceTargeting: AudienceTargeting
  campaignDelivery: CampaignDelivery
}

export enum TargetingType {
  Audience = 'Audience',
  CustomAudience = 'CustomAudience',
  Location = 'Location',
  Smart = 'Smart',
  Retarget = 'Retarget'
}

export interface AudienceTargeting {
  demographics: IDemographics
  segments: SegmentTarget[]
  uploadedAudienceUrl?: string
  retargeting?: Retargeting
}

export interface Retargeting {
  retargetCampaignIds: string[]
  retargetWebsiteUrls: string[]
  entireSite: boolean
  specificPages: boolean
}

export enum LocationTargetingType {
  USA = 'Country',
  Region = 'Region',
  DMA = 'DMA',
  City = 'City',
  Zip = 'Zip',
  Map = 'Map',
  Address = 'Address',
  Uploaded = 'Uploaded'
}

export interface LocationTargeting {
  targetingType: LocationTargetingType
  regions: RegionTarget[]
  dmas: LocationDma[]
  cities: CityTarget[]
  zips: PostalCodeTarget[]
  geoCircles?: GeoCircle[]
  geoRectangles?: GeoRectangle[]
  geoPolygons?: GeoPolygon[]
  geoFences?: GeoFence[]
  addresses?: Address[]
  uploadedAudienceUrl?: string
}

export interface SmartTargeting {
  targetArea?: SmartTargeterAreaType
  streetAddress: string
  city: string
  state: string
  zip: string
  walkInTargeting: boolean
  walkInUsePrimary: boolean
  walkInAddresses: Address[]
  competitorTargeting: boolean
  competitors: Competitor[]
}

export enum SmartTargeterAreaType {
  Mile5 = '5',
  Mile20 = '20',
  Mile50 = '50',
  State = 'state',
  Country = 'country'
}

export interface IDemographics {
  ageRanges: string[]
  genders: string[]
  householdIncomes: string[]
}

export interface LocationCountry {
  id: number
  code: string
  name: string
}

export interface LocationRegion {
  id: number
  code: string
  name: string
}

export interface LocationDma {
  id: number
  name: string
}

export interface LocationCity {
  id: number
  name: string
  regionCode: string
}

export interface AddressAutoComplete {
  placeId: string
  description: string
}

export interface LocationGeoFence {
  placeId: string
  description: string
}

export interface LocationPostalCode {
  id: number
  code: string
  name: string
}

export interface PostalCodeList {
  id: number
  count: number
}

export interface Address {
  streetAddress: string
  city: string
  state: string
  zip: string
  country: string
}

export interface Competitor extends Address {
  name: string
}

export interface City {
  cityId: string
  name: string
  countryCode: string
  state: string
  stateCode: string
  population: number
  households: number
  yahooDspId: string
}

export interface Zip {
  zipId: string
  name: string
  countryCode: string
  yahooDspId: string
}

export interface GeoFence extends GeoPoint {
  placeId: string
  radiusUnits: string
  formattedAddress: string
  radius: number
  geocodedAddress: string
}

export interface GeoFenceAd {
  center: GeoPoint
  radius: number
  geocodedAddress: string
}

export interface GeoPoint {
  lat: number
  lng: number
}

export interface GeoCircle {
  id: string
  center: GeoPoint
  radius: number
}

export interface GeoRectangle extends GeoCircle {
  northEast: GeoPoint
  southWest: GeoPoint
}

export interface GeoPolygon extends GeoCircle {
  points: Array<GeoPoint>
}
