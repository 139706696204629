import React, { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { EuiFlexGroup, EuiFlexItem, EuiHideFor, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiSpacer, EuiStepsHorizontal, EuiTitle } from '@elastic/eui'
import { EuiStepHorizontalProps } from '@elastic/eui/src/components/steps/step_horizontal'

import { apiGetBusinessType } from 'api/adcritterApi'
import { showErrorToast } from 'app/appSlice'
import config from 'app/config'
import { RootState } from 'app/rootReducer'
import { ActivateShopifyCampaignStep } from 'features/builders/ActivateShopifyCampaignStep'
import { ActivateVendastaCampaignStep } from 'features/builders/ActivateVendastaCampaignStep'
import { ShopifyTargetingStep } from 'features/builders/ShopifyTargetingStep'
import { RetargetAdvancedStep } from 'features/builders/internet/retail/45_RetargetAdvancedStep'
import { fetchCampaigns } from 'features/campaigns/campaigns/campaignsSlice'
import history from 'services/HistoryService'
import { AcRequiredFields } from 'utils/AcRequiredFields'
import { CampaignType } from 'utils/CampaignType'

import StartStep from './10_StartStep'
import SuggestStep from './12_SuggestStep'
import QuestionnaireStep from './13_QuestionnaireStep'
import SuggestionsStep from './14_SuggestionsStep'
import CreateStep from './30_CreateStep'
import UploadStep from './31_UploadStep'
import TargetChooseStep from './40_TargetChooseStep'
import TargetingStandardStep from './41_TargetStandardStep'
import TargetAdvancedLocationStep from './42_TargetAdvancedLocationStep'
import TargetAdvancedAudienceStep from './43_TargetAdvancedAudienceStep'
import DeliverStep from './50_DeliverStep'
import ActivateStep from './99_ActivateStep'
import { BuilderStep, setBuilderStep, setBusinessType } from './builderInternetRetailSlice'

interface BuilderPageParams {
  step: string
}

const Page: React.FC = () => {
  const { step } = useParams<BuilderPageParams>()
  const dispatch = useDispatch()
  const builder = useSelector((state: RootState) => state.builderInternetRetail)
  const [content, setContent] = useState<ReactNode>()
  const { currentAccount, isVendastaUser, isShopifyUser } = useSelector((state: RootState) => state.app)

  const failureRedirect = () => {
    history.push('/build')
    dispatch(showErrorToast("Something didn't go as expected. Please try again."))
  }

  useEffect(() => {
    switch (step) {
      default:
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            setContent(<StartStep />)
            dispatch(setBuilderStep(BuilderStep.Start))
          },
          () => {
            history.push('campaigns')
          }
        )
        break
      case 'suggest':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            if (isShopifyUser) {
              setContent(<SuggestionsStep />)
              dispatch(setBuilderStep(BuilderStep.Suggestions))
            } else {
              setContent(<SuggestStep />)
              dispatch(setBuilderStep(BuilderStep.Suggest))
            }
          },
          failureRedirect
        )
        break
      case 'questionnaire':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            setContent(<QuestionnaireStep />)
            dispatch(setBuilderStep(BuilderStep.Questionnaire))
          },
          failureRedirect
        )
        break
      case 'suggestions':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            setContent(<SuggestionsStep />)
            dispatch(setBuilderStep(BuilderStep.Suggestions))
          },
          failureRedirect
        )
        break
      case 'create':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            setContent(<CreateStep />)
            dispatch(setBuilderStep(BuilderStep.Create))
          },
          failureRedirect
        )
        break
      case 'upload':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            if (config.features.uploadInternetAdEnabled) {
              setContent(<UploadStep />)
              dispatch(setBuilderStep(BuilderStep.Upload))
            }
          },
          failureRedirect
        )
        break
      case 'target':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            if (isShopifyUser) {
              setContent(<ShopifyTargetingStep campaignType={CampaignType.Internet} />)
              dispatch(setBuilderStep(BuilderStep.TargetAdvancedLocation))
            } else {
              setContent(<TargetChooseStep />)
              dispatch(setBuilderStep(BuilderStep.TargetChoose))
            }
          },
          failureRedirect
        )
        break
      case 'target-smart':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            if (isShopifyUser) {
              setContent(<ShopifyTargetingStep campaignType={CampaignType.Internet} />)
              dispatch(setBuilderStep(BuilderStep.TargetAdvancedLocation))
            } else {
              setContent(<TargetingStandardStep />)
              dispatch(setBuilderStep(BuilderStep.TargetStandard))
            }
          },
          failureRedirect
        )
        break
      case 'target-location':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            if (isShopifyUser) {
              setContent(<ShopifyTargetingStep campaignType={CampaignType.Internet} />)
              dispatch(setBuilderStep(BuilderStep.TargetAdvancedLocation))
            } else {
              setContent(<TargetAdvancedLocationStep />)
              dispatch(setBuilderStep(BuilderStep.TargetAdvancedLocation))
            }
          },
          failureRedirect
        )
        break
      case 'target-audience':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            if (isShopifyUser) {
              setContent(<ShopifyTargetingStep campaignType={CampaignType.Internet} />)
              dispatch(setBuilderStep(BuilderStep.TargetAdvancedLocation))
            } else {
              setContent(<TargetAdvancedAudienceStep />)
              dispatch(setBuilderStep(BuilderStep.TargetAdvancedAudience))
            }
          },
          failureRedirect
        )
        break
      case 'target-retarget':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            if (isShopifyUser) {
              setContent(<ShopifyTargetingStep campaignType={CampaignType.Internet} />)
              dispatch(setBuilderStep(BuilderStep.TargetAdvancedLocation))
            } else {
              setContent(<RetargetAdvancedStep />)
              dispatch(setBuilderStep(BuilderStep.TargetAdvancedRetarget))
            }
          },
          failureRedirect
        )
        break
      case 'deliver':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            setContent(<DeliverStep />)
            dispatch(setBuilderStep(BuilderStep.Deliver))
          },
          failureRedirect
        )
        break
      case 'activate':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            if (isVendastaUser) {
              setContent(<ActivateVendastaCampaignStep campaignType={CampaignType.Internet} />)
            } else if (isShopifyUser) {
              setContent(<ActivateShopifyCampaignStep campaignType={CampaignType.Internet} />)
            } else {
              setContent(<ActivateStep />)
            }
            dispatch(setBuilderStep(BuilderStep.Activate))
          },
          failureRedirect
        )
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, step])

  useEffect(() => {
    if (currentAccount && builder.builderStep === BuilderStep.Start) {
      if (currentAccount.businessTypeId) {
        dispatch(fetchCampaigns(currentAccount.id))
        apiGetBusinessType(currentAccount.businessTypeId).then(result => {
          dispatch(setBusinessType(result.data))
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, dispatch])

  const onCreateAdClick = () => {
    dispatch(setBuilderStep(BuilderStep.Start))
  }

  const onSetTargetingClick = () => {
    dispatch(setBuilderStep(BuilderStep.TargetChoose))
  }

  const onAdPlacementClick = () => {
    dispatch(setBuilderStep(BuilderStep.Deliver))
  }

  const wizardSteps: Array<EuiStepHorizontalProps> = [
    {
      title: 'Create',
      isSelected: builder.builderStep >= 10 && builder.builderStep < 33,
      isComplete: builder.builderStep >= 33,
      disabled: builder.builderStep < 10,
      onClick: onCreateAdClick
    },
    {
      title: 'Target',
      isSelected: builder.builderStep >= 40 && builder.builderStep < 50,
      isComplete: builder.builderStep >= 44,
      disabled: builder.builderStep < 20,
      onClick: onSetTargetingClick
    },
    {
      title: 'Deliver',
      isSelected: builder.builderStep >= 50,
      isComplete: builder.builderStep >= 51,
      disabled: builder.builderStep < 30,
      onClick: onAdPlacementClick
    }
  ]

  return (
    <React.Fragment>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle>
            <h1>New Internet Campaign</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>
      <EuiPanel grow={false} className='eui-xScroll'>
        <EuiHideFor sizes={['s']}>
          <EuiFlexGroup alignItems={'center'}>
            <EuiFlexItem>
              <EuiStepsHorizontal steps={wizardSteps} />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='m' />
        </EuiHideFor>
        {content}
      </EuiPanel>
    </React.Fragment>
  )
}

export default Page
