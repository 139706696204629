import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'

import { EuiButton, EuiCard, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import history from 'services/HistoryService'

import { startBuilder as startTvRetailBuilder } from './retail/builderTvRetailSlice'

const StartTvBuilderPage: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(startTvRetailBuilder())
  }, [dispatch])

  const setAdCritter = () => {
    dispatch(startTvRetailBuilder())
  }

  const gotoStart = () => {
    history.push('/build')
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Choose Your Builder</title>
      </Helmet>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle>
            <h1>New Campaign</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>
      <EuiPanel grow={false} className='eui-xScroll'>
        <EuiSpacer />
        <EuiFlexGroup>
          <EuiFlexItem grow={true} />
          <EuiFlexItem grow={false} style={{ width: 280 }}>
            <EuiCard icon={<EuiIcon size='xxl' type='wrench' color='success' />} title='AdCritter' description='Create. Target. Deliver' onClick={setAdCritter} footer={<EuiText size='s'>The full AdCritter Platform is available as part of your subscription.</EuiText>} />
          </EuiFlexItem>
          <EuiFlexItem grow={true} />
        </EuiFlexGroup>
        <EuiSpacer />
        <EuiFlexGroup responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={gotoStart} iconType='arrowLeft' iconSide='left'>
              Back
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    </React.Fragment>
  )
}

export default StartTvBuilderPage
