import { AuthResponse } from 'api/interfaces'
import { ShopifyConnect } from 'api/interfaces/Shopify'
import { defaultTransformFn, platformApi } from 'api/rtkQueryApi/platform/platformApi'

const shopifyTags = platformApi.enhanceEndpoints({ addTagTypes: ['PlanCampaignSlotsForAccount'] })

export const shopifyApi = shopifyTags.injectEndpoints({
  endpoints: build => {
    return {
      shopifyCreate: build.mutation<AuthResponse, { query: string }>({
        query: ({ query }) => ({
          url: `shopify/create`,
          method: 'POST',
          data: {
            query: query
          },
          isContext: false,
          isAgency: false
        }),
        transformResponse: defaultTransformFn
      }),
      shopifyRegister: build.mutation<ShopifyConnect, { query: string }>({
        query: ({ query }) => ({
          url: `shopify/register`,
          method: 'POST',
          data: {
            query: query
          },
          isContext: false,
          isAgency: false
        }),
        transformResponse: defaultTransformFn
      }),
      updateUsageCap: build.mutation<ShopifyConnect, { newCapAmount: number }>({
        query: request => ({
          url: `shopify/updateUsageCap`,
          method: 'POST',
          data: request,
          isContext: true,
          isAgency: false
        }),
        transformResponse: defaultTransformFn
      })
    }
  },
  overrideExisting: false
})

export const { useShopifyCreateMutation, useShopifyRegisterMutation, useUpdateUsageCapMutation } = shopifyApi
