import React from 'react'
import { useSelector } from 'react-redux'

import { EuiButtonEmpty, EuiButtonIcon, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSelect, EuiSpacer, EuiToolTip } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'

import { SmartTargeting } from 'api/interfaces/CampaignTargeting'
import { RootState } from 'app/rootReducer'

export interface IWalkInAddressesTargeter {
  smartTargeting: SmartTargeting
  setSmartTargeting: (smartTargeting: SmartTargeting) => void
}

export const WalkInAddressesTargeter: React.FC<IWalkInAddressesTargeter> = ({ smartTargeting, setSmartTargeting }) => {
  const dictionary = useSelector((state: RootState) => state.dictionary)

  return (
    <React.Fragment>
      {smartTargeting.walkInAddresses &&
        smartTargeting.walkInAddresses.map((walkInAddress, index) => (
          <EuiFlexGroup gutterSize='xs' key={index} style={{ marginLeft: -30, marginTop: 5, marginBottom: 5 }}>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup alignItems='center'>
                <EuiFlexItem grow={false}>
                  <EuiToolTip position='top' content='Remove this Location'>
                    <EuiButtonIcon
                      id='removeWalkInAddress'
                      iconType='cross'
                      aria-label='remove'
                      color='warning'
                      onClick={() => {
                        const updatedWalkInAddresses = [...smartTargeting.walkInAddresses]
                        updatedWalkInAddresses.splice(index, 1)
                        setSmartTargeting({ ...smartTargeting, walkInAddresses: updatedWalkInAddresses })
                      }}
                    />
                  </EuiToolTip>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label={index === 0 ? 'Street Address' : ''} fullWidth display='rowCompressed' isInvalid={!walkInAddress.streetAddress}>
                <EuiFieldText
                  fullWidth
                  value={walkInAddress.streetAddress}
                  onChange={e => {
                    const updatedWalkInAddresses = [...smartTargeting.walkInAddresses]
                    updatedWalkInAddresses[index].streetAddress = e.target.value
                    setSmartTargeting({ ...smartTargeting, walkInAddresses: updatedWalkInAddresses })
                  }}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label={index === 0 ? 'City' : ''} fullWidth display='rowCompressed' isInvalid={!walkInAddress.city}>
                <EuiFieldText
                  fullWidth
                  value={walkInAddress.city}
                  onChange={e => {
                    const updatedWalkInAddresses = [...smartTargeting.walkInAddresses]
                    updatedWalkInAddresses[index].city = e.target.value
                    setSmartTargeting({ ...smartTargeting, walkInAddresses: updatedWalkInAddresses })
                  }}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label={index === 0 ? 'State' : ''} display='rowCompressed' fullWidth isInvalid={!walkInAddress.state}>
                <EuiSelect
                  isLoading={dictionary.isLoadingStates}
                  options={dictionary.states.map(
                    s =>
                      ({
                        value: s.code,
                        label: s.name,
                        text: s.name
                      } as EuiSelectOption)
                  )}
                  value={walkInAddress.state}
                  onChange={e => {
                    const updatedWalkInAddresses = [...smartTargeting.walkInAddresses]
                    updatedWalkInAddresses[index].state = e.target.value
                    setSmartTargeting({ ...smartTargeting, walkInAddresses: updatedWalkInAddresses })
                  }}
                  fullWidth
                  hasNoInitialSelection={true}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label={index === 0 ? 'Zip Code' : ''} fullWidth display='rowCompressed' isInvalid={!walkInAddress.zip}>
                <EuiFieldText
                  fullWidth
                  value={walkInAddress.zip}
                  onChange={e => {
                    const updatedWalkInAddresses = [...smartTargeting.walkInAddresses]
                    updatedWalkInAddresses[index].zip = e.target.value
                    setSmartTargeting({ ...smartTargeting, walkInAddresses: updatedWalkInAddresses })
                  }}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
        ))}
      <EuiSpacer size='s' />
      <EuiButtonEmpty
        id='addWalkInAddress'
        size='s'
        onClick={() => {
          setSmartTargeting({ ...smartTargeting, walkInAddresses: [...smartTargeting.walkInAddresses, { streetAddress: '', city: '', state: '', zip: '', country: 'usa' }] })
        }}
        iconType='listAdd'>
        Add {smartTargeting.walkInAddresses.length === 0 ? 'New' : 'Another'} Location
      </EuiButtonEmpty>
    </React.Fragment>
  )
}
