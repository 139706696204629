import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { EuiButton, EuiCheckableCard, EuiCheckbox, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiHideFor, EuiShowFor, EuiSpacer, EuiText, EuiTitle, htmlIdGenerator } from '@elastic/eui'

import { FrequencyUnit, TvAdPlacementCategory } from 'api/interfaces'
import { CampaignDelivery } from 'api/interfaces/CampaignDelivery'
import { RootState } from 'app/rootReducer'
import { DefaultDayParts } from 'components/targeters/DayPartPicker'
import { ICampaignBuilderWorkflowStep } from 'features/builders/createCampaign/creativeBuilder/CreativeBuilderWorkflowManager'
import { useCampaignBuilderActions } from 'features/builders/createCampaign/useCampaignBuilderActions'
import { AdPlacementType } from 'features/builders/tv/retail/builderTvRetailSlice'

export const DeliveryTvPage: React.FC<ICampaignBuilderWorkflowStep> = props => {
  const { deliveryComplete } = useCampaignBuilderActions()
  const [deliveryDetails, setDeliveryDetails] = useState<CampaignDelivery>({
    dayParts: DefaultDayParts,
    frequency: 12,
    frequencyUnit: FrequencyUnit.week,
    inventorySettings: { targetApp: false, targetWeb: true },
    deviceSettings: { targetPc: true, targetPhone: true, targetTablet: true },
    placement: AdPlacementType.Everywhere,
    categories: [],
    specific: []
  })

  const {
    campaignTargeting: { campaignDelivery }
  } = useSelector((state: RootState) => state.campaignCreateWorkflow)

  useEffect(() => {
    if (campaignDelivery) {
      setDeliveryDetails(campaignDelivery)
    }
  }, [campaignDelivery])

  const gotoActivateStep = () => {
    deliveryComplete(deliveryDetails)
  }

  const gotoPrevious = () => {
    props.onBack?.()
  }

  const updateCategories = (isChecked: boolean, category: TvAdPlacementCategory) => {
    if (isChecked) {
      setDeliveryDetails({ ...deliveryDetails, categories: [...deliveryDetails.categories, category] })
    } else {
      setDeliveryDetails({ ...deliveryDetails, categories: deliveryDetails.categories.filter(c => c !== category) })
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Ad Placement</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Ad Placement</h3>
      </EuiTitle>
      <EuiText size='s'>
        <div>Prioritize where your commercials will run.</div>
      </EuiText>
      <EuiSpacer />
      <EuiCheckableCard
        id={htmlIdGenerator()()}
        label={<strong>Prioritize Highest Performing Networks</strong>}
        name={'placement'}
        checked={deliveryDetails.placement === AdPlacementType.Everywhere}
        onChange={() => {
          setDeliveryDetails({ ...deliveryDetails, placement: AdPlacementType.Everywhere })
        }}>
        <EuiText size='s' color={deliveryDetails.placement !== AdPlacementType.Everywhere ? 'subdued' : 'primary'}>
          Prioritizes the best performing networks for your target audience. Automatically self-optimizes for best results.
        </EuiText>
      </EuiCheckableCard>
      <EuiSpacer size='m' />
      <EuiCheckableCard
        className='specificCategories'
        id={htmlIdGenerator()()}
        label={<strong>Prioritize Specific Categories</strong>}
        name={'placement'}
        checked={deliveryDetails.placement === AdPlacementType.Categories}
        onChange={() => {
          setDeliveryDetails({ ...deliveryDetails, placement: AdPlacementType.Categories, categories: [TvAdPlacementCategory.Major, TvAdPlacementCategory.Sports, TvAdPlacementCategory.Lifestyle, TvAdPlacementCategory.Entertainment, TvAdPlacementCategory.Family, TvAdPlacementCategory.News] })
        }}>
        <EuiFlexGrid columns={3} gutterSize='s'>
          <EuiFlexItem>
            <EuiCheckbox
              id={htmlIdGenerator()()}
              onChange={e => {
                updateCategories(e.target.checked, TvAdPlacementCategory.Major)
              }}
              disabled={deliveryDetails.placement !== AdPlacementType.Categories}
              checked={deliveryDetails.categories.filter(c => c === TvAdPlacementCategory.Major).length > 0}
              label='Major Networks'
            />
            <EuiText size='xs' style={{ marginLeft: 25 }} color={deliveryDetails.placement !== AdPlacementType.Categories ? 'subdued' : 'primary'}>
              Includes NBC, ABC, ESPN, Fox, MSNBC and more...
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCheckbox
              id={htmlIdGenerator()()}
              onChange={e => {
                updateCategories(e.target.checked, TvAdPlacementCategory.Sports)
              }}
              disabled={deliveryDetails.placement !== AdPlacementType.Categories}
              checked={deliveryDetails.categories.filter(c => c === TvAdPlacementCategory.Sports).length > 0}
              label='Sports Networks'
            />
            <EuiText size='xs' style={{ marginLeft: 25 }} color={deliveryDetails.placement !== AdPlacementType.Categories ? 'subdued' : 'primary'}>
              Includes ESPN, NBCSN, Fox Sports, ACC, MLB, NBA, NFL, Big Ten and more...
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCheckbox
              id={htmlIdGenerator()()}
              onChange={e => {
                updateCategories(e.target.checked, TvAdPlacementCategory.Lifestyle)
              }}
              disabled={deliveryDetails.placement !== AdPlacementType.Categories}
              checked={deliveryDetails.categories.filter(c => c === TvAdPlacementCategory.Lifestyle).length > 0}
              label='Lifestyle Networks'
            />
            <EuiText size='xs' style={{ marginLeft: 25 }} color={deliveryDetails.placement !== AdPlacementType.Categories ? 'subdued' : 'primary'}>
              Includes HGTV, Food Network, DIY, Cooking Channel, Travel Channel and more...
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCheckbox
              id={htmlIdGenerator()()}
              onChange={e => {
                updateCategories(e.target.checked, TvAdPlacementCategory.Entertainment)
              }}
              disabled={deliveryDetails.placement !== AdPlacementType.Categories}
              checked={deliveryDetails.categories.filter(c => c === TvAdPlacementCategory.Entertainment).length > 0}
              label='Entertainment Networks'
            />
            <EuiText size='xs' style={{ marginLeft: 25 }} color={deliveryDetails.placement !== AdPlacementType.Categories ? 'subdued' : 'primary'}>
              Includes A&E, Lifetime, TLC, E! Entertainment, Bravo, Ion Television, Oxygen, USA Network and more...
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCheckbox
              id={htmlIdGenerator()()}
              onChange={e => {
                updateCategories(e.target.checked, TvAdPlacementCategory.Family)
              }}
              disabled={deliveryDetails.placement !== AdPlacementType.Categories}
              checked={deliveryDetails.categories.filter(c => c === TvAdPlacementCategory.Family).length > 0}
              label='Family Friendly Networks'
            />
            <EuiText size='xs' style={{ marginLeft: 25 }} color={deliveryDetails.placement !== AdPlacementType.Categories ? 'subdued' : 'primary'}>
              Includes Discovery, Animal Planet, National Geographic, History Channel and more...
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCheckbox
              id={htmlIdGenerator()()}
              onChange={e => {
                updateCategories(e.target.checked, TvAdPlacementCategory.News)
              }}
              disabled={deliveryDetails.placement !== AdPlacementType.Categories}
              checked={deliveryDetails.categories.filter(c => c === TvAdPlacementCategory.News).length > 0}
              label='News Networks'
            />
            <EuiText size='xs' style={{ marginLeft: 25 }} color={deliveryDetails.placement !== AdPlacementType.Categories ? 'subdued' : 'primary'}>
              Includes Bloomberg Television, CNBC, Fox Business, MSNBC, Fox News, MSN, Weather Channel and more...
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGrid>
      </EuiCheckableCard>
      <EuiSpacer />

      <EuiShowFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='reviewAndFinalizeBtn' fill onClick={gotoActivateStep}>
              Review &amp; Finalize
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={gotoPrevious}>
              Back
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={gotoPrevious} iconType='arrowLeft' iconSide='left'>
              Back
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='reviewAndFinalizeBtn' fill onClick={gotoActivateStep} iconType='arrowRight' iconSide='right'>
              Review &amp; Finalize
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiHideFor>
    </React.Fragment>
  )
}
