import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { AdvancedTargeting, LocationTarget, ThirdPartyAudience } from 'api/interfaces'
import { useGetBusinessTypeSuggestedAudiencesQuery } from 'api/rtkQueryApi/platform/businessTypesApi'
import { RootState } from 'app/rootReducer'
import { AudienceFinder } from 'components/finders'
import { AgeRangeTargeter, GenderTargeter } from 'components/targeters'
import { AudienceTargeterDetailed } from 'components/targeters/AudienceTargeterDetailed'
import { DefaultDayParts } from 'components/targeters/DayPartPicker'
import { GeoSelectionTargeter, LocationType } from 'components/targeters/GeoSelectionTargeter'
import { TargetingType } from 'definitions/BuilderDefinitions'
import { AdvancedTargetingType, setAdvancedTargeting, setTargetingType } from 'features/builders/internet/retail/builderInternetRetailSlice'
import { setTargeting, Targeting } from 'features/builders/tv/retail/builderTvRetailSlice'
import history from 'services/HistoryService'
import { CampaignType } from 'utils/CampaignType'

export interface IShopifyTargetingStep {
  campaignType: CampaignType
}

export const ShopifyTargetingStep: React.FC<IShopifyTargetingStep> = ({ campaignType }) => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const { businessType } = useSelector((state: RootState) => state.builderTvRetail)
  const suggestedAudienceSegmentsRequest = useGetBusinessTypeSuggestedAudiencesQuery({ businessTypeId: businessType?.id ?? '' }, { skip: !businessType?.id })
  const [selectedLocationType, setSelectedLocationType] = useState<LocationType>(LocationType.USA)
  const [locationTargeting, setLocationTargeting] = useState<LocationTarget>()
  const [selectedAgeRanges, setSelectedAgeRanges] = useState<string[]>(['all'])
  const [selectedGenders, setSelectedGenders] = useState<string[]>(['all'])
  const [selectedAudiences, setSelectedAudiences] = useState<ThirdPartyAudience[]>([])
  const [inspirationalAudiences, setInspirationalAudiences] = useState<ThirdPartyAudience[]>([])
  const internetBuilder = useSelector((state: RootState) => state.builderInternetRetail)
  const tvBuilder = useSelector((state: RootState) => state.builderTvRetail)

  useEffect(() => {
    if (campaignType == CampaignType.TV) {
      if ((suggestedAudienceSegmentsRequest.data?.tvAudiences?.length ?? 0) > 0) {
        setSelectedAudiences([...selectedAudiences, ...suggestedAudienceSegmentsRequest.data!.tvAudiences].filter((a, index, arr) => arr.findIndex(ia => ia.id === a.id) === index))
      }
      if ((suggestedAudienceSegmentsRequest.data?.tvInspirationalAudiences?.length ?? 0) > 0) {
        setInspirationalAudiences(suggestedAudienceSegmentsRequest.data!.tvInspirationalAudiences)
      }
    } else if (campaignType == CampaignType.Internet) {
      if ((suggestedAudienceSegmentsRequest.data?.internetAudiences?.length ?? 0) > 0) {
        setSelectedAudiences([...selectedAudiences, ...suggestedAudienceSegmentsRequest.data!.internetAudiences].filter((a, index, arr) => arr.findIndex(ia => ia.id === a.id) === index))
      }
      if ((suggestedAudienceSegmentsRequest.data?.internetInspirationalAudiences?.length ?? 0) > 0) {
        setInspirationalAudiences(suggestedAudienceSegmentsRequest.data!.internetInspirationalAudiences)
      }
    }
  }, [suggestedAudienceSegmentsRequest.data])

  const onBackClick = () => {
    switch (campaignType) {
      case CampaignType.TV:
        if (tvBuilder.builderStyle === 'Upload') {
          history.push('/build/tv/public/upload')
        } else {
          history.push('/build/tv/public/create')
        }
        break
      case CampaignType.Billboard:
        history.push('/build/billboards/public/target')
        break
      case CampaignType.Internet:
        history.push('/build/internet/public/create')
        break
    }
  }

  const onActivateClick = () => {
    if (!!currentAccount?.id) {
      switch (campaignType) {
        case CampaignType.TV:
          const tvTargeting: Targeting = {
            locationType: selectedLocationType,
            locations: locationTargeting ?? {
              countries: [],
              cities: [],
              dmas: [],
              postalCodes: [],
              regions: []
            },
            cities: [],
            dmas: [],
            zips: [],
            states: [],
            segments: [],
            ageRanges: selectedAgeRanges,
            genders: selectedGenders,
            householdIncomes: [],
            audiences: selectedAudiences,
            frequency: null,
            frequencyUnit: null,
            dayParts: DefaultDayParts
          }
          dispatch(setTargeting(tvTargeting))
          history.push('/build/tv/public/deliver')
          break
        case CampaignType.Billboard:
          history.push('/build/billboards/public/target')
          break
        case CampaignType.Internet:
          const internetTargeting: AdvancedTargeting = {
            ...internetBuilder.advancedTargeting!,
            advancedTargetingType: AdvancedTargetingType.Location,
            locationType: selectedLocationType,
            locations: locationTargeting ?? {
              countries: [],
              cities: [],
              dmas: [],
              postalCodes: [],
              regions: []
            },
            cities: [],
            dmas: [],
            zips: [],
            states: [],
            ageRanges: selectedAgeRanges,
            genders: selectedGenders,
            householdIncomes: [],
            audiences: selectedAudiences,
            dayParts: DefaultDayParts
          }
          dispatch(setAdvancedTargeting(internetTargeting))
          dispatch(setTargetingType(TargetingType.Advanced))
          history.push('/build/internet/public/deliver')
          break
      }
    }
  }

  const onSelectedLocationTypeChange = (locationType: LocationType) => {
    setSelectedLocationType(locationType)
  }

  const onGeoTargetingChange = (targeting: LocationTarget) => {
    setLocationTargeting(targeting)
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Now lets define your ideal customer</title>
      </Helmet>
      <EuiTitle size='s'>
        <h2>Now lets define your ideal customer</h2>
      </EuiTitle>
      <EuiSpacer size='m' />
      <EuiFormRow fullWidth>
        <EuiText>In addition to targeting based on your Shopify Store, AdCritter will help you find new customers based on their individual characteristics and demographic profiles. Simply begin typing characteristics and select as many as you like. Then, if your store only sells to specific ages or income levels, select them in the Demographic section.</EuiText>
      </EuiFormRow>
      <EuiSpacer />
      <EuiFormRow fullWidth>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size='s'>
              <span>Demographics</span>
            </EuiTitle>
            <EuiSpacer size='s' />
            <EuiFormRow label='Age ranges' fullWidth>
              <AgeRangeTargeter
                ageRanges={selectedAgeRanges}
                setAgeRanges={ageRanges => {
                  setSelectedAgeRanges(ageRanges)
                }}
              />
            </EuiFormRow>
            <EuiSpacer size='s' />
            <EuiFormRow label='Gender' fullWidth>
              <GenderTargeter
                genders={selectedGenders}
                setGenders={genders => {
                  setSelectedGenders(genders)
                }}
              />
            </EuiFormRow>
            <EuiSpacer />
            <EuiFormRow fullWidth>
              <EuiTitle size='s'>
                <span>Geographic Prioritization</span>
              </EuiTitle>
            </EuiFormRow>
            <EuiFormRow fullWidth>
              <GeoSelectionTargeter hideLocationTypes={[LocationType.Map, LocationType.Address, LocationType.Zip]} selectedLocationType={selectedLocationType} onSelectedTargetingTypeChange={onSelectedLocationTypeChange} onTargetingUpdated={onGeoTargetingChange} />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiTitle size='s'>
              <span>Audience Segments</span>
            </EuiTitle>
            <EuiSpacer size='s' />
            <EuiFormRow label='Search for individual characteristics to refine your ideal audience' fullWidth>
              <AudienceFinder
                onAudienceClicked={audience => {
                  setSelectedAudiences([...selectedAudiences, audience])
                }}
                isInvalid={false}
              />
            </EuiFormRow>
            <EuiFormRow label='Your campaign will prioritize individuals in any of these categories' fullWidth>
              <AudienceTargeterDetailed
                audiences={selectedAudiences}
                onAudienceRemoved={audience => {
                  setSelectedAudiences([...selectedAudiences.filter(a => a.id !== audience.id)])
                }}
              />
            </EuiFormRow>
            {inspirationalAudiences?.length > 0 && (
              <EuiFormRow label='Audience can get pretty specific, here are some examples:' fullWidth>
                <AudienceTargeterDetailed
                  audiences={inspirationalAudiences}
                  itemIcon={'sortUp'}
                  onAudienceRemoved={audience => {
                    setInspirationalAudiences([...inspirationalAudiences.filter(a => a.id !== audience.id)])
                    setSelectedAudiences([...selectedAudiences, audience])
                  }}
                />
              </EuiFormRow>
            )}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiSpacer size={'xl'} />
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton id='back' fill color='text' iconType='arrowLeft' iconSide='left' onClick={onBackClick}>
            Back
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton id='activate' fill iconType='arrowRight' onClick={onActivateClick}>
            Continue
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}
