const dev = {
  api: {
    BASEURL: 'https://localhost:5030/'
  },
  apxl: {
    BASEURL: 'http://localhost:5000/'
  },
  stripe: {
    PUBLISHABLEKEY: 'pk_test_r8aqvoMgb3iB6S54PPbatw0B'
  },
  gtag: {
    ANALYTICSID: undefined,
    ADWORDSID: undefined
  },
  ai: {
    INSTRUMENTATION_KEY: '46f3a057-04e6-47fc-b9ae-ea5b5796db21'
  },
  bugsnag: {
    APIKEY: '650d51d5705728da7a28732d055db243',
    RELEASE_STAGE: 'development'
  },
  microsoftAdvertising: {
    TAG_ID: '134002212'
  },
  clarity: {
    PROJECT: undefined
  },
  facebook: {
    PIXEL_ID: undefined
  },
  linkedin: {
    PARTNER_ID: undefined,
    PURCHASE_CONVERSION_ID: undefined,
    VIEW_CONTENT_CONVERSION_ID: undefined,
    BEGIN_CHECKOUT_CONVERSION_ID: undefined,
    ADD_TO_CART_CONVERSION_ID: undefined
  },
  modernIoPostcard: {
    PIXEL_ID: ''
  },
  BASEURL: 'https://adcritter-platform.ngrok.io/',
  VIDEOURL: 'https://devopenshotstorage.blob.core.windows.net/',
  signupUrl: 'http://localhost:8080/signup/',
  features: {
    chatEnabled: true,
    billboardSuggestionsEnabled: true,
    uploadInternetAdEnabled: true,
    customBillboardsEnabled: true,
    mathsQuestionEnabled: false
  }
}

const stage = {
  api: {
    BASEURL: 'https://platform-api.stage.adcritter.com/'
  },
  apxl: {
    BASEURL: ''
  },
  stripe: {
    PUBLISHABLEKEY: 'pk_test_r8aqvoMgb3iB6S54PPbatw0B'
  },
  gtag: {
    ANALYTICSID: undefined,
    ADWORDSID: undefined
  },
  ai: {
    INSTRUMENTATION_KEY: '9be623e4-808c-42cf-9870-c8283229b341'
  },
  bugsnag: {
    APIKEY: '650d51d5705728da7a28732d055db243',
    RELEASE_STAGE: 'staging'
  },
  microsoftAdvertising: {
    TAG_ID: undefined
  },
  clarity: {
    PROJECT: undefined
  },
  facebook: {
    PIXEL_ID: undefined
  },
  linkedin: {
    PARTNER_ID: undefined,
    PURCHASE_CONVERSION_ID: undefined,
    VIEW_CONTENT_CONVERSION_ID: undefined,
    BEGIN_CHECKOUT_CONVERSION_ID: undefined,
    ADD_TO_CART_CONVERSION_ID: undefined
  },
  modernIoPostcard: {
    PIXEL_ID: ''
  },
  BASEURL: 'https://platform.stage.adcritter.com/',
  VIDEOURL: 'https://files.stage.adcritter.com/',
  signupUrl: 'https://accstageweb-dotcom.azurewebsites.net/signup',
  features: {
    chatEnabled: false,
    billboardSuggestionsEnabled: true,
    uploadInternetAdEnabled: true,
    customBillboardsEnabled: false,
    mathsQuestionEnabled: false
  }
}

const prod = {
  api: {
    BASEURL: 'https://platform-api.adcritter.com/'
  },
  apxl: {
    BASEURL: 'https://apxl.io/'
  },
  stripe: {
    PUBLISHABLEKEY: 'pk_live_IBsLniZKbA5vruudkfBGt99s'
  },
  gtag: {
    ANALYTICSID: 'G-EYJXSVNQD7',
    ADWORDSID: 'AW-732436947'
  },
  ai: {
    INSTRUMENTATION_KEY: '1b912b48-e1ab-4c1d-a78d-83db8831c24d'
  },
  bugsnag: {
    APIKEY: '650d51d5705728da7a28732d055db243',
    RELEASE_STAGE: 'production'
  },
  microsoftAdvertising: {
    TAG_ID: '134002212'
  },
  clarity: {
    PROJECT: 'ffr6m9xvm8'
  },
  facebook: {
    PIXEL_ID: '303632057212442'
  },
  linkedin: {
    PARTNER_ID: '196793',
    PURCHASE_CONVERSION_ID: '13717620',
    VIEW_CONTENT_CONVERSION_ID: '15117793',
    BEGIN_CHECKOUT_CONVERSION_ID: '18593385',
    ADD_TO_CART_CONVERSION_ID: '18593377'
  },
  modernIoPostcard: {
    PIXEL_ID: 'E3CFA25F605B2C'
  },
  BASEURL: 'https://platform.adcritter.com/',
  VIDEOURL: 'https://files.adcritter.com/',
  signupUrl: 'https://adcritter.com/signup',
  features: {
    chatEnabled: true,
    billboardSuggestionsEnabled: false,
    uploadInternetAdEnabled: true,
    customBillboardsEnabled: false,
    mathsQuestionEnabled: true
  }
}

let config

switch (process.env.REACT_APP_ENV) {
  case 'Staging':
    config = stage
    break

  case 'Development':
    config = dev
    break

  default:
    config = prod
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  TIMEOUT: 1500,
  googleMaps: {
    APIKEY: 'AIzaSyBJ-T89Z2B1MH6p4sk_zScWx1mtcWuv3wI'
  },
  ...config
}
