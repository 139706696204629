import React, { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { EuiFlexGroup, EuiFlexItem, EuiHideFor, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiSpacer, EuiStepsHorizontal, EuiTitle } from '@elastic/eui'
import { EuiStepHorizontalProps } from '@elastic/eui/src/components/steps/step_horizontal'

import { showErrorToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { ActivateShopifyCampaignStep } from 'features/builders/ActivateShopifyCampaignStep'
import { ActivateVendastaCampaignStep } from 'features/builders/ActivateVendastaCampaignStep'
import { ShopifyTargetingStep } from 'features/builders/ShopifyTargetingStep'
import TargetStep from 'features/builders/tv/retail/40_TargetStep'
import ShopifySuggestionsStep from 'features/builders/tv/shopify/23_ShopifySuggestionsStep'
import history from 'services/HistoryService'
import { AcRequiredFields } from 'utils/AcRequiredFields'
import { CampaignType } from 'utils/CampaignType'

import StartStep from './10_StartStep'
import SuggestStep from './20_SuggestStep'
import QuestionnaireStep from './21_QuestionnaireStep'
import QuestionnaireDoneStep from './22_QuestionnaireDoneStep'
import SuggestionsStep from './23_SuggestionsStep'
import CreateStep from './30_CreateStep'
import UploadStep from './31_UploadStep'
import DeliverStep from './50_DeliverStep'
import ActivateStep from './99_ActivateStep'
import { BuilderStep, setBuilderStep } from './builderTvRetailSlice'

interface BuilderPageParams {
  step: string
}

const Page: React.FC = () => {
  const { step } = useParams<BuilderPageParams>()
  const dispatch = useDispatch()
  const builder = useSelector((state: RootState) => state.builderTvRetail)
  const [content, setContent] = useState<ReactNode>(<StartStep />)
  const { currentAccount, isVendastaUser, isShopifyUser } = useSelector((state: RootState) => state.app)

  useEffect(() => {
    const failureRedirect = () => {
      history.push('/build')
      dispatch(showErrorToast("Something didn't go as expected. Please try again."))
    }

    switch (step) {
      default:
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            setContent(<StartStep />)
            dispatch(setBuilderStep(BuilderStep.Start))
          },
          failureRedirect
        )
        break
      case 'suggest':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            if (isShopifyUser) {
              setContent(<ShopifySuggestionsStep />)
            } else {
              setContent(<SuggestStep />)
            }
            dispatch(setBuilderStep(BuilderStep.Suggest))
          },
          failureRedirect
        )
        break
      case 'questionnaire':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            setContent(<QuestionnaireStep />)
            dispatch(setBuilderStep(BuilderStep.Questionnaire))
          },
          failureRedirect
        )
        break
      case 'done':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            setContent(<QuestionnaireDoneStep />)
            dispatch(setBuilderStep(BuilderStep.QuestionnaireDone))
          },
          failureRedirect
        )
        break
      case 'suggestions':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            if (isShopifyUser) {
              setContent(<ShopifySuggestionsStep />)
            } else {
              setContent(<SuggestionsStep />)
            }
            dispatch(setBuilderStep(BuilderStep.Suggestions))
          },
          failureRedirect
        )
        break
      case 'create':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            setContent(<CreateStep />)
            dispatch(setBuilderStep(BuilderStep.Create))
          },
          failureRedirect
        )
        break
      case 'upload':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            setContent(<UploadStep />)
            dispatch(setBuilderStep(BuilderStep.Upload))
          },
          failureRedirect
        )
        break
      case 'target':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            if (isShopifyUser) {
              setContent(<ShopifyTargetingStep campaignType={CampaignType.TV} />)
            } else {
              setContent(<TargetStep />)
            }
            dispatch(setBuilderStep(BuilderStep.Target))
          },
          failureRedirect
        )
        break
      case 'deliver':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            setContent(<DeliverStep />)
            dispatch(setBuilderStep(BuilderStep.Deliver))
          },
          failureRedirect
        )
        break
      case 'activate':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            if (isVendastaUser) {
              setContent(<ActivateVendastaCampaignStep campaignType={CampaignType.TV} />)
            } else if (isShopifyUser) {
              setContent(<ActivateShopifyCampaignStep campaignType={CampaignType.TV} />)
            } else {
              setContent(<ActivateStep />)
            }
            dispatch(setBuilderStep(BuilderStep.Activate))
          },
          failureRedirect
        )
        break
    }
  }, [step, dispatch, currentAccount])

  const onCreateClick = () => {
    dispatch(setBuilderStep(BuilderStep.Start))
  }

  const onTargetClick = () => {
    dispatch(setBuilderStep(BuilderStep.Target))
  }

  const onDeliverClick = () => {
    dispatch(setBuilderStep(BuilderStep.Deliver))
  }

  const wizardSteps: Array<EuiStepHorizontalProps> = [
    {
      title: 'Create',
      isSelected: builder.builderStep >= 10 && builder.builderStep < 40,
      isComplete: builder.builderStep >= 40,
      disabled: builder.builderStep < 10,
      onClick: onCreateClick
    },
    {
      title: 'Target',
      isSelected: builder.builderStep >= 40 && builder.builderStep < 50,
      isComplete: builder.builderStep >= 50,
      disabled: builder.builderStep < 40,
      onClick: onTargetClick
    },
    {
      title: 'Deliver',
      isSelected: builder.builderStep >= 50,
      isComplete: builder.builderStep >= 99,
      disabled: builder.builderStep < 50,
      onClick: onDeliverClick
    }
  ]

  return (
    <React.Fragment>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle>
            <h1>New Targeted TV Campaign</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>
      <EuiPanel grow={false} className='eui-xScroll'>
        <EuiHideFor sizes={['s']}>
          <EuiFlexGroup alignItems={'center'}>
            <EuiFlexItem>
              <EuiStepsHorizontal steps={wizardSteps} />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='m' />
        </EuiHideFor>
        {content}
      </EuiPanel>
    </React.Fragment>
  )
}

export default Page
