import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { EuiCodeBlock, EuiPage, EuiPageBody, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiText, EuiTitle } from '@elastic/eui'

import { RootState } from 'app/rootReducer'

export const AudiencePixelPage: React.FC = () => {
  const { currentAccount } = useSelector((state: RootState) => state.app)

  let content
  if (currentAccount == null) {
    content = <div></div>
  } else {
    content = (
      <EuiPage className='euiPage--platform'>
        <EuiPageBody>
          <EuiPageHeader>
            <EuiPageHeaderSection>
              <EuiTitle>
                <h1>Audience Pixel</h1>
              </EuiTitle>
            </EuiPageHeaderSection>
          </EuiPageHeader>
          <EuiPanel grow={false}>
            <EuiText>
              <p>Placing the audience pixel on your website (and/or landing pages) enables more advanced audience analytics across all your campaigns and provides additional targeting methods.</p>

              <h3>Audience Pixel Code</h3>
              <p>Place the code below on every page of your website.</p>
              <EuiCodeBlock language='html' fontSize='m' paddingSize='m' color='dark' isCopyable>{`<script src="https://apxl.io/script.js?id=${currentAccount.id}" />`}</EuiCodeBlock>
            </EuiText>
          </EuiPanel>
        </EuiPageBody>
      </EuiPage>
    )
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Audience Pixel</title>
      </Helmet>
      {content}
    </React.Fragment>
  )
}

export default AudiencePixelPage
