import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { EuiPageBody, EuiPanel, EuiButton, EuiCard, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiLink, EuiSpacer, EuiTitle, EuiPageHeader, EuiPageHeaderSection } from '@elastic/eui'

import { apiGetBusinessType, apiPutAccountBusinessType } from 'api/adcritterApi'
import { BusinessType } from 'api/interfaces'
import { updateAccountState } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { BusinessTypeFinder } from 'components/finders/BusinessTypeFinder'
import { PremierBusinessTypeOptions } from 'components/shopify/PremierBusinessTypeOptions'
import history from 'services/HistoryService'

export const ShopifyBusinessTypeSelector: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [chosenBusinessType, setChosenBusinessType] = useState<BusinessType | null>(null)
  const [businessTypeError, setBusinessTypeError] = useState(false)
  const [isLoadingBusinessType, setIsLoadingBusinessType] = useState(false)

  useEffect(() => {
    if (currentAccount) {
      if (currentAccount.businessTypeId) {
        setIsLoadingBusinessType(true)
        apiGetBusinessType(currentAccount.businessTypeId).then(() => {
          setIsLoadingBusinessType(false)
        })
      } else {
        setChosenBusinessType(null)
      }
    }
  }, [currentAccount])

  const onContinueClick = () => {
    if (!chosenBusinessType) {
      setBusinessTypeError(true)
      return
    }
    if (!currentAccount!.businessTypeId) apiPutAccountBusinessType(currentAccount!.id, chosenBusinessType!.id).then(result => dispatch(updateAccountState(result.data)))
    history.push('/build')
  }

  const gotoStart = () => {
    history.push('/campaigns')
  }

  const chooseBusinessType = (businessType: BusinessType | null) => {
    if (businessType) {
      setChosenBusinessType(businessType)
      setBusinessTypeError(false)
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Campaign Business Type</title>
      </Helmet>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle>
            <h1>Business Type Selector</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>

      <EuiPageBody>
        <EuiPanel grow={false} className='eui-xScroll'>
          <EuiTitle size={'xs'}>
            <h1>What is your best selling product category?</h1>
          </EuiTitle>
          <EuiSpacer />
          <PremierBusinessTypeOptions onBusinessTypeSelected={chooseBusinessType} />
          <EuiSpacer />
          <div id='bizTypeField'>
            <EuiFormRow label='Business Type' fullWidth isInvalid={businessTypeError}>
              <React.Fragment>
                {chosenBusinessType && (
                  <React.Fragment>
                    <EuiCard
                      display='primary'
                      layout='horizontal'
                      paddingSize='s'
                      titleSize='xs'
                      icon={
                        <div>
                          <img alt='' src={chosenBusinessType.imageUrl} />
                        </div>
                      }
                      title={chosenBusinessType.name}
                      description={chosenBusinessType.businessTypeCategories.join(', ')}
                    />
                    <EuiSpacer size='s' />
                    <EuiLink color='success' onClick={() => setChosenBusinessType(null)}>
                      Clear selected business type
                    </EuiLink>
                  </React.Fragment>
                )}
                {!chosenBusinessType && <BusinessTypeFinder onBusinessTypeClicked={chooseBusinessType} isInvalid={businessTypeError} searchPlaceHolderText={'Search for your product category...'} />}
              </React.Fragment>
            </EuiFormRow>
            <EuiSpacer size='s' />
          </div>
          <EuiSpacer />

          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton id='back' fill type='button' color='text' onClick={gotoStart} iconType='arrowLeft' iconSide='left'>
                Back
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton id='continue' fill type='submit' isDisabled={isLoadingBusinessType} iconType='arrowRight' iconSide='right' onClick={onContinueClick}>
                Continue
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiPageBody>
    </React.Fragment>
  )
}
