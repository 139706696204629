export class TestCase {
  public validate: (userInput: string) => boolean
  public name: string
  public description: string

  constructor(testFunction: (userInput: string) => boolean, name: string, description?: string) {
    this.validate = testFunction
    this.name = name
    this.description = description ?? ''
  }
}

export interface IValidatorResult {
  Name: string
  DidPass: boolean
  Description: string
}

export class Validator {
  public testCases: TestCase[]
  private lastResults: IValidatorResult[]

  constructor(testCases: TestCase[]) {
    this.lastResults = []
    this.testCases = testCases
  }

  public getLastResults = () => this.lastResults
  public lastResultsPassed = () => this.lastResults.every(r => r.DidPass)
  public validate = (userInput: string) => {
    this.lastResults = []
    for (let test of this.testCases) {
      this.lastResults.push({ Name: test.name, DidPass: test.validate(userInput), Description: test.description })
    }
  }
}
