import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from '@elastic/eui'

import { AcImage } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import { resetSearchImage } from 'components/chooseImage/searchImageSlice'
import InternetSuggestedAds from 'components/suggestedAds/InternetSuggestedAds'
import { clearSuggestions as clearGeneralSuggestions, fetchImages as fetchGeneralImages, fetchSuggestions as fetchGeneralSuggestions } from 'components/suggestedAds/generalSuggestionSlice'
import history from 'services/HistoryService'

import { setAd, setImage } from './createAdInternetRetailSlice'

const SuggestionsStep: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const createAd = useSelector((state: RootState) => state.createAdInternetRetail)
  const [companyName, setCompanyName] = useState(createAd.suggest!.companyName)

  const proceedWithSuggestion = (image: AcImage, headline: string, body: string) => {
    const ad = {
      id: '',
      headline: headline,
      body: body,
      companyName: createAd.suggest!.companyName,
      callToAction: 'Learn More',
      destinationUrl: currentAccount!.website || ''
    }
    dispatch(setImage(image))
    dispatch(setAd(ad))
    history.push('/campaigns/internet/public/create')
  }

  useEffect(() => {
    if (currentAccount && createAd.businessType && createAd.suggest) {
      dispatch(clearGeneralSuggestions())
      dispatch(fetchGeneralSuggestions(currentAccount.id, createAd.businessType.id, createAd.suggest.companyName, createAd.suggest.city))
      dispatch(fetchGeneralImages(createAd.businessType.id))
      setCompanyName(createAd.suggest!.companyName)
    }
  }, [dispatch, currentAccount, createAd.businessType, createAd.suggest])

  const stepBuild = () => {
    dispatch(resetSearchImage())
    dispatch(
      setAd({
        id: '',
        headline: '',
        body: '',
        companyName: currentAccount!.name,
        callToAction: 'Learn More',
        destinationUrl: currentAccount!.website
      })
    )
    history.push('/campaigns/internet/public/create')
  }

  const gotoSuggest = () => {
    history.push('/campaigns/internet/public/suggest')
  }

  const content = (() => {
    let c

    if (currentAccount && createAd.businessType && createAd.suggest) {
      c = <InternetSuggestedAds businessTypeId={createAd.businessType.id} proceed={proceedWithSuggestion} companyName={companyName} />
    }

    return c ?? <React.Fragment />
  })()

  return (
    <React.Fragment>
      <Helmet>
        <title>Suggested Ads</title>
      </Helmet>
      <EuiFlexGroup responsive={false}>
        <EuiFlexItem>
          <EuiTitle size='s'>
            <h3>Suggested Ads</h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton onClick={stepBuild} fullWidth={false}>
            Switch to Ad Builder
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      {content}
      <EuiButton id='back' fill type='button' color='text' onClick={gotoSuggest} iconType='arrowLeft' iconSide='left'>
        Back
      </EuiButton>
    </React.Fragment>
  )
}

export default SuggestionsStep
