import React from 'react'

import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiImage, EuiLink, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import history from 'services/HistoryService'

export const AgencyOfferAccepted: React.FC = () => {
  const onBackToDashboardClicked = () => {
    history.push('/dashboard')
  }

  return (
    <React.Fragment>
      <EuiTitle>
        <h2>Ok, got it.</h2>
      </EuiTitle>
      <EuiSpacer />
      <EuiFormRow fullWidth>
        <EuiText size={'m'}>
          <b>Remember</b>, you are now in the running to get in the group.
        </EuiText>
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <EuiText size={'s'}>You have to activate a client campaign before the end of the year. And the first 50 agencies to activate are the ones in the group.</EuiText>
      </EuiFormRow>
      <EuiFormRow>
        <EuiText size={'s'}>If you have not already, add me to your cell phone:</EuiText>
      </EuiFormRow>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiImage size='s' alt='' src='/eric.png' />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ paddingTop: 12 }}>
          <EuiText size={'s'}>
            <b>Eric Busby</b>
            <br />
            <i>Co-Founder & President</i>
            <br />
            AdCritter
            <br />
            <EuiLink href={'tel:+6158777118'} color={'success'}>
              (615) 877-7118
            </EuiLink>{' '}
            personal cell
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiButtonEmpty iconType={'arrowRight'} color={'primary'} onClick={onBackToDashboardClicked}>
        Back to Dashboard
      </EuiButtonEmpty>
    </React.Fragment>
  )
}
