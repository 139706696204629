import React, { FC } from 'react'

import { EuiHorizontalRule, EuiLoadingChart } from '@elastic/eui'

import { Campaign, CampaignActiveStatus, CampaignStatus, Creative, CreativeStatus } from 'api/interfaces'
import { InsightsById } from 'api/interfaces/InsightsById'
import InsightsAdFatigueSingleCreative from 'components/insights/Components/InsightsAdFatigueSingleCreative'
import InsightsPanel from 'components/insights/Components/InsightsPanel'
import { adFatigueButtonColor, adFatigueButtonTextColor, adFatigueDescription, adFatigueRightPanelColor, adFatigueRightPanelDescription } from 'components/insights/InsightsConstants'

export interface InsightsAdFatigueProps {
  isLoading: boolean
  adFatigue?: InsightsById
  creatives?: Creative[]
}

const InsightsAdFatigue: FC<InsightsAdFatigueProps> = ({ isLoading, adFatigue, creatives }) => {
  return (
    <InsightsPanel isLoading={isLoading} title='Ad Fatigue' description={adFatigueDescription} buttonColor={adFatigueButtonColor} buttonTextColor={adFatigueButtonTextColor} rightPanelTitle={adFatigue?.status ?? 'OK'} rightPanelDescription={adFatigueRightPanelDescription} rightPanelColor={adFatigueRightPanelColor}>
      {isLoading ? (
        <EuiLoadingChart size='l' />
      ) : (
        creatives?.map((c, i) => {
          if (c.status === CreativeStatus.Inactive || c.status === CreativeStatus.Deleted) {
            return
          }

          const fatigueItem = adFatigue?.byId[c.id]

          return (
            <React.Fragment>
              <InsightsAdFatigueSingleCreative isLoading={isLoading} creative={c} adFatigue={fatigueItem} />

              {i < creatives.length - 1 && <EuiHorizontalRule margin='xs' />}
            </React.Fragment>
          )
        })
      )}
    </InsightsPanel>
  )
}

export default InsightsAdFatigue
