import React from 'react'

import { EuiListGroup, EuiListGroupItem } from '@elastic/eui'

import { DmaTarget } from 'api/interfaces'

interface NewDmaTargeterProps {
  dmas: Array<DmaTarget>

  onDmaRemoved(dmaId: number): void
}

export const NewDmaTargeter = ({ dmas, onDmaRemoved }: NewDmaTargeterProps) => {
  return (
    <React.Fragment>
      {dmas && dmas.length > 0 && (
        <EuiListGroup bordered={true} maxWidth={false} size='s'>
          {dmas.map(w => (
            <EuiListGroupItem
              size='s'
              key={w.id}
              label={`${w.name}`}
              extraAction={{
                onClick: () => {
                  onDmaRemoved(w.id)
                },
                iconType: 'cross',
                iconSize: 's',
                'aria-label': 'Remove',
                alwaysShow: true
              }}
            />
          ))}
        </EuiListGroup>
      )}
    </React.Fragment>
  )
}
