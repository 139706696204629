import React, { FC } from 'react'

import { EuiStat, EuiSplitPanel } from '@elastic/eui'

import { CampaignInsightsOverviewData } from 'api/interfaces/CampaignInsightsOverviewData'

export interface InsightsOverviewProps {
  isLoading: boolean
  overview?: CampaignInsightsOverviewData
}

const CampaignInsightsOverview: FC<InsightsOverviewProps> = ({ isLoading, overview }) => {
  return (
    <EuiSplitPanel.Outer direction='row'>
      <EuiSplitPanel.Inner style={{ borderRight: '1px solid #E6E7EB' }}>
        <EuiStat description='Total Views' title={overview?.totalViews.toLocaleString()} titleColor='#C1701B' titleSize='m' isLoading={isLoading} />
      </EuiSplitPanel.Inner>
      <EuiSplitPanel.Inner style={{ borderRight: '1px solid #E6E7EB' }}>
        <EuiStat description='Total Clicks' title={overview?.totalClicks.toLocaleString()} titleColor='#C1701B' titleSize='m' isLoading={isLoading} />
      </EuiSplitPanel.Inner>
      <EuiSplitPanel.Inner>
        <EuiStat description='Avg. Click Thru Rate' title={`${((overview?.avgClickThruRate ?? Number.EPSILON) * 100).toPrecision(3)}%`} titleColor='#C1701B' titleSize='m' isLoading={isLoading} />
      </EuiSplitPanel.Inner>
    </EuiSplitPanel.Outer>
  )
}

export default CampaignInsightsOverview
