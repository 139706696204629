import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiLoadingContent, EuiShowFor, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { BillboardCustomization, BillboardSuggestion } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import { BillboardCard } from 'components/Cards/BillboardCard'
import history from 'services/HistoryService'

import { setLoaded } from '../../../../builders/billboards/retail/builderBillboardRetailSlice'
import { fetchBillboards, setBillboard } from './createAdBillboardRetailSlice'

const SuggestionsStep: React.FC = () => {
  const dispatch = useDispatch()
  const createAd = useSelector((state: RootState) => state.createAdBillboardRetail)
  const [chosenBillboard, setChosenBillboard] = useState<BillboardCustomization>()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const campaign = useSelector((state: RootState) => state.campaign)

  useEffect(() => {
    if (!createAd.billboards && !createAd.isLoadingBillboards && createAd.businessType && currentAccount) {
      dispatch(fetchBillboards(currentAccount.id, createAd.businessType.id, currentAccount.name, currentAccount.city))
    }
  }, [dispatch, currentAccount, createAd.businessType, createAd.billboards, createAd.isLoadingBillboards])

  useEffect(() => {
    if (chosenBillboard && currentAccount && createAd.suggest) {
      dispatch(setBillboard(chosenBillboard))
      history.push('/campaigns/billboards/public/create')
    }
  }, [dispatch, currentAccount, chosenBillboard, campaign.campaign, createAd.suggest])

  const gotoSuggest = () => {
    history.push('/campaigns/billboards/public/suggest')
  }
  const suggestedBillboards = (() => {
    return createAd.billboards?.map((b, i) => {
      let displayBillboard: BillboardSuggestion = { ...b }
      displayBillboard.headline = { ...displayBillboard.headline }
      displayBillboard.headline.sampleText = b.suggestedHeader
      if (currentAccount?.name) {
        displayBillboard.companyName = { ...displayBillboard.companyName }
        displayBillboard.companyName.sampleText = currentAccount?.name
      }
      if (currentAccount?.website) {
        displayBillboard.website = { ...displayBillboard.website }
        displayBillboard.website.sampleText = currentAccount?.website
      }
      if (displayBillboard.headline.width === 0) displayBillboard.headline.width = 200
      if (displayBillboard.headline.height === 0) displayBillboard.headline.height = 200
      return (
        <BillboardCard
          key={i}
          billboard={displayBillboard}
          header={currentAccount?.name}
          onUseClick={() => {
            setChosenBillboard(displayBillboard)
            setLoaded(false)
          }}
        />
      )
    })
  })()

  return (
    <React.Fragment>
      <Helmet>
        <title>Billboards</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Billboards</h3>
      </EuiTitle>
      <EuiText size='s'>
        <p>Browse and edit these suggested billboards.</p>
      </EuiText>
      <EuiSpacer />

      {createAd.isLoadingBillboards && <EuiLoadingContent lines={3} />}
      {createAd.billboards && (
        <React.Fragment>
          {createAd.billboards && createAd.billboards.length > 0 && (
            <React.Fragment>
              <EuiShowFor sizes={['s', 'xs']}>
                <EuiFlexGrid columns={1} responsive={true}>
                  {suggestedBillboards}
                </EuiFlexGrid>
              </EuiShowFor>
              <EuiShowFor sizes={['l', 'm']}>
                <EuiFlexGrid columns={2} responsive={true}>
                  {suggestedBillboards}
                </EuiFlexGrid>
              </EuiShowFor>
              <EuiShowFor sizes={['xl']}>
                <EuiFlexGrid columns={3} responsive={true}>
                  {suggestedBillboards}
                </EuiFlexGrid>
              </EuiShowFor>
            </React.Fragment>
          )}
          <EuiSpacer />
        </React.Fragment>
      )}

      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton id='back' fill type='button' color='text' onClick={gotoSuggest} iconType='arrowLeft' iconSide='left'>
            Back
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default SuggestionsStep
