import { CampaignInsightsConfiguration } from 'api/interfaces/CampaignInsightsConfiguration'
import { CampaignInsightsOverviewData } from 'api/interfaces/CampaignInsightsOverviewData'
import { Insights } from 'api/interfaces/Insights'
import { InsightsById } from 'api/interfaces/InsightsById'
import { defaultTransformFn, platformApi } from 'api/rtkQueryApi/platform/platformApi'

export interface IGetCampaignInsightsQuery {
  accountId: string
  campaignId?: string
  isAgency?: boolean
}

const campaignInsightsTags = platformApi.enhanceEndpoints({ addTagTypes: ['CampaignInsightsConfiguration', 'CampaignInsightsOverview', 'CampaignInsightsRepetition', 'CampaignInsightsSaturation', 'CampaignInsightsAdFatigue', 'CampaignInsightsTimeToBehaviorModification'] })

const campaignInsightsApi = campaignInsightsTags.injectEndpoints({
  endpoints: build => ({
    getCampaignInsightsConfiguration: build.query<CampaignInsightsConfiguration, IGetCampaignInsightsQuery>({
      query: ({ accountId, isAgency }) => {
        return {
          url: `accounts/${accountId}/campaigns/insights/configuration`,
          method: 'GET',
          isContext: true,
          isAgency
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'CampaignInsightsConfiguration' }]
    }),
    getCampaignInsightsOverview: build.query<CampaignInsightsOverviewData, IGetCampaignInsightsQuery>({
      query: ({ accountId, campaignId, isAgency }) => {
        return {
          url: `accounts/${accountId}/campaigns/${campaignId}/insights/overview`,
          method: 'GET',
          isContext: true,
          isAgency
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { campaignId }) => [{ type: 'CampaignInsightsOverview', id: campaignId }]
    }),
    getCampaignInsightsRepetition: build.query<Insights, IGetCampaignInsightsQuery>({
      query: ({ accountId, campaignId, isAgency }) => {
        return {
          url: `accounts/${accountId}/campaigns/${campaignId}/insights/repetition`,
          method: 'GET',
          isContext: true,
          isAgency
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { campaignId }) => [{ type: 'CampaignInsightsRepetition', id: campaignId }]
    }),
    getCampaignInsightsSaturation: build.query<Insights, IGetCampaignInsightsQuery>({
      query: ({ accountId, campaignId, isAgency }) => {
        return {
          url: `accounts/${accountId}/campaigns/${campaignId}/insights/saturation`,
          method: 'GET',
          isContext: true,
          isAgency
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { campaignId }) => [{ type: 'CampaignInsightsSaturation', id: campaignId }]
    }),
    getCampaignInsightsAdFatigue: build.query<InsightsById, IGetCampaignInsightsQuery>({
      query: ({ accountId, campaignId, isAgency }) => {
        return {
          url: `accounts/${accountId}/campaigns/${campaignId}/insights/fatigue`,
          method: 'GET',
          isContext: true,
          isAgency
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { campaignId }) => [{ type: 'CampaignInsightsAdFatigue', id: campaignId }]
    }),
    getCampaignInsightsTimeToBehaviorModificationCurrentMonth: build.query<number, IGetCampaignInsightsQuery>({
      query: ({ accountId, campaignId, isAgency }) => {
        return {
          url: `accounts/${accountId}/campaigns/${campaignId}/insights/timeToBehaviorModification`,
          method: 'GET',
          isContext: true,
          isAgency
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { campaignId }) => [{ type: 'CampaignInsightsTimeToBehaviorModification', id: campaignId }]
    })
  }),
  overrideExisting: false
})

export const { useLazyGetCampaignInsightsConfigurationQuery, useGetCampaignInsightsConfigurationQuery, useGetCampaignInsightsOverviewQuery, useGetCampaignInsightsRepetitionQuery, useGetCampaignInsightsSaturationQuery, useGetCampaignInsightsAdFatigueQuery, useGetCampaignInsightsTimeToBehaviorModificationCurrentMonthQuery } = campaignInsightsApi
