import React, { FC } from 'react'

import { EuiFormRow, EuiHorizontalRule, EuiLink, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import ScheduleMeetingButton from 'features/mobileDashboard/ScheduleMeetingButton'

const SelfServicePackagesFallbackPage: FC = () => {
  return (
    <React.Fragment>
      <EuiTitle size='l'>
        <h2>Incentive Packages for your Clients</h2>
      </EuiTitle>
      <EuiSpacer size={'m'} />
      <EuiFormRow fullWidth>
        <EuiText style={{ fontSize: '1.25em' }}>
          Qualified Agencies can now offer <b>FREE matching incentives</b> to their clients.
          <EuiSpacer />
          In order to see if your agency qualifies, and start receiving incentive packages as they
          <br />
          become available, simply schedule a meeting with us using the button below.
        </EuiText>
      </EuiFormRow>
      <EuiSpacer />
      <EuiFormRow>
        <ScheduleMeetingButton color={'primary'} textOverride={'Schedule Meeting'} linkOverride='https://calendly.com/adcritter-exec/incentive-packages' />
      </EuiFormRow>
    </React.Fragment>
  )
}

export default SelfServicePackagesFallbackPage
