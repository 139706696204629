import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiCheckableCard, EuiCheckbox, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiHideFor, EuiShowFor, EuiSpacer, EuiText, EuiTitle, htmlIdGenerator } from '@elastic/eui'

import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

import { AdPlacementType, setDeliver } from './builderTvRetailSlice'

const DeliverStep: React.FC = () => {
  const dispatch = useDispatch()
  const builder = useSelector((state: RootState) => state.builderTvRetail)
  const [placement, setPlacement] = useState(AdPlacementType.Everywhere)
  const [categories, setCategories] = useState<string[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (builder.deliver && !isSubmitting) {
      setPlacement(builder.deliver.placement ?? AdPlacementType.Everywhere)
      setCategories(builder.deliver.categories ?? [])
    }
  }, [builder.deliver, isSubmitting])

  const gotoActivateStep = () => {
    setIsSubmitting(true)
    dispatch(setDeliver({ placement, categories }))
    history.push('/build/tv/public/activate')
    setIsSubmitting(false)
  }

  const gotoPrevious = () => {
    history.push('/build/tv/public/target')
  }

  const updateCategories = (isChecked: boolean, category: string) => {
    if (isChecked && categories.filter(c => c === category).length === 0) {
      const newCategories = [...categories]
      newCategories.push(category)
      setCategories(newCategories)
      if (placement !== 'Categories') {
        setPlacement(AdPlacementType.Categories)
      }
    } else if (!isChecked && categories.filter(c => c === category).length > 0) {
      const index = categories.indexOf(category)
      const newCategories = [...categories]
      newCategories.splice(index, 1)
      setCategories(newCategories)
      if (newCategories.length === 0) {
        setPlacement(AdPlacementType.Everywhere)
      }
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Ad Placement</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Ad Placement</h3>
      </EuiTitle>
      <EuiText size='s'>
        <div>Prioritize where your commercials will run.</div>
      </EuiText>
      <EuiSpacer />
      <EuiCheckableCard
        id={htmlIdGenerator()()}
        label={<strong>Prioritize Highest Performing Networks</strong>}
        name={'placement'}
        checked={placement === AdPlacementType.Everywhere}
        onChange={() => {
          setPlacement(AdPlacementType.Everywhere)
          if (categories.length > 0) {
            setCategories([])
          }
        }}>
        <EuiText size='s' color={placement !== 'Everywhere' ? 'subdued' : 'primary'}>
          Prioritizes the best performing networks for your target audience. Automatically self-optimizes for best results.
        </EuiText>
      </EuiCheckableCard>
      <EuiSpacer size='m' />
      <EuiCheckableCard
        className='specificCategories'
        id={htmlIdGenerator()()}
        label={<strong>Prioritize Specific Categories</strong>}
        name={'placement'}
        checked={placement === AdPlacementType.Categories}
        onChange={() => {
          setPlacement(AdPlacementType.Categories)
          if (categories.length === 0) {
            setCategories(['Major', 'Sports', 'Lifestyle', 'Entertainment', 'Family', 'News'])
          }
        }}>
        <EuiFlexGrid columns={3} gutterSize='s'>
          <EuiFlexItem>
            <EuiCheckbox
              id={htmlIdGenerator()()}
              onChange={e => {
                updateCategories(e.target.checked, 'Major')
              }}
              disabled={placement !== 'Categories'}
              checked={categories.filter(c => c === 'Major').length > 0}
              label='Major Networks'
            />
            <EuiText size='xs' style={{ marginLeft: 25 }} color={placement !== 'Categories' ? 'subdued' : 'primary'}>
              Includes NBC, ABC, ESPN, Fox, MSNBC and more...
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCheckbox
              id={htmlIdGenerator()()}
              onChange={e => {
                updateCategories(e.target.checked, 'Sports')
              }}
              disabled={placement !== 'Categories'}
              checked={categories.filter(c => c === 'Sports').length > 0}
              label='Sports Networks'
            />
            <EuiText size='xs' style={{ marginLeft: 25 }} color={placement !== 'Categories' ? 'subdued' : 'primary'}>
              Includes ESPN, NBCSN, Fox Sports, ACC, MLB, NBA, NFL, Big Ten and more...
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCheckbox
              id={htmlIdGenerator()()}
              onChange={e => {
                updateCategories(e.target.checked, 'Lifestyle')
              }}
              disabled={placement !== 'Categories'}
              checked={categories.filter(c => c === 'Lifestyle').length > 0}
              label='Lifestyle Networks'
            />
            <EuiText size='xs' style={{ marginLeft: 25 }} color={placement !== 'Categories' ? 'subdued' : 'primary'}>
              Includes HGTV, Food Network, DIY, Cooking Channel, Travel Channel and more...
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCheckbox
              id={htmlIdGenerator()()}
              onChange={e => {
                updateCategories(e.target.checked, 'Entertainment')
              }}
              disabled={placement !== 'Categories'}
              checked={categories.filter(c => c === 'Entertainment').length > 0}
              label='Entertainment Networks'
            />
            <EuiText size='xs' style={{ marginLeft: 25 }} color={placement !== 'Categories' ? 'subdued' : 'primary'}>
              Includes A&E, Lifetime, TLC, E! Entertainment, Bravo, Ion Television, Oxygen, USA Network and more...
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCheckbox
              id={htmlIdGenerator()()}
              onChange={e => {
                updateCategories(e.target.checked, 'Family')
              }}
              disabled={placement !== 'Categories'}
              checked={categories.filter(c => c === 'Family').length > 0}
              label='Family Friendly Networks'
            />
            <EuiText size='xs' style={{ marginLeft: 25 }} color={placement !== 'Categories' ? 'subdued' : 'primary'}>
              Includes Discovery, Animal Planet, National Geographic, History Channel and more...
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCheckbox
              id={htmlIdGenerator()()}
              onChange={e => {
                updateCategories(e.target.checked, 'News')
              }}
              disabled={placement !== 'Categories'}
              checked={categories.filter(c => c === 'News').length > 0}
              label='News Networks'
            />
            <EuiText size='xs' style={{ marginLeft: 25 }} color={placement !== 'Categories' ? 'subdued' : 'primary'}>
              Includes Bloomberg Television, CNBC, Fox Business, MSNBC, Fox News, MSN, Weather Channel and more...
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGrid>
      </EuiCheckableCard>
      <EuiSpacer />

      <EuiShowFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='reviewAndFinalizeBtn' fill onClick={gotoActivateStep} isLoading={isSubmitting}>
              Review &amp; Finalize
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={gotoPrevious}>
              Back
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={gotoPrevious} iconType='arrowLeft' iconSide='left'>
              Back
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='reviewAndFinalizeBtn' fill onClick={gotoActivateStep} isLoading={isSubmitting} iconType='arrowRight' iconSide='right'>
              Review &amp; Finalize
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiHideFor>
    </React.Fragment>
  )
}

export default DeliverStep
