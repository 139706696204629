import { AuthResponse } from 'api/interfaces'
import history from 'services/HistoryService'

export const useRedirectAfterLogin = (url?: string) => {
  return (redirect: string | null, authResponse: AuthResponse) => {
    url = url ?? '/dashboard'

    if (authResponse.organization.hasCanceledSubscription) {
      url = '/restart'
    } else if (redirect) {
      url = redirect
    }

    history.push(url)
  }
}
