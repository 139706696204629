import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCallOut, EuiCheckbox, EuiFieldNumber, EuiFieldText, EuiFilePicker, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiHorizontalRule, EuiLoadingContent, EuiRadio, EuiSelect, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { ApiError, apiPutCampaignAdvancedTargeting } from 'api/adcritterApi'
import { City, DayPartitioning, DeviceSettings, FrequencyUnit, InventorySettings, ThirdPartyAudience, Zip } from 'api/interfaces'
import { showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { AudienceFinder, CityFinder, ZipFinder, DmaFinder, StateFinder } from 'components/finders'
import { AgeRangeTargeter, CityTargeter, DmaTargeter, GenderTargeter, StateTargeter, ZipTargeter, HouseholdIncomeTargeter } from 'components/targeters'
import { AudienceTargeterDetailed } from 'components/targeters/AudienceTargeterDetailed'
import { DayPartPicker, DefaultDayParts } from 'components/targeters/DayPartPicker'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import { fetchCampaign, finishedLoadingCampaign } from '../campaignSlice'

interface FormValues {
  audienceType: string
  audiences: Array<ThirdPartyAudience>
  ageRanges: Array<string>
  genders: Array<string>
  householdIncomes: Array<string>
  locationType: string | null
  states: Array<string>
  dmas: Array<string>
  cities: Array<City>
  zips: Array<Zip>
  uploadType: string | null
  uploadedData: Array<string>
  retargetingType: string | null
  urlContains: string | null
  inventorySettings: InventorySettings
  deviceSettings: DeviceSettings
  frequency: number
  frequencyUnit: FrequencyUnit
  dayParts: DayPartitioning
}

const AdvancedAudienceDrawer: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const campaign = useSelector((state: RootState) => state.campaign)
  const [formValues, setFormValues] = useState<FormValues>({
    audienceType: 'Build',
    ageRanges: ['all'],
    genders: ['all'],
    householdIncomes: ['all'],
    audiences: [],
    locationType: 'USA',
    states: [],
    dmas: [],
    cities: [],
    zips: [],
    uploadType: 'Email',
    uploadedData: [],
    urlContains: null,
    retargetingType: null,
    inventorySettings: { targetWeb: true, targetApp: false },
    deviceSettings: { targetPc: true, targetPhone: true, targetTablet: true },
    frequency: 0,
    frequencyUnit: FrequencyUnit.hour,
    dayParts: DefaultDayParts
  })
  const whiteLabel = useWhiteLabel()

  useEffect(() => {
    if (campaign.campaign && campaign.campaign.advancedTargeting && campaign.campaign.advancedTargeting!.advancedTargetingType === 'Audience') {
      setFormValues({
        audienceType: campaign.campaign.advancedTargeting.audienceType ?? 'Build',
        audiences: campaign.campaign.advancedTargeting.audiences,
        ageRanges: campaign.campaign.advancedTargeting.ageRanges,
        genders: campaign.campaign.advancedTargeting.genders,
        householdIncomes: campaign.campaign.advancedTargeting.householdIncomes,
        locationType: campaign.campaign.advancedTargeting.locationType ?? 'USA',
        states: campaign.campaign.advancedTargeting.states,
        dmas: campaign.campaign.advancedTargeting.dmas,
        cities: campaign.campaign.advancedTargeting.cities,
        zips: campaign.campaign.advancedTargeting.zips,
        uploadType: campaign.campaign.advancedTargeting.uploadType ?? 'Email',
        uploadedData: campaign.campaign.advancedTargeting.uploadedData,
        urlContains: campaign.campaign.advancedTargeting.urlParts.length === 1 ? campaign.campaign.advancedTargeting.urlParts[0] : null,
        retargetingType: campaign.campaign.advancedTargeting.retargetingType ?? 'All',
        inventorySettings: {
          targetWeb: campaign.campaign.advancedTargeting!.inventorySettings?.targetWeb ?? true,
          targetApp: campaign.campaign.advancedTargeting!.inventorySettings?.targetApp ?? false
        },
        deviceSettings: {
          targetPc: campaign.campaign.advancedTargeting!.deviceSettings?.targetPc ?? true,
          targetPhone: campaign.campaign.advancedTargeting!.deviceSettings?.targetPhone ?? true,
          targetTablet: campaign.campaign.advancedTargeting!.deviceSettings?.targetTablet ?? true
        },
        frequency: campaign.campaign.advancedTargeting!.frequency ?? 0,
        frequencyUnit: campaign.campaign.advancedTargeting!.frequencyUnit ?? FrequencyUnit.hour,
        dayParts: campaign.campaign.advancedTargeting!.dayParts ?? DefaultDayParts
      })
    }
  }, [campaign.campaign])

  const advancedAudienceSchema = Yup.object().shape({
    locationTargeting: Yup.string().nullable(),
    states: Yup.array().when(['audienceType', 'locationTargeting'], {
      is: (audienceType: string, locationTargeting: string) => audienceType === 'Build' && locationTargeting === 'State',
      then: Yup.array().min(1, 'Please Select at Least 1 State').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    dmas: Yup.array().when(['audienceType', 'locationTargeting'], {
      is: (audienceType: string, locationTargeting: string) => audienceType === 'Build' && locationTargeting === 'DMA',
      then: Yup.array().min(1, 'Please Select at Least 1 DMA/Metro Area').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    cities: Yup.array().when(['audienceType', 'locationTargeting'], {
      is: (audienceType: string, locationTargeting: string) => audienceType === 'Build' && locationTargeting === 'City',
      then: Yup.array().min(1, 'Please Add at Least 1 City').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    zips: Yup.array().when(['audienceType', 'locationTargeting'], {
      is: (audienceType: string, locationTargeting: string) => audienceType === 'Build' && locationTargeting === 'Zip',
      then: Yup.array().min(1, 'Please Add at Least 1 Zip').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    audiences: Yup.array().when('audienceType', {
      is: 'Build',
      then: Yup.array().min(1, 'Please Add at Least 1 Category').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    ageRanges: Yup.array().of(Yup.string()),
    genders: Yup.array().of(Yup.string()),
    householdIncomes: Yup.array().of(Yup.string()),
    uploadType: Yup.string().when('audienceType', {
      is: 'Upload',
      then: Yup.string().required(),
      otherwise: Yup.string().nullable()
    }),
    uploadedData: Yup.array().when('audienceType', {
      is: 'Upload',
      then: Yup.array().min(1, 'Please upload a text file with at least 1 line / row').of(Yup.string().required()),
      otherwise: Yup.array().of(Yup.string())
    }),
    retargetType: Yup.string().nullable(),
    urlContains: Yup.string().when(['audienceType', 'retargetType'], {
      is: (audienceType: string, retargetType: string) => audienceType === 'Retarget' && retargetType === 'Specific',
      then: Yup.string().required('Please Enter a Specific URL Part'),
      otherwise: Yup.string().nullable()
    }),
    inventorySettings: Yup.object().shape({ targetWeb: Yup.boolean(), targetApp: Yup.boolean() }),
    deviceSettings: Yup.object().shape({
      targetPc: Yup.boolean(),
      targetPhone: Yup.boolean(),
      targetTablet: Yup.boolean()
    }),
    frequency: Yup.number(),
    frequencyUnit: Yup.number()
  })

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: advancedAudienceSchema,
    onSubmit: (values: FormValues) => {
      formik.validateField('states')
      formik.validateField('dmas')
      if (!formik.isValid) {
        formik.setSubmitting(false)
        return
      }
      apiPutCampaignAdvancedTargeting(currentAccount!.id, campaign.campaign!.id, {
        advancedTargetingType: 'Audience',
        locationType: values.locationType,
        states: values.states,
        dmas: values.dmas,
        cities: values.cities.map(c => c.id),
        zips: values.zips.map(z => z.id),
        geoFences: [],
        geoCircles: [],
        geoRectangles: [],
        geoPolygons: [],
        audiences: values.audiences,
        audienceType: campaign.campaign!.advancedTargeting!.audienceType,
        ageRanges: values.ageRanges,
        genders: values.genders,
        householdIncomes: values.householdIncomes,
        uploadType: values.audienceType === 'Upload' ? values.uploadType : null,
        uploadedData: values.uploadedData,
        retargetingType: values.audienceType === 'Retargeting' ? values.retargetingType : null,
        urlParts: values.urlContains ? [values.urlContains] : [],
        keywords: [],
        negativeKeywords: [],
        inventorySettings: whiteLabel?.isAgencies() ? values.inventorySettings : null,
        deviceSettings: whiteLabel?.isAgencies() ? values.deviceSettings : null,
        frequency: whiteLabel?.isAgencies() ? values.frequency : null,
        frequencyUnit: whiteLabel?.isAgencies() ? values.frequencyUnit : null,
        dayParts: values.dayParts
      })
        .then(response => {
          dispatch(showSuccessToast('Saved Audience Based targeting'))
          dispatch(finishedLoadingCampaign(response.data))
          formik.setSubmitting(false)
          formik.setStatus(null)

          dispatch(fetchCampaign(currentAccount!.id, campaign.campaign!.id))
        })
        .catch(response => {
          formik.setSubmitting(false)
          response.errors.forEach(function (error: ApiError) {
            formik.setFieldError(error.name, error.message)
          })
        })
    }
  })

  const onFileChange = (files: FileList | null) => {
    if (files && files.length === 1) {
      let r = new FileReader()
      r.onloadend = () => {
        let content = r.result as string
        let rows = content.split('\n').filter(r => r.trim() !== '')
        formik.setFieldTouched('uploadedData')
        formik.setFieldValue('uploadedData', rows, true)
      }
      r.readAsText(files[0])
    }
  }

  const frequencyOptions = [
    { value: FrequencyUnit.hour, text: 'Hour' },
    { value: FrequencyUnit.day, text: 'Day' },
    { value: FrequencyUnit.week, text: 'Week' }
  ]

  let content

  const proceed = () => {
    formik.submitForm().then(() => {})
  }

  if (campaign.isLoadingCampaign) {
    content = <EuiLoadingContent lines={3} />
  } else {
    content = (
      <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
        <div hidden={campaign.campaign!.advancedTargeting!.audienceType !== 'Build'}>
          <EuiFormRow label='Search for individual characteristics' fullWidth isInvalid={!!formik.errors.audiences} error={formik.errors.audiences}>
            <AudienceFinder onAudienceClicked={audience => formik.setFieldValue('audiences', [...formik.values.audiences, audience])} isInvalid={!!formik.errors.audiences} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will prioritize individuals in any of these categories:' fullWidth>
            <AudienceTargeterDetailed
              audiences={formik.values.audiences}
              onAudienceRemoved={audience =>
                formik.setFieldValue(
                  'audiences',
                  formik.values.audiences.filter(a => a.id !== audience.id)
                )
              }
            />
          </EuiFormRow>

          <EuiHorizontalRule margin='xl' />

          <EuiTitle size='xs'>
            <h2>Location Restriction</h2>
          </EuiTitle>
          <EuiSpacer size='s' />
          <EuiRadio
            id='usa'
            name='locationType'
            value='USA'
            defaultValue='USA'
            label={
              <EuiText size='s'>
                <strong>Entire USA</strong>
              </EuiText>
            }
            checked={formik.values.locationType === 'USA'}
            onChange={() => formik.setFieldValue('locationType', 'USA', true)}
          />
          <EuiSpacer size='s' />
          <EuiRadio
            id='state'
            name='locationType'
            value='State'
            defaultValue='State'
            label={
              <EuiText size='s'>
                <strong>State(s)</strong>
              </EuiText>
            }
            checked={formik.values.locationType === 'State'}
            onChange={() => formik.setFieldValue('locationType', 'State', true)}
          />
          <EuiSpacer size='s' />
          <EuiRadio
            id='dma'
            name='locationType'
            value='DMA'
            defaultValue='DMA'
            label={
              <EuiText size='s'>
                <strong>DMA(s)</strong>
              </EuiText>
            }
            checked={formik.values.locationType === 'DMA'}
            onChange={() => formik.setFieldValue('locationType', 'DMA', true)}
          />
          <EuiSpacer size='s' />
          <EuiRadio
            id='city'
            name='locationType'
            value='City'
            defaultValue='City'
            label={
              <EuiText size='s'>
                <strong>City(s)</strong>
              </EuiText>
            }
            checked={formik.values.locationType === 'City'}
            onChange={() => formik.setFieldValue('locationType', 'City', true)}
          />
          <EuiSpacer size='s' />
          <EuiRadio
            id='zip'
            name='locationType'
            value='Zip'
            defaultValue='Zip'
            label={
              <EuiText size='s'>
                <strong>Zip Code(s)</strong>
              </EuiText>
            }
            checked={formik.values.locationType === 'Zip'}
            onChange={() => formik.setFieldValue('locationType', 'Zip', true)}
          />

          <div hidden={formik.values.locationType !== 'State'}>
            <EuiSpacer />
            <EuiFormRow label='Search for a State' fullWidth isInvalid={!!formik.errors.states} error={formik.errors.states}>
              <StateFinder addState={stateToAdd => formik.setFieldValue('states', [...formik.values.states, stateToAdd])} isInvalid={!!formik.errors.states} />
            </EuiFormRow>
            <EuiFormRow label='Your campaign will target these States:' fullWidth>
              <StateTargeter
                states={formik.values.states}
                onStateRemoved={stateToRemove =>
                  formik.setFieldValue(
                    'states',
                    formik.values.states.filter(x => x !== stateToRemove),
                    true
                  )
                }
              />
            </EuiFormRow>
          </div>

          <div hidden={formik.values.locationType !== 'DMA'}>
            <EuiSpacer />
            <EuiFormRow label='Search for a DMA' fullWidth isInvalid={!!formik.errors.dmas} error={formik.errors.dmas}>
              <DmaFinder addDma={dmaToAdd => formik.setFieldValue('dmas', [...formik.values.dmas, dmaToAdd])} isInvalid={!!formik.errors.dmas} />
            </EuiFormRow>
            <EuiFormRow label='Your campaign will target these DMAs:' fullWidth>
              <DmaTargeter
                dmas={formik.values.dmas}
                onDmaRemoved={dmaToRemove =>
                  formik.setFieldValue(
                    'dmas',
                    formik.values.dmas.filter(x => x !== dmaToRemove),
                    true
                  )
                }
              />
            </EuiFormRow>
          </div>

          <div hidden={formik.values.locationType !== 'City'}>
            <EuiSpacer />
            <EuiFormRow label='Search for a city' fullWidth isInvalid={!!formik.errors.cities} error={formik.errors.cities}>
              <CityFinder onCityClicked={city => formik.setFieldValue('cities', [...formik.values.cities.filter(c => c.id !== city.id), city])} isInvalid={!!formik.errors.cities} />
            </EuiFormRow>
            <EuiFormRow label='Your campaign will target these cities:' fullWidth>
              <CityTargeter
                cities={formik.values.cities}
                onCityRemoved={city =>
                  formik.setFieldValue(
                    'cities',
                    formik.values.cities.filter(c => c.id !== city.id)
                  )
                }
              />
            </EuiFormRow>
          </div>

          <div hidden={formik.values.locationType !== 'Zip'}>
            <EuiSpacer />
            <EuiFormRow label='Search for a zip code' fullWidth isInvalid={!!formik.errors.zips} error={formik.errors.zips}>
              <ZipFinder onZipClicked={zip => formik.setFieldValue('zips', [...formik.values.zips.filter(z => z.id !== zip.id), zip])} isInvalid={!!formik.errors.zips} />
            </EuiFormRow>
            <EuiFormRow label='Your campaign will target these zip codes:' fullWidth>
              <ZipTargeter
                zips={formik.values.zips}
                onZipRemoved={zip =>
                  formik.setFieldValue(
                    'zips',
                    formik.values.zips.filter(z => z.id !== zip.id)
                  )
                }
              />
            </EuiFormRow>
          </div>

          <EuiHorizontalRule margin='xl' />

          <EuiTitle size='xs'>
            <h2>Demographic Restriction</h2>
          </EuiTitle>
          <EuiSpacer size='s' />
          <EuiFormRow label='Age Ranges' fullWidth>
            <AgeRangeTargeter ageRanges={formik.values.ageRanges} setAgeRanges={ageRanges => formik.setFieldValue('ageRanges', ageRanges, true)} />
          </EuiFormRow>
          <EuiSpacer size='s' />
          <EuiFormRow label='Gender' fullWidth>
            <GenderTargeter genders={formik.values.genders} setGenders={genders => formik.setFieldValue('genders', genders, true)} />
          </EuiFormRow>
          <EuiSpacer size='s' />
          <EuiFormRow label='Household Incomes' fullWidth>
            <HouseholdIncomeTargeter householdIncomes={formik.values.householdIncomes} setHouseholdIncomes={householdIncomes => formik.setFieldValue('householdIncomes', householdIncomes, true)} />
          </EuiFormRow>
          <EuiSpacer />
        </div>

        <div hidden={campaign.campaign!.advancedTargeting!.audienceType !== 'Upload'}>
          <EuiFormRow label='Type of uploaded data'>
            <EuiSelect
              fullWidth
              value={formik.values.uploadType ?? 'Email'}
              options={[
                { text: 'Email', value: 'Email' },
                { text: 'Zips', value: 'Zips' },
                { text: 'Street Addresses', value: 'Addresses' },
                { text: 'Mobile IDs', value: 'MobileId' }
              ]}
              onChange={e => formik.setFieldValue('uploadType', e.target.value)}
            />
          </EuiFormRow>
          <EuiFormRow label='File' helpText='Please only upload text files, with one record per line.'>
            <EuiFilePicker
              id='file'
              initialPromptText='Select or drag and drop your text file'
              onChange={files => {
                onFileChange(files)
              }}
              display='large'
              aria-label='Select or drag and drop your text file'
            />
          </EuiFormRow>
          <EuiSpacer />
          <EuiCallOut hidden={formik.values.uploadedData.length === 0} title='Your File Has Been Read' iconType='document' color='success'>
            <p>Upon activating this Ad, we will create this audience. We found {formik.values.uploadedData.length} records in the file you chose.</p>
          </EuiCallOut>
        </div>

        <div hidden={campaign.campaign!.advancedTargeting!.audienceType !== 'Retargeting'}>
          <EuiCallOut title='Retargeting Visitors to your Website' iconType='globe'>
            <p>You can either retarget users of your entire website or users who visited a specific page on your website. To target users of a specific page, select that option below and then provide a unique part of that page's URL. Both retargeting options require first installing the {whiteLabel?.getName()} audience pixel on your website.</p>
          </EuiCallOut>
          <EuiSpacer />
          <EuiRadio
            id='all'
            name='retargetingType'
            value='All'
            label={
              <EuiText size='s'>
                <strong>Target users of all pages on my Website</strong>
              </EuiText>
            }
            checked={formik.values.retargetingType === 'All'}
            onChange={() => {
              formik.setFieldValue('retargetingType', 'All', true)
            }}
          />
          <EuiSpacer size='s' />
          <EuiRadio
            id='specific'
            name='retargetingType'
            value='Specific'
            label={
              <EuiText size='s'>
                <strong>Target users of a specific page on my Website</strong>
              </EuiText>
            }
            checked={formik.values.retargetingType === 'Specific'}
            onChange={() => {
              formik.setFieldValue('retargetingType', 'Specific', true)
            }}
          />
          <EuiSpacer />
          <div hidden={formik.values.retargetingType !== 'Specific'}>
            <EuiFormRow label='The URL of the specific page contains this text:' isInvalid={formik.touched.urlContains && !!formik.errors.urlContains} error={formik.errors.urlContains}>
              <EuiFieldText name='urlContains' placeholder='for example: /products/widget' value={formik.values.urlContains || ''} onChange={formik.handleChange} fullWidth isInvalid={formik.touched.urlContains && !!formik.errors.urlContains} />
            </EuiFormRow>
          </div>
          <EuiSpacer />
        </div>

        {whiteLabel?.isAgencies() && (
          <React.Fragment>
            <EuiSpacer size='xl' />
            <EuiTitle size='xs'>
              <span>Advanced Targeting Options</span>
            </EuiTitle>
            <EuiSpacer size='s' />
            <EuiFormRow fullWidth label={'Select which hours of the day your ad will run. (Uses the audience time zone)'}>
              <DayPartPicker
                dayParts={formik.values.dayParts}
                onSelectedDayPartsChange={days => {
                  formik.setFieldValue('dayParts', days)
                }}
              />
            </EuiFormRow>
            <EuiSpacer size='s' />
            <EuiFormRow label='Inventory'>
              <EuiFlexGrid direction='column'>
                <EuiFlexItem>
                  <EuiCheckbox
                    id='targetWebCheck'
                    checked={formik.values.inventorySettings.targetWeb}
                    label='Target Web'
                    onChange={e => {
                      formik.setFieldValue('inventorySettings.targetWeb', e.target.checked)
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiCheckbox
                    id='targetAppCheck'
                    checked={formik.values.inventorySettings.targetApp}
                    label='Target Apps'
                    onChange={e => {
                      formik.setFieldValue('inventorySettings.targetApp', e.target.checked)
                    }}
                  />
                </EuiFlexItem>
              </EuiFlexGrid>
            </EuiFormRow>
            <EuiFormRow label='Device'>
              <EuiFlexGrid direction='column'>
                <EuiFlexItem>
                  <EuiCheckbox
                    id='targetPcCheck'
                    checked={formik.values.deviceSettings.targetPc}
                    label='Target PC'
                    onChange={e => {
                      formik.setFieldValue('deviceSettings.targetPc', e.target.checked)
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiCheckbox
                    id='targetPhoneCheck'
                    checked={formik.values.deviceSettings.targetPhone}
                    label='Target Phone'
                    onChange={e => {
                      formik.setFieldValue('deviceSettings.targetPhone', e.target.checked)
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiCheckbox
                    id='targetTabletCheck'
                    checked={formik.values.deviceSettings.targetTablet}
                    label='Target Tablet'
                    onChange={e => {
                      formik.setFieldValue('deviceSettings.targetTablet', e.target.checked)
                    }}
                  />
                </EuiFlexItem>
              </EuiFlexGrid>
            </EuiFormRow>
            <EuiFormRow label='Frequency Capping (0 is unlimited)'>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFieldNumber
                    name='frequency'
                    value={formik.values.frequency!}
                    onChange={e => {
                      formik.setFieldValue('frequency', Number(e.target.value))
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiText size='xs'>per</EuiText>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiSelect
                    options={frequencyOptions}
                    value={formik.values.frequencyUnit}
                    onChange={e => {
                      formik.setFieldValue('frequencyUnit', e.target.value)
                    }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFormRow>
          </React.Fragment>
        )}
        <EuiSpacer />

        <EuiButton id='save' fill type='button' isLoading={formik.isSubmitting} onClick={proceed}>
          Save
        </EuiButton>
      </EuiForm>
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>{content}</EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: 260 }}>
          <EuiText size='xs'>
            <h3>Best Practices</h3>
            <h5>Demographic Restriction</h5>
            <p>If you are wanting to target a specific demographic within the audience you are building, you can select one or more age range and even specify the gender within your audience. Keep in mind, this will reduce the size of your audience to reach just those individuals, so use this carefully.</p>
            <h5>About Location Restriction</h5>
            <p>{whiteLabel?.getName()} allows you to specify the geographic location(s) you wish to reach the audience you are building. This differs from location targeting because it starts with the individual characteristics you are targeting and then restricts that audience to the location(s) you select.</p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default AdvancedAudienceDrawer
