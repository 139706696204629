import { AcImage } from './AcImage'

export enum BillboardAdImageType {
  None = 0,
  Square = 1,
  Rectangle = 2
}

export enum AdImagePlacement {
  Right = 0,
  Left = 1
}

export const GetAdWidthForAdImageType = (type: BillboardAdImageType) => {
  const BillboardSizesForAd = {
    Rectangle: 765,
    Square: 400,
    None: 0
  }
  switch (type) {
    case BillboardAdImageType.None:
      return BillboardSizesForAd.None
    case BillboardAdImageType.Rectangle:
      return BillboardSizesForAd.Rectangle
    case BillboardAdImageType.Square:
      return BillboardSizesForAd.Square
    default:
      return BillboardSizesForAd.None
  }
}

export interface BillboardCustomization {
  headline: BillboardTextComponent
  companyName: BillboardTextComponent
  phone: BillboardTextComponent
  location: BillboardTextComponent
  website: BillboardTextComponent
  backgroundUrl: string | null
  logo: BillboardImageComponent
  photo: BillboardImageComponent
  uploadedUrl: string | null
  previewUrl: string
  campaignIds: Array<string>
  adImage?: AcImage
  billboardImageType: BillboardAdImageType
  imageLocation: AdImagePlacement
  id: string
  name: string
  type: string
  quality: string
  kind: string
  thumbnailUrl: string
  status: string
}

export interface BillboardSuggestion extends BillboardCustomization {
  suggestedHeader: string
  adImage: AcImage
  imageLocation: AdImagePlacement
}

export interface BillboardUploaded {
  uploadedUrl: string
  height: number
  width: number
  fileName: string
  fileType: string
}

export interface BillboardTextComponent extends BillboardComponent {
  fontFace?: string
  fontSize?: number
  fontColor?: string
  sampleText?: string
  isDragging?: boolean
  selected?: boolean
}

export interface BillboardImageComponent extends BillboardComponent {
  sampleUrl?: string
  isDragging?: boolean
  selected?: boolean
}

export interface BillboardComponent {
  width?: number
  height?: number
  x?: number
  y?: number
  align?: string
}

export interface BillboardGeosInRange {
  lat: number
  lng: number
  radiusMeters: number
}

export interface BillboardGeo {
  id: string
  mediaOwner: string
  venueName: string
  venueType: string
  latitude: number
  longitude: number
  dma: string
  zipCode: string
}
