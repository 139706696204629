import Konva from 'konva'
import { RefObject } from 'react'

export class StageSnapGuide {
  private _stageRef
  private GUIDELINE_OFFSET = 5

  constructor(stageRef: RefObject<Konva.Stage>) {
    this._stageRef = stageRef
  }

  public ApplyGuidesToLayer(layerRef: RefObject<Konva.Layer>, objectNames: string[]) {
    if (!layerRef.current) {
      return
    }
    const that = this

    layerRef.current!.on('dragmove', function (e) {
      layerRef.current!.find('.guid-line').forEach(l => l.destroy())
      const lineGuideStops = that.getLineGuideStops(e.target, objectNames)
      const itemBounds = that.getObjectSnappingEdges(e.target)
      const guides = that.getGuides(lineGuideStops, itemBounds)
      if (!guides.length) {
        return
      }

      that.drawGuides(guides, layerRef)

      const absPos = e.target.absolutePosition()
      guides.forEach(lg => {
        switch (lg.snap) {
          case 'start': {
            switch (lg.orientation) {
              case 'V': {
                absPos.x = lg.lineGuide + lg.offset
                break
              }
              case 'H': {
                absPos.y = lg.lineGuide + lg.offset
                break
              }
            }
            break
          }
          case 'center': {
            switch (lg.orientation) {
              case 'V': {
                absPos.x = lg.lineGuide + lg.offset
                break
              }
              case 'H': {
                absPos.y = lg.lineGuide + lg.offset
                break
              }
            }
            break
          }
          case 'end': {
            switch (lg.orientation) {
              case 'V': {
                absPos.x = lg.lineGuide + lg.offset
                break
              }
              case 'H': {
                absPos.y = lg.lineGuide + lg.offset
                break
              }
            }
            break
          }
        }
      })
      e.target.absolutePosition(absPos)
    })

    layerRef.current!.on('dragend', () => {
      layerRef.current!.find('.guid-line').forEach(l => l.destroy())
    })
  }

  private getLineGuideStops = (skipShape: any, objectNames: string[]) => {
    let rightPadding = 0
    this._stageRef.current!.find('.rightPadding').forEach(p => {
      rightPadding = p.getClientRect().width
    })

    let leftPadding = 0
    this._stageRef.current!.find('.leftPadding').forEach(p => {
      leftPadding = p.getClientRect().width
    })

    const stageWidth = this._stageRef.current!.width() - rightPadding - leftPadding

    let vertical = [leftPadding, stageWidth / 2 + leftPadding, stageWidth + leftPadding]
    let horizontal = [0, this._stageRef.current!.height() / 2, this._stageRef.current!.height()]

    objectNames.forEach(name => {
      this._stageRef.current!.find('.' + name).forEach(item => {
        if (item === skipShape || (skipShape._nodes && skipShape._nodes.includes(item))) {
          return
        }

        let box = item.getClientRect()
        vertical = ([] as number[]).concat(vertical, [box.x + box.width / 2])
        horizontal = ([] as number[]).concat(horizontal, [box.y + box.height / 2])
      })
    })

    return { vertical, horizontal }
  }

  private getObjectSnappingEdges = (node: any) => {
    let box = node.getClientRect()
    let absPos = node.absolutePosition()

    return {
      vertical: [
        {
          guide: Math.round(box.x + box.width / 2),
          offset: Math.round(absPos.x - box.x - box.width / 2),
          snap: 'center'
        }
      ],
      horizontal: [
        {
          guide: Math.round(box.y + box.height / 2),
          offset: Math.round(absPos.y - box.y - box.height / 2),
          snap: 'center'
        }
      ]
    }
  }

  private getGuides = (lineGuideStops: any, itemBounds: any) => {
    let resultV: any[] = []
    let resultH: any[] = []

    lineGuideStops.vertical.forEach((lineGuide: any) => {
      itemBounds.vertical.forEach((itemBound: any) => {
        const diff = Math.abs(lineGuide - itemBound.guide)
        if (diff < this.GUIDELINE_OFFSET) {
          resultV.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset
          })
        }
      })
    })

    lineGuideStops.horizontal.forEach((lineGuide: any) => {
      itemBounds.horizontal.forEach((itemBound: any) => {
        const diff = Math.abs(lineGuide - itemBound.guide)
        if (diff < this.GUIDELINE_OFFSET) {
          resultH.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset
          })
        }
      })
    })

    const guides = []
    const minV = resultV.sort((a, b) => a.diff - b.diff)[0]
    const minH = resultH.sort((a, b) => a.diff - b.diff)[0]
    if (minV) {
      guides.push({
        lineGuide: minV.lineGuide,
        offset: minV.offset,
        orientation: 'V',
        snap: minV.snap
      })
    }
    if (minH) {
      guides.push({
        lineGuide: minH.lineGuide,
        offset: minH.offset,
        orientation: 'H',
        snap: minH.snap
      })
    }
    return guides
  }

  private drawGuides = (guides: any, layerRef: RefObject<Konva.Layer>) => {
    guides.forEach((lg: any) => {
      if (lg.orientation === 'H') {
        const line = new Konva.Line({
          points: [-6000, 0, 6000, 0],
          stroke: 'rgb(0, 161, 255)',
          strokeWidth: 3,
          name: 'guid-line',
          dash: [4, 6]
        })
        layerRef.current!.add(line)
        line.absolutePosition({
          x: 0,
          y: lg.lineGuide
        })
      } else if (lg.orientation === 'V') {
        const line = new Konva.Line({
          points: [0, -6000, 0, 6000],
          stroke: 'rgb(0, 161, 255)',
          strokeWidth: 3,
          name: 'guid-line',
          dash: [4, 6]
        })
        layerRef.current!.add(line)
        line.absolutePosition({
          x: lg.lineGuide,
          y: 0
        })
      }
    })
  }
}
