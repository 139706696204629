import React from 'react'

import { EuiFieldText, EuiFlexGrid, EuiFlexItem, EuiFormRow } from '@elastic/eui'

import { SalesAgencyInformation } from 'api/interfaces/SalesAgencyDetails'

export interface IAgencyConfirmationDetails {
  agencyInformation: SalesAgencyInformation
  onAgencyInformationChanged?: (agencyDetails: SalesAgencyInformation) => void
}

export const AgencyConfirmationDetails: React.FC<IAgencyConfirmationDetails> = props => {
  const onAgencyDetailsChanged = (agencyInformation: SalesAgencyInformation) => {
    props.onAgencyInformationChanged?.(agencyInformation)
  }

  return (
    <React.Fragment>
      <EuiFlexGrid columns={2}>
        <EuiFlexItem>
          <EuiFormRow label='Business Name' isInvalid={!props.agencyInformation.businessName}>
            <EuiFieldText
              placeholder={'My Business Name'}
              value={props.agencyInformation.businessName}
              onChange={e => {
                onAgencyDetailsChanged({ ...props.agencyInformation, businessName: e.target.value })
              }}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label='Website' isInvalid={!props.agencyInformation.website}>
            <EuiFieldText
              placeholder={'www.mywebsite.com'}
              value={props.agencyInformation.website}
              onChange={e => {
                onAgencyDetailsChanged({ ...props.agencyInformation, website: e.target.value })
              }}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label='Business Phone' isInvalid={!props.agencyInformation.businessPhone}>
            <EuiFieldText
              placeholder={'(555) 555-5555'}
              value={props.agencyInformation.businessPhone}
              onChange={e => {
                onAgencyDetailsChanged({ ...props.agencyInformation, businessPhone: e.target.value })
              }}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label='Time Zone' isInvalid={!props.agencyInformation.timezone}>
            <EuiFieldText
              placeholder={'Central Time'}
              value={props.agencyInformation.timezone}
              onChange={e => {
                onAgencyDetailsChanged({ ...props.agencyInformation, timezone: e.target.value })
              }}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label='Address' isInvalid={!props.agencyInformation.address}>
            <EuiFieldText
              placeholder={'123 Main St'}
              value={props.agencyInformation.address}
              onChange={e => {
                onAgencyDetailsChanged({ ...props.agencyInformation, address: e.target.value })
              }}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label='City' isInvalid={!props.agencyInformation.cityName}>
            <EuiFieldText
              placeholder={'My City'}
              value={props.agencyInformation.cityName}
              onChange={e => {
                onAgencyDetailsChanged({ ...props.agencyInformation, cityName: e.target.value })
              }}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label='State' isInvalid={!props.agencyInformation.state}>
            <EuiFieldText
              placeholder={'My State'}
              value={props.agencyInformation.state}
              onChange={e => {
                onAgencyDetailsChanged({ ...props.agencyInformation, state: e.target.value })
              }}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label='Zip Code' isInvalid={!props.agencyInformation.zipCode}>
            <EuiFieldText
              placeholder={'12345'}
              value={props.agencyInformation.zipCode}
              onChange={e => {
                onAgencyDetailsChanged({ ...props.agencyInformation, zipCode: e.target.value })
              }}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label='Your Name' isInvalid={!props.agencyInformation.contactName}>
            <EuiFieldText
              placeholder={'My Name'}
              value={props.agencyInformation.contactName}
              onChange={e => {
                onAgencyDetailsChanged({ ...props.agencyInformation, contactName: e.target.value })
              }}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label='Email' isInvalid={!props.agencyInformation.contactEmail}>
            <EuiFieldText
              placeholder={'Email'}
              value={props.agencyInformation.contactEmail}
              onChange={e => {
                onAgencyDetailsChanged({ ...props.agencyInformation, contactEmail: e.target.value })
              }}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label='Mobile Phone' isInvalid={!props.agencyInformation.contactPhone}>
            <EuiFieldText
              placeholder={'(555) 555-5555'}
              value={props.agencyInformation.contactPhone}
              onChange={e => {
                onAgencyDetailsChanged({ ...props.agencyInformation, contactPhone: e.target.value })
              }}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGrid>
    </React.Fragment>
  )
}
