import { Organization, User, Account } from 'api/interfaces'
import jsonTryParse from 'utils/jsonTryParse'

export const ACCESS_TOKEN = 'accessToken'
export const REMEMBERED_EMAIL = 'rememberedEmail'
export const CURRENT_USER = 'user'
export const CURRENT_ORGANIZATION = 'organization'
export const CURRENT_ACCOUNT = 'account'
export const STAFF_USER = 'staff'
export const VENDASTA_NAV_BAR_URL = 'strawberryFieldNavBarUrl'
export const VENDASTA_ACCESS_TOKEN = 'strawberryFieldAccessToken'
export const VENDASTA_USER = 'strawberryField-user'
export const SHOPIFY_USER = 'shopifyUser'
export const VENDASTA_PARTNER_USER = 'strawberryField-partner-user'
export const ISINSIGNUPPROCESS = 'isInSignupProcess'
export const SHOPIFY_SHOP = 'shopify_shop'
export const SHOPIFY_NONCE = 'shopify_nonce'
export const RETURN_URL = 'return_url'
export const TRY_AUTO_CREATE_CAMPAIGN = 'return_url'
export const SHOW_WELCOME = 'showWelcome'
export const Agency_Special_Matching_Offer = 'agencySpecialMatchingOffer'

export class LocalStoreManagerService {
  private reservedKeys: string[] = ['sync_keys', 'addToSyncKeys', 'removeFromSyncKeys', 'getSessionStorage', 'setSessionStorage', 'addToSessionStorage', 'removeFromSessionStorage', 'clearAllSessionsStorage']

  public clearSessionStorage() {
    sessionStorage.clear()
  }

  public saveSessionData(key: string, data: any) {
    this.testForInvalidKeys(key)

    localStorage.removeItem(key)
    this.sessionStorageSetItem(key, data)
  }

  public savePermanentData(key: string, data: any) {
    this.testForInvalidKeys(key)

    sessionStorage.removeItem(key)
    this.localStorageSetItem(key, data)
  }

  public exists(key: string) {
    let data = sessionStorage.getItem(key)

    if (data == null) {
      data = localStorage.getItem(key)
    }

    return data != null
  }

  public getData(key: string) {
    this.testForInvalidKeys(key)

    let data = this.sessionStorageGetItem(key)

    if (data == null) {
      data = this.localStorageGetItem(key)
    }

    return data
  }

  public getDataObject<T>(key: string, isDateType = false): T | null {
    let data = this.getData(key)

    if (data != null) {
      if (isDateType) {
        data = new Date(data)
      }
      return data as T
    }
    return null
  }

  public deleteData(key: string) {
    this.testForInvalidKeys(key)

    sessionStorage.removeItem(key)
    localStorage.removeItem(key)
  }

  private testForInvalidKeys(key: string) {
    if (!key) {
      throw new Error('key cannot be empty')
    }

    if (this.reservedKeys.some(x => x === key)) {
      throw new Error(`The storage key "${key}" is reserved and cannot be used. Please use a different key`)
    }
  }

  private localStorageSetItem(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data))
  }

  private sessionStorageSetItem(key: string, data: any) {
    sessionStorage.setItem(key, JSON.stringify(data))
  }

  private localStorageGetItem(key: string) {
    return jsonTryParse(localStorage.getItem(key))
  }

  private sessionStorageGetItem(key: string) {
    return jsonTryParse(sessionStorage.getItem(key))
  }
}

const localStoreManagerService = new LocalStoreManagerService()

export default localStoreManagerService

export const getAccessToken = () => localStoreManagerService.getDataObject<string>(ACCESS_TOKEN)
export const setAccessToken = (value: string) => localStoreManagerService.savePermanentData(ACCESS_TOKEN, value)
export const deleteAccessToken = () => localStoreManagerService.deleteData(ACCESS_TOKEN)

export const getRememberedEmail = () => localStoreManagerService.getDataObject<string>(REMEMBERED_EMAIL)
export const setRememberedEmail = (value: string) => localStoreManagerService.savePermanentData(REMEMBERED_EMAIL, value)
export const deleteRememberedEmail = () => localStoreManagerService.deleteData(REMEMBERED_EMAIL)

export const getCurrentUser = () => localStoreManagerService.getDataObject<User>(CURRENT_USER)
export const setCurrentUser = (value: User) => localStoreManagerService.savePermanentData(CURRENT_USER, value)
export const deleteCurrentUser = () => localStoreManagerService.deleteData(CURRENT_USER)

export const getCurrentOrganization = () => localStoreManagerService.getDataObject<Organization>(CURRENT_ORGANIZATION)
export const setCurrentOrganization = (value: Organization) => localStoreManagerService.savePermanentData(CURRENT_ORGANIZATION, value)
export const deleteCurrentOrganization = () => localStoreManagerService.deleteData(CURRENT_ORGANIZATION)

export const getCurrentAccount = () => localStoreManagerService.getDataObject<Account>(CURRENT_ACCOUNT)
export const setCurrentAccount = (value: Account) => localStoreManagerService.savePermanentData(CURRENT_ACCOUNT, value)
export const deleteCurrentAccount = () => localStoreManagerService.deleteData(CURRENT_ACCOUNT)

export const getShopifyShop = () => localStoreManagerService.getDataObject<string>(SHOPIFY_SHOP)
export const setShopifyShop = (value: string) => localStoreManagerService.savePermanentData(SHOPIFY_SHOP, value)
export const deleteShopifyShop = () => localStoreManagerService.deleteData(SHOPIFY_SHOP)

export const getShopifyNonce = () => localStoreManagerService.getDataObject<string>(SHOPIFY_NONCE)
export const setShopifyNonce = (value: string) => localStoreManagerService.savePermanentData(SHOPIFY_NONCE, value)
export const deleteShopifyNonce = () => localStoreManagerService.deleteData(SHOPIFY_NONCE)

export const getIsInSignupProcess = () => localStoreManagerService.getDataObject<boolean>(ISINSIGNUPPROCESS)
export const setInSignupProcess = (value: boolean) => localStoreManagerService.savePermanentData(ISINSIGNUPPROCESS, value)
export const deleteInSignupProcess = () => localStoreManagerService.deleteData(ISINSIGNUPPROCESS)

export const getVendastaNavBarUrl = () => localStoreManagerService.getDataObject<string>(VENDASTA_NAV_BAR_URL) ?? undefined
export const setVendastaNavBarUrl = (value?: string) => localStoreManagerService.savePermanentData(VENDASTA_NAV_BAR_URL, value)
export const deleteVendastaNavBarUrl = () => localStoreManagerService.deleteData(VENDASTA_NAV_BAR_URL)

export const getVendastaAccessToken = () => localStoreManagerService.getDataObject<string>(VENDASTA_ACCESS_TOKEN) ?? undefined
export const setVendastaAccessToken = (value?: string) => localStoreManagerService.savePermanentData(VENDASTA_ACCESS_TOKEN, value)
export const deleteVendastaAccessToken = () => localStoreManagerService.deleteData(VENDASTA_ACCESS_TOKEN)

export const getReturnUrl = () => localStoreManagerService.getDataObject<string>(RETURN_URL) ?? undefined
export const setReturnUrl = (value?: string) => localStoreManagerService.savePermanentData(RETURN_URL, value)
export const deleteReturnUrl = () => localStoreManagerService.deleteData(RETURN_URL)

export const getIsStaffUser = () => getCurrentUser()?.roles?.includes(STAFF_USER) ?? false

export const getIsVendastaUser = () => getCurrentUser()?.roles?.includes(VENDASTA_USER) ?? false

export const getIsShopifyUser = () => getCurrentUser()?.roles?.includes(SHOPIFY_USER) ?? false

export const getIsVendastaPartnerUser = () => getCurrentUser()?.roles?.includes(VENDASTA_PARTNER_USER) ?? false

export const getShowWelcome = () => localStoreManagerService.getDataObject<boolean>(SHOW_WELCOME) ?? true
export const setShowWelcome = (value?: boolean) => localStoreManagerService.savePermanentData(SHOW_WELCOME, value)

export const getAgencySpecialMatchingOffer = () => localStoreManagerService.getDataObject<boolean>(Agency_Special_Matching_Offer) ?? false
export const setAgencySpecialMatchingOffer = (value?: boolean) => localStoreManagerService.savePermanentData(Agency_Special_Matching_Offer, value)
