import React from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiHideFor, EuiShowFor, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import history from 'services/HistoryService'

import { resetBuilder } from './builderTvRetailSlice'

const CustomStep: React.FC = () => {
  const dispatch = useDispatch()

  const gotoQuestionnaire = () => {
    history.push('/build/tv/public/questionnaire')
  }

  const gotoDashboard = () => {
    history.push('/dashboard')
    dispatch(resetBuilder())
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Custom Commercials</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Ad Request Submitted</h3>
      </EuiTitle>
      <EuiText size='s'>
        <p>Thank you for your submission. Our ad team will now create a suite of custom commercials for you to choose from. We will notify you when they are ready, usually within 2-3 business days.</p>
      </EuiText>
      <EuiSpacer />

      <EuiShowFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton fill type='submit' onClick={gotoDashboard}>
              Proceed to Dashboard
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={gotoQuestionnaire}>
              Edit my Submission
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={gotoQuestionnaire} iconType='arrowLeft' iconSide='left'>
              Edit my Submission
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton fill type='submit' onClick={gotoDashboard}>
              Proceed to Dashboard
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiHideFor>
    </React.Fragment>
  )
}

export default CustomStep
