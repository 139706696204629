import React from 'react'

import { EuiListGroup, EuiListGroupItem } from '@elastic/eui'

import { City } from 'api/interfaces'

interface CityTargeterProps {
  cities: Array<City>

  onCityRemoved(city: City): void
}

export const CityTargeter = ({ cities, onCityRemoved }: CityTargeterProps) => {
  return (
    <React.Fragment>
      {cities && cities.length > 0 && (
        <EuiListGroup bordered={true} maxWidth={false} size='s'>
          {cities.map(w => (
            <EuiListGroupItem
              size='s'
              key={w.id.toString()}
              label={`${w.name}, ${w.stateCode}`}
              extraAction={{
                onClick: () => {
                  onCityRemoved(w)
                },
                iconType: 'cross',
                iconSize: 's',
                'aria-label': 'Remove',
                alwaysShow: true
              }}
            />
          ))}
        </EuiListGroup>
      )}
    </React.Fragment>
  )
}
