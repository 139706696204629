import React, { useEffect, useState } from 'react'

import { EuiFieldText, EuiFormRow, EuiSpacer, EuiTitle } from '@elastic/eui'

export interface IContactForm {
  header?: string
  subtext?: string
  name?: string
  phone?: string
  email?: string
  onNameChange?: (name: string) => void
  onPhoneChange?: (phone: string) => void
  onEmailChange?: (email: string) => void
  onFormValidChange?: (valid: boolean) => void
}

export const CustomizationsContactForm: React.FC<IContactForm> = props => {
  const [isValid, setIsValid] = useState<boolean>(false)

  useEffect(() => {
    if (props.name && props.phone && props.email) {
      if (!isValid && props.onFormValidChange) {
        props.onFormValidChange(true)
      }
    } else {
      if (isValid && props.onFormValidChange) {
        props.onFormValidChange(false)
      }
    }

    setIsValid(!!props.name && !!props.phone && !!props.email)
  }, [props.name, props.phone, props.email])

  const onNameChange = (value: string) => {
    if (props.onNameChange) {
      props.onNameChange(value)
    }
  }
  const onPhoneChange = (value: string) => {
    if (props.onPhoneChange) {
      props.onPhoneChange(value)
    }
  }
  const onEmailChange = (value: string) => {
    if (props.onEmailChange) {
      props.onEmailChange(value)
    }
  }

  return (
    <React.Fragment>
      <EuiTitle size='s'>
        <h2>{props.header}</h2>
      </EuiTitle>
      <EuiSpacer size='m' />
      <p>{props.subtext}</p>
      <EuiSpacer size='s' />
      <EuiFormRow label='Name'>
        <EuiFieldText
          fullWidth
          value={props.name}
          isInvalid={!props.name}
          onChange={e => {
            onNameChange(e.target.value)
          }}
        />
      </EuiFormRow>
      <EuiSpacer size='s' />
      <EuiFormRow label='Phone'>
        <EuiFieldText
          fullWidth
          value={props.phone}
          isInvalid={!props.phone}
          onChange={e => {
            onPhoneChange(e.target.value)
          }}
        />
      </EuiFormRow>
      <EuiSpacer size='s' />
      <EuiFormRow label='Email'>
        <EuiFieldText
          fullWidth
          value={props.email}
          isInvalid={!props.email}
          onChange={e => {
            onEmailChange(e.target.value)
          }}
        />
      </EuiFormRow>
      <EuiSpacer size='s' />
    </React.Fragment>
  )
}
