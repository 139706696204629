import { Moment } from 'moment'

import { CampaignStatus } from 'api/interfaces/Campaign'

export interface InvoiceDocument {
  id: string
  initialBudget: number
  startDate: Moment
  invoices: InvoiceItem[]
}

export interface InvoiceItem {
  id: string
  invoiceDate: Moment
  amount: number
  name: string
  isMatching: boolean
}

export interface CampaignSpendReport {
  id: string
  campaignName: string
  campaignStatus: CampaignStatus
  allocated: number
  preMatchBudget?: number
  finalCost: number
  impressions: number
  activated?: Moment
  start: Moment
  end: Moment
}

export interface MonthLedger {
  startingBalance: number
  invoiceSum: number
  spend: number
  underspend: number
  year: number
  month: number
  ledgerRecords: LedgerRecord[]
}

export interface LedgerRecord {
  id: string
  name: string
  activatedBudget: number
  finalSpend: number
  amount: number
  recordDate: Moment
  description: string
  ledgerRecordType: LedgerRecordType
}

export enum LedgerRecordType {
  Activated = 'Activated',
  Completed = 'Completed',
  Invoice = 'Invoice'
}
