import React, { useState } from 'react'

import { useEuiTheme, EuiPanel, EuiSpacer, EuiText, EuiTitle, EuiCheckbox } from '@elastic/eui'

export interface IPaymentOption {
  categoryName: string
  name: string
  valueOffered: string
  frequencyDescription: string
  description: string
  descriptionSubtext: string
  isSelected: boolean
  isDisabled: boolean
  price: string
  onSelected?: () => void
}

export const PaymentOption: React.FC<IPaymentOption> = props => {
  const [isHovered, setIsHovered] = useState(false)
  const euiTheme = useEuiTheme()

  const onButtonClick = () => {
    if (props.onSelected) {
      props.onSelected()
    }
  }

  return (
    <EuiPanel
      style={{ width: 260, textAlign: 'center', boxShadow: 'none', backgroundColor: isHovered ? '#fafbfd' : undefined, paddingTop: 12, borderColor: props.isSelected ? euiTheme.euiTheme.colors.primary : euiTheme.euiTheme.colors.mediumShade }}
      color={isHovered ? undefined : 'transparent'}
      hasBorder={true}
      hasShadow={false}
      onClick={onButtonClick}
      onMouseOver={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}>
      <EuiText size={'m'}>
        <p>{props.name}</p>
      </EuiText>
      <EuiSpacer />
      <EuiTitle size={'s'}>
        <h2>{props.valueOffered}</h2>
      </EuiTitle>
      <EuiText size={'s'}>
        <p>
          <b>{props.frequencyDescription}</b>
        </p>
      </EuiText>
      <EuiSpacer size={'l'} />
      <EuiText size={'m'}>
        <p>
          <b>{props.price}</b>
        </p>
      </EuiText>
      <EuiSpacer />
      <div style={{ textAlign: 'initial', margin: '0 auto', width: '16px' }}>
        <EuiCheckbox id={props.name} onChange={onButtonClick} checked={props.isSelected} style={{ margin: '0 auto' }} />
      </div>
    </EuiPanel>
  )
}
