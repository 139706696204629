import React, { FC, useEffect, useState } from 'react'

import { EuiComboBox } from '@elastic/eui'
import { EuiComboBoxOptionOption } from '@elastic/eui/src/components/combo_box/types'

import { Campaign, CampaignActiveStatus, CampaignType } from 'api/interfaces'
import { useGetCampaignsQuery } from 'api/rtkQueryApi/platform/campaignsApi'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

export interface CampaignPickerProps {
  accountId: string | undefined
  campaignType: CampaignType | undefined
  selectedCampaignId: string | undefined
  includeCampaignIds: string[]
  excludeCampaignIds: string[]
  campaignSelected: (campaignId: string | undefined) => void
  fullWidth?: boolean
  isDisabled?: boolean
}

const CampaignPicker: FC<CampaignPickerProps> = ({ accountId, campaignType, selectedCampaignId, includeCampaignIds, excludeCampaignIds, campaignSelected, fullWidth, isDisabled }) => {
  const whiteLabel = useWhiteLabel()

  const { isFetching: isCampaignsFetching, data: campaigns } = useGetCampaignsQuery(
    {
      accountId: accountId ?? '',
      isAgency: whiteLabel?.isAgencies() ?? false
    },
    { skip: !accountId || !whiteLabel }
  )

  const [filteredCampaigns, setFilteredCampaigns] = useState<Campaign[]>([])

  useEffect(() => {
    if (!campaigns) {
      return
    }

    const newFilteredCampaigns = campaigns
      .filter(c => !campaignType || campaignType === c.type)
      .filter(c => includeCampaignIds.includes(c.id) || !c.hasBundle)
      .filter(c => c.activeStatus === CampaignActiveStatus.Inactive)
      .filter(c => !excludeCampaignIds.includes(c.id))

    setFilteredCampaigns(newFilteredCampaigns)
  }, [campaigns, excludeCampaignIds])

  const onCampaignSelected = (options: EuiComboBoxOptionOption<string>[]) => {
    if (!campaigns) {
      return
    }

    const campaign = campaigns.find(a => a.id === options[0]?.value)

    campaignSelected(campaign?.id)
  }

  const options = filteredCampaigns?.map(a => ({ label: a.name, value: a.id })) ?? []
  const selectedCampaign = campaigns?.find(a => a.id === selectedCampaignId)
  const selectedOptions = selectedCampaign ? [{ label: selectedCampaign.name, value: selectedCampaign.id }] : []

  return <EuiComboBox options={options} singleSelection={{ asPlainText: true }} selectedOptions={selectedOptions} onChange={onCampaignSelected} isLoading={isCampaignsFetching} placeholder='Choose a campaign (optional)...' fullWidth={fullWidth} isDisabled={isDisabled} />
}

export default CampaignPicker
