import moment from 'moment'

import { SmartTargeterAreaType } from 'api/interfaces/CampaignTargeting'

import { Address, Competitor } from './Address'
import { ThirdPartyAudience } from './Audience'
import { City, GeoCircle, GeoFence, GeoPolygon, GeoRectangle, PostalCodeTarget, Zip } from './Geo'
import { PaymentMethod } from './Organization'

export interface Campaign {
  id: string
  accountId: string
  name: string
  type: CampaignType
  campaignSubType: CampaignSubType
  status: CampaignStatus
  activeStatus: CampaignActiveStatus
  isUnpaidSubscriptionPayment: boolean | null
  canEditStatus: boolean
  canRemove: boolean
  canArchive: boolean
  creativeIds: Array<string>
  canUnpauseWithoutPayment: boolean
  canAddCreatives: boolean
  budget: number
  budgetFrequency: string
  monthlyImpressions: number | null
  targetingTemplate: string
  businessTypeId: string
  standardTargeting: StandardTargeting | null
  advancedTargeting: AdvancedTargeting | null
  recruitmentTargeting: RecruitmentTargeting | null
  tvTargeting: TvTargeting | null
  billboardTargeting: BillboardTargeting | null
  placement: string
  categories: Array<InternetAdPlacementCategory | TvAdPlacementCategory>
  specific: Array<string>
  startDate: moment.Moment | null
  endDate: moment.Moment | null
  maxCpm: number | null
  firstViewDate: moment.Moment | null
  bundleId?: string
  bundleCampaignSlotId?: string
  hasBundle: boolean
  isMatching: boolean
  endingOnDate: moment.Moment | null
  activated: moment.Moment | null
}

export enum CampaignSubType {
  Default = 'Default',
  NativeOrDisplay = 'NativeOrDisplay',
  Native = 'Native',
  Display = 'Display',
  TV = 'TV',
  Billboard = 'Billboard',
  Audio = 'Audio'
}

export interface LocationTarget {
  countries: LocationCountry[]
  regions: LocationRegion[]
  dmas: LocationDma[]
  cities: LocationCity[]
  postalCodes: PostalCodeTarget[]
  uploadedAudienceUrl?: string
}

export interface LocationCountry {
  id: number
  code: string
  name: string
}

export interface LocationRegion {
  id: number
  code: string
  name: string
}

export interface LocationDma {
  id: number
  name: string
}

export interface LocationCity {
  id: number
  name: string
  regionCode: string
}

export interface LocationPostalCode {
  id: number
  code: string
  name: string
}

export interface SegmentTarget {
  id: number
  name: string
  description?: string
  hierarchy: string[]
}

export interface StandardTargeting {
  targetArea: SmartTargeterAreaType
  streetAddress: string
  city: string
  state: string
  zip: string
  walkInTargeting: boolean
  walkInUsePrimary: boolean
  walkInAddresses: Array<Address>
  competitorTargeting: boolean
  locations?: LocationTarget
  competitors: Array<Competitor>
}

export interface AdvancedTargeting {
  advancedTargetingType: string
  locationType: string | null
  states: Array<string>
  dmas: Array<string>
  cities: Array<City>
  zips: Array<Zip>
  geoFences: Array<GeoFence>
  geoCircles: Array<GeoCircle>
  geoRectangles: Array<GeoRectangle>
  geoPolygons: Array<GeoPolygon>
  audienceType: string | null
  audiences: Array<ThirdPartyAudience>
  ageRanges: Array<string>
  genders: Array<string>
  householdIncomes: Array<string>
  uploadType: string | null
  uploadedAudienceUrl: string | null
  uploadedData: Array<string>
  retargetingType: string | null
  urlParts: Array<string>
  keywords: Array<string>
  negativeKeywords: Array<string>
  inventorySettings: InventorySettings | null
  deviceSettings: DeviceSettings | null
  frequency: number | null
  frequencyUnit: FrequencyUnit | null
  dayParts: DayPartitioning | null
  locations?: LocationTarget
  segments?: SegmentTarget[]
  retargetingCampaignIds?: string[]
}

export interface VistaTargeting {
  locationType: string
  states: Array<string>
  dmas: Array<string>
  cities: Array<City>
  keywords: Array<string>
  negativeKeywords: Array<string>
}

export interface DayPartitioning {
  sunday: number[]
  monday: number[]
  tuesday: number[]
  wednesday: number[]
  thursday: number[]
  friday: number[]
  saturday: number[]
}

export interface InventorySettings {
  targetWeb: boolean
  targetApp: boolean
}

export interface DeviceSettings {
  targetPc: boolean
  targetPhone: boolean
  targetTablet: boolean
}

export enum FrequencyUnit {
  hour = 0,
  day = 1,
  week = 2
}

export interface TvTargeting {
  locationType: string | null
  states: Array<string>
  dmas: Array<string>
  cities: Array<City>
  zips: Array<Zip>
  audiences: Array<ThirdPartyAudience>
  ageRanges: Array<string>
  genders: Array<string>
  householdIncomes: Array<string>
  frequency: number | null
  frequencyUnit: FrequencyUnit | null
  dayParts: DayPartitioning | null
  uploadedAudienceUrl?: string
  locations?: LocationTarget
  segments?: SegmentTarget[]
}

export interface BillboardTargeting {
  locationType: string | null
  states: Array<string>
  dmas: Array<string>
  cities: Array<City>
  zips: Array<Zip>
  locations?: LocationTarget
}

export interface RecruitmentTargeting {
  targetMode: string
  destinationUrl: string
  hasAdvancedTargeting: boolean
}

export interface UpdateAdvancedTargeting {
  advancedTargetingType: string
  locationType: string | null
  states: Array<string>
  dmas: Array<string>
  cities: Array<string>
  zips: Array<string>
  geoFences: Array<GeoFence>
  geoCircles: Array<GeoCircle>
  geoRectangles: Array<GeoRectangle>
  geoPolygons: Array<GeoPolygon>
  audienceType: string | null
  audiences: Array<ThirdPartyAudience>
  ageRanges: Array<string>
  genders: Array<string>
  householdIncomes: Array<string>
  uploadType: string | null
  uploadedData: Array<string>
  retargetingType: string | null
  urlParts: Array<string>
  keywords: Array<string>
  negativeKeywords: Array<string>
  inventorySettings: InventorySettings | null
  deviceSettings: DeviceSettings | null
  frequency: number | null
  frequencyUnit: FrequencyUnit | null
  dayParts: DayPartitioning | null
  retargetingCampaignIds?: string[]
}

export interface UpdateVistaTargeting {
  locationType: string | null
  states: Array<string>
  dmas: Array<string>
  cities: Array<string>
  keywords: Array<string>
}

export interface UpdateTvTargeting {
  locationType: string | null
  states: Array<string>
  dmas: Array<string>
  cities: Array<string>
  zips: Array<string>
  audiences: Array<ThirdPartyAudience>
  ageRanges: Array<string>
  genders: Array<string>
  householdIncomes: Array<string>
  frequency: number | null
  frequencyUnit: FrequencyUnit | null
  dayParts: DayPartitioning | null
  uploadedAudienceUrl?: string
}

export interface UpdateBillboardTargeting {
  locationType: string | null
  states: Array<string>
  dmas: Array<string>
  cities: Array<string>
  zips: Array<string>
}

export interface CampaignPrepareActivate {
  campaignId: string
  paymentIntentId: string
  clientSecret: string
  paymentMethodId: string | null
  amount: number
  last4: string | null
  brand: string | null
  expires: string | null
  type: string
}

export interface CampaignPrepareUnpaid {
  isStillUnpaid: boolean
  amount: number
}

export interface CampaignPrepareStatusActive {
  clientSecret: string
  paymentMethodId: string
  paymentIntentId: string
  amount: number
  paymentMethods: PaymentMethod[]
}

export enum CampaignType {
  Internet = 'Internet',
  TV = 'TV',
  Billboard = 'Billboard'
}

export enum InternetAdPlacementCategory {
  Business = 'Business',
  Social = 'Social',
  Portals = 'Portals',
  Lifestyle = 'Lifestyle',
  Sports = 'Sports',
  Celebrity = 'Celebrity',
  News = 'News',
  Finance = 'Finance',
  Weather = 'Weather',
  Community = 'Community',
  Entertainment = 'Entertainment'
}

export enum TvAdPlacementCategory {
  Major = 'Major',
  Sports = 'Sports',
  Lifestyle = 'Lifestyle',
  Entertainment = 'Entertainment',
  Family = 'Family',
  News = 'News'
}

export enum CampaignStatus {
  NoApprovedAds = 'NoApprovedAds',
  EndingOn = 'EndingOn',
  PaymentFailed = 'PaymentFailed',
  SavedForLater = 'SavedForLater',
  CampaignEnded = 'CampaignEnded',
  PendingAdApproval = 'PendingAdApproval',
  Archived = 'Archived',
  Scheduled = 'Scheduled',
  PendingNetworkApproval = 'PendingNetworkApproval',
  Live = 'Live',
  Deleted = 'Deleted',
  WaitingForTVOutro = 'WaitingForTVOutro',
  WaitingForCustomAds = 'WaitingForCustomAds'
}

export enum CampaignActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export interface IGeoTargeting {
  locations: LocationTarget
  geoCircles: GeoCircle[]
  geoRectangles: GeoRectangle[]
  geoPolygons: GeoPolygon[]
  geoFences: GeoFence[]
}

export interface UpdateCampaignNameAndBudgetResponse {
  name: string
  budget: number
  success: boolean
  didAttemptToCharge: boolean
}
