import React, { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiSpacer, EuiTitle } from '@elastic/eui'

import { apiGetBusinessType } from 'api/adcritterApi'
import config from 'app/config'
import { RootState } from 'app/rootReducer'
import { fetchCampaigns } from 'features/campaigns/campaigns/campaignsSlice'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import StartStep from './10_StartStep'
import SuggestStep from './12_SuggestStep'
import SuggestionsStep from './15_SuggestionsStep'
import CreateStep from './30_CreateStep'
import UploadStep from './31_UploadStep'
import { CreateAdStep, setBusinessType, setCreateAdStep } from './createAdInternetRetailSlice'

interface CreateAdPageParams {
  step: string
}

const Page: React.FC = () => {
  const dispatch = useDispatch()
  const { step } = useParams<CreateAdPageParams>()
  const createAd = useSelector((state: RootState) => state.createAdInternetRetail)
  const [content, setContent] = useState<ReactNode>(<StartStep />)
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const whiteLabel = useWhiteLabel()

  useEffect(() => {
    if (currentAccount && createAd.createAdStep === CreateAdStep.Start) {
      if (currentAccount.businessTypeId) {
        dispatch(fetchCampaigns(currentAccount.id))
        apiGetBusinessType(currentAccount.businessTypeId).then(result => {
          dispatch(setBusinessType(result.data))
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, dispatch])

  useEffect(() => {
    switch (step) {
      default:
        setContent(<StartStep />)
        dispatch(setCreateAdStep(CreateAdStep.Start))
        break
      case 'suggest':
        setContent(<SuggestStep />)
        dispatch(setCreateAdStep(CreateAdStep.Suggest))
        break
      case 'suggestions':
        setContent(<SuggestionsStep />)
        dispatch(setCreateAdStep(CreateAdStep.Suggestions))
        break
      case 'create':
        setContent(<CreateStep />)
        dispatch(setCreateAdStep(CreateAdStep.Create))
        break
      case 'upload':
        if (config.features.uploadInternetAdEnabled) {
          setContent(<UploadStep />)
          dispatch(setCreateAdStep(CreateAdStep.Upload))
        }
        break
    }
  }, [dispatch, step, whiteLabel])

  return (
    <React.Fragment>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiSpacer size='xs' />
          <EuiTitle>
            <h1>Create Ad</h1>
          </EuiTitle>
          <EuiSpacer size='xs' />
        </EuiPageHeaderSection>
      </EuiPageHeader>
      <EuiPanel grow={false} className='eui-xScroll'>
        {content}
      </EuiPanel>
    </React.Fragment>
  )
}

export default Page
