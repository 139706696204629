import React from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import history from 'services/HistoryService'

import { resetCreateAd } from './createAdBillboardRetailSlice'

const QuestionnaireDoneStep: React.FC = () => {
  const dispatch = useDispatch()

  const gotoQuestionnaire = () => {
    history.push('/campaigns/billboards/public/questionnaire')
  }

  const gotoDashboard = () => {
    history.push('/dashboard')
    dispatch(resetCreateAd())
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Custom Billboards</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Ad Request Submitted</h3>
      </EuiTitle>
      <EuiText size='s'>
        <p>Thank you for your submission. Our ad team will now create a suite of custom billboards for you to choose from. We will notify you when they are ready, usually within 2-3 business days.</p>
      </EuiText>
      <EuiSpacer />

      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton id='back' fill type='button' color='text' onClick={gotoQuestionnaire} iconType='arrowLeft' iconSide='left'>
            Edit my Submission
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton fill type='submit' onClick={gotoDashboard}>
            Proceed to Dashboard
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default QuestionnaireDoneStep
