import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { EuiButton, EuiCallOut, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiSpacer, EuiText, EuiTitle, EuiLink, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { dashboardApi } from 'api/rtkQueryApi/platform/dashboardApi'
import { RootState } from 'app/rootReducer'
import { initializeCampaignCreate } from 'features/builders/CampaignCreateSlice'
import { CreateCampaignStorageManager } from 'features/builders/createCampaign/CreateCampaignStorageManager'
import { fetchCampaigns } from 'features/campaigns/campaigns/campaignsSlice'
import history from 'services/HistoryService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

interface FinishedPageParams {
  campaignId: string
  campaignType: string
}

const FinishedPage: React.FC = () => {
  const dispatch = useDispatch()
  let { campaignId, campaignType } = useParams<FinishedPageParams>()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const whiteLabel = useWhiteLabel()

  useEffect(() => {
    dispatch(dashboardApi.util.invalidateTags([{ type: 'DashboardFilter', id: currentAccount!.id }]))

    if (currentAccount) {
      dispatch(fetchCampaigns(currentAccount.id))
    }
  }, [dispatch, currentAccount])

  return (
    <React.Fragment>
      <Helmet>
        <title>Created Your Campaign</title>
      </Helmet>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle>
            <h1>New Campaign</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>
      <EuiPanel grow={false} className='eui-xScroll'>
        <EuiText size='s'>
          <h3>Your New Campaign</h3>
          <p>Now that you’ve successfully created your campaign, all that is left is the ad approval process. It can take 2-3 days for the ad exchanges to approve your ad. Once that happens, your ads will start running automatically.</p>
        </EuiText>
        <EuiSpacer />
        {campaignType === 'Internet' && (
          <EuiCallOut iconType='check' title='Things to Remember' color='success'>
            <EuiText size='s'>
              <ul>
                <li>In order for your ads to be approved, your website must be working properly. Be sure to double-check your site and make sure it is ready for business.</li>
                {!whiteLabel?.isVista() && (
                  <li>
                    If you haven’t already, consider adding the <EuiLink onClick={() => history.push('/audiencePixel')}>audience pixel</EuiLink>. This will improve your campaign’s performance and reporting.
                  </li>
                )}
              </ul>
            </EuiText>
          </EuiCallOut>
        )}
        <EuiSpacer />
        <EuiFlexGroup responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiButton id='edit' onClick={() => history.push(`/campaigns/edit/${campaignId}`)}>
              Edit Campaign
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton
              id='create'
              onClick={() => {
                dispatch(initializeCampaignCreate())
                CreateCampaignStorageManager.DeleteFromLocalStorage()
                history.push('/build')
              }}
              fill={true}>
              Create Another Campaign
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    </React.Fragment>
  )
}

export default FinishedPage
