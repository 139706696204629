import { CampaignCreateWorkflow } from 'workflows/CampaignCreateWorkflow'

const storageKey = 'campaignCreate'
export const CreateCampaignStorageManager = {
  SaveToLocalStorage: (state: CampaignCreateWorkflow) => {
    localStorage.setItem(storageKey, JSON.stringify(state))
  },
  LoadFromLocalStorage: () => {
    const data = localStorage.getItem(storageKey)
    if (data) {
      return JSON.parse(data) as CampaignCreateWorkflow
    }
    return undefined
  },
  DeleteFromLocalStorage: () => {
    localStorage.removeItem(storageKey)
  }
}
