import React, { FC } from 'react'

import { ReportCampaign } from 'api/interfaces'
import ReportDataGrid, { ReportDataGridColumn, ReportDataGridSorting } from 'features/reports/ReportDataGrid'

export interface ReportCampaignGridProps {
  data: ReportCampaign[]
}

const ReportCampaignGrid: FC<ReportCampaignGridProps> = ({ data }) => {
  const defaultSorting = [
    {
      id: 'campaignName',
      direction: 'asc'
    },
    {
      id: 'forDate',
      direction: 'desc'
    }
  ] as ReportDataGridSorting[]

  const columns: ReportDataGridColumn[] = [
    {
      id: 'accountId',
      displayAsText: 'Account Id',
      hidden: true
    },
    {
      id: 'accountName',
      displayAsText: 'Account Name',
      hidden: true
    },
    {
      id: 'accountName',
      displayAsText: 'Account Name',
      hidden: true
    },
    {
      id: 'campaignId',
      displayAsText: 'Campaign Id',
      hidden: true
    },
    {
      id: 'campaignName',
      displayAsText: 'Campaign Name'
    },
    {
      id: 'campaignType',
      displayAsText: 'Campaign Type'
    },
    {
      id: 'campaignBusinessType',
      displayAsText: 'Campaign Business Type'
    },
    {
      id: 'forDate',
      displayAsText: 'Date',
      schema: 'date'
    },
    {
      id: 'views',
      displayAsText: 'Views'
    },
    {
      id: 'clicks',
      displayAsText: 'Clicks'
    },
    {
      id: 'ctr',
      displayAsText: 'CTR %'
    },
    {
      id: 'spend',
      displayAsText: 'Spend'
    },
    {
      id: 'cpc',
      displayAsText: 'CPC'
    },
    {
      id: 'cpa',
      displayAsText: 'CPA'
    },
    {
      id: 'cpm',
      displayAsText: 'CPM'
    },
    {
      id: 'uniqueViewers',
      displayAsText: 'Unique Viewers'
    }
  ]

  return <ReportDataGrid data={data} columns={columns} defaultSorting={defaultSorting} />
}

export default ReportCampaignGrid
