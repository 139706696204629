import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { EuiButton, EuiCallOut, EuiFilePicker, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, EuiTitle, EuiLoadingContent, EuiShowFor, EuiHideFor } from '@elastic/eui'

import { apiGetBillboardBuilderUpload } from 'api/adcritterApi'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

import { setUploadedUrl } from './builderBillboardRetailSlice'

const UploadStep: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount, isVendastaUser } = useSelector((state: RootState) => state.app)
  const builder = useSelector((state: RootState) => state.builderBillboardRetail)
  const [containerClient, setContainerClient] = useState<ContainerClient | null>(null)
  const [isUploading, setIsUploading] = useState(false)
  const [hasFile, setHasFile] = useState(false)
  const [hasCorrectFile, setHasCorrectFile] = useState(false)
  const [uploadedBillboardImg, setUploadedBillboardImg] = useState<string>('')
  const [height, setHeight] = useState<number>(0)
  const [width, setWidth] = useState<number>(0)
  const [fileSize, setFileSize] = useState<number>(0)
  const [fileType, setFileType] = useState<string>('')
  const filePickerRemoval = useRef<EuiFilePicker>(null)
  const [fileName, setFileName] = useState<string>('')

  useEffect(() => {
    if (builder.uploadedBillboardUrl) {
      setUploadedBillboardImg(uploadedBillboardImg ? uploadedBillboardImg : builder?.uploadedBillboardUrl.uploadedUrl!)
      setWidth(width ? width : builder?.uploadedBillboardUrl.width!)
      setHeight(height ? height : builder?.uploadedBillboardUrl.height!)
      setFileType(fileType ? fileType : builder?.uploadedBillboardUrl.fileType!)
      setHasFile(true)
    }
  }, [builder.uploadedBillboardUrl, width, height, fileType, uploadedBillboardImg])

  const largeRectangle = {
    width: 1400,
    height: 400
  }

  const standardRectangle = {
    width: 840,
    height: 400
  }

  useEffect(() => {
    if (currentAccount) {
      apiGetBillboardBuilderUpload(currentAccount.id).then(data => {
        const storageClient = new BlobServiceClient(data.data)
        const containerClient = storageClient.getContainerClient(currentAccount.id)
        setContainerClient(containerClient)
      })
    }
  }, [currentAccount])

  function getMeta(url: any) {
    const img = new Image()
    img.src = url
    img.onload = function () {
      setHeight(img.height)
      setWidth(img.width)
      delay(function () {
        setHasFile(true)
      }, 750)
    }
  }

  const delay = (func: any, num: number) => {
    setTimeout(func, num)
  }

  useEffect(() => {
    fileType.toUpperCase()
    if ((fileType === 'PNG' || fileType === 'JPEG' || fileType === 'JPG') && ((width === largeRectangle.width && height === largeRectangle.height) || (width === standardRectangle.width && height === standardRectangle.height))) {
      setHasCorrectFile(true)
    } else {
      dispatch(setUploadedUrl(null))
      setHasCorrectFile(false)
    }
  }, [fileType, fileSize, height, width, standardRectangle.width, standardRectangle.height, largeRectangle.width, largeRectangle.height, dispatch])

  const onFileChange = (files: FileList | null) => {
    if (files && files.length === 1) {
      const blockBlobClient = containerClient!.getBlockBlobClient(uuidv4() + '.' + files[0].name.split('.').pop())!
      const url = blockBlobClient!.url.split('?')[0]
      let splitArrayLength = url.split('.').length
      let fileType = url.split('.')[splitArrayLength - 1].toUpperCase()
      setFileType(fileType)
      setFileName(files[0].name)
      let size = Math.round(files[0].size * 0.001)
      setFileSize(size)
      setIsUploading(true)
      blockBlobClient!
        .uploadData(files[0], {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20
        })
        .then(d => {
          setIsUploading(false)
          setUploadedBillboardImg(url)
          getMeta(url)
        })
        .then(() => {
          if (!(fileType === 'PNG' || fileType === 'JPEG' || fileType === 'JPG')) {
            setHasFile(true)
            setUploadedBillboardImg('')
            dispatch(setUploadedUrl(null))
          }
        })
    }
    if (files && files.length < 1) {
      setHeight(0)
      setWidth(0)
      setFileType('')
      setHasCorrectFile(false)
      setUploadedBillboardImg('')
      setHasFile(false)
      dispatch(setUploadedUrl(null))
    }
  }

  const gotoTargeting = () => {
    dispatch(
      setUploadedUrl({
        uploadedUrl: uploadedBillboardImg,
        height: height,
        width: width,
        fileName: fileName,
        fileType: fileType
      })
    )
    history.push('/build/billboards/public/target')
  }

  const gotoStart = () => {
    if (isVendastaUser) {
      history.push('/build')
    } else {
      history.push('/build/billboards/public/start')
    }
  }

  if (!containerClient) {
    return <EuiLoadingContent />
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Upload Billboard</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Upload Billboard</h3>
      </EuiTitle>
      <EuiSpacer size='m' />
      <EuiCallOut title='Start by uploading your ad, making sure it meets all requirements.' color='primary' iconType='importAction'>
        <p style={{ color: 'black', fontSize: '1.14286rem' }}>Recommendations:</p>
        <p style={{ marginLeft: 30, marginTop: -20 }}>
          Big fonts.
          <br />
          Few words. (Seven or less.)
          <br />
          Crisp, uncomplicated images.
        </p>
      </EuiCallOut>
      <EuiSpacer size='l' />

      {uploadedBillboardImg !== '' && (width === standardRectangle.width || width === largeRectangle.width) && (height === standardRectangle.height || height === largeRectangle.height) && (
        <EuiFlexItem>
          <EuiText size='s'>
            <h4>Preview Your Billboard</h4>
          </EuiText>
          <EuiSpacer size='s' />
          <EuiFlexItem style={{ alignItems: 'center' }}>
            <img src={uploadedBillboardImg} alt={'uploaded billboard'} style={{ width: width / 1.5, height: height / 1.5, backgroundColor: 'lightgrey', borderRadius: 8 }} />
          </EuiFlexItem>
          <EuiSpacer size='l' />
        </EuiFlexItem>
      )}

      <EuiFilePicker ref={filePickerRemoval} id='upload' fullWidth display='large' multiple={false} initialPromptText='Select or drag and drop  file here' onChange={onFileChange} isLoading={isUploading} />
      <EuiSpacer size='m' />
      <EuiCallOut title='Requirements: ' color='primary' iconType='importAction'>
        <EuiSpacer />
        <section style={{ marginLeft: 30, marginTop: -20, marginBottom: 20 }}>
          <span style={{ color: 'black', fontSize: '1.14286rem' }}>Acceptable image dimensions:</span> (Dimensions must be exact).
          <section style={{ marginLeft: 30 }}>
            <EuiText>
              Large: {largeRectangle.width} x {largeRectangle.height} pixels{' '}
            </EuiText>
            <EuiText>
              Standard: {standardRectangle.width} x {standardRectangle.height} pixels{' '}
            </EuiText>
          </section>
        </section>
        <p style={{ marginLeft: 30, marginTop: -20 }}>
          <span style={{ color: 'black', fontSize: '1.14286rem' }}>Accepted file formats:</span> PNG, JPEG or JPG.
        </p>
      </EuiCallOut>
      <EuiSpacer size='m' />
      {hasFile && (
        <React.Fragment>
          {hasCorrectFile ? (
            <EuiCallOut title='Your File Has Been Read' iconType='document' color='success'>
              <p>You did everything right!</p>
            </EuiCallOut>
          ) : (
            <EuiCallOut title='Your file has not been uploaded because it had these issues: ' iconType='document' color='warning'>
              {!(fileType === 'PNG' || fileType === 'JPEG' || fileType === 'JPG') && (
                <p>
                  {fileName} is not an accepted file type (it's a .{fileType}). Please upload a file that is a <strong>PNG, JPEG or JPG</strong>.
                </p>
              )}
              {!((width === standardRectangle.width && height === standardRectangle.height) || (width === largeRectangle.width && height === largeRectangle.height)) && (
                <section>
                  {fileName} isn't one of the accepted image dimensions (it's {width} x {height}). Please upload a file that is one of the following dimensions:
                  <section style={{ marginLeft: 30 }}>
                    <EuiText size='s'>
                      Large Rectangle: {largeRectangle.width} x {largeRectangle.height} pixels{' '}
                    </EuiText>
                    <EuiText size='s'>
                      Standard Rectangle: {standardRectangle.width} x {standardRectangle.height} pixels{' '}
                    </EuiText>
                  </section>
                </section>
              )}
              <EuiSpacer />
              <div>
                <EuiButton color='warning' iconType='trash' onClick={() => filePickerRemoval.current!.removeFiles()}>
                  <h3 style={{ marginBottom: -0.5 }}>Remove file</h3>
                </EuiButton>
              </div>
            </EuiCallOut>
          )}
        </React.Fragment>
      )}

      <EuiSpacer size='xl' />

      <EuiShowFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='continue' fill type='submit' isDisabled={!hasCorrectFile} onClick={gotoTargeting}>
              Continue to Targeting
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={gotoStart}>
              Back
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={gotoStart} iconType='arrowLeft' iconSide='left'>
              Back
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='continue' fill type='submit' isDisabled={!hasCorrectFile} iconType='arrowRight' iconSide='right' onClick={gotoTargeting}>
              Continue to Targeting
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiHideFor>
    </React.Fragment>
  )
}

export default UploadStep
