import { SalesAgencyDetails, SalesAgencyInformation } from 'api/interfaces/SalesAgencyDetails'
import { defaultTransformFn, platformApi } from 'api/rtkQueryApi/platform/platformApi'

const agenciesTags = platformApi.enhanceEndpoints({ addTagTypes: ['Organization'] })

const agenciesApi = agenciesTags.injectEndpoints({
  endpoints: build => ({
    getAgencyForOrganization: build.query<SalesAgencyDetails, void>({
      query: () => {
        return {
          url: `agencies/organization`,
          method: 'GET',
          isContext: true
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'Organization' }]
    }),
    updateOffer: build.mutation<SalesAgencyDetails, { agencyId: string; accepted: boolean }>({
      query: request => ({
        url: `agencies/${request.agencyId}/updateOffer`,
        method: 'POST',
        data: {
          accepted: request.accepted
        },
        isContext: true
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: () => [{ type: 'Organization' }]
    }),
    updateAgencyInformation: build.mutation<SalesAgencyDetails, { agencyId: string; agencyInformation: SalesAgencyInformation }>({
      query: request => {
        return {
          url: `agencies/${request.agencyId}/updateAgencyInformation`,
          method: 'POST',
          data: request.agencyInformation,
          isContext: true
        }
      },
      transformResponse: defaultTransformFn
    })
  }),
  overrideExisting: false
})
export const { useGetAgencyForOrganizationQuery, useUpdateOfferMutation, useUpdateAgencyInformationMutation } = agenciesApi
