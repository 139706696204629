import React, { useEffect } from 'react'

import { DayPartitioning } from 'api/interfaces'
import { DayPartPickerCell } from 'components/targeters/DayPartPickerCell'

export interface IDayPartPicker {
  onSelectedDayPartsChange: (dayParts: DayPartitioning) => void
  dayParts: DayPartitioning
}

export interface IDayPart {
  day: number
  hour: number
  selected: boolean
}

export interface AcAxis {
  text: string
  value: number
}
export const DayPartDays: AcAxis[] = [
  { text: 'Mon', value: 0 },
  { text: 'Tue', value: 1 },
  { text: 'Wed', value: 2 },
  { text: 'Thu', value: 3 },
  { text: 'Fri', value: 4 },
  { text: 'Sat', value: 5 },
  { text: 'Sun', value: 6 }
]
export const DayPartHours: AcAxis[] = [
  { text: '12am', value: 0 },
  { text: '1am', value: 1 },
  { text: '2am', value: 2 },
  { text: '3am', value: 3 },
  { text: '4am', value: 4 },
  { text: '5am', value: 5 },
  { text: '6am', value: 6 },
  { text: '7am', value: 7 },
  { text: '8am', value: 8 },
  { text: '9am', value: 9 },
  { text: '10am', value: 10 },
  { text: '11am', value: 11 },
  { text: '12pm', value: 12 },
  { text: '1pm', value: 13 },
  { text: '2pm', value: 14 },
  { text: '3pm', value: 15 },
  { text: '4pm', value: 16 },
  { text: '5pm', value: 17 },
  { text: '6pm', value: 18 },
  { text: '7pm', value: 19 },
  { text: '8pm', value: 20 },
  { text: '9pm', value: 21 },
  { text: '10pm', value: 22 },
  { text: '11pm', value: 23 }
]

const dayPartCellWidth = 36
const dayPartCellMargin = 2

export const DefaultDayParts = (() => {
  return {
    monday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
    tuesday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
    wednesday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
    thursday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
    friday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
    saturday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
    sunday: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22]
  } as DayPartitioning
})()

enum HoverSelectMode {
  SELECT,
  DESELECT,
  NONE
}

export const DayPartPicker: React.FC<IDayPartPicker> = props => {
  const [mouseIsDown, setMouseIsDown] = React.useState(false)
  const [startDrag, setStartDrag] = React.useState<IDayPart>()
  const [lastHovered, setLastHovered] = React.useState<IDayPart>()
  const [hoverSelectMode, setHoverSelectMode] = React.useState<HoverSelectMode>(HoverSelectMode.NONE)

  useEffect(() => {
    if (lastHovered && startDrag && hoverSelectMode !== HoverSelectMode.NONE) {
      const cellsToModify: IDayPart[] = []
      for (let day = Math.min(startDrag.day, lastHovered.day); day <= Math.max(startDrag.day, lastHovered.day); day++) {
        for (let hour = Math.min(startDrag.hour, lastHovered.hour); hour <= Math.max(startDrag.hour, lastHovered.hour); hour++) {
          cellsToModify.push({ day, hour, selected: true })
        }
      }
      const newDayParts = modifyCells(cellsToModify, hoverSelectMode === HoverSelectMode.SELECT)
      props.onSelectedDayPartsChange(newDayParts)
    }
  }, [startDrag, lastHovered, hoverSelectMode])

  const cellTotalWidth = (dayPartCellWidth + 2 * dayPartCellMargin) * (DayPartHours.length + 1)

  const modifyCells = (dayParts: IDayPart[], selected: boolean) => {
    const newMondayCells = dayParts.filter(dp => dp.day === 0).map(dp => ({ ...dp, selected }))
    const newTuesdayCells = dayParts.filter(dp => dp.day === 1).map(dp => ({ ...dp, selected }))
    const newWednesdayCells = dayParts.filter(dp => dp.day === 2).map(dp => ({ ...dp, selected }))
    const newThursdayCells = dayParts.filter(dp => dp.day === 3).map(dp => ({ ...dp, selected }))
    const newFridayCells = dayParts.filter(dp => dp.day === 4).map(dp => ({ ...dp, selected }))
    const newSaturdayCells = dayParts.filter(dp => dp.day === 5).map(dp => ({ ...dp, selected }))
    const newSundayCells = dayParts.filter(dp => dp.day === 6).map(dp => ({ ...dp, selected }))

    const excludedMondayCells = props.dayParts.monday.filter(h => !newMondayCells.map(dp => dp.hour).includes(h))
    const excludedTuesdayCells = props.dayParts.tuesday.filter(h => !newTuesdayCells.map(dp => dp.hour).includes(h))
    const excludedWednesdayCells = props.dayParts.wednesday.filter(h => !newWednesdayCells.map(dp => dp.hour).includes(h))
    const excludedThursdayCells = props.dayParts.thursday.filter(h => !newThursdayCells.map(dp => dp.hour).includes(h))
    const excludedFridayCells = props.dayParts.friday.filter(h => !newFridayCells.map(dp => dp.hour).includes(h))
    const excludedSaturdayCells = props.dayParts.saturday.filter(h => !newSaturdayCells.map(dp => dp.hour).includes(h))
    const excludedSundayCells = props.dayParts.sunday.filter(h => !newSundayCells.map(dp => dp.hour).includes(h))

    return {
      monday: selected ? [...excludedMondayCells, ...newMondayCells.map(dp => dp.hour)] : excludedMondayCells,
      tuesday: selected ? [...excludedTuesdayCells, ...newTuesdayCells.map(dp => dp.hour)] : excludedTuesdayCells,
      wednesday: selected ? [...excludedWednesdayCells, ...newWednesdayCells.map(dp => dp.hour)] : excludedWednesdayCells,
      thursday: selected ? [...excludedThursdayCells, ...newThursdayCells.map(dp => dp.hour)] : excludedThursdayCells,
      friday: selected ? [...excludedFridayCells, ...newFridayCells.map(dp => dp.hour)] : excludedFridayCells,
      saturday: selected ? [...excludedSaturdayCells, ...newSaturdayCells.map(dp => dp.hour)] : excludedSaturdayCells,
      sunday: selected ? [...excludedSundayCells, ...newSundayCells.map(dp => dp.hour)] : excludedSundayCells
    }
  }

  const isCellSelected = (day: number, hour: number) => {
    switch (day) {
      case 0:
        return props.dayParts.monday.includes(hour)
      case 1:
        return props.dayParts.tuesday.includes(hour)
      case 2:
        return props.dayParts.wednesday.includes(hour)
      case 3:
        return props.dayParts.thursday.includes(hour)
      case 4:
        return props.dayParts.friday.includes(hour)
      case 5:
        return props.dayParts.saturday.includes(hour)
      case 6:
        return props.dayParts.sunday.includes(hour)
      default:
        return false
    }
  }

  const onMouseDown = () => {
    setMouseIsDown(true)
    if (lastHovered && hoverSelectMode === HoverSelectMode.NONE) {
      setStartDrag({ day: lastHovered.day, hour: lastHovered.hour, selected: true })
      setHoverSelectMode(isCellSelected(lastHovered.day, lastHovered.hour) ? HoverSelectMode.DESELECT : HoverSelectMode.SELECT)
    }
  }

  const onMouseUp = () => {
    setMouseIsDown(false)
    setHoverSelectMode(HoverSelectMode.NONE)
    setStartDrag(undefined)
  }

  const onMouseEnter = (day: number, hour: number) => {
    setLastHovered({ day, hour, selected: true })
    if (mouseIsDown) {
      if (hoverSelectMode === HoverSelectMode.NONE) {
        setStartDrag({ day, hour, selected: true })
        setHoverSelectMode(isCellSelected(day, hour) ? HoverSelectMode.DESELECT : HoverSelectMode.SELECT)
      }
    } else {
      setHoverSelectMode(HoverSelectMode.NONE)
    }
  }

  const onMouseLeave = () => {
    setMouseIsDown(false)
    setHoverSelectMode(HoverSelectMode.NONE)
    setStartDrag(undefined)
  }

  return (
    <div style={{ width: cellTotalWidth + 'px', overflow: 'auto' }} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseLeave={onMouseLeave}>
      <div style={{ width: '100%', float: 'left' }} key={'hourRow'}>
        <div
          style={{
            float: 'left',
            width: dayPartCellWidth + 'px',
            height: dayPartCellWidth + 'px',
            margin: dayPartCellMargin + 'px',
            textAlign: 'center',
            lineHeight: dayPartCellWidth - 4 + 'px'
          }}
          key={'blank'}
        />
        {DayPartHours.map(hour => (
          <DayPartPickerCell onSelected={() => {}} isSelected={false} disableHover={true} text={hour.text} />
        ))}
      </div>
      {DayPartDays.map(day => (
        <React.Fragment key={day.text + ' row'}>
          <div style={{ width: '100%', float: 'left' }} key={day.text + ' row'}>
            <DayPartPickerCell isSelected={false} disableHover={true} text={day.text} />
            {DayPartHours.map(hour => (
              <DayPartPickerCell
                isSelected={isCellSelected(day.value, hour.value)}
                key={hour.value}
                onMouseEnter={() => {
                  onMouseEnter(day.value, hour.value)
                }}
              />
            ))}
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}
