export enum WhiteLabelType {
  AdCritter = 'adcritter',
  Agency = 'agency',
  Vista = 'vista'
}

export class WhiteLabel {
  private readonly whiteLabelType: WhiteLabelType
  private readonly isVendastaUser: boolean
  private readonly isVendastaPartnerUser: boolean
  private readonly orgIsAgency: boolean

  constructor(host: string, port: string, isVendastaUser: boolean, isVendastaPartnerUser: boolean, isAgency: boolean) {
    this.isVendastaUser = isVendastaUser
    this.isVendastaPartnerUser = isVendastaPartnerUser
    this.orgIsAgency = isAgency
    if (host === 'www.claysadmachine.com' || host === 'vista.adcritter.com' || host === 'vista.uat.adcritter.com' || host === 'vista.staging.adcritter.com' || port === '3020') {
      //www.claysadmachine.com, vista.adcritter.com
      this.whiteLabelType = WhiteLabelType.Vista
    } else if (isAgency) {
      this.whiteLabelType = WhiteLabelType.Agency
    } else {
      this.whiteLabelType = WhiteLabelType.AdCritter
    }
  }

  public getName() {
    switch (this.whiteLabelType) {
      case WhiteLabelType.Agency:
        return 'AdCritter for Agencies'
      case WhiteLabelType.Vista:
        return 'Clays Ad Machine'
      default:
        return 'AdCritter'
    }
  }

  public getLogo() {
    switch (this.whiteLabelType) {
      case WhiteLabelType.Agency:
        return '/agencies.png'
      case WhiteLabelType.Vista:
        return '/clays.png'
      default:
        return '/adcritter.svg'
    }
  }

  public getBlackTextLogo() {
    switch (this.whiteLabelType) {
      case WhiteLabelType.Agency:
        return '/agenciesBlackText.svg'
      case WhiteLabelType.Vista:
        return '/claysBlackText.svg'
      default:
        return '/adcritterBlackText.svg'
    }
  }

  public getSquareLogo() {
    switch (this.whiteLabelType) {
      case WhiteLabelType.Agency:
        return '/agencies.png'
      case WhiteLabelType.Vista:
        return '/clays_square.svg'
      default:
        return '/adcritter.svg'
    }
  }

  public getTheme() {
    switch (this.whiteLabelType) {
      case WhiteLabelType.Agency:
        return 'agencies'
      case WhiteLabelType.Vista:
        return 'vista'
      default:
        return 'adcritter'
    }
  }

  public getFrontChatId() {
    if (this.whiteLabelType === WhiteLabelType.Agency) return '0230468b06e5c60f417ee2e587e1fda1'
    return 'beb9e4d4c125ae40a336cd077f03c9dc'
  }

  public getFrontChatSecret() {
    if (this.whiteLabelType === WhiteLabelType.Agency) return 'b05511e04f8671df3825dc17ec4f0ca4'
    return 'd37a92454db211a29663250d09c9cc9e'
  }

  public hideKnowledgeBase() {
    return !(this.whiteLabelType === WhiteLabelType.Agency || this.whiteLabelType === WhiteLabelType.AdCritter)
  }

  public hideReports() {
    return this.whiteLabelType === WhiteLabelType.Vista || (this.isVendastaUser && !this.isVendastaPartnerUser)
  }

  public hideBlueNav() {
    return !(this.whiteLabelType === WhiteLabelType.Agency || this.whiteLabelType === WhiteLabelType.AdCritter)
  }

  public disableSignup() {
    return !(this.whiteLabelType === WhiteLabelType.Agency || this.whiteLabelType === WhiteLabelType.AdCritter)
  }

  public hideAllSpend() {
    return this.isVendastaUser && !this.isVendastaPartnerUser
  }

  public isAgencies() {
    return this.whiteLabelType === WhiteLabelType.Agency
  }

  public isVista() {
    return this.whiteLabelType === WhiteLabelType.Vista
  }
}
