import { FormikProvider, useFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiHideFor, EuiRadio, EuiShowFor, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import config from 'app/config'
import { RootState } from 'app/rootReducer'
import TargetingMap from 'components/TargetingMap'
import { NewDmaFinder } from 'components/finders/NewDmaFinder'
import { NewPostalCodeFinder } from 'components/finders/NewPostalCodeFinder'
import { NewRegionFinder } from 'components/finders/NewRegionFinder'
import { ZipTargeter } from 'components/targeters'
import { NewDmaTargeter } from 'components/targeters/NewDmaTargeter'
import { NewRegionTargeter } from 'components/targeters/NewRegionTargeter'
import { setInitialTargetingMapState } from 'components/targetingMapSlice'
import history from 'services/HistoryService'

import { BuilderStyle, setTargeting, Targeting, setLoaded } from './builderBillboardRetailSlice'

const TargetStep: React.FC = () => {
  const dispatch = useDispatch()
  const [suggestedPlaces, setSuggestedPlace] = useState<string[]>([])
  const builder = useSelector((state: RootState) => state.builderBillboardRetail)
  const [initialValues, setInitialValues] = useState<Targeting>({
    locationType: 'USA',
    cities: [],
    dmas: [],
    zips: [],
    states: []
  })

  useEffect(() => {
    if (config.gtag.ANALYTICSID) {
      ReactGA.event('select_item', { items: [{ item_name: 'Billboard Campaign' }], send_to: config.gtag.ANALYTICSID })
    }

    ReactPixel.track('ViewContent', { content_type: 'Billboard Campaign' })

    if (config.linkedin.PARTNER_ID && config.linkedin.VIEW_CONTENT_CONVERSION_ID) {
      window.lintrk('track', { conversion_id: config.linkedin.VIEW_CONTENT_CONVERSION_ID })
    }
  }, [])

  useEffect(() => {
    if (builder.targeting) {
      setInitialValues(builder.targeting)
    }
  }, [builder.targeting])

  useEffect(() => {
    dispatch(
      setInitialTargetingMapState({
        hideDrawingTools: true,
        hideLocationSearch: true,
        targetedPlaces: suggestedPlaces,
        includeBillboards: true
      })
    )
  }, [suggestedPlaces])

  const createSchema = Yup.object().shape({
    locationType: Yup.string().nullable(),
    states: Yup.array().when('locationType', {
      is: 'State',
      then: Yup.array().min(1, 'Please select at least 1 state').of(Yup.object()).required(),
      otherwise: Yup.array().of(Yup.object())
    }),
    dmas: Yup.array().when('locationType', {
      is: 'DMA',
      then: Yup.array().min(1, 'Please select at least 1 metro area / DMA').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    zips: Yup.array().when('locationType', {
      is: 'Zip',
      then: Yup.array().min(1, 'Please add at least 1 zip').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    audiences: Yup.array().when('audienceType', {
      is: 'Build',
      then: Yup.array().min(1, 'Please add at least 1 individual characteristic').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    ageRanges: Yup.array().of(Yup.string()),
    genders: Yup.array().of(Yup.string()),
    householdIncomes: Yup.array().of(Yup.string())
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: createSchema,
    onSubmit: (values: Targeting) => {
      dispatch(setTargeting(values))
      history.push('/build/billboards/public/activate')
    }
  })

  useEffect(() => {
    setSuggestedPlace(getSuggestedPlaces())
  }, [formik.values])

  const getSuggestedPlaces = useCallback(() => {
    switch (formik.values.locationType) {
      case 'State':
        return formik.values.states.map(state => state.name)
      case 'DMA':
        return formik.values.dmas.map(d => d.name)
      case 'Zip':
        return formik.values.zips.map(z => z.name)
      default:
        return []
    }
  }, [formik.values])

  const gotoPrevious = () => {
    if (builder.builderStyle === BuilderStyle.Upload) {
      history.push('/build/billboards/public/upload')
    } else if (builder.builderStyle === BuilderStyle.Custom) {
      history.push('/build/billboards/public/questionnaire')
    } else {
      history.push('/build/billboards/public/create')
      dispatch(setLoaded(true))
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Billboards</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Targeting</h3>
      </EuiTitle>
      <EuiText size='s'>
        <div>
          Define the location(s) you want to target.
          <br />
          You can target specific geographic areas below.
        </div>
      </EuiText>
      <EuiSpacer />

      <FormikProvider value={formik}>
        <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.status && !!formik.errors} error={formik.status}>
          <EuiRadio
            id='country'
            name='locationType'
            value='USA'
            label={
              <EuiText size='s'>
                <strong>Target the USA</strong>
              </EuiText>
            }
            checked={formik.values.locationType === 'USA'}
            onChange={() => {
              formik.setFieldValue('locationType', 'USA', true)
            }}
          />
          <EuiSpacer size='s' />
          <EuiRadio
            id='state'
            name='locationType'
            value='State'
            label={
              <EuiText size='s'>
                <strong>Target by State</strong> (one or more U.S. States)
              </EuiText>
            }
            checked={formik.values.locationType === 'State'}
            onChange={() => {
              formik.setFieldValue('locationType', 'State', true)
            }}
          />
          <EuiSpacer size='s' />
          <EuiRadio
            id='dma'
            name='locationType'
            value='DMA'
            label={
              <EuiText size='s'>
                <strong>Target by Metro Area / DMA</strong> (one or more)
              </EuiText>
            }
            checked={formik.values.locationType === 'DMA'}
            onChange={() => {
              formik.setFieldValue('locationType', 'DMA', true)
            }}
          />
          <EuiSpacer size='s' />
          <EuiRadio
            id='zip'
            name='locationType'
            value='Zip'
            label={
              <EuiText size='s'>
                <strong>Target by Zip Code</strong> (one or more)
              </EuiText>
            }
            checked={formik.values.locationType === 'Zip'}
            onChange={() => {
              formik.setFieldValue('locationType', 'Zip', true)
            }}
          />
          <div hidden={formik.values.locationType !== 'State'}>
            <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. states below.' />
            <EuiSpacer size='s' />

            <EuiFormRow id='stateFinder' label='Search for a state' fullWidth isInvalid={!!formik.touched.states && !!formik.errors.states} error={formik.errors.states}>
              <NewRegionFinder addRegion={regionToAdd => formik.setFieldValue('states', [...formik.values.states, regionToAdd])} isInvalid={!!formik.touched.states && !!formik.errors.states} />
            </EuiFormRow>
            {formik.values.states && formik.values.states.length > 0 && (
              <EuiFormRow label='Your campaign will target these states' fullWidth>
                <NewRegionTargeter
                  regions={formik.values.states}
                  onRegionRemoved={regionId => {
                    formik.setFieldValue(
                      'states',
                      formik.values.states.filter(x => x.id !== regionId),
                      true
                    )
                  }}
                />
              </EuiFormRow>
            )}
          </div>

          <div hidden={formik.values.locationType !== 'DMA'}>
            <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more metro areas / DMAs below.' />
            <EuiSpacer size='s' />

            <EuiFormRow id='dmaFinder' label='Search for a metro area' fullWidth isInvalid={!!formik.touched.dmas && !!formik.errors.dmas} error={formik.errors.dmas}>
              <NewDmaFinder addDma={dmaToAdd => formik.setFieldValue('dmas', [...formik.values.dmas, dmaToAdd])} isInvalid={!!formik.touched.dmas && !!formik.errors.dmas} />
            </EuiFormRow>
            {formik.values.dmas && formik.values.dmas.length > 0 && (
              <EuiFormRow label='Your campaign will target these metro areas' fullWidth>
                <NewDmaTargeter
                  dmas={formik.values.dmas}
                  onDmaRemoved={dmaId => {
                    formik.setFieldValue(
                      'dmas',
                      formik.values.dmas.filter(x => x.id !== dmaId),
                      true
                    )
                  }}
                />
              </EuiFormRow>
            )}
          </div>

          <div hidden={formik.values.locationType !== 'Zip'}>
            <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. zip codes.' />
            <EuiSpacer size='s' />

            <EuiFormRow id='zipFinder' label='Search for a zip code' fullWidth isInvalid={!!formik.touched.zips && !!formik.errors.zips} error={formik.errors.zips}>
              <NewPostalCodeFinder onPostalCodeClicked={zip => formik.setFieldValue('zips', [...formik.values.zips.filter(z => z.id !== zip.id), zip])} isInvalid={!!formik.touched.zips && !!formik.errors.zips} />
            </EuiFormRow>
            {formik.values.zips && formik.values.zips.length > 0 && (
              <EuiFormRow label='Your campaign will target these zip codes' fullWidth>
                <ZipTargeter
                  zips={formik.values.zips.map(z => ({ id: z.id.toString(), name: z.name }))}
                  onZipRemoved={zip => {
                    formik.setFieldValue(
                      'zips',
                      formik.values.zips.filter(z => z.id.toString() !== zip.id)
                    )
                  }}
                />
              </EuiFormRow>
            )}
          </div>
          <EuiSpacer size='xl' />
          <EuiFormRow fullWidth>
            <TargetingMap />
          </EuiFormRow>
          <EuiSpacer />
        </EuiForm>
      </FormikProvider>
      <EuiSpacer />

      <EuiShowFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='reviewAndFinalizeBtn' fill type='submit' isLoading={formik.isSubmitting} onClick={formik.submitForm}>
              Review &amp; Finalize
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={gotoPrevious}>
              Back
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={gotoPrevious} iconType='arrowLeft' iconSide='left'>
              Back
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='reviewAndFinalizeBtn' fill type='submit' isLoading={formik.isSubmitting} iconType='arrowRight' iconSide='right' onClick={formik.submitForm}>
              Review &amp; Finalize
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiHideFor>
    </React.Fragment>
  )
}

export default TargetStep
