import moment, { Moment } from 'moment/moment'
import React, { useCallback, useEffect, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { BuilderCampaignType, CampaignCreateStep, CampaignCreateWorkflow, CampaignInfo, CreativeType, GetBudgetMinimum } from 'workflows/CampaignCreateWorkflow'

import { EuiButton, EuiCallOut, EuiCheckableCard, EuiDatePicker, EuiFieldNumber, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHealth, EuiHideFor, EuiLink, EuiLoadingContent, EuiRadioGroupOption, EuiShowFor, EuiSpacer, EuiText, EuiTitle, htmlIdGenerator } from '@elastic/eui'

import { CampaignBuilderActivateResponse } from 'api/interfaces'
import { TargetingType } from 'api/interfaces/CampaignTargeting'
import { BudgetFrequency, PaymentInfo } from 'api/interfaces/PaymentInfo'
import { useGetOrganizationBundlesQuery } from 'api/rtkQueryApi/platform/bundlesApi'
import { useCampaignBuilderActivateMutation } from 'api/rtkQueryApi/platform/campaignBuilderApi'
import { useGetCampaignsQuery } from 'api/rtkQueryApi/platform/campaignsApi'
import { showSuccessToast, showWarningToast } from 'app/appSlice'
import config from 'app/config'
import { RootState } from 'app/rootReducer'
import { IPaymentMethodPickerState, PaymentMethodPicker, usePaymentMethodPickerState } from 'components/payments/PaymentMethodPicker'
import { initializeCampaignCreate, setCampaignCreateStep } from 'features/builders/CampaignCreateSlice'
import { CreateCampaignStorageManager } from 'features/builders/createCampaign/CreateCampaignStorageManager'
import { ICampaignBuilderWorkflowStep } from 'features/builders/createCampaign/creativeBuilder/CreativeBuilderWorkflowManager'
import { useCampaignBuilderActions } from 'features/builders/createCampaign/useCampaignBuilderActions'
import { AdPaymentType, AdvancedTargetingType } from 'features/builders/internet/retail/builderInternetRetailSlice'
import history from 'services/HistoryService'
import { CampaignType } from 'utils/CampaignType'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

export const BuilderLaunchPage: React.FC<ICampaignBuilderWorkflowStep> = props => {
  const { builderComplete } = useCampaignBuilderActions()
  const dispatch = useDispatch()
  const { currentAccount, currentOrganization, currentUser, isStaffUser } = useSelector((state: RootState) => state.app)
  const organizationBundles = useGetOrganizationBundlesQuery(undefined, { skip: !currentOrganization })
  const campaignsQuery = useGetCampaignsQuery({ accountId: currentAccount?.id ?? '' }, { skip: !currentAccount })
  const [{ selectedPaymentMethodId, selectedCampaignSlotId }, setPaymentMethodPickerState] = usePaymentMethodPickerState()
  const [showBudget, setShowBudget] = useState(true)
  const [start, setStart] = useState<Moment | null>(null)
  const [end, setEnd] = useState<Moment | null>(null)
  const [availableCampaignSlots, setAvailableCampaignSlots] = useState<EuiRadioGroupOption[]>([])
  const [paymentType, setPaymentType] = useState<AdPaymentType>(AdPaymentType.Standard)
  const whiteLabel = useWhiteLabel()
  const campaignCreateWorkflow = useSelector((state: RootState) => state.campaignCreateWorkflow)
  const { campaignType, campaignInfo, campaignTargeting, creativeInfo } = campaignCreateWorkflow
  const [launchSettings, setLaunchSettings] = useState<CampaignInfo>({ ...campaignInfo })
  const [campaignBuilderActivate, campaignBuilderActivateQuery] = useCampaignBuilderActivateMutation()
  const [saving, setSaving] = useState(false)
  const [activating, setActivating] = useState(false)

  useEffect(() => {
    if (campaignInfo) {
      setLaunchSettings(campaignInfo)
      if (config.gtag.ANALYTICSID) {
        ReactGA.event('begin_checkout', { value: campaignInfo.budget, currency: 'USD', items: [{ item_name: 'Internet Campaign' }], send_to: config.gtag.ANALYTICSID })
      }

      ReactPixel.track('InitiateCheckout', { value: campaignInfo.budget, content_type: 'Internet Campaign' })

      if (config.linkedin.PARTNER_ID && config.linkedin.BEGIN_CHECKOUT_CONVERSION_ID) {
        window.lintrk('track', { conversion_id: config.linkedin.BEGIN_CHECKOUT_CONVERSION_ID })
      }

      setStart(campaignInfo.startDate ?? isStaffUser ? moment() : moment().add(3, 'day'))
      setEnd(campaignInfo.endDate ?? moment().add(1, 'month'))
    }
  }, [campaignInfo])

  useEffect(() => {
    if (campaignsQuery.data) {
      const campaigns = campaignsQuery.data
      if (campaignType === BuilderCampaignType.TV) {
        const campaignsCount = campaigns.filter(c => c.targetingTemplate === 'TV').length
        setLaunchSettings({
          ...launchSettings,
          name: launchSettings.name.length > 0 ? launchSettings.name : `TV Campaign ${campaignsCount + 1}`
        })
        return
      }

      if (campaignType === BuilderCampaignType.Billboard) {
        const campaignsCount = campaigns.filter(c => c.targetingTemplate === 'Billboard').length
        setLaunchSettings({
          ...launchSettings,
          name: launchSettings.name.length > 0 ? launchSettings.name : `Billboard Campaign ${campaignsCount + 1}`
        })
        return
      }

      switch (campaignTargeting.targetingType) {
        case TargetingType.Smart:
          const campaignsCount = campaigns.filter(c => c.targetingTemplate === 'Standard').length
          setLaunchSettings({
            ...launchSettings,
            name: launchSettings.name.length > 0 ? launchSettings.name : `Smart Targeter Campaign ${campaignsCount + 1}`
          })
          break
        case TargetingType.Location:
          const campaignsLocationCount = campaigns.filter(c => c.targetingTemplate === 'Advanced' && c.advancedTargeting?.advancedTargetingType === AdvancedTargetingType.Location).length
          setLaunchSettings({
            ...launchSettings,
            name: launchSettings.name.length > 0 ? launchSettings.name : `Location Based Campaign ${campaignsLocationCount + 1}`
          })
          break
        case TargetingType.Audience:
          const campaignsAudienceCount = campaigns.filter(c => c.targetingTemplate === 'Advanced' && c.advancedTargeting?.advancedTargetingType === AdvancedTargetingType.Audience).length
          setLaunchSettings({
            ...launchSettings,
            name: launchSettings.name.length > 0 ? launchSettings.name : `Audience Based Campaign ${campaignsAudienceCount + 1}`
          })
          break
        case TargetingType.Retarget:
          const campaignsSearchCount = campaigns.filter(c => c.targetingTemplate === 'Advanced' && c.advancedTargeting?.advancedTargetingType === AdvancedTargetingType.Retarget).length
          setLaunchSettings({
            ...launchSettings,
            name: launchSettings.name.length > 0 ? launchSettings.name : `Retargeting Campaign ${campaignsSearchCount + 1}`
          })
          break
      }
    }
  }, [campaignType, campaignsQuery, campaignTargeting])

  useEffect(() => {
    if (organizationBundles.data && !organizationBundles.isLoading && !organizationBundles.isFetching && currentUser?.roles.includes('staff')) {
      const availableCampaignSlotOptions = []
      for (let bundle of organizationBundles.data) {
        for (let slot of bundle.campaignSlots) {
          if (!slot.campaignId && slot.campaignType === CampaignType.Internet) {
            availableCampaignSlotOptions.push({ id: slot.id, label: `${bundle.name ? bundle.name + ': ' : ''}$${slot.budget} ${slot.campaignType}` })
          }
        }
      }
      setAvailableCampaignSlots(availableCampaignSlotOptions)
    }
  }, [organizationBundles])

  useEffect(() => {
    if (selectedCampaignSlotId.length > 0 && isStaffUser) {
      setPaymentType(AdPaymentType.Bundle)
    } else {
      setPaymentType(AdPaymentType.Standard)
    }
  }, [selectedPaymentMethodId, selectedCampaignSlotId])

  const saveForLaterCampaign = () => {
    create({ saveForLater: true, campaignCreate: { ...campaignCreateWorkflow, campaignInfo: { ...launchSettings, startDate: start ?? undefined, endDate: end ?? undefined } } })
  }

  const activateCampaign = () => {
    create({ saveForLater: false, campaignCreate: { ...campaignCreateWorkflow, campaignInfo: { ...launchSettings, startDate: start ?? undefined, endDate: end ?? undefined } } })
  }

  const create = (data: { saveForLater: boolean; campaignCreate: CampaignCreateWorkflow }) => {
    const { saveForLater, campaignCreate } = data
    setSaving(saveForLater)
    setActivating(!saveForLater)
    if (!builderComplete.isComplete || !isValid()) {
      return
    }

    const purchaseDetails: PaymentInfo =
      paymentType === AdPaymentType.Bundle && selectedCampaignSlotId.length > 0
        ? {
            paymentMethodType: AdPaymentType.Bundle,
            campaignSlotId: selectedCampaignSlotId,
            bundleId: organizationBundles.data?.find(b => b.campaignSlots.find(s => s.id === selectedCampaignSlotId))?.id,
            budgetFrequency: BudgetFrequency.Weekly
          }
        : {
            paymentMethodType: AdPaymentType.Standard,
            paymentMethodId: selectedPaymentMethodId,
            budgetFrequency: BudgetFrequency.Weekly
          }

    campaignBuilderActivate({
      accountId: currentAccount!.id,
      workflow: campaignCreate,
      purchaseDetails: purchaseDetails,
      isSaveForLater: saveForLater
    })
      .then(c => {
        let budget = campaignCreate?.campaignInfo.budget ?? 0
        if (selectedCampaignSlotId) {
          const campaignSlot = organizationBundles.data?.find(b => b.campaignSlots.find(s => s.id === selectedCampaignSlotId))?.campaignSlots.find(s => s.id === selectedCampaignSlotId)
          budget = campaignSlot?.budget ?? 0
        }
        let result: CampaignBuilderActivateResponse | undefined = undefined
        if ('error' in c) {
          dispatch(showWarningToast('There was a problem with your payment'))
        } else if ('data' in c) {
          result = c.data
        }
        if (!result || !result.campaignCreated) return

        if (!isStaffUser) {
          if (saveForLater) {
            if (config.gtag.ANALYTICSID) {
              ReactGA.event('add_to_cart', { value: budget, currency: 'USD', send_to: config.gtag.ANALYTICSID })
            }
            if (config.gtag.ADWORDSID) {
              ReactGA.event('conversion', { transaction_id: result.campaignId, send_to: 'AW-732436947/Uc2dCP3Ao_wBENOzoN0C' })
            }

            ReactPixel.track('AddToCart', { value: budget, currency: 'USD', content_type: `${campaignCreateWorkflow.campaignType} Campaign` })

            if (config.linkedin.PARTNER_ID && config.linkedin.ADD_TO_CART_CONVERSION_ID) {
              window.lintrk('track', { conversion_id: config.linkedin.ADD_TO_CART_CONVERSION_ID })
            }
          } else {
            if (config.gtag.ANALYTICSID) {
              ReactGA.event('purchase', {
                value: budget,
                currency: 'USD',
                transaction_id: result.campaignId,
                items: [{ item_name: `${campaignCreateWorkflow.campaignType} Campaign` }],
                send_to: config.gtag.ANALYTICSID
              })
            }
            if (config.gtag.ADWORDSID) {
              ReactGA.event('conversion', { transaction_id: result.campaignId, send_to: 'AW-732436947/mEO9CMKO6NUBENOzoN0C' })
            }
            if (config.facebook.PIXEL_ID) {
              ReactPixel.track('Purchase', {
                value: budget,
                currency: 'USD',
                content_type: `${campaignCreateWorkflow.campaignType} Campaign`
              })
            }
            if (config.linkedin.PARTNER_ID && config.linkedin.PURCHASE_CONVERSION_ID) {
              window.lintrk('track', { conversion_id: config.linkedin.PURCHASE_CONVERSION_ID })
            }
          }
        }
        dispatch(initializeCampaignCreate())
        CreateCampaignStorageManager.DeleteFromLocalStorage()
        if (saveForLater) {
          dispatch(showSuccessToast('Successfully saved your campaign'))
          history.push(`/campaigns/edit/${result.campaignId}/details`)
        } else {
          dispatch(showSuccessToast('Successfully activated your campaign'))
          history.push(`/build/finished/${result.campaignId}/${campaignCreateWorkflow.campaignType}`)
        }
      })
      .finally(() => {
        setSaving(false)
        setActivating(false)
      })
  }

  const onBackClick = () => {
    props.onBack?.()
  }

  const updatePaymentMethodPickerState = (newState: IPaymentMethodPickerState) => {
    if (newState.selectedCampaignSlotId && newState.selectedCampaignSlotId.length > 0) {
      setShowBudget(false)
    } else {
      setShowBudget(true)
    }

    setPaymentMethodPickerState(newState)
  }

  const minBudget = GetBudgetMinimum(campaignType, launchSettings.isTotalBudget)

  const requiresCustomization = creativeInfo.isCustom || (creativeInfo.creativeType === CreativeType.Video && (creativeInfo.videoDetails ?? []).some(v => (v.uploadedUrl ?? '').length <= 0))

  const isValid = useCallback(() => {
    const customizationNeeded = creativeInfo.isCustom || (creativeInfo.creativeType === CreativeType.Video && (creativeInfo.videoDetails ?? []).some(v => (v.uploadedUrl ?? '').length <= 0))
    if (launchSettings.isTotalBudget && (!start || !end)) {
      return false
    }

    if (!launchSettings.budget || launchSettings.budget < minBudget) {
      return false
    }

    if (creativeInfo.isCustom && (!launchSettings.contactName || !launchSettings.phone || !launchSettings.email)) {
      return false
    }

    if (launchSettings.name.length === 0) {
      return false
    }

    if (customizationNeeded && (launchSettings.contactName.length < 1 || launchSettings.contactName.length < 1 || launchSettings.contactName.length < 1)) {
      return false
    }

    return builderComplete.isComplete
  }, [launchSettings, creativeInfo, minBudget, builderComplete])

  const campType = campaignType === BuilderCampaignType.TV ? CampaignType.TV : campaignType === BuilderCampaignType.Billboard ? CampaignType.Billboard : CampaignType.Internet

  return (
    <React.Fragment>
      <Helmet>
        <title>{creativeInfo.isCustom ? 'Submit' : 'Activate'} Your Campaign</title>
      </Helmet>
      <EuiTitle size='s'>
        <h2>{creativeInfo.isCustom ? 'Submit' : 'Activate'} Your Campaign</h2>
      </EuiTitle>
      <EuiSpacer size='m' />
      <EuiFormRow label='Campaign Name' fullWidth>
        <EuiFieldText
          fullWidth
          value={launchSettings.name || ''}
          onChange={e =>
            setLaunchSettings({
              ...launchSettings,
              name: e.target.value
            })
          }
        />
      </EuiFormRow>
      <EuiSpacer />
      {organizationBundles.isLoading || organizationBundles.isFetching ? (
        <EuiLoadingContent />
      ) : (
        <React.Fragment>
          <React.Fragment>
            {!whiteLabel?.isAgencies() && showBudget && (
              <React.Fragment>
                <EuiFormRow label={`Weekly Budget (${minBudget} minimums)`} fullWidth>
                  <EuiFieldNumber
                    fullWidth
                    prepend={'$'}
                    value={launchSettings.budget || ''}
                    onChange={e => {
                      setLaunchSettings({ ...launchSettings, budget: e.target.valueAsNumber })
                    }}
                    min={minBudget}
                  />
                </EuiFormRow>
                <EuiSpacer />
              </React.Fragment>
            )}
            {whiteLabel?.isAgencies() && (
              <React.Fragment>
                <EuiText size='xs'>
                  <strong>Campaign Schedule</strong>
                </EuiText>
                <EuiSpacer size='xs' />
                <EuiCheckableCard
                  id={'indefinite'}
                  label={<strong>Run campaign indefinitely</strong>}
                  name={'method'}
                  checked={!launchSettings.isTotalBudget}
                  onChange={() => {
                    const newMinBudget = GetBudgetMinimum(campaignType, false)
                    setLaunchSettings({ ...launchSettings, isTotalBudget: false, budget: launchSettings.budget < newMinBudget ? newMinBudget : launchSettings.budget })
                  }}>
                  {!launchSettings.isTotalBudget && (
                    <EuiFormRow
                      label={
                        <EuiText size='xs'>
                          <strong>Weekly Budget (${minBudget} minimum)</strong>
                        </EuiText>
                      }
                      fullWidth>
                      <EuiFieldNumber
                        fullWidth
                        prepend={'$'}
                        value={launchSettings.budget || ''}
                        onChange={e => {
                          setLaunchSettings({ ...launchSettings, budget: e.target.valueAsNumber })
                        }}
                        isInvalid={launchSettings.budget < minBudget}
                        min={minBudget}
                      />
                    </EuiFormRow>
                  )}
                </EuiCheckableCard>
                <EuiSpacer size='m' />
                <EuiCheckableCard
                  id={htmlIdGenerator()()}
                  label={<strong>Run campaign within a specific date range.</strong>}
                  name={'method'}
                  checked={launchSettings.isTotalBudget}
                  onChange={() => {
                    const newMinBudget = GetBudgetMinimum(campaignType, true)
                    setLaunchSettings({ ...launchSettings, isTotalBudget: true, budget: launchSettings.budget < newMinBudget ? newMinBudget : launchSettings.budget })
                  }}>
                  {launchSettings.isTotalBudget && (
                    <React.Fragment>
                      <EuiFormRow
                        label={
                          <EuiText size='xs'>
                            <strong>Start Date</strong>
                          </EuiText>
                        }
                        fullWidth>
                        <EuiDatePicker fullWidth selected={start} dateFormat='MM/DD/yyyy' onChange={d => setStart(d)} minDate={moment().add(3, 'day')} showTimeSelect={false} />
                      </EuiFormRow>
                      <EuiFormRow
                        label={
                          <EuiText size='xs'>
                            <strong>End Date</strong>
                          </EuiText>
                        }
                        fullWidth>
                        <EuiDatePicker fullWidth selected={end} dateFormat='MM/DD/yyyy' onChange={d => setEnd(d)} minDate={start ?? moment().add(3, 'day')} showTimeSelect={false} />
                      </EuiFormRow>
                      <EuiFormRow
                        hidden={!showBudget}
                        label={
                          <EuiText size='xs'>
                            <strong>Total Budget (${minBudget} minimum)</strong>
                          </EuiText>
                        }
                        helpText={'Your total budget will be evenly spread across the life of your campaign.'}
                        fullWidth>
                        <EuiFieldNumber
                          fullWidth
                          prepend={'$'}
                          value={launchSettings.budget || ''}
                          onChange={e => {
                            setLaunchSettings({ ...launchSettings, budget: e.target.valueAsNumber })
                          }}
                          isInvalid={launchSettings.budget < minBudget}
                          min={minBudget}
                        />
                      </EuiFormRow>
                    </React.Fragment>
                  )}
                </EuiCheckableCard>
                <EuiSpacer />
              </React.Fragment>
            )}
            {currentAccount?.id && <PaymentMethodPicker campaignType={campType} paymentMethodPickerState={{ selectedPaymentMethodId, selectedCampaignSlotId }} updatePaymentMethodPickerState={updatePaymentMethodPickerState} onAddPaymentMethodClicked={() => {}} />}
            <EuiSpacer />

            {creativeInfo.isCustom && (
              <EuiCallOut title='Submitting Your Campaign' iconType='bell'>
                <p>Upon submitting your campaign, AdCritter will contact you to discuss creating your custom ads.</p>
                <p>Your card will not be charged until your campaign is finished and approved to run.</p>
              </EuiCallOut>
            )}
            {!creativeInfo.isCustom && (
              <EuiCallOut title='Activate Your Campaign' iconType='bell'>
                {availableCampaignSlots.length <= 0 && <p>Upon activating your campaign, you will be charged for {launchSettings.isTotalBudget ? 'the total amount of your campaign' : 'your first week of advertising'}, based on the budget you set.</p>}
                <p>Your ads will be submitted for approval with all the major ad exchanges. The approval process takes two to three business days to complete. Once approved, your ad will begin to run automatically.</p>
              </EuiCallOut>
            )}
          </React.Fragment>

          {requiresCustomization && (
            <React.Fragment>
              <EuiSpacer />
              <EuiTitle size='s'>
                <h2>Customizations Contact</h2>
              </EuiTitle>
              <EuiSpacer size='m' />
              <p>{creativeInfo.isCustom ? 'Best contact to discuss your custom ads request.' : 'Best contact to discuss your commercial.'}</p>
              <EuiSpacer size='s' />
              <div id='customContactName'>
                <EuiFormRow label='Name' fullWidth>
                  <EuiFieldText
                    fullWidth
                    value={launchSettings.contactName || ''}
                    onChange={e => {
                      setLaunchSettings({ ...launchSettings, contactName: e.target.value })
                    }}
                  />
                </EuiFormRow>
                <EuiSpacer size='s' />
              </div>
              <div id='phone'>
                <EuiFormRow label='Phone' fullWidth>
                  <EuiFieldText
                    fullWidth
                    value={launchSettings.phone || ''}
                    onChange={e => {
                      setLaunchSettings({ ...launchSettings, phone: e.target.value })
                    }}
                  />
                </EuiFormRow>
                <EuiSpacer size='s' />
              </div>
              <div id='email'>
                <EuiFormRow label='Email' fullWidth>
                  <EuiFieldText
                    fullWidth
                    value={launchSettings.email || ''}
                    onChange={e => {
                      setLaunchSettings({ ...launchSettings, email: e.target.value })
                    }}
                    isInvalid={!!launchSettings.email}
                  />
                </EuiFormRow>
                <EuiSpacer size='s' />
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <EuiSpacer />

      <EuiShowFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          {!requiresCustomization && builderComplete.isComplete && (
            <EuiFlexItem grow={false}>
              <EuiButton id='activate' fill iconType='check' onClick={activateCampaign} isDisabled={(!selectedPaymentMethodId && !selectedCampaignSlotId) || !isValid() || saving} isLoading={campaignBuilderActivateQuery.isLoading && activating}>
                Activate
              </EuiButton>
            </EuiFlexItem>
          )}
          <EuiFlexItem grow={false}>
            <EuiButton id='save' iconType={requiresCustomization ? 'check' : 'save'} iconSide={requiresCustomization ? 'right' : 'left'} onClick={saveForLaterCampaign} isDisabled={!isValid() || activating} isLoading={campaignBuilderActivateQuery.isLoading && saving}>
              {requiresCustomization ? 'Submit to Ad Team' : 'Save for Later'}
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill color='text' onClick={onBackClick} disabled={campaignBuilderActivateQuery.isLoading}>
              Back
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill color='text' iconType='arrowLeft' iconSide='left' onClick={onBackClick} disabled={campaignBuilderActivateQuery.isLoading}>
              Back
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='save' iconType={requiresCustomization ? 'check' : 'save'} iconSide={requiresCustomization ? 'right' : 'left'} onClick={saveForLaterCampaign} isDisabled={!isValid() || activating} isLoading={campaignBuilderActivateQuery.isLoading && saving}>
              {requiresCustomization ? 'Submit to Ad Team' : 'Save for Later'}
            </EuiButton>
          </EuiFlexItem>
          {!requiresCustomization && (
            <EuiFlexItem grow={false}>
              <EuiButton id='activate' fill iconType='check' onClick={activateCampaign} isDisabled={(!selectedPaymentMethodId && !selectedCampaignSlotId) || !isValid() || saving} isLoading={campaignBuilderActivateQuery.isLoading && activating}>
                Activate
              </EuiButton>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiHideFor>
      {!builderComplete.isComplete && (
        <React.Fragment>
          <EuiSpacer />
          <EuiCallOut title='Incomplete Campaign' iconType='folderExclamation' color={'warning'}>
            {!builderComplete.isCreateComplete && (
              <React.Fragment>
                <EuiSpacer size={'s'} />
                <EuiHealth color={'subdued'}>
                  <EuiLink onClick={() => dispatch(setCampaignCreateStep(CampaignCreateStep.CreativeCreate))}>
                    <EuiText size={'s'}>Missing Ad</EuiText>
                  </EuiLink>
                </EuiHealth>
              </React.Fragment>
            )}
            {!builderComplete.isTargetComplete && (
              <React.Fragment>
                <EuiSpacer size={'s'} />
                <EuiHealth color={'subdued'}>
                  <EuiLink onClick={() => dispatch(setCampaignCreateStep(CampaignCreateStep.Target))}>Missing Targeting</EuiLink>
                </EuiHealth>
              </React.Fragment>
            )}
            {!builderComplete.isDeliveryComplete && (
              <React.Fragment>
                <EuiSpacer size={'s'} />
                <EuiHealth color={'subdued'}>
                  <EuiLink onClick={() => dispatch(setCampaignCreateStep(CampaignCreateStep.Deliver))}>Missing Delivery</EuiLink>
                </EuiHealth>
              </React.Fragment>
            )}
          </EuiCallOut>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
