import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { EuiTitle, EuiPageHeader, EuiPageHeaderSection, EuiButtonEmpty } from '@elastic/eui'

import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

import { fetchAd } from './adSlice'
import { default as BillboardView } from './billboard/View'
import { default as InternetAdView } from './internet/View'
import { default as DisplayAdView } from './internet/ViewDisplayAd'
import { default as TvCommercialView } from './tv/View'

interface EditAdPageParams {
  adId: string
  campaignId: string
}

const EditAdPage: React.FC = () => {
  let { adId, campaignId } = useParams<EditAdPageParams>()
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const { ad } = useSelector((state: RootState) => state)

  useEffect(() => {
    if (currentAccount && adId) {
      dispatch(fetchAd(currentAccount.id, adId))
    }
  }, [currentAccount, adId, dispatch])

  return (
    <React.Fragment>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle>
            <h1>Ad</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
        <EuiPageHeaderSection>
          <EuiButtonEmpty id='back' onClick={() => history.push(`/campaigns/edit/${campaignId}`)} iconType='arrowLeft' iconSide='left' size='s'>
            Back To All Ads
          </EuiButtonEmpty>
        </EuiPageHeaderSection>
      </EuiPageHeader>

      {ad?.ad && ad.ad.type === 'Internet' && ad.ad.mediaType === 'Native' && <InternetAdView />}
      {ad?.ad && ad.ad.type === 'Internet' && ad.ad.mediaType === 'Display' && <DisplayAdView />}
      {ad?.ad && ad.ad.type === 'TV' && <TvCommercialView />}
      {ad?.ad && ad.ad.type === 'Billboard' && <BillboardView />}
    </React.Fragment>
  )
}

export default EditAdPage
