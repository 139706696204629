import React from 'react'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiHideFor, EuiShowFor } from '@elastic/eui'

export interface IBackNextFooter {
  isBackDisabled?: boolean
  isNextDisabled?: boolean
  isNextHidden?: boolean
  onBack?: () => void
  onNext?: () => void
  backText?: string
  nextText?: string
}

export const BackNextFooter: React.FC<IBackNextFooter> = props => {
  const onContinueClick = () => {
    props.onNext?.()
  }

  const onBackClick = () => {
    props.onBack?.()
  }

  return (
    <React.Fragment>
      <EuiShowFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='finalize' onClick={onContinueClick} fill isDisabled={props.isNextDisabled}>
              {props.nextText ?? 'Continue'}
            </EuiButton>
          </EuiFlexItem>
          {!props.isNextHidden && (
            <EuiFlexItem grow={false}>
              <EuiButton id='back' fill type='button' color='text' onClick={onBackClick} isDisabled={props.isBackDisabled}>
                {props.backText ?? 'Back'}
              </EuiButton>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={onBackClick} iconType='arrowLeft' iconSide='left' isDisabled={props.isBackDisabled}>
              {props.backText ?? 'Back'}
            </EuiButton>
          </EuiFlexItem>
          {!props.isNextHidden && (
            <EuiFlexItem grow={false}>
              <EuiButton id='finalize' fill onClick={onContinueClick} iconType='arrowRight' iconSide='right' isDisabled={props.isNextDisabled}>
                {props.nextText ?? 'Continue'}
              </EuiButton>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiHideFor>
    </React.Fragment>
  )
}
