import React from 'react'
import { BuilderCampaignType } from 'workflows/CampaignCreateWorkflow'

import { EuiFormRow, EuiSpacer, EuiTitle } from '@elastic/eui'

import { CampaignType } from 'api/interfaces'
import { AudienceTargeting } from 'api/interfaces/CampaignTargeting'
import { NewSegmentFinder } from 'components/finders/NewSegmentFinder'
import { AudienceTargeterDetailed } from 'components/targeters/AudienceTargeterDetailed'

interface IAudienceTargeter {
  audienceDetails: AudienceTargeting
  setAudienceDetails: (audience: AudienceTargeting) => void
  title?: string
  campaignType: BuilderCampaignType
}

export const AudienceTargeter = ({ audienceDetails, setAudienceDetails, title, campaignType }: IAudienceTargeter) => {
  return (
    <React.Fragment>
      <EuiTitle size='s'>
        <span>{title}</span>
      </EuiTitle>
      <EuiSpacer size='s' />
      <EuiFormRow label='Search for unique audience characteristics (i.e. homeowners, luxury goods, etc.)' fullWidth>
        <NewSegmentFinder
          campaignType={campaignType === BuilderCampaignType.Billboard ? CampaignType.Billboard : campaignType === BuilderCampaignType.TV ? CampaignType.TV : CampaignType.Internet}
          isInvalid={false}
          onSegmentClicked={segment => {
            setAudienceDetails({ ...audienceDetails, segments: [...audienceDetails.segments.filter(s => s.id !== segment.id), segment] })
          }}
        />
      </EuiFormRow>
      {audienceDetails.segments.length > 0 && (
        <EuiFormRow label='Your campaign will prioritize individuals in any of these categories' fullWidth>
          <AudienceTargeterDetailed
            audiences={audienceDetails.segments.map(s => ({
              id: s.id,
              name: s.name,
              description: s.description ?? '',
              provider: '',
              reachCount: -1,
              price: -1,
              hierarchy: [],
              sourceDetails: ''
            }))}
            onAudienceRemoved={audience => {
              const updatedSegments = audienceDetails.segments.filter(s => s.id !== audience.id)
              setAudienceDetails({ ...audienceDetails, segments: updatedSegments })
            }}
          />
        </EuiFormRow>
      )}
    </React.Fragment>
  )
}
