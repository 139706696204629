import moment from 'moment/moment'
import React, { FC } from 'react'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiHorizontalRule, EuiPanelProps, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { Account, SelfServicePackage } from 'api/interfaces'
import { useAssignSelfServicePackageMutation } from 'api/rtkQueryApi/platform/bundlesApi'
import HandleWrapInPanel from 'components/HandleWrapInPanel'
import { AccountPickerModal } from 'features/selfServicePackages/modals/AccountPickerModal'

export interface AvailableSelfServicePackageItemSmallProps {
  selfServicePackage: SelfServicePackage
  onAssigned?: () => void
  onViewClick?: () => void
  wrapInPanel?: boolean
  panelProps?: EuiPanelProps
}

const AvailableSelfServicePackageItemSmall: FC<AvailableSelfServicePackageItemSmallProps> = ({ selfServicePackage, onAssigned, onViewClick, wrapInPanel, panelProps }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'percent'
  })

  const [showAssignModal, setShowAssignModal] = React.useState<boolean>(false)
  const [assignBundle, assignBundleQuery] = useAssignSelfServicePackageMutation()

  let matchingPercentage = selfServicePackage.matchingPercentage
  if (!matchingPercentage) {
    const paidSlotsAmount = selfServicePackage.campaignSlots.filter(slot => !slot.isBonus).reduce((acc, slot) => acc + slot.budget, 0)
    const bonusSlotsAmount = selfServicePackage.campaignSlots.filter(slot => slot.isBonus).reduce((acc, slot) => acc + slot.budget, 0)

    if (paidSlotsAmount > 0) {
      matchingPercentage = bonusSlotsAmount / paidSlotsAmount
    } else {
      matchingPercentage = 0
    }
  }

  const onModalClose = () => {
    setShowAssignModal(false)
  }

  const onAccountConfirm = (account: Account) => {
    assignBundle({ bundleId: selfServicePackage.id, accountId: account.id }).then(() => {
      setShowAssignModal(false)
      onAssigned?.()
    })
  }

  return (
    <React.Fragment>
      <HandleWrapInPanel wrapInPanel={wrapInPanel ?? false} panelProps={panelProps ?? { hasShadow: false, hasBorder: true }}>
        <EuiFlexGroup gutterSize='none' direction='column' style={{ width: '100%', height: '100%' }}>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup alignItems='center'>
              <EuiFlexItem grow={false}>
                <EuiTitle size={'xs'}>
                  <h3>{selfServicePackage.salesTitle}</h3>
                </EuiTitle>
              </EuiFlexItem>

              <EuiFlexItem grow={true} />

              <EuiFlexItem grow={false}>
                <EuiText size='xs' color='subdued'>
                  Id:{'\u00A0'}
                  {selfServicePackage.id.slice(0, 8)}
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>

          <EuiSpacer size='s' />

          <EuiFlexItem grow={false}>
            <EuiFormRow fullWidth>
              <React.Fragment>
                {selfServicePackage.salesShortDescription &&
                  selfServicePackage.salesShortDescription.split('\n').map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        {i > 0 && <EuiSpacer size={'xs'} />}
                        {item.length > 0 && <EuiText size={'s'}>{item}</EuiText>}
                      </React.Fragment>
                    )
                  })}
              </React.Fragment>
            </EuiFormRow>
          </EuiFlexItem>

          <EuiSpacer size='l' />
          <EuiFlexItem grow={true} />

          <EuiFlexItem grow={false}>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiFlexGroup direction='column' gutterSize='xs'>
                  <EuiFlexItem grow={true} />

                  <EuiFlexItem grow={false}>
                    <EuiTitle size='xs'>
                      <h4>{`${formatter.format(matchingPercentage)} matching`}</h4>
                    </EuiTitle>
                  </EuiFlexItem>

                  <EuiFlexItem grow={true} />
                </EuiFlexGroup>
              </EuiFlexItem>

              <EuiFlexItem />

              <EuiFlexItem grow={false}>
                <EuiButton fill onClick={onViewClick} size={'s'}>
                  View
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexItem grow={false}>
              <EuiHorizontalRule margin={'s'} />
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText size={'s'}>
                <b>
                  <em>Available until {moment(selfServicePackage.expirationDate).format('MM/DD/yyyy')}</em>
                </b>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </HandleWrapInPanel>
      {showAssignModal && <AccountPickerModal selfServicePackage={selfServicePackage} onModalClose={onModalClose} onAccountConfirm={onAccountConfirm} isLoading={assignBundleQuery.isLoading} />}
    </React.Fragment>
  )
}

export default AvailableSelfServicePackageItemSmall
