import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { updateScreenSize } from 'app/eventsSlice'

export default function useListenScreenSize() {
  const dispatch = useDispatch()

  useEffect(() => {
    const handleResize = () => {
      const innerWidth = window.innerWidth
      const pixelRatio = window.devicePixelRatio

      const ratioWidth = innerWidth / pixelRatio

      const bp = {
        sm: ratioWidth < 440,
        md: ratioWidth >= 440 && ratioWidth < 1440,
        lg: ratioWidth >= 1440,
        width: window.innerWidth,
        height: window.innerHeight
      }
      dispatch(updateScreenSize(bp))
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
}
