import numeral from 'numeral'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiBadge, EuiFieldSearch, EuiInputPopover, EuiSelectable, EuiText, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { City } from 'api/interfaces'
import { clearCities, fetchCities } from 'app/dictionarySlice'
import { RootState } from 'app/rootReducer'
import useDebounce from 'utils/useDebounce'

interface CityTargeterProps {
  isInvalid: boolean
  onCityClicked(city: City): void
}

export const CityFinder = ({ onCityClicked, isInvalid }: CityTargeterProps) => {
  const dispatch = useDispatch()
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const [citySearchTerm, setCitySearchTerm] = useState<string>('')
  const debouncedCitySearchTerm = useDebounce(citySearchTerm, 750)
  const [searchCityPopoverOpen, setSearchCityPopoverOpen] = useState<boolean>(false)
  const [cities, setCities] = useState<Array<EuiSelectableOption>>([])

  const onSearchCity = () => {
    setSearchCityPopoverOpen(false)
  }

  const onSearchCityChange = (hint: string) => {
    setCitySearchTerm(hint)
  }

  const onSearchCityClick = useCallback(
    (city: City) => {
      setSearchCityPopoverOpen(false)
      setCitySearchTerm('')
      onCityClicked(city)
    },
    [onCityClicked]
  )

  useEffect(() => {
    if (debouncedCitySearchTerm) {
      dispatch(fetchCities(debouncedCitySearchTerm))
    } else {
      dispatch(clearCities())
    }
  }, [debouncedCitySearchTerm, dispatch])

  useEffect(() => {
    if (dictionary.cities) {
      setCities(
        dictionary.cities.map(
          c =>
            ({
              label: `${c.name}, ${c.stateCode}`,
              key: c.id.toString(),
              checked: undefined,
              append: (
                <>
                  <EuiBadge color='primary'>{numeral(c.population).format('0a')} people</EuiBadge>
                  <EuiBadge color='primary'>{numeral(c.households).format('0a')} households</EuiBadge>
                </>
              ),
              onClick: () => onSearchCityClick(c)
            } as EuiSelectableOption)
        )
      )
    }
  }, [dictionary.cities, onSearchCityClick])

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          value={citySearchTerm}
          isLoading={dictionary.isLoadingCities}
          onChange={v => onSearchCityChange(v.target.value)}
          onFocus={() => setSearchCityPopoverOpen(true)}
          incremental={false}
          onSearch={() => onSearchCity()}
          placeholder='Enter one or more cities...'
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
          isInvalid={isInvalid}
        />
      }
      isOpen={searchCityPopoverOpen}
      closePopover={() => setSearchCityPopoverOpen(false)}>
      {dictionary.cities && dictionary.cities.length ? (
        <EuiSelectable singleSelection options={cities}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search... results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
