import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'

import { apiDeleteCampaign, apiGetCampaigns, apiPutCampaignStatusActive, apiPutCampaignStatusArchived, apiPutCampaignStatusPaused } from 'api/adcritterApi'
import { Campaign, CampaignActiveStatus, CampaignStatus } from 'api/interfaces'
import { campaignsApi } from 'api/rtkQueryApi/platform/campaignsApi'
import { AppThunk } from 'app/store'

export interface CampaignState {
  id: string
  checked: boolean
  canRemove: boolean
  canUnpauseWithoutPayment: boolean
  canArchive: boolean
  disabled: boolean
  loading: boolean
  name: string
  budget: number
  budgetFrequency: string
  status: CampaignStatus
  type: string
  hasBundle: boolean
  endingOnDate: moment.Moment | null
  isMatching: boolean
}

interface CampaignsState {
  errors: Error[] | null
  isLoadingCampaigns: boolean
  campaigns: Campaign[]
  selectedCampaign: Campaign | null
  campaignStates: CampaignState[]
  isRemovingCampaign: boolean
}

const initialState: CampaignsState = {
  errors: null,
  campaigns: [],
  isLoadingCampaigns: false,
  selectedCampaign: null,
  campaignStates: [],
  isRemovingCampaign: false
}

const campaigns = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    startLoadingCampaigns(state: CampaignsState) {
      state.isLoadingCampaigns = true
    },
    finishedLoadingCampaigns(state: CampaignsState, { payload }: PayloadAction<Campaign[]>) {
      state.isLoadingCampaigns = false
      state.campaigns = payload
      state.campaignStates = payload.map(c => ({
        id: c.id,
        checked: c.activeStatus === CampaignActiveStatus.Active && c.status !== CampaignStatus.EndingOn,
        canRemove: c.canRemove,
        canUnpauseWithoutPayment: c.canUnpauseWithoutPayment,
        canArchive: c.canArchive,
        disabled: !c.canEditStatus,
        name: c.name,
        budget: c.budget,
        budgetFrequency: c.budgetFrequency,
        loading: false,
        status: c.status,
        type: c.type,
        hasBundle: c.hasBundle,
        endingOnDate: c.endingOnDate,
        isMatching: c.isMatching
      }))
    },
    selectCampaign(state: CampaignsState, { payload }: PayloadAction<Campaign>) {
      state.selectedCampaign = payload
    },
    disableCampaignState(state: CampaignsState, { payload }: PayloadAction<string>) {
      const index = state.campaignStates.map(c => c.id).indexOf(payload)
      const campaignState = { ...state.campaignStates[index], disabled: true, loading: true }
      const newCampaignStates = [...state.campaignStates]
      newCampaignStates.splice(index, 1, campaignState)
      state.campaignStates = newCampaignStates
    },
    replaceCampaignState(state: CampaignsState, { payload }: PayloadAction<CampaignState>) {
      const index = state.campaignStates.map(c => c.id).indexOf(payload.id)
      const newCampaignStates = [...state.campaignStates]
      newCampaignStates.splice(index, 1, payload)
      state.campaignStates = newCampaignStates
    },
    startRemovingCampaign(state: CampaignsState) {
      state.isRemovingCampaign = true
    },
    finishedRemovingCampaign(state: CampaignsState) {
      state.isRemovingCampaign = false
    }
  }
})

export const { startLoadingCampaigns, finishedLoadingCampaigns, selectCampaign, replaceCampaignState, disableCampaignState, startRemovingCampaign, finishedRemovingCampaign } = campaigns.actions

export default campaigns.reducer

export const fetchCampaigns =
  (accountId: string): AppThunk =>
  async dispatch => {
    dispatch(startLoadingCampaigns())
    const response = await apiGetCampaigns(accountId)
    dispatch(finishedLoadingCampaigns(response.data))
    dispatch(campaignsApi.util.invalidateTags([{ type: 'Campaigns', id: accountId }]))
  }

export const pauseCampaign =
  (accountId: string, campaignId: string): AppThunk =>
  async dispatch => {
    dispatch(disableCampaignState(campaignId))
    const response = await apiPutCampaignStatusPaused(accountId, campaignId)
    dispatch(
      replaceCampaignState({
        id: response.data.id,
        checked: response.data.activeStatus === CampaignActiveStatus.Active && response.data.status !== CampaignStatus.EndingOn,
        canRemove: response.data.canRemove,
        canUnpauseWithoutPayment: response.data.canUnpauseWithoutPayment,
        canArchive: response.data.canArchive,
        disabled: !response.data.canEditStatus,
        name: response.data.name,
        budget: response.data.budget,
        budgetFrequency: response.data.budgetFrequency,
        loading: false,
        status: response.data.status,
        type: response.data.type,
        hasBundle: response.data.hasBundle,
        endingOnDate: response.data.endingOnDate,
        isMatching: response.data.isMatching
      })
    )
    dispatch(campaignsApi.util.invalidateTags([{ type: 'Campaigns', id: accountId }]))
  }

export const startCampaign =
  (accountId: string, campaignId: string): AppThunk =>
  async dispatch => {
    dispatch(disableCampaignState(campaignId))
    const response = await apiPutCampaignStatusActive(accountId, campaignId)
    dispatch(campaignsApi.util.invalidateTags([{ type: 'Campaigns', id: accountId }]))
    dispatch(
      replaceCampaignState({
        id: response.data.id,
        checked: response.data.activeStatus === CampaignActiveStatus.Active && response.data.status !== CampaignStatus.EndingOn,
        canRemove: response.data.canRemove,
        canUnpauseWithoutPayment: response.data.canUnpauseWithoutPayment,
        canArchive: response.data.canArchive,
        disabled: !response.data.canEditStatus,
        name: response.data.name,
        budget: response.data.budget,
        budgetFrequency: response.data.budgetFrequency,
        loading: false,
        status: response.data.status,
        type: response.data.type,
        hasBundle: response.data.hasBundle,
        endingOnDate: response.data.endingOnDate,
        isMatching: response.data.isMatching
      })
    )
  }

export const removeCampaign =
  (accountId: string, campaignId: string): AppThunk =>
  async dispatch => {
    dispatch(startRemovingCampaign())
    dispatch(startLoadingCampaigns())
    await apiDeleteCampaign(accountId, campaignId)
    dispatch(finishedRemovingCampaign())
    const response = await apiGetCampaigns(accountId)
    dispatch(finishedLoadingCampaigns(response.data))
    dispatch(campaignsApi.util.invalidateTags([{ type: 'Campaigns', id: accountId }]))
  }

export const archiveCampaign =
  (accountId: string, campaignId: string): AppThunk =>
  async dispatch => {
    const response = await apiPutCampaignStatusArchived(accountId, campaignId)
    dispatch(
      replaceCampaignState({
        id: response.data.id,
        checked: response.data.activeStatus === CampaignActiveStatus.Active && response.data.status !== CampaignStatus.EndingOn,
        canRemove: response.data.canRemove,
        canUnpauseWithoutPayment: response.data.canUnpauseWithoutPayment,
        canArchive: response.data.canArchive,
        disabled: !response.data.canEditStatus,
        name: response.data.name,
        budget: response.data.budget,
        budgetFrequency: response.data.budgetFrequency,
        loading: false,
        status: response.data.status,
        type: response.data.type,
        hasBundle: response.data.hasBundle,
        endingOnDate: response.data.endingOnDate,
        isMatching: response.data.isMatching
      })
    )

    dispatch(campaignsApi.util.invalidateTags([{ type: 'Campaigns', id: accountId }]))
  }

export const unarchiveCampaign =
  (accountId: string, campaignId: string): AppThunk =>
  async dispatch => {
    const response = await apiPutCampaignStatusPaused(accountId, campaignId)
    dispatch(
      replaceCampaignState({
        id: response.data.id,
        checked: response.data.activeStatus === CampaignActiveStatus.Active && response.data.status !== CampaignStatus.EndingOn,
        canRemove: response.data.canRemove,
        canUnpauseWithoutPayment: response.data.canUnpauseWithoutPayment,
        canArchive: response.data.canArchive,
        disabled: !response.data.canEditStatus,
        name: response.data.name,
        budget: response.data.budget,
        budgetFrequency: response.data.budgetFrequency,
        loading: false,
        status: response.data.status,
        type: response.data.type,
        hasBundle: response.data.hasBundle,
        endingOnDate: response.data.endingOnDate,
        isMatching: response.data.isMatching
      })
    )

    dispatch(campaignsApi.util.invalidateTags([{ type: 'Campaigns', id: accountId }]))
  }
