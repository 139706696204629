import moment from 'moment'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'
import { EUI_SPARKLINE_THEME_PARTIAL } from '@elastic/eui/dist/eui_charts_theme'

import { Campaign } from 'api/interfaces'
import { MobileDashboardAnalytic } from 'api/interfaces/MobileDashboardAnalytic'
import { RootState } from 'app/rootReducer'
import MobileBundleTile from 'features/mobileDashboard/MobileBundleTile'
import MobileCampaignTile from 'features/mobileDashboard/MobileCampaignTile'
import ScheduleMeetingButton from 'features/mobileDashboard/ScheduleMeetingButton'
import history from 'services/HistoryService'

export interface MobileDashboardNoBundlesProps {
  mobileDashboardAnalytics: MobileDashboardAnalytic[]
  spacerSize?: 'm' | 's' | 'l' | 'xs' | 'xl' | 'xxl'
}

const MobileDashboardNoBundles: FC<MobileDashboardNoBundlesProps> = ({ mobileDashboardAnalytics, spacerSize }) => {
  const { accounts } = useSelector((state: RootState) => state.app)
  const theme = [EUI_SPARKLINE_THEME_PARTIAL]

  return (
    <React.Fragment>
      {mobileDashboardAnalytics.length <= 0 && (
        <React.Fragment>
          <EuiFlexItem grow={false}>
            <EuiButton id='createCampaignBtn' size='s' key='createCampaign' onClick={() => history.push('/build')} fill>
              Create Campaign
            </EuiButton>
          </EuiFlexItem>
        </React.Fragment>
      )}

      {mobileDashboardAnalytics.length > 0 &&
        mobileDashboardAnalytics.map((mobileDashboardAnalytic, index) => {
          return (
            <React.Fragment key={mobileDashboardAnalytic.campaign.id}>
              <MobileCampaignTile mobileDashboardAnalytic={mobileDashboardAnalytic} theme={theme} index={index} />
              {index < mobileDashboardAnalytics.length - 1 && <EuiSpacer size={spacerSize} />}
            </React.Fragment>
          )
        }, [])}

      {mobileDashboardAnalytics.length > 0 && mobileDashboardAnalytics.length <= 2 && accounts.length <= 1 && (
        <React.Fragment>
          <EuiSpacer size={spacerSize} />

          <EuiPanel grow={false}>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiText size='m'>Get a professionally crafted advertising plan that targets your ideal customers with ads that move the needle. All it takes is a quick 15 min call to take it to the next level.</EuiText>
              </EuiFlexItem>

              <EuiSpacer size={spacerSize} />

              <EuiFlexItem grow={false}>
                <ScheduleMeetingButton />
              </EuiFlexItem>

              <EuiSpacer size={spacerSize} />

              <EuiFlexItem grow={false}>
                <EuiButton id='createCampaignBtn' size='s' key='createCampaign' onClick={() => history.push('/build')} fill>
                  Create Campaign
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default MobileDashboardNoBundles
