import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiFieldSearch, EuiInputPopover, EuiSelectable, EuiText, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { DmaTarget } from 'api/interfaces'
import { clearDmaTargets, fetchDmaTargets, filterDmaTargets } from 'app/dictionarySlice'
import { RootState } from 'app/rootReducer'
import useDebounce from 'utils/useDebounce'

interface NewDmaFinderProps {
  isInvalid: boolean

  addDma(dma: DmaTarget): void
}

export const NewDmaFinder = ({ addDma, isInvalid }: NewDmaFinderProps) => {
  const dispatch = useDispatch()
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const [dmaSearchTerm, setDmaSearchTerm] = useState<string>('')
  const debouncedDmaSearchTerm = useDebounce(dmaSearchTerm, 100)
  const [searchDmaPopoverOpen, setSearchDmaPopoverOpen] = useState<boolean>(false)
  const [dmas, setDmas] = useState<Array<EuiSelectableOption>>([])

  const onSearchDma = () => {
    setSearchDmaPopoverOpen(false)
  }

  const onSearchDmaChange = (hint: string) => {
    setDmaSearchTerm(hint)
  }

  useEffect(() => {
    dispatch(fetchDmaTargets())
  }, [])

  useEffect(() => {
    if (debouncedDmaSearchTerm) {
      dispatch(filterDmaTargets(debouncedDmaSearchTerm))
    } else {
      dispatch(clearDmaTargets())
    }
  }, [debouncedDmaSearchTerm, dispatch])

  useEffect(() => {
    if (dictionary.dmaTargetsFiltered) {
      setDmas(
        dictionary.dmaTargetsFiltered.map(
          dma =>
            ({
              label: dma?.name,
              key: dma?.name,
              checked: undefined,
              onClick: () => {
                addDma(dma)
                setSearchDmaPopoverOpen(false)
                setDmaSearchTerm('')
              }
            } as EuiSelectableOption)
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dictionary.dmaTargetsFiltered])

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          value={dmaSearchTerm}
          isLoading={dictionary.isLoadingDmas}
          onChange={v => onSearchDmaChange(v.target.value)}
          onFocus={() => setSearchDmaPopoverOpen(true)}
          incremental={false}
          onSearch={() => onSearchDma()}
          placeholder='Type to begin search...'
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
          isInvalid={isInvalid}
        />
      }
      isOpen={searchDmaPopoverOpen}
      closePopover={() => setSearchDmaPopoverOpen(false)}>
      {dictionary.dmaTargetsFiltered && dictionary.dmaTargetsFiltered.length ? (
        <EuiSelectable singleSelection options={dmas}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search... results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
