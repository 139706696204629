import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import { BillboardDetails, BuilderCampaignType, BusinessInfo, CampaignCreateStep, CampaignCreateWorkflow, CampaignInfo, CreativeBuilderStep, CreativeBuilderType, CreativeCreateStep, CreativeInfo, CreativeType, DeliverStep, DisplayDetails, NativeDetails, TargetStep, VideoDetails } from 'workflows/CampaignCreateWorkflow'

import { FrequencyUnit } from 'api/interfaces'
import { CampaignDelivery } from 'api/interfaces/CampaignDelivery'
import { AudienceTargeting, LocationTargeting, LocationTargetingType, SmartTargeting, TargetingType } from 'api/interfaces/CampaignTargeting'
import { DefaultDayParts } from 'components/targeters/DayPartPicker'
import { AdPlacementType } from 'features/builders/internet/retail/builderInternetRetailSlice'

export const CampaignCreateSlice = createSlice({
  name: 'campaignCreate',
  initialState: {
    id: uuidv4(),
    campaignInfo: {},
    campaignType: BuilderCampaignType.Default,
    campaignTargeting: {},
    creativeInfo: {},
    businessInfo: {},
    campaignCreateStep: CampaignCreateStep.CreativeCreate,
    createWorkflow: {
      creativeCreateStep: CreativeCreateStep.CampaignTypeSelect,
      creativeBuilderWorkflow: {
        creativeBuilderType: CreativeBuilderType.PreMade,
        creativeBuilderStep: CreativeBuilderStep.BusinessInfo
      }
    },
    targetWorkflow: {
      targetStep: TargetStep.Choose
    },
    deliverWorkflow: {
      deliverStep: DeliverStep.Delivery
    }
  } as CampaignCreateWorkflow,
  reducers: {
    setCampaignCreateWorkflow: (state, action: PayloadAction<CampaignCreateWorkflow>) => {
      const details = action.payload
      if (details) {
        state.id = details.id
        state.campaignType = details.campaignType
        state.campaignInfo = details.campaignInfo
        state.creativeInfo = details.creativeInfo
        state.campaignTargeting = details.campaignTargeting
        state.businessInfo = details.businessInfo
        state.campaignCreateStep = details.campaignCreateStep
        state.createWorkflow = details.createWorkflow
        state.targetWorkflow = details.targetWorkflow
        state.deliverWorkflow = details.deliverWorkflow
      }
    },
    initializeCampaignCreate: state => {
      state.id = uuidv4()
      state.campaignType = BuilderCampaignType.Default
      state.campaignInfo = {
        name: '',
        budget: 0,
        contactName: '',
        phone: '',
        email: '',
        isTotalBudget: false
      }
      state.campaignTargeting = {
        targetingType: TargetingType.Location,
        smartTargeting: {
          streetAddress: '',
          city: '',
          state: '',
          zip: '',
          walkInTargeting: false,
          walkInUsePrimary: false,
          walkInAddresses: [],
          competitorTargeting: false,
          competitors: []
        },
        locationTargeting: {
          targetingType: LocationTargetingType.City,
          regions: [],
          dmas: [],
          cities: [],
          zips: []
        },
        audienceTargeting: {
          demographics: {
            ageRanges: [],
            householdIncomes: [],
            genders: []
          },
          segments: [],
          uploadedAudienceUrl: '',
          retargeting: {
            retargetCampaignIds: [],
            retargetWebsiteUrls: [],
            entireSite: false,
            specificPages: false
          }
        },
        campaignDelivery: {
          dayParts: DefaultDayParts,
          frequency: 0,
          frequencyUnit: FrequencyUnit.hour,
          inventorySettings: { targetApp: false, targetWeb: true },
          deviceSettings: { targetPc: true, targetPhone: true, targetTablet: true },
          placement: AdPlacementType.Everywhere,
          categories: [],
          specific: []
        }
      }
      state.creativeInfo = {
        creativeType: CreativeType.Native,
        creativeId: uuidv4()
      }
      state.businessInfo = {}
      state.campaignCreateStep = CampaignCreateStep.CreativeCreate
      state.createWorkflow = {
        creativeCreateStep: CreativeCreateStep.CampaignTypeSelect,
        creativeBuilderWorkflow: {
          creativeBuilderType: CreativeBuilderType.PreMade,
          creativeBuilderStep: CreativeBuilderStep.BusinessInfo
        }
      }
      state.targetWorkflow = {
        targetStep: TargetStep.Choose
      }
      state.deliverWorkflow = {
        deliverStep: DeliverStep.Delivery
      }
    },
    setCampaignType: (state, action: PayloadAction<BuilderCampaignType>) => {
      state.campaignType = action.payload
    },
    setCampaignInfo: (state, action: PayloadAction<CampaignInfo>) => {
      state.campaignInfo = action.payload
    },
    setBusinessInfo: (state, action: PayloadAction<BusinessInfo>) => {
      state.businessInfo = action.payload
    },
    setCampaignCreateStep: (state, action: PayloadAction<CampaignCreateStep>) => {
      state.campaignCreateStep = action.payload
    },
    setCreativeCreateStep: (state, action: PayloadAction<CreativeCreateStep>) => {
      state.createWorkflow.creativeCreateStep = action.payload
    },
    setCreativeBuilderType: (state, action: PayloadAction<CreativeBuilderType>) => {
      state.createWorkflow.creativeBuilderWorkflow.creativeBuilderType = action.payload
    },
    setCreativeBuilderStep: (state, action: PayloadAction<CreativeBuilderStep>) => {
      state.createWorkflow.creativeBuilderWorkflow.creativeBuilderStep = action.payload
      state.creativeInfo.isCustom = false
    },
    setTargetStep: (state, action: PayloadAction<TargetStep>) => {
      state.targetWorkflow.targetStep = action.payload
    },
    setTargetingType: (state, action: PayloadAction<TargetingType>) => {
      state.campaignTargeting.targetingType = action.payload
    },
    setDeliverStep: (state, action: PayloadAction<DeliverStep>) => {
      state.deliverWorkflow.deliverStep = action.payload
    },
    setCreativeInfo: (state, action: PayloadAction<CreativeInfo>) => {
      state.creativeInfo = action.payload
    },
    setCreativeType: (state, action: PayloadAction<CreativeType>) => {
      state.creativeInfo.creativeType = action.payload
    },
    setCreativeId: (state, action: PayloadAction<string>) => {
      state.creativeInfo.creativeId = action.payload
    },
    setNativeDetails: (state, action: PayloadAction<NativeDetails[]>) => {
      state.creativeInfo.nativeDetails = action.payload
    },
    setDisplayDetails: (state, action: PayloadAction<DisplayDetails[]>) => {
      state.creativeInfo.displayDetails = action.payload
    },
    setVideoDetails: (state, action: PayloadAction<VideoDetails[]>) => {
      state.creativeInfo.videoDetails = action.payload
    },
    setBillboardDetails: (state, action: PayloadAction<BillboardDetails[]>) => {
      state.creativeInfo.billboardDetails = action.payload
    },
    setSmartTargeting: (state, action: PayloadAction<SmartTargeting>) => {
      state.campaignTargeting.smartTargeting = action.payload
    },
    setLocationTargeting: (state, action: PayloadAction<LocationTargeting>) => {
      state.campaignTargeting.locationTargeting = action.payload
    },
    setAudienceTargeting: (state, action: PayloadAction<AudienceTargeting>) => {
      state.campaignTargeting.audienceTargeting = action.payload
    },
    setCampaignDelivery: (state, action: PayloadAction<CampaignDelivery>) => {
      state.campaignTargeting.campaignDelivery = action.payload
    }
  }
})

export const { setCampaignCreateWorkflow, initializeCampaignCreate, setCampaignType, setCampaignInfo, setCampaignCreateStep, setTargetStep, setCreativeCreateStep, setCreativeBuilderType, setCreativeBuilderStep, setBusinessInfo, setCreativeInfo, setCreativeType, setNativeDetails, setDisplayDetails, setVideoDetails, setBillboardDetails, setTargetingType, setCampaignDelivery, setLocationTargeting, setAudienceTargeting, setSmartTargeting, setCreativeId } =
  CampaignCreateSlice.actions
