import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { EuiButton, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiHideFor, EuiShowFor, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

const QuestionnaireStep: React.FC = () => {
  const { isVendastaUser } = useSelector((state: RootState) => state.app)

  const gotoStart = () => {
    history.push('/build/billboards/public/start')
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Custom Billboards</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Custom Billboards</h3>
      </EuiTitle>
      <EuiSpacer />
      <EuiCallOut title='We Will Contact You'>
        <EuiText size='s'>
          <p>In order to create a custom billboard for your business, we will need to contact you to gather details (so we can make great ads).</p>
          {isVendastaUser ? <p>Please continue setting up your campaign.</p> : <p>Please continue setting up your campaign and at the end of the process we will verify your contact information so your campaign advisor can get in touch.</p>}
        </EuiText>
      </EuiCallOut>
      <EuiSpacer />

      <EuiShowFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='submit' fill type='submit' onClick={() => history.push('/build/billboards/public/target')}>
              Continue to Targeting
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={gotoStart}>
              Back
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={gotoStart} iconType='arrowLeft' iconSide='left'>
              Back
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='submit' fill type='submit' onClick={() => history.push('/build/billboards/public/target')} iconType='arrowRight' iconSide='right'>
              Continue to Targeting
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiHideFor>
    </React.Fragment>
  )
}

export default QuestionnaireStep
