import React from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiCard, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiSpacer, EuiText } from '@elastic/eui'

import { CampaignSubType } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import { resetSearchImage } from 'components/chooseImage/searchImageSlice'
import history from 'services/HistoryService'

import { CreateAdStep, CreateAdStyle, resetCreateAd, setAd, setCreateAdStep, setCreateAdStyle } from './createAdInternetRetailSlice'

const StartStep: React.FC = () => {
  const dispatch = useDispatch()
  const campaign = useSelector((state: RootState) => state.campaign)
  const { currentAccount } = useSelector((state: RootState) => state.app)

  const setGeneral = () => {
    dispatch(resetSearchImage())
    dispatch(setCreateAdStyle(CreateAdStyle.Suggested))
    history.push('/campaigns/internet/public/suggest')
  }

  const setBuild = () => {
    dispatch(resetSearchImage())
    dispatch(
      setAd({
        id: '',
        headline: '',
        body: '',
        companyName: currentAccount!.name,
        callToAction: 'Learn More',
        destinationUrl: currentAccount!.website
      })
    )
    dispatch(setCreateAdStyle(CreateAdStyle.Build))
    dispatch(setCreateAdStep(CreateAdStep.Create))
    history.push('/campaigns/internet/public/create')
  }

  const setUpload = () => {
    dispatch(setCreateAdStyle(CreateAdStyle.Upload))
    history.push('/campaigns/internet/public/upload')
  }

  const gotoStart = () => {
    dispatch(resetCreateAd())
    if (campaign.campaign) {
      history.push(`/campaigns/edit/${campaign.campaign.id}/details`)
    } else {
      history.push('/campaigns')
    }
  }

  const cardWidth = 280

  const SuggestedAdClicked = () => {
    setGeneral()
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Create Ad</title>
      </Helmet>
      <EuiFlexGroup>
        <EuiFlexItem grow={true} />
        {campaign.campaign?.campaignSubType === CampaignSubType.NativeOrDisplay && (
          <React.Fragment>
            <EuiFlexItem grow={false} style={{ width: cardWidth }}>
              <EuiCard id='suggestedAdsTile' icon={<EuiIcon size='xxl' type='grid' color='success' />} title='Suggested Ads' description='Suggested ads for your business.' onClick={SuggestedAdClicked} footer={<EuiText size='s'>Browse ads and pick one you like. You can use it as-is or easily customize it for your business.</EuiText>} />
            </EuiFlexItem>
            <EuiFlexItem grow={false} style={{ width: cardWidth }}>
              <EuiCard id='adBuilderTile' icon={<EuiIcon size='xxl' type='image' color='success' />} title='Ad Builder' description='Create a new ad for your business.' onClick={setBuild} footer={<EuiText size='s'>Use our state-of-the-art Ad Builder and see how your ad will look for users as you build it.</EuiText>} />
            </EuiFlexItem>
          </React.Fragment>
        )}
        {campaign.campaign?.campaignSubType === CampaignSubType.Native && (
          <React.Fragment>
            <EuiFlexItem grow={false} style={{ width: cardWidth }}>
              <EuiCard id='suggestedAdsTile' icon={<EuiIcon size='xxl' type='grid' color='success' />} title='Suggested Ads' description='Suggested ads for your business.' onClick={SuggestedAdClicked} footer={<EuiText size='s'>Browse ads and pick one you like. You can use it as-is or easily customize it for your business.</EuiText>} />
            </EuiFlexItem>
            <EuiFlexItem grow={false} style={{ width: cardWidth }}>
              <EuiCard id='adBuilderTile' icon={<EuiIcon size='xxl' type='image' color='success' />} title='Ad Builder' description='Create a new ad for your business.' onClick={setBuild} footer={<EuiText size='s'>Use our state-of-the-art Ad Builder and see how your ad will look for users as you build it.</EuiText>} />
            </EuiFlexItem>
          </React.Fragment>
        )}
        {campaign.campaign?.campaignSubType === CampaignSubType.Display && (
          <React.Fragment>
            <EuiFlexItem grow={false} style={{ width: cardWidth }}>
              <EuiCard icon={<EuiIcon size='xxl' type='arrowUp' color='success' />} title='Upload' description='Upload an ad.' onClick={setUpload} footer={<EuiText size='s'>Do you have your own Internet ad? Upload it here.</EuiText>} />
            </EuiFlexItem>
          </React.Fragment>
        )}
        {campaign.campaign?.campaignSubType === CampaignSubType.Default && (
          <React.Fragment>
            {!campaign.ads.find(a => a.nativeDetails?.body || a.nativeDetails?.headline) ? (
              <EuiFlexItem grow={false} style={{ width: cardWidth }}>
                <EuiCard icon={<EuiIcon size='xxl' type='arrowUp' color='success' />} title='Upload' description='Upload an ad.' onClick={setUpload} footer={<EuiText size='s'>Do you have your own Internet ad? Upload it here.</EuiText>} />
              </EuiFlexItem>
            ) : (
              <React.Fragment>
                <EuiFlexItem grow={false} style={{ width: cardWidth }}>
                  <EuiCard id='suggestedAdsTile' icon={<EuiIcon size='xxl' type='grid' color='success' />} title='Suggested Ads' description='Suggested ads for your business.' onClick={SuggestedAdClicked} footer={<EuiText size='s'>Browse ads and pick one you like. You can use it as-is or easily customize it for your business.</EuiText>} />
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={{ width: cardWidth }}>
                  <EuiCard id='adBuilderTile' icon={<EuiIcon size='xxl' type='image' color='success' />} title='Ad Builder' description='Create a new ad for your business.' onClick={setBuild} footer={<EuiText size='s'>Use our state-of-the-art Ad Builder and see how your ad will look for users as you build it.</EuiText>} />
                </EuiFlexItem>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <EuiFlexItem grow={true} />
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiButton fill type='button' color='text' onClick={gotoStart} iconType='arrowLeft' iconSide='left'>
            Back
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default StartStep
