import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiDescriptionList, EuiDescriptionListDescription, EuiDescriptionListTitle, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer } from '@elastic/eui'

import { RootState } from 'app/rootReducer'

const ViewDisplayAd: React.FC = () => {
  const { ad } = useSelector((state: RootState) => state)
  const [adUrl, setAdUrl] = useState('')

  useEffect(() => {
    if (ad.ad!.destinationUrl) {
      setAdUrl(ad.ad!.destinationUrl)
    }
  }, [ad.ad])

  return (
    <EuiPanel grow={false}>
      <React.Fragment>
        <EuiFlexItem style={{ marginLeft: 20 }}>
          <EuiSpacer size='s' />
          <EuiFlexGroup style={{ marginTop: 10 }}>
            <EuiFlexItem grow={true}>
              <EuiDescriptionList>
                <EuiDescriptionListTitle>Destination URL</EuiDescriptionListTitle>
                <EuiDescriptionListDescription>{ad.ad!.destinationUrl}</EuiDescriptionListDescription>
              </EuiDescriptionList>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='m' />
          <EuiFlexGroup>
            <EuiFlexItem grow={false} style={{ width: 420 }}>
              <a href={adUrl} rel='noreferrer' target={'_blank'}>
                <img src={ad.ad!.displayAdDetails?.uploadedUrl} alt={'display ad'} />
              </a>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='xl' />
        </EuiFlexItem>
      </React.Fragment>
    </EuiPanel>
  )
}

export default ViewDisplayAd
