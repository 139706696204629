import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { BillboardDetails } from 'workflows/CampaignCreateWorkflow'

import { EuiButton, EuiCallOut, EuiFilePicker, EuiFlexGroup, EuiFlexItem, EuiHideFor, EuiLoadingContent, EuiShowFor, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { apiGetBillboardBuilderUpload } from 'api/adcritterApi'
import { RootState } from 'app/rootReducer'
import { ICampaignBuilderWorkflowStep } from 'features/builders/createCampaign/creativeBuilder/CreativeBuilderWorkflowManager'
import { useCampaignBuilderActions } from 'features/builders/createCampaign/useCampaignBuilderActions'

export const AdUploadBillboardPage: React.FC<ICampaignBuilderWorkflowStep> = props => {
  const { createBillboardComplete } = useCampaignBuilderActions()
  const {
    creativeInfo: { billboardDetails, creativeId }
  } = useSelector((state: RootState) => state.campaignCreateWorkflow)
  const [adDetails, setAdDetails] = useState<BillboardDetails>({
    creativeId: uuidv4(),
    fileName: '',
    fileType: '',
    height: 0,
    uploadedUrl: '',
    width: 0
  })
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [containerClient, setContainerClient] = useState<ContainerClient | null>(null)
  const [isUploading, setIsUploading] = useState(false)
  const [hasFile, setHasFile] = useState(false)
  const [hasCorrectFile, setHasCorrectFile] = useState(false)
  const filePickerRemoval = useRef<EuiFilePicker>(null)

  useEffect(() => {
    if (billboardDetails && creativeId) {
      const details = billboardDetails.find(d => d.creativeId === creativeId)
      if (details) {
        setAdDetails(details)
        const fileType = details.fileType
        setHasCorrectFile(fileType === 'PNG' || fileType === 'JPEG' || fileType === 'JPG')
      }
    }
  }, [billboardDetails, creativeId])

  const largeRectangle = {
    width: 1400,
    height: 400
  }

  const standardRectangle = {
    width: 840,
    height: 400
  }

  useEffect(() => {
    if (currentAccount) {
      apiGetBillboardBuilderUpload(currentAccount.id).then(data => {
        const storageClient = new BlobServiceClient(data.data)
        const containerClient = storageClient.getContainerClient(currentAccount.id)
        setContainerClient(containerClient)
      })
    }
  }, [currentAccount])

  function getMeta(url: string, fileName: string, fileType: string) {
    const img = new Image()
    img.src = url
    img.onload = function () {
      setAdDetails({ ...adDetails, height: img.height, width: img.width, fileName: fileName, fileType: fileType, uploadedUrl: url })
    }
  }

  const onFileChange = (files: FileList | null) => {
    if (files && files.length === 1) {
      const blockBlobClient = containerClient!.getBlockBlobClient(uuidv4() + '.' + files[0].name.split('.').pop())!
      const url = blockBlobClient!.url.split('?')[0]
      let splitArrayLength = url.split('.').length
      let fileType = url.split('.')[splitArrayLength - 1].toUpperCase()
      const name = files[0].name.split('.')[0]
      setIsUploading(true)
      blockBlobClient!
        .uploadData(files[0], {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20
        })
        .then(() => {
          getMeta(url, name, fileType)
        })
        .then(() => {
          setHasFile(true)
          setHasCorrectFile(fileType === 'PNG' || fileType === 'JPEG' || fileType === 'JPG')
        })
        .finally(() => {
          setIsUploading(false)
        })
    }
    if (files && files.length < 1) {
      setAdDetails({ ...adDetails, fileName: '', fileType: '', height: 0, width: 0, uploadedUrl: '' })
      setHasCorrectFile(false)
      setHasFile(false)
    }
  }

  const gotoTargeting = useCallback(() => {
    createBillboardComplete(adDetails)
  }, [adDetails])

  const onBackClick = () => {
    props.onBack?.()
  }

  if (!containerClient) {
    return <EuiLoadingContent />
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Upload Billboard</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Upload Billboard</h3>
      </EuiTitle>
      <EuiSpacer size='m' />
      <EuiCallOut title='Start by uploading your ad, making sure it meets all requirements.' color='primary' iconType='importAction'>
        <p style={{ color: 'black', fontSize: '1.14286rem' }}>Recommendations:</p>
        <p style={{ marginLeft: 30, marginTop: -20 }}>
          Big fonts.
          <br />
          Few words. (Seven or less.)
          <br />
          Crisp, uncomplicated images.
        </p>
      </EuiCallOut>
      <EuiSpacer size='l' />

      {adDetails.uploadedUrl !== '' && (adDetails.width === standardRectangle.width || adDetails.width === largeRectangle.width) && (adDetails.height === standardRectangle.height || adDetails.height === largeRectangle.height) && (
        <EuiFlexItem>
          <EuiText size='s'>
            <h4>Preview Your Billboard</h4>
          </EuiText>
          <EuiSpacer size='s' />
          <EuiFlexItem style={{ alignItems: 'center' }}>
            <img src={adDetails.uploadedUrl} alt={'uploaded billboard'} style={{ width: adDetails.width / 1.5, height: adDetails.height / 1.5, backgroundColor: 'lightgrey', borderRadius: 8 }} />
          </EuiFlexItem>
          <EuiSpacer size='l' />
        </EuiFlexItem>
      )}

      <EuiFilePicker ref={filePickerRemoval} id='upload' fullWidth display='large' multiple={false} initialPromptText='Select or drag and drop  file here' onChange={onFileChange} isLoading={isUploading} />
      <EuiSpacer size='m' />
      <EuiCallOut title='Requirements: ' color='primary' iconType='importAction'>
        <EuiSpacer />
        <section style={{ marginLeft: 30, marginTop: -20, marginBottom: 20 }}>
          <span style={{ color: 'black', fontSize: '1.14286rem' }}>Acceptable image dimensions:</span> (Dimensions must be exact).
          <section style={{ marginLeft: 30 }}>
            <EuiText>
              Large: {largeRectangle.width} x {largeRectangle.height} pixels{' '}
            </EuiText>
            <EuiText>
              Standard: {standardRectangle.width} x {standardRectangle.height} pixels{' '}
            </EuiText>
          </section>
        </section>
        <p style={{ marginLeft: 30, marginTop: -20 }}>
          <span style={{ color: 'black', fontSize: '1.14286rem' }}>Accepted file formats:</span> PNG, JPEG or JPG.
        </p>
      </EuiCallOut>
      <EuiSpacer size='m' />
      {hasFile && (
        <React.Fragment>
          {hasCorrectFile ? (
            <EuiCallOut title='Your File Has Been Read' iconType='document' color='success'>
              <p>You did everything right!</p>
            </EuiCallOut>
          ) : (
            <EuiCallOut title='Your file has not been uploaded because it had these issues: ' iconType='document' color='warning'>
              {!(adDetails.fileType === 'PNG' || adDetails.fileType === 'JPEG' || adDetails.fileType === 'JPG') && (
                <p>
                  {adDetails.fileName} is not an accepted file type (it's a .{adDetails.fileType}). Please upload a file that is a <strong>PNG, JPEG or JPG</strong>.
                </p>
              )}
              {!((adDetails.width === standardRectangle.width && adDetails.height === standardRectangle.height) || (adDetails.width === largeRectangle.width && adDetails.height === largeRectangle.height)) && (
                <section>
                  {adDetails.fileName} isn't one of the accepted image dimensions (it's {adDetails.width} x {adDetails.height}). Please upload a file that is one of the following dimensions:
                  <section style={{ marginLeft: 30 }}>
                    <EuiText size='s'>
                      Large Rectangle: {largeRectangle.width} x {largeRectangle.height} pixels{' '}
                    </EuiText>
                    <EuiText size='s'>
                      Standard Rectangle: {standardRectangle.width} x {standardRectangle.height} pixels{' '}
                    </EuiText>
                  </section>
                </section>
              )}
              <EuiSpacer />
              <div>
                <EuiButton color='warning' iconType='trash' onClick={() => filePickerRemoval.current!.removeFiles()}>
                  <h3 style={{ marginBottom: -0.5 }}>Remove file</h3>
                </EuiButton>
              </div>
            </EuiCallOut>
          )}
        </React.Fragment>
      )}

      <EuiSpacer size='xl' />

      <EuiShowFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='continue' fill isDisabled={!hasCorrectFile} onClick={gotoTargeting}>
              Continue to Targeting
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={onBackClick}>
              Back
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={onBackClick} iconType='arrowLeft' iconSide='left'>
              Back
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='continue' fill type='submit' isDisabled={!hasCorrectFile} iconType='arrowRight' iconSide='right' onClick={gotoTargeting}>
              Continue to Targeting
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiHideFor>
    </React.Fragment>
  )
}
