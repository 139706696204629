import React, { useEffect, useState } from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiImage, EuiPanel, EuiSpacer, EuiText, htmlIdGenerator } from '@elastic/eui'

import { PreviewProps } from './index'

export const MobileWeb = ({ ad, image }: PreviewProps) => {
  const divId = htmlIdGenerator()()
  const [width, setWidth] = useState(0)
  const [imgUrl, setImgUrl] = useState('/200x200.png')
  const [useDiv, setUseDiv] = useState(false)

  useEffect(() => {
    setWidth(document.getElementById(divId)?.clientWidth || 0)
  }, [divId])

  useEffect(() => {
    if (image) {
      setUseDiv(!!image.thumbnailWidth)
      setImgUrl(image.highQualityUrl)
    }
  }, [image])

  return (
    <EuiFlexGroup style={{ marginTop: 20 }}>
      <EuiFlexItem grow={true} />
      <EuiFlexItem grow={false} style={{ width: 320 }}>
        <EuiPanel paddingSize='none'>
          <EuiFlexGroup responsive={false} style={{ padding: 10 }} gutterSize='none'>
            <EuiFlexItem style={{ width: 100 }} grow={false}>
              {useDiv && (
                <div id={divId} style={{ height: (image!.thumbnailHeight! / image!.thumbnailWidth!) * width, overflow: 'hidden' }}>
                  <img alt='ad' src={imgUrl} width={'100%'} />
                </div>
              )}
              {!useDiv && <EuiImage alt='ad' url={imgUrl} size='fullWidth' />}
            </EuiFlexItem>
            <EuiFlexItem style={{ marginLeft: 10 }}>
              <EuiText size='m'>
                <h5>{ad?.headline || 'Attention Grabbing Headline'}</h5>
              </EuiText>
              <EuiSpacer size='s' />
              <EuiText size='xs' color='subdued'>
                <strong>{ad?.companyName || 'Your Company Name'}</strong>
              </EuiText>
              <EuiSpacer size='s' />
              <EuiText size='xs' color='warning'>
                <strong>Ad</strong>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexItem>
      <EuiFlexItem grow={true} />
    </EuiFlexGroup>
  )
}
