import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiForm, EuiFormRow, EuiText, EuiFieldText, EuiSpacer, EuiButton, EuiLoadingContent, EuiFlexGroup, EuiFlexItem, EuiSelect, EuiPanel, EuiPageHeader, EuiPageHeaderSection, EuiTitle, EuiButtonEmpty, EuiCallOut, EuiCard, EuiLink } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'

import { ApiError, apiGetBusinessType, apiPutAccounts } from 'api/adcritterApi'
import { BusinessType } from 'api/interfaces'
import { showSuccessToast, updateAccountState } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { BusinessTypeFinder } from 'components/finders/BusinessTypeFinder'
import { open } from 'features/organization/organizationSlice'

interface FormValues {
  businessTypeId: string
  name: string
  streetAddress: string
  city: string
  state: string
  zip: string
  country: string
  website: string
}

const AccountDrawer: React.FC = () => {
  const dispatch = useDispatch()
  const account = useSelector((state: RootState) => state.account)
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const { isVendastaUser, isShopifyUser } = useSelector((state: RootState) => state.app)
  const [selectedBusinessType, setSelectedBusinessType] = useState<BusinessType | null>(null)
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [formValues, setFormValues] = useState<FormValues>({
    businessTypeId: '',
    name: '',
    streetAddress: '',
    city: '',
    state: 'AL',
    zip: '',
    country: 'usa',
    website: ''
  })

  useEffect(() => {
    if (currentAccount != null) {
      setFormValues({
        businessTypeId: currentAccount.businessTypeId || '',
        name: currentAccount.name,
        streetAddress: currentAccount.streetAddress || '',
        state: currentAccount.state || 'AL',
        city: currentAccount.city || '',
        zip: currentAccount.zip || '',
        website: currentAccount.website || '',
        country: 'usa'
      })

      if (currentAccount.businessTypeId) {
        apiGetBusinessType(currentAccount.businessTypeId).then(result => {
          setSelectedBusinessType(result.data)
        })
      }
    }
  }, [currentAccount, dispatch])

  const accountSchema = Yup.object().shape({
    businessTypeId: Yup.string(),
    name: Yup.string().max(128).required('Please enter a short company name'),
    streetAddress: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zip: Yup.string(),
    website: Yup.string().url('Please enter a valid URL including https:// (ex. https://www.domain.com)')
  })

  let formik = useFormik({
    enableReinitialize: true,
    initialValues: formValues,
    validationSchema: accountSchema,
    onSubmit: (values: FormValues) => {
      apiPutAccounts(currentAccount!.id, values.businessTypeId, values.name, values.streetAddress, values.city, values.state, values.zip, values.country, values.website)
        .then(response => {
          formik.setSubmitting(false)
          formik.setStatus(null)
          dispatch(updateAccountState(response.data))
          dispatch(showSuccessToast('Saved Account'))
        })
        .catch(response => {
          formik.setSubmitting(false)
          response.errors.forEach(function (e: ApiError) {
            formik.setFieldError(e.name, e.message)
          })
        })
    }
  })

  const selectBusinessType = (selected: BusinessType) => {
    setSelectedBusinessType(selected)
    formik.setFieldValue('businessTypeId', selected.id)
  }

  let content

  if (account.isLoading || currentAccount == null) {
    content = <EuiLoadingContent lines={3} />
  } else {
    content = (
      <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
        <EuiTitle size='s'>
          <h4>Business Details</h4>
        </EuiTitle>
        <EuiSpacer size='s' />
        <EuiFormRow label='Business Type' fullWidth isInvalid={formik.touched.businessTypeId && !!formik.errors.businessTypeId} error={formik.errors.businessTypeId}>
          <React.Fragment>
            {selectedBusinessType && (
              <React.Fragment>
                <EuiCard
                  display='primary'
                  layout='horizontal'
                  paddingSize='s'
                  titleSize='xs'
                  icon={
                    <div>
                      <img alt='' src={selectedBusinessType.imageUrl} />
                    </div>
                  }
                  title={selectedBusinessType.name}
                  description={selectedBusinessType.businessTypeCategories.join(', ')}
                />
                <EuiSpacer size='s' />
                <EuiLink color='success' onClick={() => setSelectedBusinessType(null)}>
                  Clear selected business type
                </EuiLink>
              </React.Fragment>
            )}
            {!selectedBusinessType && <BusinessTypeFinder onBusinessTypeClicked={selectBusinessType} isInvalid={formik.touched.businessTypeId && !!formik.errors.businessTypeId} />}
          </React.Fragment>
        </EuiFormRow>
        <EuiSpacer />
        <EuiCallOut iconType='iInCircle' size='s' title='Enter your business name as you would like it to appear in ads. Generally, the shorter the better. For example, “Smith’s Flowers” is better than “Smith’s Flower Emporium, Inc.' color='success' />
        <EuiSpacer size='s' />
        <EuiFormRow label='Company Short Name' fullWidth isInvalid={formik.touched.name && !!formik.errors.name} error={formik.errors.name}>
          <EuiFieldText name='name' value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='' fullWidth isInvalid={formik.touched.name && !!formik.errors.name} />
        </EuiFormRow>
        <EuiSpacer />
        <EuiCallOut iconType='home' size='s' title='Entering your business location' color='success'>
          <EuiText size='s'>
            <p>Enter the address of your company’s physical location. This address is used by Smart Targeter for targeting walk-in customers to your store (if enabled). It is also used as the center-point for Smart Targeter location campaigns. For example, if you set Smart Targeter to advertise to people within five miles of your location, it will advertise to people within five miles of this address.</p>
          </EuiText>
        </EuiCallOut>
        <EuiSpacer size='s' />
        <EuiFormRow label='Street Address' fullWidth isInvalid={formik.touched.streetAddress && !!formik.errors.streetAddress} error={formik.errors.streetAddress}>
          <EuiFieldText name='streetAddress' value={formik.values.streetAddress} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='' fullWidth isInvalid={formik.touched.streetAddress && !!formik.errors.streetAddress} />
        </EuiFormRow>
        <EuiSpacer size='s' />
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label='City' fullWidth isInvalid={formik.touched.city && !!formik.errors.city} error={formik.errors.city}>
              <EuiFieldText name='city' value={formik.values.city} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='' fullWidth isInvalid={formik.touched.city && !!formik.errors.city} />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label='State' fullWidth isInvalid={formik.touched.state && !!formik.errors.state} error={formik.errors.state}>
              <EuiSelect
                name='state'
                isLoading={dictionary.isLoadingStates}
                options={dictionary.states.map(
                  s =>
                    ({
                      value: s.code,
                      label: s.name,
                      text: s.name
                    } as EuiSelectOption)
                )}
                value={formik.values.state}
                onChange={value => formik.setFieldValue('state', value.target.value, true)}
                onBlur={formik.handleBlur}
                fullWidth
                isInvalid={formik.touched.state && !!formik.errors.state}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label='Zip' fullWidth isInvalid={formik.touched.zip && !!formik.errors.zip} error={formik.errors.zip}>
              <EuiFieldText name='zip' value={formik.values.zip} onChange={formik.handleChange} placeholder='' fullWidth isInvalid={formik.touched.zip && !!formik.errors.zip} />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer />
        <EuiFormRow label='Website' helpText='This is the default website URL that will be used you create new ads' fullWidth isInvalid={formik.touched.website && !!formik.errors.website} error={formik.errors.website}>
          <EuiFieldText name='website' value={formik.values.website} onChange={formik.handleChange} fullWidth isInvalid={formik.touched.website && !!formik.errors.website} />
        </EuiFormRow>
        <EuiSpacer />

        <EuiButton id='save' fill type='submit' isLoading={formik.isSubmitting}>
          Save
        </EuiButton>
      </EuiForm>
    )
  }

  return (
    <React.Fragment>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle>
            <h1>Settings</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
        {!isVendastaUser && !isShopifyUser && (
          <EuiPageHeaderSection>
            <EuiButtonEmpty
              id='manageSubscription'
              onClick={() => {
                dispatch(open())
              }}>
              Manage Subscription
            </EuiButtonEmpty>
          </EuiPageHeaderSection>
        )}
      </EuiPageHeader>
      <EuiPanel grow={false}>{content}</EuiPanel>
    </React.Fragment>
  )
}

export default AccountDrawer
