import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'

import { AreaSeries, Chart, RecursivePartial, Settings, Theme } from '@elastic/charts'
import { EuiBadge, EuiButton, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiStat } from '@elastic/eui'

import { MobileDashboardAnalytic } from 'api/interfaces/MobileDashboardAnalytic'
import history from 'services/HistoryService'
import { CampaignStatusToElasticColor, CampaignStatusToString } from 'utils/EnumToFriendly'

export interface MobileCampaignTileProps {
  mobileDashboardAnalytic: MobileDashboardAnalytic
  theme: RecursivePartial<Theme>[]
  index: number
  color?: string[]
}

const MobileCampaignTile: FC<MobileCampaignTileProps> = ({ mobileDashboardAnalytic, theme, index, color }) => {
  const { campaign, analytics } = mobileDashboardAnalytic

  return (
    <EuiPanel grow={false}>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          {/*<EuiStat title={campaign.name} description={<EuiBadge color={CampaignStatusToElasticColor(campaign.status)}>{CampaignStatusToString(campaign)}</EuiBadge>} textAlign='left' titleSize='s' reverse />*/}
          <EuiStat title={campaign.name} description={CampaignStatusToString(campaign)} textAlign='left' titleSize='s' reverse />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size='m' />

      {analytics.length > 0 && (
        <React.Fragment>
          <EuiStat title='' description='' titleColor='success' textAlign='left'>
            <EuiSpacer size='s' />
            <Chart size={{ height: 48 }}>
              <Settings theme={theme} showLegend={false} tooltip='none' />
              <AreaSeries
                id={campaign.id}
                data={analytics.map((a, i) => ({
                  x: i,
                  y: a.impressions
                }))}
                xAccessor='x'
                yAccessors={['y']}
                color={color}
              />
            </Chart>
          </EuiStat>

          <EuiSpacer size='m' />
        </React.Fragment>
      )}

      <EuiFlexItem grow={false}>
        <EuiButton id='createCampaignBtn' size='s' key='createCampaign' onClick={() => history.push(`/campaigns/edit/${campaign.id}`)} fill>
          Edit Campaign
        </EuiButton>
      </EuiFlexItem>
    </EuiPanel>
  )
}

export default MobileCampaignTile
