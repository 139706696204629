import { useFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCallOut, EuiForm, EuiFormRow, EuiLoadingContent, EuiRadio, EuiSpacer, EuiText } from '@elastic/eui'

import { ApiError, apiPutCampaignBillboardTargeting } from 'api/adcritterApi'
import { City, Zip } from 'api/interfaces'
import { showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import TargetingMap from 'components/TargetingMap'
import { CityFinder, ZipFinder, DmaFinder, StateFinder } from 'components/finders'
import { CityTargeter, DmaTargeter, StateTargeter, ZipTargeter } from 'components/targeters'
import { setInitialTargetingMapState } from 'components/targetingMapSlice'

import { finishedLoadingCampaign } from '../campaignSlice'

interface FormValues {
  locationType: string
  states: Array<string>
  dmas: Array<string>
  cities: Array<City>
  zips: Array<Zip>
}

const BillboardTargetingDrawer: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const [suggestedPlaces, setSuggestedPlace] = useState<string[]>([])
  const campaign = useSelector((state: RootState) => state.campaign)
  const [formValues, setFormValues] = useState<FormValues>({
    locationType: 'USA',
    states: [],
    dmas: [],
    cities: [],
    zips: []
  })

  useEffect(() => {
    if (campaign.campaign && campaign.campaign.billboardTargeting) {
      setFormValues({
        locationType: campaign.campaign.billboardTargeting!.locationType ?? 'USA',
        states: campaign.campaign.billboardTargeting!.states,
        dmas: campaign.campaign.billboardTargeting!.dmas,
        cities: campaign.campaign.billboardTargeting!.cities,
        zips: campaign.campaign.billboardTargeting!.zips
      })
    }
  }, [campaign.campaign])

  useEffect(() => {
    dispatch(
      setInitialTargetingMapState({
        hideDrawingTools: true,
        hideLocationSearch: true,
        targetedPlaces: suggestedPlaces,
        includeBillboards: true
      })
    )
  }, [suggestedPlaces])

  const advancedLocationSchema = Yup.object().shape({
    locationType: Yup.string().required(),
    states: Yup.array().when('locationType', {
      is: 'State',
      then: Yup.array().min(1, 'Please Select at Least 1 State').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    dmas: Yup.array().when('locationType', {
      is: 'DMA',
      then: Yup.array().min(1, 'Please Select at Least 1 DMA/Metro Area').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    cities: Yup.array().when('locationType', {
      is: 'City',
      then: Yup.array().min(1, 'Please Add at Least 1 City').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    zips: Yup.array().when('locationType', {
      is: 'Zip',
      then: Yup.array().min(1, 'Please Add at Least 1 Zip').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    })
  })

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: advancedLocationSchema,
    onSubmit: (values: FormValues) => {
      apiPutCampaignBillboardTargeting(currentAccount!.id, campaign.campaign!.id, {
        locationType: values.locationType,
        states: values.states,
        dmas: values.dmas,
        cities: values.cities.map(c => c.id),
        zips: values.zips.map(z => z.id)
      })
        .then(response => {
          dispatch(showSuccessToast('Saved Billboard targeting'))
          dispatch(finishedLoadingCampaign(response.data))
          formik.setSubmitting(false)
          formik.setStatus(null)
        })
        .catch(response => {
          formik.setSubmitting(false)
          response.errors.forEach(function (error: ApiError) {
            formik.setFieldError(error.name, error.message)
          })
        })
    }
  })

  useEffect(() => {
    setSuggestedPlace(getSuggestedPlaces())
  }, [formik.values])

  const getSuggestedPlaces = useCallback(() => {
    switch (formik.values.locationType) {
      case 'State':
        return formik.values.states.map(code => dictionary.states.filter(state => state.code === code)[0].name)
      case 'DMA':
        return formik.values.dmas
      case 'City':
        return formik.values.cities.map(c => `${c.name}, ${c.stateCode}`)
      case 'Zip':
        return formik.values.zips.map(z => z.name)
      default:
        return []
    }
  }, [formik.values])

  let content

  if (campaign.isLoadingCampaign) {
    content = <EuiLoadingContent lines={3} />
  } else {
    content = (
      <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
        <EuiRadio
          id='usa'
          name='locationType'
          value='USA'
          label={
            <EuiText size='s'>
              <strong>Targeting the USA</strong>
            </EuiText>
          }
          checked={formik.values.locationType === 'USA'}
          onChange={() => {
            formik.setFieldValue('locationType', 'USA', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='state'
          name='locationType'
          value='State'
          label={
            <EuiText size='s'>
              <strong>Targeting Using State</strong> (one or more U.S. States)
            </EuiText>
          }
          checked={formik.values.locationType === 'State'}
          onChange={() => {
            formik.setFieldValue('locationType', 'State', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='dma'
          name='locationType'
          value='DMA'
          label={
            <EuiText size='s'>
              <strong>Targeting Using DMA/Metro Area</strong> (one or more DMAs)
            </EuiText>
          }
          checked={formik.values.locationType === 'DMA'}
          onChange={() => {
            formik.setFieldValue('locationType', 'DMA', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='city'
          name='locationType'
          value='City'
          label={
            <EuiText size='s'>
              <strong>Targeting Using City</strong> (one or more City names)
            </EuiText>
          }
          checked={formik.values.locationType === 'City'}
          onChange={() => {
            formik.setFieldValue('locationType', 'City', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='zip'
          name='locationType'
          value='Zip'
          label={
            <EuiText size='s'>
              <strong>Targeting Using Zip Code</strong> (one or more Zip Codes)
            </EuiText>
          }
          checked={formik.values.locationType === 'Zip'}
          onChange={() => {
            formik.setFieldValue('locationType', 'Zip', true)
          }}
        />

        <div hidden={formik.values.locationType !== 'State'}>
          <EuiSpacer />
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can select U.S. States below, multiple States can be targeted at once.' />
          <EuiSpacer size='s' />

          <EuiFormRow label='Search for a State' fullWidth isInvalid={!!formik.errors.states} error={formik.errors.states}>
            <StateFinder addState={stateToAdd => formik.setFieldValue('states', [...formik.values.states, stateToAdd])} isInvalid={!!formik.errors.states} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these States:' fullWidth>
            <StateTargeter
              states={formik.values.states}
              onStateRemoved={stateToRemove =>
                formik.setFieldValue(
                  'states',
                  formik.values.states.filter(x => x !== stateToRemove),
                  true
                )
              }
            />
          </EuiFormRow>
        </div>

        <div hidden={formik.values.locationType !== 'DMA'}>
          <EuiSpacer />
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can select a DMA/Metro Area(s) below, multiple DMAs can be targeted at once.' />
          <EuiSpacer size='s' />

          <EuiFormRow label='Search for a DMA' fullWidth isInvalid={!!formik.errors.dmas} error={formik.errors.dmas}>
            <DmaFinder addDma={dmaToAdd => formik.setFieldValue('dmas', [...formik.values.dmas, dmaToAdd])} isInvalid={!!formik.errors.dmas} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these DMAs:' fullWidth>
            <DmaTargeter
              dmas={formik.values.dmas}
              onDmaRemoved={dmaToRemove =>
                formik.setFieldValue(
                  'dmas',
                  formik.values.dmas.filter(x => x !== dmaToRemove),
                  true
                )
              }
            />
          </EuiFormRow>
        </div>

        <div hidden={formik.values.locationType !== 'City'}>
          <EuiSpacer />
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can search for U.S. Cities below, multiple Cities can be targeted at once.' />
          <EuiSpacer size='s' />

          <EuiFormRow label='Search for a city' fullWidth isInvalid={!!formik.errors.cities} error={formik.errors.cities}>
            <CityFinder onCityClicked={city => formik.setFieldValue('cities', [...formik.values.cities, city])} isInvalid={!!formik.errors.cities} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these cities:' fullWidth>
            <CityTargeter
              cities={formik.values.cities}
              onCityRemoved={city => {
                formik.setFieldValue(
                  'cities',
                  formik.values.cities.filter(c => c.id !== city.id)
                )
              }}
            />
          </EuiFormRow>
        </div>

        <div hidden={formik.values.locationType !== 'Zip'}>
          <EuiSpacer />
          <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can search for U.S. Zip Codes below, multiple Zip Codes can be targeted at once.' />
          <EuiSpacer size='s' />

          <EuiFormRow label='Search for a zip code' fullWidth isInvalid={!!formik.errors.zips} error={formik.errors.zips}>
            <ZipFinder onZipClicked={zip => formik.setFieldValue('zips', [...formik.values.zips, zip])} isInvalid={!!formik.errors.zips} />
          </EuiFormRow>
          <EuiFormRow label='Your campaign will target these zip codes:' fullWidth>
            <ZipTargeter
              zips={formik.values.zips}
              onZipRemoved={zip => {
                formik.setFieldValue(
                  'zips',
                  formik.values.zips.filter(z => z.id !== zip.id)
                )
              }}
            />
          </EuiFormRow>
        </div>
        <EuiSpacer />
        <EuiFormRow fullWidth>
          <TargetingMap />
        </EuiFormRow>
        <EuiSpacer />

        <EuiButton fill type='submit' isLoading={formik.isSubmitting}>
          Save
        </EuiButton>
      </EuiForm>
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      {content}
    </React.Fragment>
  )
}

export default BillboardTargetingDrawer
