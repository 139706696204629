import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { EuiButton, EuiFieldPassword, EuiForm, EuiFormRow, EuiIcon, EuiSpacer } from '@elastic/eui'

import { apiChangePassword } from 'api/adcritterApi'
import { DisplayErrorToast, DisplaySuccessToast } from 'app/appSlice'
import { TestCase, Validator } from 'utils/Validator/PasswordValidator'

export const ChangePasswordDrawer: React.FC = () => {
  const [password, setPassword] = useState<string>()
  const [verifyPassword, setVerifyPassword] = useState<string>()
  const [isUpdating, setIsUpdating] = useState(false)
  const mounted = useRef(true)
  const dispatch = useDispatch()
  const validator = new Validator([new TestCase(s => s.length >= 6, 'Test 1', 'Password contains a minimum of 6 characters'), new TestCase(s => /[A-Z]/.test(s), 'Test 2', 'Password contains an uppercase character.'), new TestCase(s => /[a-z]/.test(s), 'Test 3', 'Password contains a lowercase character.'), new TestCase(s => /\d/.test(s), 'Test 4', 'Password contains a number.')])

  const passwordValidator = (password: string) => {
    validator.validate(password)
  }

  const isValid = password && password.length > 0 && password === verifyPassword
  const updatePassword = () => {
    if (password) {
      setIsUpdating(true)
      apiChangePassword(password)
        .then(response => {
          if (response.data && mounted) {
            setPassword('')
            setVerifyPassword('')
            dispatch(DisplaySuccessToast('Your password has been successfully updated.'))
          } else if (!response.data) {
            dispatch(DisplayErrorToast("Something didn't go as expected. Please try again."))
          }
        })
        .catch(() => {
          dispatch(DisplayErrorToast('Oops! Sorry, it looks like something went wrong. Please try again. If the issue persists please contact AdCritter Support.'))
        })
        .finally(() => {
          setIsUpdating(false)
        })
    }
  }

  return (
    <EuiForm component='form'>
      <EuiFormRow label='Enter your new password'>
        <EuiFieldPassword
          name='password'
          value={password}
          onChange={e => {
            setPassword(e.target.value)
            passwordValidator(e.target.value)
          }}
        />
      </EuiFormRow>
      <EuiSpacer size='s' />
      {validator.getLastResults().map(r => {
        return (
          <React.Fragment>
            <EuiIcon type={r.DidPass ? 'checkInCircleFilled' : 'crossInACircleFilled'} size='s' color={r.DidPass ? 'success' : 'danger'} /> {r.Description}
            <EuiSpacer size='s' />
          </React.Fragment>
        )
      })}
      <EuiSpacer size='m' />
      <EuiFormRow label='Confirm password'>
        <EuiFieldPassword
          name='password'
          value={verifyPassword}
          onChange={e => {
            setVerifyPassword(e.target.value)
          }}
        />
      </EuiFormRow>
      <EuiFormRow hasEmptyLabelSpace>
        <EuiButton isLoading={isUpdating} disabled={!isValid || !validator.lastResultsPassed()} fill onClick={updatePassword}>
          Change Password
        </EuiButton>
      </EuiFormRow>
    </EuiForm>
  )
}
