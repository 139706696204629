import React from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiCard, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiSpacer, EuiText } from '@elastic/eui'

import { RootState } from 'app/rootReducer'
import { resetSearchImage } from 'components/chooseImage/searchImageSlice'
import history from 'services/HistoryService'

import { BuilderStyle, resetBuilder, setAd, setBuilderStyle } from './builderInternetRetailSlice'

const StartStep: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)

  const setGeneral = () => {
    dispatch(resetSearchImage())
    dispatch(setBuilderStyle(BuilderStyle.Suggested))
    history.push('/build/internet/public/suggest')
  }

  const setBuild = () => {
    dispatch(resetSearchImage())
    dispatch(
      setAd({
        id: '',
        headline: '',
        body: '',
        companyName: currentAccount?.name || '',
        callToAction: 'Learn More',
        destinationUrl: currentAccount?.website || ''
      })
    )
    dispatch(setBuilderStyle(BuilderStyle.Build))
    history.push('/build/internet/public/create')
  }

  const setUpload = () => {
    dispatch(setBuilderStyle(BuilderStyle.Upload))
    history.push('/build/internet/public/upload')
  }

  const setCustom = () => {
    dispatch(setBuilderStyle(BuilderStyle.Custom))
    history.push('/build/internet/public/questionnaire')
  }

  const gotoStart = () => {
    history.push('/build')
    dispatch(resetBuilder())
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Start</title>
      </Helmet>
      <EuiFlexGroup>
        <EuiFlexItem grow={true} />
        <EuiFlexItem grow={false} style={{ width: 280 }}>
          <EuiCard icon={<EuiIcon size='xxl' type='grid' color='success' />} id='suggestedAdsTile' title='Suggested Ads' description='Suggested ads for your business.' onClick={setGeneral} footer={<EuiText size='s'>Browse ads and pick one you like. You can use it as-is or easily customize it for your business.</EuiText>} />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: 280 }}>
          <EuiCard icon={<EuiIcon size='xxl' type='image' color='success' />} title='Ad Builder' description='Create a new ad for your business.' onClick={setBuild} footer={<EuiText size='s'>Use our state-of-the-art Ad Builder and see how your ad will look for users as you build it.</EuiText>} />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: 280 }}>
          <EuiCard icon={<EuiIcon size='xxl' type='arrowUp' color='success' />} id='uploadAdTile' title='Upload' description='Upload an ad.' onClick={setUpload} footer={<EuiText size='s'>Do you have your own Internet ad? Upload it here.</EuiText>} />
        </EuiFlexItem>
        <EuiFlexItem grow={true} />
      </EuiFlexGroup>
      <EuiSpacer size='m' />
      <EuiFlexGroup>
        <EuiFlexItem grow={true} />
        <EuiFlexItem grow={false} style={{ width: 280 }}>
          <EuiCard icon={<EuiIcon size='xxl' type='pencil' color='success' />} id='customAdsTile' title='Custom Ads' description='Let us make you some ads.' onClick={setCustom} footer={<EuiText size='s'>Is your business too unique for Suggested Ads? Answer a few questions and we'll make a suite of custom ads just for you.</EuiText>} />
        </EuiFlexItem>
        <EuiFlexItem grow={true} />
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiButton fill type='button' color='text' onClick={gotoStart} iconType='arrowLeft' iconSide='left'>
            Back
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default StartStep
