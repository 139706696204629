import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  EuiButton, EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiPanel,
  EuiText
} from '@elastic/eui'

import { RootState } from 'app/rootReducer'
import { AcVideoUploader, UploadedVideo } from '../Basic/AcVideoUploader'

export interface IVideoAdUploader {
  websiteLink: string
  onUpdateWebsiteLink: (link: string) => void
  onUploadClick: (videos: UploadedVideo[]) => void
  uploadedVideos: UploadedVideo[]
  onUploadedVideosChange: (images: UploadedVideo[]) => void
  invalidVideos: UploadedVideo[]
  onInvalidVideosChange: (images: UploadedVideo[]) => void
  isLoading?: boolean
  isDisabled?: boolean
}

export const TvAdUploader: React.FC<IVideoAdUploader> = props => {
  const [isValidUpload, setIsValidUpload] = useState<boolean>(true)
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const onWebsiteLinkChange = (link: string) => {
    props.onUpdateWebsiteLink(link)
  }

  console.log(props.uploadedVideos)

  const onUploadedVideoChanged = (videos: UploadedVideo[]) => {
    props.onUploadedVideosChange([...videos])
    props.onInvalidVideosChange([...props.invalidVideos.filter(u => videos.every(i => i.fileName != u.fileName)), ...videos.filter(i => !isValidDimensions(i))])
  }

  const onUploadClick = async () => {
    const imagesToUpload = props.uploadedVideos.filter(u => !props.invalidVideos.some(i => i.fileName === u.fileName))
    if (canUpload()) {
      props.onUploadClick(imagesToUpload)
    }
  }

  const onVideoRemove = (fileName: string) => {
    props.onUploadedVideosChange([...props.uploadedVideos.filter(u => u.fileName != fileName)])
  }

  // 300 MB
  const acceptedFileSize = 300 * 1000000

  const isValidUrl = (urlString: string) => {
    try {
      return Boolean(new URL(urlString))
    } catch (e) {
      return false
    }
  }

  const isValidDimensions = (video: UploadedVideo) => {
    return true
  }

  const canUpload = useCallback(() => {
    return props.uploadedVideos.filter(u => !props.invalidVideos.some(i => i === u)).length > 0 && isValidUrl(props.websiteLink) && isValidUpload
  }, [props.uploadedVideos, props.invalidVideos, props.websiteLink, isValidUpload])

  return (
    <React.Fragment>
      <EuiFormRow label={'Website Link'} isInvalid={!isValidUrl(props.websiteLink)}>
        <EuiFieldText placeholder='https://...' onChange={e => onWebsiteLinkChange(e.target.value)} value={props.websiteLink} />
      </EuiFormRow>
      <EuiFormRow label={'TV Ad'}>
        <AcVideoUploader maxFileSize={acceptedFileSize} onUploadedUrlsChange={onUploadedVideoChanged} onIsValidFileChange={isValidUpload => setIsValidUpload(isValidUpload)} accountId={currentAccount!.id} />
      </EuiFormRow>
      {!isValidUpload && (
        <EuiFormRow fullWidth>
          <EuiText color={'danger'} size={'s'}>
            <p>Issue uploading images. Sizes must be less than {(acceptedFileSize / 1000000).toLocaleString()} MB</p>
          </EuiText>
        </EuiFormRow>
      )}
      <EuiFormRow label={`Upload Preview (${props.uploadedVideos.length})`} fullWidth>
        <EuiFlexGroup wrap>
          {props.uploadedVideos.map((video, index) => (
            <EuiFlexItem key={video.fileName + index}>
              <EuiPanel style={{width: 240,}} grow={false} hasShadow={false} hasBorder={true}>
                <EuiFlexGroup direction={'column'} style={{ height: '100%' }} gutterSize={'xs'}>
                  <EuiFlexItem>
                    <video id={'uploadedVideo_' + video.fileName} controls width="100%">
                      <source src={video.url} type="video/mp4" />
                    </video>
                  </EuiFlexItem>
                  <EuiFlexItem />
                  <EuiFlexItem grow={false}>
                    <EuiButtonEmpty iconType={'cross'} style={{ margin: 0, padding: 0 }} color={'danger'} size={'xs'} onClick={() => onVideoRemove(video.fileName)}>
                      Remove
                    </EuiButtonEmpty>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiPanel>
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiFormRow>
        <EuiButton disabled={!canUpload()} onClick={onUploadClick} isLoading={props.isLoading} isDisabled={props.isDisabled}>
          Submit to Ad Team
        </EuiButton>
      </EuiFormRow>
    </React.Fragment>
  )
}
