import React, { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { EuiFlexGroup, EuiFlexItem, EuiHideFor, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiSpacer, EuiStepsHorizontal, EuiTitle } from '@elastic/eui'
import { EuiStepHorizontalProps } from '@elastic/eui/src/components/steps/step_horizontal'

import { apiGetBusinessType } from 'api/adcritterApi'
import { showErrorToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { ActivateVendastaCampaignStep } from 'features/builders/ActivateVendastaCampaignStep'
import { fetchCampaigns } from 'features/campaigns/campaigns/campaignsSlice'
import history from 'services/HistoryService'
import { AcRequiredFields } from 'utils/AcRequiredFields'
import { CampaignType } from 'utils/CampaignType'

import SuggestionsStep from './10_SuggestionsStep'
import CreateStep from './30_CreateStep'
import TargetStep from './40_TargetStep'
import ActivateStep from './99_ActivateStep'
import { BuilderStep, setBuilderStep, setBusinessType } from './builderInternetVistaSlice'

interface BuilderPageParams {
  step: string
}

const Page: React.FC = () => {
  const { step } = useParams<BuilderPageParams>()
  const dispatch = useDispatch()
  const builder = useSelector((state: RootState) => state.builderInternetVista)
  const [content, setContent] = useState<ReactNode>()
  const { currentAccount, isInSignupProcess, isVendastaUser } = useSelector((state: RootState) => state.app)

  const failureRedirect = () => {
    history.push('/')
    dispatch(showErrorToast("Something didn't go as expected. Please try again."))
  }

  useEffect(() => {
    switch (step) {
      default:
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            setContent(<SuggestionsStep />)
            dispatch(setBuilderStep(BuilderStep.Suggestions))
          },
          failureRedirect
        )
        break
      case 'create':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            setContent(<CreateStep />)
            dispatch(setBuilderStep(BuilderStep.Create))
          },
          failureRedirect
        )
        break
      case 'target':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            setContent(<TargetStep />)
            dispatch(setBuilderStep(BuilderStep.Target))
          },
          failureRedirect
        )
        break
      case 'activate':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            if (isVendastaUser) {
              setContent(<ActivateVendastaCampaignStep campaignType={CampaignType.Internet} />)
            } else {
              setContent(<ActivateStep />)
            }
            dispatch(setBuilderStep(BuilderStep.Activate))
          },
          failureRedirect
        )
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, step])

  useEffect(() => {
    if (currentAccount && builder.builderStep === BuilderStep.Suggestions) {
      if (currentAccount.businessTypeId) {
        dispatch(fetchCampaigns(currentAccount.id))
        apiGetBusinessType(currentAccount.businessTypeId).then(result => {
          dispatch(setBusinessType(result.data))
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, dispatch])

  const onCreateAdClick = () => {
    dispatch(setBuilderStep(BuilderStep.Suggestions))
  }

  const onSetTargetingClick = () => {
    dispatch(setBuilderStep(BuilderStep.Target))
  }

  const onActivateClick = () => {
    dispatch(setBuilderStep(BuilderStep.Activate))
  }

  const wizardSteps: Array<EuiStepHorizontalProps> = [
    {
      title: 'Create',
      isSelected: builder.builderStep >= 10 && builder.builderStep < 40,
      isComplete: builder.builderStep >= 40,
      disabled: builder.builderStep < 10,
      onClick: onCreateAdClick
    },
    {
      title: 'Target',
      isSelected: builder.builderStep >= 40 && builder.builderStep < 99,
      isComplete: builder.builderStep >= 99,
      disabled: builder.builderStep < 20,
      onClick: onSetTargetingClick
    },
    {
      title: 'Activate',
      isSelected: builder.builderStep >= 99,
      isComplete: builder.builderStep >= 99,
      disabled: builder.builderStep < 99,
      onClick: onActivateClick
    }
  ]

  return (
    <React.Fragment>
      {!isInSignupProcess && (
        <EuiPageHeader>
          <EuiPageHeaderSection>
            <EuiTitle>
              <h1>New Internet Campaign</h1>
            </EuiTitle>
          </EuiPageHeaderSection>
        </EuiPageHeader>
      )}
      <EuiPanel grow={false} className='eui-xScroll' style={{ margin: 12 }}>
        <EuiHideFor sizes={['s']}>
          <EuiFlexGroup alignItems={'center'}>
            <EuiFlexItem>
              <EuiStepsHorizontal steps={wizardSteps} />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='m' />
        </EuiHideFor>
        {content}
      </EuiPanel>
    </React.Fragment>
  )
}

export default Page
