import React, { FC, useState } from 'react'

import { EuiFormRow, EuiSpacer, EuiTitle } from '@elastic/eui'

import { Campaign, CampaignType, SegmentTarget, ThirdPartyAudience } from 'api/interfaces'
import { IAudienceTargeting } from 'api/rtkQueryApi/platform/campaignsApi'
import { NewSegmentFinder } from 'components/finders/NewSegmentFinder'
import { AudienceTargeterDetailed } from 'components/targeters/AudienceTargeterDetailed'

export interface ModernAudienceTargeterProps {
  title: string
  campaign?: Campaign
  audienceTargeting: IAudienceTargeting
  onSegmentClicked: (segment: SegmentTarget) => void
  onAudienceRemoved: (audience: ThirdPartyAudience) => void
}

const ModernAudienceTargeter: FC<ModernAudienceTargeterProps> = ({ title, campaign, audienceTargeting, onSegmentClicked, onAudienceRemoved }) => {
  return (
    <>
      <EuiTitle size='s'>
        <span>{title}</span>
      </EuiTitle>
      <EuiSpacer size='s' />
      <EuiFormRow label='Search for unique audience characteristics (i.e. homeowners, luxury goods, etc.)' fullWidth>
        <NewSegmentFinder campaignType={campaign?.tvTargeting ? CampaignType.TV : CampaignType.Internet} isInvalid={false} onSegmentClicked={onSegmentClicked} />
      </EuiFormRow>
      {(audienceTargeting.segments?.length ?? 0) > 0 && (
        <EuiFormRow fullWidth label='Your campaign will prioritize individuals in any of these categories'>
          <AudienceTargeterDetailed
            audiences={audienceTargeting.segments.map(s => ({
              id: s.id,
              name: s.name,
              description: s.description ?? '',
              provider: '',
              reachCount: -1,
              price: -1,
              hierarchy: [],
              sourceDetails: ''
            }))}
            onAudienceRemoved={onAudienceRemoved}
          />
        </EuiFormRow>
      )}
    </>
  )
}

export default ModernAudienceTargeter
