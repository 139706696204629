export enum TargetingType {
  Standard = 'Standard',
  Location = 'Location',
  Audience = 'Audience',
  Search = 'Search',
  Advanced = 'Advanced',
  Retarget = 'Retarget'
}

export enum BuilderStyle {
  Suggested = 'Suggested',
  Build = 'Build',
  Upload = 'Upload',
  Custom = 'Custom'
}
