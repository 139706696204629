import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Moment } from 'moment'

import { apiGetSuggestedTvCommercials, apiGetTvCommercialVideoPreviewUrl, apiPostTvBuilderRetail } from 'api/adcritterApi'
import { BuilderResult, BusinessType, City, DayPartitioning, FrequencyUnit, LocationTarget, SegmentTarget, ThirdPartyAudience, TvCommercial, TvCommercialCustomization, TvCommercialPreviewCustomization, Zip } from 'api/interfaces'
import { dashboardApi } from 'api/rtkQueryApi/platform/dashboardApi'
import { RootState } from 'app/rootReducer'
import { AppThunk } from 'app/store'
import { DefaultDayParts } from 'components/targeters/DayPartPicker'
import { fetchCampaigns } from 'features/campaigns/campaigns/campaignsSlice'
import history from 'services/HistoryService'
import localStoreManagerService from 'services/LocalStoreManagerService'

export enum BuilderStyle {
  Suggested = 'Suggested',
  Custom = 'Custom',
  Upload = 'Upload'
}

export enum BuilderStep {
  Start = 10,
  Suggest = 20,
  Questionnaire = 21,
  QuestionnaireDone = 22,
  Suggestions = 23,
  Create = 30,
  Upload = 31,
  Target = 40,
  Deliver = 50,
  Activate = 99
}

export interface Suggest {
  companyName: string
  cityName: string
}

export enum AdPlacementType {
  Everywhere = 'Everywhere',
  Categories = 'Categories'
}

export interface Targeting {
  locationType: string | null
  states: Array<string>
  dmas: Array<string>
  cities: Array<City>
  zips: Array<Zip>
  ageRanges: Array<string>
  genders: Array<string>
  householdIncomes: Array<string>
  audiences: Array<ThirdPartyAudience>
  frequency: number | null
  frequencyUnit: FrequencyUnit | null
  dayParts: DayPartitioning
  syncShopifyCustomerData?: boolean
  uploadedAudienceUrl?: string
  locations: LocationTarget
  segments: SegmentTarget[]
}

export interface Deliver {
  placement: AdPlacementType | null
  categories: Array<string>
}

export interface TvBuilderState {
  builderStep: BuilderStep
  builderStyle: BuilderStyle
  businessType: BusinessType | null
  suggest: Suggest | null
  tvCommercials: TvCommercial[] | null
  isLoadingTvCommercials: boolean
  tvCommercial: TvCommercial | null
  create: TvCommercialCustomization | null
  uploadedUrl: string | null
  previewUrl: string | null
  isLoadingPreviewUrl: boolean
  targeting: Targeting | null
  deliver: Deliver | null
  isCreating: boolean
  result: BuilderResult | null
  name: string | null
  budget: number | null
  totalBudget: number | null
}

const initialState: TvBuilderState = {
  builderStep: localStoreManagerService.getData('builderTvRetail.builderStep') || BuilderStep.Start,
  builderStyle: localStoreManagerService.getData('builderTvRetail.builderStyle') || BuilderStyle.Suggested,
  businessType: localStoreManagerService.getData('builderTvRetail.businessType'),
  suggest: localStoreManagerService.getData('builderTvRetail.suggest'),
  tvCommercials: localStoreManagerService.getData('builderTvRetail.tvCommercials'),
  isLoadingTvCommercials: false,
  tvCommercial: localStoreManagerService.getData('builderTvRetail.tvCommercial'),
  create: localStoreManagerService.getData('builderTvRetail.create'),
  uploadedUrl: localStoreManagerService.getData('builderTvRetail.uploadedUrl'),
  previewUrl: localStoreManagerService.getData('builderTvRetail.previewUrl'),
  isLoadingPreviewUrl: false,
  targeting: localStoreManagerService.getData('builderTvRetail.targeting'),
  deliver: localStoreManagerService.getData('builderTvRetail.deliver') || {
    placement: AdPlacementType.Everywhere,
    categories: []
  },
  isCreating: false,
  result: null,
  name: localStoreManagerService.getData('builderTvRetail.name'),
  budget: localStoreManagerService.getData('builderTvRetail.budget'),
  totalBudget: localStoreManagerService.getData('builderTvRetail.totalBudget')
}

function reset(state: TvBuilderState) {
  state.builderStep = BuilderStep.Start
  state.builderStyle = BuilderStyle.Suggested
  state.businessType = null
  state.suggest = null
  state.tvCommercials = null
  state.isLoadingTvCommercials = false
  state.tvCommercial = null
  state.uploadedUrl = null
  state.create = null
  state.previewUrl = null
  state.isLoadingPreviewUrl = false
  state.targeting = null
  state.deliver = null
  state.isCreating = false
  state.result = null
  state.name = null
  state.budget = null
  state.totalBudget = null

  localStoreManagerService.deleteData('builderTvRetail.builderStep')
  localStoreManagerService.deleteData('builderTvRetail.builderStyle')
  localStoreManagerService.deleteData('builderTvRetail.businessType')
  localStoreManagerService.deleteData('builderTvRetail.suggest')
  localStoreManagerService.deleteData('builderTvRetail.tvCommercials')
  localStoreManagerService.deleteData('builderTvRetail.tvCommercial')
  localStoreManagerService.deleteData('builderTvRetail.create')
  localStoreManagerService.deleteData('builderTvRetail.uploadedUrl')
  localStoreManagerService.deleteData('builderTvRetail.outroUrls')
  localStoreManagerService.deleteData('builderTvRetail.targeting')
  localStoreManagerService.deleteData('builderTvRetail.deliver')
  localStoreManagerService.deleteData('builderTvRetail.name')
  localStoreManagerService.deleteData('builderTvRetail.budget')
  localStoreManagerService.deleteData('builderTvRetail.totalBudget')
}

const builderTvRetail = createSlice({
  name: 'builderTvRetail',
  initialState,
  reducers: {
    startBuilder(state: TvBuilderState) {
      reset(state)
      history.push('/build/tv/public/start')
    },
    resetBuilder(state: TvBuilderState) {
      reset(state)
    },
    setBuilderStep(state: TvBuilderState, { payload }: PayloadAction<BuilderStep>) {
      state.builderStep = payload
      localStoreManagerService.saveSessionData('builderTvRetail.builderStep', payload)
    },
    setBuilderStyle(state: TvBuilderState, { payload }: PayloadAction<BuilderStyle>) {
      state.builderStyle = payload
      localStoreManagerService.saveSessionData('builderTvRetail.builderStyle', payload)
    },
    setTargeting(state: TvBuilderState, { payload }: PayloadAction<Targeting>) {
      state.targeting = payload
      localStoreManagerService.saveSessionData('builderTvRetail.targeting', payload)
    },
    setBusinessType(state: TvBuilderState, { payload }: PayloadAction<BusinessType>) {
      state.businessType = payload
      localStoreManagerService.saveSessionData('builderTvRetail.businessType', payload)
    },
    setSuggest(state: TvBuilderState, { payload }: PayloadAction<Suggest>) {
      state.suggest = payload
      localStoreManagerService.saveSessionData('builderTvRetail.suggest', payload)
    },
    startLoadingTvCommercials(state: TvBuilderState) {
      state.tvCommercials = []
      state.isLoadingTvCommercials = true
    },
    finishedLoadingTvCommercials(state: TvBuilderState, { payload }: PayloadAction<TvCommercial[]>) {
      state.tvCommercials = payload
      state.isLoadingTvCommercials = false
      localStoreManagerService.saveSessionData('builderTvRetail.tvCommercials', payload)
    },
    setTvCommercial(state: TvBuilderState, { payload }: PayloadAction<TvCommercial>) {
      state.tvCommercial = payload
      localStoreManagerService.saveSessionData('builderTvRetail.tvCommercial', payload)
    },
    setPreviewUrl(state: TvBuilderState, { payload }: PayloadAction<string>) {
      state.previewUrl = payload
    },
    startLoadingPreviewUrl(state: TvBuilderState) {
      state.isLoadingPreviewUrl = true
      state.previewUrl = null
    },
    finishedLoadingPreviewUrl(state: TvBuilderState, { payload }: PayloadAction<string>) {
      state.previewUrl = payload
      localStoreManagerService.saveSessionData('builderTvRetail.previewUrl', payload)
      state.isLoadingPreviewUrl = false
    },
    setCreate(state: TvBuilderState, { payload }: PayloadAction<TvCommercialCustomization>) {
      state.create = payload
      localStoreManagerService.saveSessionData('builderTvRetail.create', payload)
    },
    setUploadedUrl(state: TvBuilderState, { payload }: PayloadAction<string>) {
      state.uploadedUrl = payload
      localStoreManagerService.saveSessionData('builderTvRetail.uploadedUrl', payload)
    },
    setDeliver(state: TvBuilderState, { payload }: PayloadAction<Deliver>) {
      state.deliver = payload
      localStoreManagerService.saveSessionData('builderTvRetail.deliver', state.deliver)
    },
    startCreating(state: TvBuilderState) {
      state.isCreating = true
    },
    setNameAndBudget(state: TvBuilderState, { payload }: PayloadAction<{ name: string; budget: number; totalBudget: number }>) {
      state.name = payload.name
      state.budget = payload.budget
      state.totalBudget = payload.totalBudget
      localStoreManagerService.saveSessionData('builderTvRetail.name', payload.name)
      localStoreManagerService.saveSessionData('builderTvRetail.budget', payload.budget)
      localStoreManagerService.saveSessionData('builderTvRetail.totalBudget', payload.totalBudget)
    },
    finishedCreating(state: TvBuilderState, { payload }: PayloadAction<BuilderResult>) {
      state.result = payload
      state.isCreating = false
    }
  }
})

export const { startBuilder, resetBuilder, setBuilderStep, setBuilderStyle, setBusinessType, setSuggest, startLoadingTvCommercials, finishedLoadingTvCommercials, setTvCommercial, setCreate, setUploadedUrl, setPreviewUrl, startLoadingPreviewUrl, finishedLoadingPreviewUrl, setTargeting, setDeliver, startCreating, setNameAndBudget, finishedCreating } = builderTvRetail.actions

export const fetchTvCommercials =
  (accountId: string, businessTypeId: string): AppThunk =>
  async dispatch => {
    dispatch(startLoadingTvCommercials())
    const response = await apiGetSuggestedTvCommercials(accountId, businessTypeId)
    dispatch(finishedLoadingTvCommercials(response.data))
  }

const getTvRetailBuilder = (state: RootState) => state.builderTvRetail

export const fetchPreviewUrl =
  (accountId: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startLoadingPreviewUrl())
    const builder = getTvRetailBuilder(getState())
    const response = await apiGetTvCommercialVideoPreviewUrl(accountId, builder.tvCommercial!.id, { sections: builder.create!.sections } as TvCommercialPreviewCustomization)
    dispatch(finishedLoadingPreviewUrl(response.data))
  }

export const createTvPlanCampaign = (accountId: string, campaignName: string, campaignSlotId: string | null, saveForLater: boolean, name?: string, phone?: string, email?: string): AppThunk => {
  return createCampaign(accountId, campaignName, 0, 0, false, null, saveForLater, name ?? '', phone ?? '', email ?? '', null, null, campaignSlotId)
}

export const createTvQuickCampaign = (accountId: string, campaignName: string, campaignSlotId: string | null, saveForLater: boolean, budget: number, name?: string, phone?: string, email?: string, isDays30?: boolean): AppThunk => {
  return createCampaign(accountId, campaignName, budget, 0, false, null, saveForLater, name ?? '', phone ?? '', email ?? '', null, null, campaignSlotId, isDays30)
}

export const createCampaign =
  (accountId: string, campaignName: string, budget: number, maxCpm: number | null, isTotalBudget: boolean, paymentMethodId: string | null, saveForLater: boolean, name: string | null, phone: string | null, email: string | null, start: Moment | null, end: Moment | null, campaignSlotId: string | null, isDays30?: boolean): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startCreating())
    const isVendastaUser = getState().app.isVendastaUser
    const builder = getTvRetailBuilder(getState())

    const response = await apiPostTvBuilderRetail(accountId, {
      campaignName: campaignName,
      budget: budget,
      maxCpm: maxCpm,
      isTotalBudget: isTotalBudget,
      isDays30: isDays30 ?? false,
      businessTypeId: builder.businessType?.id ?? null,
      tvCommercialId: builder.tvCommercial?.id ?? null,
      companyName: builder.suggest?.companyName ?? null,
      city: builder.suggest?.cityName ?? null,
      name: name,
      phone: phone,
      email: email,
      sections: builder.create?.sections ?? null,
      script: builder.create?.script ?? null,
      uploadedUrl: builder.uploadedUrl,
      locationType: builder.targeting!.locationType,
      audiences: [],
      segments: builder.targeting!.segments,
      locations: builder.targeting!.locations,
      states: [],
      dmas: [],
      cities: [],
      zips: [],
      ageRanges: builder.targeting!.ageRanges,
      genders: builder.targeting!.genders,
      householdIncomes: builder.targeting!.householdIncomes,
      categories: builder.deliver!.categories,
      placement: builder.deliver!.placement!,
      paymentMethodId: paymentMethodId,
      setupIntentId: null,
      saveForLater: saveForLater,
      hasCreative: builder.builderStyle !== 'Custom',
      previewUrl: builder.previewUrl,
      startDate: start,
      endDate: end,
      frequency: builder.targeting!.frequency,
      frequencyUnit: builder.targeting!.frequencyUnit,
      dayParts: builder.targeting!.dayParts ?? DefaultDayParts,
      campaignSlotId: campaignSlotId,
      syncShopifyCustomerData: builder.targeting!.syncShopifyCustomerData ?? false,
      uploadedAudienceUrl: builder.targeting!.uploadedAudienceUrl,
      isStrawberryFieldUser: isVendastaUser
    })
    dispatch(finishedCreating(response.data))
    dispatch(fetchCampaigns(accountId))
    dispatch(dashboardApi.util.invalidateTags([{ type: 'DashboardFilter', id: accountId }]))
  }

export default builderTvRetail.reducer
