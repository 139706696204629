import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Moment } from 'moment'

import { apiGetSuggestedTvCommercials, apiGetTvCommercialVideoPreviewUrl, apiPostTvBuilderRetail } from 'api/adcritterApi'
import { BuilderResult, BusinessType, City, FrequencyUnit, ThirdPartyAudience, TvCommercial, TvCommercialCustomization, TvCommercialPreviewCustomization, Zip } from 'api/interfaces'
import { dashboardApi } from 'api/rtkQueryApi/platform/dashboardApi'
import { RootState } from 'app/rootReducer'
import { AppThunk } from 'app/store'
import { IDayPart } from 'components/targeters/DayPartPicker'
import { fetchCampaigns } from 'features/campaigns/campaigns/campaignsSlice'
import history from 'services/HistoryService'
import localStoreManagerService from 'services/LocalStoreManagerService'

export enum CreateAdStep {
  Start = 10,
  Upload = 20
}

export interface TvBuilderState {
  createAdStep: CreateAdStep
  uploadedUrl: string | null
}

const initialState: TvBuilderState = {
  createAdStep: localStoreManagerService.getData('createAdTvRetail.createAdStep') || CreateAdStep.Start,
  uploadedUrl: localStoreManagerService.getData('createAdTvRetail.uploadedUrl')
}

function reset(state: TvBuilderState) {
  state.createAdStep = CreateAdStep.Start
  state.uploadedUrl = null

  localStoreManagerService.deleteData('createAdTvRetail.createAdStep')
  localStoreManagerService.deleteData('createAdTvRetail.uploadedUrl')
}

const createAdTvRetail = createSlice({
  name: 'builderTvRetail',
  initialState,
  reducers: {
    startCreateAdStep(state: TvBuilderState) {
      reset(state)
      history.push('/campaigns/tv/public/start')
    },
    resetCreateAd(state: TvBuilderState) {
      reset(state)
    },
    setCreateAdStep(state: TvBuilderState, { payload }: PayloadAction<CreateAdStep>) {
      state.createAdStep = payload
      localStoreManagerService.saveSessionData('createAdTvRetail.createAdStep', payload)
    },
    setUploadedUrl(state: TvBuilderState, { payload }: PayloadAction<string>) {
      state.uploadedUrl = payload
      localStoreManagerService.saveSessionData('builderTvRetail.uploadedUrl', payload)
    }
  }
})

export const { startCreateAdStep, resetCreateAd, setCreateAdStep, setUploadedUrl } = createAdTvRetail.actions

const getTvRetailBuilder = (state: RootState) => state.builderTvRetail

export default createAdTvRetail.reducer
