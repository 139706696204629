import { useState } from 'react'

import { EuiFlexGrid, EuiFlexItem, EuiLoadingSpinner } from '@elastic/eui'

import { BusinessType } from 'api/interfaces'
import { useGetShopifyPremierBusinessTypesQuery } from 'api/rtkQueryApi/platform/businessTypesApi'
import { BusinessTypePanelOption } from 'components/shopify/BusinessTypePanelOption'

export interface IPremierBusinessTypeOptions {
  businessTypes?: BusinessType[]
  onBusinessTypeSelected?: (businessType: BusinessType) => void
}

export const PremierBusinessTypeOptions: React.FC<IPremierBusinessTypeOptions> = props => {
  const businessTypesQuery = useGetShopifyPremierBusinessTypesQuery()
  const [selectedBusinessTypeId, setSelectedBusinessTypeId] = useState<string>()

  const onBusinessTypeSelected = (businessType: BusinessType) => {
    setSelectedBusinessTypeId(businessType.id)
    if (props.onBusinessTypeSelected) {
      props.onBusinessTypeSelected(businessType)
    }
  }

  return (
    <EuiFlexGrid columns={4}>
      {(!businessTypesQuery.isFetching &&
        !businessTypesQuery.isLoading &&
        businessTypesQuery.data?.map(businessType => (
          <EuiFlexItem>
            <BusinessTypePanelOption
              isSelected={businessType.id === selectedBusinessTypeId}
              title={businessType.name}
              description={businessType.businessTypeCategories.join(', ')}
              imageSrc={businessType.imageUrl}
              businessType={businessType}
              onClick={() => {
                onBusinessTypeSelected(businessType)
              }}
            />
          </EuiFlexItem>
        ))) || (
        <EuiFlexItem>
          <EuiLoadingSpinner />
        </EuiFlexItem>
      )}
    </EuiFlexGrid>
  )
}
