export const grayTextColor = '#757A86'
export const rightPanelLinkTextColor = '#6D727F'
export const primaryTrajectoryColor = '#E6E6E6'
export const secondaryTrajectoryColor = '#A1A1A1'

export const repetitionDescription = 'Individuals need to see an ad multiple times a month before it begins to influence their perceptions. This measurement is the average number of times an individual in your audience has seen one of your ads.'
export const repetitionRightPanelDescription = 'This month'
export const repetitionRightPanelTitleDescription = 'avg.'
export const repetitionRightPanelLink = 'https://adcritter.com'
export const repetitionRightPanelLinkText = 'Learn more about repetition'
export const repetitionChartLine = '#4CB1D2'
export const repetitionChartArea = '#C2E3FC'
export const repetitionButtonColor = '#E3F1FD'
export const repetitionButtonTextColor = '#235783'
export const repetitionRightPanelColor = '#F3F9FF'
export const repetitionAttentionColor = '#FEFCE9'

export const saturationDescription = 'Campaigns always target an audience. These can be geographic and/or a specific group of people based on how it was created. Over time, the number of people in an audience who have experienced enough repetition grows. This is called saturation. The goal should be to saturate an audience to reach at least {saturation_target}% before expanding to new opportunities.'
export const saturationRightPanelLink = 'https://adcritter.com'
export const saturationRightPanelLinkText = 'Learn more about saturation'
export const saturationChartBarBorder = '#4EB3A6'
export const saturationChartBarArea = '#ABF1E5'
export const saturationButtonColor = '#D5F9F2'
export const saturationButtonTextColor = '#285B59'
export const saturationRightPanelColor = '#F2FCFA'

export const adFatigueDescription = 'Over time an audience seeing the same ad can grow tired of the ad. Eventually the ads will have a negative affect on the brand. Ad fatigue measures the effectiveness of each ad in the campaign over time.'
export const adFatigueRightPanelDescription = 'Status'
export const adFatigueChartViewArea = '#A8B4F9'
export const adFatigueChartFatigueArea = '#CE472B'
export const adFatigueHeadline = '#5049E0'
export const adFatigueButtonColor = '#E1E7FE'
export const adFatigueButtonTextColor = '#37329F'
export const adFatigueRightPanelColor = '#EFF2FF'

export const timeToBehaviorDescription = 'Advertising is most effective when it has sufficient time to change the behaviour of the target audience. Once your campaign reaches this milestone, behavior begins to change.'
export const timeToBehaviorRightPanelDescription = 'Remaining'
export const timeToBehaviorChartLoading = '#8B42E5'
export const timeToBehaviorChartText = '#8B42E5'
export const timeToBehaviorButtonColor = '#F3E9FE'
export const timeToBehaviorButtonTextColor = '#652DA4'
export const timeToBehaviorDescriptionTextColor = '#757A86'
export const timeToBehaviorRightPanelColor = '#FBF6FF'
export const timeToBehaviorAttentionColor = '#FEFCE9'
