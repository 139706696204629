import { StripeError } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiAccordion, EuiButton, EuiFormRow, EuiLoadingSpinner, EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle, EuiOverlayMask, EuiRadioGroup, EuiRadioGroupOption, EuiSpacer, EuiText } from '@elastic/eui'
import { ButtonSize } from '@elastic/eui/src/components/button/button'

import { Account, BundleType, PaymentMethodType } from 'api/interfaces'
import { useGetOrganizationBundlesQuery } from 'api/rtkQueryApi/platform/bundlesApi'
import { useGetPaymentMethodsQuery } from 'api/rtkQueryApi/platform/paymentMethodsApi'
import { RootState } from 'app/rootReducer'
import { AddPaymentMethod } from 'components/payments/AddPaymentMethod'
import { CampaignType } from 'utils/CampaignType'

export interface IPaymentMethodPickerState {
  selectedPaymentMethodId: string
  selectedCampaignSlotId: string
}

export const usePaymentMethodPickerState = () => {
  return useState<IPaymentMethodPickerState>({
    selectedPaymentMethodId: '',
    selectedCampaignSlotId: ''
  })
}

export interface PaymentMethodPickerProps {
  account?: Account
  campaignType?: CampaignType
  disableBundlePayment?: boolean
  paymentMethodPickerState: IPaymentMethodPickerState
  updatePaymentMethodPickerState: (newState: IPaymentMethodPickerState) => void
  onAddPaymentMethodClicked?: () => void
  return_url?: string
  onError?: (error: StripeError) => void
  preSubmitAsync?: () => Promise<void>
  preSubmit?: () => void
  includedCampaignId?: string
  buttonSize?: ButtonSize
}

export const PaymentMethodPicker: React.FC<PaymentMethodPickerProps> = ({ account, campaignType, paymentMethodPickerState, updatePaymentMethodPickerState, buttonSize, ...props }) => {
  const { currentAccount, currentOrganization, isStaffUser } = useSelector((state: RootState) => state.app)
  const organizationBundles = useGetOrganizationBundlesQuery(undefined, { skip: !isStaffUser || !currentOrganization || props.disableBundlePayment })
  const paymentMethods = useGetPaymentMethodsQuery({ accountId: account?.id ?? currentAccount?.id ?? '' }, { skip: !account && !currentAccount })
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<EuiRadioGroupOption[]>([])
  const [availableCampaignSlots, setAvailableCampaignSlots] = useState<EuiRadioGroupOption[]>([])
  const [showAddPaymentMethod, setShowAddPaymentMethod] = useState<boolean>(false)

  useEffect(() => {
    if (organizationBundles.data && !organizationBundles.isLoading && !organizationBundles.isFetching && isStaffUser && !props.disableBundlePayment) {
      const availableSlots = []
      for (let bundle of organizationBundles.data) {
        if (bundle.bundleType === BundleType.SelfServicePackage) {
          continue
        }

        for (let slot of bundle.campaignSlots) {
          if (props.includedCampaignId && slot.campaignId === props.includedCampaignId) {
            availableSlots.push({ id: slot.id, label: `${bundle.name ? bundle.name + ': ' : ''}$${slot.budget} ${slot.campaignType}` })
          } else if (!slot.campaignId && slot.campaignType === campaignType) {
            availableSlots.push({ id: slot.id, label: `${bundle.name ? bundle.name + ': ' : ''}$${slot.budget} ${slot.campaignType}` })
          }
        }
      }
      setAvailableCampaignSlots(availableSlots)
    }
  }, [organizationBundles, campaignType, props.disableBundlePayment, props.includedCampaignId])

  useEffect(() => {
    if (paymentMethods.data) {
      const defaultMethod = paymentMethods.data.find(pm => pm.isDefault)

      if (paymentMethods.data.length <= 0) {
        return
      }

      setPaymentMethodOptions([
        {
          id: 'default',
          label: `Always Use Default: ${defaultMethod?.paymentMethodType == PaymentMethodType.BankAccount ? `Bank: ${defaultMethod?.name}` : `Card: ${defaultMethod?.brand} ending xxxx${defaultMethod?.last4}`}`
        },
        ...paymentMethods.data.map(pm => ({ id: pm.id, label: `${pm.paymentMethodType == PaymentMethodType.BankAccount ? `Bank: ${pm.name}` : `Card: ${pm.brand}`} ending xxxx${pm.last4}` }))
      ])

      if (defaultMethod?.id && paymentMethodPickerState.selectedPaymentMethodId.length <= 0 && paymentMethodPickerState.selectedCampaignSlotId?.length <= 0) {
        updatePaymentMethodPickerState({ selectedPaymentMethodId: 'default', selectedCampaignSlotId: '' })
      }
    }
  }, [paymentMethods, paymentMethodPickerState])

  const onCampaignSlotSelected = (id: string) => {
    updatePaymentMethodPickerState({
      selectedPaymentMethodId: '',
      selectedCampaignSlotId: id
    })
  }

  const onPaymentMethodSelected = (id: string) => {
    updatePaymentMethodPickerState({
      selectedPaymentMethodId: id,
      selectedCampaignSlotId: ''
    })
  }

  const onCloseAddPaymentMethod = () => {
    setShowAddPaymentMethod(false)
  }

  const onShowAddPaymentMethodClick = () => {
    props.onAddPaymentMethodClicked?.()
    setShowAddPaymentMethod(true)
  }

  return (
    <React.Fragment>
      <EuiFormRow label='Select your payment method'>
        <React.Fragment>
          {availableCampaignSlots && availableCampaignSlots.length > 0 ? (
            <React.Fragment>
              <EuiAccordion id={'bundleCampaignSlot'} buttonContent='Plan Campaign Slot' paddingSize='m' initialIsOpen={true}>
                <EuiRadioGroup options={availableCampaignSlots} onChange={onCampaignSlotSelected} idSelected={paymentMethodPickerState.selectedCampaignSlotId ?? undefined} />
              </EuiAccordion>
              {paymentMethodOptions.length > 0 && (
                <EuiAccordion id={'paymentMethod'} buttonContent='Payment Method' paddingSize='m' initialIsOpen={true}>
                  <EuiRadioGroup options={paymentMethodOptions} onChange={onPaymentMethodSelected} idSelected={paymentMethodPickerState.selectedPaymentMethodId} />
                </EuiAccordion>
              )}
            </React.Fragment>
          ) : (
            <>
              {paymentMethodOptions.length > 0 && <EuiRadioGroup options={paymentMethodOptions} onChange={onPaymentMethodSelected} idSelected={paymentMethodPickerState.selectedPaymentMethodId} />}
              {paymentMethodOptions.length <= 0 && (
                <>
                  <EuiSpacer size='s' />
                  <EuiText size='xs'>No payment methods available.</EuiText>
                </>
              )}
            </>
          )}
          {paymentMethods.isFetching && (
            <React.Fragment>
              <EuiSpacer />
              <EuiLoadingSpinner />
            </React.Fragment>
          )}
          <EuiSpacer />
          <EuiButton onClick={onShowAddPaymentMethodClick} isDisabled={showAddPaymentMethod} size={buttonSize}>
            Add Payment Method
          </EuiButton>
          {currentAccount && showAddPaymentMethod && (
            <EuiOverlayMask headerZindexLocation='above'>
              <EuiModal onClose={onCloseAddPaymentMethod}>
                <EuiModalHeader>
                  <EuiModalHeaderTitle>Add Payment Method</EuiModalHeaderTitle>
                </EuiModalHeader>
                <EuiModalBody>
                  <AddPaymentMethod accountId={currentAccount.id} return_url={props.return_url} onError={props.onError} preSubmit={props.preSubmit} preSubmitAsync={props.preSubmitAsync} />
                </EuiModalBody>
              </EuiModal>
            </EuiOverlayMask>
          )}
        </React.Fragment>
      </EuiFormRow>
    </React.Fragment>
  )
}
