import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { EuiButton, EuiCard, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiLink, EuiSpacer, EuiTitle } from '@elastic/eui'

import { apiGetBusinessType, apiGetCampaign, apiPutAccountBusinessType } from 'api/adcritterApi'
import { BusinessType, Campaign } from 'api/interfaces'
import { updateAccountState } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { resetSearchImage } from 'components/chooseImage/searchImageSlice'
import { BusinessTypeFinder } from 'components/finders/BusinessTypeFinder'
import { clearSuggestions as clearGeneralSuggestions } from 'components/suggestedAds/generalSuggestionSlice'
import history from 'services/HistoryService'

import { setSuggestedChoices, setBusinessType, Suggest } from './createAdInternetRetailSlice'

interface AdBuildStepParams {
  campaignId: string
}

const SuggestStep: React.FC = () => {
  const dispatch = useDispatch()
  let { campaignId } = useParams<AdBuildStepParams>()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const createAd = useSelector((state: RootState) => state.createAdInternetRetail)
  const [campaign, setCampaign] = useState<Campaign>()
  const [chosenBusinessType, setChosenBusinessType] = useState<BusinessType | null>(null)
  const [businessTypeError, setBusinessTypeError] = useState(false)
  const [isLoadingBusinessType, setIsLoadingBusinessType] = useState(false)
  const [initialValues, setInitialValues] = useState<Suggest>({
    city: '',
    companyName: ''
  })

  useEffect(() => {
    if (currentAccount && campaignId) {
      apiGetCampaign(currentAccount.id, campaignId).then(response => setCampaign(response.data))
    }
  }, [dispatch, currentAccount, campaignId])

  useEffect(() => {
    if (createAd.suggest) {
      setInitialValues(createAd.suggest)
    } else if (currentAccount) {
      let initial = { ...initialValues }
      initial.companyName = currentAccount.name
      if (currentAccount.city && currentAccount.city !== '') {
        initial.city = currentAccount.city
      }
      setInitialValues(initial)
      if (campaign && campaign.businessTypeId) {
        setIsLoadingBusinessType(true)
        apiGetBusinessType(campaign.businessTypeId).then(result => {
          setChosenBusinessType(result.data)
          setIsLoadingBusinessType(false)
        })
      } else if (currentAccount.businessTypeId) {
        setIsLoadingBusinessType(true)
        apiGetBusinessType(currentAccount.businessTypeId).then(result => {
          setChosenBusinessType(result.data)
          setIsLoadingBusinessType(false)
        })
      } else {
        setChosenBusinessType(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAd.suggest, currentAccount, campaign])

  useEffect(() => {
    if (createAd.businessType) {
      setChosenBusinessType(createAd.businessType)
    }
  }, [createAd.businessType])

  const suggestedChoicesSchema = Yup.object().shape({
    city: Yup.string(),
    companyName: Yup.string().required('Please enter your company name')
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: suggestedChoicesSchema,
    onSubmit: (values: Suggest) => {
      if (!chosenBusinessType) {
        setBusinessTypeError(true)
        formik.setSubmitting(false)
        return
      }
      dispatch(clearGeneralSuggestions())
      dispatch(setBusinessType(chosenBusinessType))
      dispatch(setSuggestedChoices(values))
      if (!currentAccount!.businessTypeId) apiPutAccountBusinessType(currentAccount!.id, chosenBusinessType!.id).then(result => dispatch(updateAccountState(result.data)))
      history.push('/campaigns/internet/public/suggestions')
    }
  })

  const returnChoose = () => {
    dispatch(resetSearchImage())
    history.push('/campaigns/internet/public/start')
  }

  const chooseBusinessType = (businessType: BusinessType | null) => {
    if (businessType) {
      setChosenBusinessType(businessType)
      setBusinessTypeError(false)
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Suggested Ads</title>
      </Helmet>
      <EuiTitle size='s'>{<h3>Suggested Ads</h3>}</EuiTitle>
      <EuiSpacer />
      <EuiFormRow label='Business Type' fullWidth isInvalid={businessTypeError}>
        <React.Fragment>
          {chosenBusinessType && (
            <React.Fragment>
              <EuiCard
                display='primary'
                layout='horizontal'
                paddingSize='s'
                titleSize='xs'
                icon={
                  <div>
                    <img alt='' src={chosenBusinessType.imageUrl} />
                  </div>
                }
                title={chosenBusinessType.name}
                description={chosenBusinessType.businessTypeCategories.join(', ')}
              />
              <EuiSpacer size='s' />
              <EuiLink color='success' onClick={() => setChosenBusinessType(null)}>
                Clear selected business type
              </EuiLink>
            </React.Fragment>
          )}
          {!chosenBusinessType && <BusinessTypeFinder onBusinessTypeClicked={chooseBusinessType} isInvalid={businessTypeError} />}
        </React.Fragment>
      </EuiFormRow>
      <EuiFormRow label='Company Name' fullWidth isInvalid={formik.touched.companyName && !!formik.errors.companyName} error={formik.errors.companyName} helpText='This will be the company name that appears in your ads'>
        <EuiFieldText name='companyName' value={formik.values.companyName} isInvalid={formik.touched.companyName && !!formik.errors.companyName} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth />
      </EuiFormRow>
      <EuiFormRow label='City' fullWidth helpText='Your business city as it should appear in local ads'>
        <EuiFieldText name='city' value={formik.values.city} onChange={formik.handleChange} fullWidth />
      </EuiFormRow>
      <EuiSpacer />

      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton id='back' fill type='button' color='text' onClick={returnChoose} iconType='arrowLeft' iconSide='left'>
            Back
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton id='continue' fill type='submit' isLoading={formik.isSubmitting} isDisabled={isLoadingBusinessType} iconType='arrowRight' iconSide='right' onClick={formik.submitForm}>
            Continue to Choose Ads
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default SuggestStep
