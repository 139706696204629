import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiDatePicker, EuiDatePickerRange, EuiFilterButton, EuiFilterGroup, EuiFilterSelectItem, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiFormRow, EuiPage, EuiPageBody, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiPopover, EuiPortal, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { IntervalUnit } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import ReportAccountGrid from 'features/reports/ReportAccountGrid'
import ReportAdGrid from 'features/reports/ReportAdGrid'
import ReportCampaignDimensionGrid from 'features/reports/ReportCampaignDimensionGrid'
import ReportCampaignGrid from 'features/reports/ReportCampaignGrid'

import { fetchCampaigns, fetchReportAccount, fetchReportAd, fetchReportCampaign, fetchReportCampaignDimension } from './reportsSlice'

enum ReportFlyoutType {
  None = 'None',
  Account = 'Account',
  Campaign = 'Campaign',
  CampaignDimension = 'CampaignDimension',
  CampaignDomain = 'CampaignDomain',
  Ad = 'Ad'
}

const ReportsPage: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const reports = useSelector((state: RootState) => state.reports)
  const [start, setStart] = useState<Moment>(moment().add(-31, 'd'))
  const [end, setEnd] = useState<Moment>(moment().add(-1, 'd'))
  const [isCampaignAdsPopoverOpen, setIsCampaignAdsPopoverOpen] = useState(false)
  const [isCampaignsPopoverOpen, setIsCampaignsPopoverOpen] = useState(false)
  const [isCampaignBreakDownByRangePopoverOpen, setIsCampaignBreakDownByRangePopoverOpen] = useState(false)
  const [isCampaignsDimensionPopoverOpen, setIsCampaignsDimensionPopoverOpen] = useState(false)
  const [isCampaignsDomainPopoverOpen, setIsCampaignsDomainPopoverOpen] = useState(false)
  const [isDimensionPopoverOpen, setIsDimensionPopoverOpen] = useState(false)
  const [isDomainBreakDownByRangePopoverOpen, setIsDomainBreakDownByRangePopoverOpen] = useState(false)
  const [selectedCampaigns, setSelectedCampaigns] = useState<Array<string>>([])
  const [selectedCampaignDimensions, setSelectedCampaignDimensions] = useState<Array<string>>([])
  const [selectedCampaignDomain, setSelectedCampaignDomain] = useState<Array<string>>([])
  const [selectedCampaignAds, setSelectedCampaignAds] = useState<Array<string>>([])
  const [hidden, setHidden] = useState(false)
  const [dimension, setDimension] = useState('State')
  const [campaignBreakDownBy, setCampaignBreakDownBy] = useState(IntervalUnit.Day)
  const [domainBreakDownBy, setDomainBreakDownBy] = useState(IntervalUnit.Week)
  const [reportFlyoutType, setReportFlyoutType] = useState(ReportFlyoutType.None)

  const internetCampaigns = reports.campaigns.filter(c => c.type === 'Internet')

  useEffect(() => {
    if (currentAccount) {
      dispatch(fetchCampaigns(currentAccount.id))
    }
  }, [currentAccount, dispatch])

  useEffect(() => {
    if (reports.campaigns && reports.campaigns.length > 0) {
      setSelectedCampaigns(reports.campaigns.map(c => c.id))
      setSelectedCampaignDimensions(reports.campaigns.filter(c => c.type !== 'Billboard').map(c => c.id))
      setSelectedCampaignDomain(internetCampaigns.map(c => c.id))
      setSelectedCampaignAds(reports.campaigns.map(c => c.id))
    }
  }, [reports.campaigns])

  const closeFlyout = () => setReportFlyoutType(ReportFlyoutType.None)

  const onCampaignAdsButtonClick = () => {
    setIsCampaignAdsPopoverOpen(!isCampaignAdsPopoverOpen)
  }

  const closeCampaignAdsPopover = () => {
    setIsCampaignAdsPopoverOpen(false)
  }

  const onCampaignsButtonClick = () => {
    setIsCampaignsPopoverOpen(!isCampaignsPopoverOpen)
  }

  const onCampaignsDimensionButtonClick = () => {
    setIsCampaignsDimensionPopoverOpen(!isCampaignsDimensionPopoverOpen)
  }

  const closeCampaignsPopover = () => {
    setIsCampaignsPopoverOpen(false)
  }

  const closeCampaignsDimensionPopover = () => {
    setIsCampaignsDimensionPopoverOpen(false)
  }

  const onDimensionButtonClick = () => {
    setIsDimensionPopoverOpen(!isDimensionPopoverOpen)
  }

  const closeDimensionPopover = () => {
    setIsDimensionPopoverOpen(false)
  }

  const dateBreakdownOptions = [{ value: 'daily', text: 'Daily' }]
  const [dateBreakdownValue, setDateBreakdownValue] = useState(dateBreakdownOptions[0].value)

  const toggleSelectedCampaign = (id: string) => {
    const selectedGroupIndex = selectedCampaigns.indexOf(id)
    const updatedSelectedGroups = [...selectedCampaigns]
    if (selectedGroupIndex >= 0) {
      updatedSelectedGroups.splice(selectedGroupIndex, 1)
    } else {
      updatedSelectedGroups.push(id)
    }
    setSelectedCampaigns(updatedSelectedGroups)
  }

  const toggleSelectedCampaignDimension = (id: string) => {
    const selectedGroupIndex = selectedCampaignDimensions.indexOf(id)
    const updatedSelectedGroups = [...selectedCampaignDimensions]
    if (selectedGroupIndex >= 0) {
      updatedSelectedGroups.splice(selectedGroupIndex, 1)
    } else {
      updatedSelectedGroups.push(id)
    }
    setSelectedCampaignDimensions(updatedSelectedGroups)
  }

  const toggleSelectedCampaignDomain = (id: string) => {
    const selectedGroupIndex = selectedCampaignDomain.indexOf(id)
    const updatedSelectedGroups = [...selectedCampaignDomain]
    if (selectedGroupIndex >= 0) {
      updatedSelectedGroups.splice(selectedGroupIndex, 1)
    } else {
      updatedSelectedGroups.push(id)
    }
    setSelectedCampaignDomain(updatedSelectedGroups)
  }

  const toggleSelectedCampaignAd = (id: string) => {
    const selectedGroupIndex = selectedCampaignAds.indexOf(id)
    const updatedSelectedGroups = [...selectedCampaignAds]
    if (selectedGroupIndex >= 0) {
      updatedSelectedGroups.splice(selectedGroupIndex, 1)
    } else {
      updatedSelectedGroups.push(id)
    }
    setSelectedCampaignAds(updatedSelectedGroups)
  }

  const onDownloadAdPerformanceClick = () => {
    dispatch(fetchReportAd(currentAccount!.id, selectedCampaignAds, start, end))
    setReportFlyoutType(ReportFlyoutType.Ad)
  }

  const onDownloadCampaignPerformanceClick = () => {
    dispatch(fetchReportCampaign(currentAccount!.id, selectedCampaigns, start, end, campaignBreakDownBy))
    setReportFlyoutType(ReportFlyoutType.Campaign)
  }

  const onDownloadCampaignDimensionPerformanceClick = () => {
    dispatch(fetchReportCampaignDimension(currentAccount!.id, selectedCampaignDimensions, dimension, IntervalUnit.Day, start, end))
    setReportFlyoutType(ReportFlyoutType.CampaignDimension)
  }

  const onDownloadCampaignDomainPerformanceClick = () => {
    dispatch(fetchReportCampaignDimension(currentAccount!.id, selectedCampaignDomain, 'Domain', domainBreakDownBy, start, end))
    setReportFlyoutType(ReportFlyoutType.CampaignDomain)
  }

  const onDownloadAccountPerformanceClick = () => {
    dispatch(fetchReportAccount(currentAccount!.id, start, end))
    setReportFlyoutType(ReportFlyoutType.Account)
  }

  const uncheckAllCampaigns = () => {
    setHidden(!hidden)
    setSelectedCampaigns([])
  }

  const checkAllCampaigns = () => {
    setHidden(!hidden)
    let campaignIdArray = reports.campaigns.map(i => i.id)
    setSelectedCampaigns(campaignIdArray)
  }

  const uncheckAllCampaignsDimension = () => {
    setHidden(!hidden)
    setSelectedCampaignDimensions([])
  }

  const checkAllCampaignsDimension = () => {
    setHidden(!hidden)
    let campaignIdArray = reports.campaigns.filter(c => c.type !== 'Billboard').map(i => i.id)
    setSelectedCampaignDimensions(campaignIdArray)
  }

  const uncheckAllCampaignsDomain = () => {
    setHidden(!hidden)
    setSelectedCampaignDomain([])
  }

  const checkAllCampaignsDomain = () => {
    setHidden(!hidden)
    let campaignIdArray = internetCampaigns.map(i => i.id)
    setSelectedCampaignDomain(campaignIdArray)
  }

  const uncheckAllCampaignAds = () => {
    setHidden(!hidden)
    setSelectedCampaignAds([])
  }

  const checkAllCampaignAds = () => {
    setHidden(!hidden)
    let campaignIdArray = reports.campaigns.map(i => i.id)
    setSelectedCampaignAds(campaignIdArray)
  }

  let flyoutReportView
  if (reportFlyoutType !== ReportFlyoutType.None) {
    let hasData = false
    let isLoading = false
    let headerText = ''
    let reportGrid: JSX.Element | undefined

    switch (reportFlyoutType) {
      case ReportFlyoutType.Account:
        hasData = reports.reportAccount.length > 0
        isLoading = reports.isLoadingReportAccount
        headerText = 'Account Summary'
        reportGrid = <ReportAccountGrid data={reports.reportAccount} />
        break
      case ReportFlyoutType.Campaign:
        hasData = reports.reportCampaign.length > 0
        isLoading = reports.isLoadingReportCampaign
        headerText = 'Campaign Performance'
        reportGrid = <ReportCampaignGrid data={reports.reportCampaign} />
        break
      case ReportFlyoutType.CampaignDimension:
        hasData = reports.reportCampaignDimension.length > 0
        isLoading = reports.isLoadingReportCampaignDimension
        headerText = 'Breakdown Performance'
        reportGrid = <ReportCampaignDimensionGrid data={reports.reportCampaignDimension} dimension={dimension} />
        break
      case ReportFlyoutType.CampaignDomain:
        hasData = reports.reportCampaignDimension.length > 0
        isLoading = reports.isLoadingReportCampaignDimension
        headerText = 'Domain Performance'
        reportGrid = <ReportCampaignDimensionGrid data={reports.reportCampaignDimension} dimension={'Domain'} />
        break
      case ReportFlyoutType.Ad:
        hasData = reports.reportAd.length > 0
        isLoading = reports.isLoadingReportAd
        headerText = 'Ad Performance'
        reportGrid = <ReportAdGrid data={reports.reportAd} />
        break
    }

    if (!isLoading && hasData && reportGrid) {
      flyoutReportView = (
        <EuiPortal>
          <EuiFlyout onClose={closeFlyout} size='l' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
            <EuiFlyoutHeader>
              <EuiTitle size='s'>
                <h2>{headerText}</h2>
              </EuiTitle>
            </EuiFlyoutHeader>
            <EuiFlyoutBody>{reportGrid}</EuiFlyoutBody>
          </EuiFlyout>
        </EuiPortal>
      )
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <EuiPage className='euiPage--platform'>
        <EuiPageBody>
          <EuiPageHeader>
            <EuiPageHeaderSection>
              <EuiTitle>
                <h1>Reports</h1>
              </EuiTitle>
            </EuiPageHeaderSection>
          </EuiPageHeader>
          <EuiPanel grow={false}>
            <EuiFlexGroup responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiFormRow label='Date Range'>
                  <EuiDatePickerRange
                    startDateControl={<EuiDatePicker selected={start} onChange={(d: Moment) => setStart(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='Start date' showTimeSelect={false} className='whiteCalendarInput' />}
                    endDateControl={<EuiDatePicker selected={end} onChange={(d: Moment) => setEnd(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='End date' showTimeSelect={false} className='whiteCalendarInput' />}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
          <EuiSpacer />
          <EuiPanel grow={false}>
            <EuiText>
              <h4>Account Summary</h4>
              <p>A summary of all campaigns.</p>
            </EuiText>

            <EuiSpacer />

            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButton id='acctPerformanceReport' onClick={onDownloadAccountPerformanceClick} isLoading={reports.isLoadingReportAccount}>
                  View Report
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
          <EuiSpacer />
          <EuiPanel grow={false}>
            <EuiText>
              <h4>Campaign Performance</h4>
              <p>Daily analytics for selected campaigns.</p>
            </EuiText>
            <EuiSpacer />
            <EuiFlexGroup responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiFilterGroup>
                  <EuiPopover
                    ownFocus
                    button={
                      <EuiFilterButton id='filterCampaigns' iconType='arrowDown' onClick={onCampaignsButtonClick} isSelected={isCampaignsPopoverOpen} numFilters={selectedCampaigns.length} hasActiveFilters={selectedCampaigns.length > 0} numActiveFilters={selectedCampaigns.length} isLoading={reports.isLoadingCampaigns}>
                        Campaigns
                      </EuiFilterButton>
                    }
                    isOpen={isCampaignsPopoverOpen}
                    closePopover={closeCampaignsPopover}
                    panelPaddingSize='none'>
                    <div className='euiFilterSelect__items'>
                      {!hidden ? (
                        <EuiButton fullWidth style={{ margin: 3, alignContent: 'center', backgroundColor: 'white' }} onClick={uncheckAllCampaigns}>
                          Uncheck All
                        </EuiButton>
                      ) : (
                        <EuiButton fullWidth style={{ margin: 3, alignContent: 'center', backgroundColor: 'white' }} onClick={checkAllCampaigns}>
                          Check All
                        </EuiButton>
                      )}
                      {reports.campaigns.map(item => (
                        <EuiFilterSelectItem checked={selectedCampaigns.includes(item.id) ? 'on' : undefined} key={`campaigns-${item.id}`} onClick={() => toggleSelectedCampaign(item.id)}>
                          {item.name}
                        </EuiFilterSelectItem>
                      ))}
                    </div>
                  </EuiPopover>
                  <EuiPopover
                    ownFocus
                    button={
                      <EuiFilterButton id='campaignBreakDownByRange' iconType='arrowDown' onClick={() => setIsCampaignBreakDownByRangePopoverOpen(!isCampaignBreakDownByRangePopoverOpen)} isSelected={isCampaignBreakDownByRangePopoverOpen}>
                        Breakdown by {campaignBreakDownBy}
                      </EuiFilterButton>
                    }
                    isOpen={isCampaignBreakDownByRangePopoverOpen}
                    closePopover={() => setIsCampaignBreakDownByRangePopoverOpen(false)}
                    panelPaddingSize='none'>
                    <div className='euiFilterSelect__items'>
                      <EuiFilterSelectItem
                        checked={campaignBreakDownBy === IntervalUnit.Day ? 'on' : undefined}
                        key={`campaign-week`}
                        onClick={() => {
                          setCampaignBreakDownBy(IntervalUnit.Day)
                          setIsCampaignBreakDownByRangePopoverOpen(false)
                        }}>
                        Day
                      </EuiFilterSelectItem>
                      <EuiFilterSelectItem
                        checked={campaignBreakDownBy === IntervalUnit.Month ? 'on' : undefined}
                        key={`campaign-month`}
                        onClick={() => {
                          setCampaignBreakDownBy(IntervalUnit.Month)
                          setIsCampaignBreakDownByRangePopoverOpen(false)
                        }}>
                        Month
                      </EuiFilterSelectItem>
                    </div>
                  </EuiPopover>
                </EuiFilterGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiButton id='campaignPerformanceReport' onClick={onDownloadCampaignPerformanceClick} isLoading={reports.isLoadingReportCampaign} disabled={selectedCampaigns.length === 0}>
                  View Report
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
          <EuiSpacer />
          <EuiPanel grow={false}>
            <EuiText>
              <h4>Geographic Performance</h4>
              <p>Daily geographic analytics for selected campaigns.</p>
            </EuiText>

            <EuiSpacer />
            <EuiFlexGroup responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiFilterGroup>
                  <EuiPopover
                    ownFocus
                    button={
                      <EuiFilterButton id='filterCampaigns' iconType='arrowDown' onClick={onCampaignsDimensionButtonClick} isSelected={isCampaignsDimensionPopoverOpen} numFilters={selectedCampaignDimensions.length} hasActiveFilters={selectedCampaignDimensions.length > 0} numActiveFilters={selectedCampaignDimensions.length} isLoading={reports.isLoadingCampaigns}>
                        Campaigns
                      </EuiFilterButton>
                    }
                    isOpen={isCampaignsDimensionPopoverOpen}
                    closePopover={closeCampaignsDimensionPopover}
                    panelPaddingSize='none'>
                    <div className='euiFilterSelect__items'>
                      {!hidden ? (
                        <EuiButton fullWidth style={{ margin: 3, alignContent: 'center', backgroundColor: 'white' }} onClick={uncheckAllCampaignsDimension}>
                          Uncheck All
                        </EuiButton>
                      ) : (
                        <EuiButton fullWidth style={{ margin: 3, alignContent: 'center', backgroundColor: 'white' }} onClick={checkAllCampaignsDimension}>
                          Check All
                        </EuiButton>
                      )}
                      {reports.campaigns
                        .filter(c => c.type !== 'Billboard')
                        .map(item => (
                          <EuiFilterSelectItem checked={selectedCampaignDimensions.includes(item.id) ? 'on' : undefined} key={`campaignsdimension-${item.id}`} onClick={() => toggleSelectedCampaignDimension(item.id)}>
                            {item.name}
                          </EuiFilterSelectItem>
                        ))}
                    </div>
                  </EuiPopover>
                  <EuiPopover
                    ownFocus
                    button={
                      <EuiFilterButton id='filterDimensions' iconType='arrowDown' onClick={onDimensionButtonClick} isSelected={isDimensionPopoverOpen}>
                        Breakdown by {dimension}
                      </EuiFilterButton>
                    }
                    isOpen={isDimensionPopoverOpen}
                    closePopover={closeDimensionPopover}
                    panelPaddingSize='none'>
                    <div className='euiFilterSelect__items'>
                      <EuiFilterSelectItem
                        checked={dimension === 'State' ? 'on' : undefined}
                        key={`dimension-state`}
                        onClick={() => {
                          setDimension('State')
                          closeDimensionPopover()
                        }}>
                        State
                      </EuiFilterSelectItem>
                      <EuiFilterSelectItem
                        checked={dimension === 'DMA' ? 'on' : undefined}
                        key={`dimension-dma`}
                        onClick={() => {
                          setDimension('DMA')
                          closeDimensionPopover()
                        }}>
                        DMA / Market Area
                      </EuiFilterSelectItem>
                      <EuiFilterSelectItem
                        checked={dimension === 'City' ? 'on' : undefined}
                        key={`dimension-city`}
                        onClick={() => {
                          setDimension('City')
                          closeDimensionPopover()
                        }}>
                        City
                      </EuiFilterSelectItem>
                    </div>
                  </EuiPopover>
                </EuiFilterGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiButton id='campaignDimensionPerformanceReport' onClick={onDownloadCampaignDimensionPerformanceClick} isLoading={reportFlyoutType === ReportFlyoutType.CampaignDimension && reports.isLoadingReportCampaignDimension} disabled={selectedCampaigns.length === 0}>
                  View Report
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>{' '}
          <EuiSpacer />
          <EuiPanel grow={false}>
            <EuiText>
              <h4>Domain Performance</h4>
              <p>Site specific analytics for Internet campaigns.</p>
            </EuiText>

            <EuiSpacer />
            <EuiFlexGroup responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiFilterGroup>
                  <EuiPopover
                    ownFocus
                    button={
                      <EuiFilterButton id='filterCampaignsDomain' iconType='arrowDown' onClick={() => setIsCampaignsDomainPopoverOpen(!isCampaignsDomainPopoverOpen)} isSelected={isCampaignsDimensionPopoverOpen} numFilters={selectedCampaignDomain.length} hasActiveFilters={selectedCampaignDomain.length > 0} numActiveFilters={selectedCampaignDomain.length} isLoading={reports.isLoadingCampaigns}>
                        Campaigns
                      </EuiFilterButton>
                    }
                    isOpen={isCampaignsDomainPopoverOpen}
                    closePopover={() => setIsCampaignsDomainPopoverOpen(false)}
                    panelPaddingSize='none'>
                    <div className='euiFilterSelect__items'>
                      {!hidden ? (
                        <EuiButton fullWidth style={{ margin: 3, alignContent: 'center', backgroundColor: 'white' }} onClick={uncheckAllCampaignsDomain}>
                          Uncheck All
                        </EuiButton>
                      ) : (
                        <EuiButton fullWidth style={{ margin: 3, alignContent: 'center', backgroundColor: 'white' }} onClick={checkAllCampaignsDomain}>
                          Check All
                        </EuiButton>
                      )}
                      {internetCampaigns.map(item => (
                        <EuiFilterSelectItem checked={selectedCampaignDomain.includes(item.id) ? 'on' : undefined} key={`campaignsdomain-${item.id}`} onClick={() => toggleSelectedCampaignDomain(item.id)}>
                          {item.name}
                        </EuiFilterSelectItem>
                      ))}
                    </div>
                  </EuiPopover>
                  <EuiPopover
                    ownFocus
                    button={
                      <EuiFilterButton id='breakDownByRange' iconType='arrowDown' onClick={() => setIsDomainBreakDownByRangePopoverOpen(!isDomainBreakDownByRangePopoverOpen)} isSelected={isDomainBreakDownByRangePopoverOpen}>
                        Breakdown by {domainBreakDownBy}
                      </EuiFilterButton>
                    }
                    isOpen={isDomainBreakDownByRangePopoverOpen}
                    closePopover={() => setIsDomainBreakDownByRangePopoverOpen(false)}
                    panelPaddingSize='none'>
                    <div className='euiFilterSelect__items'>
                      <EuiFilterSelectItem
                        checked={dimension === 'Week' ? 'on' : undefined}
                        key={`dimension-week`}
                        onClick={() => {
                          setDomainBreakDownBy(IntervalUnit.Week)
                          setIsDomainBreakDownByRangePopoverOpen(false)
                        }}>
                        Week
                      </EuiFilterSelectItem>
                      <EuiFilterSelectItem
                        checked={dimension === 'Month' ? 'on' : undefined}
                        key={`dimension-month`}
                        onClick={() => {
                          setDomainBreakDownBy(IntervalUnit.Month)
                          setIsDomainBreakDownByRangePopoverOpen(false)
                        }}>
                        Month
                      </EuiFilterSelectItem>
                    </div>
                  </EuiPopover>
                </EuiFilterGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiButton id='campaignDomainPerformanceReport' onClick={onDownloadCampaignDomainPerformanceClick} isLoading={reportFlyoutType === ReportFlyoutType.CampaignDomain && reports.isLoadingReportCampaignDimension} disabled={selectedCampaigns.length === 0}>
                  View Report
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>{' '}
          <EuiSpacer />
          <EuiPanel grow={false}>
            <EuiText>
              <h4>Ad Performance</h4>
              <p>Ad specific analytics for selected campaigns.</p>
            </EuiText>
            <EuiSpacer />
            <EuiFlexGroup responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiFilterGroup>
                  <EuiPopover
                    ownFocus
                    button={
                      <EuiFilterButton id='filterCampaignsAds' iconType='arrowDown' onClick={onCampaignAdsButtonClick} isSelected={isCampaignAdsPopoverOpen} numFilters={selectedCampaignAds.length} hasActiveFilters={selectedCampaignAds.length > 0} numActiveFilters={selectedCampaignAds.length} isLoading={reports.isLoadingCampaigns}>
                        Campaigns
                      </EuiFilterButton>
                    }
                    isOpen={isCampaignAdsPopoverOpen}
                    closePopover={closeCampaignAdsPopover}
                    panelPaddingSize='none'>
                    <div className='euiFilterSelect__items'>
                      {!hidden ? (
                        <EuiButton fullWidth style={{ margin: 3, alignContent: 'center', backgroundColor: 'white' }} onClick={uncheckAllCampaignAds}>
                          Uncheck All
                        </EuiButton>
                      ) : (
                        <EuiButton fullWidth style={{ margin: 3, alignContent: 'center', backgroundColor: 'white' }} onClick={checkAllCampaignAds}>
                          Check All
                        </EuiButton>
                      )}
                      {reports.campaigns.map(item => (
                        <EuiFilterSelectItem checked={selectedCampaignAds.includes(item.id) ? 'on' : undefined} key={`campaignAds-${item.id}`} onClick={() => toggleSelectedCampaignAd(item.id)}>
                          {item.name}
                        </EuiFilterSelectItem>
                      ))}
                    </div>
                  </EuiPopover>
                </EuiFilterGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiButton id='adPerformanceReport' onClick={onDownloadAdPerformanceClick} isLoading={reports.isLoadingReportAd} disabled={selectedCampaignAds.length === 0}>
                  View Report
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </EuiPageBody>
        {flyoutReportView}
      </EuiPage>
    </React.Fragment>
  )
}

export default ReportsPage
