import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiForm, EuiFormRow, EuiFieldText, EuiSpacer, EuiButton, EuiLoadingContent } from '@elastic/eui'

import { ApiError, apiPutOrganization } from 'api/adcritterApi'
import { showSuccessToast, updateOrganizationState } from 'app/appSlice'
import { RootState } from 'app/rootReducer'

interface FormValues {
  name: string
  billingEmail: string
}

const OrganizationDrawer: React.FC = () => {
  const dispatch = useDispatch()
  const organization = useSelector((state: RootState) => state.organization)
  const { currentOrganization } = useSelector((state: RootState) => state.app)
  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    billingEmail: ''
  })

  useEffect(() => {
    if (currentOrganization) {
      setFormValues({
        name: currentOrganization.name,
        billingEmail: currentOrganization.billingEmail || ''
      })
    }
  }, [currentOrganization, dispatch])

  const organizationSchema = Yup.object().shape({
    name: Yup.string().max(128).required(`Please Enter Your Admin Organization Name`),
    billingEmail: Yup.string().email().lowercase().trim()
  })

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: organizationSchema,
    onSubmit: (values: FormValues) => {
      apiPutOrganization(values.name, values.billingEmail)
        .then(response => {
          dispatch(showSuccessToast(`Saved organization`))
          dispatch(updateOrganizationState(response.data))
          formik.setSubmitting(false)
          formik.setStatus(null)
        })
        .catch(response => {
          formik.setSubmitting(false)
          response.errors.forEach(function (error: ApiError) {
            formik.setFieldError(error.name, error.message)
          })
        })
    }
  })

  let content

  if (organization.isLoading) {
    content = <EuiLoadingContent lines={3} />
  } else {
    content = (
      <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
        <EuiFormRow label='Billing Organization Name' fullWidth isInvalid={formik.touched.name && !!formik.errors.name} error={formik.errors.name}>
          <EuiFieldText name='name' value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='' fullWidth isInvalid={formik.touched.name && !!formik.errors.name} />
        </EuiFormRow>

        <EuiFormRow label='Billing Email' fullWidth isInvalid={formik.touched.billingEmail && !!formik.errors.billingEmail} error={formik.errors.billingEmail}>
          <EuiFieldText name='billingEmail' value={formik.values.billingEmail} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='' fullWidth isInvalid={formik.touched.billingEmail && !!formik.errors.billingEmail} />
        </EuiFormRow>
        <EuiSpacer />

        <EuiButton id='save' fill type='submit' isLoading={formik.isSubmitting}>
          Save
        </EuiButton>
      </EuiForm>
    )
  }

  return <div>{content}</div>
}

export default OrganizationDrawer
