import React, { FC, useEffect, useState } from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiTitle } from '@elastic/eui'

import { CampaignSlot } from 'api/interfaces'
import CampaignPicker from 'features/selfServicePackages/components/CampaignPicker'

export interface SelfServicePackageDetailsSlotRowProps {
  index: number
  accountId: string | undefined
  campaignSlot: CampaignSlot
  campaignSlots: CampaignSlot[]
  updateCampaignSlot: (slot: CampaignSlot, campaignId: string | undefined) => void
  disabled?: boolean
}

const SelfServicePackageDetailsSlotRow: FC<SelfServicePackageDetailsSlotRowProps> = ({ accountId, campaignSlot, campaignSlots, index, updateCampaignSlot, disabled }) => {
  const [includedCampaignIds, setIncludedCampaignIds] = useState<string[]>([])
  const excludeCampaignIds = campaignSlots.filter(cs => !!cs.campaignId).map(cs => cs.campaignId!)

  const campaignSelected = (campaignId: string | undefined) => {
    if (campaignSlot.campaignId && !campaignId) {
      const updatedIncludedCampaigns = [...includedCampaignIds]
      updatedIncludedCampaigns.push(campaignSlot.campaignId)

      setIncludedCampaignIds(updatedIncludedCampaigns)
    } else if (!campaignSlot.campaignId && campaignId && includedCampaignIds.includes(campaignId)) {
      const updatedIncludedCampaigns = [...includedCampaignIds]
      updatedIncludedCampaigns.splice(includedCampaignIds.indexOf(campaignId), 1)

      setIncludedCampaignIds(updatedIncludedCampaigns)
    }

    updateCampaignSlot({ ...campaignSlot }, campaignId)
  }

  return (
    <EuiFlexGroup alignItems='center' gutterSize='m'>
      <EuiFlexItem grow={3}>
        <EuiFlexGroup alignItems='center'>
          <EuiFlexItem grow={false}>
            <EuiTitle size='xs'>
              <h4>Campaign {index + 1}:</h4>
            </EuiTitle>
          </EuiFlexItem>

          <EuiFlexItem grow={true}>{campaignSlot.campaignType}</EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiFlexItem grow={6}>
        <CampaignPicker accountId={accountId} campaignType={campaignSlot.campaignType} selectedCampaignId={campaignSlot.campaignId} includeCampaignIds={includedCampaignIds} excludeCampaignIds={excludeCampaignIds} campaignSelected={campaignSelected} isDisabled={disabled} fullWidth />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default SelfServicePackageDetailsSlotRow
