import { Elements, useStripe } from '@stripe/react-stripe-js'
import { Appearance, StripeError } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import React from 'react'

import { EuiLoadingSpinner } from '@elastic/eui'

import { usePreparePaymentMethodQuery } from 'api/rtkQueryApi/platform/paymentMethodsApi'
import { ACPaymentElement } from 'components/payments/ACPaymentElement'

export interface IAddPaymentMethod {
  accountId: string
  return_url?: string
  onError?: (error: StripeError) => void
  preSubmitAsync?: () => Promise<void>
  preSubmit?: () => void
}

export const AddPaymentMethod: React.FC<IAddPaymentMethod> = props => {
  const preparePaymentMethod = usePreparePaymentMethodQuery({ accountId: props.accountId })
  const [clientSecret, setClientSecret] = useState<string>()

  const stripe = useStripe()

  const appearance: Appearance = {
    theme: 'stripe',
    variables: {
      fontFamily: 'Inter',
      colorText: '#374151',
      colorDanger: '#dc2626'
    }
  }

  useEffect(() => {
    if (preparePaymentMethod.data) {
      setClientSecret(preparePaymentMethod.data.clientSecret)
    }
  }, [preparePaymentMethod.data])

  if (!clientSecret || !stripe) {
    return <EuiLoadingSpinner />
  }

  return (
    <Elements options={{ clientSecret: clientSecret, appearance, fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Inter:wght@400;500' }] }} stripe={stripe}>
      <ACPaymentElement return_url={props.return_url} onError={props.onError} preSubmit={props.preSubmit} preSubmitAsync={props.preSubmitAsync} />
    </Elements>
  )
}
