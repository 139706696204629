import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from '@elastic/eui'

import { AcImage } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import { default as GeneralSuggestedAds } from 'components/suggestedAds/InternetSuggestedAds'
import { clearSuggestions, fetchImages, fetchSuggestions } from 'components/suggestedAds/generalSuggestionSlice'
import history from 'services/HistoryService'

import { setAd, setImage } from './builderInternetVistaSlice'

const SuggestionsStep: React.FC = () => {
  const dispatch = useDispatch()
  const builder = useSelector((state: RootState) => state.builderInternetVista)
  const { currentAccount } = useSelector((state: RootState) => state.app)

  const proceedWithSuggestion = (image: AcImage, headline: string, body: string) => {
    const ad = {
      id: '',
      headline: headline,
      body: body,
      companyName: currentAccount!.name,
      callToAction: 'Learn More',
      destinationUrl: currentAccount!.website || ''
    }
    dispatch(setImage(image))
    dispatch(setAd(ad))
    history.push('/build/internet/vista/create')
  }

  useEffect(() => {
    if (currentAccount && builder.businessType) {
      dispatch(clearSuggestions())
      dispatch(fetchSuggestions(currentAccount.id, builder.businessType.id, currentAccount.name, null, true))
      dispatch(fetchImages(currentAccount.businessTypeId))
    }
  }, [dispatch, currentAccount, builder.businessType])

  return (
    <React.Fragment>
      <Helmet>
        <title>Suggested Ads</title>
      </Helmet>
      <EuiFlexGroup responsive={false}>
        <EuiFlexItem>
          <EuiTitle size='s'>
            <h3>Suggested Ads</h3>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      {currentAccount && <GeneralSuggestedAds businessTypeId={currentAccount.businessTypeId} proceed={proceedWithSuggestion} companyName={currentAccount.name} />}
    </React.Fragment>
  )
}

export default SuggestionsStep
