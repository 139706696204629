import { BaseQueryFn } from '@reduxjs/toolkit/query'
import { AxiosError, AxiosRequestConfig } from 'axios'

import { publicAxios, securedAxios, securedContextAxios } from 'api/adcritterApi'

interface BaseQueryFnProps {
  url: string
  method: AxiosRequestConfig['method']
  isContext: boolean
  isAgency?: boolean
  data?: AxiosRequestConfig['data']
  params?: AxiosRequestConfig['params']
  isPublic?: boolean
}

export interface BaseQuery {
  accountId: string
  isAgency: boolean
}

export const axiosBaseQuery =
  (): BaseQueryFn<BaseQueryFnProps, unknown, unknown, {}, {}> =>
  async ({ url, method, isContext, isAgency, data, params, isPublic }) => {
    try {
      let axiosInstance
      if (isPublic) {
        axiosInstance = publicAxios()
      } else if (isContext) {
        axiosInstance = securedContextAxios()
      } else {
        axiosInstance = securedAxios(isAgency)
      }

      const result = await axiosInstance.request({ url, method, data, params })

      return {
        data: result.data
      }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message
        }
      }
    }
  }
