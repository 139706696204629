import moment, { Moment } from 'moment'
import React, { useEffect } from 'react'

import { EuiFieldSearch, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiPanel, EuiSpacer, EuiStat, EuiSwitch } from '@elastic/eui'

import { CampaignSpendReport } from 'api/interfaces/InvoiceDocument'
import { useGetCampaignSpendReportQuery, useGetInvoiceDocQuery } from 'api/rtkQueryApi/platform/dashboardApi'
import { AcMonthPicker, GetMonthByNumber, GetMonthNumber, Month } from 'components/Basic/AcMonthPicker'
import { BudgetGrid } from 'features/reports/BudgetGrid'
import { formatCurrency } from 'utils/Formatters'
import { useDebounceEffect } from 'utils/useDebounceEffect'

enum GridItemType {
  Campaign = 'Campaign',
  Invoice = 'Invoice',
  Match = 'Match'
}

interface GridItem {
  id: string
  name: string
  allocated: number
  start?: Moment
  end?: Moment
  activated?: Moment
  preMatchBudget?: number
  finalCost?: number
  status?: string
  type: GridItemType
}

export const ForecastReport: React.FC = () => {
  const [searchValue, setSearchValue] = React.useState<string>('')
  const [selectedMonth, setSelectedMonth] = React.useState<Month>(GetMonthByNumber(moment().month()))
  const [selectedYear, setSelectedYear] = React.useState<number>(moment().year())
  const [showInvoices, setShowInvoices] = React.useState<boolean>(true)

  const [reportCampaigns, setReportCampaigns] = React.useState<CampaignSpendReport[]>([])
  const invoiceDocQuery = useGetInvoiceDocQuery()
  const campaignSpendQuery = useGetCampaignSpendReportQuery()
  const [campaignGridItems, setCampaignGridItems] = React.useState<GridItem[]>([])
  const [invoiceGridItems, setInvoiceGridItems] = React.useState<GridItem[]>([])
  const [unfilteredGridItems, setUnfilteredGridItems] = React.useState<GridItem[]>([])
  const [filteredGridItems, setFilteredGridItems] = React.useState<GridItem[]>([])

  const [totalAdBudget, setTotalAdBudget] = React.useState<number>(0)
  const [totalCost, setTotalCost] = React.useState<number>(0)
  const [availableNow, setAvailableNow] = React.useState<number>(0)
  const [allocated, setAllocated] = React.useState<number>(0)
  const minInfusionDate = moment('2023-08-31')

  useEffect(() => {
    setAvailableNow(totalAdBudget - totalCost)
  }, [totalCost, totalAdBudget])

  useEffect(() => {
    if (reportCampaigns.length) {
      const newGridItems = reportCampaigns.map(c => {
        return {
          id: c.id,
          name: c.campaignName,
          allocated: c.allocated,
          start: moment(c.start),
          end: moment(c.end),
          activated: c.activated ? moment(c.activated) : undefined,
          finalCost: c.finalCost > c.allocated ? c.allocated : c.finalCost,
          status: c.campaignStatus,
          type: GridItemType.Campaign
        }
      })
      setCampaignGridItems(newGridItems)
    }
  }, [reportCampaigns])

  useEffect(() => {
    if (campaignSpendQuery.data) {
      const now = moment()
      const campaignsToUse = campaignSpendQuery.data.filter(c => moment(c.start).isSameOrAfter(minInfusionDate))
      const totalCost = campaignsToUse.reduce((acc, c) => acc + (moment(c.end).isAfter(moment(now).add(1, 'days').startOf('day')) ? c.allocated : c.finalCost > c.allocated ? c.allocated : c.finalCost), 0)
      setTotalCost(totalCost)
      setReportCampaigns(campaignsToUse)
    }
  }, [campaignSpendQuery.data])

  useEffect(() => {
    if (invoiceDocQuery.data) {
      const totalInvoiced = invoiceDocQuery.data.invoices.reduce((acc, invoice) => acc + invoice.amount, invoiceDocQuery.data.initialBudget)
      setTotalAdBudget(totalInvoiced)
      const newGridItems = [
        ...unfilteredGridItems.filter(x => x.type !== GridItemType.Invoice && x.type !== GridItemType.Match),
        ...invoiceDocQuery.data.invoices.map(i => {
          return {
            id: i.id,
            name: i.name,
            allocated: i.amount,
            activated: moment(i.invoiceDate),
            type: i.isMatching ? GridItemType.Match : GridItemType.Invoice
          }
        })
      ]
      newGridItems.push({
        id: 'initialBudget',
        name: 'Initial Budget',
        allocated: invoiceDocQuery.data.initialBudget,
        activated: moment(invoiceDocQuery.data.startDate),
        type: GridItemType.Invoice
      })

      setInvoiceGridItems(newGridItems)
    }
  }, [invoiceDocQuery])

  useEffect(() => {
    setUnfilteredGridItems([...invoiceGridItems, ...campaignGridItems])
  }, [invoiceGridItems, campaignGridItems])

  useDebounceEffect(
    300,
    () => {
      let filteredItems = [...unfilteredGridItems]
      filteredItems = filteredItems.filter(i => i.name.toLowerCase().includes(searchValue.toLowerCase()) && i.activated)
      if (!showInvoices) {
        filteredItems = filteredItems.filter(i => i.type !== GridItemType.Invoice && i.type !== GridItemType.Match)
      }
      filteredItems = filteredItems.filter(i => i.activated!.month() === GetMonthNumber(selectedMonth) && i.activated!.year() === selectedYear)
      const orderByActivated = filteredItems.sort((a, b) => a.activated!.diff(b.start))
      setFilteredGridItems(orderByActivated)
    },
    [unfilteredGridItems, selectedMonth, selectedYear, showInvoices, searchValue]
  )

  useEffect(() => {
    setAllocated(filteredGridItems.filter(i => i.type === GridItemType.Campaign).reduce((acc, c) => acc + c.allocated, 0))
  }, [filteredGridItems])

  const onMonthChange = (month: Month, year: number) => {
    setSelectedMonth(month)
    setSelectedYear(year)
  }

  return (
    <React.Fragment>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiPanel hasShadow={false} hasBorder={true} style={{ width: 240 }}>
            {invoiceDocQuery.isLoading || campaignSpendQuery.isLoading ? <EuiStat title={''} description={'Loading...'} titleSize={'m'} /> : <EuiStat title={formatCurrency(availableNow)} description='Available Now' titleSize={'m'} />}
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiPanel hasShadow={false} hasBorder={true} style={{ width: 240 }}>
            {invoiceDocQuery.isLoading || campaignSpendQuery.isLoading ? <EuiStat title={''} description={'Loading...'} titleSize={'m'} /> : <EuiStat title={formatCurrency(allocated)} description={`Allocated (${selectedMonth} ${selectedYear})`} titleSize={'m'} />}
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiHorizontalRule />
      <EuiFlexGroup gutterSize={'s'}>
        <EuiFlexItem grow={false}>
          <EuiFieldSearch style={{ width: 240 }} placeholder={'Search'} value={searchValue} onChange={e => setSearchValue(e.target.value)} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <AcMonthPicker selectedMonth={selectedMonth} selectedYear={selectedYear} onChange={onMonthChange} style={{ paddingTop: 4 }} />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ paddingTop: 10 }}>
          <EuiSwitch label={'Show Invoices'} checked={showInvoices} onChange={() => setShowInvoices(!showInvoices)} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <BudgetGrid gridItems={filteredGridItems} isLoading={invoiceDocQuery.isLoading || campaignSpendQuery.isLoading} />
    </React.Fragment>
  )
}
