import React from 'react'

import { EuiListGroup, EuiListGroupItem } from '@elastic/eui'

import { Zip } from 'api/interfaces'

interface ZipTargeterProps {
  zips: Array<Zip>

  onZipRemoved(zip: Zip): void
}

export const ZipTargeter = ({ zips, onZipRemoved }: ZipTargeterProps) => {
  return (
    <React.Fragment>
      {zips && zips.length > 0 && (
        <EuiListGroup bordered={true} maxWidth={false} size='s'>
          {zips.map(w => (
            <EuiListGroupItem
              size='s'
              key={w.id.toString()}
              label={w.name}
              extraAction={{
                onClick: () => {
                  onZipRemoved(w)
                },
                iconType: 'cross',
                iconSize: 's',
                'aria-label': 'Remove',
                alwaysShow: true
              }}
            />
          ))}
        </EuiListGroup>
      )}
    </React.Fragment>
  )
}
