import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AcImage, Ad, BusinessType, DisplayAdDetails } from 'api/interfaces'
import history from 'services/HistoryService'
import localStoreManagerService from 'services/LocalStoreManagerService'

export enum CreateAdStep {
  Start = 10,
  Suggest = 12,
  Suggestions = 15,
  Create = 30,
  Upload = 31,
  Custom = 33
}

export enum CreateAdStyle {
  Suggested = 'Suggested',
  Build = 'Build',
  Custom = 'Custom',
  Upload = 'Upload'
}

export interface Suggest {
  city: string
  companyName: string
}

interface CreateAdState {
  createAdStep: CreateAdStep
  createAdStyle: CreateAdStyle
  businessType: BusinessType | null
  suggest: Suggest | null
  ad: Ad | null
  image: AcImage | null
  isCopy: boolean
  displayAd: DisplayAdDetails | null
}

const initialState: CreateAdState = {
  createAdStep: localStoreManagerService.getData('createAd.createAdStep') || CreateAdStep.Start,
  createAdStyle: localStoreManagerService.getData('createAd.createAdStyle') || CreateAdStyle.Build,
  businessType: localStoreManagerService.getData('createAd.businessType'),
  suggest: localStoreManagerService.getData('createAd.suggest'),
  ad: localStoreManagerService.getData('createAd.ad'),
  image: localStoreManagerService.getData('createAd.image'),
  isCopy: localStoreManagerService.getData('createAd.isCopy') || false,
  displayAd: localStoreManagerService.getData('createAd.displayAd')
}

function reset(state: CreateAdState) {
  state.createAdStep = CreateAdStep.Start
  state.createAdStyle = CreateAdStyle.Build
  state.businessType = null
  state.suggest = null
  state.ad = null
  state.image = null
  state.isCopy = false
  state.displayAd = null

  localStoreManagerService.deleteData('createAd.suggest')
  localStoreManagerService.deleteData('createAd.businessType')
  localStoreManagerService.deleteData('createAd.ad')
  localStoreManagerService.deleteData('createAd.image')
  localStoreManagerService.deleteData('createAd.displayAd')
}

const createAds = createSlice({
  name: 'createAds',
  initialState,
  reducers: {
    startCreateAd(state: CreateAdState) {
      reset(state)
      history.push(`/campaigns/internet/public/start`)
    },
    resetCreateAd(state: CreateAdState) {
      reset(state)
    },
    setCreateAdStep(state: CreateAdState, { payload }: PayloadAction<CreateAdStep>) {
      state.createAdStep = payload
      localStoreManagerService.saveSessionData('createAd.createAdStep', payload)
    },
    setCreateAdStyle(state: CreateAdState, { payload }: PayloadAction<CreateAdStyle>) {
      state.createAdStyle = payload
      localStoreManagerService.saveSessionData('createAd.createAdStyle', payload)
    },
    setSuggestedChoices(state: CreateAdState, { payload }: PayloadAction<Suggest>) {
      state.suggest = payload
      localStoreManagerService.saveSessionData('createAd.suggest', payload)
    },
    setImage(state: CreateAdState, { payload }: PayloadAction<AcImage>) {
      state.image = payload
      localStoreManagerService.saveSessionData('createAd.image', payload)
    },
    setBusinessType(state: CreateAdState, { payload }: PayloadAction<BusinessType>) {
      state.businessType = payload
      localStoreManagerService.saveSessionData('createAd.businessType', payload)
    },
    setAd(state: CreateAdState, { payload }: PayloadAction<Ad>) {
      state.ad = payload
      localStoreManagerService.saveSessionData('createAd.ad', payload)
    },
    setDisplayAd(state: CreateAdState, { payload }: PayloadAction<DisplayAdDetails | null>) {
      state.displayAd = payload
      localStoreManagerService.saveSessionData('createAd.displayAd', payload)
    },
    setAdCompanyName(state: CreateAdState, { payload }: PayloadAction<string>) {
      if (state.ad) {
        state.ad!.companyName = payload
        localStoreManagerService.saveSessionData('createAd.ad.companyName', payload)
      }
    },
    setIsCopy(state: CreateAdState) {
      state.isCopy = true
      localStoreManagerService.saveSessionData('createAd.isCopy', true)
    }
  }
})

export const { startCreateAd, resetCreateAd, setCreateAdStep, setCreateAdStyle, setSuggestedChoices, setBusinessType, setAd, setDisplayAd, setAdCompanyName, setImage, setIsCopy } = createAds.actions

export default createAds.reducer
