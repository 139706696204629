import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import { useFormik } from 'formik'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiText, EuiTitle, EuiSpacer, EuiToolTip, EuiIcon, EuiForm, EuiFilePicker, EuiFormRow, EuiFieldText, EuiCallOut, EuiLoadingContent, EuiSelect, EuiTextArea, EuiShowFor, EuiHideFor } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'

import { apiGetInternetBuilderUpload } from 'api/adcritterApi'
import { DisplayAdDetails } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import { setDisplayAd } from './builderInternetRetailSlice'

interface AcceptedSize {
  width: number
  height: number
}

const UploadStep: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const builder = useSelector((state: RootState) => state.builderInternetRetail)
  const [containerClient, setContainerClient] = useState<ContainerClient | null>(null)
  const [size, setSize] = useState('300x250')
  const [hasCorrectFile, setHasCorrectFile] = useState(false)
  const [hasFile, setHasFile] = useState(false)
  const [displayAdImg, setDisplayAdImg] = useState<string>('')
  const [htmlTag, setHtmlTag] = useState<string>('')
  const [height, setHeight] = useState<number>(0)
  const [width, setWidth] = useState<number>(0)
  const [fileSize, setFileSize] = useState<number>(0)
  const [fileType, setFileType] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  const filePickerRemoval = useRef<EuiFilePicker>(null)
  const [fileName, setFileName] = useState<string>('')
  const [initialValues] = useState<DisplayAdDetails>({
    uploadedUrl: '',
    htmlTag: '',
    destinationUrl: builder.displayAd?.destinationUrl ? builder.displayAd?.destinationUrl : '',
    width: 0,
    height: 0,
    displayAdName: 'Display Ad 1',
    mimeType: ''
  })
  const whitelabel = useWhiteLabel()

  useEffect(() => {
    if (builder.displayAd) {
      setDisplayAdImg(displayAdImg ? displayAdImg : builder?.displayAd.uploadedUrl)
      setHtmlTag(htmlTag ? htmlTag : builder?.displayAd.htmlTag)
      setWidth(width ? width : builder?.displayAd.width!)
      setHeight(height ? height : builder?.displayAd.height!)
      setFileType(fileType ? fileType : builder?.displayAd.mimeType!.split('/')[1].toUpperCase())
      setFileName(fileName ? fileName : builder?.displayAd.displayAdName!)
      setHasFile(true)
    }
  }, [builder.displayAd, width, height, fileType, fileName, displayAdImg])

  const acceptedSizes = useMemo<AcceptedSize[]>(
    () => [
      { width: 160, height: 600 },
      { width: 300, height: 50 },
      { width: 300, height: 250 },
      { width: 300, height: 600 },
      { width: 320, height: 50 },
      { width: 336, height: 280 },
      { width: 728, height: 90 },
      { width: 970, height: 90 },
      { width: 970, height: 250 }
    ],
    []
  )

  const acceptedFileSize = 300

  const goToStart = () => {
    history.push('/build/internet/public/start')
  }

  const displayAdSchema = Yup.object().shape({
    uploadedUrl: Yup.string(),
    htmlTag: Yup.string(),
    destinationUrl: Yup.string()
      .matches(/^(https:\/\/)/, 'Please enter a valid website URL including https:// (ex. https://www.domain.com)')
      .url('Please enter a valid website URL including https:// (ex. https://www.domain.com)')
      .required('Please enter the website URL including https:// (ex. https://www.domain.com)')
      .max(2048)
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: displayAdSchema,
    onSubmit: (values: DisplayAdDetails) => {
      dispatch(
        setDisplayAd({
          uploadedUrl: displayAdImg,
          htmlTag: htmlTag,
          destinationUrl: values.destinationUrl,
          width: width,
          height: height,
          displayAdName: `${htmlTag === '' ? 'Uploaded' : 'HTML'} Ad 1 (${width} x ${height})`,
          mimeType: htmlTag === '' ? `image/${fileType.toLowerCase()}` : 'text/html'
        })
      )
      history.push('/build/internet/public/target')
    }
  })

  useEffect(() => {
    if (currentAccount) {
      apiGetInternetBuilderUpload(currentAccount.id).then(data => {
        const storageClient = new BlobServiceClient(data.data)
        const containerClient = storageClient.getContainerClient(currentAccount.id)
        setContainerClient(containerClient)
      })
    }
  }, [currentAccount])

  function getMeta(url: any) {
    const img = new Image()
    img.src = url
    img.onload = function () {
      setHeight(img.height)
      setWidth(img.width)
      delay(function () {
        setHasFile(true)
      }, 750)
    }
  }

  const delay = (func: any, num: number) => {
    setTimeout(func, num)
  }

  useEffect(() => {
    fileType.toUpperCase()
    if ((fileType === 'PNG' || fileType === 'JPEG' || fileType === 'JPG' || fileType === 'GIF') && fileSize < acceptedFileSize && acceptedSizes.some(s => s.width === width && s.height === height)) {
      setHasCorrectFile(true)
    } else {
      dispatch(setDisplayAd(null))
      setHasCorrectFile(false)
    }
  }, [fileType, fileSize, height, width, acceptedSizes, dispatch])

  useEffect(() => {
    if (size) {
      const s = size.split('x')
      setWidth(Number(s[0]))
      setHeight(Number(s[1]))
    }
  }, [size])

  const onFileChange = (files: FileList | null) => {
    if (files && files.length === 1) {
      setIsUploading(true)
      const blockBlobClient = containerClient!.getBlockBlobClient(uuidv4() + '.' + files[0].name.split('.').pop())!
      const url = blockBlobClient!.url.split('?')[0]
      let splitArrayLength = url.split('.').length
      let fileType = url.split('.')[splitArrayLength - 1].toUpperCase()
      setFileType(fileType)
      setFileName(files[0].name)
      
      // Convert to MB
      let size = Math.round(files[0].size * 0.000001)
      setFileSize(size)
      blockBlobClient!
        .uploadData(files[0], {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20
        })
        .then(() => {
          setIsUploading(false)
          setDisplayAdImg(url)
          getMeta(url)
        })
        .then(() => {
          if (!(fileType === 'PNG' || fileType === 'JPEG' || fileType === 'JPG' || fileType === 'GIF' || fileType === 'ZIP')) {
            setHasFile(true)
            setDisplayAdImg('')
          }
          if (size > acceptedFileSize) {
            setHasFile(true)
            setDisplayAdImg('')
          }
        })
    }
    if (files && files.length < 1) {
      setHeight(0)
      setWidth(0)
      setFileType('')
      setFileSize(0)
      setHasCorrectFile(false)
      setDisplayAdImg('')
      setHasFile(false)
      dispatch(setDisplayAd(null))
    }
  }

  if (!containerClient) {
    return <EuiLoadingContent />
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Upload Display Ad</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Upload Your Ad</h3>
      </EuiTitle>
      <EuiSpacer size='m' />
      <EuiFlexGroup>
        <EuiFlexItem style={{ marginLeft: 25 }}>
          <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
            <EuiFormRow label='Uploaded file' fullWidth isInvalid={formik.touched.uploadedUrl && !!formik.errors.uploadedUrl} error={formik.errors.uploadedUrl}>
              <EuiFilePicker ref={filePickerRemoval} id='upload' fullWidth display='large' onChange={onFileChange} isLoading={isUploading} />
            </EuiFormRow>
            <EuiSpacer size='m' />

            <EuiCallOut title='Uploaded Image Requirements: ' color='primary' iconType='importAction' hidden={htmlTag != ''}>
              <EuiText size='s'>
                <p>
                  <strong>Acceptable image dimensions:</strong> (Dimensions must be exact).
                  <br />
                  {acceptedSizes.map(s => `${s.width}x${s.height}`).join(', ')} <br />
                  <strong>Max file size:</strong> {acceptedFileSize}MB or less <br />
                  <strong>Accepted file formats:</strong> PNG, JPEG, JPG or GIF.
                </p>
              </EuiText>
            </EuiCallOut>
            <EuiSpacer size='m' />

            {hasFile && !hasCorrectFile && (
              <EuiCallOut title='Your file has not been uploaded because it had these issues: ' iconType='document' color='warning'>
                {fileSize > acceptedFileSize && (
                  <p>
                    {fileName} is too big (it's {fileSize}MB). Please upload a file that is <strong>{acceptedFileSize}MB or less</strong>.
                  </p>
                )}
                {!(fileType === 'PNG' || fileType === 'JPEG' || fileType === 'JPG' || fileType === 'GIF') && (
                  <p>
                    {fileName} is not an accepted file type (it's a .{fileType}). Please upload a file that is a <strong>PNG, JPEG, JPG or GIF</strong>.
                  </p>
                )}
                {!acceptedSizes.some(s => s.width === width && s.height === height) && (
                  <section>
                    {fileName} isn't one of the accepted image dimensions (it's {width} x {height}). Please upload a file that is one of the accepted dimensions
                  </section>
                )}
              </EuiCallOut>
            )}

            {whitelabel?.isAgencies() && displayAdImg == '' && (
              <React.Fragment>
                <EuiFormRow label='or HTML Tag' fullWidth isInvalid={formik.touched.htmlTag && !!formik.errors.htmlTag} error={formik.errors.htmlTag}>
                  <EuiTextArea fullWidth onChange={e => setHtmlTag(e.target.value)} value={htmlTag} />
                </EuiFormRow>
                <EuiSpacer size='m' />
              </React.Fragment>
            )}

            <EuiFormRow label='Size' fullWidth>
              <EuiSelect
                name='callToAction'
                options={acceptedSizes.map(
                  x =>
                    ({
                      value: `${x.width}x${x.height}`,
                      label: `${x.width}x${x.height}`,
                      text: `${x.width}x${x.height}`
                    } as EuiSelectOption)
                )}
                value={size}
                onChange={v => setSize(v.target.value)}
                fullWidth
              />
            </EuiFormRow>
            <EuiFormRow
              label='Destination URL'
              fullWidth
              isInvalid={formik.touched.destinationUrl && !!formik.errors.destinationUrl}
              error={formik.errors.destinationUrl}
              labelAppend={
                <EuiText style={{ display: 'flex', flexDirection: 'row' }}>
                  <EuiToolTip position='bottom' content='This should be the page that you want users to land on when they click on this ad.'>
                    <EuiIcon type='iInCircle' color='success' style={{ marginLeft: 5, marginBottom: 8 }} />
                  </EuiToolTip>
                  <EuiText size='xs' color={formik.values.displayAdName!.length <= 50 ? 'default' : 'warning'}>
                    ({(formik.values.destinationUrl || '').length}/2048)
                  </EuiText>
                </EuiText>
              }>
              <EuiFieldText name='destinationUrl' defaultValue={formik.values.destinationUrl} placeholder='https://www.domain.com' onChange={formik.handleChange} fullWidth isInvalid={formik.touched.destinationUrl && !!formik.errors.destinationUrl} />
            </EuiFormRow>
          </EuiForm>
        </EuiFlexItem>

        <EuiFlexItem style={{ paddingLeft: 50, paddingRight: 50 }}>
          <EuiText size='s'>
            <h4>Preview Your Display Ad</h4>
          </EuiText>
          <EuiSpacer size='s' />
          <EuiFlexItem>{displayAdImg !== '' && hasCorrectFile ? <img src={displayAdImg} alt={'uploaded display ad'} style={{ width: width, height: height, backgroundColor: 'lightgrey', borderRadius: 8 }} /> : <div style={{ width: 300, height: 250, backgroundColor: 'lightgrey', borderRadius: 8, textAlign: 'center', color: 'white', fontSize: 50, paddingTop: 90 }}> {htmlTag !== '' ? 'no preview' : '300x250'}</div>}</EuiFlexItem>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size='xl' />

      <EuiShowFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='continue' fill type='submit' isDisabled={!hasCorrectFile && htmlTag === ''} isLoading={formik.isSubmitting} onClick={formik.submitForm}>
              Continue to Targeting
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={goToStart}>
              Back
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={goToStart} iconType='arrowLeft' iconSide='left'>
              Back
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='continue' fill type='submit' isDisabled={!hasCorrectFile && htmlTag === ''} iconType='arrowRight' iconSide='right' isLoading={formik.isSubmitting} onClick={formik.submitForm}>
              Continue to Targeting
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiHideFor>
    </React.Fragment>
  )
}

export default UploadStep
