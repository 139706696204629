import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { EuiButton, EuiCheckbox, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiImage, EuiPanel, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { CohortStatus, SalesAgencyInformation } from 'api/interfaces/SalesAgencyDetails'
import { useGetAgencyForOrganizationQuery, useUpdateAgencyInformationMutation, useUpdateOfferMutation } from 'api/rtkQueryApi/platform/agenciesApi'
import { RootState } from 'app/rootReducer'
import { AgencyConfirmationDetails } from 'features/offers/AgencyConfirmationDetails'
import history from 'services/HistoryService'

export const AgencyOffer: React.FC = () => {
  const userState = useSelector((state: RootState) => state.app)
  const [updateAgencyInformation] = useUpdateAgencyInformationMutation()
  const agencyQuery = useGetAgencyForOrganizationQuery()
  const [updateOffer, updateOfferRequest] = useUpdateOfferMutation()
  const [agree, setAgree] = React.useState(false)
  const [agencyInformation, setAgencyInformation] = React.useState<SalesAgencyInformation>({
    businessName: '',
    website: '',
    businessPhone: '',
    timezone: '',
    address: '',
    cityName: '',
    state: '',
    zipCode: '',
    contactName: '',
    contactEmail: '',
    contactPhone: ''
  })

  useEffect(() => {
    if (agencyQuery.data && (!agencyQuery.data.cohortId || agencyQuery.data.cohortStatus !== CohortStatus.Offered)) {
      history.push('/dashboard')
    }
  }, [agencyQuery.data])

  useEffect(() => {
    if (userState.currentOrganization?.id && userState.currentUser && userState.currentAccount) {
      setAgencyInformation({
        businessName: userState.currentOrganization.name,
        website: userState.currentAccount.website,
        businessPhone: userState.currentOrganization.phoneNumber,
        timezone: userState.currentAccount.timezone,
        address: userState.currentAccount.streetAddress,
        cityName: userState.currentAccount.city,
        state: userState.currentAccount.state,
        zipCode: userState.currentAccount.zip,
        contactName: userState.currentAccount.name,
        contactEmail: userState.currentUser.email,
        contactPhone: ''
      })
    }
  }, [userState.currentOrganization])

  const onGetStartedClicked = () => {
    if (agencyQuery.data?.id) {
      const agencyId = agencyQuery.data.id
      updateAgencyInformation({ agencyId: agencyId, agencyInformation: agencyInformation }).then(() => {
        updateOffer({ agencyId: agencyId, accepted: true }).then(() => {
          history.push('/offers/accepted')
        })
      })
    }
  }

  const onAgencyDetailsChanged = (agencyDetails: SalesAgencyInformation) => {
    setAgencyInformation(agencyDetails)
  }

  if (!userState.currentOrganization?.id || agencyQuery.isLoading || !agencyQuery.data?.cohortId) {
    return <React.Fragment />
  }

  const canSubmit = agree && agencyInformation.businessName && agencyInformation.website && agencyInformation.contactPhone && agencyInformation.timezone && agencyInformation.address && agencyInformation.cityName && agencyInformation.state && agencyInformation.zipCode && agencyInformation.contactName && agencyInformation.contactEmail && agencyInformation.contactPhone

  return (
    <React.Fragment>
      <EuiTitle>
        <h2>You are invited!</h2>
      </EuiTitle>
      <EuiSpacer />
      <EuiFlexGroup gutterSize={'s'}>
        <EuiFlexItem grow={false} style={{ paddingTop: 4, paddingLeft: 6 }}>
          <EuiCheckbox
            id='agreeCheckBox2'
            label=''
            checked={agree}
            onChange={() => {
              setAgree(!agree)
            }}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText size={'s'}>
            <b>Yes</b>, Eric, I want to participate in the group of agencies you will be working with personally between now and 2024.
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFormRow fullWidth>
        <EuiText size={'s'}>
          <b>* This is a hand-selected group of 50 agencies</b>
        </EuiText>
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <EuiText size={'s'}>
          <b>* There is no cost to participate.</b>
          <br />
          (in fact, your $149 platform fee will be waived until January 2025!)
        </EuiText>
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <EuiText size={'s'}>
          <b>* Eric will dedicate a half-day per week to this group.</b> <br />
          (Helping everyone grow their agencies and on shaping the agency features we are building into our software)
        </EuiText>
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <EuiText size={'s'}>
          <b>* We just got a bunch of Free CTV incentives you can give to your clients</b>
          <br />
          (This is MAJOR!)
        </EuiText>
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <EuiText size={'s'}>
          <b>* Agencies being invited are planning to allocate a minimum of $5k/mo to digital/CTV/Billboards.</b>
          <br />
          (This means you know you will be in a group of peers)
        </EuiText>
      </EuiFormRow>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiImage
            size='s'
            alt=''
            src='/eric.png'
            caption={
              <EuiText size={'xs'}>
                Eric Busby
                <br />
                <i>Co-Founder & President</i>
              </EuiText>
            }
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiTitle size={'xxs'}>
            <h2>Lets get started</h2>
          </EuiTitle>
          <EuiText size={'s'}>All you need to do is confirm your information below, click "Save" and then allocate some client budget to some campaigns.</EuiText>
          <EuiSpacer />
          <EuiText size={'s'}>
            <b>The first 50 to activate will be the ones in the club.</b>
            <br /> (We will name it... I have already had some funny suggestions.)
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiPanel hasShadow={false} hasBorder={true}>
        <AgencyConfirmationDetails agencyInformation={agencyInformation} onAgencyInformationChanged={onAgencyDetailsChanged} />
      </EuiPanel>
      <EuiSpacer />
      <EuiButton color={'success'} disabled={!canSubmit} onClick={onGetStartedClicked} isLoading={updateOfferRequest.isLoading}>
        Save
      </EuiButton>
    </React.Fragment>
  )
}
