import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BuilderCampaignType, CreativeBuilderStep, CreativeBuilderType, CreativeCreateStep } from 'workflows/CampaignCreateWorkflow'

import { RootState } from 'app/rootReducer'
import { setCreativeBuilderStep, setCreativeCreateStep } from 'features/builders/CampaignCreateSlice'
import { AdBuilderInternetPage } from 'features/builders/createCampaign/creativeBuilder/AdBuilderInternetPage'
import { AdBuilderTvPage } from 'features/builders/createCampaign/creativeBuilder/AdBuilderTvPage'
import { AdSuggestionsPage } from 'features/builders/createCampaign/creativeBuilder/AdSuggestionsPage'
import { AdUploadBillboardPage } from 'features/builders/createCampaign/creativeBuilder/AdUploadBillboardPage'
import { AdUploadInternetPage } from 'features/builders/createCampaign/creativeBuilder/AdUploadInternetPage'
import { AdUploadTvPage } from 'features/builders/createCampaign/creativeBuilder/AdUploadTvPage'
import { BusinessInfoPage } from 'features/builders/createCampaign/creativeBuilder/BusinessInfoPage'
import { CustomAdPage } from 'features/builders/createCampaign/creativeBuilder/CustomAdPage'
import history from 'services/HistoryService'

export interface ICampaignBuilderWorkflowStep {
  onBack?: () => void
}

export const CreativeBuilderWorkflowManager: React.FC<ICampaignBuilderWorkflowStep> = props => {
  const { isShopifyUser, isVendastaUser } = useSelector((state: RootState) => state.app)
  const dispatch = useDispatch()
  const {
    createWorkflow: { creativeBuilderWorkflow },
    campaignType
  } = useSelector((state: RootState) => state.campaignCreateWorkflow)

  return (
    <React.Fragment>
      {creativeBuilderWorkflow.creativeBuilderType === CreativeBuilderType.PreMade && (
        <React.Fragment>
          {creativeBuilderWorkflow.creativeBuilderStep === CreativeBuilderStep.BusinessInfo && <BusinessInfoPage onBack={() => dispatch(setCreativeCreateStep(CreativeCreateStep.CreativeBuilderModeSelect))} />}
          {creativeBuilderWorkflow.creativeBuilderStep === CreativeBuilderStep.Suggestions && (
            <AdSuggestionsPage
              onBack={() => {
                if (isShopifyUser) {
                  history.push('/build/internet/public/start')
                } else {
                  props.onBack?.()
                }
              }}
            />
          )}
          {creativeBuilderWorkflow.creativeBuilderStep === CreativeBuilderStep.AdBuilder && (
            <React.Fragment>
              {(campaignType === BuilderCampaignType.Native || campaignType === BuilderCampaignType.Display) && <AdBuilderInternetPage onBack={() => dispatch(setCreativeBuilderStep(CreativeBuilderStep.Suggestions))} />}
              {campaignType === BuilderCampaignType.TV && <AdBuilderTvPage onBack={() => dispatch(setCreativeBuilderStep(CreativeBuilderStep.Suggestions))} />}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {creativeBuilderWorkflow.creativeBuilderType === CreativeBuilderType.AdBuilder && (
        <React.Fragment>
          {(campaignType === BuilderCampaignType.Native || campaignType === BuilderCampaignType.Display) && <AdBuilderInternetPage onBack={() => dispatch(setCreativeCreateStep(CreativeCreateStep.CreativeBuilderModeSelect))} />}
          {campaignType === BuilderCampaignType.TV && <AdBuilderTvPage onBack={() => dispatch(setCreativeCreateStep(CreativeCreateStep.CreativeBuilderModeSelect))} />}
        </React.Fragment>
      )}
      {creativeBuilderWorkflow.creativeBuilderType === CreativeBuilderType.Upload && (
        <React.Fragment>
          {(campaignType === BuilderCampaignType.Native || campaignType === BuilderCampaignType.Display) && <AdUploadInternetPage onBack={() => dispatch(setCreativeCreateStep(CreativeCreateStep.CreativeBuilderModeSelect))} />}
          {campaignType === BuilderCampaignType.TV && <AdUploadTvPage onBack={() => dispatch(setCreativeCreateStep(CreativeCreateStep.CreativeBuilderModeSelect))} />}
          {campaignType === BuilderCampaignType.Billboard && (
            <AdUploadBillboardPage
              onBack={() => {
                if (isVendastaUser) {
                  history.push('/build')
                } else {
                  dispatch(setCreativeCreateStep(CreativeCreateStep.CreativeBuilderModeSelect))
                }
              }}
            />
          )}
        </React.Fragment>
      )}
      {creativeBuilderWorkflow.creativeBuilderType === CreativeBuilderType.Custom && <React.Fragment>{creativeBuilderWorkflow.creativeBuilderStep === CreativeBuilderStep.Custom && <CustomAdPage onBack={() => dispatch(setCreativeCreateStep(CreativeCreateStep.CreativeBuilderModeSelect))} />}</React.Fragment>}
    </React.Fragment>
  )
}
