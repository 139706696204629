import React, { FC } from 'react'

import { EuiButton } from '@elastic/eui'

export interface ReportButtonGroupProps {
  buttonColor: string
  buttonTextColor: string
  exportToPdf: () => void
}

const ReportButtonGroup: FC<ReportButtonGroupProps> = ({ buttonColor, buttonTextColor, exportToPdf }) => {
  return (
    <EuiButton size='s' style={{ backgroundColor: buttonColor, color: buttonTextColor }} iconType='arrowRight' iconSide='right' onClick={exportToPdf}>
      Export to PDF
    </EuiButton>
  )
}

export default ReportButtonGroup
