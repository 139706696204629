import React, { useEffect } from 'react'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { BuilderCampaignType } from 'workflows/CampaignCreateWorkflow'

import { EuiSpacer } from '@elastic/eui'

import { PermissionType } from 'api/interfaces'
import { useGetOrganizationQuery } from 'api/rtkQueryApi/platform/organizationsApi'
import config from 'app/config'
import { RootState } from 'app/rootReducer'
import { AcCardSelector, IAcCard, LayoutType } from 'components/Collections/AcCardSelector'
import { BackNextFooter } from 'components/navigation/BackNextFooter'
import { ICampaignBuilderWorkflowStep } from 'features/builders/createCampaign/creativeBuilder/CreativeBuilderWorkflowManager'
import { useCampaignBuilderActions } from 'features/builders/createCampaign/useCampaignBuilderActions'

export const TargetModeSelectPage: React.FC<ICampaignBuilderWorkflowStep> = props => {
  const { targetAudienceSelected, targetCustomAudienceSelected, targetSmartSelected, targetLocationSelected, targetRetargetingSelected, skipTargeting } = useCampaignBuilderActions()
  const {
    campaignType,
    campaignTargeting: { smartTargeting }
  } = useSelector((state: RootState) => state.campaignCreateWorkflow)

  const [builderModeCards, setBuilderModeCards] = React.useState<IAcCard[]>([])
  const [cardsPerRow, setCardsPerRow] = React.useState<number>(2)

  const { currentAccount } = useSelector((state: RootState) => state.app)

  const organizationQuery = useGetOrganizationQuery()
  const canUploadAudience = organizationQuery.data?.permissions?.some(p => p.permissionType === PermissionType.UploadAudience)

  useEffect(() => {
    const cards: IAcCard[] = []
    if (campaignType === BuilderCampaignType.Native || campaignType === BuilderCampaignType.Display) {
      setCardsPerRow(3)
      cards.push({
        id: 'smartTargeter',
        title: 'Smart Targeter',
        description: 'Use Intelligent ad targeting for your specific business type.',
        onClick: () => {
          const info = { ...smartTargeting }
          if (info.streetAddress === '' && currentAccount?.streetAddress) {
            info.streetAddress = currentAccount.streetAddress
          }
          if (info.city === '' && currentAccount?.city) {
            info.city = currentAccount.city
          }
          if (info.state === '' && currentAccount?.state) {
            info.state = currentAccount.state
          }
          if (info.zip === '' && currentAccount?.zip) {
            info.zip = currentAccount.zip
          }
          targetSmartSelected(info)
        },
        iconType: 'crosshairs',
        footer: 'Provide a few basic details to enjoy a professionally targeted campaign.'
      })
      if (canUploadAudience) {
        cards.push({
          id: 'customAudience',
          title: 'Custom Audience',
          description: 'Target your audience.',
          onClick: () => {
            targetCustomAudienceSelected()
          },
          iconType: 'importAction',
          footer: 'Do you have your own audience list?\nUpload it here.'
        })
      }
      cards.push({
        id: 'locationTargeting',
        title: 'Location Targeting',
        description: 'Target geographic areas.',
        onClick: () => {
          targetLocationSelected()
        },
        iconType: 'pinFilled',
        footer: 'Geo-target street addresses, zip codes, entire cities, or a whole state.'
      })
      cards.push({
        id: 'audienceTargeting',
        title: 'Audience Targeting',
        description: 'Target by specific criteria.',
        onClick: () => {
          targetAudienceSelected()
        },
        iconType: 'users',
        footer: 'Target an audience based on interests, behaviors, vocations, brand affinities, or demographics.'
      })
      cards.push({
        id: 'retargeting',
        title: 'Retargeting',
        description: 'Target your audience.',
        onClick: () => {
          targetRetargetingSelected()
        },
        iconType: 'magnet',
        footer: 'Target people who have seen your campaigns or have been to your website.'
      })
    } else if (campaignType === BuilderCampaignType.TV) {
      setCardsPerRow(2)
    } else if (campaignType === BuilderCampaignType.Billboard) {
      setCardsPerRow(2)
    }

    setBuilderModeCards(cards)
  }, [campaignType])

  useEffect(() => {
    if (config.gtag.ANALYTICSID) {
      ReactGA.event('select_item', { items: [{ item_name: 'Internet Campaign' }], send_to: config.gtag.ANALYTICSID })
    }

    ReactPixel.track('ViewContent', { content_type: 'Internet Campaign' })

    if (config.linkedin.PARTNER_ID && config.linkedin.VIEW_CONTENT_CONVERSION_ID) {
      window.lintrk('track', { conversion_id: config.linkedin.VIEW_CONTENT_CONVERSION_ID })
    }
  }, [])

  const onBackClick = () => {
    props.onBack?.()
  }

  const onSkipClick = () => {
    skipTargeting()
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Choose Targeting</title>
      </Helmet>
      <AcCardSelector cards={builderModeCards} cardsPerRow={cardsPerRow} layoutType={LayoutType.GridReverse} />
      <EuiSpacer />
      <BackNextFooter onBack={onBackClick} nextText={'Skip For Now'} onNext={onSkipClick} isNextHidden={true} />
    </React.Fragment>
  )
}
