import React, { ReactNode, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiSpacer, EuiTitle } from '@elastic/eui'

import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import StartStep from './10_StartStep'
import UploadStep from './20_UploadStep'
import { CreateAdStep, setCreateAdStep } from './createAdTvRetailSlice'

interface CreateAdPageParams {
  step: string
}

const Page: React.FC = () => {
  const dispatch = useDispatch()
  const { step } = useParams<CreateAdPageParams>()
  const [content, setContent] = useState<ReactNode>(<StartStep />)
  const whiteLabel = useWhiteLabel()

  useEffect(() => {
    switch (step) {
      default:
        setContent(<StartStep />)
        dispatch(setCreateAdStep(CreateAdStep.Start))
        break
      case 'upload':
        setContent(<UploadStep />)
        dispatch(setCreateAdStep(CreateAdStep.Upload))
        break
    }
  }, [dispatch, step, whiteLabel])

  return (
    <React.Fragment>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiSpacer size='xs' />
          <EuiTitle>
            <h1>Create Ad</h1>
          </EuiTitle>
          <EuiSpacer size='xs' />
        </EuiPageHeaderSection>
      </EuiPageHeader>
      <EuiPanel grow={false} className='eui-xScroll'>
        {content}
      </EuiPanel>
    </React.Fragment>
  )
}

export default Page
