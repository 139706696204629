import moment from 'moment/moment'
import React, { FC, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiBadge, EuiBasicTable, EuiButtonEmpty, EuiFieldSearch, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHorizontalRule, EuiLink, EuiSpacer, EuiSwitch, EuiText, EuiTitle, useResizeObserver } from '@elastic/eui'
import { Criteria, EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'
import { FlexGridColumns } from '@elastic/eui/src/components/flex/flex_grid'

import { Account, BundleActiveStatus, BundleStatus, SelfServicePackage, SelfServicePackageStatus } from 'api/interfaces'
import { useGetAccountsQuery } from 'api/rtkQueryApi/platform/accountsApi'
import { useAssignSelfServicePackageMutation, useGetNonReservedSelfServicePackagesQuery, useGetReservedSelfServicePackagesQuery } from 'api/rtkQueryApi/platform/bundlesApi'
import { RootState } from 'app/rootReducer'
import AvailableSelfServicePackageItemSmall from 'features/selfServicePackages/components/AvailableSelfServicePackageItemSmall'
import SelfServicePackageDetailsFlyout from 'features/selfServicePackages/flyouts/SelfServicePackageDetailsFlyout'
import { AccountPickerModal } from 'features/selfServicePackages/modals/AccountPickerModal'
import { InsertSpaces } from 'utils/EnumToFriendly'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

export interface SelfServicePackagesPageProps {
  onViewAvailablePackagesClick: () => void
}

const SelfServicePackagesPage: FC<SelfServicePackagesPageProps> = ({ onViewAvailablePackagesClick }) => {
  const { currentOrganization } = useSelector((state: RootState) => state.app)
  const whiteLabel = useWhiteLabel()
  const accountsQuery = useGetAccountsQuery({})
  const { isFetching: isSelfServicePackagesFetching, data: selfServicePackages } = useGetNonReservedSelfServicePackagesQuery(undefined, { skip: !currentOrganization || !whiteLabel?.isAgencies() })
  const [filteredPackages, setFilteredPackages] = useState<SelfServicePackage[]>([])
  const [selectedPackage, setSelectedPackage] = useState<SelfServicePackage>()
  const [showViewPackage, setShowViewPackage] = useState<boolean>(false)
  const [pageSize, setPageSize] = useState<number>(10)
  const [currentPage, setPageIndex] = useState<number>(0)
  const [displayedPackages, setDisplayedPackages] = useState<SelfServicePackage[]>([])
  const [showEnded, setShowEnded] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  const [showAssignModal, setShowAssignModal] = useState<boolean>(false)
  const [assignBundle, assignBundleQuery] = useAssignSelfServicePackageMutation()

  const resizeRef = useRef<HTMLDivElement>(null)
  const { width } = useResizeObserver(resizeRef.current)

  const { data: availablePackages } = useGetReservedSelfServicePackagesQuery(undefined, {
    skip: !currentOrganization || !whiteLabel?.isAgencies(),
    pollingInterval: 300000
  })

  React.useEffect(() => {
    if (selfServicePackages) {
      let filtered = selfServicePackages.filter(b => showEnded || b.bundleStatus !== BundleStatus.Ended)
      if (accountsQuery.data) {
        const filteredAccounts = accountsQuery.data.filter(a => a.name.toLowerCase().includes(searchText.toLowerCase()))
        filtered = filtered.filter(b => filteredAccounts.some(a => a.id === b.accountId) || b.name.toLowerCase().includes(searchText.toLowerCase()) || b.salesTitle?.toLowerCase().includes(searchText.toLowerCase()))
      } else {
        filtered = filtered.filter(b => b.name.toLowerCase().includes(searchText.toLowerCase()) || b.salesTitle?.toLowerCase().includes(searchText.toLowerCase()))
      }
      filtered = filtered.sort((a, b) => (!a.created ? -1 : !b.created ? -1 : a.created > b.created ? -1 : 1))

      setFilteredPackages(filtered)
      const start = currentPage * pageSize
      setDisplayedPackages(filtered.slice(start, start + pageSize))
    }
  }, [selfServicePackages, accountsQuery.data, showEnded, pageSize, currentPage, searchText])

  const openViewPackage = (bundle: SelfServicePackage) => {
    setShowViewPackage(true)
    setSelectedPackage(bundle)
  }

  const closePackageDetails = () => {
    setShowViewPackage(false)
    setSelectedPackage(undefined)
  }

  const onModalClose = () => {
    setShowAssignModal(false)
  }

  const onAccountConfirm = (account: Account) => {
    if (!selectedPackage) {
      return
    }

    assignBundle({ bundleId: selectedPackage?.id, accountId: account.id }).then(() => {
      setShowAssignModal(false)
    })
  }

  const columns: Array<EuiBasicTableColumn<SelfServicePackage>> = [
    {
      name: 'Package Name',
      render: (bundle: SelfServicePackage) => (
        <EuiLink color='primary' onClick={() => openViewPackage(bundle)}>
          {bundle.name}
        </EuiLink>
      ),
      sortable: (bundle: SelfServicePackage) => bundle.name
    },
    {
      name: 'Client',
      render: (bundle: SelfServicePackage) => accountsQuery.data?.find(a => a.id === bundle.accountId)?.name ?? '',
      sortable: (bundle: SelfServicePackage) => accountsQuery.data?.find(a => a.id === bundle.accountId)?.name ?? ''
    },
    {
      name: 'Description',
      render: (bundle: SelfServicePackage) => bundle.salesTitle,
      sortable: (bundle: SelfServicePackage) => bundle.salesTitle
    },
    {
      name: 'Available Until',
      sortable: (bundle: SelfServicePackage) => moment(bundle.expirationDate).unix(),
      render: (bundle: SelfServicePackage) => {
        if (!bundle.expirationDate) {
          return <EuiText size={'s'}>N/A</EuiText>
        }

        return <EuiText size={'s'}>{moment(bundle.expirationDate).format('MM/DD/yyyy')}</EuiText>
      }
    },
    {
      name: 'Status',
      sortable: (selfServicePackage: SelfServicePackage) => selfServicePackage.selfServicePackageStatus,
      render: (selfServicePackage: SelfServicePackage) => <EuiBadge color={selfServicePackage.selfServicePackageStatus === SelfServicePackageStatus.Expired || selfServicePackage.selfServicePackageStatus === SelfServicePackageStatus.PaymentFailed ? 'danger' : 'default'}>{InsertSpaces(selfServicePackage.selfServicePackageStatus)}</EuiBadge>
    },
    {
      name: ' ',
      render: (selfServicePackage: SelfServicePackage) => (
        <EuiFlexGroup gutterSize='xs'>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty color={'success'} onClick={() => openViewPackage(selfServicePackage)}>
              {selfServicePackage.bundleActiveStatus === BundleActiveStatus.Active ? 'View' : 'Setup'}
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }
  ]

  const onTableChange = ({ page }: Criteria<SelfServicePackage>) => {
    if (page) {
      const { index: pageIndex, size: pageSize } = page
      setPageIndex(pageIndex)
      setPageSize(pageSize)
    }
  }

  const pagination = {
    pageSizeOptions: [10, 50, 100],
    pageSize: pageSize,
    pageIndex: currentPage,
    totalItemCount: filteredPackages.length
  }

  let columnsToShow: FlexGridColumns = 1

  if (width >= 750 && width < 1440) {
    columnsToShow = 2
  } else if (width >= 1440) {
    columnsToShow = 3
  }

  return (
    <div ref={resizeRef}>
      {availablePackages && availablePackages.length > 0 && (
        <>
          <EuiFlexGroup direction='column'>
            <EuiFlexItem>
              <EuiFlexGroup alignItems='center'>
                <EuiFlexItem grow={false}>
                  <EuiTitle>
                    <h2>Available Incentive Packages</h2>
                  </EuiTitle>
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                  <EuiText>
                    (
                    <EuiLink color='success' onClick={onViewAvailablePackagesClick}>
                      <u>view all {availablePackages.length}</u>
                    </EuiLink>
                    )
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiFlexGrid columns={columnsToShow} responsive={false}>
                {availablePackages &&
                  availablePackages.slice(0, columnsToShow).map(selfServicePackage => (
                    <EuiFlexItem key={selfServicePackage.id} grow={true}>
                      <AvailableSelfServicePackageItemSmall selfServicePackage={selfServicePackage} onViewClick={() => openViewPackage(selfServicePackage)} panelProps={{ hasShadow: true }} wrapInPanel />
                    </EuiFlexItem>
                  ))}
              </EuiFlexGrid>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiHorizontalRule margin='xxl' />
        </>
      )}

      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle>
            <h2>Assigned Incentive Packages</h2>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size={'m'} />
      <EuiFormRow fullWidth>
        <EuiFlexGroup gutterSize={'m'}>
          <EuiFlexItem grow={false}>
            <EuiFieldSearch placeholder={'Search'} aria-label={'Package Search'} value={searchText} onChange={e => setSearchText(e.target.value)} />
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ paddingTop: 6 }}>
            <EuiSwitch checked={showEnded} onChange={() => setShowEnded(!showEnded)} label={'Show Ended'} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <EuiBasicTable loading={isSelfServicePackagesFetching} items={displayedPackages} columns={columns} pagination={pagination} onChange={onTableChange} />
      </EuiFormRow>

      {showViewPackage && selectedPackage && <SelfServicePackageDetailsFlyout selfServicePackage={selectedPackage} closeFlyout={closePackageDetails} />}
      {selectedPackage && showAssignModal && <AccountPickerModal selfServicePackage={selectedPackage} onModalClose={onModalClose} onAccountConfirm={onAccountConfirm} isLoading={assignBundleQuery.isLoading} />}
    </div>
  )
}

export default SelfServicePackagesPage
