import React, { FC } from 'react'

import { InsightsConfiguration } from 'api/interfaces'
import { Insights } from 'api/interfaces/Insights'
import { useGetCampaignInsightsConfigurationQuery, useGetCampaignInsightsSaturationQuery } from 'api/rtkQueryApi/platform/campaignInsightsApi'
import InsightsSaturationChart from 'components/insights/Charts/InsightsSaturationChart'
import InsightsPanel from 'components/insights/Components/InsightsPanel'
import { saturationButtonColor, saturationButtonTextColor, saturationDescription, saturationRightPanelColor, saturationRightPanelLink, saturationRightPanelLinkText } from 'components/insights/InsightsConstants'

export interface InsightsSaturationProps {
  isLoading: boolean
  saturation?: Insights
  config?: InsightsConfiguration
}

const InsightsSaturation: FC<InsightsSaturationProps> = ({ isLoading, saturation, config }) => {
  const saturationTarget = config?.saturationTargetPercent ?? 80
  let lastMonth = ''
  let lastMonthValue = 0
  for (const item in saturation?.byMonth) {
    let curr = saturation?.byMonth[item]?.value ?? 0

    if (curr !== -1) {
      lastMonthValue = saturation?.byMonth[item]?.value ?? 0
    }

    if (curr !== -1 || lastMonth.length <= 0) {
      lastMonth = item
    }
  }

  return (
    <InsightsPanel isLoading={isLoading} title='Saturation' description={saturationDescription.replaceAll('{saturation_target}', saturationTarget.toString())} buttonColor={saturationButtonColor} buttonTextColor={saturationButtonTextColor} rightPanelTitle={`${lastMonthValue}%`} rightPanelDescription={lastMonth} rightPanelLink={saturationRightPanelLink} rightPanelLinkText={saturationRightPanelLinkText} rightPanelColor={saturationRightPanelColor}>
      <InsightsSaturationChart isLoading={isLoading} saturationTarget={config?.saturationTargetPercent ?? 80} saturation={saturation} />
    </InsightsPanel>
  )
}

export default InsightsSaturation
