import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiCard, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from '@elastic/eui'

import { RootState } from 'app/rootReducer'
import { fetchCampaigns } from 'features/campaigns/campaigns/campaignsSlice'
import history from 'services/HistoryService'

const StartStep: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)

  useEffect(() => {
    if (currentAccount) {
      dispatch(fetchCampaigns(currentAccount.id))
    }
  }, [dispatch, currentAccount])

  const gotoUpload = () => {
    history.push('/campaigns/tv/public/upload')
  }

  const gotoStart = () => {
    history.push('/campaigns/tv/public/start')
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Start</title>
      </Helmet>
      <EuiFlexGroup>
        <EuiFlexItem grow={true} />
        <EuiFlexItem grow={false} style={{ width: 280 }}>
          <EuiCard id='uploadCommercialsTile' icon={<EuiIcon size='xxl' type='play' color='success' />} title='Upload a Commercial' description='Upload a TV commercial.' onClick={gotoUpload} footer={<EuiText size='s'>Do you have your own TV commercial? Upload it here.</EuiText>} />
        </EuiFlexItem>
        <EuiFlexItem grow={true} />
      </EuiFlexGroup>
      <EuiFlexGroup responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiButton id='back' fill type='button' color='text' onClick={gotoStart} iconType='arrowLeft' iconSide='left'>
            Back
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default StartStep
