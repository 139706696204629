import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { EuiButton, EuiCallOut, EuiCheckbox, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHideFor, EuiLink, EuiListGroup, EuiListGroupItem, EuiLoadingSpinner, EuiShowFor, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { Retargeting } from 'api/interfaces/CampaignTargeting'
import { useGetRetargetingCampaignsQuery } from 'api/rtkQueryApi/platform/campaignsApi'
import { RootState } from 'app/rootReducer'
import { ICampaignBuilderWorkflowStep } from 'features/builders/createCampaign/creativeBuilder/CreativeBuilderWorkflowManager'
import { useCampaignBuilderActions } from 'features/builders/createCampaign/useCampaignBuilderActions'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

export const TargetRetargetingPage: React.FC<ICampaignBuilderWorkflowStep> = props => {
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const { targetRetargetingComplete } = useCampaignBuilderActions()
  const whiteLabel = useWhiteLabel()
  const getCampaignsQuery = useGetRetargetingCampaignsQuery({ accountId: currentAccount?.id ?? '', isAgency: whiteLabel?.isAgencies() })
  const [retargetingInfo, setRetargetingInfo] = useState<Retargeting>({
    retargetCampaignIds: [],
    retargetWebsiteUrls: [],
    entireSite: false,
    specificPages: false
  })
  const [websiteUrl, setWebsiteUrl] = useState('')

  const {
    campaignTargeting: {
      audienceTargeting: { retargeting }
    }
  } = useSelector((state: RootState) => state.campaignCreateWorkflow)

  useEffect(() => {
    if (retargeting) {
      setRetargetingInfo(retargeting)
    }
  }, [retargeting])

  const canContinue = retargetingInfo.retargetCampaignIds.length > 0 || retargetingInfo.entireSite || retargetingInfo.retargetWebsiteUrls.length > 0

  const onBackClick = () => {
    props.onBack?.()
  }

  const onNextClick = () => {
    targetRetargetingComplete(retargetingInfo)
  }

  const onCampaignSelected = (optionId: string) => {
    if (retargetingInfo.retargetCampaignIds.includes(optionId)) {
      setRetargetingInfo({ ...retargetingInfo, retargetCampaignIds: retargetingInfo.retargetCampaignIds.filter(id => id !== optionId) })
    } else {
      setRetargetingInfo({ ...retargetingInfo, retargetCampaignIds: [...retargetingInfo.retargetCampaignIds, optionId] })
    }
  }

  const onUrlAdded = () => {
    if (websiteUrlIsValid) {
      setRetargetingInfo({ ...retargetingInfo, retargetWebsiteUrls: [...retargetingInfo.retargetWebsiteUrls, websiteUrl] })
      setWebsiteUrl('')
    }
  }

  const onUrlRemoved = (url: string) => {
    setRetargetingInfo({ ...retargetingInfo, retargetWebsiteUrls: retargetingInfo.retargetWebsiteUrls.filter(u => u !== url) })
  }

  const websiteUrlIsValid = websiteUrl && websiteUrl.length > 0 && websiteUrl.startsWith('https://')

  return (
    <React.Fragment>
      <Helmet>
        <title>Retargeting</title>
      </Helmet>
      <EuiTitle size='s'>
        <h3>Retarget my campaigns</h3>
      </EuiTitle>
      <EuiText size='s'>Select the campaigns you want to retarget.</EuiText>
      {getCampaignsQuery.data && getCampaignsQuery.data.length === 0 && (
        <EuiCallOut title='You have no campaigns to retarget' color={'primary'}>
          <p>Retargeting requires a previously active TV or Internet campaign to have generated an audience.</p>
        </EuiCallOut>
      )}
      <EuiSpacer />
      <EuiFormRow>
        <div style={{ maxHeight: 600, overflow: 'auto' }}>{getCampaignsQuery.data ? getCampaignsQuery.data.map(campaign => <EuiCheckbox id={campaign.id} label={`${campaign.name} ($${campaign.budget} ${campaign.type})`} checked={retargetingInfo.retargetCampaignIds.some(id => id === campaign.id)} onChange={() => onCampaignSelected(campaign.id)} />) : getCampaignsQuery.isLoading ? <EuiLoadingSpinner /> : <React.Fragment />}</div>
      </EuiFormRow>
      <EuiSpacer />
      <EuiTitle size='s'>
        <h3>Retarget my website</h3>
      </EuiTitle>
      <EuiText size='s'>Select how you want to retarget your website</EuiText>
      {!currentAccount?.hasVisits && (
        <EuiCallOut title='The Tracking Pixel must be installed onto the site you wish to retarget' color={'primary'}>
          <p>
            To install the tracking pixel and enable website retargeting, check out the{' '}
            <EuiLink target='_blank' href={'/explicitTracking'} color={'accent'}>
              Tracking Tab
            </EuiLink>
          </p>
        </EuiCallOut>
      )}
      <EuiSpacer />
      <EuiFormRow label={'Target an audience generated from your website'}>
        <React.Fragment>
          <EuiCheckbox
            id='website'
            label='Entire Site'
            checked={retargetingInfo.entireSite}
            onChange={() => {
              setRetargetingInfo({ ...retargetingInfo, entireSite: !retargetingInfo.entireSite, specificPages: false })
            }}
            disabled={!currentAccount?.hasVisits}
          />
          <EuiCheckbox
            id='specificPages'
            label='Specific Pages'
            checked={retargetingInfo.specificPages}
            onChange={() => {
              setRetargetingInfo({ ...retargetingInfo, specificPages: !retargetingInfo.specificPages, entireSite: false })
            }}
            disabled={!currentAccount?.hasVisits}
          />
        </React.Fragment>
      </EuiFormRow>
      {retargetingInfo.specificPages && (
        <React.Fragment>
          <EuiFormRow label={"Add the url's of the pages you would like to target"} helpText={!!websiteUrl && websiteUrl.length > 0 && !websiteUrlIsValid ? 'Url must begin with https://' : undefined}>
            <EuiFieldText
              placeholder='https://'
              value={websiteUrl}
              onChange={e => {
                setWebsiteUrl(e.target.value)
              }}
              isInvalid={!!websiteUrl && websiteUrl.length > 0 && !websiteUrlIsValid}
              append={
                <EuiButton size='s' disabled={!websiteUrlIsValid} onClick={onUrlAdded}>
                  Add
                </EuiButton>
              }
            />
          </EuiFormRow>
          <EuiListGroup>
            {retargetingInfo.retargetWebsiteUrls.map(url => (
              <EuiListGroupItem
                key={url}
                label={url}
                extraAction={{
                  color: 'text',
                  onClick: () => onUrlRemoved(url),
                  iconType: 'cross',
                  iconSize: 's',
                  'aria-label': 'Favorite link2',
                  alwaysShow: true
                }}
              />
            ))}
          </EuiListGroup>
        </React.Fragment>
      )}
      <EuiSpacer />
      {!canContinue && (
        <React.Fragment>
          <EuiText color='warning' size={'xs'}>
            Please select a retargeting option.
          </EuiText>
          <EuiSpacer size={'xs'} />
        </React.Fragment>
      )}
      <EuiShowFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='finalize' fill type='submit' isLoading={getCampaignsQuery.isLoading} onClick={onNextClick} isDisabled={!canContinue}>
              Continue
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={onBackClick}>
              Back
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='back' fill type='button' color='text' onClick={onBackClick} iconType='arrowLeft' iconSide='left'>
              Back
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton id='finalize' fill type='submit' isLoading={getCampaignsQuery.isLoading} isDisabled={!canContinue} iconType='arrowRight' iconSide='right' onClick={onNextClick}>
              Continue
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiHideFor>
    </React.Fragment>
  )
}
