import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { apiGetAd } from 'api/adcritterApi'
import { Creative } from 'api/interfaces'
import { AppThunk } from 'app/store'

interface AdState {
  isLoadingAd: boolean
  ad: Creative | null
}

const initialState: AdState = {
  ad: null,
  isLoadingAd: false
}

const ad = createSlice({
  name: 'ad',
  initialState,
  reducers: {
    startLoadingAd(state: AdState) {
      state.isLoadingAd = true
    },
    finishedLoadingAd(state: AdState, { payload }: PayloadAction<Creative>) {
      state.isLoadingAd = false
      state.ad = payload
    }
  }
})

export const { startLoadingAd, finishedLoadingAd } = ad.actions

export default ad.reducer

export const fetchAd =
  (accountId: string, adId: string): AppThunk =>
  async dispatch => {
    dispatch(startLoadingAd())
    apiGetAd(accountId, adId).then(response => {
      dispatch(finishedLoadingAd(response.data))
    })
  }
