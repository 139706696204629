import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { EuiAutoSizer, EuiButtonEmpty, EuiFlexGrid, EuiFlexItem, EuiFormRow, EuiSpacer, EuiTitle } from '@elastic/eui'

import { SelfServicePackage } from 'api/interfaces'
import { useGetReservedSelfServicePackagesQuery } from 'api/rtkQueryApi/platform/bundlesApi'
import { RootState } from 'app/rootReducer'
import AvailableSelfServicePackageItemLarge from 'features/selfServicePackages/components/AvailableSelfServicePackageItemLarge'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

export interface SelfServicePackagesAvailablePageProps {
  onToPackagesClick: () => void
}

const SelfServicePackagesAvailablePage: FC<SelfServicePackagesAvailablePageProps> = ({ onToPackagesClick }) => {
  const { currentOrganization } = useSelector((state: RootState) => state.app)
  const whiteLabel = useWhiteLabel()
  const availablePackagesQuery = useGetReservedSelfServicePackagesQuery(undefined, { skip: !currentOrganization || !whiteLabel?.isAgencies() })
  const [filteredPackages, setFilteredPackages] = React.useState<SelfServicePackage[]>([])

  React.useEffect(() => {
    if (availablePackagesQuery.data) {
      setFilteredPackages(availablePackagesQuery.data)
    }
  }, [availablePackagesQuery.data])

  return (
    <React.Fragment>
      <EuiFormRow>
        <EuiButtonEmpty onClick={onToPackagesClick} iconType={'arrowLeft'} color={'primary'}>
          Packages
        </EuiButtonEmpty>
      </EuiFormRow>
      <EuiSpacer />
      <EuiTitle>
        <h2>Packages Recently Reserved</h2>
      </EuiTitle>

      <EuiSpacer size={'m'} />

      <EuiAutoSizer>
        {({ height, width }: any) => (
          <EuiFlexGrid columns={width >= 1440 ? 3 : width >= 750 ? 2 : 1} style={{ height: height, width: width }} responsive={false}>
            {filteredPackages.map(bundle => (
              <EuiFlexItem key={bundle.id} grow={true}>
                <AvailableSelfServicePackageItemLarge selfServicePackage={bundle} onAssigned={onToPackagesClick} wrapInPanel />
              </EuiFlexItem>
            ))}
          </EuiFlexGrid>
        )}
      </EuiAutoSizer>

      <EuiSpacer />
    </React.Fragment>
  )
}

export default SelfServicePackagesAvailablePage
