import moment from 'moment'
import React, { useEffect } from 'react'

import { EuiFieldSearch, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiPanel, EuiSpacer, EuiStat } from '@elastic/eui'

import { CampaignSpendReport } from 'api/interfaces/InvoiceDocument'
import { useGetCampaignSpendReportQuery, useGetInvoiceDocQuery } from 'api/rtkQueryApi/platform/dashboardApi'
import { AcMonthPicker, GetMonthByNumber, GetMonthNumber, Month } from 'components/Basic/AcMonthPicker'
import { BudgetGrid, GridItem, GridItemType } from 'features/reports/BudgetGrid'
import { formatCurrency } from 'utils/Formatters'
import { useDebounceEffect } from 'utils/useDebounceEffect'

export const EndOfMonthReport: React.FC = () => {
  const [searchValue, setSearchValue] = React.useState<string>('')
  const [selectedMonth, setSelectedMonth] = React.useState<Month>(GetMonthByNumber(moment().month()))
  const [selectedYear, setSelectedYear] = React.useState<number>(moment().year())

  const [reportCampaigns, setReportCampaigns] = React.useState<CampaignSpendReport[]>([])
  const invoiceDocQuery = useGetInvoiceDocQuery()
  const campaignSpendQuery = useGetCampaignSpendReportQuery()
  const [campaignGridItems, setCampaignGridItems] = React.useState<GridItem[]>([])
  const [invoiceGridItems, setInvoiceGridItems] = React.useState<GridItem[]>([])
  const [unfilteredGridItems, setUnfilteredGridItems] = React.useState<GridItem[]>([])
  const [filteredGridItems, setFilteredGridItems] = React.useState<GridItem[]>([])
  const [finalCost, setFinalCost] = React.useState<number>(0)
  const minInfusionDate = moment('2023-08-31')

  useEffect(() => {
    if (reportCampaigns.length) {
      const newGridItems = reportCampaigns.map(c => {
        return {
          id: c.id,
          name: c.campaignName,
          allocated: c.allocated,
          start: moment(c.start),
          end: moment(c.end),
          activated: c.activated ? moment(c.activated) : undefined,
          finalCost: c.finalCost > c.allocated ? c.allocated : c.finalCost,
          status: c.campaignStatus,
          type: GridItemType.Campaign
        }
      })
      setCampaignGridItems(newGridItems)
    }
  }, [reportCampaigns])

  useEffect(() => {
    if (campaignSpendQuery.data) {
      const campaignsToUse = campaignSpendQuery.data.filter(c => moment(c.start).isSameOrAfter(minInfusionDate))
      setReportCampaigns(campaignsToUse)
    }
  }, [campaignSpendQuery.data])

  useEffect(() => {
    if (invoiceDocQuery.data) {
      const newGridItems = [
        ...unfilteredGridItems.filter(x => x.type !== GridItemType.Invoice && x.type !== GridItemType.Match),
        ...invoiceDocQuery.data.invoices.map(i => {
          return {
            id: i.id,
            name: i.name,
            allocated: i.amount,
            start: moment(i.invoiceDate),
            type: i.isMatching ? GridItemType.Match : GridItemType.Invoice
          }
        })
      ]
      newGridItems.push({
        id: 'initialBudget',
        name: 'Initial Budget',
        allocated: invoiceDocQuery.data.initialBudget,
        start: moment(invoiceDocQuery.data.startDate),
        type: GridItemType.Invoice
      })

      setInvoiceGridItems(newGridItems)
    }
  }, [invoiceDocQuery])

  useEffect(() => {
    setUnfilteredGridItems([...invoiceGridItems, ...campaignGridItems])
  }, [invoiceGridItems, campaignGridItems])

  useDebounceEffect(
    300,
    () => {
      let filteredItems = [...unfilteredGridItems]
      filteredItems = filteredItems.filter(i => i.name.toLowerCase().includes(searchValue.toLowerCase()) && i.end)
      filteredItems = filteredItems.filter(i => i.type !== GridItemType.Invoice && i.type !== GridItemType.Match)
      filteredItems = filteredItems.filter(i => i.end?.month() === GetMonthNumber(selectedMonth) && i.end!.year() === selectedYear && i.end < moment())
      const orderByEnd = filteredItems.sort((a, b) => a.end!.diff(b.end))
      setFilteredGridItems(orderByEnd)
    },
    [unfilteredGridItems, selectedMonth, selectedYear, searchValue]
  )

  useEffect(() => {
    setFinalCost(filteredGridItems.filter(i => i.type === GridItemType.Campaign).reduce((acc, c) => acc + (c.finalCost ?? 0), 0))
  }, [filteredGridItems])

  const onMonthChange = (month: Month, year: number) => {
    setSelectedMonth(month)
    setSelectedYear(year)
  }

  return (
    <React.Fragment>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiPanel hasShadow={false} hasBorder={true} style={{ width: 240 }}>
            {invoiceDocQuery.isLoading || campaignSpendQuery.isLoading ? <EuiStat title={''} description={'Loading...'} titleSize={'m'} /> : <EuiStat title={formatCurrency(finalCost)} description={`Final Cost (${selectedMonth} ${selectedYear})`} titleSize={'m'} />}
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiHorizontalRule />
      <EuiFlexGroup gutterSize={'s'}>
        <EuiFlexItem grow={false}>
          <EuiFieldSearch style={{ width: 240 }} placeholder={'Search'} value={searchValue} onChange={e => setSearchValue(e.target.value)} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <AcMonthPicker selectedMonth={selectedMonth} selectedYear={selectedYear} onChange={onMonthChange} style={{ paddingTop: 4 }} maxDate={moment().startOf('month').add(-1, 'day')} minDate={moment(new Date(2024, 3))} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <BudgetGrid gridItems={filteredGridItems} isLoading={invoiceDocQuery.isLoading || campaignSpendQuery.isLoading} defaultSortField={'end'} />
    </React.Fragment>
  )
}
