import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { apiDeleteAccountPaymentMethods, apiGetAccountPaymentMethods, apiMakeDefaultAccountPaymentMethods, apiSetupAccountPaymentMethods, apiGetAccountInvoices, apiGetAccountCreditNotes } from 'api/adcritterApi'
import { State, Timezone, Invoice, PaymentMethod, CreditNote } from 'api/interfaces'
import { AppThunk } from 'app/store'

interface AccountState {
  isLoading: boolean
  isProcessing: boolean
  errors: Error[] | null
  isLoadingTimezones: boolean
  timezones: Timezone[]
  isLoadingStates: boolean
  states: State[]
  paymentMethods: PaymentMethod[]
  token: string
  invoices: Invoice[]
  creditNotes: CreditNote[]
}

const initialState: AccountState = {
  isLoading: false,
  isProcessing: false,
  errors: null,
  isLoadingTimezones: false,
  timezones: [],
  isLoadingStates: false,
  states: [],
  paymentMethods: [],
  token: '',
  invoices: [],
  creditNotes: []
}

const account = createSlice({
  name: 'account',
  initialState,
  reducers: {
    loadingStarted(state: AccountState) {
      state.isLoading = true
    },
    loadingFailed(state: AccountState, { payload }: PayloadAction<Error[]>) {
      state.isLoading = false
      state.errors = payload
    },
    processingStarted(state: AccountState) {
      state.isProcessing = true
    },
    processingFinished(state: AccountState) {
      state.isProcessing = false
    },
    processingFailed(state, { payload }: PayloadAction<Error[]>) {
      state.isProcessing = false
      state.errors = payload
    },
    paymentMethodsLoaded(state: AccountState, { payload }: PayloadAction<PaymentMethod[]>) {
      state.errors = []
      state.isLoading = false
      state.isProcessing = false
      state.paymentMethods = payload
    },
    paymentMethodSetup(state: AccountState, { payload }: PayloadAction<string>) {
      state.errors = []
      state.isProcessing = false
      state.token = payload
    },
    invoicesLoaded(state: AccountState, { payload }: PayloadAction<Invoice[]>) {
      state.errors = []
      state.isLoading = false
      state.isProcessing = false
      state.invoices = payload
    },
    creditNotesLoaded(state: AccountState, { payload }: PayloadAction<CreditNote[]>) {
      state.errors = []
      state.isLoading = false
      state.isProcessing = false
      state.creditNotes = payload
    }
  }
})

export const { loadingStarted, loadingFailed, processingStarted, processingFinished, processingFailed, paymentMethodsLoaded, paymentMethodSetup, invoicesLoaded, creditNotesLoaded } = account.actions

export default account.reducer

export const fetchAccountPaymentMethods =
  (accountId: string): AppThunk =>
  async dispatch => {
    dispatch(loadingStarted())
    const response = await apiGetAccountPaymentMethods(accountId)
    if (response.errors) {
      dispatch(loadingFailed(response.errors))
    } else {
      dispatch(paymentMethodsLoaded(response.data))
    }
  }

export const setupAccountPaymentMethod =
  (accountId: string): AppThunk =>
  async dispatch => {
    dispatch(processingStarted())
    const response = await apiSetupAccountPaymentMethods(accountId)
    if (response.errors) {
      dispatch(processingFailed(response.errors))
    } else {
      dispatch(paymentMethodSetup(response.data.clientSecret))
    }
  }

export const removeAccountPaymentMethod =
  (accountId: string, paymentMethodId: string): AppThunk =>
  async dispatch => {
    dispatch(processingStarted())
    const response = await apiDeleteAccountPaymentMethods(accountId, paymentMethodId)
    if (response.errors) {
      dispatch(processingFailed(response.errors))
    } else {
      dispatch(paymentMethodsLoaded(response.data))
    }
  }

export const makeDefaultAccountPaymentMethod =
  (accountId: string, paymentMethodId: string): AppThunk =>
  async dispatch => {
    dispatch(processingStarted())
    const response = await apiMakeDefaultAccountPaymentMethods(accountId, paymentMethodId)
    if (response.errors) {
      dispatch(processingFailed(response.errors))
    } else {
      dispatch(paymentMethodsLoaded(response.data))
    }
  }

export const fetchAccountInvoices =
  (accountId: string): AppThunk =>
  async dispatch => {
    dispatch(loadingStarted())
    const response = await apiGetAccountInvoices(accountId)
    if (response.errors) {
      dispatch(loadingFailed(response.errors))
    } else {
      dispatch(invoicesLoaded(response.data))
    }
  }

export const fetchAccountCreditNotes =
  (accountId: string): AppThunk =>
  async dispatch => {
    dispatch(loadingStarted())
    const response = await apiGetAccountCreditNotes(accountId)
    if (response.errors) {
      dispatch(loadingFailed(response.errors))
    } else {
      dispatch(creditNotesLoaded(response.data))
    }
  }
