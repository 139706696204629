import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { EuiPage, EuiPageBody } from '@elastic/eui'

import { RootState } from 'app/rootReducer'
import InitialBuildersPageForMobile from 'features/InitialBuildersPageForMobile'
import { ShopifyBusinessTypeSelector } from 'features/builders/ShopifyBusinessTypeSelector'
import { ShopifyStartBuilderPage } from 'features/builders/ShopifyStartBuilderPage'
import StartThirdPartyBuilderPage from 'features/builders/StartThirdPartyBuilderPage'
import { QuickCampaignPage } from 'features/builders/agency/QuickCampaignPage'
import { BuilderWorkflowManager } from 'features/builders/createCampaign/BuilderWorkflowManager'

import FinishedBuilderPage from './FinishedBuilderPage'
import BuilderBillboardsRetailPage from './billboards/retail/Page'
import StartInternetBuilderPage from './internet/StartInternetBuilderPage'
import BuilderInternetRetailPage from './internet/retail/Page'
import BuilderInternetVistaPage from './internet/vista/Page'
import StartTvBuilderPage from './tv/StartTvBuilderPage'
import BuilderTvRetailPage from './tv/retail/Page'

const BuildersPage: React.FC = () => {
  const { path } = useRouteMatch()
  const { isInSignupProcess, isShopifyUser, currentAccount, isVendastaUser, isStaffUser, currentOrganization } = useSelector((state: RootState) => state.app)

  const startBuilderPage = () => {
    if (isShopifyUser) {
      if (!currentAccount?.businessTypeId) {
        return <ShopifyBusinessTypeSelector />
      } else {
        return <ShopifyStartBuilderPage />
      }
    } else if (isVendastaUser) {
      return <StartThirdPartyBuilderPage />
    } else {
      return <BuilderWorkflowManager />
    }
  }

  return (
    <React.Fragment>
      {!isInSignupProcess && (
        <EuiPage className='euiPage--platform'>
          <EuiPageBody>
            <Switch>
              <Route exact={true} path={`${path}/create`}>
                <InitialBuildersPageForMobile />
              </Route>
              {isShopifyUser || isVendastaUser ? (
                <Switch>
                  <Route exact={true} path={`${path}`}>
                    {startBuilderPage()}
                  </Route>
                  <Route exact={true} path={`${path}/tv/start`}>
                    <StartTvBuilderPage />
                  </Route>
                  <Route exact={true} path={`${path}/tv/public/:step`}>
                    <BuilderTvRetailPage />
                  </Route>

                  <Route exact={true} path={`${path}/internet/start`}>
                    <StartInternetBuilderPage />
                  </Route>
                  <Route exact={true} path={`${path}/internet/public/:step`}>
                    <BuilderInternetRetailPage />
                  </Route>
                  <Route exact={true} path={`${path}/internet/vista/:step`}>
                    <BuilderInternetVistaPage />
                  </Route>

                  <Route exact={true} path={`${path}/billboards/public/:step`}>
                    <BuilderBillboardsRetailPage />
                  </Route>
                  <Route exact={true} path={`${path}/finished/:campaignId/:campaignType`}>
                    <FinishedBuilderPage />
                  </Route>
                </Switch>
              ) : (
                <Switch>
                  <Route exact={true} path={`${path}/finished/:campaignId/:campaignType`}>
                    <FinishedBuilderPage />
                  </Route>
                  {(isStaffUser || currentOrganization?.id === 'afa76f00-644f-4c1e-8bdc-855f240dd5a9') && (
                    <Route path={`${path}/quickCampaign`}>
                      <QuickCampaignPage />
                    </Route>
                  )}
                  <Route path={`${path}`}>
                    <BuilderWorkflowManager />
                  </Route>
                </Switch>
              )}
            </Switch>
          </EuiPageBody>
        </EuiPage>
      )}

      {isInSignupProcess && (
        <Switch>
          <Route exact={true} path={`${path}/internet/vista/:step`}>
            <BuilderInternetVistaPage />
          </Route>
          <Route exact={true} path={`${path}/finished/:campaignId/:campaignType`}>
            <FinishedBuilderPage />
          </Route>
        </Switch>
      )}
    </React.Fragment>
  )
}

export default BuildersPage
