import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiHealth, EuiHideFor, EuiImage, EuiInMemoryTable, EuiLoadingSpinner, EuiSpacer, EuiSwitch, EuiText, EuiToolTip, htmlIdGenerator } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { CreativeStatus } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import LastCreativePausedErrorModal from 'features/campaigns/campaign/LastCreativePausedErrorModal'
import history from 'services/HistoryService'
import { CreativeStatusToElasticColor, CreativeStatusToString } from 'utils/EnumToFriendly'

import { resetCreateAd } from '../../createAd/tv/retail/createAdTvRetailSlice'
import { AdState, pauseAd, startAd } from '../campaignSlice'

const AdsDrawer: React.FC = () => {
  const dispatch = useDispatch()
  const campaign = useSelector((state: RootState) => state.campaign)
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [currentPage, setCurrentPage] = useState(0)
  const [isLastCreativePausedErrorVisible, setIsLastCreativePausedErrorVisible] = useState(false)

  const preview = (thumbnailUrl: string, name: string, sections: string[] | null, uploadedUrl: any) => (
    <EuiFlexGroup key={htmlIdGenerator()()} responsive={false} style={{ minWidth: 270 }} gutterSize='none'>
      <EuiHideFor sizes={['xs']}>
        <EuiFlexItem grow={false} style={{ width: 150, minWidth: 150 }} className='eui-alignMiddle'>
          {uploadedUrl === null ? (
            <EuiImage alt='ad' url={thumbnailUrl || '/82x82.png'} size='fullWidth' />
          ) : (
            <video id='uploadedVideo' width='100%' hidden={false}>
              <source src={uploadedUrl} type='video/mp4' />
            </video>
          )}
        </EuiFlexItem>
      </EuiHideFor>
      <EuiFlexItem grow={false} style={{ marginLeft: 10, width: 200 }}>
        <EuiText size='xs' style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          <strong>{name}</strong>
        </EuiText>
        <EuiText size='xs' color='subdued' style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {sections && sections.map(s => <div key={htmlIdGenerator()()}>{s.replace('\\n', ' ')}</div>)}
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  )

  const columns: Array<EuiBasicTableColumn<AdState>> = [
    {
      width: '90px',
      name: 'ON/OFF',
      render: (ad: AdState) => (
        <React.Fragment>
          <EuiSwitch
            showLabel={false}
            label={''}
            checked={ad.checked}
            disabled={ad.disabled}
            onChange={e => {
              if (e.target.checked) {
                dispatch(startAd(currentAccount!.id, campaign!.campaign!.id, ad.id))
              } else {
                if (campaign!.adStates.filter(a => a.checked).length <= 1) {
                  setIsLastCreativePausedErrorVisible(true)
                  return
                }

                dispatch(pauseAd(currentAccount!.id, campaign!.campaign!.id, ad.id))
              }
            }}
          />
          {ad.loading && <EuiLoadingSpinner style={{ marginLeft: 5 }} />}
        </React.Fragment>
      )
    },
    {
      width: '240px',
      name: 'Ad',
      render: (ad: AdState) => preview(ad.thumbnailUrl, ad.name, ad.sections, ad.uploadedUrl)
    },
    {
      width: '110px',
      name: 'Status',
      render: (creative: AdState) => {
        let color = CreativeStatusToElasticColor(creative.status)
        let label = CreativeStatusToString(creative.status)

        return creative.status === CreativeStatus.Disapproved ? (
          <EuiToolTip
            key={htmlIdGenerator()()}
            position='right'
            content={
              <EuiText>
                <ul>
                  {creative.rejectionReasons.map(a => (
                    <li>{a}</li>
                  ))}
                </ul>
              </EuiText>
            }>
            <EuiHealth color={color}>{label}</EuiHealth>
          </EuiToolTip>
        ) : (
          <EuiHealth key={htmlIdGenerator()()} color={color}>
            {label}
          </EuiHealth>
        )
      }
    },
    {
      width: '215px',
      name: '',
      render: (ad: AdState) => (
        <React.Fragment key={htmlIdGenerator()()}>
          <EuiButtonEmpty iconType='inspect' onClick={() => history.push(`/campaigns/ads/${campaign.campaign!.id}/edit/${ad.id}`)} size='s'>
            View
          </EuiButtonEmpty>
        </React.Fragment>
      )
    }
  ]

  const pagination = {
    initialPageSize: 10,
    pageSizeOptions: [10, 50, 100],
    pageIndex: currentPage
  }

  const createAd = () => {
    dispatch(resetCreateAd())
    history.push('/campaigns/tv/public/start')
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem />
        <EuiFlexItem grow={false}>
          <EuiButton id='create' size='s' key='createAd' onClick={createAd} fill>
            Create Ad
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiInMemoryTable
        loading={campaign.isLoadingAds}
        items={campaign.adStates}
        columns={columns}
        pagination={pagination}
        responsive={true}
        onChange={(criteria: any) => {
          setCurrentPage(criteria.page.index)
        }}
      />
      <LastCreativePausedErrorModal isModalVisible={isLastCreativePausedErrorVisible} closeModal={() => setIsLastCreativePausedErrorVisible(false)} />
    </React.Fragment>
  )
}

export default AdsDrawer
