import React, { FC } from 'react'

import { EuiPanel, EuiPanelProps } from '@elastic/eui'

export interface WrapInPanelProps {
  wrapInPanel: boolean
  panelProps?: EuiPanelProps
}

const HandleWrapInPanel: FC<WrapInPanelProps> = ({ wrapInPanel, children, panelProps }) => {
  if (wrapInPanel) {
    return <EuiPanel {...panelProps}>{children}</EuiPanel>
  }

  return <>{children}</>
}

export default HandleWrapInPanel
