import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { EuiLoadingSpinner, EuiPage } from '@elastic/eui'

import { apiGetOrganizationDirect, apiGetAccountsByOrganizationId } from 'api/adcritterApi'
import { Organization, Account } from 'api/interfaces'
import { showSuccessToast, updateOrganizationAndAccountState } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

interface GotoOrganizationPageParams {
  organizationId: string
}

const GotoOrganizationPage: React.FC = () => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state: RootState) => state.app)
  let { organizationId } = useParams<GotoOrganizationPageParams>()
  const [organization, setOrganization] = useState<Organization>()
  const [account, setAccount] = useState<Account>()

  useEffect(() => {
    if (currentUser && !currentUser.roles.includes('staff')) {
      history.push('/dashboard')
    }
    if (currentUser && organizationId) {
      apiGetOrganizationDirect(organizationId).then(result => {
        setOrganization(result.data)
        apiGetAccountsByOrganizationId(result.data.id).then(response => {
          setAccount(response[0])
        })
      })
    }
  }, [currentUser, organizationId])

  useEffect(() => {
    if (organization && account) {
      dispatch(updateOrganizationAndAccountState({ account: account, organization }))
      dispatch(showSuccessToast(`Switching organization to ${organization.name}`))

      history.push(`/dashboard`)
    }
  }, [dispatch, organization, account])

  return (
    <EuiPage className='euiPage--platform'>
      <EuiLoadingSpinner size='xl' style={{ margin: 32 }} />
    </EuiPage>
  )
}

export default GotoOrganizationPage
