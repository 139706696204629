import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiBasicTable, EuiButton, EuiIcon, EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle, EuiOverlayMask, EuiSpacer, EuiText } from '@elastic/eui'
import { DefaultItemIconButtonAction } from '@elastic/eui/src/components/basic_table/action_types'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { PaymentMethod } from 'api/interfaces'
import { useAssignDefaultPaymentMethodMutation, useDeletePaymentMethodMutation, useGetPaymentMethodsQuery } from 'api/rtkQueryApi/platform/paymentMethodsApi'
import { RootState } from 'app/rootReducer'
import { AddPaymentMethod } from 'components/payments/AddPaymentMethod'
import { useGetOrganizationBillingCustomersQuery } from '../../api/rtkQueryApi/platform/organizationsApi'

const PaymentMethodsDrawer = () => {
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const paymentMethods = useGetPaymentMethodsQuery({ accountId: currentAccount?.id ?? '' }, { skip: !currentAccount?.id })
  const [deletePaymentMethod, deletePaymentMethodRequest] = useDeletePaymentMethodMutation()
  const [assignDefaultPaymentMethod, assignDefaultPaymentMethodRequest] = useAssignDefaultPaymentMethodMutation()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const billingCustomersQuery = useGetOrganizationBillingCustomersQuery(undefined)

  const actions: Array<DefaultItemIconButtonAction<PaymentMethod>> = [
    {
      name: 'Remove',
      description: 'Remove this Payment Method',
      icon: 'crossInACircleFilled',
      type: 'icon',
      color: 'danger',
      onClick: (item: PaymentMethod) => {
        if (currentAccount?.id) {
          deletePaymentMethod({ accountId: currentAccount!.id, paymentMethodId: item.id })
        }
      },
      available: (item: PaymentMethod) => !item.isDefault
    },
    {
      name: 'Default',
      description: 'Make this the Default Payment Method',
      icon: 'checkInCircleFilled',
      type: 'icon',
      onClick: (item: PaymentMethod) => {
        assignDefaultPaymentMethod({ accountId: currentAccount!.id, paymentMethodId: item.id })
      },
      available: (item: PaymentMethod) => !item.isDefault
    }
  ]

  const columns: Array<EuiBasicTableColumn<PaymentMethod>> = [
    {
      name: 'Type',
      field: 'paymentMethodType',
      render: (item: string) => {
        if (item.toUpperCase() === 'CARD') {
          return 'Card'
        } else if (item.toUpperCase() === 'BANKACCOUNT') {
          return 'Bank Account'
        }
      }
    },
    {
      name: 'Number',
      field: 'last4',
      render: (item: string) => `ends in ${item}`
    },
    {
      name: 'Expires',
      render: (item: PaymentMethod) => (item.expiryMonth && item.expiryYear ? `${item.expiryMonth}/${item.expiryYear}` : '')
    },
    {
      name: 'Customer',
      render: (item: PaymentMethod) => {
        const customer = billingCustomersQuery.data?.find(customer => customer.id === item.customerId)
        return customer?.name ?? ''
      }
    },
    {
      name: '',
      render: (item: PaymentMethod) =>
        item.isDefault ? (
          <EuiText size={'s'}>
            <EuiIcon type={'check'} /> Default
          </EuiText>
        ) : (
          ''
        )
    },
    {
      name: 'Actions',
      actions
    }
  ]

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  return (
    <React.Fragment>
      <EuiBasicTable loading={paymentMethods.isLoading || deletePaymentMethodRequest.isLoading || assignDefaultPaymentMethodRequest.isLoading} items={paymentMethods.data ?? []} columns={columns} hasActions={true} noItemsMessage={!paymentMethods.isLoading && 'please add a payment method'} />
      <EuiSpacer />
      <EuiButton id='addPayment' onClick={showModal} type='secondary' size='s'>
        Add Payment Method
      </EuiButton>
      {isModalVisible && currentAccount?.id && (
        <EuiOverlayMask headerZindexLocation='above'>
          <EuiModal onClose={closeModal}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>Add Payment Method</EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <AddPaymentMethod accountId={currentAccount.id} />
            </EuiModalBody>
          </EuiModal>
        </EuiOverlayMask>
      )}
    </React.Fragment>
  )
}

export default PaymentMethodsDrawer
