import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Moment } from 'moment'

import { apiPostAnalytics, apiGetDashboardFilters } from 'api/adcritterApi'
import { Analytics, DashboardCampaign, DashboardCreative } from 'api/interfaces'

interface DashboardState {
  loadingState: 'idle' | 'loading' | 'refreshing' | 'succeeded' | 'failed'
  isLoadingFilters: boolean
  currentRequestId: string | undefined
  analytics: Array<Analytics>
  tvAnalytics: Array<Analytics>
  tvBreakdownAnalytics: Array<Analytics>
  billboardAnalytics: Array<Analytics>
  billboardBreakdownAnalytics: Array<Analytics>
  campaigns: Array<DashboardCampaign>
  ads: Array<DashboardCreative>
}

const initialState: DashboardState = {
  loadingState: 'idle',
  isLoadingFilters: false,
  currentRequestId: undefined,
  analytics: [],
  tvAnalytics: [],
  tvBreakdownAnalytics: [],
  billboardAnalytics: [],
  billboardBreakdownAnalytics: [],
  campaigns: [],
  ads: []
}

interface FetchAnalyticsParams {
  accountId: string
  start: Moment
  end: Moment
  label: string
  campaignIds: Array<string>
  adIds: Array<string>
}

export const fetchAnalytics = createAsyncThunk('dashboard/analytics', async (params: FetchAnalyticsParams) => {
  const { data } = await apiPostAnalytics(params.accountId, params.start, params.end, params.campaignIds, params.adIds, params.label)
  return data
})

export const fetchFilters = createAsyncThunk('dashboard/fetchFilters', async (accountId: string) => {
  const { data } = await apiGetDashboardFilters(accountId)
  return data
})

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setIdleLoadingState(state: DashboardState) {
      state.loadingState = 'idle'
      state.campaigns = []
      state.ads = []
      state.isLoadingFilters = false
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchAnalytics.pending, (state, action) => {
      if (state.loadingState === 'idle' || state.loadingState === 'succeeded') {
        state.loadingState = state.loadingState === 'idle' ? 'loading' : 'refreshing'
        state.currentRequestId = action.meta.requestId
      }
    })
    builder.addCase(fetchAnalytics.fulfilled, (state, action) => {
      const { requestId } = action.meta
      if ((state.loadingState === 'loading' || state.loadingState === 'refreshing') && state.currentRequestId === requestId) {
        state.loadingState = 'succeeded'
        state.analytics = action.payload.internetCampaigns
        state.tvAnalytics = action.payload.tvCampaigns
        state.tvBreakdownAnalytics = action.payload.tvBreakdownCampaigns
        state.billboardAnalytics = action.payload.billboardCampaigns
        state.billboardBreakdownAnalytics = action.payload.billboardBreakdownCampaigns
        state.currentRequestId = undefined
      }
    })
    builder.addCase(fetchFilters.pending, state => {
      state.isLoadingFilters = true
    })
    builder.addCase(fetchFilters.fulfilled, (state, action) => {
      state.isLoadingFilters = false
      state.campaigns = action.payload.campaigns
      state.ads = action.payload.creatives
    })
  }
})

export const { setIdleLoadingState } = dashboard.actions

export default dashboard.reducer
