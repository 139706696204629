import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiFieldSearch, EuiImage, EuiInputPopover, EuiSelectable, EuiText, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { BusinessType } from 'api/interfaces'
import { clearBusinessTypes, fetchBusinessTypes } from 'app/dictionarySlice'
import { RootState } from 'app/rootReducer'
import useDebounce from 'utils/useDebounce'

interface BusinessTypeFinderProps {
  isInvalid: boolean | undefined
  onBusinessTypeClicked(businessType: BusinessType): void
  searchPlaceHolderText?: string
}

export const BusinessTypeFinder = (props: BusinessTypeFinderProps) => {
  const dispatch = useDispatch()
  const minimumLengthForSearch = 2
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const [businessTypeSearchTerm, setBusinessTypeSearchTerm] = useState<string>('')
  const debouncedBusinessTypeSearchTerm = useDebounce(businessTypeSearchTerm, 750)
  const [searchBusinessTypePopoverOpen, setSearchBusinessTypePopoverOpen] = useState<boolean>(false)
  const [businessTypes, setBusinessTypes] = useState<Array<EuiSelectableOption>>([])

  const onSearchBusinessType = () => {
    setSearchBusinessTypePopoverOpen(false)
  }

  const onSearchBusinessTypeChange = (hint: string) => {
    setBusinessTypeSearchTerm(hint)
  }

  const onSearchBusinessTypeClick = useCallback(
    (businessType: BusinessType) => {
      setSearchBusinessTypePopoverOpen(false)
      setBusinessTypeSearchTerm('')
      props.onBusinessTypeClicked(businessType)
    },
    [props.onBusinessTypeClicked]
  )

  useEffect(() => {
    if (debouncedBusinessTypeSearchTerm && debouncedBusinessTypeSearchTerm.length > minimumLengthForSearch) {
      dispatch(fetchBusinessTypes(debouncedBusinessTypeSearchTerm))
    } else {
      dispatch(clearBusinessTypes())
    }
  }, [debouncedBusinessTypeSearchTerm, dispatch])

  useEffect(() => {
    if (dictionary.businessTypes) {
      setBusinessTypes(
        dictionary.businessTypes.map(
          a =>
            ({
              label: a.name,
              key: a.id.toString(),
              prepend: a.imageUrl ? <EuiImage alt='image' src={a.imageUrl} size={40} /> : <div style={{ width: 40 }} />,
              append: a.businessTypeCategories.join(', '),
              checked: undefined,
              onClick: () => onSearchBusinessTypeClick(a),
              showIcons: false
            } as EuiSelectableOption)
        )
      )
    }
  }, [dictionary.businessTypes, onSearchBusinessTypeClick])

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          value={businessTypeSearchTerm}
          isLoading={dictionary.isLoadingBusinessTypes}
          onChange={v => onSearchBusinessTypeChange(v.target.value)}
          onFocus={() => setSearchBusinessTypePopoverOpen(true)}
          incremental={false}
          onSearch={() => onSearchBusinessType()}
          placeholder={props.searchPlaceHolderText ?? 'Type to begin searching for your business type...'}
          isInvalid={props.isInvalid}
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
        />
      }
      isOpen={searchBusinessTypePopoverOpen}
      closePopover={() => setSearchBusinessTypePopoverOpen(false)}>
      {dictionary.businessTypes && dictionary.businessTypes.length ? (
        <EuiSelectable singleSelection options={businessTypes} listProps={{ rowHeight: 60 }}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search, results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
