import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiLoadingContent, EuiOverlayMask, EuiModalHeader, EuiModalHeaderTitle, EuiModalBody, EuiForm, EuiFormRow, EuiFieldText, EuiFieldPassword, EuiSpacer, EuiModalFooter, EuiButtonEmpty, EuiModal, EuiBasicTable } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { ApiError, apiPostOrganizationUsers } from 'api/adcritterApi'
import { User } from 'api/interfaces'
import { showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'

import { fetchOrganizationUsers, usersLoaded } from './organizationSlice'

interface FormValues {
  firstName: string
  lastName: string
  email: string
  password: string
}

const UsersDrawer = () => {
  const dispatch = useDispatch()
  const organization = useSelector((state: RootState) => state.organization)
  const { currentOrganization } = useSelector((state: RootState) => state.app)
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    dispatch(fetchOrganizationUsers())
  }, [currentOrganization, dispatch])

  const columns: Array<EuiBasicTableColumn<User>> = [
    {
      name: 'Name',
      field: 'name'
    },
    {
      name: 'Email',
      field: 'email'
    }
  ]

  const inviteSchema = Yup.object().shape({
    firstName: Yup.string().max(128).required('Please Enter a First Name'),
    lastName: Yup.string().max(128).required('Please Enter a Last Name'),
    email: Yup.string().email().required('Please Enter an Email'),
    password: Yup.string().required('Please Enter a Password')
  })

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: ''
    },
    validationSchema: inviteSchema,
    onSubmit: (values: FormValues) => {
      apiPostOrganizationUsers(values.firstName, values.lastName, values.email, values.password)
        .then(response => {
          if (response.errors) {
            if (response.errors[0].name) {
              response.errors.forEach(function (e: ApiError) {
                formik.setFieldError(e.name, e.message)
              })
            } else {
              formik.setStatus(response.errors[0].message)
            }
          } else {
            dispatch(showSuccessToast(`Invited ${values.firstName} ${values.lastName}`))
            dispatch(usersLoaded(response.data))
            closeModal()
            formik.setStatus(null)
          }
          formik.setSubmitting(false)
        })
        .catch(response => {
          formik.setSubmitting(false)
          formik.setStatus('Error inviting user')
        })
    }
  })

  const closeModal = () => setIsModalVisible(false)
  const showModal = () => setIsModalVisible(true)

  let modal

  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal} style={{ width: 500 }}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Invite User</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.status && !!formik.errors} error={formik.status}>
              <EuiFormRow label='First Name' fullWidth isInvalid={formik.touched.firstName && !!formik.errors.firstName} error={formik.errors.firstName}>
                <EuiFieldText name='firstName' defaultValue={formik.values.firstName} fullWidth isInvalid={formik.touched.firstName && !!formik.errors.firstName} />
              </EuiFormRow>

              <EuiFormRow label='Last Name' fullWidth isInvalid={formik.touched.lastName && !!formik.errors.lastName} error={formik.errors.lastName}>
                <EuiFieldText name='lastName' defaultValue={formik.values.lastName} fullWidth isInvalid={formik.touched.lastName && !!formik.errors.lastName} />
              </EuiFormRow>

              <EuiFormRow label='Email' fullWidth isInvalid={formik.touched.email && !!formik.errors.email} error={formik.errors.email}>
                <EuiFieldText name='email' icon='email' defaultValue={formik.values.email} placeholder='username@domain.com' fullWidth isInvalid={formik.touched.email && !!formik.errors.email} />
              </EuiFormRow>

              <EuiFormRow label='Password' fullWidth isInvalid={formik.touched.password && !!formik.errors.password} error={formik.errors.password}>
                <EuiFieldPassword name='password' fullWidth isInvalid={formik.touched.password && !!formik.errors.password} />
              </EuiFormRow>
              <EuiSpacer />
            </EuiForm>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButtonEmpty id='cancel' onClick={closeModal}>
              Cancel
            </EuiButtonEmpty>

            <EuiButton id='invite' onClick={() => formik.handleSubmit()} fill isLoading={formik.isSubmitting}>
              Invite
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  let content

  if (organization.users == null || organization.isLoading) {
    content = <EuiLoadingContent lines={3} />
  } else {
    content = (
      <React.Fragment>
        <EuiButton id='inviteUser' onClick={showModal} type='secondary' size='s'>
          Invite User
        </EuiButton>
        <EuiSpacer />
        <EuiBasicTable loading={organization.isLoading} items={organization.users} columns={columns} />
        {modal}
      </React.Fragment>
    )
  }

  return <div>{content}</div>
}

export default UsersDrawer
