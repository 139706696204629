import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { BuilderCampaignType } from 'workflows/CampaignCreateWorkflow'

import { EuiSpacer } from '@elastic/eui'

import { RootState } from 'app/rootReducer'
import { AcCardSelector, IAcCard } from 'components/Collections/AcCardSelector'
import { BackNextFooter } from 'components/navigation/BackNextFooter'
import { ICampaignBuilderWorkflowStep } from 'features/builders/createCampaign/creativeBuilder/CreativeBuilderWorkflowManager'
import { useCampaignBuilderActions } from 'features/builders/createCampaign/useCampaignBuilderActions'

export const BuilderModeSelectPage: React.FC<ICampaignBuilderWorkflowStep> = props => {
  const { builderNativeSuggestedSelected, builderNativeUploadSelected, builderDisplayUploadSelected, builderDisplayCustomSelected, builderTvSuggestedSelected, builderTvUploadSelected, builderTvCustomSelected, builderBillboardUploadSelected, builderBillboardCustomSelected, skipAdBuilder } = useCampaignBuilderActions()
  const { campaignType } = useSelector((state: RootState) => state.campaignCreateWorkflow)
  const [builderModeCards, setBuilderModeCards] = React.useState<IAcCard[]>([])
  const [cardsPerRow, setCardsPerRow] = React.useState<number>(2)

  useEffect(() => {
    const cards: IAcCard[] = []
    if (campaignType === BuilderCampaignType.Native || campaignType === BuilderCampaignType.Display) {
      setCardsPerRow(3)
      cards.push({
        id: 'suggestedAdsTile',
        title: 'Suggested Ads',
        description: 'Suggested ads for your business.',
        onClick: () => {
          builderNativeSuggestedSelected()
        },
        iconType: 'grid',
        footer: 'Browse ads and pick one you like. You can use it as-is or easily customize it for your business.'
      })
      cards.push({
        id: 'adBuilderTile',
        title: 'Ad Builder',
        description: 'Create a new ad for your business.',
        onClick: () => {
          builderNativeUploadSelected()
        },
        iconType: 'image',
        footer: 'Use our state-of-the-art Ad Builder and see how your ad will look for users as you build it.'
      })
      cards.push({
        id: 'uploadAdTile',
        title: 'Upload',
        description: 'Upload an ad.',
        onClick: () => {
          builderDisplayUploadSelected()
        },
        iconType: 'arrowUp',
        footer: 'Do you have your own Internet ad? Upload it here.'
      })
      cards.push({
        id: 'customAdsTile',
        title: 'Custom Ads',
        description: 'Let us make you some ads.',
        onClick: () => {
          builderDisplayCustomSelected()
        },
        iconType: 'pencil',
        footer: "Is your business too unique for Suggested Ads? Answer a few questions and we'll make a suite of custom ads just for you."
      })
    } else if (campaignType === BuilderCampaignType.TV) {
      setCardsPerRow(2)
      cards.push({
        id: 'preMadeCommercialsTile',
        title: 'Pre-Made Commercials',
        description: 'Pre-made commercials based on your business type.',
        onClick: () => {
          builderTvSuggestedSelected()
        },
        iconType: 'grid',
        footer: 'Browse commercials and pick one you like. You can use it as-is or easily customize it for your business.'
      })
      cards.push({
        id: 'uploadCommercialsTile',
        title: 'Upload a Commercial',
        description: 'Upload a TV commercial.',
        onClick: () => {
          builderTvUploadSelected()
        },
        iconType: 'play',
        footer: 'Do you have your own TV commercial? Upload it here.'
      })
      cards.push({
        id: 'customCommercialsTile',
        title: 'Custom Commercials',
        description: 'Custom-made commercials designed for you.',
        onClick: () => {
          builderTvCustomSelected()
        },
        iconType: 'pencil',
        footer: 'Tell us about your business or provide a rough script. We’ll custom design your commercial. Free with three-month commitment.'
      })
    } else if (campaignType === BuilderCampaignType.Billboard) {
      setCardsPerRow(1)
      cards.push({
        id: 'uploadBillboardTile',
        title: 'Upload a Billboard',
        description: 'Upload an ad.',
        onClick: () => {
          builderBillboardUploadSelected()
        },
        iconType: 'play',
        footer: 'Do you have your own billboard ad? Upload it here.'
      })
      cards.push({
        id: 'customBillboardTile',
        title: 'Custom Billboards',
        description: 'Let us make you some billboards.',
        onClick: () => {
          builderBillboardCustomSelected()
        },
        iconType: 'pencil',
        footer: "Answer a few questions and we'll make some custom billboards just for you."
      })
    }

    setBuilderModeCards(cards)
  }, [campaignType])

  const onBackClick = () => {
    props.onBack?.()
  }

  const onSkipClick = () => {
    skipAdBuilder()
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <AcCardSelector cards={builderModeCards} cardsPerRow={cardsPerRow} />
      <EuiSpacer />
      <BackNextFooter onBack={onBackClick} nextText={'Skip For Now'} onNext={onSkipClick} isNextHidden={true} />
      <EuiSpacer />
    </React.Fragment>
  )
}
