import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Axis, Chart, LIGHT_THEME, LineSeries, PartialTheme, Position, ScaleType, Settings } from '@elastic/charts'
import { CurveType } from '@elastic/charts/dist/utils/curves'
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiHideFor, EuiPanel, EuiShowFor, EuiSpacer, EuiSwitch, EuiText, EuiTitle, EuiToolTip } from '@elastic/eui'
import { EuiBreakpointSize } from '@elastic/eui/src/services/breakpoint'

import { Analytics } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import { initializeCampaignCreate } from 'features/builders/CampaignCreateSlice'
import { CreateCampaignStorageManager } from 'features/builders/createCampaign/CreateCampaignStorageManager'
import history from 'services/HistoryService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import { toggleCpm, toggleSpend, toggleViews } from './tvOverviewSlice'

interface TvOverviewChartProps {
  showCreate: boolean
  tvCampaigns: Analytics[]
}

const TvOverviewChart: React.FC<TvOverviewChartProps> = ({ showCreate, tvCampaigns }) => {
  const dispatch = useDispatch()
  const { currentOrganization, isVendastaUser } = useSelector((state: RootState) => state.app)
  const overview = useSelector((state: RootState) => state.tvOverview)
  const whiteLabel = useWhiteLabel()
  const [hideFor, setHideFor] = useState<EuiBreakpointSize[] | 'all' | 'none'>('none')

  useEffect(() => {
    if (showCreate) {
      setHideFor(['xs', 's'])
    }
  }, [showCreate])

  const startTargetedTv = () => {
    dispatch(initializeCampaignCreate())
    CreateCampaignStorageManager.DeleteFromLocalStorage()
    history.push('/build')
  }

  const theme: PartialTheme = {
    lineSeriesStyle: {
      line: {
        strokeWidth: 3
      },
      point: { radius: 5 }
    }
  }

  return (
    <React.Fragment>
      <EuiShowFor sizes={['xs', 's']}>
        {!isVendastaUser && showCreate && (
          <EuiButton onClick={startTargetedTv} color='primary' fill>
            Create TV Campaign
          </EuiButton>
        )}
      </EuiShowFor>
      <EuiHideFor sizes={hideFor}>
        <EuiPanel grow={false} style={{ position: 'relative' }}>
          <EuiHideFor sizes={['xs', 's']}>
            {showCreate && (
              <div
                style={{
                  background: 'rgba(0,0,0,0.6)',
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  zIndex: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '6px',
                  marginLeft: -16
                }}>
                {isVendastaUser ? (
                  <EuiTitle size='m'>
                    <EuiText color='white'>No campaigns available</EuiText>
                  </EuiTitle>
                ) : (
                  <EuiButton onClick={startTargetedTv} color='primary' fill>
                    Create TV Campaign
                  </EuiButton>
                )}
              </div>
            )}
          </EuiHideFor>
          <EuiTitle size='xs'>
            <h4 style={{ whiteSpace: 'nowrap' }}>Targeted TV Campaigns</h4>
          </EuiTitle>
          <EuiSpacer size='s' />
          <EuiHideFor sizes={['xs', 's', 'm']}>
            <EuiFlexGroup responsive={false}>
              {!whiteLabel?.hideAllSpend() && (
                <EuiFlexItem grow={false} style={{ borderBottom: '2px solid #f49342', paddingBottom: '3px' }}>
                  <EuiSwitch label={'Views'} checked={overview.showViews} onChange={() => dispatch(toggleViews())} compressed={true} />
                </EuiFlexItem>
              )}
              {!whiteLabel?.hideAllSpend() && (
                <EuiFlexItem grow={false} style={{ borderBottom: '2px solid #9170B8', paddingBottom: '3px' }}>
                  <EuiSwitch label='Spend' checked={overview.showSpend} onChange={() => dispatch(toggleSpend())} compressed={true} />
                </EuiFlexItem>
              )}
              {!whiteLabel?.hideAllSpend() && (
                <EuiFlexItem grow={false} style={{ borderBottom: '2px solid #D6BF57', paddingBottom: '3px' }}>
                  <EuiToolTip position='top' content='Cost per thousand views'>
                    <EuiSwitch label='CPM' checked={overview.showCpm} onChange={() => dispatch(toggleCpm())} compressed={true} />
                  </EuiToolTip>
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiHideFor>
          <Chart size={{ height: 230 }}>
            <Settings theme={theme} baseTheme={LIGHT_THEME} />
            {overview.showViews && <LineSeries data={tvCampaigns.map(a => ({ x: a.forDateAsString, y: a.impressions }))} id='Views' xAccessor='x' yAccessors={['y']} xScaleType={ScaleType.Ordinal} yScaleType={ScaleType.Linear} groupId='views' curve={CurveType.CURVE_MONOTONE_X} color={['#f49342']} />}
            {!whiteLabel?.hideAllSpend() && overview.showSpend && <LineSeries data={tvCampaigns.map(a => ({ x: a.forDateAsString, y: a.spend }))} id='Spend' xAccessor='x' yAccessors={['y']} xScaleType={ScaleType.Ordinal} yScaleType={ScaleType.Linear} groupId='spend' curve={CurveType.CURVE_MONOTONE_X} color={['#9170B8']} />}
            {!whiteLabel?.hideAllSpend() && overview.showCpm && (
              <LineSeries
                data={tvCampaigns.map(a => ({
                  x: a.forDateAsString,
                  y: a.impressions === 0 ? 0 : (a.spend / a.impressions) * 1000
                }))}
                id='CPM'
                xAccessor='x'
                yAccessors={['y']}
                xScaleType={ScaleType.Ordinal}
                yScaleType={ScaleType.Linear}
                groupId='cpm'
                curve={CurveType.CURVE_MONOTONE_X}
                color={['#D6BF57']}
              />
            )}
            <Axis id='views' hide={!overview.showViews} title='Views' position={overview.positionViews} tickFormat={d => `${Number(d).toLocaleString()}`} groupId='views' />
            {!whiteLabel?.hideAllSpend() && <Axis id='spend' hide={!overview.showSpend} title='Spend' position={overview.positionSpend} tickFormat={d => `${Number(d).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`} groupId='spend' />}
            {!whiteLabel?.hideAllSpend() && <Axis id='cpm' hide={!overview.showCpm} title='CPM' position={overview.positionCpm} tickFormat={d => `${Number(d).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`} groupId='cpm' />}
            <Axis id='bottom' position={Position.Bottom} />
          </Chart>
        </EuiPanel>
      </EuiHideFor>
    </React.Fragment>
  )
}

export default TvOverviewChart
