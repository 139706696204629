import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiCheckbox, EuiFieldNumber, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiLink, EuiSelect, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { FrequencyUnit, GeoFence, LocationTarget, SegmentTarget, ThirdPartyAudience } from 'api/interfaces'
import { AdvancedTargetingOptions, IAudienceTargeting, useUpdateCampaignLocationTargetingMutation } from 'api/rtkQueryApi/platform/campaignsApi'
import { showErrorToast, showSuccessToast } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import { DayPartPicker, DefaultDayParts } from 'components/targeters/DayPartPicker'
import { DemographicsTargeter } from 'components/targeters/DemographicsTargeter'
import { GeoSelectionTargeter, LocationType } from 'components/targeters/GeoSelectionTargeter'
import ModernAudienceTargeter from 'components/targeters/ModernAudienceTargeter'
import { setInitialTargetingMapState } from 'components/targetingMapSlice'
import { AdvancedTargetingType } from 'features/builders/internet/retail/builderInternetRetailSlice'
import { fetchCampaign } from 'features/campaigns/campaign/campaignSlice'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

export interface IModernTargetingDrawer {}

export const ModernTargetingDrawer: React.FC<IModernTargetingDrawer> = () => {
  const dispatch = useDispatch()
  const { updatedGeoCircles, updatedGeoRectangles, updatedGeoPolygons } = useSelector((state: RootState) => state.targetingMap)
  const [selectedLocationType, setSelectedLocationType] = useState<LocationType>(LocationType.City)
  const whiteLabel = useWhiteLabel()

  const [audienceTargeting, setAudienceTargeting] = useState<IAudienceTargeting>({
    ageRanges: [],
    genders: [],
    householdIncomes: [],
    thirdPartyAudiences: [],
    segments: []
  })
  const { campaign } = useSelector((state: RootState) => state.campaign)
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [locationTargeting, setLocationTargeting] = useState<LocationTarget>()
  const [addresses, setAddresses] = useState<GeoFence[]>([])
  const [hiddenGeoTypes, setHiddenGeoTypes] = useState<LocationType[]>([LocationType.Map, LocationType.Address])
  const [updateTargeting, updateTargetingRequest] = useUpdateCampaignLocationTargetingMutation()

  const [advancedTargetingOptions, setAdvancedTargetingOptions] = useState<AdvancedTargetingOptions>({
    dayParts: DefaultDayParts,
    inventorySettings: {
      targetWeb: false,
      targetApp: false
    },
    deviceSettings: {
      targetPc: false,
      targetPhone: false,
      targetTablet: false
    },
    frequency: 0,
    frequencyUnit: FrequencyUnit.day
  })

  useEffect(() => {
    if (!campaign) {
      return
    }

    if (campaign.advancedTargeting) {
      setAudienceTargeting({
        ageRanges: campaign.advancedTargeting.ageRanges ?? [],
        genders: campaign.advancedTargeting.genders ?? [],
        householdIncomes: campaign.advancedTargeting.householdIncomes ?? [],
        thirdPartyAudiences: campaign.advancedTargeting.audiences ?? [],
        segments: campaign.advancedTargeting.segments ?? []
      })
      setLocationTargeting(
        campaign.advancedTargeting?.locations ?? {
          countries: [],
          regions: [],
          cities: [],
          postalCodes: [],
          dmas: []
        }
      )

      setAdvancedTargetingOptions({
        dayParts: campaign.advancedTargeting.dayParts ?? DefaultDayParts,
        inventorySettings: {
          targetWeb: campaign.advancedTargeting.inventorySettings?.targetWeb ?? false,
          targetApp: campaign.advancedTargeting.inventorySettings?.targetApp ?? false
        },
        deviceSettings: {
          targetPc: campaign.advancedTargeting.deviceSettings?.targetPc ?? false,
          targetPhone: campaign.advancedTargeting.deviceSettings?.targetPhone ?? false,
          targetTablet: campaign.advancedTargeting.deviceSettings?.targetTablet ?? false
        },
        frequency: campaign.advancedTargeting.frequency ?? 0,
        frequencyUnit: campaign.advancedTargeting.frequencyUnit ?? FrequencyUnit.day
      })

      setAddresses(campaign.advancedTargeting.geoFences ?? [])

      dispatch(
        setInitialTargetingMapState({
          initialGeoCircles: campaign.advancedTargeting?.geoCircles ?? [],
          initialGeoRectangles: campaign.advancedTargeting?.geoRectangles ?? [],
          initialGeoPolygons: campaign.advancedTargeting?.geoPolygons ?? [],
          includeBillboards: false
        })
      )

      setSelectedLocationType(campaign.advancedTargeting.locationType as LocationType)

      if (campaign.advancedTargeting.advancedTargetingType === AdvancedTargetingType.Audience) {
        setHiddenGeoTypes([LocationType.Map, LocationType.Address])
      } else {
        setHiddenGeoTypes([])
      }
    } else if (campaign.billboardTargeting) {
      setLocationTargeting(
        campaign.billboardTargeting?.locations ?? {
          countries: [],
          regions: [],
          cities: [],
          postalCodes: [],
          dmas: []
        }
      )

      dispatch(
        setInitialTargetingMapState({
          initialGeoCircles: [],
          initialGeoRectangles: [],
          initialGeoPolygons: [],
          includeBillboards: true
        })
      )

      setSelectedLocationType(campaign.billboardTargeting.locationType as LocationType)
      setHiddenGeoTypes([LocationType.Map, LocationType.Address])
    } else if (campaign.tvTargeting) {
      setAudienceTargeting({
        ageRanges: campaign.tvTargeting.ageRanges ?? [],
        genders: campaign.tvTargeting.genders ?? [],
        householdIncomes: campaign.tvTargeting.householdIncomes ?? [],
        thirdPartyAudiences: campaign.tvTargeting.audiences ?? [],
        segments: campaign.tvTargeting.segments ?? []
      })
      setLocationTargeting(
        campaign.tvTargeting?.locations ?? {
          countries: [],
          regions: [],
          cities: [],
          postalCodes: [],
          dmas: []
        }
      )
      setAdvancedTargetingOptions({
        dayParts: campaign.tvTargeting.dayParts ?? DefaultDayParts,
        inventorySettings: {
          targetWeb: false,
          targetApp: false
        },
        deviceSettings: {
          targetPc: false,
          targetPhone: false,
          targetTablet: false
        },
        frequency: campaign.tvTargeting.frequency ?? 0,
        frequencyUnit: campaign.tvTargeting.frequencyUnit ?? FrequencyUnit.day
      })

      dispatch(
        setInitialTargetingMapState({
          initialGeoCircles: [],
          initialGeoRectangles: [],
          initialGeoPolygons: [],
          includeBillboards: false
        })
      )

      setSelectedLocationType(campaign.tvTargeting.locationType as LocationType)
      setHiddenGeoTypes([LocationType.Map, LocationType.Address])
    }
  }, [campaign])

  const onUpdateCampaign = () => {
    if (currentAccount?.id && locationTargeting && campaign) {
      if (campaign?.advancedTargeting?.advancedTargetingType === AdvancedTargetingType.Audience) {
        if (audienceTargeting.segments.length === 0 && audienceTargeting.thirdPartyAudiences.length === 0) {
          dispatch(showErrorToast('Please add at least 1 individual characteristic'))
          return
        }
      }

      updateTargeting({
        campaignId: campaign.id,
        accountId: currentAccount?.id,
        targeting: {
          locations: locationTargeting,
          geoCircles: updatedGeoCircles,
          geoRectangles: updatedGeoRectangles,
          geoPolygons: updatedGeoPolygons,
          geoFences: addresses
        },
        locationType: selectedLocationType,
        audience: audienceTargeting,
        advancedOptions: whiteLabel?.isAgencies() ? advancedTargetingOptions : undefined
      }).then(() => {
        dispatch(showSuccessToast('Saved targeting information'))
        dispatch(fetchCampaign(currentAccount.id, campaign.id))
      })
    }
  }

  const onTargetingUpdated = (targeting: LocationTarget, addresses?: GeoFence[]) => {
    setLocationTargeting(targeting)
    setAddresses(addresses ?? [])
  }

  const onAgeRangesChanged = (ageRanges: string[]) => {
    setAudienceTargeting({
      ...audienceTargeting,
      ageRanges
    })
  }

  const onGendersChanged = (genders: string[]) => {
    setAudienceTargeting({
      ...audienceTargeting,
      genders
    })
  }

  const onHouseholdIncomesChanged = (householdIncomes: string[]) => {
    setAudienceTargeting({
      ...audienceTargeting,
      householdIncomes
    })
  }

  const onSegmentClicked = (segment: SegmentTarget) => {
    setAudienceTargeting({
      ...audienceTargeting,
      segments: [...audienceTargeting.segments.filter(s => s.id !== segment.id), segment]
    })
  }

  const onAudienceRemoved = (audience: ThirdPartyAudience) => {
    setAudienceTargeting({
      ...audienceTargeting,
      segments: audienceTargeting.segments.filter(s => s.id !== audience.id)
    })
  }

  const frequencyOptions = [
    { value: FrequencyUnit.hour, text: 'Hour' },
    { value: FrequencyUnit.day, text: 'Day' },
    { value: FrequencyUnit.week, text: 'Week' }
  ]

  const ogFileName = locationTargeting?.uploadedAudienceUrl
    ?.split('/')
    .pop()
    ?.replace(/_(?!.*_)[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}\./g, '.')

  return (
    <React.Fragment>
      <EuiSpacer />

      {campaign?.advancedTargeting?.advancedTargetingType === AdvancedTargetingType.Audience && <ModernAudienceTargeter title='Audience Targeting' audienceTargeting={audienceTargeting} onSegmentClicked={onSegmentClicked} onAudienceRemoved={onAudienceRemoved} />}
      {campaign?.advancedTargeting?.advancedTargetingType !== AdvancedTargetingType.Audience && <GeoSelectionTargeter locations={locationTargeting} addresses={addresses} hideLocationTypes={hiddenGeoTypes} selectedLocationType={selectedLocationType} onTargetingUpdated={onTargetingUpdated} onSelectedTargetingTypeChange={setSelectedLocationType} alwaysShowMap={!!campaign?.billboardTargeting} includeBillboards={!!campaign?.billboardTargeting} />}

      <EuiSpacer />

      {campaign?.advancedTargeting?.advancedTargetingType === AdvancedTargetingType.Audience && <GeoSelectionTargeter title='Location Restriction' locations={locationTargeting} addresses={addresses} hideLocationTypes={hiddenGeoTypes} selectedLocationType={selectedLocationType} onTargetingUpdated={onTargetingUpdated} onSelectedTargetingTypeChange={setSelectedLocationType} />}

      <EuiSpacer />

      {!campaign?.billboardTargeting && (
        <React.Fragment>
          <EuiTitle size='s'>
            <span>Demographic Prioritization</span>
          </EuiTitle>
          <EuiSpacer size='s' />
          <DemographicsTargeter ageRanges={audienceTargeting.ageRanges} genders={audienceTargeting.genders} householdIncomes={audienceTargeting.householdIncomes} onAgeRangesChanged={onAgeRangesChanged} onHouseholdIncomesChanged={onHouseholdIncomesChanged} onGendersChanged={onGendersChanged} />
          <EuiSpacer size='xl' />
          {locationTargeting?.uploadedAudienceUrl && (
            <React.Fragment>
              <EuiTitle size='s'>
                <span>Optional: Target Audience</span>
              </EuiTitle>
              <EuiFormRow fullWidth>
                <EuiLink href={locationTargeting?.uploadedAudienceUrl} target='_blank'>
                  {ogFileName}
                </EuiLink>
              </EuiFormRow>
              <EuiSpacer />
            </React.Fragment>
          )}
          {campaign?.advancedTargeting?.advancedTargetingType !== AdvancedTargetingType.Audience && <ModernAudienceTargeter title='Optional: Audience Refinement' audienceTargeting={audienceTargeting} onSegmentClicked={onSegmentClicked} onAudienceRemoved={onAudienceRemoved} />}
          {whiteLabel?.isAgencies() && (
            <React.Fragment>
              <EuiSpacer size='xl' />
              <EuiTitle size='s'>
                <span>Advanced Targeting Options</span>
              </EuiTitle>
              <EuiSpacer size='s' />
              <EuiFormRow fullWidth label={'Select which hours of the day your ad will run. (Uses the audience time zone)'}>
                <DayPartPicker
                  dayParts={advancedTargetingOptions.dayParts}
                  onSelectedDayPartsChange={days => {
                    setAdvancedTargetingOptions({ ...advancedTargetingOptions, dayParts: days })
                  }}
                />
              </EuiFormRow>
              <EuiSpacer size='s' />
              <EuiSpacer size='s' />
              {campaign?.advancedTargeting && (
                <EuiFormRow label='Inventory'>
                  <EuiFlexGrid direction='column'>
                    <EuiFlexItem>
                      <EuiCheckbox
                        id='targetWebCheck'
                        checked={advancedTargetingOptions.inventorySettings.targetWeb}
                        label='Target Web'
                        onChange={e => {
                          setAdvancedTargetingOptions({ ...advancedTargetingOptions, inventorySettings: { ...advancedTargetingOptions.inventorySettings, targetWeb: e.target.checked } })
                        }}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiCheckbox
                        id='targetAppCheck'
                        checked={advancedTargetingOptions.inventorySettings.targetApp}
                        label='Target Apps'
                        onChange={e => {
                          setAdvancedTargetingOptions({ ...advancedTargetingOptions, inventorySettings: { ...advancedTargetingOptions.inventorySettings, targetApp: e.target.checked } })
                        }}
                      />
                    </EuiFlexItem>
                  </EuiFlexGrid>
                </EuiFormRow>
              )}
              {campaign?.advancedTargeting && (
                <EuiFormRow label='Device'>
                  <EuiFlexGrid direction='column'>
                    <EuiFlexItem>
                      <EuiCheckbox
                        id='targetPcCheck'
                        checked={advancedTargetingOptions.deviceSettings.targetPc}
                        label='Target PC'
                        onChange={e => {
                          setAdvancedTargetingOptions({ ...advancedTargetingOptions, deviceSettings: { ...advancedTargetingOptions.deviceSettings, targetPc: e.target.checked } })
                        }}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiCheckbox
                        id='targetPhoneCheck'
                        checked={advancedTargetingOptions.deviceSettings.targetPhone}
                        label='Target Phone'
                        onChange={e => {
                          setAdvancedTargetingOptions({ ...advancedTargetingOptions, deviceSettings: { ...advancedTargetingOptions.deviceSettings, targetPhone: e.target.checked } })
                        }}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiCheckbox
                        id='targetTabletCheck'
                        checked={advancedTargetingOptions.deviceSettings.targetTablet}
                        label='Target Tablet'
                        onChange={e => {
                          setAdvancedTargetingOptions({ ...advancedTargetingOptions, deviceSettings: { ...advancedTargetingOptions.deviceSettings, targetTablet: e.target.checked } })
                        }}
                      />
                    </EuiFlexItem>
                  </EuiFlexGrid>
                </EuiFormRow>
              )}
              <EuiFormRow label='Frequency Capping (0 is unlimited)'>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFieldNumber
                      name='frequency'
                      value={advancedTargetingOptions.frequency}
                      onChange={e => {
                        setAdvancedTargetingOptions({ ...advancedTargetingOptions, frequency: e.target.valueAsNumber })
                      }}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiText size='xs'>per</EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiSelect
                      options={frequencyOptions}
                      value={advancedTargetingOptions.frequencyUnit}
                      onChange={e => {
                        setAdvancedTargetingOptions({ ...advancedTargetingOptions, frequencyUnit: e.target.value as any as FrequencyUnit })
                      }}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFormRow>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <EuiButton onClick={onUpdateCampaign} isLoading={updateTargetingRequest.isLoading} fill>
        Save
      </EuiButton>
    </React.Fragment>
  )
}
