import { Moment } from 'moment/moment'

import { BaseBundle } from 'api/interfaces/Bundle'
import { CampaignType } from 'api/interfaces/Campaign'

export interface SelfServicePackage extends BaseBundle {
  selfServicePackageStatus: SelfServicePackageStatus
  selfServicePackageType: SelfServicePackageType
  matchingPercentage?: number
  minimumAmount?: number
  maximumAmount?: number
  paidSlotCampaignTypes: CampaignType[]
  matchingSlotCampaignTypes: CampaignType[]
  expirationDate?: Moment
  salesTitle: string
  salesShortDescription: string
  salesDescription: string
}

export interface SelfServicePackageReserveRequest {
  accountId: string
  bundleId: string
  name: string
  paymentMethodId: string
}

export enum SelfServicePackageType {
  Specific = 'Specific',
  Percent = 'Percent'
}

export enum SelfServicePackageStatus {
  Reserved = 'Reserved',
  Available = 'Available',
  Ready = 'Ready',
  Launching = 'Launching',
  Live = 'Live',
  EndingOn = 'EndingOn',
  Ended = 'Ended',
  Expired = 'Expired',
  PaymentFailed = 'PaymentFailed'
}
