import React from 'react'
import { BuilderCampaignType } from 'workflows/CampaignCreateWorkflow'

import { EuiCheckbox, EuiFieldNumber, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSelect, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { FrequencyUnit } from 'api/interfaces'
import { CampaignDelivery } from 'api/interfaces/CampaignDelivery'
import { DayPartPicker, DefaultDayParts } from 'components/targeters/DayPartPicker'

export interface IDeliveryTargeter {
  deliveryDetails: CampaignDelivery
  setDeliveryDetails: (delivery: CampaignDelivery) => void
  campaignType: BuilderCampaignType
  title?: string
}

export const DeliveryTargeter: React.FC<IDeliveryTargeter> = props => {
  const { deliveryDetails, setDeliveryDetails, title, campaignType } = props

  const frequencyOptions = [
    { value: FrequencyUnit.hour, text: 'Hour' },
    { value: FrequencyUnit.day, text: 'Day' },
    { value: FrequencyUnit.week, text: 'Week' }
  ]

  return (
    <React.Fragment>
      <EuiSpacer size='xl' />
      <EuiTitle size='s'>
        <span>{title}</span>
      </EuiTitle>
      <EuiSpacer size='s' />
      <EuiFormRow fullWidth label={'Select which hours of the day your ad will run. (Uses the audience time zone)'}>
        <DayPartPicker
          dayParts={deliveryDetails.dayParts ?? DefaultDayParts}
          onSelectedDayPartsChange={days => {
            setDeliveryDetails({ ...deliveryDetails, dayParts: days })
          }}
        />
      </EuiFormRow>
      <EuiSpacer size='s' />
      {(campaignType === BuilderCampaignType.Native || campaignType == BuilderCampaignType.Display) && (
        <React.Fragment>
          <EuiFormRow label='Inventory'>
            <EuiFlexGrid direction='column'>
              <EuiFlexItem>
                <EuiCheckbox
                  id='targetWebCheck'
                  checked={deliveryDetails.inventorySettings?.targetWeb}
                  label='Target Web'
                  onChange={() => {
                    setDeliveryDetails({
                      ...deliveryDetails,
                      inventorySettings: { ...deliveryDetails.inventorySettings, targetWeb: !deliveryDetails.inventorySettings?.targetWeb }
                    })
                  }}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id='targetAppCheck'
                  checked={deliveryDetails.inventorySettings?.targetApp}
                  label='Target Apps'
                  onChange={() => {
                    setDeliveryDetails({
                      ...deliveryDetails,
                      inventorySettings: { ...deliveryDetails.inventorySettings, targetApp: !deliveryDetails.inventorySettings?.targetApp }
                    })
                  }}
                />
              </EuiFlexItem>
            </EuiFlexGrid>
          </EuiFormRow>
          <EuiFormRow label='Device'>
            <EuiFlexGrid direction='column'>
              <EuiFlexItem>
                <EuiCheckbox
                  id='targetPcCheck'
                  checked={deliveryDetails.deviceSettings?.targetPc}
                  label='Target PC'
                  onChange={() => {
                    setDeliveryDetails({
                      ...deliveryDetails,
                      deviceSettings: { ...deliveryDetails.deviceSettings, targetPc: !deliveryDetails.deviceSettings?.targetPc }
                    })
                  }}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id='targetPhoneCheck'
                  checked={deliveryDetails.deviceSettings?.targetPhone}
                  label='Target Phone'
                  onChange={() => {
                    setDeliveryDetails({
                      ...deliveryDetails,
                      deviceSettings: { ...deliveryDetails.deviceSettings, targetPhone: !deliveryDetails.deviceSettings?.targetPhone }
                    })
                  }}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id='targetTabletCheck'
                  checked={deliveryDetails.deviceSettings?.targetTablet}
                  label='Target Tablet'
                  onChange={() => {
                    setDeliveryDetails({
                      ...deliveryDetails,
                      deviceSettings: { ...deliveryDetails.deviceSettings, targetTablet: !deliveryDetails.deviceSettings?.targetTablet }
                    })
                  }}
                />
              </EuiFlexItem>
            </EuiFlexGrid>
          </EuiFormRow>
        </React.Fragment>
      )}
      <EuiFormRow label='Frequency Capping (0 is unlimited)'>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFieldNumber
              name='frequency'
              value={deliveryDetails.frequency}
              onChange={e => {
                setDeliveryDetails({ ...deliveryDetails, frequency: e.target.valueAsNumber })
              }}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText size='xs'>per</EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiSelect
              options={frequencyOptions}
              value={deliveryDetails.frequencyUnit}
              onChange={e => {
                setDeliveryDetails({ ...deliveryDetails, frequencyUnit: e.target.value as any as FrequencyUnit })
              }}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
    </React.Fragment>
  )
}
