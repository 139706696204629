import React from 'react'

import { EuiListGroup, EuiListGroupItem } from '@elastic/eui'

import { GeoFence } from 'api/interfaces'

interface GeoFenceTargeterProps {
  geoFence: Array<GeoFence>

  onGeoFenceRemoved(geoFence: GeoFence): void
}

export const GeoFenceTargeter = ({ geoFence, onGeoFenceRemoved }: GeoFenceTargeterProps) => {
  return (
    <React.Fragment>
      {geoFence && geoFence.length > 0 && (
        <EuiListGroup bordered={true} maxWidth={false} size='s'>
          {geoFence.map(w => (
            <EuiListGroupItem
              size='s'
              key={w.placeId.toString()}
              label={w.name}
              extraAction={{
                onClick: () => {
                  onGeoFenceRemoved(w)
                },
                iconType: 'cross',
                iconSize: 's',
                'aria-label': 'Remove',
                alwaysShow: true
              }}
            />
          ))}
        </EuiListGroup>
      )}
    </React.Fragment>
  )
}
