import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCallOut, EuiCard, EuiCheckbox, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiHideFor, EuiHorizontalRule, EuiLink, EuiSelect, EuiShowFor, EuiSpacer, EuiSwitch, EuiText, EuiTitle, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'

import { apiPutAccountAddress, apiPutAccountBusinessType } from 'api/adcritterApi'
import { BusinessType, StandardTargeting } from 'api/interfaces'
import { SmartTargeterAreaType } from 'api/interfaces/CampaignTargeting'
import { updateAccountState } from 'app/appSlice'
import { RootState } from 'app/rootReducer'
import Competitors from 'components/Competitors'
import WalkInAddresses from 'components/WalkInAddresses'
import { BusinessTypeFinder } from 'components/finders/BusinessTypeFinder'
import history from 'services/HistoryService'

import { BuilderStyle, setBusinessType, setStandardTargeting } from './builderInternetRetailSlice'

const TargetingStandardStep: React.FC = () => {
  const dispatch = useDispatch()
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const builder = useSelector((state: RootState) => state.builderInternetRetail)
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const [getAddress, setGetAddress] = useState(true)
  const [getStateOnly, setGetStateOnly] = useState(false)
  const [chosenBusinessType, setChosenBusinessType] = useState<BusinessType | null>(null)
  const [showBusinessType, setShowBusinessType] = useState(false)
  const [businessTypeError, setBusinessTypeError] = useState(false)
  const [initialValues, setInitialValues] = useState<StandardTargeting>({
    competitors: [],
    competitorTargeting: false,
    targetArea: SmartTargeterAreaType.Mile5,
    streetAddress: '',
    city: '',
    state: 'AL',
    zip: '',
    walkInAddresses: [],
    walkInTargeting: false,
    walkInUsePrimary: true
  })

  const standardTargetingSchema = Yup.object().shape({
    targetArea: Yup.string().required('Please Select the Targeting Distance'),
    streetAddress: Yup.string()
      .nullable()
      .when('targetArea', {
        is: (targetArea: string) => targetArea === SmartTargeterAreaType.Mile5 || targetArea === SmartTargeterAreaType.Mile20 || targetArea === SmartTargeterAreaType.Mile50,
        then: Yup.string().required('Please Enter your Street Address')
      }),
    city: Yup.string()
      .nullable()
      .when('targetArea', {
        is: (targetArea: string) => targetArea === SmartTargeterAreaType.Mile5 || targetArea === SmartTargeterAreaType.Mile20 || targetArea === SmartTargeterAreaType.Mile50,
        then: Yup.string().required('Please Enter your City')
      }),
    state: Yup.string().when('targetArea', {
      is: (targetArea: string) => targetArea === SmartTargeterAreaType.Mile5 || targetArea === SmartTargeterAreaType.Mile20 || targetArea === SmartTargeterAreaType.Mile50 || targetArea === SmartTargeterAreaType.State,
      then: Yup.string().required('Please Enter your State').nullable()
    }),
    zip: Yup.string()
      .nullable()
      .when('targetArea', {
        is: (targetArea: string) => targetArea === SmartTargeterAreaType.Mile5 || targetArea === SmartTargeterAreaType.Mile20 || targetArea === SmartTargeterAreaType.Mile50,
        then: Yup.string().required('Please Enter your Zip')
      }),
    walkInTargeting: Yup.boolean().required(),
    walkInUsePrimary: Yup.boolean().required(),
    walkInAddresses: Yup.array().when(['walkInTargeting', 'walkInUsePrimary'], {
      is: (walkInTargeting: boolean, walkInUsePrimary: boolean) => walkInTargeting && !walkInUsePrimary,
      then: Yup.array()
        .min(1, 'Please Enter at Least 1 Walk-in Address')
        .of(
          Yup.object().shape({
            streetAddress: Yup.string().required('Please Enter a Street Address'),
            city: Yup.string().required('Please Enter a City'),
            state: Yup.string().required('Please select a State'),
            zip: Yup.string().required('Please Enter a Zip')
          })
        ),
      otherwise: Yup.array().of(
        Yup.object().shape({
          streetAddress: Yup.string().required('Please Enter a Street Address'),
          city: Yup.string().required('Please Enter a City'),
          state: Yup.string().required('Please select a State'),
          zip: Yup.string().required('Please Enter a Zip')
        })
      )
    }),
    competitorTargeting: Yup.boolean().required(),
    competitors: Yup.array().when('competitorTargeting', {
      is: true,
      then: Yup.array()
        .min(1, 'Please Enter at Least 1 Competitor Address')
        .of(
          Yup.object().shape({
            name: Yup.string().max(64).required('Please Enter the Competitor Name'),
            streetAddress: Yup.string().max(120).required('Please Enter a Street Address'),
            city: Yup.string().max(150).required('Please Enter a City'),
            state: Yup.string().required('Please Select a State'),
            zip: Yup.string().max(15).required('Please Enter a Zip')
          })
        ),
      otherwise: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().max(64),
          streetAddress: Yup.string().max(120),
          city: Yup.string().max(150),
          state: Yup.string(),
          zip: Yup.string().max(15)
        })
      )
    }),
    specialities: Yup.array().of(Yup.string())
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: standardTargetingSchema,
    onSubmit: (values: StandardTargeting) => {
      if (showBusinessType && chosenBusinessType === null) {
        setBusinessTypeError(true)
        formik.setSubmitting(false)
        return
      }
      dispatch(setStandardTargeting(values))
      if (chosenBusinessType) dispatch(setBusinessType(chosenBusinessType))
      if ((!currentAccount!.businessTypeId || currentAccount!.businessTypeId === '') && chosenBusinessType) apiPutAccountBusinessType(currentAccount!.id, chosenBusinessType!.id).then(result => dispatch(updateAccountState(result.data)))
      if (!currentAccount!.streetAddress || currentAccount!.streetAddress === '') apiPutAccountAddress(currentAccount!.id, values.streetAddress!, values.city!, values.state!, values.zip!, 'usa').then(result => dispatch(updateAccountState(result.data)))
      history.push('/build/internet/public/deliver')
    }
  })

  useEffect(() => {
    if (builder.businessType) {
      setChosenBusinessType(builder.businessType)
    }
    if (currentAccount && builder.builderStyle) {
      if (builder.builderStyle === BuilderStyle.Suggested) {
        setShowBusinessType(false)
      } else if (builder.builderStyle === BuilderStyle.Build || builder.builderStyle === BuilderStyle.Upload) {
        setShowBusinessType(true)
      }
    }
  }, [currentAccount, builder.builderStyle, builder.businessType])

  useEffect(() => {
    if (builder.standardTargeting) {
      let values = { ...builder.standardTargeting }
      if ((!values.streetAddress || values.streetAddress === '') && currentAccount && currentAccount.streetAddress !== '') {
        values.streetAddress = currentAccount.streetAddress ?? ''
        values.city = currentAccount.city ?? ''
        values.state = currentAccount.state ?? ''
        values.zip = currentAccount.zip ?? ''
      }
      setInitialValues(values)
    } else {
      let values = { ...initialValues }
      if ((!values.streetAddress || values.streetAddress === '') && currentAccount && currentAccount.streetAddress !== '') {
        values.streetAddress = currentAccount.streetAddress ?? ''
        values.city = currentAccount.city ?? ''
        values.state = currentAccount.state ?? ''
        values.zip = currentAccount.zip ?? ''
      }
      setInitialValues(values)
      setGetAddress(values.targetArea === SmartTargeterAreaType.Mile5 || values.targetArea === SmartTargeterAreaType.Mile20 || values.targetArea === SmartTargeterAreaType.Mile50)
      setGetStateOnly(values.targetArea === SmartTargeterAreaType.State)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [builder.standardTargeting, currentAccount])

  const returnTargetingChooseStep = () => {
    history.push('/build/internet/public/target')
  }

  const targetAreaOptions: Array<EuiSelectOption> = [
    {
      value: SmartTargeterAreaType.Mile5,
      text: 'Up to 5 miles'
    },
    {
      value: SmartTargeterAreaType.Mile20,
      text: 'Up to 20 miles'
    },
    {
      value: SmartTargeterAreaType.Mile50,
      text: 'Up to 50 miles'
    },
    {
      value: SmartTargeterAreaType.State,
      text: 'Statewide'
    },
    {
      value: SmartTargeterAreaType.Country,
      text: 'Entire USA'
    }
  ]

  const onTargetAreaChanged = (v: string) => {
    formik.setFieldValue('targetArea', v, true)
    setGetAddress(v === SmartTargeterAreaType.Mile5 || v === SmartTargeterAreaType.Mile20 || v === SmartTargeterAreaType.Mile50)
    setGetStateOnly(v === SmartTargeterAreaType.State)
  }

  const chooseBusinessType = (businessType: BusinessType | null) => {
    if (businessType) {
      setChosenBusinessType(businessType)
      setBusinessTypeError(false)
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Smart Targeter</title>
      </Helmet>
      <EuiFlexGroup>
        <EuiFlexItem>
          {showBusinessType && (
            <React.Fragment>
              <EuiTitle size='s'>
                <h3>Smart Targeter</h3>
              </EuiTitle>
              <EuiSpacer />
              <EuiCallOut title="Let's get started with Smart Targeter" iconType='user' color='success'>
                <p>Select your business type to use professionally designed targeting for your campaign.</p>
              </EuiCallOut>
              <EuiSpacer />
              <EuiFormRow label='Business Type' fullWidth isInvalid={businessTypeError}>
                <React.Fragment>
                  {chosenBusinessType && (
                    <React.Fragment>
                      <EuiCard
                        display='primary'
                        layout='horizontal'
                        paddingSize='s'
                        titleSize='xs'
                        icon={
                          <div>
                            <img alt='' src={chosenBusinessType.imageUrl} />
                          </div>
                        }
                        title={chosenBusinessType.name}
                        description={chosenBusinessType.businessTypeCategories.join(', ')}
                      />
                      <EuiSpacer size='s' />
                      <EuiLink color='success' onClick={() => setChosenBusinessType(null)}>
                        Clear selected business type
                      </EuiLink>
                    </React.Fragment>
                  )}
                  {!chosenBusinessType && <BusinessTypeFinder onBusinessTypeClicked={chooseBusinessType} isInvalid={businessTypeError} />}
                </React.Fragment>
              </EuiFormRow>
            </React.Fragment>
          )}

          {chosenBusinessType && (
            <React.Fragment>
              <EuiSpacer />
              <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  {builder.image && (
                    <React.Fragment>
                      <EuiSpacer size='s' />
                      {builder.image.isPurchased && (
                        <div
                          style={{
                            width: 82,
                            height: (82 / (builder.image.thumbnailWidth || 1)) * (builder.image.thumbnailHeight || 0),
                            borderRadius: 8,
                            marginTop: 10,
                            overflow: 'hidden'
                          }}>
                          <img src={builder.image.thumbnailUrl} alt={builder.image.id} width={82} />
                        </div>
                      )}
                      {!builder.image.isPurchased && <img src={builder.image.thumbnailUrl} alt={builder.image.id} style={{ width: 82, borderRadius: 8, marginTop: 10 }} />}
                      <EuiSpacer size={'s'} />
                    </React.Fragment>
                  )}
                  {builder.displayAd && (
                    <React.Fragment>
                      <EuiSpacer size='s' />
                      <div
                        style={{
                          width: 82,
                          height: (82 / (builder.displayAd.width || 1)) * (builder.displayAd.height || 0),
                          borderRadius: 8,
                          overflow: 'hidden'
                        }}>
                        <img src={builder.displayAd.uploadedUrl} alt={'display ad'} width={82} />
                      </div>
                      <EuiSpacer size={'s'} />
                    </React.Fragment>
                  )}
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiText size='s'>
                    <h3>Smart Targeter</h3>
                  </EuiText>
                  <EuiText size='s'>
                    <p>AdCritter will target your ads to your most likely customers based on your selected business type. We use machine learning to continually adjust and target individuals using their interest and demographic data, browsing history, search history, and past purchase behavior.</p>
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            </React.Fragment>
          )}

          <EuiHorizontalRule />
          <FormikProvider value={formik}>
            <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
              <React.Fragment>
                <EuiText size='m'>
                  <h4>Target Area</h4>
                </EuiText>
                <EuiText size='s'>
                  <p>How large of an area do you want to reach?</p>
                </EuiText>
                <EuiSpacer size='s' />
                <EuiSelect name='targetArea' options={targetAreaOptions} value={formik.values.targetArea} onChange={e => onTargetAreaChanged(e.target.value)} fullWidth isInvalid={formik.touched.targetArea && !!formik.errors.targetArea} hasNoInitialSelection={true} />
                <EuiSpacer size='s' />
                <div hidden={!getAddress} style={{ marginLeft: 53 }}>
                  <EuiSpacer size='s' />
                  <EuiText size='m'>
                    <h4>From this Location</h4>
                  </EuiText>
                  <EuiSpacer size='s' />
                  <EuiFormRow label='Street Address' fullWidth isInvalid={formik.touched.streetAddress && !!formik.errors.streetAddress} error={formik.errors.streetAddress}>
                    <EuiFieldText name='streetAddress' value={formik.values.streetAddress} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='' fullWidth isInvalid={formik.touched.streetAddress && !!formik.errors.streetAddress} />
                  </EuiFormRow>
                  <EuiSpacer size='s' />

                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiFormRow label='City' fullWidth isInvalid={formik.touched.city && !!formik.errors.city} error={formik.errors.city}>
                        <EuiFieldText name='city' value={formik.values.city} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='' fullWidth isInvalid={formik.touched.city && !!formik.errors.city} />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFormRow id='targetAreaState' label='State' fullWidth isInvalid={formik.touched.state && !!formik.errors.state} error={formik.errors.state}>
                        <EuiSelect
                          name='state'
                          isLoading={dictionary.isLoadingStates}
                          options={dictionary.states.map(
                            s =>
                              ({
                                value: s.code,
                                label: s.name,
                                text: s.name
                              } as EuiSelectOption)
                          )}
                          value={formik.values.state}
                          onChange={value => formik.setFieldValue('state', value.target.value, true)}
                          onBlur={formik.handleBlur}
                          fullWidth
                          isInvalid={formik.touched.state && !!formik.errors.state}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFormRow label='Zip' fullWidth isInvalid={formik.touched.zip && !!formik.errors.zip} error={formik.errors.zip}>
                        <EuiFieldText name='zip' value={formik.values.zip} onChange={formik.handleChange} placeholder='' fullWidth isInvalid={formik.touched.zip && !!formik.errors.zip} />
                      </EuiFormRow>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </div>
                <div hidden={!getStateOnly} id='hiddenSatewideDiv'>
                  <EuiSpacer size='m' />
                  <EuiFormRow id='statewide' label='State' fullWidth isInvalid={formik.touched.state && !!formik.errors.state} error={formik.errors.state}>
                    <EuiSelect
                      name='state'
                      isLoading={dictionary.isLoadingStates}
                      options={dictionary.states.map(
                        s =>
                          ({
                            value: s.code,
                            label: s.name,
                            text: s.name
                          } as EuiSelectOption)
                      )}
                      value={formik.values.state}
                      onChange={value => formik.setFieldValue('state', value.target.value, true)}
                      onBlur={formik.handleBlur}
                      fullWidth
                      isInvalid={formik.touched.state && !!formik.errors.state}
                    />
                  </EuiFormRow>
                </div>
                <EuiHorizontalRule />
                <EuiTitle size='xs'>
                  <h6 id='walkInTarget'>
                    <EuiSwitch
                      label=''
                      checked={formik.values.walkInTargeting}
                      onChange={e => {
                        formik.setFieldValue('walkInTargeting', e.target.checked, true)
                        if (!e.target.checked) formik.setFieldValue('walkInAddresses', [], false)
                      }}
                    />
                    Walk-in Customer Targeting
                  </h6>
                </EuiTitle>
                <div style={{ paddingLeft: 53 }}>
                  <EuiText size='s'>
                    <p>Targets individuals who’ve visited your location within the past sixty days.</p>
                  </EuiText>
                </div>
                <EuiSpacer size='s' />
                <div hidden={!formik.values.walkInTargeting} style={{ paddingLeft: 53 }}>
                  <div hidden={!getAddress}>
                    <EuiSpacer size='s' />
                    <EuiCheckbox id={htmlIdGenerator()()} label={<React.Fragment>Target people who visit the above address</React.Fragment>} checked={formik.values.walkInUsePrimary} onChange={e => (formik.values.walkInUsePrimary = e.target.checked)} />
                  </div>
                  <EuiSpacer size='s' />
                  <EuiCallOut color='danger' hidden={formik.values.walkInAddresses.length !== 0 || formik.values.walkInUsePrimary} size='s' title={'Please Enter at Least 1 Walk-in Address, or Disable Walk-in Customer Targeting'} iconType='listAdd' style={{ marginBottom: 12 }} />
                  <WalkInAddresses formik={formik} />
                </div>
                <EuiSpacer size='xl' />
                <EuiTitle size='xs'>
                  <h6 id='competitorTarget'>
                    <EuiSwitch
                      label=''
                      checked={formik.values.competitorTargeting}
                      onChange={e => {
                        formik.setFieldValue('competitorTargeting', e.target.checked, true)
                        if (!e.target.checked) formik.setFieldValue('competitors', [], false)
                      }}
                    />
                    Competitor Location Targeting
                  </h6>
                </EuiTitle>
                <div style={{ paddingLeft: 53 }}>
                  <EuiText size='s'>
                    <p>Targets individuals who’ve been to your competitor’s location(s) within the past sixty days.</p>
                  </EuiText>
                </div>
                <EuiSpacer size='s' />
                <div hidden={!formik.values.competitorTargeting} style={{ paddingLeft: 55 }}>
                  <EuiCallOut color='danger' hidden={formik.values.competitors.length !== 0} size='s' title={'Please Enter at Least 1 Competitor Address, or Disable Competitor Location Targeting'} iconType='listAdd' style={{ marginBottom: 12 }} />
                  <Competitors formik={formik} />
                </div>
              </React.Fragment>
              <EuiSpacer />

              <EuiShowFor sizes={['xs', 's']}>
                <EuiFlexGroup>
                  <EuiFlexItem grow={false}>
                    <EuiButton id='finalize' fill type='submit' isLoading={formik.isSubmitting}>
                      Continue
                    </EuiButton>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiButton id='back' fill type='button' color='text' onClick={returnTargetingChooseStep}>
                      Back
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiShowFor>

              <EuiHideFor sizes={['xs', 's']}>
                <EuiFlexGroup responsive={false}>
                  <EuiFlexItem grow={false}>
                    <EuiButton id='back' fill type='button' color='text' onClick={returnTargetingChooseStep} iconType='arrowLeft' iconSide='left'>
                      Back
                    </EuiButton>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiButton id='finalize' fill type='submit' isLoading={formik.isSubmitting} iconType='arrowRight' iconSide='right'>
                      Continue
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiHideFor>
            </EuiForm>
          </FormikProvider>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default TargetingStandardStep
