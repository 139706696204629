import React from 'react'
import { useSelector } from 'react-redux'

import { EuiFlexGrid, EuiLoadingContent, EuiShowFor } from '@elastic/eui'

import { TvCommercial } from 'api/interfaces'
import { useGetTvCommercialsQuery } from 'api/rtkQueryApi/platform/creativesApi'
import { RootState } from 'app/rootReducer'
import { VideoCard } from 'components/suggestedAds/VideoCard'

export interface ITvSuggestedAds {
  businessTypeId: string
  onSelected: (commercial: TvCommercial) => void
}

export const TvSuggestedAds: React.FC<ITvSuggestedAds> = props => {
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const tvCommercialsQuery = useGetTvCommercialsQuery({ accountId: currentAccount?.id ?? '', businessTypeId: props.businessTypeId }, { skip: !currentAccount })

  if (tvCommercialsQuery.isLoading || !tvCommercialsQuery.data) {
    return (
      <EuiLoadingContent>
        <p>Loading your ads...</p>
      </EuiLoadingContent>
    )
  }

  const onSelected = (commercial: TvCommercial) => {
    props.onSelected(commercial)
  }

  return (
    <React.Fragment>
      <EuiShowFor sizes={['s', 'xs']}>
        <EuiFlexGrid columns={1} responsive={true}>
          {tvCommercialsQuery.data.map((t, i) => (
            <VideoCard
              key={i}
              tvCommercial={t}
              onSelected={() => {
                onSelected(t)
              }}
            />
          ))}
        </EuiFlexGrid>
      </EuiShowFor>
      <EuiShowFor sizes={['l', 'm']}>
        <EuiFlexGrid columns={2} responsive={true}>
          {tvCommercialsQuery.data.map((t, i) => (
            <VideoCard
              key={i}
              tvCommercial={t}
              onSelected={() => {
                onSelected(t)
              }}
            />
          ))}
        </EuiFlexGrid>
      </EuiShowFor>
      <EuiShowFor sizes={['xl']}>
        <EuiFlexGrid columns={3} responsive={true}>
          {tvCommercialsQuery.data.map((t, i) => (
            <VideoCard
              key={i}
              tvCommercial={t}
              onSelected={() => {
                onSelected(t)
              }}
            />
          ))}
        </EuiFlexGrid>
      </EuiShowFor>
    </React.Fragment>
  )
}
