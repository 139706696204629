import React, { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { EuiFlexGroup, EuiFlexItem, EuiHideFor, EuiPageBody, EuiPageHeader, EuiPageHeaderSection, EuiPanel, EuiSpacer, EuiStepsHorizontal, EuiTitle } from '@elastic/eui'
import { EuiStepHorizontalProps } from '@elastic/eui/src/components/steps/step_horizontal'

import { showErrorToast } from 'app/appSlice'
import config from 'app/config'
import { RootState } from 'app/rootReducer'
import { ActivateShopifyCampaignStep } from 'features/builders/ActivateShopifyCampaignStep'
import { ActivateVendastaCampaignStep } from 'features/builders/ActivateVendastaCampaignStep'
import history from 'services/HistoryService'
import { AcRequiredFields } from 'utils/AcRequiredFields'
import { CampaignType } from 'utils/CampaignType'

import StartStep from './10_StartStep'
import SuggestStep from './20_SuggestStep'
import QuestionnaireStep from './21_QuestionnaireStep'
import SuggestionsStep from './22_SuggestionsStep'
import CreateStep from './30_CreateStep'
import UploadStep from './31_UploadStep'
import TargetStep from './40_TargetStep'
import ActivateStep from './99_ActivateStep'
import { BuilderStep, setBuilderStep } from './builderBillboardRetailSlice'

interface BuilderPageParams {
  step: string
}

const Page: React.FC = () => {
  const dispatch = useDispatch()
  const { step } = useParams<BuilderPageParams>()
  const builder = useSelector((state: RootState) => state.builderBillboardRetail)
  const [content, setContent] = useState<ReactNode | null>()
  const { currentAccount, isVendastaUser, isShopifyUser } = useSelector((state: RootState) => state.app)

  useEffect(() => {
    const failureRedirect = () => {
      history.push('/build')
      dispatch(showErrorToast("Something didn't go as expected. Please try again."))
    }

    switch (step) {
      default:
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            setContent(<StartStep />)
            dispatch(setBuilderStep(BuilderStep.Start))
          },
          () => {
            history.push('campaigns')
          }
        )
        break
      case 'suggest':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            if (config.features.billboardSuggestionsEnabled) {
              if (isShopifyUser) {
                setContent(<SuggestionsStep />)
                dispatch(setBuilderStep(BuilderStep.Suggestions))
              } else {
                setContent(<SuggestStep />)
                dispatch(setBuilderStep(BuilderStep.Suggest))
              }
            }
          },
          failureRedirect
        )
        break
      case 'questionnaire':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            setContent(<QuestionnaireStep />)
            dispatch(setBuilderStep(BuilderStep.Questionnaire))
          },
          failureRedirect
        )
        break
      case 'suggestions':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            if (config.features.billboardSuggestionsEnabled) {
              setContent(<SuggestionsStep />)
              dispatch(setBuilderStep(BuilderStep.Suggestions))
            }
          },
          failureRedirect
        )
        break
      case 'create':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            if (config.features.billboardSuggestionsEnabled) {
              setContent(<CreateStep />)
              dispatch(setBuilderStep(BuilderStep.Create))
            }
          },
          failureRedirect
        )
        break
      case 'upload':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            setContent(<UploadStep />)
            dispatch(setBuilderStep(BuilderStep.Upload))
          },
          failureRedirect
        )
        break
      case 'target':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            setContent(<TargetStep />)
            dispatch(setBuilderStep(BuilderStep.Target))
          },
          failureRedirect
        )
        break
      case 'activate':
        AcRequiredFields(
          [currentAccount?.id],
          () => {
            if (isVendastaUser) {
              setContent(<ActivateVendastaCampaignStep campaignType={CampaignType.Billboard} />)
            } else if (isShopifyUser) {
              setContent(<ActivateShopifyCampaignStep campaignType={CampaignType.Billboard} />)
            } else {
              setContent(<ActivateStep />)
            }
            dispatch(setBuilderStep(BuilderStep.Activate))
          },
          failureRedirect
        )
        break
    }
  }, [step, dispatch, currentAccount])

  const onCreateAdClick = () => {
    dispatch(setBuilderStep(BuilderStep.Start))
  }

  const onSetTargetingClick = () => {
    dispatch(setBuilderStep(BuilderStep.Target))
  }

  const onAdPlacementClick = () => {
    dispatch(setBuilderStep(BuilderStep.Activate))
  }

  const wizardSteps: Array<EuiStepHorizontalProps> = [
    {
      title: 'Create',
      isSelected: builder.builderStep >= 10 && builder.builderStep < 33,
      isComplete: builder.builderStep >= 33,
      disabled: builder.builderStep < 10,
      onClick: onCreateAdClick
    },
    {
      title: 'Target',
      isSelected: builder.builderStep >= 40 && builder.builderStep < 50,
      isComplete: builder.builderStep >= 44,
      disabled: builder.builderStep < 20,
      onClick: onSetTargetingClick
    },
    {
      title: 'Activate',
      isSelected: builder.builderStep >= 50,
      isComplete: builder.builderStep >= 51,
      disabled: builder.builderStep < 30,
      onClick: onAdPlacementClick
    }
  ]

  return (
    <React.Fragment>
      <EuiPageBody>
        <EuiPageHeader>
          <EuiPageHeaderSection>
            <EuiTitle>
              <h1>New Billboard Campaign</h1>
            </EuiTitle>
          </EuiPageHeaderSection>
        </EuiPageHeader>
        <EuiPanel grow={false} className='eui-xScroll'>
          <EuiHideFor sizes={['s']}>
            <EuiFlexGroup alignItems={'center'}>
              <EuiFlexItem>
                <EuiStepsHorizontal steps={wizardSteps} />
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size='m' />
          </EuiHideFor>
          {content}
        </EuiPanel>
      </EuiPageBody>
    </React.Fragment>
  )
}

export default Page
