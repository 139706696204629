import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { CampaignSlot, SelfServicePackage, SelfServicePackageType } from 'api/interfaces'

export const useCampaignSlotsState = (selfServicePackage: SelfServicePackage) => {
  const packageBudgetState = useState<number | undefined>(selfServicePackage.price > 0 ? selfServicePackage.price : selfServicePackage.minimumAmount)
  const [packageBudget, setPackageBudget] = packageBudgetState

  const paidCampaignSlotsState = useState<CampaignSlot[]>([])
  const [paidCampaignSlots, setPaidCampaignSlots] = paidCampaignSlotsState

  const matchingCampaignSlotsState = useState<CampaignSlot[]>([])
  const [matchingCampaignSlots, setMatchingCampaignSlots] = matchingCampaignSlotsState

  const selfServicePackageType = selfServicePackage.selfServicePackageType
  const matchingPercentage = selfServicePackage.matchingPercentage ?? 1

  useEffect(() => {
    let paidSlots = selfServicePackage.campaignSlots.filter(slot => !slot.isBonus)
    let matchingSlots = selfServicePackage.campaignSlots.filter(slot => slot.isBonus)

    if (selfServicePackageType === SelfServicePackageType.Percent && packageBudget) {
      if (paidSlots.length <= 0 || paidSlots.every(slot => slot.budget <= 0)) {
        paidSlots = selfServicePackage.paidSlotCampaignTypes.map(
          campaignType =>
            ({
              id: uuidv4(),
              isBonus: false,
              campaignType: campaignType,
              budget: packageBudget / selfServicePackage.paidSlotCampaignTypes.length
            } as CampaignSlot)
        )
      }

      if (matchingSlots.length <= 0 || matchingSlots.every(slot => slot.budget <= 0)) {
        matchingSlots = selfServicePackage.matchingSlotCampaignTypes.map(
          campaignType =>
            ({
              id: uuidv4(),
              isBonus: true,
              campaignType: campaignType,
              budget: (packageBudget * matchingPercentage) / selfServicePackage.matchingSlotCampaignTypes.length
            } as CampaignSlot)
        )
      }
    }

    setPaidCampaignSlots(paidSlots)
    setMatchingCampaignSlots(matchingSlots)
  }, [selfServicePackage.campaignSlots])

  useEffect(() => {
    if (selfServicePackageType !== SelfServicePackageType.Percent || !packageBudget) {
      return
    }

    if (paidCampaignSlots.length > 0) {
      setPaidCampaignSlots(
        paidCampaignSlots.map(
          slot =>
            ({
              id: slot.id,
              campaignId: slot.campaignId,
              isBonus: slot.isBonus,
              campaignType: slot.campaignType,
              budget: packageBudget / paidCampaignSlots.length
            } as CampaignSlot)
        )
      )
    }

    if (matchingCampaignSlots.length > 0) {
      setMatchingCampaignSlots(
        matchingCampaignSlots.map(
          slot =>
            ({
              id: slot.id,
              campaignId: slot.campaignId,
              isBonus: slot.isBonus,
              campaignType: slot.campaignType,
              budget: (packageBudget * matchingPercentage) / matchingCampaignSlots.length
            } as CampaignSlot)
        )
      )
    }
  }, [packageBudget])

  const updateCampaignSlot = (slot: CampaignSlot, campaignId: string | undefined) => {
    if (slot.campaignId === campaignId) {
      return
    }

    if (slot.isBonus) {
      updateCampaignSlotHelper(slot, campaignId, matchingCampaignSlots, setMatchingCampaignSlots)
    } else {
      updateCampaignSlotHelper(slot, campaignId, paidCampaignSlots, setPaidCampaignSlots)
    }
  }

  const updateCampaignSlotHelper = (slot: CampaignSlot, campaignId: string | undefined, campaignSlots: CampaignSlot[], setCampaignSlots: Dispatch<SetStateAction<CampaignSlot[]>>) => {
    setCampaignSlots(
      campaignSlots.map(s => {
        if (s.id === slot.id) {
          return {
            ...s,
            campaignId: campaignId
          }
        } else {
          return s
        }
      })
    )
  }

  return {
    packageBudgetState: [packageBudget, setPackageBudget] as typeof packageBudgetState,
    paidCampaignSlots,
    matchingCampaignSlots,
    updateCampaignSlot
  }
}
