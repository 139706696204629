import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCallOut, EuiCheckbox, EuiFieldNumber, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiHideFor, EuiRadio, EuiSelect, EuiShowFor, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { AdvancedTargeting, CampaignType, City, CityTarget, DayPartitioning, DeviceSettings, DmaTarget, FrequencyUnit, GeoFence, InventorySettings, PermissionType, PostalCodeTarget, RegionTarget, SegmentTarget, Zip } from 'api/interfaces'
import { useGetOrganizationQuery } from 'api/rtkQueryApi/platform/organizationsApi'
import { RootState } from 'app/rootReducer'
import { AcFileUploader } from 'components/Basic/AcFileUploader'
import TargetingMap from 'components/TargetingMap'
import { GeoFenceFinder } from 'components/finders'
import { NewCityFinder } from 'components/finders/NewCityFinder'
import { NewDmaFinder } from 'components/finders/NewDmaFinder'
import { NewPostalCodeFinder } from 'components/finders/NewPostalCodeFinder'
import { NewRegionFinder } from 'components/finders/NewRegionFinder'
import { NewSegmentFinder } from 'components/finders/NewSegmentFinder'
import { GeoFenceTargeter, ZipTargeter } from 'components/targeters'
import { AudienceTargeterDetailed } from 'components/targeters/AudienceTargeterDetailed'
import { DayPartPicker, DefaultDayParts } from 'components/targeters/DayPartPicker'
import { NewCityTargeter } from 'components/targeters/NewCityTargeter'
import { NewDmaTargeter } from 'components/targeters/NewDmaTargeter'
import { NewRegionTargeter } from 'components/targeters/NewRegionTargeter'
import { resetTargetingMapState, setInitialTargetingMapState } from 'components/targetingMapSlice'
import history from 'services/HistoryService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import { AdvancedTargetingType, setAdvancedTargeting } from './builderInternetRetailSlice'

interface FormValues {
  locationType: string
  states: Array<RegionTarget>
  dmas: Array<DmaTarget>
  cities: Array<CityTarget>
  zips: Array<PostalCodeTarget>
  geoFences: Array<GeoFence>
  segments: Array<SegmentTarget>
  inventorySettings: InventorySettings
  deviceSettings: DeviceSettings
  frequency: number
  frequencyUnit: FrequencyUnit
  dayParts: DayPartitioning
}

const TargetAdvancedLocationStep: React.FC = () => {
  const dispatch = useDispatch()
  const organizationQuery = useGetOrganizationQuery(undefined)
  const [uploadedAudienceUrl, setUploadedAudienceUrl] = useState<string>()
  const [isInvalidUpload, setIsInvalidUpload] = useState(false)
  const builder = useSelector((state: RootState) => state.builderInternetRetail)
  const { isGoogleLoaded } = useSelector((state: RootState) => state.app)
  const { updatedGeoCircles, updatedGeoRectangles, updatedGeoPolygons } = useSelector((state: RootState) => state.targetingMap)
  const [initialValues, setInitialValues] = useState<FormValues>({
    locationType: 'Map',
    states: [],
    dmas: [],
    cities: [],
    zips: [],
    geoFences: [],
    segments: [],
    inventorySettings: { targetWeb: true, targetApp: false },
    deviceSettings: { targetPc: true, targetPhone: true, targetTablet: true },
    frequency: 0,
    frequencyUnit: FrequencyUnit.hour,
    dayParts: DefaultDayParts
  })
  const whiteLabel = useWhiteLabel()

  const advancedLocationTargetingSchema = Yup.object().shape({
    locationType: Yup.string().required(),
    states: Yup.array().when('locationType', {
      is: 'State',
      then: Yup.array().min(1, 'Please select at least 1 state').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    dmas: Yup.array().when('locationType', {
      is: 'DMA',
      then: Yup.array().min(1, 'Please select at least 1 metro area / DMA').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    cities: Yup.array().when('locationType', {
      is: 'City',
      then: Yup.array().min(1, 'Please add at least 1 city').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    zips: Yup.array().when('locationType', {
      is: 'Zip',
      then: Yup.array().min(1, 'Please add at least 1 zip').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    geoFences: Yup.array().when('locationType', {
      is: 'Address',
      then: Yup.array().min(1, 'Please add at least 1 address').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    segments: Yup.array().of(Yup.object()),
    inventorySettings: Yup.object().shape({ targetWeb: Yup.boolean(), targetApp: Yup.boolean() }),
    deviceSettings: Yup.object().shape({
      targetPc: Yup.boolean(),
      targetPhone: Yup.boolean(),
      targetTablet: Yup.boolean()
    }),
    frequency: Yup.number(),
    frequencyUnit: Yup.number()
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: advancedLocationTargetingSchema,
    onSubmit: (values: FormValues) => {
      const targeting: AdvancedTargeting = {
        ...builder.advancedTargeting!,
        advancedTargetingType: AdvancedTargetingType.Location,
        locationType: values.locationType,
        locations: {
          countries: [],
          regions: values.states,
          dmas: values.dmas,
          cities: values.cities,
          postalCodes: values.zips,
          uploadedAudienceUrl: values.locationType === 'Upload' ? uploadedAudienceUrl : undefined
        },
        segments: values.segments,
        states: [],
        dmas: [],
        cities: [],
        zips: [],
        geoFences: values.geoFences,
        geoCircles: updatedGeoCircles,
        geoRectangles: updatedGeoRectangles,
        geoPolygons: updatedGeoPolygons,
        audiences: [],
        inventorySettings: whiteLabel?.isAgencies()
          ? {
              targetWeb: values.inventorySettings.targetWeb,
              targetApp: values.inventorySettings.targetApp
            }
          : null,
        deviceSettings: whiteLabel?.isAgencies()
          ? {
              targetPc: values.deviceSettings.targetPc,
              targetPhone: values.deviceSettings.targetPhone,
              targetTablet: values.deviceSettings.targetTablet
            }
          : null,
        frequency: whiteLabel?.isAgencies() ? values.frequency : null,
        frequencyUnit: whiteLabel?.isAgencies() ? values.frequencyUnit : null,
        dayParts: values.dayParts
      }
      dispatch(setAdvancedTargeting(targeting))
      history.push('/build/internet/public/deliver')
    }
  })

  useEffect(() => {
    if (builder.advancedTargeting && builder.advancedTargeting.advancedTargetingType === 'Location') {
      setInitialValues({
        locationType: builder.advancedTargeting!.locationType ?? 'City',
        states: builder.advancedTargeting!.locations!.regions,
        dmas: builder.advancedTargeting!.locations!.dmas,
        cities: builder.advancedTargeting!.locations!.cities,
        zips: builder.advancedTargeting!.locations!.postalCodes,
        geoFences: builder.advancedTargeting!.geoFences,
        segments: builder.advancedTargeting!.segments ?? [],
        inventorySettings: builder.advancedTargeting!.inventorySettings ?? { targetWeb: true, targetApp: false },
        deviceSettings: builder.advancedTargeting!.deviceSettings ?? {
          targetPc: true,
          targetPhone: true,
          targetTablet: true
        },
        frequency: builder.advancedTargeting!.frequency ?? 0,
        frequencyUnit: builder.advancedTargeting!.frequencyUnit ?? FrequencyUnit.hour,
        dayParts: builder.advancedTargeting.dayParts ?? DefaultDayParts
      })

      setUploadedAudienceUrl(builder.advancedTargeting!.locations!.uploadedAudienceUrl)

      dispatch(
        setInitialTargetingMapState({
          initialGeoCircles: builder.advancedTargeting!.geoCircles,
          initialGeoRectangles: builder.advancedTargeting!.geoRectangles,
          initialGeoPolygons: builder.advancedTargeting!.geoPolygons,
          includeBillboards: false
        })
      )
    }

    return () => {
      dispatch(resetTargetingMapState())
    }
  }, [builder.advancedTargeting])

  const returnTargetingChooseStep = () => {
    history.push('/build/internet/public/target')
  }

  const frequencyOptions = [
    { value: FrequencyUnit.hour, text: 'Hour' },
    { value: FrequencyUnit.day, text: 'Day' },
    { value: FrequencyUnit.week, text: 'Week' }
  ]

  return (
    <React.Fragment>
      <Helmet>
        <title>Advanced Location Targeting</title>
      </Helmet>
      <EuiFlexGroup>
        <EuiFlexItem grow={true}>
          <EuiTitle size='s'>
            <h3>Location Targeting</h3>
          </EuiTitle>
          <EuiText size='s'>
            <div>
              Define the location(s) you want to target.
              <br />
              You can target specific geographic areas below and then further target individuals based on specific characteristics in the Traffic Prioritization section below.
            </div>
          </EuiText>
          <EuiSpacer />
          <FormikProvider value={formik}>
            <EuiForm id='locationTargetingForm' component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.status && !!formik.errors} error={formik.status}>
              <EuiRadio
                id='state'
                name='locationType'
                value='State'
                label={
                  <EuiText size='s'>
                    <strong>Target by State</strong> (one or more U.S. States)
                  </EuiText>
                }
                checked={formik.values.locationType === 'State'}
                onChange={() => {
                  formik.setFieldValue('locationType', 'State', true)
                }}
              />
              <EuiSpacer size='s' />
              <EuiRadio
                id='dma'
                name='locationType'
                value='DMA'
                label={
                  <EuiText size='s'>
                    <strong>Target by Metro Area / DMA</strong> (one or more)
                  </EuiText>
                }
                checked={formik.values.locationType === 'DMA'}
                onChange={() => {
                  formik.setFieldValue('locationType', 'DMA', true)
                }}
              />
              <EuiSpacer size='s' />
              <EuiRadio
                id='city'
                name='locationType'
                value='City'
                label={
                  <EuiText size='s'>
                    <strong>Target by City</strong> (one or more)
                  </EuiText>
                }
                checked={formik.values.locationType === 'City'}
                onChange={() => {
                  formik.setFieldValue('locationType', 'City', true)
                }}
              />
              <EuiSpacer size='s' />
              <EuiRadio
                id='zip'
                name='locationType'
                value='Zip'
                label={
                  <EuiText size='s'>
                    <strong>Target by Zip Code</strong> (one or more)
                  </EuiText>
                }
                checked={formik.values.locationType === 'Zip'}
                onChange={() => {
                  formik.setFieldValue('locationType', 'Zip', true)
                }}
              />
              <EuiSpacer size='s' />
              <EuiRadio
                id='address'
                name='locationType'
                value='Address'
                label={
                  <EuiText size='s'>
                    <strong>Target by Street Addresses</strong> (one or more)
                  </EuiText>
                }
                checked={formik.values.locationType === 'Address'}
                onChange={() => {
                  formik.setFieldValue('locationType', 'Address', true)
                }}
              />
              <EuiSpacer size='s' />
              <EuiRadio
                id='map'
                name='locationType'
                value='Map'
                label={
                  <EuiText size='s'>
                    <strong>Target on Map</strong> (draw the areas you want to target on a map)
                  </EuiText>
                }
                checked={formik.values.locationType === 'Map'}
                onChange={() => {
                  formik.setFieldValue('locationType', 'Map', true)
                }}
              />
              {organizationQuery.data?.permissions?.some(p => p.permissionType === PermissionType.UploadAudience) && (
                <React.Fragment>
                  <EuiSpacer size='s' />
                  <EuiRadio
                    id='upload'
                    name='locationType'
                    value='Upload'
                    label={
                      <EuiText size='s'>
                        <strong>Upload Location Audience</strong>
                      </EuiText>
                    }
                    checked={formik.values.locationType === 'Upload'}
                    onChange={() => {
                      formik.setFieldValue('locationType', 'Upload', true)
                    }}
                  />
                </React.Fragment>
              )}
              <EuiSpacer size='xl' />

              <div hidden={formik.values.locationType !== 'State'}>
                <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. states below.' />
                <EuiSpacer size='s' />

                <EuiFormRow id='stateFinder' label='Search for a state' fullWidth isInvalid={!!formik.touched.states && !!formik.errors.states} error={formik.errors.states}>
                  <NewRegionFinder addRegion={regionToAdd => formik.setFieldValue('states', [...formik.values.states, regionToAdd])} isInvalid={!!formik.touched.states && !!formik.errors.states} />
                </EuiFormRow>
                {formik.values.states && formik.values.states.length > 0 && (
                  <EuiFormRow label='Your campaign will target these states' fullWidth>
                    <NewRegionTargeter
                      regions={formik.values.states}
                      onRegionRemoved={regionId => {
                        formik.setFieldValue(
                          'states',
                          formik.values.states.filter(x => x.id !== regionId),
                          true
                        )
                      }}
                    />
                  </EuiFormRow>
                )}
              </div>

              <div hidden={formik.values.locationType !== 'DMA'}>
                <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more metro areas / DMAs below.' />
                <EuiSpacer size='s' />

                <EuiFormRow id='dmaFinder' label='Search for a metro area' fullWidth isInvalid={!!formik.touched.dmas && !!formik.errors.dmas} error={formik.errors.dmas}>
                  <NewDmaFinder addDma={dmaToAdd => formik.setFieldValue('dmas', [...formik.values.dmas, dmaToAdd])} isInvalid={!!formik.touched.dmas && !!formik.errors.dmas} />
                </EuiFormRow>
                {formik.values.dmas && formik.values.dmas.length > 0 && (
                  <EuiFormRow label='Your campaign will target these metro areas' fullWidth>
                    <NewDmaTargeter
                      dmas={formik.values.dmas}
                      onDmaRemoved={dmaId => {
                        formik.setFieldValue(
                          'dmas',
                          formik.values.dmas.filter(x => x.id !== dmaId),
                          true
                        )
                      }}
                    />
                  </EuiFormRow>
                )}
              </div>

              <div hidden={formik.values.locationType !== 'City'}>
                <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. cities by name.' />
                <EuiSpacer size='s' />

                <EuiFormRow id='cityFinder' label='Search for a city' fullWidth isInvalid={!!formik.touched.cities && !!formik.errors.cities} error={formik.errors.cities}>
                  <NewCityFinder onCityClicked={city => formik.setFieldValue('cities', [...formik.values.cities.filter(c => c.id !== city.id), city])} isInvalid={!!formik.touched.cities && !!formik.errors.cities} />
                </EuiFormRow>
                {formik.values.cities && formik.values.cities.length > 0 && (
                  <EuiFormRow label='Your campaign will target these cities' fullWidth>
                    <NewCityTargeter
                      cities={formik.values.cities}
                      onCityRemoved={cityId => {
                        formik.setFieldValue(
                          'cities',
                          formik.values.cities.filter(c => c.id !== cityId)
                        )
                      }}
                    />
                  </EuiFormRow>
                )}
              </div>

              <div hidden={formik.values.locationType !== 'Zip'}>
                <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. zip codes.' />
                <EuiSpacer size='s' />

                <EuiFormRow id='zipFinder' label='Search for a zip code' fullWidth isInvalid={!!formik.touched.zips && !!formik.errors.zips} error={formik.errors.zips}>
                  <NewPostalCodeFinder onPostalCodeClicked={zip => formik.setFieldValue('zips', [...formik.values.zips.filter(z => z.id !== zip.id), zip])} isInvalid={!!formik.touched.zips && !!formik.errors.zips} />
                </EuiFormRow>
                {formik.values.zips && formik.values.zips.length > 0 && (
                  <EuiFormRow label='Your campaign will target these zip codes' fullWidth>
                    <ZipTargeter
                      zips={formik.values.zips.map(z => ({ id: z.id.toString(), name: z.name }))}
                      onZipRemoved={zip => {
                        formik.setFieldValue(
                          'zips',
                          formik.values.zips.filter(z => z.id.toString() !== zip.id)
                        )
                      }}
                    />
                  </EuiFormRow>
                )}
              </div>

              <div hidden={formik.values.locationType !== 'Address'}>
                <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. street addresses.' />
                <EuiSpacer size='s' />

                <EuiFormRow id='addressFinder' label='Search for an address' fullWidth isInvalid={!!formik.touched.geoFences && !!formik.errors.geoFences} error={formik.errors.geoFences}>
                  <GeoFenceFinder onGeoFenceClicked={geoFence => formik.setFieldValue('geoFences', [...formik.values.geoFences.filter(g => g.placeId !== geoFence.placeId), geoFence])} isInvalid={!!formik.touched.geoFences && !!formik.errors.geoFences} />
                </EuiFormRow>
                {formik.values.geoFences && formik.values.geoFences.length > 0 && (
                  <EuiFormRow label='Your campaign will target these addresses' fullWidth>
                    <GeoFenceTargeter
                      geoFence={formik.values.geoFences}
                      onGeoFenceRemoved={geoFence => {
                        formik.setFieldValue(
                          'geoFences',
                          formik.values.geoFences.filter(g => g.placeId !== geoFence.placeId)
                        )
                      }}
                    />
                  </EuiFormRow>
                )}
              </div>

              <div hidden={formik.values.locationType !== 'Map'}>
                <EuiShowFor sizes={['xs', 's']}>
                  <EuiCallOut color='warning' title='Drawing on the map is best experienced on a computer.' iconType='alert' size='s' />
                </EuiShowFor>

                <EuiHideFor sizes={['xs', 's']}>
                  <EuiCallOut size='s' iconType='mapMarker' color='success' title='Use the box, circle and/or polygon tool to draw areas on the map you want to target.' />
                </EuiHideFor>

                <EuiSpacer size='s' />

                {formik.values.locationType === 'Map' && isGoogleLoaded && <TargetingMap />}
              </div>
              {organizationQuery.data?.permissions?.some(p => p.permissionType === PermissionType.UploadAudience) && formik.values.locationType === 'Upload' && (
                <React.Fragment>
                  <EuiCallOut size='s' iconType='filebeatApp' color='success' title='Target your own list' />
                  <EuiSpacer size='s' />
                  <EuiFormRow fullWidth helpText='Please only upload plain text files, with one record per line.'>
                    <AcFileUploader
                      maxFileSize={10000000}
                      allowedFileTypes={['text/plain', 'text/csv']}
                      onUploadedUrlChange={url => {
                        setUploadedAudienceUrl(url)
                      }}
                      onIsValidFileChange={isValid => {
                        setIsInvalidUpload(!isValid)
                      }}
                    />
                  </EuiFormRow>
                  {isInvalidUpload && <EuiCallOut title='Please upload a csv under 10MB' iconType='document' color='warning' />}
                </React.Fragment>
              )}
              <EuiSpacer size='xl' />
              <EuiTitle size='s'>
                <span>Traffic Prioritization</span>
              </EuiTitle>
              <EuiSpacer size='s' />
              <EuiFormRow label='Search for individual characteristics to refine your ideal audience' fullWidth>
                <NewSegmentFinder
                  campaignType={CampaignType.Internet}
                  isInvalid={false}
                  onSegmentClicked={segment => {
                    formik.setFieldValue('segments', [...formik.values.segments.filter(s => s.id !== segment.id), segment])
                  }}
                />
              </EuiFormRow>
              {formik.values.segments && formik.values.segments.length > 0 && (
                <EuiFormRow label='Your campaign will prioritize individuals in any of these categories' fullWidth>
                  <AudienceTargeterDetailed
                    audiences={formik.values.segments.map(s => ({
                      id: s.id,
                      name: s.name,
                      description: s.description ?? '',
                      provider: '',
                      reachCount: -1,
                      price: -1,
                      hierarchy: [],
                      sourceDetails: ''
                    }))}
                    onAudienceRemoved={audience => {
                      formik.setFieldValue(
                        'audiences',
                        formik.values.segments.filter(a => a.id !== audience.id)
                      )
                    }}
                  />
                </EuiFormRow>
              )}
              {whiteLabel?.isAgencies() && (
                <React.Fragment>
                  <EuiSpacer size='xl' />
                  <EuiTitle size='s'>
                    <span>Advanced Targeting Options</span>
                  </EuiTitle>
                  <EuiSpacer size='s' />
                  <EuiFormRow fullWidth label={'Select which hours of the day your ad will run. (Uses the audience time zone)'}>
                    <DayPartPicker
                      dayParts={formik.values.dayParts}
                      onSelectedDayPartsChange={days => {
                        formik.setFieldValue('dayParts', days)
                      }}
                    />
                  </EuiFormRow>
                  <EuiSpacer size='s' />
                  <EuiFormRow label='Inventory'>
                    <EuiFlexGrid direction='column'>
                      <EuiFlexItem>
                        <EuiCheckbox
                          id='targetWebCheck'
                          checked={formik.values.inventorySettings.targetWeb}
                          label='Target Web'
                          onChange={e => {
                            formik.setFieldValue('inventorySettings.targetWeb', e.target.checked)
                          }}
                        />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiCheckbox
                          id='targetAppCheck'
                          checked={formik.values.inventorySettings.targetApp}
                          label='Target Apps'
                          onChange={e => {
                            formik.setFieldValue('inventorySettings.targetApp', e.target.checked)
                          }}
                        />
                      </EuiFlexItem>
                    </EuiFlexGrid>
                  </EuiFormRow>
                  <EuiFormRow label='Device'>
                    <EuiFlexGrid direction='column'>
                      <EuiFlexItem>
                        <EuiCheckbox
                          id='targetPcCheck'
                          checked={formik.values.deviceSettings.targetPc}
                          label='Target PC'
                          onChange={e => {
                            formik.setFieldValue('deviceSettings.targetPc', e.target.checked)
                          }}
                        />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiCheckbox
                          id='targetPhoneCheck'
                          checked={formik.values.deviceSettings.targetPhone}
                          label='Target Phone'
                          onChange={e => {
                            formik.setFieldValue('deviceSettings.targetPhone', e.target.checked)
                          }}
                        />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiCheckbox
                          id='targetTabletCheck'
                          checked={formik.values.deviceSettings.targetTablet}
                          label='Target Tablet'
                          onChange={e => {
                            formik.setFieldValue('deviceSettings.targetTablet', e.target.checked)
                          }}
                        />
                      </EuiFlexItem>
                    </EuiFlexGrid>
                  </EuiFormRow>
                  <EuiFormRow label='Frequency Capping (0 is unlimited)'>
                    <EuiFlexGroup>
                      <EuiFlexItem>
                        <EuiFieldNumber
                          name='frequency'
                          value={formik.values.frequency!}
                          onChange={e => {
                            formik.setFieldValue('frequency', Number(e.target.value))
                          }}
                        />
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <EuiText size='xs'>per</EuiText>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiSelect
                          options={frequencyOptions}
                          value={formik.values.frequencyUnit}
                          onChange={e => {
                            formik.setFieldValue('frequencyUnit', e.target.value)
                          }}
                        />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFormRow>
                </React.Fragment>
              )}
              <EuiSpacer />

              <EuiShowFor sizes={['xs', 's']}>
                <EuiFlexGroup>
                  <EuiFlexItem grow={false}>
                    <EuiButton id='finalize' fill type='submit' isLoading={formik.isSubmitting} onClick={formik.submitForm}>
                      Continue
                    </EuiButton>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiButton id='back' fill type='button' color='text' onClick={returnTargetingChooseStep}>
                      Back
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiShowFor>

              <EuiHideFor sizes={['xs', 's']}>
                <EuiFlexGroup>
                  <EuiFlexItem grow={false}>
                    <EuiButton id='back' fill type='button' color='text' onClick={returnTargetingChooseStep} iconType='arrowLeft' iconSide='left'>
                      Back
                    </EuiButton>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiButton id='finalize' fill type='submit' isLoading={formik.isSubmitting} iconType='arrowRight' iconSide='right' onClick={formik.submitForm}>
                      Continue
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiHideFor>
            </EuiForm>
          </FormikProvider>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default TargetAdvancedLocationStep
