import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiFieldSearch, EuiInputPopover, EuiSelectable, EuiText, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { PostalCodeTarget } from 'api/interfaces'
import { clearPostalCodeTargets, fetchPostalCodeTargets } from 'app/dictionarySlice'
import { RootState } from 'app/rootReducer'
import useDebounce from 'utils/useDebounce'

interface NewPostalCodeFinderProps {
  isInvalid: boolean

  onPostalCodeClicked(postalCode: PostalCodeTarget): void
}

export const NewPostalCodeFinder = ({ onPostalCodeClicked, isInvalid }: NewPostalCodeFinderProps) => {
  const dispatch = useDispatch()
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const [postalCodeSearchTerm, setPostalCodeSearchTerm] = useState<string>('')
  const debouncedPostalCodeSearchTerm = useDebounce(postalCodeSearchTerm, 750)
  const [searchPostalCodePopoverOpen, setSearchPostalCodePopoverOpen] = useState<boolean>(false)
  const [postalCodes, setPostalCodes] = useState<Array<EuiSelectableOption>>([])

  const onSearchPostalCode = () => {
    setSearchPostalCodePopoverOpen(false)
  }

  const onSearchPostalCodeChange = (hint: string) => {
    setPostalCodeSearchTerm(hint)
  }

  const onSearchPostalCodeClick = useCallback(
    (postalCode: PostalCodeTarget) => {
      setSearchPostalCodePopoverOpen(false)
      setPostalCodeSearchTerm('')
      onPostalCodeClicked(postalCode)
    },
    [onPostalCodeClicked]
  )

  useEffect(() => {
    if (debouncedPostalCodeSearchTerm) {
      dispatch(fetchPostalCodeTargets(debouncedPostalCodeSearchTerm))
    } else {
      dispatch(clearPostalCodeTargets())
    }
  }, [debouncedPostalCodeSearchTerm, dispatch])

  useEffect(() => {
    if (dictionary.postalCodeTargets) {
      setPostalCodes(
        dictionary.postalCodeTargets.map(
          pc =>
            ({
              label: pc.name,
              key: pc.id.toString(),
              checked: undefined,
              onClick: () => onSearchPostalCodeClick(pc)
            } as EuiSelectableOption)
        )
      )
    }
  }, [dictionary.postalCodeTargets, onSearchPostalCodeClick])

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          value={postalCodeSearchTerm}
          isLoading={dictionary.isLoadingPostalCodeTargets}
          onChange={v => onSearchPostalCodeChange(v.target.value)}
          onFocus={() => setSearchPostalCodePopoverOpen(true)}
          incremental={false}
          onSearch={() => onSearchPostalCode()}
          placeholder='Type to begin search...'
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
          isInvalid={isInvalid}
        />
      }
      isOpen={searchPostalCodePopoverOpen}
      closePopover={() => setSearchPostalCodePopoverOpen(false)}>
      {dictionary.postalCodeTargets && dictionary.postalCodeTargets.length ? (
        <EuiSelectable singleSelection options={postalCodes}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search... results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
