import React, { useEffect, useState } from 'react'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiImage, EuiPanel, EuiSpacer, EuiText, htmlIdGenerator } from '@elastic/eui'

import { PreviewProps } from './index'

export const DesktopWeb = ({ ad, image }: PreviewProps) => {
  const divId = htmlIdGenerator()()
  const [width, setWidth] = useState(0)
  const [imgUrl, setImgUrl] = useState('/1200x667.png')
  const [useDiv, setUseDiv] = useState(false)

  useEffect(() => {
    setWidth(document.getElementById(divId)?.clientWidth || 0)
  }, [divId])

  useEffect(() => {
    if (image) {
      setUseDiv(!!image.thumbnailWidth)
      setImgUrl(image.highQualityUrl)
    }
  }, [image])

  return (
    <EuiFlexGroup style={{ marginTop: 20 }}>
      <EuiFlexItem grow={true} />
      <EuiFlexItem grow={false} style={{ width: 520 }}>
        <EuiPanel paddingSize='none'>
          <EuiFlexGroup responsive={false} style={{ paddingTop: 10 }}>
            <EuiFlexItem style={{ paddingLeft: 10, paddingRight: 10 }}>
              <EuiText size='s' color='warning'>
                <strong>Sponsored</strong>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup responsive={false}>
            <EuiFlexItem style={{ paddingRight: 10, paddingLeft: 10, paddingBottom: 10 }}>
              <EuiText size='s'>
                <h3>{ad?.headline || 'Attention Grabbing Headline'}</h3>
                <p>{ad?.body || 'One or two sentences describing your product or service.'}</p>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
          {useDiv && (
            <div id={divId} style={{ height: (image?.thumbnailHeight! / image?.thumbnailWidth!) * width, overflow: 'hidden' }}>
              <img alt='ad' src={imgUrl} width={'100%'} />
            </div>
          )}
          {!useDiv && <EuiImage alt='ad' url={imgUrl} size='fullWidth' />}
          <EuiSpacer size='s' />
          <EuiFlexGroup responsive={false} style={{ paddingBottom: 10 }}>
            <EuiFlexItem grow={3} style={{ paddingLeft: 10, justifyContent: 'center' }}>
              <EuiText size='m' color='subdued'>
                <strong>{ad?.companyName || 'Your Company Name'}</strong>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={1} style={{ paddingRight: 10 }}>
              <EuiButton id='callToAction' href={ad?.destinationUrl || 'https://google.com'} target='_blank' fullWidth={false} style={{ borderRadius: 0 }}>
                {ad?.callToAction || 'Learn More'}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexItem>
      <EuiFlexItem grow={true} />
    </EuiFlexGroup>
  )
}
