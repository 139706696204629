import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiButton, EuiButtonEmpty, EuiConfirmModal, EuiFlexGroup, EuiFlexItem, EuiHealth, EuiHideFor, EuiImage, EuiInMemoryTable, EuiLoadingSpinner, EuiOverlayMask, EuiSpacer, EuiSwitch, EuiText, EuiToolTip } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { AcImage, Creative, CreativeStatus } from 'api/interfaces'
import { useGetCampaignCreativesQuery } from 'api/rtkQueryApi/platform/creativesApi'
import { RootState } from 'app/rootReducer'
import LastCreativePausedErrorModal from 'features/campaigns/campaign/LastCreativePausedErrorModal'
import history from 'services/HistoryService'
import { CreativeStatusToElasticColor, CreativeStatusToString } from 'utils/EnumToFriendly'

import { removeCampaign } from '../../campaigns/campaignsSlice'
import { CreateAdStep, CreateAdStyle, resetCreateAd, setAd, setCreateAdStep, setCreateAdStyle, setDisplayAd, setImage, setIsCopy } from '../../createAd/internet/retail/createAdInternetRetailSlice'
import { AdState, pauseAd, removeAd, startAd } from '../campaignSlice'

const AdsDrawer: React.FC = () => {
  const dispatch = useDispatch()
  const campaign = useSelector((state: RootState) => state.campaign)
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [allowAdCreation, setAllowAdCreation] = useState(true)
  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false)
  const [adIdToRemove, setAdIdToRemove] = useState<string | null>(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [isLastCreativePausedErrorVisible, setIsLastCreativePausedErrorVisible] = useState(false)
  const getCampaignsCreativesQuery = useGetCampaignCreativesQuery({ accountId: currentAccount!.id, campaignId: campaign.campaign!.id })
  const [campaignCreatives, setCampaignCreatives] = useState<Creative[]>([])
  const [adStates, setAdStates] = useState<AdState[]>([])

  useEffect(() => {
    if (campaign.campaign) {
      setAllowAdCreation(campaign.campaign.targetingTemplate !== 'Recruitment' && campaign.campaign.targetingTemplate !== 'Consumer' && campaign.campaign.canAddCreatives)
    }
  }, [campaign.campaign])

  useEffect(() => {
    if (getCampaignsCreativesQuery.data) {
      setCampaignCreatives(getCampaignsCreativesQuery.data)
    }
  }, [getCampaignsCreativesQuery.data])

  useEffect(() => {
    let aStates = campaignCreatives.map(a => ({
      id: a.id,
      type: a.type,
      checked: a.status !== CreativeStatus.Inactive && a.status !== CreativeStatus.Deleted,
      status: a.status,
      disabled: !a.canEditStatus,
      canRemove: a.canRemove,
      rejectionReasons: a.auditReasons,
      name: a.name ?? '',
      headline: a.nativeDetails?.headline,
      body: a.nativeDetails?.body,
      thumbnailUrl: a.thumbnailUrl ? a.thumbnailUrl : a.displayAdDetails?.uploadedUrl,
      sections: a.tvCommercialDetails?.sections,
      loading: false,
      mediaType: a.mediaType,
      uploadedUrl: a.tvCommercialUploadedDetails?.uploadedUrl!
    }))
    aStates = aStates.filter(a => !(a.type === 'Internet' && (a.status === CreativeStatus.Inactive || a.status === CreativeStatus.Deleted) && (a.headline || a.body)))
    setAdStates(aStates)
  }, [campaignCreatives])

  const preview = (headline: string, thumbnailUrl: string, body: string) => (
    <EuiFlexGroup responsive={false} style={{ minWidth: 270 }} gutterSize='none'>
      <EuiHideFor sizes={['xs']}>
        <EuiFlexItem grow={false} style={{ width: 50, minWidth: 50 }} className='eui-alignMiddle'>
          <EuiImage alt='ad' url={thumbnailUrl || '/82x82.png'} size='fullWidth' style={{ borderRadius: 12 }} />
        </EuiFlexItem>
      </EuiHideFor>
      <EuiFlexItem grow={false} style={{ marginLeft: 10, width: 200 }}>
        <EuiText size='xs' style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
          <strong>{headline}</strong>
        </EuiText>
        <EuiText size='xs' color='subdued' style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {body}
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  )

  const cancelRemoveModal = () => setIsRemoveModalVisible(false)

  const confirmRemoveModal = () => {
    if (adIdToRemove && currentAccount && campaign && campaign.campaign) {
      if (campaignCreatives.length === 1) {
        dispatch(removeCampaign(currentAccount.id, campaign.campaign.id))
        setIsRemoveModalVisible(false)
        setAdIdToRemove(null)
        history.push('/campaigns')
      } else {
        dispatch(removeAd(currentAccount.id, campaign.campaign.id, adIdToRemove))
        setIsRemoveModalVisible(false)
        setAdIdToRemove(null)
      }
    }
  }

  const showRemoveModal = (adId: string) => {
    setIsRemoveModalVisible(true)
    setAdIdToRemove(adId)
  }

  let removeModal

  if (isRemoveModalVisible) {
    removeModal = (
      <EuiOverlayMask>
        <EuiConfirmModal title='Are you sure you want to remove this ad?' onCancel={cancelRemoveModal} onConfirm={confirmRemoveModal} cancelButtonText="No, don't do it" confirmButtonText='Yes, do it' defaultFocusedButton='confirm' buttonColor='danger'>
          {campaignCreatives.length === 1 ? <p>Once you do this, this campaign will be removed and you will need to recreate it if you made a mistake.</p> : <p>Once you do this, this ad will be removed and you will need to recreate it if you made a mistake.</p>}
        </EuiConfirmModal>
      </EuiOverlayMask>
    )
  }

  const createAd = () => {
    dispatch(resetCreateAd())
    history.push(`/campaigns/internet/public/start`)
  }

  const cloneAd = (adId: string) => {
    const selectedAd = campaignCreatives.find(a => a.id === adId)

    if(!!selectedAd) {
      dispatch(
        setImage({
          id: '',
          highQualityUrl: selectedAd.highQualityUrl,
          squareUrl: selectedAd.squareUrl,
          thumbnailUrl: selectedAd.thumbnailUrl,
          isPurchased: false,
          isUploaded: false
        } as AcImage)
      )
      dispatch(
        setAd({
          id: '',
          headline: selectedAd.nativeDetails.headline,
          body: selectedAd.nativeDetails.body,
          companyName: selectedAd.nativeDetails.companyName,
          callToAction: selectedAd.nativeDetails.callToAction,
          destinationUrl: selectedAd.destinationUrl
        })
      )
      dispatch(setDisplayAd(null))
      dispatch(setIsCopy())
      dispatch(setCreateAdStyle(CreateAdStyle.Build))
      dispatch(setCreateAdStep(CreateAdStep.Create))
      history.push(`/campaigns/internet/public/create`)
    }
  }

  const columns: Array<EuiBasicTableColumn<AdState>> = [
    {
      width: '90px',
      name: 'ON/OFF',
      render: (ad: AdState) => (
        <React.Fragment>
          <EuiSwitch
            showLabel={false}
            label={''}
            checked={ad.checked}
            disabled={ad.disabled}
            onChange={e => {
              if (e.target.checked) {
                dispatch(startAd(currentAccount!.id, campaign!.campaign!.id, ad.id))
                setAdStates(adStates.map(a => (a.id === ad.id ? { ...a, checked: true } : a)))
              } else {
                if (adStates.filter(a => a.checked).length <= 1) {
                  setIsLastCreativePausedErrorVisible(true)
                  return
                }

                dispatch(pauseAd(currentAccount!.id, campaign!.campaign!.id, ad.id))
                setAdStates(adStates.map(a => (a.id === ad.id ? { ...a, checked: false } : a)))
              }
            }}
          />
          {ad.loading && <EuiLoadingSpinner style={{ marginLeft: 5 }} />}
        </React.Fragment>
      )
    },
    {
      width: '240px',
      name: 'Ad',
      render: (ad: AdState) => preview(ad.headline!, ad.thumbnailUrl, ad.body!)
    },
    ...(adStates.find(ad => ad.mediaType === 'Display')
      ? [
          {
            width: '240px',
            name: 'Name',
            render: (ad: AdState) => ad.name
          }
        ]
      : []),
    {
      width: '110px',
      name: 'Status',
      render: (creative: AdState) => {
        let color = CreativeStatusToElasticColor(creative.status)
        let label = CreativeStatusToString(creative.status)

        return creative.status === CreativeStatus.Disapproved ? (
          <EuiToolTip
            position='right'
            content={
              <EuiText>
                <ul>
                  {creative.rejectionReasons.map(a => (
                    <li>{a}</li>
                  ))}
                </ul>
              </EuiText>
            }>
            <EuiHealth color={color}>{label}</EuiHealth>
          </EuiToolTip>
        ) : (
          <EuiHealth color={color}>{label}</EuiHealth>
        )
      }
    },
    {
      width: '215px',
      name: '',
      render: (ad: AdState) => (
        <React.Fragment>
          <EuiButtonEmpty
            id='view'
            iconType='inspect'
            onClick={() => {
              history.push(`/campaigns/ads/${campaign.campaign!.id}/edit/${ad.id}`)
            }}
            size='s'>
            View
          </EuiButtonEmpty>
          {ad.mediaType === 'Display' ? (
            <></>
          ) : (
            <EuiButtonEmpty id='copy' iconType='copy' onClick={() => cloneAd(ad.id)} size='s'>
              Copy
            </EuiButtonEmpty>
          )}
          {ad.canRemove && (
            <EuiButtonEmpty id='remove' iconType='cross' onClick={() => showRemoveModal(ad.id)} size='s'>
              Remove
            </EuiButtonEmpty>
          )}
        </React.Fragment>
      )
    }
  ]
  const pagination = {
    initialPageSize: 10,
    pageSizeOptions: [10, 50, 100],
    pageIndex: currentPage
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiFlexGroup id='adsDrawerMediaQuery'>
        <EuiFlexItem>
          {allowAdCreation && (
            <EuiFlexGroup alignItems='center'>
              <EuiFlexItem />
              <EuiFlexItem grow={false}>
                <EuiButton id='create' size='s' key='createAd' onClick={createAd} fill>
                  Create Ad
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          )}
          <EuiInMemoryTable
            loading={getCampaignsCreativesQuery.isLoading}
            items={adStates}
            columns={columns}
            pagination={pagination}
            responsive={true}
            onChange={(criteria: any) => {
              setCurrentPage(criteria.page.index)
            }}
          />
        </EuiFlexItem>
        <EuiHideFor sizes={['m', 'l']}>
          <EuiFlexItem grow={false} style={{ width: 270 }}>
            <EuiText size='xs'>
              <h3>Best Practices</h3>
              <h5>Keeping Your Ads Fresh</h5>
              <p>It is important to keep an eye on your ads and make sure they are not getting stale and/or overexposed. If you have had an ad running for a while, consider creating another ad as part of your campaign.</p>
            </EuiText>
          </EuiFlexItem>
        </EuiHideFor>
      </EuiFlexGroup>
      {removeModal}
      <LastCreativePausedErrorModal isModalVisible={isLastCreativePausedErrorVisible} closeModal={() => setIsLastCreativePausedErrorVisible(false)} />
    </React.Fragment>
  )
}

export default AdsDrawer
