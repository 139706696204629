import { createSlice } from '@reduxjs/toolkit'

import { GeoCircle, GeoPolygon, GeoRectangle } from 'api/interfaces'

export interface TargetingMapState {
  initialGeoCircles: Array<GeoCircle>
  initialGeoRectangles: Array<GeoRectangle>
  initialGeoPolygons: Array<GeoPolygon>
  updatedGeoCircles: Array<GeoCircle>
  updatedGeoRectangles: Array<GeoRectangle>
  updatedGeoPolygons: Array<GeoPolygon>
  hideLocationSearch?: boolean
  hideDrawingTools?: boolean
  includeBillboards?: boolean
  targetedPlaces?: string[]
}

const initialState: TargetingMapState = {
  initialGeoCircles: [],
  initialGeoRectangles: [],
  initialGeoPolygons: [],
  updatedGeoCircles: [],
  updatedGeoRectangles: [],
  updatedGeoPolygons: [],
  hideLocationSearch: false,
  hideDrawingTools: false,
  includeBillboards: false,
  targetedPlaces: []
}

export interface IInitialTargetingMapState {
  initialGeoCircles: Array<GeoCircle>
  initialGeoRectangles: Array<GeoRectangle>
  initialGeoPolygons: Array<GeoPolygon>
  hideLocationSearch?: boolean
  hideDrawingTools?: boolean
  includeBillboards?: boolean
  targetedPlaces?: string[]
}

const targetingMap = createSlice({
  name: 'targetingMap',
  initialState,
  reducers: {
    resetTargetingMapState(state: TargetingMapState) {
      state.initialGeoCircles = []
      state.initialGeoRectangles = []
      state.initialGeoPolygons = []
      state.updatedGeoCircles = []
      state.updatedGeoRectangles = []
      state.updatedGeoPolygons = []
      state.hideLocationSearch = false
      state.hideDrawingTools = false
      state.includeBillboards = false
      state.targetedPlaces = []
    },
    setInitialTargetingMapState(state: TargetingMapState, { payload }: { payload: Partial<IInitialTargetingMapState> }) {
      state.initialGeoCircles = payload.initialGeoCircles ?? []
      state.initialGeoRectangles = payload.initialGeoRectangles ?? []
      state.initialGeoPolygons = payload.initialGeoPolygons ?? []
      state.updatedGeoCircles = payload.initialGeoCircles ?? []
      state.updatedGeoRectangles = payload.initialGeoRectangles ?? []
      state.updatedGeoPolygons = payload.initialGeoPolygons ?? []
      state.hideLocationSearch = payload.hideLocationSearch
      state.hideDrawingTools = payload.hideDrawingTools
      state.includeBillboards = payload.includeBillboards
      state.targetedPlaces = payload.targetedPlaces
    },
    addCircle(state: TargetingMapState, { payload }: { payload: GeoCircle }) {
      state.updatedGeoCircles.push(payload)
    },
    removeCircle(state: TargetingMapState, { payload }: { payload: string }) {
      state.updatedGeoCircles = state.updatedGeoCircles.filter(circle => circle.id !== payload)
    },
    modifyCircle(state: TargetingMapState, { payload }: { payload: GeoCircle }) {
      state.updatedGeoCircles = state.updatedGeoCircles.map(circle => (circle.id === payload.id ? payload : circle))
    },
    addRectangle(state: TargetingMapState, { payload }: { payload: GeoRectangle }) {
      state.updatedGeoRectangles.push(payload)
    },
    removeRectangle(state: TargetingMapState, { payload }: { payload: string }) {
      state.updatedGeoRectangles = state.updatedGeoRectangles.filter(rectangle => rectangle.id !== payload)
    },
    modifyRectangle(state: TargetingMapState, { payload }: { payload: GeoRectangle }) {
      state.updatedGeoRectangles = state.updatedGeoRectangles.map(rectangle => (rectangle.id === payload.id ? payload : rectangle))
    },
    addPolygon(state: TargetingMapState, { payload }: { payload: GeoPolygon }) {
      state.updatedGeoPolygons.push(payload)
    },
    removePolygon(state: TargetingMapState, { payload }: { payload: string }) {
      state.updatedGeoPolygons = state.updatedGeoPolygons.filter(polygon => polygon.id !== payload)
    },
    modifyPolygon(state: TargetingMapState, { payload }: { payload: GeoPolygon }) {
      state.updatedGeoPolygons = state.updatedGeoPolygons.map(polygon => (polygon.id === payload.id ? payload : polygon))
    },
    modifyTargetedPlaces(state: TargetingMapState, { payload }: { payload: string[] }) {
      state.targetedPlaces = payload
    },
    configureDisplays(state: TargetingMapState, { payload }: { payload: { hideLocationSearch?: boolean; hideDrawingTools?: boolean; includeBillboards?: boolean } }) {
      state.hideLocationSearch = payload.hideLocationSearch ?? state.hideLocationSearch
      state.hideDrawingTools = payload.hideDrawingTools ?? state.hideDrawingTools
      state.includeBillboards = payload.includeBillboards ?? state.includeBillboards
    }
  }
})

export const { resetTargetingMapState, setInitialTargetingMapState, addCircle, removeCircle, modifyCircle, addRectangle, removeRectangle, modifyRectangle, addPolygon, removePolygon, modifyPolygon, modifyTargetedPlaces, configureDisplays } = targetingMap.actions

export default targetingMap.reducer
