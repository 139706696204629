import React from 'react'

import { EuiCard, EuiLink, EuiSpacer } from '@elastic/eui'

import { BusinessType } from 'api/interfaces'
import { BusinessTypeFinder } from 'components/finders/BusinessTypeFinder'

export interface IAcBusinessTypePicker {
  selectedBusinessType?: BusinessType
  onBusinessTypeChange: (businessType?: BusinessType) => void
  isInvalid?: boolean
}

export const AcBusinessTypePicker: React.FC<IAcBusinessTypePicker> = props => {
  const onBusinessTypeChange = (businessType: BusinessType) => {
    props.onBusinessTypeChange(businessType)
  }

  const onClearBusinessType = () => {
    props.onBusinessTypeChange()
  }

  return (
    <React.Fragment>
      {props.selectedBusinessType && (
        <React.Fragment>
          <EuiCard
            display='primary'
            layout='horizontal'
            paddingSize='s'
            titleSize='xs'
            icon={
              <div>
                <img alt='' src={props.selectedBusinessType.imageUrl} />
              </div>
            }
            title={props.selectedBusinessType.name}
            description={props.selectedBusinessType.businessTypeCategories.join(', ')}
          />
          <EuiSpacer size='s' />
          <EuiLink color='success' onClick={() => onClearBusinessType()}>
            Clear selected business type
          </EuiLink>
        </React.Fragment>
      )}
      {!props.selectedBusinessType && <BusinessTypeFinder onBusinessTypeClicked={onBusinessTypeChange} isInvalid={props.isInvalid} />}
    </React.Fragment>
  )
}
