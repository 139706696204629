import React, { useEffect } from 'react'

import { EuiFieldSearch, EuiHighlight, EuiInputPopover, EuiSelectable, EuiText, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { CampaignType, SegmentTarget } from 'api/interfaces'
import { useGetInternetSegmentsQuery, useGetTvSegmentsQuery } from 'api/rtkQueryApi/platform/audiencesApi'

export interface INewSegmentFinder {
  campaignType: CampaignType
  isInvalid: boolean
  onSegmentClicked(segment: SegmentTarget): void
}

export const NewSegmentFinder: React.FC<INewSegmentFinder> = props => {
  const [segments, setSegments] = React.useState<SegmentTarget[]>([])
  const [query, setQuery] = React.useState<string>('')
  const [popoverOpen, setPopoverOpen] = React.useState<boolean>(false)
  const tvSegmentsRequest = useGetTvSegmentsQuery({ query: query }, { skip: props.campaignType !== CampaignType.TV || query.length < 2 })
  const internetSegmentsRequest = useGetInternetSegmentsQuery({ query: query }, { skip: props.campaignType !== CampaignType.Internet || query.length < 2 })

  useEffect(() => {
    if (props.campaignType === CampaignType.Internet) {
      setSegments(internetSegmentsRequest.data ?? [])
    } else if (props.campaignType === CampaignType.TV) {
      setSegments(tvSegmentsRequest.data ?? [])
    }
  }, [tvSegmentsRequest.data, internetSegmentsRequest.data, props.campaignType])

  const onSearch = () => {}

  const onSearchChanged = (value: string) => {
    setQuery(value)
  }

  const onSegmentClicked = (segment: SegmentTarget) => {
    setPopoverOpen(false)
    setQuery('')
    props.onSegmentClicked(segment)
  }

  const renderAudienceOption = (option: EuiSelectableOption, _: string) => {
    const segment = segments.find(a => a.id.toString() === option.key)
    if (!segment) return <></>

    return (
      <React.Fragment>
        <EuiHighlight search={query}>{option.label}</EuiHighlight>
        <EuiText style={{ paddingLeft: '10px' }} size='xs'>
          <EuiHighlight search={query}>{segment.description ?? ''}</EuiHighlight>
        </EuiText>
        <EuiText style={{ paddingLeft: '10px' }} size='xs'>
          <EuiHighlight search={query}>{segment.hierarchy?.join(' \\ ')}</EuiHighlight>
        </EuiText>
      </React.Fragment>
    )
  }

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          value={query}
          isLoading={tvSegmentsRequest.isLoading || internetSegmentsRequest.isLoading}
          onChange={v => onSearchChanged(v.target.value)}
          onFocus={() => setPopoverOpen(true)}
          incremental={false}
          onSearch={onSearch}
          placeholder='Type to begin search...'
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
          isInvalid={props.isInvalid}
        />
      }
      isOpen={popoverOpen}
      closePopover={() => setPopoverOpen(false)}>
      {segments.length ? (
        <EuiSelectable singleSelection options={segments.map(s => ({ key: s.id.toString(), label: s.name, onClick: () => onSegmentClicked(s) }))} renderOption={renderAudienceOption} listProps={{ rowHeight: 60 }}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search, results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
