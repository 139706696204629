import React, { FC } from 'react'

import { BarSeries, Axis, Chart, LIGHT_THEME, PartialTheme, Position, ScaleType, Settings } from '@elastic/charts'
import { EuiFlexGroup, EuiFlexItem, EuiLoadingChart, EuiText, useGeneratedHtmlId } from '@elastic/eui'

import { Insights } from 'api/interfaces/Insights'
import { primaryTrajectoryColor, saturationChartBarArea, saturationChartBarBorder, secondaryTrajectoryColor } from 'components/insights/InsightsConstants'

export interface InsightsSaturationChartProps {
  isLoading: boolean
  saturationTarget: number
  saturation?: Insights
}

const InsightsSaturationChart: FC<InsightsSaturationChartProps> = ({ isLoading, saturationTarget, saturation }) => {
  const chartId = useGeneratedHtmlId()

  const target = saturationTarget
  const chartData = [] as any[]
  const chartTrajectoryData = [] as any[]
  let missingMonths = [] as string[]
  let lastPercentage = 0

  for (const item in saturation?.byMonth) {
    let yVal: number | null = saturation?.byMonth[item]?.value ?? 0

    if (yVal === -1) {
      missingMonths.push(item)
      continue
    }

    lastPercentage = yVal
    chartData.push({ x: item, y: yVal })
  }

  const ratio = Math.round((target - lastPercentage) / missingMonths.length)
  let i = 1

  missingMonths.map(m => {
    let yVal = ratio * i + lastPercentage

    if (i !== missingMonths.length) {
      chartTrajectoryData.push({ x: m, y: yVal })
    } else {
      chartTrajectoryData.push({ x: m, y: target })
    }

    i++
  })

  const theme: PartialTheme = {
    axes: {
      tickLabel: {
        fontSize: 13
      }
    },
    barSeriesStyle: {
      rect: {
        fill: saturationChartBarArea
      },
      rectBorder: {
        stroke: saturationChartBarBorder,
        strokeWidth: 3,
        visible: true
      }
    },
    colors: {
      vizColors: [saturationChartBarBorder]
    }
  }

  const trajectoryTheme = {
    rect: {
      fill: primaryTrajectoryColor
    },
    rectBorder: {
      stroke: secondaryTrajectoryColor,
      strokeWidth: 3,
      visible: false
    }
  }

  if (isLoading) {
    return <EuiLoadingChart size='l' />
  }

  if (chartData.length <= 0) {
    return (
      <EuiFlexGroup alignItems='center' justifyContent='center' direction='column'>
        <EuiFlexItem>
          <EuiText size='m'>Talk to your campaign advisor to activate saturation.</EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  return (
    <Chart id={chartId} size={{ height: 150 }}>
      <Settings theme={theme} baseTheme={LIGHT_THEME} />
      <BarSeries data={chartData} id='Avg frequency' xAccessor='x' yAccessors={['y']} stackAccessors={[0]} xScaleType={ScaleType.Ordinal} yScaleType={ScaleType.Linear} tickFormat={d => `${d.toString()}%`} />
      <BarSeries data={chartTrajectoryData} id='Avg frequency predicted' xAccessor='x' yAccessors={['y']} stackAccessors={[0]} xScaleType={ScaleType.Ordinal} yScaleType={ScaleType.Linear} tickFormat={d => `${d.toString()}%`} color={[secondaryTrajectoryColor]} barSeriesStyle={trajectoryTheme} />
      <Axis id='avg. frequency' position={Position.Left} />
      <Axis id='date' position={Position.Bottom} tickFormat={d => d.toString()} />
    </Chart>
  )
}

export default InsightsSaturationChart
