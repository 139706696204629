import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiRadio, EuiSpacer, EuiText, EuiTitle, EuiSelect, EuiFieldNumber, EuiFlexGrid, EuiCheckbox, EuiShowFor, EuiHideFor } from '@elastic/eui'

import { City, ThirdPartyAudience, Zip, InventorySettings, DeviceSettings, FrequencyUnit, RegionTarget, DmaTarget, CityTarget, CampaignType, SegmentTarget, PostalCodeTarget, PermissionType, DayPartitioning } from 'api/interfaces'
import { useGetOrganizationQuery } from 'api/rtkQueryApi/platform/organizationsApi'
import { RootState } from 'app/rootReducer'
import { AcFileUploader } from 'components/Basic/AcFileUploader'
import { NewCityFinder } from 'components/finders/NewCityFinder'
import { NewDmaFinder } from 'components/finders/NewDmaFinder'
import { NewPostalCodeFinder } from 'components/finders/NewPostalCodeFinder'
import { NewRegionFinder } from 'components/finders/NewRegionFinder'
import { NewSegmentFinder } from 'components/finders/NewSegmentFinder'
import { ZipTargeter, AgeRangeTargeter, GenderTargeter, HouseholdIncomeTargeter } from 'components/targeters'
import { AudienceTargeterDetailed } from 'components/targeters/AudienceTargeterDetailed'
import { DayPartPicker, DefaultDayParts } from 'components/targeters/DayPartPicker'
import { NewCityTargeter } from 'components/targeters/NewCityTargeter'
import { NewDmaTargeter } from 'components/targeters/NewDmaTargeter'
import { NewRegionTargeter } from 'components/targeters/NewRegionTargeter'
import history from 'services/HistoryService'
import { useWhiteLabel } from 'whiteLabel/WhiteLabelContext'

import { AdvancedTargetingType, setAdvancedTargeting } from './builderInternetRetailSlice'

interface FormValues {
  audienceType: string
  segments: Array<SegmentTarget>
  ageRanges: Array<string>
  genders: Array<string>
  householdIncomes: Array<string>
  locationType: string | null
  states: Array<RegionTarget>
  dmas: Array<DmaTarget>
  cities: Array<CityTarget>
  zips: Array<PostalCodeTarget>
  uploadType: string | null
  uploadedData: Array<string>
  retargetingType: string | null
  urlContains: string | null
  inventorySettings: InventorySettings
  deviceSettings: DeviceSettings
  frequency: number
  frequencyUnit: FrequencyUnit
  dayParts: DayPartitioning
}

const TargetAdvancedAudienceStep: React.FC = () => {
  const dispatch = useDispatch()
  const organizationQuery = useGetOrganizationQuery(undefined)
  const builder = useSelector((state: RootState) => state.builderInternetRetail)
  const [initialValues, setInitialValues] = useState<FormValues>({
    audienceType: 'Build',
    ageRanges: ['all'],
    genders: ['all'],
    householdIncomes: ['all'],
    segments: [],
    locationType: 'USA',
    states: [],
    dmas: [],
    cities: [],
    zips: [],
    uploadType: 'Email',
    uploadedData: [],
    urlContains: null,
    retargetingType: null,
    inventorySettings: { targetWeb: true, targetApp: false },
    deviceSettings: { targetPc: true, targetPhone: true, targetTablet: true },
    frequency: 0,
    frequencyUnit: FrequencyUnit.hour,
    dayParts: DefaultDayParts
  })
  const whiteLabel = useWhiteLabel()
  const [uploadedAudienceUrl, setUploadedAudienceUrl] = useState<string>()
  const [isInvalidUpload, setIsInvalidUpload] = useState(false)

  useEffect(() => {
    if (builder.advancedTargeting && builder.advancedTargeting.advancedTargetingType === 'Audience') {
      setUploadedAudienceUrl(builder.advancedTargeting!.locations?.uploadedAudienceUrl)
      setInitialValues({
        audienceType: builder.advancedTargeting!.audienceType ?? 'Build',
        segments: builder.advancedTargeting!.segments ?? [],
        ageRanges: builder.advancedTargeting!.ageRanges,
        genders: builder.advancedTargeting!.genders,
        householdIncomes: builder.advancedTargeting!.householdIncomes,
        locationType: builder.advancedTargeting!.locationType ?? 'USA',
        states: builder.advancedTargeting!.locations?.regions ?? [],
        dmas: builder.advancedTargeting!.locations?.dmas ?? [],
        cities: builder.advancedTargeting!.locations?.cities ?? [],
        zips: builder.advancedTargeting!.locations?.postalCodes ?? [],
        uploadType: builder.advancedTargeting!.uploadType ?? 'Email',
        uploadedData: builder.advancedTargeting!.uploadedData,
        urlContains: builder.advancedTargeting!.urlParts.length === 1 ? builder.advancedTargeting!.urlParts[0] : null,
        retargetingType: builder.advancedTargeting!.retargetingType ?? 'All',
        inventorySettings: builder.advancedTargeting!.inventorySettings ?? { targetWeb: true, targetApp: false },
        deviceSettings: builder.advancedTargeting!.deviceSettings ?? {
          targetPc: true,
          targetPhone: true,
          targetTablet: true
        },
        frequency: builder.advancedTargeting!.frequency ?? 0,
        frequencyUnit: builder.advancedTargeting!.frequencyUnit ?? FrequencyUnit.hour,
        dayParts: builder.advancedTargeting.dayParts ?? DefaultDayParts
      })
    }
  }, [builder.advancedTargeting])

  const advancedAudienceTargetingSchema = Yup.object().shape({
    audienceType: Yup.string().required(),
    locationTargeting: Yup.string().nullable(),
    states: Yup.array().when(['audienceType', 'locationTargeting'], {
      is: (audienceType: string, locationTargeting: string) => audienceType === 'Build' && locationTargeting === 'State',
      then: Yup.array().min(1, 'Please select at least 1 state').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    dmas: Yup.array().when(['audienceType', 'locationTargeting'], {
      is: (audienceType: string, locationTargeting: string) => audienceType === 'Build' && locationTargeting === 'DMA',
      then: Yup.array().min(1, 'Please select at least 1 metro area / DMA').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    cities: Yup.array().when(['audienceType', 'locationTargeting'], {
      is: (audienceType: string, locationTargeting: string) => audienceType === 'Build' && locationTargeting === 'City',
      then: Yup.array().min(1, 'Please add at least 1 city').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    zips: Yup.array().when(['audienceType', 'locationTargeting'], {
      is: (audienceType: string, locationTargeting: string) => audienceType === 'Build' && locationTargeting === 'Zip',
      then: Yup.array().min(1, 'Please add at least 1 zip').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    segments: Yup.array().when('audienceType', {
      is: 'Build',
      then: Yup.array().min(1, 'Please add at least 1 individual characteristic').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    ageRanges: Yup.array().of(Yup.string()),
    genders: Yup.array().of(Yup.string()),
    householdIncomes: Yup.array().of(Yup.string()),
    retargetType: Yup.string().nullable(),
    urlContains: Yup.string().when(['audienceType', 'retargetType'], {
      is: (audienceType: string, retargetType: string) => audienceType === 'Retarget' && retargetType === 'Specific',
      then: Yup.string().required('Please enter a specific URL part'),
      otherwise: Yup.string().nullable()
    }),
    inventorySettings: Yup.object().shape({ targetWeb: Yup.boolean(), targetApp: Yup.boolean() }),
    deviceSettings: Yup.object().shape({
      targetPc: Yup.boolean(),
      targetPhone: Yup.boolean(),
      targetTablet: Yup.boolean()
    }),
    frequency: Yup.number(),
    frequencyUnit: Yup.number()
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: advancedAudienceTargetingSchema,
    onSubmit: (values: FormValues) => {
      const targeting = {
        ...builder.advancedTargeting!,
        advancedTargetingType: AdvancedTargetingType.Audience,
        locationType: values.audienceType === 'Build' ? values.locationType : null,
        locations: {
          countries: [],
          regions: values.states,
          dmas: values.dmas,
          cities: values.cities,
          postalCodes: values.zips,
          uploadedAudienceUrl: values.audienceType === 'Upload' ? uploadedAudienceUrl : undefined
        },
        states: [],
        dmas: [],
        cities: [],
        zips: [],
        audienceType: values.audienceType,
        segments: values.segments,
        audiences: [],
        ageRanges: values.ageRanges,
        genders: values.genders,
        householdIncomes: values.householdIncomes,
        uploadType: values.audienceType === 'Upload' ? values.uploadType : null,
        uploadedData: values.uploadedData,
        retargetingType: values.audienceType === 'Retargeting' ? values.retargetingType : null,
        urlParts: values.urlContains ? [values.urlContains] : [],
        inventorySettings: whiteLabel?.isAgencies()
          ? {
              targetWeb: values.inventorySettings.targetWeb,
              targetApp: values.inventorySettings.targetApp
            }
          : null,
        deviceSettings: whiteLabel?.isAgencies()
          ? {
              targetPc: values.deviceSettings.targetPc,
              targetPhone: values.deviceSettings.targetPhone,
              targetTablet: values.deviceSettings.targetTablet
            }
          : null,
        frequency: whiteLabel?.isAgencies() ? values.frequency : null,
        frequencyUnit: whiteLabel?.isAgencies() ? values.frequencyUnit : null,
        dayParts: values.dayParts
      }
      dispatch(setAdvancedTargeting(targeting))
      history.push('/build/internet/public/deliver')
    }
  })

  const canContinue = formik.values.audienceType === 'Upload' ? !!uploadedAudienceUrl : true

  const returnTargetingChooseStep = () => {
    history.push('/build/internet/public/target')
  }

  const proceed = () => {
    formik.submitForm().then(() => {})
  }

  const frequencyOptions = [
    { value: FrequencyUnit.hour, text: 'Hour' },
    { value: FrequencyUnit.day, text: 'Day' },
    { value: FrequencyUnit.week, text: 'Week' }
  ]

  return (
    <React.Fragment>
      <Helmet>
        <title>Advanced Audience Targeting</title>
      </Helmet>
      <EuiFlexGroup>
        <EuiFlexItem grow={true}>
          <EuiTitle size='s'>
            <h3>Audience Targeting</h3>
          </EuiTitle>
          <EuiText size='s'>
            <div>
              Define the audience you want to target.
              <br />
              Build your audience from over 200,000 data segments, demographic restrictions, and location targeting.
            </div>
          </EuiText>
          <EuiSpacer />
          <FormikProvider value={formik}>
            <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={!!formik.status && !!formik.errors} error={formik.status}>
              {organizationQuery.data?.permissions?.some(p => p.permissionType === PermissionType.UploadAudience) && (
                <React.Fragment>
                  <EuiRadio
                    id='build'
                    name='audienceType'
                    value='Build'
                    label={
                      <EuiText size='s'>
                        <strong>Build an Audience</strong> (specific interests, brand affinities, demographics, etc.)
                      </EuiText>
                    }
                    checked={formik.values.audienceType === 'Build'}
                    onChange={() => {
                      formik.setFieldValue('audienceType', 'Build', true)
                    }}
                  />
                  <EuiSpacer size='s' />
                  <EuiRadio
                    id='upload'
                    name='audienceType'
                    value='Upload'
                    label={
                      <EuiText size='s'>
                        <strong>Upload an Audience</strong> (email addresses, zip codes, or street addresses)
                      </EuiText>
                    }
                    checked={formik.values.audienceType === 'Upload'}
                    onChange={() => {
                      formik.setFieldValue('audienceType', 'Upload', true)
                    }}
                  />
                  <EuiSpacer />
                </React.Fragment>
              )}
              {formik.values.audienceType === 'Upload' && (
                <React.Fragment>
                  <EuiCallOut size='s' iconType='filebeatApp' color='success' title='Target your own list of individuals' />
                  <EuiSpacer size='s' />
                  <EuiFormRow fullWidth label='File' helpText='Please only upload plain text files, with one record per line.'>
                    <AcFileUploader
                      maxFileSize={10000000}
                      allowedFileTypes={['text/plain', 'text/csv']}
                      onUploadedUrlChange={url => {
                        setUploadedAudienceUrl(url)
                      }}
                      onIsValidFileChange={isValid => {
                        setIsInvalidUpload(!isValid)
                      }}
                    />
                  </EuiFormRow>
                  {isInvalidUpload && <EuiCallOut title='Please upload a csv under 10MB' iconType='document' color='warning' />}
                </React.Fragment>
              )}
              {formik.values.audienceType === 'Build' && (
                <React.Fragment>
                  <EuiCallOut size='s' iconType='users' color='success' title='Search and select one or more individual characteristics' />
                  <EuiSpacer size='s' />
                  <EuiFormRow id='audienceFinder' label={`Search for individual characteristics`} fullWidth isInvalid={!!formik.touched.segments && !!formik.errors.segments} error={formik.errors.segments}>
                    <NewSegmentFinder
                      campaignType={CampaignType.Internet}
                      isInvalid={false}
                      onSegmentClicked={segment => {
                        formik.setFieldValue('segments', [...formik.values.segments.filter(s => s.id !== segment.id), segment])
                      }}
                    />
                  </EuiFormRow>
                  {formik.values.segments && formik.values.segments.length > 0 && (
                    <EuiFormRow label='Your campaign will prioritize individuals with any of these characteristics' fullWidth>
                      <AudienceTargeterDetailed
                        audiences={formik.values.segments.map(s => {
                          return { id: s.id, name: s.name, description: s.description } as ThirdPartyAudience
                        })}
                        onAudienceRemoved={audience => {
                          formik.setFieldValue(
                            'segments',
                            formik.values.segments.filter(a => a.id !== audience.id)
                          )
                        }}
                      />
                    </EuiFormRow>
                  )}

                  <EuiSpacer size='xl' />

                  <EuiTitle size='s'>
                    <span>Location Restriction</span>
                  </EuiTitle>
                  <EuiSpacer size='s' />

                  <EuiRadio
                    id='usa'
                    name='locationType'
                    value='USA'
                    label={
                      <EuiText size='s'>
                        <strong>Entire USA</strong>
                      </EuiText>
                    }
                    checked={formik.values.locationType === 'USA'}
                    onChange={() => {
                      formik.setFieldValue('locationType', 'USA', true)
                    }}
                  />
                  <EuiSpacer size='s' />
                  <EuiRadio
                    id='state'
                    name='locationType'
                    value='State'
                    label={
                      <EuiText size='s'>
                        <strong>State(s)</strong>
                      </EuiText>
                    }
                    checked={formik.values.locationType === 'State'}
                    onChange={() => {
                      formik.setFieldValue('locationType', 'State', true)
                    }}
                  />
                  <EuiSpacer size='s' />
                  <EuiRadio
                    id='dma'
                    name='locationType'
                    value='DMA'
                    label={
                      <EuiText size='s'>
                        <strong>Metro Area(s) / DMA(s)</strong>
                      </EuiText>
                    }
                    checked={formik.values.locationType === 'DMA'}
                    onChange={() => {
                      formik.setFieldValue('locationType', 'DMA', true)
                    }}
                  />
                  <EuiSpacer size='s' />
                  <EuiRadio
                    id='city'
                    name='locationType'
                    value='City'
                    label={
                      <EuiText size='s'>
                        <strong>City(s)</strong>
                      </EuiText>
                    }
                    checked={formik.values.locationType === 'City'}
                    onChange={() => {
                      formik.setFieldValue('locationType', 'City', true)
                    }}
                  />
                  <EuiSpacer size='s' />
                  <EuiRadio
                    id='zip'
                    name='locationType'
                    value='Zip'
                    label={
                      <EuiText size='s'>
                        <strong>Zip Code(s)</strong>
                      </EuiText>
                    }
                    checked={formik.values.locationType === 'Zip'}
                    onChange={() => {
                      formik.setFieldValue('locationType', 'Zip', true)
                    }}
                  />
                  <div hidden={formik.values.locationType !== 'State'}>
                    <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. states below.' />
                    <EuiSpacer size='s' />

                    <EuiFormRow id='stateFinder' label='Search for a state' fullWidth isInvalid={!!formik.touched.states && !!formik.errors.states} error={formik.errors.states}>
                      <NewRegionFinder addRegion={regionToAdd => formik.setFieldValue('states', [...formik.values.states, regionToAdd])} isInvalid={!!formik.touched.states && !!formik.errors.states} />
                    </EuiFormRow>
                    {formik.values.states && formik.values.states.length > 0 && (
                      <EuiFormRow label='Your campaign will target these states' fullWidth>
                        <NewRegionTargeter
                          regions={formik.values.states}
                          onRegionRemoved={regionId => {
                            formik.setFieldValue(
                              'states',
                              formik.values.states.filter(x => x.id !== regionId),
                              true
                            )
                          }}
                        />
                      </EuiFormRow>
                    )}
                  </div>

                  <div hidden={formik.values.locationType !== 'DMA'}>
                    <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more metro areas / DMAs below.' />
                    <EuiSpacer size='s' />

                    <EuiFormRow id='dmaFinder' label='Search for a metro area' fullWidth isInvalid={!!formik.touched.dmas && !!formik.errors.dmas} error={formik.errors.dmas}>
                      <NewDmaFinder addDma={dmaToAdd => formik.setFieldValue('dmas', [...formik.values.dmas, dmaToAdd])} isInvalid={!!formik.touched.dmas && !!formik.errors.dmas} />
                    </EuiFormRow>
                    {formik.values.dmas && formik.values.dmas.length > 0 && (
                      <EuiFormRow label='Your campaign will target these metro areas' fullWidth>
                        <NewDmaTargeter
                          dmas={formik.values.dmas}
                          onDmaRemoved={dmaId => {
                            formik.setFieldValue(
                              'dmas',
                              formik.values.dmas.filter(x => x.id !== dmaId),
                              true
                            )
                          }}
                        />
                      </EuiFormRow>
                    )}
                  </div>

                  <div hidden={formik.values.locationType !== 'City'}>
                    <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. cities by name.' />
                    <EuiSpacer size='s' />

                    <EuiFormRow id='cityFinder' label='Search for a city' fullWidth isInvalid={!!formik.touched.cities && !!formik.errors.cities} error={formik.errors.cities}>
                      <NewCityFinder onCityClicked={city => formik.setFieldValue('cities', [...formik.values.cities.filter(c => c.id !== city.id), city])} isInvalid={!!formik.touched.cities && !!formik.errors.cities} />
                    </EuiFormRow>
                    {formik.values.cities && formik.values.cities.length > 0 && (
                      <EuiFormRow label='Your campaign will target these cities' fullWidth>
                        <NewCityTargeter
                          cities={formik.values.cities}
                          onCityRemoved={cityId => {
                            formik.setFieldValue(
                              'cities',
                              formik.values.cities.filter(c => c.id !== cityId)
                            )
                          }}
                        />
                      </EuiFormRow>
                    )}
                  </div>

                  <div hidden={formik.values.locationType !== 'Zip'}>
                    <EuiCallOut size='s' iconType='pinFilled' color='success' title='Search and select one or more U.S. zip codes.' />
                    <EuiSpacer size='s' />

                    <EuiFormRow id='zipFinder' label='Search for a zip code' fullWidth isInvalid={!!formik.touched.zips && !!formik.errors.zips} error={formik.errors.zips}>
                      <NewPostalCodeFinder onPostalCodeClicked={zip => formik.setFieldValue('zips', [...formik.values.zips.filter(z => z.id !== zip.id), zip])} isInvalid={!!formik.touched.zips && !!formik.errors.zips} />
                    </EuiFormRow>
                    {formik.values.zips && formik.values.zips.length > 0 && (
                      <EuiFormRow label='Your campaign will target these zip codes' fullWidth>
                        <ZipTargeter
                          zips={formik.values.zips.map(z => ({ id: z.id.toString(), name: z.name }))}
                          onZipRemoved={zip => {
                            formik.setFieldValue(
                              'zips',
                              formik.values.zips.filter(z => z.id.toString() !== zip.id)
                            )
                          }}
                        />
                      </EuiFormRow>
                    )}
                  </div>
                  <EuiSpacer size='xl' />

                  <EuiTitle size='s'>
                    <span>Demographic Prioritization</span>
                  </EuiTitle>
                  <EuiSpacer size='s' />
                  <EuiFormRow label='Age ranges' fullWidth>
                    <AgeRangeTargeter
                      ageRanges={formik.values.ageRanges}
                      setAgeRanges={ageRanges => {
                        formik.setFieldValue('ageRanges', ageRanges, true)
                      }}
                    />
                  </EuiFormRow>
                  <EuiSpacer size='s' />
                  <EuiFormRow label='Gender' fullWidth>
                    <GenderTargeter
                      genders={formik.values.genders}
                      setGenders={genders => {
                        formik.setFieldValue('genders', genders, true)
                      }}
                    />
                  </EuiFormRow>
                  <EuiSpacer size='s' />
                  <EuiFormRow label='Household income' fullWidth>
                    <HouseholdIncomeTargeter
                      householdIncomes={formik.values.householdIncomes}
                      setHouseholdIncomes={householdIncomes => {
                        formik.setFieldValue('householdIncomes', householdIncomes, true)
                      }}
                    />
                  </EuiFormRow>
                  <EuiSpacer />
                </React.Fragment>
              )}
              {whiteLabel?.isAgencies() && (
                <React.Fragment>
                  <EuiSpacer size='xl' />
                  <EuiTitle size='s'>
                    <span>Advanced Targeting Options</span>
                  </EuiTitle>
                  <EuiSpacer size='s' />
                  <EuiFormRow fullWidth label={'Select which hours of the day your ad will run. (Uses the audience time zone)'}>
                    <DayPartPicker
                      dayParts={formik.values.dayParts}
                      onSelectedDayPartsChange={days => {
                        formik.setFieldValue('dayParts', days)
                      }}
                    />
                  </EuiFormRow>
                  <EuiSpacer size='s' />
                  <EuiFormRow label='Inventory'>
                    <EuiFlexGrid direction='column'>
                      <EuiFlexItem>
                        <EuiCheckbox
                          id='targetWebCheck'
                          checked={formik.values.inventorySettings.targetWeb}
                          label='Target Web'
                          onChange={e => {
                            formik.setFieldValue('inventorySettings.targetWeb', e.target.checked)
                          }}
                        />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiCheckbox
                          id='targetAppCheck'
                          checked={formik.values.inventorySettings.targetApp}
                          label='Target Apps'
                          onChange={e => {
                            formik.setFieldValue('inventorySettings.targetApp', e.target.checked)
                          }}
                        />
                      </EuiFlexItem>
                    </EuiFlexGrid>
                  </EuiFormRow>
                  <EuiFormRow label='Device'>
                    <EuiFlexGrid direction='column'>
                      <EuiFlexItem>
                        <EuiCheckbox
                          id='targetPcCheck'
                          checked={formik.values.deviceSettings.targetPc}
                          label='Target PC'
                          onChange={e => {
                            formik.setFieldValue('deviceSettings.targetPc', e.target.checked)
                          }}
                        />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiCheckbox
                          id='targetPhoneCheck'
                          checked={formik.values.deviceSettings.targetPhone}
                          label='Target Phone'
                          onChange={e => {
                            formik.setFieldValue('deviceSettings.targetPhone', e.target.checked)
                          }}
                        />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiCheckbox
                          id='targetTabletCheck'
                          checked={formik.values.deviceSettings.targetTablet}
                          label='Target Tablet'
                          onChange={e => {
                            formik.setFieldValue('deviceSettings.targetTablet', e.target.checked)
                          }}
                        />
                      </EuiFlexItem>
                    </EuiFlexGrid>
                  </EuiFormRow>
                  <EuiFormRow label='Frequency Capping (0 is unlimited)'>
                    <EuiFlexGroup>
                      <EuiFlexItem>
                        <EuiFieldNumber
                          name='frequency'
                          value={initialValues.frequency!}
                          onChange={e => {
                            formik.setFieldValue('frequency', Number(e.target.value))
                          }}
                        />
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <EuiText size='xs'>per</EuiText>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiSelect
                          options={frequencyOptions}
                          value={initialValues.frequencyUnit}
                          onChange={e => {
                            formik.setFieldValue('frequencyUnit', e.target.value)
                          }}
                        />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFormRow>
                </React.Fragment>
              )}
              <EuiSpacer />

              <EuiShowFor sizes={['xs', 's']}>
                <EuiFlexGroup>
                  <EuiFlexItem grow={false}>
                    <EuiButton id='finalize' fill type='button' isLoading={formik.isSubmitting} onClick={proceed} isDisabled={!canContinue}>
                      Continue
                    </EuiButton>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiButton id='back' fill type='button' color='text' onClick={returnTargetingChooseStep}>
                      Back
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiShowFor>

              <EuiHideFor sizes={['xs', 's']}>
                <EuiFlexGroup>
                  <EuiFlexItem grow={false}>
                    <EuiButton id='back' fill type='button' color='text' onClick={returnTargetingChooseStep} iconType='arrowLeft' iconSide='left'>
                      Back
                    </EuiButton>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiButton id='finalize' fill type='button' isLoading={formik.isSubmitting} iconType='arrowRight' iconSide='right' onClick={proceed} isDisabled={!canContinue}>
                      Continue
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiHideFor>
            </EuiForm>
          </FormikProvider>
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: 260 }}>
          <EuiText size='xs'>
            <h3>Best Practices</h3>
            <h5>Demographic Restriction</h5>
            <p>Keep in mind that specifying one or more age(s), gender(s), and household income levels will reduce the size of the audience your ads will reach.</p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default TargetAdvancedAudienceStep
