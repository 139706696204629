import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ReactPlayer from 'react-player'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { VideoDetails } from 'workflows/CampaignCreateWorkflow'

import { EuiButton, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHideFor, EuiShowFor, EuiSpacer, EuiText, EuiTextArea, EuiTitle } from '@elastic/eui'

import { apiGetTvCommercialVideoGenericPreviewUrl, apiGetTvCommercialVideoPreviewUrl } from 'api/adcritterApi'
import { TvCommercialPreviewCustomization } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import { ICampaignBuilderWorkflowStep } from 'features/builders/createCampaign/creativeBuilder/CreativeBuilderWorkflowManager'
import { useCampaignBuilderActions } from 'features/builders/createCampaign/useCampaignBuilderActions'

export const AdBuilderTvPage: React.FC<ICampaignBuilderWorkflowStep> = props => {
  const {
    creativeInfo: { videoDetails, creativeId },
    businessInfo
  } = useSelector((state: RootState) => state.campaignCreateWorkflow)
  const { createTvComplete } = useCampaignBuilderActions()
  const processingUrl = 'https://video.adcritter.com/000-templates/processing.mp4'
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [isExportingVideo, setIsExportingVideo] = useState(false)
  const [previewUrl, setPreviewUrl] = useState<string>(processingUrl)
  const [playing, setPlaying] = useState(false)
  const [loop, setLoop] = useState(true)
  const [isLoadingPreview, setIsLoadingPreview] = useState(false)
  const [controls, setControls] = useState(false)
  const [adDetails, setAdDetails] = useState<VideoDetails>({
    creativeId: uuidv4(),
    baseVideoId: '',
    kind: '',
    name: '',
    previewUrl: '',
    quality: '',
    script: '',
    sections: [],
    thumbnailUrl: '',
    type: ''
  })

  useEffect(() => {
    if (videoDetails && creativeId) {
      const details = videoDetails.find(d => d.creativeId === creativeId)
      if (details) {
        setAdDetails({ ...details, sections: [...details.sections] })
      }
    }
  }, [videoDetails, creativeId])

  useEffect(() => {
    if (!currentAccount || !adDetails.baseVideoId || !businessInfo.businessTypeId) {
      return
    }

    if (!adDetails.previewUrl) {
      setIsExportingVideo(true)
      setPreviewUrl(processingUrl)
      setLoop(true)
      setPlaying(true)
      setControls(false)
      setIsLoadingPreview(true)
      apiGetTvCommercialVideoGenericPreviewUrl(currentAccount.id, adDetails.baseVideoId, businessInfo.businessTypeId).then(result => {
        const checkExist = setInterval(async function () {
          if (doesVideoExist(result.data)) {
            clearInterval(checkExist)
            setPreviewUrl(result.data)
            setLoop(false)
            setControls(true)
            setPlaying(false)
            setIsExportingVideo(false)
            setIsLoadingPreview(false)
          }
        }, 1000)
      })
    } else {
      setPreviewUrl(adDetails.previewUrl)
      setLoop(false)
      setControls(true)
    }
  }, [currentAccount, adDetails.previewUrl, businessInfo.businessTypeId])

  const doesVideoExist = (url: string) => {
    let http = new XMLHttpRequest()
    http.open('HEAD', url, false)
    try {
      http.send()
      return http.status === 200
    } catch {
      return false
    }
  }

  const refreshVideoUrl = () => {
    setIsExportingVideo(true)
    setPreviewUrl(processingUrl)
    setLoop(true)
    setPlaying(true)
    setControls(false)
    setIsLoadingPreview(true)
    apiGetTvCommercialVideoPreviewUrl(currentAccount!.id, adDetails.baseVideoId, { sections: adDetails.sections } as TvCommercialPreviewCustomization).then(result => {
      const checkExist = setInterval(async function () {
        if (doesVideoExist(result.data)) {
          clearInterval(checkExist)
          setPreviewUrl(result.data)
          setLoop(false)
          setControls(true)
          setPlaying(false)
          setIsExportingVideo(false)
          setIsLoadingPreview(false)
        }
      }, 1000)
    })
  }

  const onBackClick = () => {
    props.onBack?.()
  }

  const setSection = (index: number, value: string) => {
    const newSections = [...adDetails.sections]
    newSections[index] = value
    setAdDetails({ ...adDetails, sections: newSections })
  }

  const onContinueToTargetingClick = () => {
    createTvComplete(adDetails)
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>TV Commercial Builder</title>
      </Helmet>
      <React.Fragment>
        <EuiFlexGroup responsive={false}>
          <EuiFlexItem grow={true} style={{ minWidth: 280 }}>
            <EuiTitle size='s'>
              <h3>Commercial Editor</h3>
            </EuiTitle>
            <EuiText size='s'>
              <p>Edit the text of your TV commercial, or leave it as-is.</p>
            </EuiText>
            <EuiSpacer />

            {adDetails.type === 'Text' && (
              <React.Fragment>
                <EuiText size='s'>
                  <h4>Commercial Text</h4>
                </EuiText>
                <EuiSpacer size='m' />
                {adDetails.sections.map((section: string, index: number) => (
                  <EuiFormRow key={`section${index}`} label={`Section ${index + 1}`} fullWidth>
                    <EuiTextArea name={`sections[${index}]`} value={section.replace('\\n', '\n')} onChange={e => setSection(index, e.target.value.replace('\n', '\\n'))} rows={2} fullWidth />
                  </EuiFormRow>
                ))}
              </React.Fragment>
            )}
            {adDetails.type === 'VoiceOver' && (
              <React.Fragment>
                <EuiText size='s'>
                  <h4>Commercial Script</h4>
                </EuiText>
                <EuiText size='xs' color={'subdued'}>
                  <span>If you change the script below, We will re-record the voice over with your changes. Small changes are best.</span>
                </EuiText>
                <EuiSpacer size='m' />
                <EuiFormRow label='Script' fullWidth>
                  <EuiTextArea
                    name='script'
                    value={adDetails.script ?? ''}
                    onChange={e => {
                      setAdDetails({ ...adDetails, script: e.target.value })
                    }}
                    fullWidth
                    rows={10}
                  />
                </EuiFormRow>
              </React.Fragment>
            )}
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ width: 640 }}>
            <EuiText size='s'>
              <h4>Preview Your TV Commercial</h4>
            </EuiText>
            <EuiSpacer size='m' />
            <ReactPlayer url={previewUrl} playing={playing} controls={controls} loop={loop} />
            {(isLoadingPreview || isExportingVideo) && (
              <React.Fragment>
                <EuiSpacer size='m' />
                <EuiCallOut title='Please wait while your commercial is updated. Allow approx. 30 secs.' size='s' iconType='scale' color='primary' />
              </React.Fragment>
            )}
            <EuiSpacer size='m' />
            {adDetails.type === 'Text' && (
              <EuiFlexGroup gutterSize='none' style={{ flexGrow: 0 }}>
                <EuiFlexItem style={{ width: 200 }} grow={false}>
                  <EuiButton id='refresh' color='success' size='s' onClick={refreshVideoUrl} style={{ width: 200 }} fill disabled={isExportingVideo}>
                    Update With My Text
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer />

        <EuiShowFor sizes={['xs', 's']}>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton id='continue' fill onClick={onContinueToTargetingClick}>
                Continue to Targeting
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton id='back' fill type='button' color='text' onClick={onBackClick}>
                Back
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiShowFor>

        <EuiHideFor sizes={['xs', 's']}>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton id='back' fill type='button' color='text' onClick={onBackClick} iconType='arrowLeft' iconSide='left'>
                Back
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton id='continue' fill iconType='arrowRight' iconSide='right' onClick={onContinueToTargetingClick}>
                Continue to Targeting
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiHideFor>
      </React.Fragment>
    </React.Fragment>
  )
}
