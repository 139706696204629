import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { NativeDetails } from 'workflows/CampaignCreateWorkflow'

import { EuiButton, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHideFor, EuiIcon, EuiLink, EuiSelect, EuiShowFor, EuiSpacer, EuiTabbedContent, EuiText, EuiTextArea, EuiTitle, EuiToolTip } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'
import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { AcImage } from 'api/interfaces'
import { RootState } from 'app/rootReducer'
import { AcImagePickerFlyout } from 'components/chooseImage/AcImagePickerFlyout'
import { fetchUploadedImages } from 'components/chooseImage/searchImageSlice'
import { ICampaignBuilderWorkflowStep } from 'features/builders/createCampaign/creativeBuilder/CreativeBuilderWorkflowManager'
import { NativePreview, PreviewSize } from 'features/builders/createCampaign/creativeBuilder/Previews/NativePreview'
import { useCampaignBuilderActions } from 'features/builders/createCampaign/useCampaignBuilderActions'

export const AdBuilderInternetPage: React.FC<ICampaignBuilderWorkflowStep> = props => {
  const { createNativeComplete } = useCampaignBuilderActions()
  const dispatch = useDispatch()
  const dictionary = useSelector((state: RootState) => state.dictionary)
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const {
    creativeInfo: { nativeDetails, creativeId },
    businessInfo
  } = useSelector((state: RootState) => state.campaignCreateWorkflow)
  const [adBuilder, setAdBuilder] = useState<NativeDetails>({
    headline: '',
    body: '',
    companyName: '',
    buttonText: 'Learn More',
    clickUrl: ''
  })
  const [showImagePicker, setShowImagePicker] = useState(false)

  useEffect(() => {
    if ((nativeDetails && creativeId) || businessInfo) {
      const details = nativeDetails?.find(d => d.creativeId === creativeId) ?? {
        headline: '',
        body: '',
        companyName: '',
        buttonText: 'Learn More',
        clickUrl: ''
      }
      setAdBuilder({ ...details, clickUrl: details?.clickUrl ?? businessInfo?.websiteUrl, companyName: details?.companyName ?? businessInfo?.companyName })
    }
  }, [nativeDetails, creativeId, businessInfo])

  useEffect(() => {
    if (currentAccount) {
      dispatch(fetchUploadedImages(currentAccount.id))
    }
  }, [currentAccount, dispatch])

  const previewTabs: Array<EuiTabbedContentTab> = [
    {
      id: 'DesktopWeb',
      name: 'Large Example',
      content: <NativePreview nativeDetails={adBuilder} previewSize={PreviewSize.Large} />
    },
    {
      id: 'MobileInApp',
      name: 'Medium Example',
      content: <NativePreview nativeDetails={adBuilder} previewSize={PreviewSize.Medium} />
    },
    {
      id: 'MobileWeb',
      name: 'Small Example',
      content: <NativePreview nativeDetails={adBuilder} previewSize={PreviewSize.Small} />
    }
  ]

  const returnChoose = () => {
    props.onBack?.()
  }

  const onNextClick = () => {
    createNativeComplete(adBuilder)
  }

  const changeImageClick = () => {
    setShowImagePicker(true)
  }

  const onChooseImage = (acImage: AcImage) => {
    setAdBuilder({ ...adBuilder, acImage })
    setShowImagePicker(false)
  }

  const formIsValid = () => {
    if (!adBuilder.headline || adBuilder.headline?.length > 50 || adBuilder.headline?.length <= 0) {
      return false
    }
    if (!adBuilder.body || adBuilder.body?.length > 150 || adBuilder.body?.length <= 0) {
      return false
    }
    if (!adBuilder.companyName || adBuilder.companyName?.length > 35 || adBuilder.companyName?.length <= 0) {
      return false
    }
    if (!adBuilder.clickUrl || adBuilder.clickUrl?.length > 2048 || adBuilder.clickUrl?.length <= 0) {
      return false
    }
    return !!adBuilder.acImage
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Ad Builder</title>
      </Helmet>
      <EuiFlexGroup responsive={false}>
        <EuiFlexItem>
          <EuiTitle size='s'>
            <h3>Ad Builder</h3>
          </EuiTitle>
          <EuiSpacer />

          <EuiFlexGroup responsive={false}>
            <EuiFlexItem grow={true} style={{ minWidth: 280 }}>
              <React.Fragment>
                <EuiText size='s'>
                  <h4>Ad Image</h4>
                </EuiText>
                <EuiSpacer size='s' />
                <div className='euiFilePicker euiFilePicker--fullWidth euiFilePicker--large euiFilePicker-hasFiles'>
                  <div className='euiFiePicker__wrap'>
                    <div className='euiFilePicker__prompt'>
                      {adBuilder.acImage ? (
                        <React.Fragment>
                          <div
                            style={{
                              width: 82,
                              borderRadius: 8,
                              marginTop: 10,
                              overflow: 'hidden'
                            }}>
                            <img src={adBuilder.acImage.thumbnailUrl} alt={adBuilder.acImage.id} width={82} />
                          </div>
                        </React.Fragment>
                      ) : (
                        <EuiIcon type='image' className='euiFilePicker__icon' size='l' aria-label='choose image' />
                      )}
                      <EuiLink onClick={changeImageClick} className='euiFilePicker__clearButton' id='changeImage'>
                        {adBuilder.acImage ? 'Change Image' : 'Select Image'}
                      </EuiLink>
                      <EuiSpacer size={'s'} />
                    </div>
                  </div>
                </div>
              </React.Fragment>
              <EuiSpacer size='m' />
              <EuiText size='s'>
                <h4>Ad Text & Links</h4>
              </EuiText>
              <EuiSpacer size='s' />
              <EuiFormRow
                label='Headline'
                fullWidth
                isInvalid={(adBuilder.headline || '').length > 50 || (adBuilder.headline || '').length <= 0}
                error={(adBuilder.headline || '').length > 50 ? 'Headline must be 50 characters or less' : undefined}
                labelAppend={
                  <EuiText size='xs' color={(adBuilder.headline || '').length <= 50 ? 'default' : 'warning'}>
                    ({(adBuilder.headline || '').length}/50)
                  </EuiText>
                }>
                <EuiFieldText name='headline' value={adBuilder.headline} placeholder='Your attention-grabbing headline.' onChange={e => setAdBuilder({ ...adBuilder, headline: e.target.value })} fullWidth />
              </EuiFormRow>
              <EuiFormRow
                label='Body'
                fullWidth
                isInvalid={(adBuilder.body?.length ?? 0) > 150 || (adBuilder.body?.length ?? 0) <= 0}
                error={(adBuilder.body?.length ?? 0) > 150 ? 'Body must be 150 characters or less' : undefined}
                labelAppend={
                  <EuiText size='xs' color={(adBuilder.body?.length ?? 0) <= 150 ? 'default' : 'warning'}>
                    ({adBuilder.body?.length ?? 0}/150)
                  </EuiText>
                }>
                <EuiTextArea name='body' defaultValue={adBuilder.body} placeholder='Your one or two sentence description of your product or service.' onChange={e => setAdBuilder({ ...adBuilder, body: e.target.value })} resize='none' rows={3} fullWidth />
              </EuiFormRow>
              <EuiFormRow
                label='Company Name'
                fullWidth
                isInvalid={(adBuilder.companyName?.length ?? 0) > 35 || (adBuilder.companyName?.length ?? 0) <= 0}
                error={(adBuilder.companyName?.length ?? 0) > 35 ? 'Company Name must be 35 characters or less' : undefined}
                labelAppend={
                  <EuiText size='xs' color={(adBuilder.companyName?.length ?? 0) <= 35 ? 'default' : 'warning'}>
                    <EuiToolTip position='bottom' content='This should match the content of the ad and landing page.'>
                      <EuiIcon type='iInCircle' style={{ marginBottom: 2 }} color='success' />
                    </EuiToolTip>
                    ({adBuilder.companyName?.length ?? 0}/35)
                  </EuiText>
                }>
                <EuiFieldText name='companyName' value={adBuilder.companyName} placeholder='Your Company Name' onChange={e => setAdBuilder({ ...adBuilder, companyName: e.target.value })} fullWidth />
              </EuiFormRow>
              <EuiFormRow label='Call-to-Action Button' fullWidth>
                <EuiSelect
                  name='callToAction'
                  isLoading={dictionary.isLoadingCallToActions}
                  options={dictionary.callToActions.map(
                    ca =>
                      ({
                        value: ca,
                        label: ca,
                        text: ca
                      } as EuiSelectOption)
                  )}
                  value={adBuilder.buttonText}
                  onChange={e => setAdBuilder({ ...adBuilder, buttonText: e.target.value })}
                  fullWidth
                  hasNoInitialSelection={true}
                />
              </EuiFormRow>
              <EuiFormRow
                label='Website URL'
                fullWidth
                isInvalid={(adBuilder.clickUrl || '').length > 2048 || (adBuilder.clickUrl || '').length <= 0}
                error={(adBuilder.clickUrl || '').length > 2048 ? 'Website URL must be 2048 characters or less' : undefined}
                labelAppend={
                  <EuiText size='xs' color={(adBuilder.clickUrl || '').length <= 2048 ? 'default' : 'warning'}>
                    <EuiToolTip position='bottom' content='This should be the page that you want users to land on when they click on this ad.'>
                      <EuiIcon type='iInCircle' style={{ marginBottom: 2 }} color='success' />
                    </EuiToolTip>
                    ({(adBuilder.clickUrl || '').length}/2048)
                  </EuiText>
                }>
                <EuiFieldText name='destinationUrl' value={adBuilder.clickUrl} placeholder='https://www.domain.com' onChange={e => setAdBuilder({ ...adBuilder, clickUrl: e.target.value })} fullWidth />
              </EuiFormRow>
              <EuiSpacer size='m' />
              <EuiShowFor sizes={['xs', 's']}>
                <React.Fragment>
                  <EuiSpacer />
                  <EuiFlexGroup>
                    <EuiFlexItem grow={false}>
                      <EuiButton fill onClick={onNextClick} isDisabled={!formIsValid()}>
                        Continue to Targeting
                      </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton id='back' fill type='button' color='text' onClick={returnChoose}>
                        Back
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </React.Fragment>
              </EuiShowFor>
            </EuiFlexItem>
            <EuiFlexItem grow={false} style={{ width: 540 }}>
              <EuiText size='s'>
                <h4>Preview Your Ad</h4>
              </EuiText>
              <EuiTabbedContent tabs={previewTabs} />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
          <EuiHideFor sizes={['xs', 's']}>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButton id='back' fill type='button' color='text' onClick={returnChoose} iconType='arrowLeft' iconSide='left'>
                  Back
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton fill iconType='arrowRight' iconSide='right' onClick={onNextClick} isDisabled={!formIsValid()}>
                  Continue to Targeting
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiHideFor>
        </EuiFlexItem>
        <EuiHideFor sizes={['s']}>
          <EuiFlexItem grow={false} style={{ minWidth: 250, width: 250 }}>
            <EuiSpacer size='l' />
            <EuiText size='s'>
              <h3>Best Practices</h3>
              <h5>Headlines that Compel</h5>
              <p>Writing a catchy headline for your ad is important. For example, “Springfield Parents love this Smartphone” will perform better than “Smartphones for sale in Springfield.” Make sure that what you say in the headline is consistent with what they’ll find on the landing page when they click.</p>
              <h5>Image Selection: Think Different</h5>
              <p>Choose images that are colorful and grab attention. For example, a veterinarian might choose images of cute pets rather than a picture of their building.</p>
            </EuiText>
          </EuiFlexItem>
        </EuiHideFor>
      </EuiFlexGroup>
      {showImagePicker && <AcImagePickerFlyout onClose={() => setShowImagePicker(false)} onChoose={onChooseImage} />}
    </React.Fragment>
  )
}
