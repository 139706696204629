import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiButton, EuiEmptyPrompt, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiShowFor, EuiSpacer, EuiText, htmlIdGenerator } from '@elastic/eui'

import { AcImage } from 'api/interfaces'
import { useGetInternetImagesQuery, useGetInternetTemplatesQuery } from 'api/rtkQueryApi/platform/creativesApi'
import { RootState } from 'app/rootReducer'
import { setImage } from 'features/builders/internet/retail/builderInternetRetailSlice'

import { SuggestedAd } from './SuggestedAd'

interface SuggestedAdsProps {
  companyName?: string
  businessTypeId: string
  cityName?: string
  proceed(image: AcImage, headline: string, body: string): void
}

const InternetSuggestedAds = ({ proceed, companyName, businessTypeId, cityName }: SuggestedAdsProps) => {
  const { currentAccount } = useSelector((state: RootState) => state.app)
  const [suggestedAds, setSuggestedAds] = useState<Array<ReactNode>>([])
  const [imageIndex, setImageIndex] = useState(0)
  const [headlineIndex, setHeadlineIndex] = useState(0)
  const [showSeeMore, setShowSeeMore] = useState(true)

  const internetTemplatesQuery = useGetInternetTemplatesQuery({ accountId: currentAccount?.id ?? '', businessTypeId: businessTypeId, companyName: companyName ?? '', cityName: cityName ?? '' }, { skip: !currentAccount })
  const internetImagesQuery = useGetInternetImagesQuery({ businessTypeId: businessTypeId ?? '' }, { skip: !businessTypeId })

  useEffect(() => {
    if (internetTemplatesQuery.data && internetImagesQuery.data) {
      setImageIndex(0)
      loadSuggestions()
    }
  }, [internetTemplatesQuery.data, internetImagesQuery.data])

  const loadSuggestions = useCallback(() => {
    if (!internetTemplatesQuery.data || !internetImagesQuery.data) {
      return
    }

    let index = imageIndex

    const suggestion = internetTemplatesQuery.data
    const images = internetImagesQuery.data
    const nextImage = () => {
      index++
      if (index >= images.length) index = 0
      return images[index]
    }

    if (images && images.length > 0 && suggestion.headlines && suggestion.bodies) {
      let h = headlineIndex
      let count: number
      let rows: Array<ReactNode> = []
      for (count = 0; count < 30; count++) {
        let bodies: string[] = []
        const headline = suggestion.headlines[h++]
        if (typeof headline === 'undefined') {
          setShowSeeMore(false)
          break
        }
        switch (headline.templateType) {
          case 'BusinessType':
            bodies = suggestion.bodies.filter(b => b.templateType === 'BusinessType').map(b => b.value)
            break
          case 'NounPhrase':
            bodies = suggestion.bodies.filter(b => b.templateType === 'WeActionPhrase' || b.templateType === 'YouActionPhrase').map(b => b.value)
            break
          case 'WeActionPhrase':
            bodies = suggestion.bodies.filter(b => b.templateType === 'NounPhrase' || b.templateType === 'YouActionPhrase').map(b => b.value)
            break
          case 'YouActionPhrase':
            bodies = suggestion.bodies.filter(b => b.templateType === 'WeActionPhrase' || b.templateType === 'NounPhrase').map(b => b.value)
            break
        }
        if (bodies.length > 0) rows.push(<SuggestedAd key={htmlIdGenerator()()} headline={headline.value} image={nextImage()} bodies={bodies} bodyIndex={Math.floor(Math.random() * bodies.length)} companyName={companyName ?? '{Company Name}'} proceed={proceedWithSuggestion} />)
      }

      setSuggestedAds([...suggestedAds, rows])
      setImageIndex(index)
      setHeadlineIndex(h)
    }
  }, [internetTemplatesQuery.data, internetImagesQuery.data])

  const seeMore = () => {
    loadSuggestions()
  }

  const proceedWithSuggestion = (image: AcImage, headline: string, body: string) => {
    proceed(image, headline, body)
    setImage(image)
  }

  return (
    <React.Fragment>
      {internetTemplatesQuery.isLoading || internetImagesQuery.isLoading ? (
        <EuiFlexGroup justifyContent='spaceAround'>
          <EuiFlexItem grow={false}>
            <EuiEmptyPrompt
              title={
                <EuiText size='s'>
                  <EuiLoadingSpinner size='xl' />
                  <h2>Your Suggested Ads</h2>
                </EuiText>
              }
              body={
                <EuiText size='s'>
                  <p>Please wait while we retrieve your suggested ads.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      ) : (
        <React.Fragment>
          <EuiShowFor sizes={['s', 'xs']}>
            <EuiFlexGrid columns={1} responsive={true}>
              {suggestedAds}
            </EuiFlexGrid>
          </EuiShowFor>
          <EuiShowFor sizes={['l', 'm']}>
            <EuiFlexGrid columns={2} responsive={true}>
              {suggestedAds}
            </EuiFlexGrid>
          </EuiShowFor>
          <EuiShowFor sizes={['xl']}>
            <EuiFlexGrid columns={3} responsive={true}>
              {suggestedAds}
            </EuiFlexGrid>
          </EuiShowFor>
          <EuiSpacer />
          {showSeeMore && (
            <EuiFlexGroup justifyContent='spaceAround'>
              <EuiFlexItem grow={false}>
                <EuiButton onClick={seeMore} iconType='arrowDown' iconSide='left' color='success'>
                  See More Suggested Ads
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default InternetSuggestedAds
