import React, { useState } from 'react'

import { EuiButton, EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiHideFor, EuiImage, EuiPanel, EuiText } from '@elastic/eui'

import { AcImage } from 'api/interfaces'

export interface SuggestedAdProps {
  headline: string
  bodies: Array<string>
  companyName: string
  bodyIndex: number
  image: AcImage

  proceed(image: AcImage, headline: string, body: string): void
}

export const SuggestedAd = ({ headline, bodies, companyName, bodyIndex, image, proceed }: SuggestedAdProps) => {
  const [index, setIndex] = useState(bodyIndex)

  return (
    <EuiFlexItem className='suggestedAdCard' grow={true} style={{ marginBottom: 32 }}>
      <EuiPanel grow={true} paddingSize='none' hasShadow={true}>
        <EuiFlexGroup responsive={false}>
          <EuiFlexItem style={{ padding: 10 }}>
            <EuiText size='s'>
              <h3>{headline}</h3>
              <p>{bodies[index]}</p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiImage alt='ad' url={image.highQualityUrl} size='fullWidth' />
        <EuiFlexGroup responsive={true} style={{ padding: 10 }}>
          <EuiFlexItem grow={3} style={{ justifyContent: 'center' }}>
            <EuiText size='m' color='subdued'>
              <strong>{companyName}</strong>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={1} style={{ paddingRight: 10 }}>
            <EuiButton id='callToAction' onClick={() => {}} fullWidth={false} disabled style={{ borderRadius: 0 }}>
              Learn More
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
      <EuiFlexGroup responsive={true} style={{ padding: 10 }} alignItems='flexStart'>
        <EuiFlexItem>
          <EuiButton
            id='useEditBtn'
            className='useEditButton'
            color='primary'
            onClick={() => {
              proceed(image, headline, bodies[index])
            }}
            fullWidth
            fill>
            Use/Edit This Ad
          </EuiButton>
        </EuiFlexItem>
        <EuiHideFor sizes={['xs', 's']}>
          <EuiFlexItem style={{ minWidth: 150 }}>
            <EuiText size='s' textAlign='right'>
              <EuiButtonIcon
                id='left'
                iconType='arrowLeft'
                aria-label='previous variant'
                onClick={() => {
                  setIndex(index - 1 === 0 ? bodies.length : index - 1)
                }}
              />
              Copy Variations
              <EuiButtonIcon
                id='right'
                iconType='arrowRight'
                aria-label='next variant'
                onClick={() => {
                  setIndex(index + 1 === bodies.length ? 0 : index + 1)
                }}
              />
            </EuiText>
          </EuiFlexItem>
        </EuiHideFor>
      </EuiFlexGroup>
    </EuiFlexItem>
  )
}
