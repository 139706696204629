import { Autocomplete, DrawingManager, GoogleMap, Marker } from '@react-google-maps/api'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { EuiButton, EuiButtonGroup, EuiCallOut, EuiFieldSearch, htmlIdGenerator } from '@elastic/eui'

import { GeoCircle, GeoPoint, GeoPolygon, GeoRectangle } from 'api/interfaces'
import { LocationTargeting } from 'api/interfaces/CampaignTargeting'
import { useLazyGetBillboardGeosInRangeQuery } from 'api/rtkQueryApi/platform/geoDataApi'
import MapControl from 'components/MapControl'
import AcCircle from 'components/acGoogleMaps/AcCircle'
import AcPolygon from 'components/acGoogleMaps/AcPolygon'
import AcRectangle from 'components/acGoogleMaps/AcRectangle'
import { useDebounceEffect } from 'utils/useDebounceEffect'

const mapOptions: google.maps.MapOptions = {
  scrollwheel: false,
  streetViewControl: false,
  fullscreenControl: false,
  zoomControl: false,
  rotateControl: false,
  mapTypeControl: false,
  mapTypeId: 'roadmap',
  clickableIcons: false
}
const circleOptions: google.maps.CircleOptions = {
  fillColor: '#313FFE',
  fillOpacity: 0.15,
  strokeColor: '#cc6600',
  strokeWeight: 2,
  clickable: true,
  draggable: true,
  zIndex: 1
}
const rectangleOptions: google.maps.RectangleOptions = {
  fillColor: '#313FFE',
  fillOpacity: 0.15,
  strokeColor: '#cc6600',
  strokeWeight: 2,
  clickable: true,
  draggable: true,
  zIndex: 1
}
const polygonOptions: google.maps.PolygonOptions = {
  fillColor: '#313FFE',
  fillOpacity: 0.15,
  strokeColor: '#cc6600',
  strokeWeight: 2,
  clickable: true,
  draggable: true,
  zIndex: 1
}
const drawingManagerOptions: google.maps.drawing.DrawingManagerOptions = {
  drawingControl: false,
  circleOptions: circleOptions,
  rectangleOptions: rectangleOptions,
  polygonOptions: polygonOptions
}

export interface IAcTargetingMap {
  locationTargeting: LocationTargeting
  includeBillboards?: boolean
  hideLocationSearch?: boolean
  hideDrawingTools?: boolean
  targetedPlaces?: string[]
  onUpdatedLocationTargeting?: (locationTargeting: LocationTargeting) => void
}

export const AcTargetingMap: React.FC<IAcTargetingMap> = props => {
  const [getBillboardGeosInRangeQueryTrigger, billboardGeosInRangeResults] = useLazyGetBillboardGeosInRangeQuery()
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null)
  const [drawingManager, setDrawingManager] = useState<google.maps.drawing.DrawingManager | null>(null)
  const prevSelectedShape = useRef<google.maps.Marker | google.maps.Circle | google.maps.Rectangle | google.maps.Polygon | google.maps.Polyline | null>()
  const [selectedShape, setSelectedShape] = useState<google.maps.Marker | google.maps.Circle | google.maps.Rectangle | google.maps.Polygon | google.maps.Polyline | null>(null)
  const [drawingModeSelected, setDrawingModeSelected] = useState('hand')
  const [mapTypeSelected, setMapTypeSelected] = useState('roadmap')

  const fourZoomDegrees = 45
  const [mapZoom, setMapZoom] = useState(4)
  const [currentCenter, setCurrentCenter] = useState<google.maps.LatLng>()
  const [lastCenter, setLastCenter] = useState<google.maps.LatLng>()
  const [lastBBZoom, setLastBBZoom] = useState<number>(0)
  const [mapCenter, setMapCenter] = useState<google.maps.LatLng>(new google.maps.LatLng(35, -99))

  useEffect(() => {
    setMapZoom(getMapZoom())
    setMapCenter(getMapCenter())
  }, [])

  const getMapZoom = () => {
    const allCenters = props.locationTargeting?.geoCircles?.map(c => c.center) ?? []
    allCenters.push(...(props.locationTargeting?.geoRectangles?.map(c => c.center) ?? []))
    allCenters.push(...(props.locationTargeting?.geoPolygons?.map(c => c.center) ?? []))

    if (allCenters.length > 0) {
      const minLat = allCenters
        .map(c => c.lat)
        .sort(function (a, b) {
          return a - b
        })[0]
      const maxLat = allCenters
        .map(c => c.lat)
        .sort(function (a, b) {
          return b - a
        })[0]
      const minLng = allCenters
        .map(c => c.lng)
        .sort(function (a, b) {
          return a - b
        })[0]
      const maxLng = allCenters
        .map(c => c.lng)
        .sort(function (a, b) {
          return b - a
        })[0]

      const lngRange = maxLng - minLng
      const latRange = maxLat - minLat

      let maxRange = lngRange > latRange ? lngRange : latRange

      if (maxRange === 0) {
        const allRadius = props.locationTargeting?.geoCircles?.map(c => c.radius) ?? []
        allRadius.push(...(props.locationTargeting?.geoRectangles?.map(c => c.radius) ?? []))
        allRadius.push(...(props.locationTargeting?.geoPolygons?.map(c => c.radius) ?? []))

        maxRange = (allRadius[0] * 2) / 111000
      }

      if (maxRange > 0) {
        return Math.floor(4 + Math.log(fourZoomDegrees / maxRange) / Math.log(2))
      }
    }

    return 4
  }

  const getMapCenter = () => {
    const allCenters = props.locationTargeting?.geoCircles?.map(c => c.center) ?? []
    allCenters.push(...(props.locationTargeting?.geoRectangles?.map(c => c.center) ?? []))
    allCenters.push(...(props.locationTargeting?.geoPolygons?.map(c => c.center) ?? []))
    if (allCenters.length > 0) {
      const minLat = allCenters
        .map(c => c.lat)
        .sort(function (a, b) {
          return a - b
        })[0]
      const maxLat = allCenters
        .map(c => c.lat)
        .sort(function (a, b) {
          return b - a
        })[0]
      const minLng = allCenters
        .map(c => c.lng)
        .sort(function (a, b) {
          return a - b
        })[0]
      const maxLng = allCenters
        .map(c => c.lng)
        .sort(function (a, b) {
          return b - a
        })[0]

      return new google.maps.LatLng((minLat + maxLat) / 2, (minLng + maxLng) / 2)
    }

    return new google.maps.LatLng(35, -99)
  }

  const handleCenterChanged = () => {
    if (map) {
      const newCenter = map.getCenter()
      setCurrentCenter(newCenter)
      if (newCenter) {
        setMapCenter(newCenter)
      }
      if (!lastCenter) {
        setLastCenter(newCenter)
      }
    }
  }

  const onLoad = useCallback((newMap: google.maps.Map) => {
    if (!map) {
      setMap(newMap)
    }
  }, [])

  const onUnmountMap = useCallback(() => {
    map?.unbindAll()
    setMap(null)
  }, [])

  const onUnmountDrawingManager = () => {
    drawingManager?.unbindAll()
    setDrawingManager(null)
  }

  const onUnmountAutocomplete = () => {
    autocomplete?.unbindAll()
    setAutocomplete(null)
  }

  const onLoadDrawingManager = useCallback((newDrawingManager: google.maps.drawing.DrawingManager) => {
    setDrawingManager(newDrawingManager)
  }, [])

  const onLoadAutocomplete = useCallback((newAutocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(newAutocomplete)
  }, [])

  const onMapClick = () => {
    if (map) {
      setSelectedShape(null)
    }
  }

  const onPlaceChanged = () => {
    let place = autocomplete?.getPlace()

    if (map && place && place.geometry && place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport)
      setMapZoom(map.getZoom()!)
      setMapCenter(place.geometry.location!)
    }
  }

  const areGeoPointsEqual = (geoPointOne: GeoPoint, geoPointTwo: GeoPoint) => {
    const latsEqual = geoPointOne.lat === geoPointTwo.lat
    const lngsEqual = geoPointOne.lng === geoPointTwo.lng

    return latsEqual && lngsEqual
  }

  const areGeoCirclesEqual = (geoCircleOne: GeoCircle, geoCircleTwo: GeoCircle) => {
    const idsEqual = geoCircleOne.id === geoCircleTwo.id
    const geoPointsEqual = areGeoPointsEqual(geoCircleOne.center, geoCircleTwo.center)
    const radiiEqual = geoCircleOne.radius === geoCircleTwo.radius

    return idsEqual && geoPointsEqual && radiiEqual
  }

  const areGeoRectanglesEqual = (geoRectangleOne: GeoRectangle, geoRectangleTwo: GeoRectangle) => {
    const innerGeoCirclesEqual = areGeoCirclesEqual(geoRectangleOne, geoRectangleTwo)
    const northEastGeoPointsEqual = areGeoPointsEqual(geoRectangleOne.northEast, geoRectangleTwo.northEast)
    const southWestGeoPointsEqual = areGeoPointsEqual(geoRectangleOne.southWest, geoRectangleTwo.southWest)

    return innerGeoCirclesEqual && northEastGeoPointsEqual && southWestGeoPointsEqual
  }

  const areGeoPolygonsEqual = (geoPolygonOne: GeoPolygon, geoPolygonTwo: GeoPolygon) => {
    if (geoPolygonOne.points.length !== geoPolygonTwo.points.length) {
      return false
    }

    if (!areGeoCirclesEqual(geoPolygonOne, geoPolygonTwo)) {
      return false
    }

    const geoPointsOne = [...geoPolygonOne.points]
    const geoPointsTwo = [...geoPolygonTwo.points]

    for (let i = 0; i < geoPointsOne.length; i++) {
      let found = false
      const geoPointAtIndexI = geoPointsOne[i]

      for (let k = 0; k < geoPointsTwo.length; k++) {
        const geoPointTwoAtIndexK = geoPointsTwo[k]

        if (areGeoPointsEqual(geoPointAtIndexI, geoPointTwoAtIndexK)) {
          found = true
          break
        }
      }

      if (!found) {
        return false
      }
    }

    return true
  }

  const translateCircleToGeoCircle = (circle: google.maps.Circle, id: string) => {
    return {
      id: id,
      center: { lat: circle.getCenter()!.lat(), lng: circle.getCenter()!.lng() },
      radius: circle.getRadius()
    } as GeoCircle
  }

  const translateRectangleToGeoRectangle = (rectangle: google.maps.Rectangle, id: string) => {
    return {
      id: id,
      northEast: {
        lat: rectangle.getBounds()!.getNorthEast().lat(),
        lng: rectangle.getBounds()!.getNorthEast().lng()
      },
      southWest: {
        lat: rectangle.getBounds()!.getSouthWest().lat(),
        lng: rectangle.getBounds()!.getSouthWest().lng()
      },
      center: { lat: rectangle.getBounds()!.getCenter().lat(), lng: rectangle.getBounds()!.getCenter().lng() },
      radius: google.maps.geometry.spherical.computeDistanceBetween(rectangle.getBounds()!.getCenter(), rectangle.getBounds()!.getNorthEast())
    } as GeoRectangle
  }

  const translatePolygonToGeoPolygon = (polygon: google.maps.Polygon, id: string) => {
    const path: Array<google.maps.LatLng> = []
    const polygonBounds = new google.maps.LatLngBounds()

    polygon.getPath().forEach(p => {
      path.push(new google.maps.LatLng(p.lat(), p.lng()))
      polygonBounds.extend(p)
    })

    return {
      id: id,
      points: path.map(p => ({ lat: p.lat(), lng: p.lng() })),
      center: { lat: polygonBounds.getCenter().lat(), lng: polygonBounds.getCenter().lng() },
      radius: google.maps.geometry.spherical.computeDistanceBetween(polygonBounds.getCenter(), polygonBounds.getNorthEast())
    } as GeoPolygon
  }

  const onCircleChange = (circle: google.maps.Circle, id: string) => {
    const circleFromEvent = translateCircleToGeoCircle(circle, id)

    for (const circleFromState of props.locationTargeting?.geoCircles ?? []) {
      if (circleFromEvent.id !== circleFromState.id) {
        continue
      }

      if (!areGeoCirclesEqual(circleFromEvent, circleFromState)) {
        const updatedTargeting = { ...props.locationTargeting, locations: { ...props.locationTargeting, geoCircles: props.locationTargeting?.geoCircles?.map(c => (c.id === circleFromEvent.id ? circleFromEvent : c)) } }
        props.onUpdatedLocationTargeting?.(updatedTargeting)
      }

      break
    }
  }

  const onRectangleChange = (rectangle: google.maps.Rectangle, id: string) => {
    const rectangleFromEvent = translateRectangleToGeoRectangle(rectangle, id)

    for (const rectangleFromState of props.locationTargeting?.geoRectangles ?? []) {
      if (rectangleFromEvent.id !== rectangleFromState.id) {
        continue
      }

      if (!areGeoRectanglesEqual(rectangleFromEvent, rectangleFromState)) {
        const updatedTargeting = { ...props.locationTargeting, locations: { ...props.locationTargeting, geoRectangles: props.locationTargeting?.geoRectangles?.map(r => (r.id === rectangleFromEvent.id ? rectangleFromEvent : r)) } }
        props.onUpdatedLocationTargeting?.(updatedTargeting)
      }

      break
    }
  }

  const onPolygonChange = (polygon: google.maps.Polygon, id: string) => {
    const polygonFromEvent = translatePolygonToGeoPolygon(polygon, id)

    for (const polygonFromState of props.locationTargeting?.geoPolygons ?? []) {
      if (polygonFromEvent.id !== polygonFromState.id) {
        continue
      }

      if (!areGeoPolygonsEqual(polygonFromEvent, polygonFromState)) {
        const updatedTargeting = { ...props.locationTargeting, locations: { ...props.locationTargeting, geoPolygons: props.locationTargeting?.geoPolygons?.map(p => (p.id === polygonFromEvent.id ? polygonFromEvent : p)) } }
        props.onUpdatedLocationTargeting?.(updatedTargeting)
      }
    }
  }

  const onOverlayComplete = (e: google.maps.drawing.OverlayCompleteEvent) => {
    if (!e.overlay) {
      return
    }

    const shape = e.overlay
    const id = htmlIdGenerator()()
    shape.set('id', id)

    setDrawingModeSelected('hand')

    if (e.type === google.maps.drawing.OverlayType.CIRCLE && shape instanceof google.maps.Circle) {
      const newGeoCircle: GeoCircle = translateCircleToGeoCircle(shape, id)
      props.onUpdatedLocationTargeting?.({ ...props.locationTargeting, geoCircles: [...(props.locationTargeting?.geoCircles ?? []), newGeoCircle] })
    } else if (e.type === google.maps.drawing.OverlayType.RECTANGLE && shape instanceof google.maps.Rectangle) {
      const newGeoRectangle: GeoRectangle = translateRectangleToGeoRectangle(shape, id)
      props.onUpdatedLocationTargeting?.({ ...props.locationTargeting, geoRectangles: [...(props.locationTargeting?.geoRectangles ?? []), newGeoRectangle] })
    } else if (e.type === google.maps.drawing.OverlayType.POLYGON && shape instanceof google.maps.Polygon) {
      const newGeoPolygon: GeoPolygon = translatePolygonToGeoPolygon(shape, id)
      props.onUpdatedLocationTargeting?.({ ...props.locationTargeting, geoPolygons: [...(props.locationTargeting?.geoPolygons ?? []), newGeoPolygon] })
    }

    shape.setMap(null)
  }

  const onShapeLoad = (shape: google.maps.Circle | google.maps.Rectangle | google.maps.Polygon, id: string) => {
    shape.set('id', id)

    shape.addListener('click', function () {
      setSelectedShape(shape)
    })
  }

  const onRemoveClick = () => {
    if (selectedShape) {
      if (selectedShape instanceof google.maps.Circle) {
        props.onUpdatedLocationTargeting?.({ ...props.locationTargeting, geoCircles: props.locationTargeting?.geoCircles?.filter(c => c.id !== (selectedShape as any).id) })
      }
      if (selectedShape instanceof google.maps.Rectangle) {
        props.onUpdatedLocationTargeting?.({ ...props.locationTargeting, geoRectangles: props.locationTargeting?.geoRectangles?.filter(r => r.id !== (selectedShape as any).id) })
      }
      if (selectedShape instanceof google.maps.Polygon) {
        props.onUpdatedLocationTargeting?.({ ...props.locationTargeting, geoPolygons: props.locationTargeting?.geoPolygons?.filter(p => p.id !== (selectedShape as any).id) })
      }
      selectedShape.setMap(null)
      setSelectedShape(null)
    }
  }

  const onZoomOutClick = () => {
    setMapZoom(mapZoom - 1)
  }

  const onZoomInClick = () => {
    setMapZoom(mapZoom + 1)
  }

  const minBillboardZoom = 8
  useDebounceEffect(
    200,
    () => {
      if (props.includeBillboards && currentCenter && lastCenter && mapZoom >= minBillboardZoom) {
        const degreeSensitivity = 0.8 / Math.pow(2, mapZoom - minBillboardZoom) > 0.04 ? 0.8 / Math.pow(2, mapZoom - minBillboardZoom) : 0.04
        if (lastBBZoom != mapZoom || Math.abs(currentCenter.lat() - lastCenter.lat()) > degreeSensitivity || Math.abs(currentCenter.lng() - lastCenter.lng()) > degreeSensitivity) {
          const radiusMeters = 300000 / Math.pow(2, mapZoom - minBillboardZoom) > 25000 ? 300000 / Math.pow(2, mapZoom - minBillboardZoom) : 25000
          getBillboardGeosInRangeQueryTrigger(
            {
              lat: currentCenter.lat(),
              lng: currentCenter.lng(),
              radiusMeters: radiusMeters
            },
            false
          )
          if (map) {
            setLastBBZoom(mapZoom)
            setLastCenter(map.getCenter())
          }
        }
      }
    },
    [mapZoom, currentCenter, lastCenter]
  )

  useEffect(() => {
    if (prevSelectedShape.current && (prevSelectedShape.current instanceof google.maps.Circle || prevSelectedShape.current instanceof google.maps.Rectangle || prevSelectedShape.current instanceof google.maps.Polygon)) {
      prevSelectedShape.current.setEditable(false)
    }
    if (selectedShape && (selectedShape instanceof google.maps.Circle || selectedShape instanceof google.maps.Rectangle || selectedShape instanceof google.maps.Polygon)) {
      selectedShape.setEditable(true)
      prevSelectedShape.current = selectedShape
    }
  }, [selectedShape])

  useDebounceEffect(
    500,
    () => {
      if (map && props.targetedPlaces && props.targetedPlaces.length > 0) {
        let latMax = -999
        let latMin = 999
        let lngMax = -999
        let lngMin = 999

        let service = new google.maps.places.PlacesService(map)
        let promiseArr: Promise<void>[] = []
        for (let place of props.targetedPlaces) {
          let request = {
            query: place,
            fields: ['geometry']
          }
          promiseArr.push(
            new Promise<void>(resolve => {
              service.findPlaceFromQuery(request, function (results, status) {
                if (status === google.maps.places.PlacesServiceStatus.OK && results && results.length > 0) {
                  const northEast = results[0].geometry?.viewport?.getNorthEast()
                  const southWest = results[0].geometry?.viewport?.getSouthWest()
                  latMax = latMax > (northEast?.lat() ?? 0) ? latMax : northEast?.lat() ?? 0
                  latMin = latMin < (southWest?.lat() ?? 0) ? latMin : southWest?.lat() ?? 0
                  lngMax = lngMax > (northEast?.lng() ?? 0) ? lngMax : northEast?.lng() ?? 0
                  lngMin = lngMin < (southWest?.lng() ?? 0) ? lngMin : southWest?.lng() ?? 0
                }
                resolve()
              })
            })
          )
        }
        Promise.all(promiseArr).then(() => {
          const lngRange = Math.abs(latMax - latMin)
          const latRange = Math.abs(lngMax - lngMin)

          const maxRange = lngRange > latRange ? lngRange : latRange

          setMapZoom(Math.floor(4 + Math.log(fourZoomDegrees / maxRange) / Math.log(2)))
          setMapCenter(new google.maps.LatLng((latMin + latMax) / 2, (lngMax + lngMin) / 2))
          setCurrentCenter(new google.maps.LatLng((latMin + latMax) / 2, (lngMax + lngMin) / 2))
        })
      }
    },
    [props.targetedPlaces]
  )

  useEffect(() => {
    if (map && drawingManager) {
      if (drawingModeSelected === 'hand') {
        drawingManager.setDrawingMode(null)
      }
      if (drawingModeSelected === 'circle') {
        drawingManager.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE)
      }
      if (drawingModeSelected === 'rectangle') {
        drawingManager.setDrawingMode(google.maps.drawing.OverlayType.RECTANGLE)
      }
      if (drawingModeSelected === 'polygon') {
        drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, drawingManager, drawingModeSelected])

  useEffect(() => {
    if (map && drawingManager) {
      map.setMapTypeId(mapTypeSelected)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, drawingManager, mapTypeSelected])

  const drawingControlButtons = [
    {
      id: 'hand',
      label: 'Hand',
      iconType: 'grab'
    },
    {
      id: 'circle',
      label: 'Circle',
      iconType: 'plusInCircle'
    },
    {
      id: 'rectangle',
      label: 'Rectangle',
      iconType: 'stop'
    },
    {
      id: 'polygon',
      label: 'Polygon',
      iconType: 'node'
    }
  ]

  const mapTypeControlButtons = [
    {
      id: 'roadmap',
      label: 'Roadmap',
      iconType: 'dot'
    },
    {
      id: 'satellite',
      label: 'Satellite',
      iconType: 'visMapRegion'
    }
  ]

  const renderMap = () => {
    const circles = (props.locationTargeting?.geoCircles ?? []).map(c => {
      const rnd = htmlIdGenerator()()
      return <AcCircle key={c.id} center={c.center} radius={c.radius} options={circleOptions} id={rnd} onCircleChange={circle => onCircleChange(circle, c.id)} onLoad={shape => onShapeLoad(shape, c.id)} />
    })

    const rectangles = (props.locationTargeting?.geoRectangles ?? []).map(r => (
      <AcRectangle
        key={r.id}
        bounds={{
          east: r.northEast.lng,
          west: r.southWest.lng,
          north: r.northEast.lat,
          south: r.southWest.lat
        }}
        options={rectangleOptions}
        onRectangleChange={rectangle => onRectangleChange(rectangle, r.id)}
        onLoad={shape => onShapeLoad(shape, r.id)}
      />
    ))
    const polygons = (props.locationTargeting?.geoPolygons ?? []).map(p => <AcPolygon key={p.id} path={p.points} options={polygonOptions} onPolygonChange={polygon => onPolygonChange(polygon, p.id)} onLoad={shape => onShapeLoad(shape, p.id)} />)

    const drawingManager = <DrawingManager onLoad={onLoadDrawingManager} onUnmount={onUnmountDrawingManager} options={drawingManagerOptions} onOverlayComplete={onOverlayComplete} />

    const drawingManagerToolbar = (
      <div style={{ marginTop: 8, marginLeft: 8 }}>
        <EuiButtonGroup color='primary' legend='Drawing Manager' options={drawingControlButtons} onChange={id => setDrawingModeSelected(id)} type='single' idSelected={drawingModeSelected} isIconOnly />
      </div>
    )

    const mapTypeToolbar = (
      <div style={{ marginBottom: 8 }}>
        <EuiButtonGroup color='primary' legend='Map Type' options={mapTypeControlButtons} onChange={id => setMapTypeSelected(id)} type='single' idSelected={mapTypeSelected} isIconOnly />
      </div>
    )
    const autocompleteControl = (
      <Autocomplete onLoad={onLoadAutocomplete} onUnmount={onUnmountAutocomplete} onPlaceChanged={onPlaceChanged} restrictions={{ country: 'US' }}>
        <div style={{ marginTop: 8, width: 350 }}>
          <EuiFieldSearch
            id={htmlIdGenerator()()}
            onClick={() => {}}
            onKeyPress={e => {
              e.key === 'Enter' && e.preventDefault()
            }}
            autoComplete='off'
            width={350}
            compressed
          />
        </div>
      </Autocomplete>
    )

    const zoomControl = (
      <React.Fragment>
        <EuiButton className='zoomOut' id={htmlIdGenerator()()} fill size='s' onClick={onZoomOutClick} style={{ marginTop: 8, marginRight: 8 }}>
          Zoom Out
        </EuiButton>
        <EuiButton className='zoomIn' id={htmlIdGenerator()()} fill size='s' onClick={onZoomInClick} style={{ marginTop: 8, marginRight: 8 }}>
          Zoom In
        </EuiButton>
      </React.Fragment>
    )

    const removeTargetControl = (
      <EuiButton className='removeTarget' id={htmlIdGenerator()()} fill size='s' onClick={onRemoveClick} style={{ marginBottom: 8 }} disabled={selectedShape === null} color='ghost'>
        Remove Target
      </EuiButton>
    )

    return (
      <GoogleMap mapContainerStyle={{ height: 500, width: '100%' }} center={mapCenter} zoom={mapZoom} onLoad={onLoad} onUnmount={onUnmountMap} onClick={onMapClick} options={mapOptions} onCenterChanged={handleCenterChanged}>
        {drawingManager}
        {!props.hideDrawingTools && <MapControl position={google.maps.ControlPosition.TOP_LEFT}>{drawingManagerToolbar}</MapControl>}
        {!props.hideLocationSearch && <MapControl position={google.maps.ControlPosition.TOP_CENTER}>{autocompleteControl}</MapControl>}
        <MapControl position={google.maps.ControlPosition.BOTTOM_CENTER}>{mapTypeToolbar}</MapControl>
        <MapControl position={google.maps.ControlPosition.TOP_RIGHT}>{zoomControl}</MapControl>
        <MapControl position={google.maps.ControlPosition.BOTTOM_LEFT}>{removeTargetControl}</MapControl>
        {circles}
        {rectangles}
        {polygons}
        {props.includeBillboards && mapZoom >= minBillboardZoom && billboardGeosInRangeResults.data && billboardGeosInRangeResults.data.map(bb => <Marker position={{ lat: bb.latitude, lng: bb.longitude }} />)}
      </GoogleMap>
    )
  }

  return (
    <React.Fragment>
      {props.includeBillboards && mapZoom < minBillboardZoom && <EuiCallOut size='s' iconType='alert' color='warning' title='Zoom in to view billboard locations' />}
      <div style={{ height: 500, width: '100%' }}>{renderMap()}</div>
    </React.Fragment>
  )
}
