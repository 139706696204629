import { AdPaymentType } from 'features/builders/internet/retail/builderInternetRetailSlice'

export interface PaymentInfo {
  paymentMethodType: AdPaymentType
  paymentMethodId?: string
  budgetFrequency: BudgetFrequency
  campaignSlotId?: string
  bundleId?: string
}

export enum BudgetFrequency {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Total = 'Total',
  Days30 = 'Days30'
}
