import React from 'react'

export interface IAcTernary {
  hasRequiredSetup: () => boolean
  failRender: () => JSX.Element
  succeedRender: () => JSX.Element
}

export const AcTernary: React.FC<IAcTernary> = props => {
  return props.hasRequiredSetup() ? props.succeedRender() : props.failRender()
}
