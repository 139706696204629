import React, { FC } from 'react'

import { ReportAccount } from 'api/interfaces'
import ReportDataGrid, { ReportDataGridColumn } from 'features/reports/ReportDataGrid'

export interface ReportAccountGridProps {
  data: ReportAccount[]
}

const ReportAccountGrid: FC<ReportAccountGridProps> = ({ data }) => {
  const columns: ReportDataGridColumn[] = [
    {
      id: 'accountId',
      displayAsText: 'Account Id',
      hidden: true
    },
    {
      id: 'accountName',
      displayAsText: 'Account Name'
    },
    {
      id: 'forDate',
      displayAsText: 'Date',
      schema: 'date'
    },
    {
      id: 'views',
      displayAsText: 'Views'
    },
    {
      id: 'clicks',
      displayAsText: 'Clicks'
    },
    {
      id: 'ctr',
      displayAsText: 'CTR %'
    },
    {
      id: 'spend',
      displayAsText: 'Spend'
    },
    {
      id: 'cpc',
      displayAsText: 'CPC'
    },
    {
      id: 'cpm',
      displayAsText: 'CPM'
    }
  ]

  return <ReportDataGrid data={data} columns={columns} defaultSorting={[{ id: 'forDate', direction: 'desc' }]} />
}

export default ReportAccountGrid
